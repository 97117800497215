import { Grid } from '@mui/material';
import { NamedFeature } from 'interfaces/features';
import { InferredFeatureConfig } from 'interfaces/inferredFeatures';
import { Permission } from 'interfaces/permissionOption';
import { QualityControlLabel } from 'interfaces/qualityControlLabels';
import { Study } from 'interfaces/study';
import React, { FunctionComponent } from 'react';
import { usePermissions } from 'utils/usePermissions';
import CellRuleStudySettings from './CellRuleStudySettings';
import FeaturesSettings from './FeaturesSettings/FeaturesSettings';
import { GeneralSettings } from './GeneralSettings';
import InferredFeaturesSettings from './InferredFeaturesSettings/InferredFeaturesSettings';
import { PlatformSettings } from './PlatformSettings';
import { StudyAlgorithmsSettings } from './StudyAlgorithmsSettings';
import StudyLabelsSettings from './StudyLabel/StudyLabelsSettings';

interface StudySettingsProps {
  study: Study;
  localHighlightedFeatures?: NamedFeature[];
  setLocalHighlightedFeatures?: React.Dispatch<React.SetStateAction<NamedFeature[]>>;
  localHiddenFeatures?: NamedFeature[];
  setLocalHiddenFeatures?: React.Dispatch<React.SetStateAction<NamedFeature[]>>;
  localInferredFeatures?: InferredFeatureConfig[];
  setLocalInferredFeatures?: React.Dispatch<React.SetStateAction<InferredFeatureConfig[]>>;
  localLabels?: QualityControlLabel[];
  setLocalLabels?: React.Dispatch<React.SetStateAction<QualityControlLabel[]>>;
  updatedSettings?: Study['settings'];
  setUpdatedSettings?: React.Dispatch<React.SetStateAction<Study['settings']>>;
  updatedPlatformSettings?: Study['platformSettings'];
  setUpdatedPlatformSettings?: React.Dispatch<React.SetStateAction<Study['platformSettings']>>;
}

const StudySettings: FunctionComponent<React.PropsWithChildren<StudySettingsProps>> = ({
  study,
  localHighlightedFeatures,
  setLocalHighlightedFeatures,
  localHiddenFeatures,
  setLocalHiddenFeatures,
  localInferredFeatures,
  setLocalInferredFeatures,
  localLabels,
  setLocalLabels,
  updatedSettings,
  setUpdatedSettings,
  updatedPlatformSettings,
  setUpdatedPlatformSettings,
}) => {
  const { hasPermission } = usePermissions();
  const globalLabels = study.qcLabelsConfig;
  const globalInferredFeaturesConfig = study.inferredFeaturesConfig;

  const hasPermissionToEditQcQaLabels = hasPermission(Permission.EditQcQaLabels);
  const hasPermissionToEditInferredFeatures = hasPermission(Permission.EditInferredFeatures);
  const hasPermissionToEditPlatformSettings = hasPermission(Permission.EditPlatformSettings);
  const hasPermissionToEditCellRules = hasPermission(Permission.EditCellRules);
  const hasPermissionToEditStudyAlgorithmsSettings = hasPermission(Permission.EditStudyAlgorithms);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FeaturesSettings
          study={study}
          highlightedFeatures={localHighlightedFeatures}
          setHighlightedFeatures={setLocalHighlightedFeatures}
          hiddenFeatures={localHiddenFeatures}
          setHiddenFeatures={setLocalHiddenFeatures}
        />
      </Grid>
      <Grid item xs={12}>
        <GeneralSettings studyId={study.id} updatedSettings={updatedSettings} setUpdatedSettings={setUpdatedSettings} />
      </Grid>
      {hasPermissionToEditPlatformSettings && (
        <Grid item xs={12}>
          <PlatformSettings
            updatedSettings={updatedPlatformSettings ?? study.platformSettings}
            setUpdatedSettings={setUpdatedPlatformSettings}
          />
        </Grid>
      )}
      {hasPermissionToEditStudyAlgorithmsSettings && (
        <Grid item xs={12}>
          <StudyAlgorithmsSettings updatedSettings={updatedSettings} setUpdatedSettings={setUpdatedSettings} />
        </Grid>
      )}
      {hasPermissionToEditQcQaLabels && (
        <Grid item xs={12}>
          <StudyLabelsSettings
            study={study}
            hasUnsavedLabels={Boolean(localLabels)}
            setLabels={setLocalLabels}
            labels={localLabels ?? globalLabels}
          />
        </Grid>
      )}
      {hasPermissionToEditInferredFeatures && (
        <Grid item xs={12}>
          <InferredFeaturesSettings
            study={study}
            hasUnsavedInferredFeatures={Boolean(localInferredFeatures)}
            setInferredFeaturesConfig={setLocalInferredFeatures}
            inferredFeaturesConfig={localInferredFeatures ?? globalInferredFeaturesConfig}
          />
        </Grid>
      )}
      {hasPermissionToEditCellRules && (
        <Grid item xs={12}>
          <CellRuleStudySettings studyId={study.id} />
        </Grid>
      )}
    </Grid>
  );
};

export default StudySettings;
