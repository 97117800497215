import { GridRowId, useGridApiContext } from '@mui/x-data-grid';
import renderCaseIdCell from 'components/atoms/DataGridRenderers/CaseIdCellRenderer';
import CancerSubtypesAutocomplete, {
  OptionalCancerSubtypesAutocompleteProps,
} from 'components/SearchFilters/CancerSubtypesField';
import {
  getAllTissueCodes,
  getTreeDataAsArray,
} from 'components/SearchFilters/CancerSubtypesField/cancerSubtypeTreeHelpers';
import { DisplayedField } from 'interfaces/genericFields';
import { Procedure } from 'interfaces/procedure';
import { get, includes, isEmpty, map, some, toPairs, values } from 'lodash';
import React from 'react';
import { getCancerSubTypes, getUpdatePathForProcedureClinicalDataField, ProceduresFieldsContext } from './helpers';

export const caseIdField: DisplayedField<Procedure, number> = {
  filterType: 'multiSelect',
  dataKey: 'id',
  label: 'Case ID',
  columnWidth: { width: 90 },
  sortable: true,
  renderCell: () => renderCaseIdCell,
};

export const caseNameField: DisplayedField<Procedure, string> = {
  cellEditType: 'text',
  filterType: 'text',
  dataCategory: 'metadata',
  dataKey: 'label',
  label: 'Case Name',
  columnWidth: { width: 150 },
};

export const cancerTypeField: DisplayedField<Procedure, string | number, ProceduresFieldsContext> = {
  cellEditType: 'select',
  filterType: 'multiSelect',
  dataKey: 'cancerTypeId',
  label: 'Cancer Type',
  valueFormatter: ({ value }, { allCancerTypes }) => `${(allCancerTypes?.[value]?.displayName || value) ?? ''}`,
  getError: ({ value, row, context }) => {
    if (!value) return `Cancer Type is required`;
    if (!get(context?.allCancerTypes, value)) return `Invalid Cancer Type`;
  },
  optionsGetter: (context) =>
    map(values(context?.allCancerTypes), (cancerType) => ({
      value: cancerType.id,
      label: cancerType.displayName,
    })),
  columnWidth: { width: 150 },
};

export interface CancerSubtypesCellEditorProps<Multiple extends boolean | undefined = true>
  extends OptionalCancerSubtypesAutocompleteProps<Multiple> {
  value: string[];
  context: ProceduresFieldsContext;
  field: string;
  id: GridRowId;
}

const CancerSubtypeHeaderEditor = ({
  value,
  cancerTypeId,
  context,
  onChange,
}: {
  value: string[];
  cancerTypeId: number;
  context: ProceduresFieldsContext;
  onChange: (val: string[]) => void;
}) => {
  const { mainCancerTypeCodes, relevantSubtypes } = getCancerSubTypes(
    [cancerTypeId],
    context.allCancerTypes,
    context.cancerSubtypes
  );

  return (
    <CancerSubtypesAutocomplete
      sx={{
        '& MuiAutocomplete-root': {
          width: '100%',
        },
      }}
      multiple
      disabled={isEmpty(relevantSubtypes)}
      subTree={relevantSubtypes}
      mainCancers={mainCancerTypeCodes}
      limitTags={1}
      labelMaxWidth={'90px'}
      handleChange={onChange}
      value={value}
      popperPlacement={'left'}
    />
  );
};

const CancerSubtypeCellEditor = ({ value, context, field, id, ...props }: CancerSubtypesCellEditorProps) => {
  const apiRef = useGridApiContext();
  const onChange = (val: string[]) => {
    apiRef.current.setEditCellValue({
      id,
      field,
      value: val,
    });
  };

  const currentRow = apiRef.current.getRowWithUpdatedValues(id, 'id');
  const { mainCancerTypeCodes, relevantSubtypes } = getCancerSubTypes(
    [currentRow?.cancerTypeId],
    context.allCancerTypes,
    context.cancerSubtypes
  );

  return (
    <CancerSubtypesAutocomplete
      sx={{
        '& fieldset': {
          border: 'none',
        },
        '& MuiAutocomplete-root': {
          width: '100%',
        },
      }}
      multiple
      isEdit
      disabled={isEmpty(relevantSubtypes)}
      subTree={relevantSubtypes}
      mainCancers={mainCancerTypeCodes}
      limitTags={1}
      labelMaxWidth={'90px'}
      handleChange={onChange}
      value={value}
      popperPlacement={'bottom-start'}
      openOnRender={true}
    />
  );
};

export const cancerSubtypeField: DisplayedField<Procedure, string[], ProceduresFieldsContext> = {
  cellEditType: 'select',
  filterType: 'multiSelect',
  dataKey: 'cancerSubtypes',
  label: 'Cancer Subtype',
  columnWidth: { width: 250 },
  customCellEditor: (fieldData, context) => (props) => {
    const { value, id, field } = props;
    return <CancerSubtypeCellEditor value={value} context={context} field={field} id={id} />;
  },
  customHeaderEditor: (context, onChange) => (props) => {
    const { value, bulkChanges } = props;
    return (
      <CancerSubtypeHeaderEditor
        value={value}
        context={context}
        onChange={onChange}
        cancerTypeId={get(bulkChanges, cancerTypeField.dataKey)}
      />
    );
  },
  getError: ({ value: chosenSubtypes, row, context }) => {
    // If row is not provided (e.g. in bulk edit), we can't validate the chosen subtypes
    if (!row) return;
    const { relevantSubtypes } = getCancerSubTypes(
      [Number(row?.cancerTypeId)],
      context.allCancerTypes,
      context.cancerSubtypes
    );
    const allCancerSubtypeOptions = getAllTissueCodes(getTreeDataAsArray(relevantSubtypes));
    if (some(chosenSubtypes, (subtype) => !includes(allCancerSubtypeOptions, subtype))) return `Invalid Cancer Subtype`;
  },
};

export const biopsyDateField: DisplayedField<Procedure, string> = {
  // TODO: set `cellEditType: 'date',` if/when we allow clinical data editing
  filterType: 'date-range',
  dataCategory: 'metadata',
  dataKey: 'biopsyDate',
  label: 'Biopsy Date',
  isFullDate: true,
  views: ['day', 'month', 'year'],
  columnWidth: { width: 120 },
  valueGetter: (procedure) => procedure?.clinicalData?.biopsyDate,
  updatePath: getUpdatePathForProcedureClinicalDataField('biopsyDate'),
};

export const studyField: DisplayedField<Procedure, string, ProceduresFieldsContext> = {
  // TODO: set `cellEditType: 'select',` if we want to support reassigning procedures to different studies
  filterType: 'multiSelect',
  dataKey: 'studyId',
  label: 'Study',
  columnWidth: { width: 120 },
  valueFormatter: ({ value }, { studyById }) => studyById?.[value]?.name,
  optionsGetter: (context) =>
    map(toPairs(context?.studyById || {}), ([id, study]) => ({ value: id, label: study.name })),
};

export const caseBaseFields = [
  caseIdField,
  caseNameField,
  cancerTypeField,
  cancerSubtypeField,
  biopsyDateField,
  studyField,
];
