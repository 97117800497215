import { Layer } from '@deck.gl/core/typed';
import { useSignals } from '@preact/signals-react/runtime';
import {
  compact,
  concat,
  Dictionary,
  filter,
  first,
  flatMap,
  fromPairs,
  groupBy,
  isEmpty,
  join,
  keys,
  map,
  size,
  uniq,
  values,
} from 'lodash';
import { useMemo } from 'react';

import {
  LayerVisualizationSettings,
  slidesLayerVisualizationSettings,
} from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { ParquetLayerType } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import {
  getAllFlatMapDeepHeatmapsFromSlide,
  SlideWithChannelAndResults,
} from 'components/Procedure/useSlideChannelsAndResults/utils';
import { MULTIPLEX_STAIN_ID } from 'interfaces/stainType';
import { deckGLParquetCellClassDensityLayer } from './deckGLParquetCellClassDensityLayer';
import { deckGLParquetCellClassLayer } from './deckGLParquetCellClassLayer';
import { deckGLParquetMarkerPositivityLayer } from './deckGLParquetMarkerPositivityLayer';
import { getMarkerPositivityParentHeatmapId, groupParquetHeatmapsByType, useParquetHeatmapFiles } from './helpers';

export const useParquetLayers = ({
  slide,
  idPrefix,
  rescaleFactor,
}: {
  slide: SlideWithChannelAndResults;
  idPrefix: string;
  rescaleFactor?: number;
}) => {
  useSignals();
  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[slide.viewerIndex];

  const slideLayerVisualizationSettings = viewerSlideLayerVisualizationSettings.value?.[slide.id];

  const allHeatmaps = getAllFlatMapDeepHeatmapsFromSlide(slide);
  const parquetHeatmapByType = useMemo(() => groupParquetHeatmapsByType(compact(allHeatmaps), true), [slide]);

  const { parquetFiles } = useParquetHeatmapFiles({ parquetHeatmapByType });

  const cellClassificationHeatmapIds = compact(map(parquetHeatmapByType[ParquetLayerType.CellClassification], 'id'));
  const cellClassificationDensitiesHeatmapIds = compact(
    map(parquetHeatmapByType[ParquetLayerType.CellClassificationDensities], 'id')
  );
  const markerPositivityHeatmapIds = compact(
    uniq(map(parquetHeatmapByType[ParquetLayerType.MarkerPositivity], getMarkerPositivityParentHeatmapId))
  );

  const cellTableVisualSettingsByHeatmapId: Dictionary<Dictionary<LayerVisualizationSettings>> = fromPairs(
    concat(
      map(compact(concat(cellClassificationHeatmapIds, cellClassificationDensitiesHeatmapIds)), (parquetHeatmapId) => [
        parquetHeatmapId,
        fromPairs(
          map(
            filter(keys(slideLayerVisualizationSettings), (key) => key.startsWith(parquetHeatmapId)),
            (key) => [key, slideLayerVisualizationSettings[key].value]
          )
        ),
      ]),
      map(markerPositivityHeatmapIds, (parquetHeatmapId) => [
        parquetHeatmapId,
        fromPairs(
          map(
            filter(keys(slideLayerVisualizationSettings), (key) => key.startsWith(parquetHeatmapId)),
            (key) => [key, slideLayerVisualizationSettings[key].value]
          )
        ),
      ])
    )
  );

  const filteredMarkerPositivityParquets = useMemo(() => {
    const groupMarkerPositivityHeatmapsById = groupBy(
      parquetHeatmapByType[ParquetLayerType.MarkerPositivity],
      getMarkerPositivityParentHeatmapId
    );

    return fromPairs(
      compact(
        map(groupMarkerPositivityHeatmapsById, (markerHeatmaps, parentHeatmapId) => {
          const markerColumns = map(markerHeatmaps, 'columnName');
          const parquetFile =
            parquetFiles?.[first(markerHeatmaps)?.heatmapUrl]?.[join(['X', 'Y', ...markerColumns], ',')];
          const visibleMarkerColumns = filter(markerColumns, (markerValue) => {
            const layerSettings =
              cellTableVisualSettingsByHeatmapId?.[parentHeatmapId]?.[`${parentHeatmapId}-${markerValue}`];
            return Boolean(layerSettings?.selected && layerSettings?.show && layerSettings?.opacity > 0);
          });
          if (!parquetFile || isEmpty(visibleMarkerColumns)) {
            return undefined;
          }
          return [parentHeatmapId, parquetFile];
        })
      )
    );
  }, [parquetFiles, parquetHeatmapByType, JSON.stringify(cellTableVisualSettingsByHeatmapId)]);

  const parquetLayers = useMemo(() => {
    const groupMarkerPositivityHeatmapsById = groupBy(
      parquetHeatmapByType[ParquetLayerType.MarkerPositivity],
      getMarkerPositivityParentHeatmapId
    );

    const cellTableLayers = flatMap(parquetHeatmapByType[ParquetLayerType.CellClassification], (parquetHeatmap) => {
      const filteredColumns = ['X', 'Y', parquetHeatmap.columnName];
      const parquetFile = parquetFiles?.[parquetHeatmap.heatmapUrl]?.[join(filteredColumns, ',')];

      if (!parquetFile?.rows) {
        if (!parquetFile?.isLoading) {
          console.warn('No parquet file found for heatmap', parquetHeatmap);
        }
        return [];
      }

      const parquetHeatmapId = parquetHeatmap.id;
      const visualSettings = cellTableVisualSettingsByHeatmapId[parquetHeatmapId];
      return deckGLParquetCellClassLayer({
        idPrefix: `${idPrefix}-${slide.id}`,
        visualSettings,
        heatmapMetadata: parquetHeatmap,
        parquetHeatmapId,
        parquetFile,
        rescaleFactor,
        filteredColumns,
        slideMaxResolution: slide.maxResolution,
      });
    });

    const cellDensityLayers = flatMap(
      parquetHeatmapByType[ParquetLayerType.CellClassificationDensities],
      (parquetHeatmap): Layer | Layer[] => {
        const filteredColumns = ['X', 'Y', parquetHeatmap.columnName];
        const parquetFile = parquetFiles?.[parquetHeatmap.heatmapUrl]?.[join(filteredColumns, ',')];

        if (!parquetFile?.rows) {
          if (!parquetFile?.isLoading) {
            console.warn('No parquet file found for heatmap', parquetHeatmap);
          }
          return [];
        }

        const parquetHeatmapId = parquetHeatmap.id;
        const visualSettings = cellTableVisualSettingsByHeatmapId[parquetHeatmapId];
        return deckGLParquetCellClassDensityLayer({
          idPrefix: `${idPrefix}-${slide.id}`,
          visualSettings,
          heatmapMetadata: parquetHeatmap,
          parquetHeatmapId,
          parquetFile,
          rescaleFactor,
          filteredColumns,
          slideMaxResolution: slide.maxResolution,
        });
      }
    );

    const markerPositivityLayers = flatMap(groupMarkerPositivityHeatmapsById, (markerHeatmaps, parentHeatmapId) => {
      const parquetFile = filteredMarkerPositivityParquets[parentHeatmapId];
      if (!parquetFile) {
        return null;
      }
      const visualSettings = cellTableVisualSettingsByHeatmapId[parentHeatmapId];
      const uniqueColors = uniq(
        map(
          filter(values(visualSettings), (layerSettings) =>
            Boolean(layerSettings?.selected && layerSettings?.show && layerSettings?.opacity > 0)
          ),
          (layerSettings) => layerSettings.color?.toLowerCase?.()
        )
      );
      return deckGLParquetMarkerPositivityLayer({
        isFilled: slide.stainingType !== MULTIPLEX_STAIN_ID,
        idPrefix: `${idPrefix}-${slide.id}`,
        visualSettings,
        parquetFile,
        rescaleFactor,
        markerHeatmaps,
        slideMaxResolution: slide.maxResolution,
        scatterPlot: size(uniqueColors) < 2,
      });
    });

    return compact(concat<Layer>(cellDensityLayers, cellTableLayers, markerPositivityLayers));
  }, [
    slide.id,
    slide.maxResolution,
    idPrefix,
    parquetHeatmapByType,
    parquetFiles,
    filteredMarkerPositivityParquets,
    rescaleFactor,
    JSON.stringify(cellTableVisualSettingsByHeatmapId),
  ]);

  return parquetLayers;
};
