import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Link, Typography } from '@mui/material';
import { DataGrid, GridEventListener } from '@mui/x-data-grid';
import { QueryFunctionContext, useQueries, useQuery } from '@tanstack/react-query';
import {
  castArray,
  filter,
  first,
  forEach,
  fromPairs,
  includes,
  isEmpty,
  join,
  map,
  orderBy,
  size,
  sortBy,
  toPairs,
  uniq,
} from 'lodash';
import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';

import { getJob } from 'api/jobs';
import Loader from 'components/Loader';
import { useJobColumns } from 'components/Pages/Jobs/columns';
import { useJobs } from 'components/Pages/Jobs/useJobs';
import { JobStatus, JobType, QaAnalysisJob } from 'interfaces/job';
import moment from 'moment';
import { humanize } from 'utils/helpers';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useNavigationToViewerPage } from 'utils/useNavigationToViewerPage';
import { PlotlyWrapper } from './charts/PlotlyWrapper';

interface Props {
  pageSize?: number;
  selectedStudyId?: string;
}

const displayedColumns = ['startedAt', 'name', 'requestSender', 'userId'];

const QaAnalysis: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ pageSize = 10, selectedStudyId }) => {
  const { labId } = useCurrentLabId();

  const [page, setPage] = React.useState(1);
  const { data: jobsResponse, isLoading: isLoadingJobs } = useJobs<QaAnalysisJob>({
    page,
    pageSize,
    enabled: true,
    additionalFilters: { type: JobType.QaAnalysis, statuses: [JobStatus.Completed], studyId: selectedStudyId },
  });

  const jobsForTable = jobsResponse?.jobs || [];

  const jobsCount = jobsResponse?.totalJobs ?? size(jobsForTable);

  const { jobColumns, isLoading: isLoadingColumnsQueries } = useJobColumns({ viewOnly: true });

  const [currentJobIdFromUrl, setCurrentJobId] = useQueryParam('jobIdForQaAnalysis', StringParam);

  const currentJobId = currentJobIdFromUrl || first(orderBy(jobsForTable, ['startedAt'], ['desc']))?.id;

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    setCurrentJobId(params.row.id);
  };

  const { data: job, isLoading: isLoadingJob } = useQuery({
    queryKey: ['job', currentJobId],
    queryFn: ({ signal }) => getJob<QaAnalysisJob>(currentJobId, signal),
    enabled: !isEmpty(currentJobId),
  });

  const { getUrlToSlidePage } = useNavigationToViewerPage();

  const presignedUrlsToDownload = useMemo(() => {
    const results: string[] = [];
    forEach(job?.results, (resultGroup) => {
      forEach(resultGroup, (resultEntry) => {
        if (resultEntry?.storage_url && !resultEntry?.storage_url.startsWith('s3://')) {
          results.push(resultEntry.storage_url);
        }
      });
    });
    return uniq(results);
  }, [job]);

  const presignedUrlQueries = useQueries({
    queries: map(presignedUrlsToDownload, (presignedUrl) => ({
      queryKey: ['presignedUrl', presignedUrl],
      queryFn: async ({ signal }: QueryFunctionContext) => {
        const response = await fetch(presignedUrl, { signal });
        // Assume response is a JSON file
        return response.json();
      },
      enabled: Boolean(presignedUrl),
    })),
  });

  const presignedUrlToQuery = useMemo(
    () => fromPairs(map(presignedUrlsToDownload, (presignedUrl, index) => [presignedUrl, presignedUrlQueries[index]])),
    [presignedUrlsToDownload, join(map(presignedUrlQueries, 'dataUpdatedAt'))]
  );

  return (
    <Grid item container spacing={2} pt={4} paddingInline={9} xs={12} direction="column">
      <Grid item xs="auto">
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid item>
              <Typography variant="h2">QA Analysis</Typography>
              <Typography variant="caption">
                {isLoadingColumnsQueries || isLoadingJobs ? 'Loading...' : 'Open to select a job'}
              </Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ width: 1000, height: 320 }}>
              <DataGrid
                paginationModel={{ page: page - 1, pageSize }}
                onPaginationModelChange={({ page: newPage }) => setPage(newPage + 1)}
                loading={isLoadingColumnsQueries || isLoadingJobs}
                pagination
                rows={jobsForTable}
                rowCount={jobsCount}
                columns={[
                  ...filter(jobColumns, (column) => includes(displayedColumns, column.field)),
                  { field: 'manifest', headerName: 'Manifest', flex: 1 },
                ]}
                onRowClick={handleRowClick}
                paginationMode="server"
                pageSizeOptions={castArray(pageSize)}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Grid>
      {isLoadingJobs || isLoadingJob ? (
        <Grid item>
          <Loader />
        </Grid>
      ) : (
        <Grid item container direction="column" spacing={1} key={job.id}>
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Grid item>
                    <Typography variant="h3">
                      {job.name} from {size(job.manifest)} slides - {moment(job.startedAt).format('LLL')}
                    </Typography>
                    <Typography variant="caption">Click to view slides</Typography>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  {map(uniq(job.manifest), (slideId) => (
                    <Grid item key={slideId}>
                      <Link
                        to={getUrlToSlidePage({ slideId, labId, caseStudyId: job.studyId })}
                        target="_blank"
                        rel="noopener noreferrer"
                        component={RouterLink}
                      >
                        <Typography variant="caption" key={slideId}>
                          {slideId}
                        </Typography>
                      </Link>
                    </Grid>
                  ))}
                </AccordionDetails>
              </Accordion>
            </Grid>
            {map(job?.results, (resultGroup, resultGroupName) => {
              return (
                <Grid item container direction="column" key={resultGroupName} marginInline={2}>
                  <Grid item container direction="column" spacing={1}>
                    <Grid item>
                      <Typography variant="h4">{humanize(resultGroupName)}</Typography>
                    </Grid>
                    <Grid item container spacing={6}>
                      {map(
                        sortBy(toPairs(resultGroup), ([resultKey, resultEntry]) => resultEntry?.index || resultKey),
                        ([resultKey, resultEntry]) => {
                          const resultTitle = resultEntry?.title || resultKey;
                          const resultQuery = resultEntry?.storage_url && presignedUrlToQuery[resultEntry.storage_url];
                          const result = resultQuery ? resultQuery?.data : resultEntry;

                          const queryFailed =
                            !result &&
                            !resultQuery?.isLoading &&
                            resultEntry?.storage_url &&
                            !includes(presignedUrlsToDownload, resultEntry.storage_url);
                          if (queryFailed) {
                            console.warn('Failed to query result', { resultGroup, resultKey, resultEntry });
                          }
                          const isLoading = resultQuery?.isLoading;
                          return (
                            <Grid item container direction="column" xs={6} spacing={1} key={resultKey}>
                              <Grid item>
                                <Typography variant="h6">{humanize(resultTitle)}</Typography>
                              </Grid>
                              <Grid item>
                                {isLoading ? (
                                  <Loader />
                                ) : queryFailed ? (
                                  <Typography variant="caption" color="warning">
                                    Failed to query result
                                  </Typography>
                                ) : isEmpty(result?.data) ? (
                                  <Typography variant="caption" color="warning">
                                    No data
                                  </Typography>
                                ) : (
                                  <PlotlyWrapper data={result.data} layout={result.layout} />
                                )}
                              </Grid>
                            </Grid>
                          );
                        }
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default QaAnalysis;
