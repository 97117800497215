import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { pageHeaderHeight } from 'components/PageHeader';
import { Permission } from 'interfaces/permissionOption';
import { usePermissions } from 'utils/usePermissions';

export const FlexBody = styled(Box)(({ theme }) => ({
  flex: '1 1 auto',
  backgroundColor: theme.palette.mode === 'light' && theme.palette.grey[50],
}));

export const annotationsDrawerWidth = 500;
export const filtersDrawerWidth = 320;
export const overviewDrawerWidth = 320;
export const jobDrawerWidth = 400;
export const settingsDetailsDrawerWidth = 400;
export const studyDashboardHeaderHeight = 88;
export const studyDashboardTabsHeight = 48;
export const caseToolbarHeight = 48;

export const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'leftDrawerOpen' && prop !== 'rightDrawerOpen' && prop !== 'rightDrawerWidth',
})<{
  leftDrawerOpen?: boolean;
  rightDrawerOpen?: boolean;
  rightDrawerWidth?: number;
}>(({ theme, leftDrawerOpen, rightDrawerOpen, rightDrawerWidth = overviewDrawerWidth }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  width: '100%',
  ...(leftDrawerOpen && {
    marginLeft: `${rightDrawerWidth}px`,
    width: `calc(100% - ${rightDrawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(rightDrawerOpen && {
    width: `calc(100% - ${rightDrawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(leftDrawerOpen &&
    rightDrawerOpen && {
      width: `calc(100% - ${rightDrawerWidth}px - ${rightDrawerWidth}px)`,
    }),
}));

export const useStudyDashboardStyles = () => {
  const { hasPermission } = usePermissions();
  const displayTabs = hasPermission(Permission.ViewAnnotationsList);
  const borderHeight = 1;
  return {
    mainMarginTop:
      studyDashboardHeaderHeight + pageHeaderHeight + borderHeight + (displayTabs ? studyDashboardTabsHeight : 0),
  };
};
