import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { TreeView } from '@mui/lab';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import {
  ClassificationBinningParams,
  InferenceModelData,
  OrchestrationInference,
  SlideInferenceResults,
} from 'interfaces/calculateFeatures';
import { Dictionary, isEmpty, keys, map } from 'lodash';
import React from 'react';
import { ModelsByStainByType } from '.';
import {
  ModelPreferencesById,
  ModelUserPreferences,
  OrchestrationBySlideByFlowClassName,
  OrchestrationBySlideByType,
} from '..';
import { modelTypesByApiModelValue } from '../../Jobs/inferenceFieldsOptions';
import ModelRow from './model/ModelRow';
import OrchestrationRow from './OrchestrationRow';

export interface InferenceModelProps {
  studyId: string;
  stain: string;
  modelType: string;
  stainModelInferences: ModelsByStainByType[string];
  orchestrations?: OrchestrationInference[];
  slides: Dictionary<SlideInferenceResults>;
  selectedOrchestrations?: OrchestrationBySlideByType;
  setSelectedOrchestrations?: (
    slideIds: string[],
    model: InferenceModelData,
    modelType: string,
    orchestration: OrchestrationInference
  ) => void;
  selectedPostprocessedOrchestrations?: OrchestrationBySlideByFlowClassName;
  setSelectedPostprocessedOrchestrations?: (
    slideIds: string[],
    flowClassName: string,
    orchestration: OrchestrationInference
  ) => void;
  setModelPreferencesByModelId?: (modelId: string, userPreferences: ModelUserPreferences) => void;
  modelPreferencesByModelId?: ModelPreferencesById;
  setSelectedOrchestrationsByClassificationType?: (modelType: string, previousModelType?: string) => void;
  setIntensityClassificationBinning?: (modelId: string, binning: ClassificationBinningParams) => void;
}

const InferenceModel: React.FC<React.PropsWithChildren<InferenceModelProps>> = ({
  studyId,
  stain,
  modelType,
  stainModelInferences,
  orchestrations,
  slides,
  selectedOrchestrations,
  setSelectedOrchestrations,
  selectedPostprocessedOrchestrations,
  setSelectedPostprocessedOrchestrations,
  modelPreferencesByModelId,
  setModelPreferencesByModelId,
  setSelectedOrchestrationsByClassificationType,
  setIntensityClassificationBinning,
}) => {
  const modelInferences = modelType ? stainModelInferences[modelType] : null;
  return (
    <Accordion disableGutters>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {modelTypesByApiModelValue[modelType]?.text ?? modelType}
      </AccordionSummary>
      <AccordionDetails>
        <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
          {!isEmpty(modelInferences)
            ? map(keys(modelInferences), (modelInferenceUrl) => (
                <ModelRow
                  key={modelInferenceUrl}
                  stain={stain}
                  modelType={modelType}
                  modelInferenceUrl={modelInferenceUrl}
                  stainModelInferences={stainModelInferences}
                  slides={slides}
                  selectedOrchestrations={selectedOrchestrations}
                  setSelectedOrchestrations={setSelectedOrchestrations}
                  studyId={studyId}
                  modelPreferencesByModelId={modelPreferencesByModelId}
                  setModelPreferencesByModelId={setModelPreferencesByModelId}
                  setSelectedOrchestrationsByClassificationType={setSelectedOrchestrationsByClassificationType}
                  setIntensityClassificationBinning={setIntensityClassificationBinning}
                />
              ))
            : !isEmpty(orchestrations)
            ? map(orchestrations, (orchestration) => {
                return (
                  <OrchestrationRow
                    key={orchestration.orchestrationId}
                    modelType={modelType}
                    orchestration={orchestration}
                    selectedOrchestrations={selectedPostprocessedOrchestrations}
                    onCheck={(newOrchestration: OrchestrationInference, slideIds: string[], checked: boolean) => {
                      setSelectedPostprocessedOrchestrations(slideIds, modelType, checked ? newOrchestration : null);
                    }}
                    slides={slides}
                    studyId={studyId}
                  />
                );
              })
            : null}
        </TreeView>
      </AccordionDetails>
    </Accordion>
  );
};

export default InferenceModel;
