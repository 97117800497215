import { DeckGLProps } from '@deck.gl/react/typed';
import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import {
  clamp,
  compact,
  Dictionary,
  filter,
  find,
  first,
  forEach,
  fromPairs,
  isEmpty,
  isEqual,
  keyBy,
  map,
  pickBy,
  reduce,
  reject,
  slice,
  times,
} from 'lodash';
import { useEffect, useMemo, useState, useTransition } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { BooleanParam, JsonParam, useQueryParam, useQueryParams } from 'use-query-params';

import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { ImagePyramid } from 'components/Procedure/useSlideImages';
import { calculateMagnificationFromZoom } from 'utils/slideTransformation';
import usePrevious from 'utils/usePrevious';
import { MAX_VIEWERS } from '../constants';
import { calculateRegistrationAndAngles } from '../registration';
import { OrthographicMapViewState } from './OrthographicMapview';
import {
  applyRegistrationToOtherViewerState,
  areViewStatesEqual,
  deckGLViewerStates,
  getInitialViewState,
} from './slidesViewerState';

export const didInteractWithViewer = times(MAX_VIEWERS, () => signal<boolean>(false));

export const getUrlViewStateKey = (viewerIndex: number) => `viewer${viewerIndex}ViewState`;

const useUrlViewStates = () =>
  useQueryParams(fromPairs(times(MAX_VIEWERS, (viewerIndex) => [getUrlViewStateKey(viewerIndex), JsonParam])));

const applyViewStateChanges = ({
  changedViewerStates,
  viewerStateValue,
  viewerSlideId,
  viewerIndex,
  newSlideState,
  currentViewerState,
}: {
  changedViewerStates: Dictionary<OrthographicMapViewState>[];
  viewerStateValue: Dictionary<OrthographicMapViewState>;
  viewerSlideId: string;
  viewerIndex: number;
  newSlideState: OrthographicMapViewState;
  currentViewerState: OrthographicMapViewState;
}) => {
  if (!areViewStatesEqual(currentViewerState, newSlideState)) {
    changedViewerStates[viewerIndex] = {
      ...viewerStateValue,
      [viewerSlideId]: newSlideState,
    };
  }
};

export const useDeckGLViewStates = ({
  slides,
  updateMagnificationFromZoom,
  viewSizes,
  slidesBaseImagePyramids,
  magnificationValue,
}: {
  slides: SlideWithChannelAndResults[];
  updateMagnificationFromZoom: any;
  viewSizes: Array<{ width: number; height: number }>;
  slidesBaseImagePyramids: Dictionary<ImagePyramid>;
  magnificationValue: number;
}) => {
  useSignals();

  const [ignoreRegistrationActive] = useQueryParam('ignoreRegistrationActive', BooleanParam);
  const previousIgnoreRegistrationActive = usePrevious(ignoreRegistrationActive);
  const [rotateToolActive] = useQueryParam('rotateToolActive', BooleanParam);
  const previousRotateToolActive = usePrevious(rotateToolActive);

  const [didSetInitialMagnification, setDidSetInitialMagnification] = useState(false);

  const slideRegistrationFields = useMemo(
    () =>
      map(compact(slides), (slide) => {
        const registrationProps = keyBy(
          map(compact(slides), (otherSlide) => ({
            otherSlideId: otherSlide.id,
            ...calculateRegistrationAndAngles([slide, otherSlide]),
          })),
          'otherSlideId'
        );

        const baseImagePyramid = slidesBaseImagePyramids[slide.id];
        if (!baseImagePyramid) {
          return null;
        }

        return {
          id: slide.id,
          viewerIndex: slide.viewerIndex,
          sizeCols: slide.sizeCols,
          registrationProps,
          maxResolution: slide.maxResolution ?? 1,
          minLevel: baseImagePyramid?.layerSource?.minLevel,
          maxLevel: baseImagePyramid?.layerSource?.maxLevel,
          imageSize: baseImagePyramid?.layerSource?.getImageSize() || { width: slide.sizeCols, height: slide.sizeRows },
        };
      }),
    [slides, slidesBaseImagePyramids]
  );

  const previousSlideRegistrationFields = usePrevious(slideRegistrationFields);
  const previousViewSizes = usePrevious(viewSizes);

  const [urlViewStates, setUrlViewStates] = useUrlViewStates();

  const debouncedSetUrlViewStates = useDebouncedCallback(setUrlViewStates, 500);

  useEffect(() => {
    if (isEqual(previousSlideRegistrationFields, slideRegistrationFields)) {
      return;
    }
    // Update the view state from the URL if there was no interaction with the viewer
    forEach(slideRegistrationFields, ({ id, viewerIndex }) => {
      const urlViewerSlideState = urlViewStates[getUrlViewStateKey(viewerIndex)]?.[id];
      if (!didInteractWithViewer[viewerIndex].value && urlViewerSlideState) {
        const currentViewerState = deckGLViewerStates[viewerIndex].value;
        deckGLViewerStates[viewerIndex].value = {
          ...currentViewerState,
          [id]: {
            ...currentViewerState?.[id],
            ...urlViewerSlideState,
          },
        };
      }
    });
  }, [slideRegistrationFields]);

  const [, startTransition] = useTransition();

  const syncViewStates = ({
    leadingViewer,
    didBearingChange,
    didZoomChange,
  }: {
    leadingViewer?: number;
    didBearingChange?: boolean;
    didZoomChange?: boolean;
  } = {}) => {
    const changedViewerStates: Dictionary<OrthographicMapViewState>[] = [];

    const didSwitchFromIgnoreAllRegistrationMode =
      (previousIgnoreRegistrationActive && previousIgnoreRegistrationActive !== ignoreRegistrationActive) ||
      (previousRotateToolActive && previousRotateToolActive !== rotateToolActive);

    const isRotationIgnored = ignoreRegistrationActive || rotateToolActive;
    const isZoomIgnored = ignoreRegistrationActive;

    forEach(slideRegistrationFields, (slide) => {
      if (!isNaN(leadingViewer) && leadingViewer !== slide.viewerIndex) {
        // Skip if not leading viewer
        return;
      }
      const currentViewerState =
        changedViewerStates?.[slide?.viewerIndex] || deckGLViewerStates[slide?.viewerIndex]?.value;
      const currentViewSlideState = currentViewerState?.[slide.id] || {};

      const baseViewStateForCurrentSlide = getInitialViewState({
        imageSize: slide.imageSize,
        maxLevel: slide.maxLevel,
        minLevel: slide.minLevel,
        viewSize: viewSizes[slide.viewerIndex],
        magnificationValue,
        maxResolution: slide.maxResolution,
      });

      const otherSlides = reject(slideRegistrationFields, { viewerIndex: slide.viewerIndex });
      if (
        !ignoreRegistrationActive &&
        !isEmpty(otherSlides) &&
        (!isEmpty(currentViewSlideState) || !isEqual(slideRegistrationFields, previousSlideRegistrationFields))
      ) {
        forEach(otherSlides, (otherSlide) => {
          const otherViewerStateValue =
            changedViewerStates?.[otherSlide?.viewerIndex] || deckGLViewerStates[otherSlide.viewerIndex]?.value;
          const otherViewerSlideState = otherViewerStateValue?.[otherSlide.id] || {};

          if (slide.id === otherSlide.id) {
            const newSlideState = {
              ...currentViewSlideState,
              ...(isRotationIgnored ? { bearing: otherViewerSlideState?.bearing } : {}),
              ...(isZoomIgnored ? { zoom: otherViewerSlideState?.zoom } : {}),
            };
            applyViewStateChanges({
              changedViewerStates,
              viewerStateValue: otherViewerStateValue,
              viewerSlideId: otherSlide.id,
              viewerIndex: otherSlide.viewerIndex,
              newSlideState,
              currentViewerState: otherViewerSlideState,
            });
            return;
          }

          const registrationProps = find(slide.registrationProps, { otherSlideId: otherSlide.id });

          const registration = registrationProps?.registration;
          const isRegisteredFromSlide = registrationProps?.registration?.registrationSlideId === otherSlide.id;

          const otherViewerBaseViewState = getInitialViewState({
            imageSize: otherSlide?.imageSize,
            maxLevel: otherSlide?.maxLevel,
            minLevel: otherSlide?.minLevel,
            isRegisteredFromSlide,
            registration,
            viewSize: viewSizes[otherSlide.viewerIndex],
            magnificationValue,
            maxResolution: slide.maxResolution,
          });
          const shouldApplyBearing =
            !isRotationIgnored &&
            (didSwitchFromIgnoreAllRegistrationMode ||
              didBearingChange ||
              isNaN(otherViewerSlideState?.bearing) ||
              otherViewerSlideState?.bearing === otherViewerBaseViewState.bearing);

          const shouldApplyZoom =
            !isZoomIgnored &&
            (didSwitchFromIgnoreAllRegistrationMode ||
              didZoomChange ||
              isNaN(otherViewerSlideState?.zoom as number) ||
              otherViewerSlideState?.zoom === otherViewerBaseViewState.zoom);

          const newSlideState = {
            ...applyRegistrationToOtherViewerState({
              isRegisteredFromSlide,
              registration,
              leadingSlideState: { ...baseViewStateForCurrentSlide, ...currentViewSlideState },
              otherSlideState: { ...otherViewerBaseViewState, ...otherViewerSlideState },
              leadingSlideMaxResolution: slide.maxResolution,
              otherSlideMaxResolution: otherSlide.maxResolution,
              shouldApplyBearing,
              shouldApplyZoom,
            }),
          };
          applyViewStateChanges({
            changedViewerStates,
            viewerStateValue: otherViewerStateValue,
            viewerSlideId: otherSlide.id,
            viewerIndex: otherSlide.viewerIndex,
            newSlideState,
            currentViewerState: otherViewerSlideState,
          });
        });
      } else {
        applyViewStateChanges({
          changedViewerStates,
          viewerStateValue: currentViewerState,
          viewerSlideId: slide.id,
          viewerIndex: slide.viewerIndex,
          newSlideState: { ...baseViewStateForCurrentSlide, ...(currentViewSlideState || {}) },
          currentViewerState: currentViewSlideState,
        });
      }
    });

    forEach(changedViewerStates, (viewerState, viewerIndex) => {
      if (viewerState) {
        // Apply updates to the viewer if the state has changed
        deckGLViewerStates[viewerIndex].value = viewerState;
      }
    });

    const slidesToUpdateUrls = filter(
      slideRegistrationFields,
      ({ viewerIndex }) => didInteractWithViewer[viewerIndex].value
    );
    const viewerStatesToUpdate = fromPairs(
      map(slidesToUpdateUrls, ({ id, viewerIndex }) => [
        getUrlViewStateKey(viewerIndex),
        {
          [id]: pickBy(
            deckGLViewerStates[viewerIndex].value?.[id],
            (value, key) => key !== 'transitionInterpolator' && typeof value !== 'function'
          ),
        },
      ])
    );
    startTransition(() => debouncedSetUrlViewStates(viewerStatesToUpdate, 'replaceIn'));
  };

  useEffect(() => {
    if (
      isEqual(previousSlideRegistrationFields, slideRegistrationFields) &&
      isEqual(previousViewSizes, viewSizes) &&
      previousIgnoreRegistrationActive === ignoreRegistrationActive &&
      previousRotateToolActive === rotateToolActive
    ) {
      return;
    }
    // Registration props changed, updating viewer states, including bearing to update the other viewer's angle for registered slides
    syncViewStates({ didBearingChange: true });

    const firstSlide = first(slideRegistrationFields);
    if (firstSlide && !didSetInitialMagnification) {
      // Set the initial magnification based on the first slide when the registration props change - to get an initial magnification value
      const deckGLViewerState = deckGLViewerStates[firstSlide.viewerIndex];
      const previousViewerState = deckGLViewerState.value;
      const currentSlideViewState = previousViewerState?.[firstSlide.id];

      const leadingViewerBaseViewState = getInitialViewState({
        imageSize: firstSlide?.imageSize,
        maxLevel: firstSlide?.maxLevel,
        minLevel: firstSlide?.minLevel,
        viewSize: viewSizes[firstSlide.viewerIndex],
        magnificationValue,
        maxResolution: firstSlide.maxResolution,
      });
      const newSlideViewStateWithUpdates: OrthographicMapViewState = {
        ...leadingViewerBaseViewState,
        ...(currentSlideViewState || {}),
      };
      const newMagnificationValue = calculateMagnificationFromZoom(
        newSlideViewStateWithUpdates.zoom as number,
        firstSlide.maxResolution
      );

      updateMagnificationFromZoom(newMagnificationValue);
      if (!didSetInitialMagnification) {
        setDidSetInitialMagnification(true);
      }
    }
  }, [
    //  Intentionally not exhaustive, we only want to resync when the registration props change or the view sizes change
    viewSizes,
    slideRegistrationFields,
    didSetInitialMagnification,
    ignoreRegistrationActive,
    previousIgnoreRegistrationActive,
    rotateToolActive,
    previousRotateToolActive,
  ]);

  const viewStateChangeHandlers: Dictionary<DeckGLProps['onViewStateChange']> = useMemo(
    () =>
      reduce(
        slideRegistrationFields,
        (acc, slide) => ({
          ...acc,
          [slide.viewerIndex]: ({ viewState: newViewState, interactionState }) => {
            if (
              !interactionState.isDragging &&
              !interactionState.isPanning &&
              !interactionState.isRotating &&
              !interactionState.isZooming
            ) {
              // Skip if the change is not due to interacting in one of the supported ways - i.e. changes made from outside the viewer for registration
              return;
            }

            if (didInteractWithViewer[slide.viewerIndex]) {
              didInteractWithViewer[slide.viewerIndex].value = true;
            }
            const deckGLViewerState = deckGLViewerStates[slide.viewerIndex];
            const previousViewerState = deckGLViewerState.value;
            const currentSlideViewState = previousViewerState?.[slide.id];
            const didBearingChange =
              isNaN(currentSlideViewState?.bearing) || currentSlideViewState?.bearing !== newViewState.bearing;

            const leadingViewerBaseViewState = getInitialViewState({
              imageSize: slide?.imageSize,
              maxLevel: slide?.maxLevel,
              minLevel: slide?.minLevel,
              viewSize: viewSizes[slide.viewerIndex],
              magnificationValue,
              maxResolution: slide.maxResolution,
            });
            const newSlideViewStateWithUpdates: OrthographicMapViewState = {
              ...leadingViewerBaseViewState,
              ...(currentSlideViewState || {}),
              ...newViewState,
            };

            const zoomBeforeClamp = newSlideViewStateWithUpdates.zoom;
            newSlideViewStateWithUpdates.zoom = clamp(
              zoomBeforeClamp as number,
              newSlideViewStateWithUpdates.minZoom,
              newSlideViewStateWithUpdates.maxZoom
            );

            const didZoomChange = currentSlideViewState?.zoom !== newSlideViewStateWithUpdates.zoom;

            // Avoid removing the slide from the view
            const imageSize = slide.imageSize;
            newSlideViewStateWithUpdates.target = [
              clamp(newSlideViewStateWithUpdates.target[0], 0, imageSize.width),
              clamp(newSlideViewStateWithUpdates.target[1], 0, imageSize.height),
              ...slice(newSlideViewStateWithUpdates.target, 2),
            ] as [number, number] | [number, number, number];

            if (areViewStatesEqual(currentSlideViewState, newSlideViewStateWithUpdates)) {
              // Skip if view state hasn't changed
              return;
            }
            deckGLViewerState.value = { ...previousViewerState, [slide.id]: newSlideViewStateWithUpdates };

            if (didZoomChange || !didSetInitialMagnification) {
              const newMagnificationValue = calculateMagnificationFromZoom(
                newSlideViewStateWithUpdates.zoom,
                slide.maxResolution
              );

              updateMagnificationFromZoom(newMagnificationValue);
              if (!didSetInitialMagnification) {
                setDidSetInitialMagnification(true);
              }
            }

            syncViewStates({ leadingViewer: slide.viewerIndex, didBearingChange, didZoomChange });
          },
        }),
        {} as Dictionary<DeckGLProps['onViewStateChange']>
      ),
    //  Intentionally not exhaustive, as we want to update the handlers when the magnification changes
    [
      ignoreRegistrationActive,
      rotateToolActive,
      viewSizes,
      JSON.stringify(slideRegistrationFields),
      updateMagnificationFromZoom,
      didSetInitialMagnification,
    ]
  );

  return { viewStateChangeHandlers, slideRegistrationFields };
};
