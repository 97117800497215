import { find, isEmpty, map } from 'lodash';
import React from 'react';

import { autocompleteCellEditorSxOverrides } from 'components/atoms/AutocompleteCellEditor';
import { ColorPickerButton } from 'components/atoms/ColorPicker';
import ColorEditInputCell from 'components/atoms/DataGridRenderers/ColorPicker';
import TaxonomySelectDropdown from 'components/atoms/Taxonomy/TaxonomySelectDropdown';
import TaxonomyTagMultiSelectDropdown from 'components/atoms/TaxonomyTag/TaxonomyTagMultiSelectDropdown';
import { DisplayedField } from 'interfaces/genericFields';
import { StainType } from 'interfaces/stainType';
import { Taxonomy } from 'interfaces/taxonomy';
import { TaxonomyTag } from 'interfaces/taxonomyTag';
import useTaxonomy from 'utils/queryHooks/taxonomy/useTaxonomy';
import useTaxonomyTags from 'utils/queryHooks/taxonomy/useTaxonomyTags';
import { useStainTypeOptions } from 'utils/queryHooks/uiConstantsHooks';
import { CellColorMapping } from '.';

export interface CellColorMappingFieldsContext {
  taxonomies: Taxonomy[];
  stainTypes: StainType[];
  stainTypesNotDeprecated: Array<{ id: string; displayName: string }>;
  taxonomyTags: TaxonomyTag[];
}

export const useCellColorMappingFieldsContext = ({
  enabled = true,
}: {
  enabled?: boolean;
} = {}): CellColorMappingFieldsContext => {
  const { data: taxonomies } = useTaxonomy();
  const { stainTypeOptions, stainTypeOptionsWithoutDeprecated } = useStainTypeOptions({ enabled });
  const { data: taxonomyTags } = useTaxonomyTags();

  return {
    taxonomies,
    stainTypes: stainTypeOptions,
    stainTypesNotDeprecated: stainTypeOptionsWithoutDeprecated,
    taxonomyTags,
  };
};

export const cellClassIdField: DisplayedField<CellColorMapping, string, CellColorMappingFieldsContext> = {
  cellEditType: 'select',
  filterType: 'multiSelect',
  dataKey: 'cellClassId',
  label: 'Cell Class',
  columnWidth: { min: 120, flex: 2 },
  customCellEditor: () => (params) => {
    const { value } = params;
    const onSelectTaxonomyId = (selectedTaxonomyId: string) => {
      params.api.setEditCellValue({
        id: params.id,
        field: params.field,
        value: selectedTaxonomyId,
      });
    };

    return (
      <TaxonomySelectDropdown
        sx={autocompleteCellEditorSxOverrides}
        selectedTaxonomyId={value}
        onSelectTaxonomyId={onSelectTaxonomyId}
      />
    );
  },
  valueFormatter: ({ value }, context) => {
    const taxonomies = context?.taxonomies ?? [];
    if (!value) {
      return 'No cell class';
    }

    return find(taxonomies, (taxonomy) => taxonomy.path === value)?.name || value;
  },
  display: 'flex',
};

export const tagsField: DisplayedField<CellColorMapping, string[], CellColorMappingFieldsContext> = {
  cellEditType: 'multiSelect',
  filterType: 'multiSelect',
  dataKey: 'tags',
  label: 'Tags',
  columnWidth: { min: 220, flex: 3 },
  customCellEditor: () => (params) => {
    const { id, value, api } = params;

    const currentCellClassId = api.getRowWithUpdatedValues(id, 'cellClassId')?.cellClassId;

    const onSelectTaxonomyTagIds = (selectedTaxonomyTagIds: string[]) => {
      params.api.setEditCellValue({
        id: params.id,
        field: params.field,
        value: selectedTaxonomyTagIds,
      });
    };

    return (
      <TaxonomyTagMultiSelectDropdown
        sx={autocompleteCellEditorSxOverrides}
        cellTaxonomy={currentCellClassId}
        selectedTaxonomyTagIds={value}
        onSelectTaxonomyTagIds={onSelectTaxonomyTagIds}
      />
    );
  },
  valueFormatter: ({ value }, context) => {
    const taxonomyTags = context?.taxonomyTags ?? [];
    if (!value || isEmpty(value)) {
      return 'No tags';
    }

    return map(value, (tag) => find(taxonomyTags, { id: tag })?.displayName || tag).join(', ');
  },
  display: 'flex',
};

export const stainTypeIdField: DisplayedField<CellColorMapping, string, CellColorMappingFieldsContext> = {
  cellEditType: 'select',
  filterType: 'multiSelect',
  dataKey: 'stainTypeId',
  label: 'Stain Type',
  valueFormatter: ({ value: stainTypeId }, context) =>
    (find(context.stainTypes, { id: stainTypeId }) as StainType)?.displayName || stainTypeId,
  getError: ({ value, context }) => {
    if (value && !find(context?.stainTypes, { id: value })) return `Invalid Stain Type`;
    if (value && !find(context?.stainTypesNotDeprecated, { id: value })) return `Deprecated Stain Type`;
  },
  columnWidth: { min: 80, flex: 2 },
  optionsGetter: (context) => {
    return map(context.stainTypesNotDeprecated, (stainType) => ({
      value: stainType.id,
      label: stainType.displayName,
    }));
  },
  display: 'flex',
};

export const colorField: DisplayedField<CellColorMapping, string, CellColorMappingFieldsContext> = {
  cellEditType: 'text',
  filterType: 'text',
  dataKey: 'color',
  label: 'Color',
  customCellEditor: () => (params) => <ColorEditInputCell {...params} />,
  renderCell: () => (params) =>
    <ColorPickerButton hexColor={params.value} sx={{ mt: 'auto', mb: 'auto', mr: 'auto', mx: 'auto' }} />,
  columnWidth: { min: 20 },
  display: 'flex',
};

export const cellColorMappingFields: DisplayedField<CellColorMapping, any, CellColorMappingFieldsContext>[] = [
  colorField,
  cellClassIdField,
  tagsField,
  stainTypeIdField,
];
