import DeleteIcon from '@mui/icons-material/Delete';
import DrawIcon from '@mui/icons-material/Draw';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import PanToolIcon from '@mui/icons-material/PanTool';
import PushPinIcon from '@mui/icons-material/PushPin';
import SquareIcon from '@mui/icons-material/Square';
import { keys, map } from 'lodash';

import { SlideInteractionMenuOption } from './SlideInteractionOption';

export enum SlideAnnotationEditType {
  Delete = 'delete',
  Modify = 'modify',
  Polygon = 'polygon',
  Roi = 'roi',
  Point = 'point',
  Move = 'move',
  Select = 'select',
}

export const RoiFixedSizes: { [key: string]: [number, number] } = {
  ['100 x 100']: [100, 100],
  ['120 x 120']: [120, 120],
  ['150 x 150']: [150, 150],
  ['300 x 300']: [300, 300],
};

// This is an internal type that we save inside properties of a feature.
// It is taken from the legacy backoffice codebase.
export enum ShapeSubTypes {
  Polygon = 'polygon',
  Rect = 'rect',
  Point = 'circle',
}

export const editTypeToShapeSubTypeMap = {
  [SlideAnnotationEditType.Polygon]: ShapeSubTypes.Polygon,
  [SlideAnnotationEditType.Roi]: ShapeSubTypes.Rect,
  [SlideAnnotationEditType.Point]: ShapeSubTypes.Point,
};

export const shapeSubTypeToEditTypeMap = {
  [ShapeSubTypes.Polygon]: SlideAnnotationEditType.Polygon,
  [ShapeSubTypes.Rect]: SlideAnnotationEditType.Roi,
  [ShapeSubTypes.Point]: SlideAnnotationEditType.Point,
};

export const slideAnnotationOptions: SlideInteractionMenuOption[] = [
  {
    editType: SlideAnnotationEditType.Delete,
    title: 'Delete Annotations',
    icon: DeleteIcon,
  },
  {
    editType: SlideAnnotationEditType.Modify,
    title: 'Modify Annotations',
    icon: DriveFileRenameOutlineIcon,
  },
  {
    editType: SlideAnnotationEditType.Move,
    title: 'Move Annotations',
    icon: PanToolIcon,
  },
  {
    editType: SlideAnnotationEditType.Polygon,
    title: 'Polygon',
    icon: DrawIcon,
  },
  {
    editType: SlideAnnotationEditType.Roi,
    title: 'ROI Selection',
    icon: SquareIcon,
    subOptions: map(keys(RoiFixedSizes), (title) => ({
      subEditType: title,
      title: title,
    })),
  },
  {
    editType: SlideAnnotationEditType.Point,
    title: 'Point',
    icon: PushPinIcon,
  },
  {
    editType: SlideAnnotationEditType.Select,
    title: 'Select multiple annotations',
    icon: HighlightAltIcon,
  },
];
