import { useSignals } from '@preact/signals-react/runtime';
import { forEach, isEmpty, isEqual } from 'lodash';
import { useEffect, useState, useTransition } from 'react';

import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { useAreaTypes } from 'utils/queryHooks/useAreaTypes';
import usePrevious from 'utils/usePrevious';
import {
  LayerVisualizationChange,
  slidesLayerVisualizationSettings,
  useApplyChangesToSlideLayerVisualizationSettings,
  useGetLayerSettingsFromUrl,
} from '../../../slidesVisualizationAndConfiguration';
import { computeDefaultPmtLayerSettings, getPmtLayerId } from './ProtomapTileControl';

export const useUpdatePmtHeatmapsSettingsOnChange = ({
  slideId,
  viewerIndex,
  stainTypeId,
  layerIdsToUrlKeys,
  pmtHeatmaps,
}: {
  slideId: string;
  viewerIndex: number;
  stainTypeId: string;
  layerIdsToUrlKeys?: Record<string, string>;
  pmtHeatmaps: FeatureMetadata[];
}) => {
  useSignals();
  const getLayerSettingsFromUrl = useGetLayerSettingsFromUrl();
  const applyChangesToSlideLayerVisualizationSettings = useApplyChangesToSlideLayerVisualizationSettings();

  const { data: areaTypes, isLoading: isLoadingAreaTypes } = useAreaTypes();

  const previousSlideParams = usePrevious({ slideId, viewerIndex, pmtHeatmaps });
  const [didApplyInitialSettings, setDidApplyInitialSettings] = useState(false);
  const [, startTransition] = useTransition();
  useEffect(() => {
    if (
      isLoadingAreaTypes ||
      !slideId ||
      (didApplyInitialSettings &&
        previousSlideParams?.slideId === slideId &&
        previousSlideParams?.viewerIndex === viewerIndex &&
        isEqual(previousSlideParams?.pmtHeatmaps, pmtHeatmaps))
    ) {
      return;
    }
    setDidApplyInitialSettings(false);
    const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];
    const changes: LayerVisualizationChange[] = [];
    forEach(pmtHeatmaps, (pmtHeatmap) => {
      const heatmapOptions = pmtHeatmap?.nestedItems || [];
      if (!isEmpty(heatmapOptions)) {
        forEach(heatmapOptions, (heatmapOption, layerIndex) => {
          const layerName = heatmapOption?.key;
          const computedLayerSettings = computeDefaultPmtLayerSettings({
            pmtHeatmap,
            layerIndex,
            layerName,
            areaTypes,
          });
          const layerSettingsKey = getPmtLayerId(pmtHeatmap, layerName);
          const previousSettings = viewerSlideLayerVisualizationSettings?.value?.[slideId]?.[layerSettingsKey]?.value;
          const layerUrlKey = layerIdsToUrlKeys?.[layerSettingsKey] || layerSettingsKey;
          const urlSettings = getLayerSettingsFromUrl({ layerUrlKey, stainTypeId, viewerIndex });
          const newSettings = {
            ...computedLayerSettings,
            ...(Boolean(previousSettings) && previousSettings),
            ...urlSettings,
          };

          // If we couldn't compute the color before, assign it now
          if (newSettings?.colorByIndex === newSettings?.color && computedLayerSettings?.color) {
            newSettings.color = computedLayerSettings?.color;
          }
          changes.push({ layerId: layerSettingsKey, newSettings });
        });
      }
    });
    if (isEmpty(changes)) {
      return;
    }
    applyChangesToSlideLayerVisualizationSettings({
      slideId,
      viewerIndex,
      changes,
      skipUrlUpdate: true,
      changeFlow: `PmtHeatmaps-initial`,
    });
    startTransition(() => {
      setDidApplyInitialSettings(true);
    });
  }, [
    viewerIndex,
    slideId,
    stainTypeId,
    pmtHeatmaps,
    layerIdsToUrlKeys,
    areaTypes,
    isLoadingAreaTypes,
    getLayerSettingsFromUrl,
  ]);

  return { didApplyInitialSettings };
};
