import { CellRulePanelWithRules } from 'interfaces/cellRule';
import { CellRules } from 'interfaces/jobs/multiplex/cellTypingParams';
import { forEach, isEmpty } from 'lodash';

export function convertToCellRules(panel: CellRulePanelWithRules): CellRules | null {
  const cellRules: CellRules = {};

  forEach(panel?.rules, (rule) => {
    const { cellTypeId, stainTypeId, ruleValue } = rule;
    // we don't need the whole tree of cell types, just the last part
    const cellType = cellTypeId.split('.').pop();

    if (!cellRules[cellType]) {
      cellRules[cellType] = {};
    }

    cellRules[cellType][stainTypeId] = ruleValue;
  });

  if (isEmpty(cellRules)) {
    // this is for the validation schema
    return null;
  }

  return cellRules;
}
