import { deepClone } from '@mui/x-data-grid/utils/utils';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { clone, compact, filter, forEach, includes, isEmpty, map } from 'lodash';
import { enqueueSnackbar } from 'notistack';

import {
  archiveExperimentResult,
  bulkUpdateResultsByExperimentResultIds,
  bulkUpdateResultsByOrchestration,
  restoreExperimentResult,
} from 'api/experimentResults';
import { getSlideChannelNormalizationsQueryKey } from 'api/slideMultiplexChannelNormalizations';
import { getStudyProcedureQueryKey } from 'api/study';
import { ExperimentResult, ExperimentResultUpdate, ResultType } from 'interfaces/experimentResults';
import { Procedure } from 'interfaces/procedure';
import moment from 'moment';
import queryClient from 'utils/queryClient';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useEncodedFilters } from 'utils/useEncodedFilters';

export type ArchiveAction = 'archive' | 'restore';

const replaceSlideResultsWithUpdatedResults = ({
  orchestrationId,
  experimentResultIds,
  resultTypes: explicitResultTypes,
  flowClassNames: explicitFlowClassNames,
  archiveAction,
  resultUpdates,
  experimentResults,
}: {
  orchestrationId?: string;
  experimentResultIds?: number[];
  resultTypes?: string[];
  flowClassNames?: string[];
  archiveAction?: ArchiveAction;
  resultUpdates?: Partial<ExperimentResultUpdate>;
  experimentResults?: ExperimentResult[];
}): ExperimentResult[] => {
  // We may have to change other results matching the types of the updated results' orchestration, so we'll get them here
  const resultsWithUpdates = filter(compact(experimentResults), (result) =>
    experimentResultIds
      ? includes(experimentResultIds, result.experimentResultId)
      : orchestrationId && result.orchestrationId === orchestrationId
  );

  const resultWithUpdatesIds = map(resultsWithUpdates, 'experimentResultId');

  // If we provided explicit filters, we only want to update the results that match the filters
  // Otherwise, we may want to update all results that have the same same type as the selected results
  const resultTypes = explicitFlowClassNames
    ? explicitResultTypes || []
    : explicitResultTypes || map(resultsWithUpdates, 'resultType');
  const flowClassNames = explicitResultTypes
    ? explicitFlowClassNames || []
    : explicitFlowClassNames || map(resultsWithUpdates, 'flowClassName');

  return map(experimentResults, (oldResult) => {
    if (!includes(resultTypes, oldResult.resultType) && !includes(flowClassNames, oldResult.flowClassName)) {
      // If the result is not of the correct type, we won't be affecting it
      return oldResult;
    }

    const result = clone(oldResult);

    if (includes(resultWithUpdatesIds, result.experimentResultId)) {
      if (archiveAction) {
        result.deletedAt = archiveAction === 'archive' ? moment().toISOString() : null;
        result.deletedBy = archiveAction === 'archive' ? 'user' : null;
        return { ...result };
      } else {
        return { ...result, ...resultUpdates };
      }
    }

    // If we are approving, we want to unapproved all previous approved results, except for normalization results
    if (
      resultUpdates &&
      // If we approved a new result
      resultUpdates.approved &&
      // If the result is already approved
      result.approved &&
      // If the update is not just for normalization results
      !includes(resultTypes, ResultType.Normalization)
    ) {
      result.approved = false;
    }
    if (
      resultUpdates &&
      // If we internally approving a new result
      resultUpdates.internallyApproved &&
      // If the result is already internally approved
      result.internallyApproved &&
      // If the update is not just for normalization results
      !isEmpty(resultTypes) &&
      includes(resultTypes, ResultType.Normalization)
    ) {
      result.internallyApproved = false;
    }

    return result;
  });
};

const replaceProcedureSlidesResultsWithUpdatedResults = ({
  orchestrationId,
  experimentResultIds,
  resultTypes: explicitResultTypes,
  flowClassNames: explicitFlowClassNames,
  slideIds,
  archiveAction,
  resultUpdates,
  procedure,
}: {
  orchestrationId?: string;
  experimentResultIds?: number[];
  resultTypes?: string[];
  flowClassNames?: string[];
  slideIds?: string[];
  archiveAction?: ArchiveAction;
  resultUpdates?: Partial<ExperimentResultUpdate>;
  procedure: Procedure;
}) => {
  if (archiveAction && resultUpdates) {
    console.warn('Cannot archive and update results at the same time');
    return;
  }
  const updatedProcedure: Procedure = deepClone(procedure);

  updatedProcedure.slides = map(updatedProcedure.slides, (slide) => {
    const newResults =
      !isEmpty(slideIds) && !includes(slideIds, slide.id)
        ? slide.experimentResults
        : replaceSlideResultsWithUpdatedResults({
            orchestrationId,
            experimentResultIds,
            resultTypes: explicitResultTypes,
            flowClassNames: explicitFlowClassNames,
            archiveAction,
            resultUpdates,
            experimentResults: slide.experimentResults,
          });
    return { ...slide, experimentResults: newResults };
  });

  return {
    procedure: updatedProcedure,
  };
};

// Case ID is currently required for the API call
const useResultsMutation = (caseId: number, studyId: string, onSuccess?: () => void) => {
  const { labId } = useCurrentLabId();

  const { queryParams } = useEncodedFilters();
  const procedureQueryKey = getStudyProcedureQueryKey(queryParams?.filters?.studyId, caseId, queryParams);

  const mutationOptions: Omit<UseMutationOptions<unknown, any, any, any>, 'mutationFn'> = {
    onError: (err, _, context: any) => {
      enqueueSnackbar('Failed to update results', { variant: 'error' });
      queryClient.setQueryData(procedureQueryKey, context.previousValue);
    },
    onSuccess: () => {
      queryClient.resetQueries(['procedures']);

      enqueueSnackbar('Successfully updated results', { variant: 'success' });
      queryClient.invalidateQueries({ queryKey: ['procedure', caseId], exact: false });
      queryClient.invalidateQueries(['orchestrations']);
      queryClient.invalidateQueries(['normalizations']);
      onSuccess?.();
    },
  };

  const resultsByOrchestrationMutation = useMutation(bulkUpdateResultsByOrchestration, {
    onMutate: async ({ orchestrationId, slideIds, updatedData: resultUpdates, flowClassNames, resultTypes }) => {
      await queryClient.cancelQueries({ queryKey: ['procedure', caseId], exact: false });
      const previousValue = queryClient.getQueryData(procedureQueryKey);

      if (previousValue) {
        queryClient.setQueryData(procedureQueryKey, ({ procedure }: { procedure: Procedure }) =>
          replaceProcedureSlidesResultsWithUpdatedResults({
            orchestrationId,
            slideIds,
            resultUpdates,
            procedure,
            flowClassNames,
            resultTypes,
          })
        );
      }
    },
    ...mutationOptions,
  });

  const resultsByExperimentResultIdsMutation = useMutation(bulkUpdateResultsByExperimentResultIds, {
    onMutate: async ({ experimentResultIds, slideIds, updatedData: resultUpdates }) => {
      await queryClient.cancelQueries({ queryKey: ['procedure', caseId], exact: false });
      const previousProcedureData = queryClient.getQueryData(procedureQueryKey);

      if (previousProcedureData) {
        queryClient.setQueryData(procedureQueryKey, ({ procedure }: { procedure: Procedure }) =>
          replaceProcedureSlidesResultsWithUpdatedResults({
            experimentResultIds,
            slideIds,
            resultUpdates,
            procedure,
          })
        );
      }

      forEach(slideIds, (slideId) => {
        const previousNormalizationsData = queryClient.getQueryData(
          getSlideChannelNormalizationsQueryKey({ id: slideId, labId })
        );
        if (previousNormalizationsData) {
          queryClient.setQueryData(
            getSlideChannelNormalizationsQueryKey({ id: slideId, labId }),
            (normalizations: ExperimentResult[]) => {
              return replaceSlideResultsWithUpdatedResults({
                experimentResultIds,
                resultUpdates,
                experimentResults: normalizations,
              });
            }
          );
        }
      });
    },
    ...mutationOptions,
  });

  const archiveExperimentResultsMutation = useMutation(archiveExperimentResult, {
    onMutate: async ({ experimentResultIds }) => {
      await queryClient.cancelQueries({ queryKey: ['procedure', caseId], exact: false });
      const previousValue = queryClient.getQueryData(procedureQueryKey);

      if (previousValue) {
        queryClient.setQueryData(procedureQueryKey, ({ procedure }: { procedure: Procedure }) =>
          replaceProcedureSlidesResultsWithUpdatedResults({
            experimentResultIds,
            archiveAction: 'archive',
            procedure,
          })
        );
      }
      return { previousValue };
    },
    ...mutationOptions,
  });

  const restoreExperimentResultsMutation = useMutation(restoreExperimentResult, {
    onMutate: async ({ experimentResultIds }) => {
      await queryClient.cancelQueries({ queryKey: ['procedure', caseId], exact: false });
      const previousValue = queryClient.getQueryData(procedureQueryKey);

      if (previousValue) {
        queryClient.setQueryData(procedureQueryKey, ({ procedure }: { procedure: Procedure }) =>
          replaceProcedureSlidesResultsWithUpdatedResults({
            experimentResultIds,
            archiveAction: 'restore',
            procedure,
          })
        );
      }
      return { previousValue };
    },
    ...mutationOptions,
  });

  const handleUpdate = ({
    orchestrationId,
    experimentResultIds,
    slideId,
    updatedData,
    flowClassNames,
    resultTypes,
  }: {
    orchestrationId?: string;
    experimentResultIds?: number[];
    slideId: string;
    updatedData: ExperimentResultUpdate;
    flowClassNames?: string[];
    resultTypes?: string[];
  }) => {
    // Case ID is required for clearing the cache
    if (isNaN(Number(caseId))) {
      console.error('A valid case ID is required, got', { caseId });
      return;
    }
    if (!studyId) {
      console.error('Study ID is required');
      return;
    }
    if (!slideId) {
      console.error('Slide ID is required');
      return;
    }
    if (!orchestrationId && !experimentResultIds) {
      console.error('Orchestration ID or Experiment Result IDs are required');
      return;
    } else if (orchestrationId && experimentResultIds) {
      console.error('Only one of Orchestration ID or Experiment Result IDs is required');
      return;
    }

    if (orchestrationId) {
      return resultsByOrchestrationMutation.mutateAsync({
        slideIds: [slideId],
        studyId,
        orchestrationId,
        updatedData,
        flowClassNames,
        resultTypes,
        labId,
      });
    } else {
      return resultsByExperimentResultIdsMutation.mutateAsync({
        slideIds: [slideId],
        studyId,
        experimentResultIds,
        updatedData,
        labId,
      });
    }
  };

  function handleFieldSave<T extends keyof ExperimentResultUpdate>({
    orchestrationId,
    experimentResultIds,
    slideId,
    fieldName,
    value,
    flowClassNames,
    resultTypes,
  }: {
    orchestrationId?: string;
    experimentResultIds?: number[];
    slideId: string;
    fieldName: T;
    value: ExperimentResultUpdate[T];
    flowClassNames?: string[];
    resultTypes?: string[];
  }) {
    const updatedData: ExperimentResultUpdate = { [fieldName]: value };
    return handleUpdate({ orchestrationId, experimentResultIds, slideId, updatedData, flowClassNames, resultTypes });
  }

  const handleArchiveAndRestore = ({
    experimentResultIds,
    archiveAction,
  }: {
    experimentResultIds: number[];
    archiveAction: ArchiveAction;
  }) => {
    // Case ID is currently required for the API call
    if (isNaN(Number(caseId))) {
      console.error('A valid case ID is required, got', { caseId });
      return;
    }
    if (!studyId) {
      console.error('Study ID is required');
      return;
    }

    if (archiveAction === 'archive') {
      archiveExperimentResultsMutation.mutate({ studyId, experimentResultIds, labId });
    } else if (archiveAction === 'restore') {
      restoreExperimentResultsMutation.mutate({ studyId, experimentResultIds, labId });
    } else {
      console.error('Invalid archive action', { archiveAction });
    }
  };

  const isLoading =
    archiveExperimentResultsMutation.isLoading ||
    restoreExperimentResultsMutation.isLoading ||
    resultsByOrchestrationMutation.isLoading ||
    resultsByExperimentResultIdsMutation.isLoading;

  return { handleFieldSave, handleArchiveAndRestore, isLoading };
};

export default useResultsMutation;
