import { GridRowId } from '@mui/x-data-grid';
import { filter, includes, map, toString } from 'lodash';
import React from 'react';
import { DelimitedArrayParam, StringParam, useQueryParam } from 'use-query-params';

export interface RowSelectionContextProps {
  allSelected: (numRows: number) => boolean;
  noneSelected: (numRows: number) => boolean;
  someSelected: (numRows: number) => boolean;
  selectedRows: GridRowId[];
  omittedRows: GridRowId[];
  toggleSelection: (row: GridRowId) => void;
  selectionMode: 'select' | 'omit';
  setSelectionMode: (mode: 'select' | 'omit') => void;
  clearSelection: () => void;
  selectAll: () => void;
  isRowSelected: (row: GridRowId) => boolean;
  selectedRowsCount: (numRows: number) => number;
}

interface RowSelectionContextProviderProps {
  children?: React.ReactNode;
  urlParamPrefix?: string;
  convertIdFromString?: (id: string) => GridRowId;
}

const RowSelectionContext = React.createContext<RowSelectionContextProps | undefined>(undefined);

const toggleRowIdSelection = (
  rowId: GridRowId,
  rowsBeforeConversion: any[],
  convertIdFromString?: (id: string) => GridRowId
) => {
  const rows: GridRowId[] = convertIdFromString
    ? map(rowsBeforeConversion, (id) => convertIdFromString(id))
    : rowsBeforeConversion;
  return includes(rows, rowId) ? filter(rows, (r) => r !== rowId) : [...rows, rowId];
};

type SelectionMode = 'select' | 'omit';

const defaultSelectionMode: SelectionMode = 'select';

export const RowSelectionContextProvider: React.FunctionComponent<
  React.PropsWithChildren<RowSelectionContextProviderProps>
> = ({ children, urlParamPrefix, convertIdFromString }: RowSelectionContextProviderProps) => {
  const [selectionModeState, setSelectionModeState] = React.useState<SelectionMode>(defaultSelectionMode);
  const [selectedRowsState, setSelectedRowsState] = React.useState<GridRowId[]>([]);
  const [omittedRowsState, setOmittedRowsState] = React.useState<GridRowId[]>([]);

  const [selectionModeUrl, setSelectionModeUrl] = useQueryParam(`${urlParamPrefix}SelectionMode`, StringParam);
  const [selectedRowsUrl, setSelectedRowsUrl] = useQueryParam(`${urlParamPrefix}SelectedRows`, DelimitedArrayParam);
  const [omittedRowsUrl, setOmittedRowsUrl] = useQueryParam(`${urlParamPrefix}OmittedRows`, DelimitedArrayParam);

  const { selectionMode, setSelectionMode, selectedRows, setSelectedRows, omittedRows, setOmittedRows } =
    React.useMemo((): {
      selectionMode: SelectionMode;
      setSelectionMode: (mode: SelectionMode) => void;
      selectedRows: GridRowId[];
      setSelectedRows: React.Dispatch<React.SetStateAction<GridRowId[]>>;
      omittedRows: GridRowId[];
      setOmittedRows: React.Dispatch<React.SetStateAction<GridRowId[]>>;
    } => {
      const isUrlSelectionModeValid = includes(['select', 'omit'], selectionModeUrl);
      const innerSelectionMode: SelectionMode = urlParamPrefix
        ? isUrlSelectionModeValid
          ? (selectionModeUrl as SelectionMode)
          : defaultSelectionMode
        : selectionModeState;

      const innerSetSelectionMode = urlParamPrefix
        ? (mode: SelectionMode) => setSelectionModeUrl(mode, 'replaceIn')
        : setSelectionModeState;

      const innerSelectedRows = urlParamPrefix
        ? map(selectedRowsUrl, (id) => convertIdFromString?.(id) ?? id)
        : selectedRowsState;
      const innerSetSelectedRows: React.Dispatch<React.SetStateAction<GridRowId[]>> = urlParamPrefix
        ? (param: React.SetStateAction<GridRowId[]>) => {
            const newIds = map(typeof param === 'function' ? param(innerSelectedRows) : param, toString);
            setSelectedRowsUrl(newIds, 'replaceIn');
          }
        : setSelectedRowsState;

      const innerOmittedRows = urlParamPrefix
        ? map(omittedRowsUrl, (id) => convertIdFromString?.(id) ?? id)
        : omittedRowsState;

      const innerSetOmittedRows: React.Dispatch<React.SetStateAction<GridRowId[]>> = urlParamPrefix
        ? (param: React.SetStateAction<GridRowId[]>) => {
            const newIds = map(typeof param === 'function' ? param(omittedRows) : param, toString);
            setOmittedRowsUrl(newIds, 'replaceIn');
          }
        : setOmittedRowsState;

      return {
        selectionMode: innerSelectionMode,
        setSelectionMode: innerSetSelectionMode,
        selectedRows: innerSelectedRows,
        setSelectedRows: innerSetSelectedRows,
        omittedRows: innerOmittedRows,
        setOmittedRows: innerSetOmittedRows,
      };
    }, [
      urlParamPrefix,
      selectionModeUrl,
      setSelectionModeUrl,
      selectedRowsUrl,
      setSelectedRowsUrl,
      omittedRowsUrl,
      setOmittedRowsUrl,
      selectionModeState,
      selectedRowsState,
      omittedRowsState,
      convertIdFromString,
    ]);

  const contextValue: RowSelectionContextProps = React.useMemo(
    () => ({
      allSelected: (numRows: number) =>
        numRows > 0 && (selectionMode === 'select' ? selectedRows.length === numRows : omittedRows.length === 0),
      noneSelected: (numRows: number) =>
        numRows > 0 && (selectionMode === 'select' ? selectedRows.length === 0 : omittedRows.length === numRows),
      someSelected: (numRows: number) =>
        numRows > 0 && (selectionMode === 'select' ? selectedRows.length > 0 : omittedRows.length < numRows),
      toggleSelection: (row: GridRowId) => {
        if (selectionMode === 'select') {
          setSelectedRows((prev) => toggleRowIdSelection(row, prev, convertIdFromString));
        } else {
          setOmittedRows((prev) => toggleRowIdSelection(row, prev, convertIdFromString));
        }
      },
      selectedRows: selectedRows.sort(),
      omittedRows: omittedRows.sort(),
      selectedRowsCount: (numRows: number) =>
        selectionMode === 'select' ? selectedRows.length : numRows - omittedRows.length,
      selectionMode,
      setSelectionMode,
      selectAll: () => {
        setSelectionMode('omit');
        setOmittedRows([]);
      },
      clearSelection: () => {
        setSelectionMode('select');
        setSelectedRows([]);
      },
      isRowSelected: (row: GridRowId) => {
        if (selectionMode === 'select') {
          return includes(selectedRows, row);
        } else {
          return !includes(omittedRows, row);
        }
      },
    }),
    [selectedRows, setSelectedRows, omittedRows, setOmittedRows, selectionMode, setSelectionMode]
  );

  return <RowSelectionContext.Provider value={contextValue}>{children}</RowSelectionContext.Provider>;
};

export const useRowSelectionContextOptional = () =>
  React.useContext(RowSelectionContext as React.Context<RowSelectionContextProps>);

export const useRowSelectionContext = () => {
  const context = useRowSelectionContextOptional();
  if (context === undefined) {
    throw new Error('useRowSelectionContext must be used within a RowSelectionContextProvider');
  }
  return context;
};
