import { Grid, Skeleton, Typography } from '@mui/material';
import CellRulesDataGrid from 'components/Pages/OperationsSettings/CellRulesDataGrid';
import CellRulePanelDropdown from 'components/Pages/OperationsSettings/CellRulesDataGrid/CellRulePanel/CellRulePanelDropdown';
import {
  useCellTypesUsedByPanel,
  useStainTypesUsedByPanel,
} from 'components/Pages/OperationsSettings/CellRulesDataGrid/panel.util';
import { last, map, orderBy, size } from 'lodash';
import React, { ReactElement, useEffect } from 'react';
import useCellRulePanels from 'utils/queryHooks/cellRule/useCellRulePanels';
import useCellRules from 'utils/queryHooks/cellRule/useCellRules';

export interface CellRulesPanelSelectProps {
  studyId: string;
  selectedPanelId: string | null;
  onSelectPanel: (panelId: string) => void;
  isRequired?: boolean;
  showRequiredError?: boolean;
  autoAssignLastPanel?: boolean;
}

export const CellRulesPanelSelect = (props: CellRulesPanelSelectProps): ReactElement => {
  const { studyId, selectedPanelId, onSelectPanel, isRequired, showRequiredError, autoAssignLastPanel } = props;

  const { data: panels, isLoading: isLoadingPanels, isError: isErrorLoadingPanels } = useCellRulePanels(studyId);

  useEffect(() => {
    if (size(panels) > 0 && !selectedPanelId && autoAssignLastPanel) {
      onSelectPanel(last(orderBy(panels, 'createdAt', 'desc')).id);
    }
  }, [panels]);

  const {
    data: panel,
    isLoading: isLoadingPanel,
    isError: isErrorLoadingPanel,
  } = useCellRules(selectedPanelId, studyId);

  const usedCellTypes = useCellTypesUsedByPanel(panel);
  const usedStainTypes = useStainTypesUsedByPanel(panel);

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        {isErrorLoadingPanels ? (
          <Typography variant="body1" color="error">
            Error loading panels
          </Typography>
        ) : (
          <CellRulePanelDropdown
            panels={panels ?? []}
            selectedPanelId={selectedPanelId}
            onSelectPanel={onSelectPanel}
            isLoading={isLoadingPanels}
            studyId={studyId}
            textFieldProps={{
              error: showRequiredError && isRequired && !selectedPanelId,
              helperText: showRequiredError && isRequired && !selectedPanelId ? 'Panel is required' : undefined,
              required: isRequired,
            }}
          />
        )}
      </Grid>
      {selectedPanelId && (
        <Grid item>
          {isLoadingPanel ? (
            <Skeleton variant="rectangular" width="100%" height={300} />
          ) : isErrorLoadingPanel ? (
            <Typography variant="body1" color="error">
              Error loading panel
            </Typography>
          ) : (
            <CellRulesDataGrid
              isLoading={isLoadingPanel || isLoadingPanels}
              panelId={selectedPanelId}
              cellTypeIds={map(usedCellTypes, 'id') ?? []}
              stainTypeIds={map(usedStainTypes, 'id') ?? []}
              rules={panel.rules}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};
