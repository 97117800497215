import DeselectIcon from '@mui/icons-material/Deselect';
import {
  Box,
  ClickAwayListener,
  Fade,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popper,
} from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { map } from 'lodash';
import React from 'react';

import { useConfirmation } from 'components/modals/ConfirmationContext';
import { useSecondaryAnalysis } from 'services/secondaryAnalysis';
import { getRelevantSecondaryAnalysisSelectionModes } from 'services/secondaryAnalysis/secondaryAnalysisSelectionModes';
import { useClearSecondaryAnalysisSelections } from 'services/secondaryAnalysis/useClearSecondaryAnalysisSelections';

export const SecondaryAnalysisToolsPopper: React.FC<{
  anchorEl: Element | null;
  isDrawingToolSelectionOpen: boolean;
  isStartingSecondaryAnalysis: boolean;
  setIsDrawingToolSelectionOpen: (isOpen: boolean) => void;
  clearNonAnnotationInteractions: () => void;
}> = ({
  anchorEl,
  isStartingSecondaryAnalysis,
  isDrawingToolSelectionOpen,
  setIsDrawingToolSelectionOpen,
  clearNonAnnotationInteractions,
}) => {
  useSignals();
  // Check if any of the viewers is currently performing a secondary analysis
  const {
    context: { secondaryAnalysisAreaSelectionMode },
    modifyContext: { setSecondaryAnalysisAreaSelectionMode },
  } = useSecondaryAnalysis();

  const clearSecondaryAnalysisSelections = useClearSecondaryAnalysisSelections();

  const confirmWithModal = useConfirmation();

  const secondaryAnalysisOptions = getRelevantSecondaryAnalysisSelectionModes();

  return (
    <Popper
      anchorEl={anchorEl}
      open={isDrawingToolSelectionOpen && !isStartingSecondaryAnalysis}
      placement="bottom"
      transition
      disablePortal
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={() => setIsDrawingToolSelectionOpen(false)}>
          <Fade {...TransitionProps}>
            <Box sx={{ bgcolor: 'background.paper', marginTop: '1px' }}>
              <List>
                <ListItem value="clearSecondaryAnalysis" title="Clear selections" sx={{ p: 0 }}>
                  <ListItemButton
                    title="Clear selections"
                    onClick={async () => {
                      if (await confirmWithModal({ title: 'Cancel all secondary analysis selections?' })) {
                        clearSecondaryAnalysisSelections(false);
                      }
                    }}
                    disabled={isStartingSecondaryAnalysis}
                  >
                    <ListItemIcon>{<DeselectIcon />}</ListItemIcon>
                    <ListItemText primary="Clear selections" />
                  </ListItemButton>
                </ListItem>
                {map(secondaryAnalysisOptions, ({ editType, title, icon: OptionIcon, color, sx }) => (
                  <ListItem value={editType} title={title} sx={{ p: 0 }} key={editType}>
                    <ListItemButton
                      onClick={() => {
                        clearNonAnnotationInteractions();
                        if (editType !== secondaryAnalysisAreaSelectionMode) {
                          setSecondaryAnalysisAreaSelectionMode(editType);
                        }
                        setIsDrawingToolSelectionOpen(false);
                      }}
                      disabled={isStartingSecondaryAnalysis}
                      selected={secondaryAnalysisAreaSelectionMode === editType}
                    >
                      <ListItemIcon>
                        <OptionIcon
                          color={
                            color ? color : secondaryAnalysisAreaSelectionMode === editType ? 'primary' : 'inherit'
                          }
                          sx={sx}
                        />
                      </ListItemIcon>
                      <ListItemText primary={title} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Fade>
        </ClickAwayListener>
      )}
    </Popper>
  );
};
