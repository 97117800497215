import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import PanToolIcon from '@mui/icons-material/PanTool';
import PolylineIcon from '@mui/icons-material/Polyline';
import { IconProps, SvgIconProps, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { compact, flatMap, includes, isEmpty, some } from 'lodash';
import React from 'react';

import { IconWithBadge } from 'components/atoms/IconWithBadge';
import { secondaryAnalysisPolygons } from 'services/secondaryAnalysis/secondaryAnalysisPolygons';

export enum SecondaryAnalysisEditType {
  DeletePolygon = 'delete-polygon',
  ModifyPolygon = 'modify-polygon',
  IncludeRoi = 'include-roi',
  ExcludeRoi = 'exclude-roi',
  IncludePolygon = 'include-polygon',
  ExcludePolygon = 'exclude-polygon',
  CutRoi = 'cut-roi',
  CutPolygon = 'cut-polygon',
  Move = 'move',
}

export const secondaryAnalysisInclusionModes = [
  SecondaryAnalysisEditType.IncludePolygon,
  SecondaryAnalysisEditType.IncludeRoi,
];
export const secondaryAnalysisExclusionModes = [
  SecondaryAnalysisEditType.ExcludePolygon,
  SecondaryAnalysisEditType.ExcludeRoi,
];
export const polygonSelectionModes = [
  SecondaryAnalysisEditType.IncludePolygon,
  SecondaryAnalysisEditType.ExcludePolygon,
  SecondaryAnalysisEditType.CutPolygon,
];
export const roiSelectionModes = [
  SecondaryAnalysisEditType.IncludeRoi,
  SecondaryAnalysisEditType.ExcludeRoi,
  SecondaryAnalysisEditType.CutRoi,
];

export const secondaryAnalysisCutModes = [SecondaryAnalysisEditType.CutPolygon, SecondaryAnalysisEditType.CutRoi];

const HighlightAltPlusIcon = (props: SvgIconProps) => (
  <IconWithBadge icon={HighlightAltIcon} badgeContent="+" {...props} />
);
const HighlightAltMinusIcon = (props: SvgIconProps) => (
  <IconWithBadge icon={HighlightAltIcon} badgeContent="-" {...props} />
);
const PolylinePlusIcon = (props: SvgIconProps) => <IconWithBadge icon={PolylineIcon} badgeContent="+" {...props} />;
const PolylineMinusIcon = (props: SvgIconProps) => <IconWithBadge icon={PolylineIcon} badgeContent="-" {...props} />;

const moveMode: SecondaryAnalysisSelectionMode = {
  editType: SecondaryAnalysisEditType.Move,
  title: 'Move Annotations',
  icon: PanToolIcon,
};
const includeRoiMode: SecondaryAnalysisSelectionMode = {
  editType: SecondaryAnalysisEditType.IncludeRoi,
  title: 'Include ROI',
  icon: HighlightAltPlusIcon,
};
const excludeRoiMode: SecondaryAnalysisSelectionMode = {
  editType: SecondaryAnalysisEditType.ExcludeRoi,
  title: 'Exclude ROI',
  icon: HighlightAltMinusIcon,
};
const includePolygonMode: SecondaryAnalysisSelectionMode = {
  editType: SecondaryAnalysisEditType.IncludePolygon,
  title: 'Include Polygon / ROI',
  icon: PolylinePlusIcon,
};
const excludePolygonMode: SecondaryAnalysisSelectionMode = {
  editType: SecondaryAnalysisEditType.ExcludePolygon,
  title: 'Exclude Polygon',
  icon: PolylineMinusIcon,
};
const modifyPolygonMode: SecondaryAnalysisSelectionMode = {
  editType: SecondaryAnalysisEditType.DeletePolygon,
  title: 'Delete Individual Polygon / ROI',
  icon: DeleteOutlineIcon,
};
const deletePolygonMode: SecondaryAnalysisSelectionMode = {
  editType: SecondaryAnalysisEditType.ModifyPolygon,
  title: 'Modify Polygon / ROI',
  icon: DriveFileRenameOutlineIcon,
};
const commonCutRoiModeProps: Omit<SecondaryAnalysisSelectionMode, 'icon'> = {
  editType: SecondaryAnalysisEditType.CutRoi,
  title: 'Cut ROI',
};
const commonCutPolygonModeProps: Omit<SecondaryAnalysisSelectionMode, 'icon'> = {
  editType: SecondaryAnalysisEditType.CutPolygon,
  title: 'Cut Polygon',
};

export interface SecondaryAnalysisSelectionMode {
  editType: string;
  title: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  color?: IconProps['color'];
  sx?: IconProps['sx'];
  isDefault?: boolean;
}

export const secondaryAnalysisOptionsWithoutPolygons: SecondaryAnalysisSelectionMode[] = [
  moveMode,
  includeRoiMode,
  excludeRoiMode,
  { ...includePolygonMode, isDefault: true },
  excludePolygonMode,
];

export const secondaryAnalysisOptionsWhenIncluding: SecondaryAnalysisSelectionMode[] = [
  moveMode,
  deletePolygonMode,
  modifyPolygonMode,
  includeRoiMode,
  { ...commonCutRoiModeProps, icon: HighlightAltMinusIcon },
  includePolygonMode,
  { ...commonCutPolygonModeProps, icon: PolylineMinusIcon },
];

export const secondaryAnalysisOptionsWhenExcluding: SecondaryAnalysisSelectionMode[] = [
  moveMode,
  deletePolygonMode,
  modifyPolygonMode,
  { ...commonCutRoiModeProps, icon: HighlightAltPlusIcon },
  excludeRoiMode,
  { ...commonCutPolygonModeProps, icon: PolylinePlusIcon },
  excludePolygonMode,
];

/**
 * Get the relevant secondary analysis options based on the current selection
 * - If no selection is made, return the default options
 * - If the selection includes an inclusion mode, return the options for when including
 * - If the selection includes an exclusion mode, return the options for when excluding
 * @returns The current secondary analysis options
 */
export const getRelevantSecondaryAnalysisSelectionModes = () => {
  const secondaryAnalysisFeatures = compact(
    flatMap(secondaryAnalysisPolygons, (secondaryAnalysisPolygon) => secondaryAnalysisPolygon.value?.features)
  );

  const noSecondaryAnalysisSelections = isEmpty(secondaryAnalysisFeatures);

  const secondaryAnalysisHasInclusion = some(secondaryAnalysisFeatures, (feature) =>
    includes(secondaryAnalysisInclusionModes, feature?.properties?.featureType)
  );
  const secondaryAnalysisOptions = noSecondaryAnalysisSelections
    ? secondaryAnalysisOptionsWithoutPolygons
    : secondaryAnalysisHasInclusion
    ? secondaryAnalysisOptionsWhenIncluding
    : secondaryAnalysisOptionsWhenExcluding;
  return secondaryAnalysisOptions;
};
