import React, { useCallback } from 'react';

import { CircularProgress, Grid, Typography } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { useMutation } from '@tanstack/react-query';
import { filter, includes, isEmpty } from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { runCalculateFeatures } from 'api/platform';
import { geojsonFeaturesToMultiPolygon } from 'components/Procedure/Infobar/SlideInfobar/Results/SecondaryAnalysisTrigger';
import { Inputs } from 'interfaces/calculateFeatures';
import { CalculateFeaturesJob, JobType, NucleaiExecutorJob, SecondaryAnalysisInput } from 'interfaces/job';
import {
  secondaryAnalysisExclusionModes,
  secondaryAnalysisInclusionModes,
} from 'services/secondaryAnalysis/secondaryAnalysisSelectionModes';
import queryClient from 'utils/queryClient';
import { secondaryAnalysisPolygons } from './secondaryAnalysisPolygons';
import { useClearSecondaryAnalysisSelections } from './useClearSecondaryAnalysisSelections';

export const SNACK_BAR_KEY_SECONDARY_ANALYSIS = 'secondary-analysis';

export interface RunSecondaryAnalysisParams {
  caseId: number;
  slideId: string;
  studyId: string;
  viewerIndex: number;
  secondaryAnalysisSourceOrchestrationId: string;
  autoInternallyApprove: boolean;
  wholeSlideAnalysisJob: CalculateFeaturesJob | NucleaiExecutorJob;
}

export const useRunSecondaryAnalysis = ({ onSuccess }: { onSuccess: () => void }) => {
  useSignals();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const clearSecondaryAnalysisSelections = useClearSecondaryAnalysisSelections();

  const runCalculateFeaturesMutation = useMutation(runCalculateFeatures, {
    mutationKey: ['runCalculateFeaturesForSecondaryAnalysis'],
    onError: () => {
      enqueueSnackbar('Error occurred, Calculate Features failed', { variant: 'error' });
    },
    onSuccess: () => {
      enqueueSnackbar('Calculate Features Started', { variant: 'success' });
      // Invalidate jobs query to get the new job
      queryClient.invalidateQueries(['jobs']);
      clearSecondaryAnalysisSelections(true);
      onSuccess?.();
    },
    onSettled() {
      // Invalidate jobs query to get the new job
      queryClient.invalidateQueries(['jobs']);
      closeSnackbar(SNACK_BAR_KEY_SECONDARY_ANALYSIS);
    },
  });
  const runSecondaryAnalysis = useCallback(
    ({
      caseId,
      slideId,
      studyId,
      viewerIndex,
      secondaryAnalysisSourceOrchestrationId,
      autoInternallyApprove,
      wholeSlideAnalysisJob,
    }: RunSecondaryAnalysisParams) => {
      const secondaryAnalysisFeature = secondaryAnalysisPolygons[viewerIndex].value;

      const secondaryAnalysisFeatures = filter(
        secondaryAnalysisFeature?.features,
        (feature) => feature.properties.orchestrationId === secondaryAnalysisSourceOrchestrationId
      );
      const inclusionFeatures = filter(secondaryAnalysisFeatures, (feature) =>
        includes(secondaryAnalysisInclusionModes, feature.properties.featureType)
      );

      const exclusionFeatures = filter(secondaryAnalysisFeatures, (feature) =>
        includes(secondaryAnalysisExclusionModes, feature.properties.featureType)
      );

      if (!wholeSlideAnalysisJob) {
        console.error('Whole slide analysis job not found');
        enqueueSnackbar('Error occurred, Calculate Features failed', { variant: 'error' });
        return;
      }
      const jobType = wholeSlideAnalysisJob.type;
      if (jobType !== JobType.CalculateFeatures && jobType !== JobType.NucleaiExecutor) {
        console.error(
          'Whole slide analysis job is not a CalculateFeatures or a study assigned algorithm with final results',
          {
            wholeSlideAnalysisJob,
          }
        );
        enqueueSnackbar('Error occurred, Calculate Features failed', { variant: 'error' });
        return;
      }
      const params =
        jobType === JobType.CalculateFeatures
          ? (wholeSlideAnalysisJob as CalculateFeaturesJob).params
          : (wholeSlideAnalysisJob.params?.graph_params?.[JobType.CalculateFeatures] as CalculateFeaturesJob['params']);

      if (!params) {
        console.error('Whole slide analysis job params not found', { wholeSlideAnalysisJob });
        enqueueSnackbar('Error occurred, Calculate Features failed', { variant: 'error' });
        return;
      }

      const secondaryAnalysisPolygonsToSend: SecondaryAnalysisInput = {
        [caseId]: {
          [slideId]: {
            ...(!isEmpty(inclusionFeatures)
              ? { inclusionMultipolygon: geojsonFeaturesToMultiPolygon(inclusionFeatures) }
              : {}),
            ...(!isEmpty(exclusionFeatures)
              ? { exclusionMultipolygon: geojsonFeaturesToMultiPolygon(exclusionFeatures) }
              : {}),
          },
        },
      };

      const newManifest = { [caseId]: [slideId] };
      const previousCaseInputs = params?.inputs?.[caseId];
      const newInputs: Inputs = { [caseId]: { [slideId]: previousCaseInputs?.[slideId] || [] } };

      const runTime = moment().format('YYYY-MM-DD HH:mm:ss');
      runCalculateFeaturesMutation.mutate({
        jobName: `${wholeSlideAnalysisJob.name} - Secondary Analysis - ${runTime}`,
        jobDescription: `${wholeSlideAnalysisJob.description} - Secondary Analysis - ${runTime}`,
        configParams: {
          secondaryAnalysisPolygons: secondaryAnalysisPolygonsToSend,
          primaryRunOrchestrationId: secondaryAnalysisSourceOrchestrationId,
          autoInternallyApprove,
          manifest: newManifest,
          inputs: newInputs,
          registrations: params.registrations,
          postprocessing: params.postprocessing,
          features: params.features,
          gridBasedCalculationParams: params.gridBasedCalculationParams,
          visualizations: params.visualizations,
          studyId,
        },
      });

      enqueueSnackbar({
        variant: 'success',
        message: (
          <Grid container>
            <Grid item>
              <Typography>Waiting for Secondary Analysis to start</Typography>
            </Grid>
            <Grid item>
              <CircularProgress sx={{ marginLeft: 10 }} color="inherit" size={20} />
            </Grid>
          </Grid>
        ),
        key: SNACK_BAR_KEY_SECONDARY_ANALYSIS,
        autoHideDuration: null,
      });
    },
    [enqueueSnackbar, runCalculateFeaturesMutation, enqueueSnackbar, clearSecondaryAnalysisSelections]
  );

  return {
    runSecondaryAnalysis,
    isStartingSecondaryAnalysis: runCalculateFeaturesMutation.isLoading,
  };
};
