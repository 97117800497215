import { compact, filter, flatMap, isEmpty, map, partition, some } from 'lodash';

import {
  FeatureMetadata,
  FeatureMetadataBeforeSecondaryAnalysisGrouping,
  FeatureMetadataSecondaryAnalysisEntry,
  FeatureMetadataWithMissingParent,
  FeatureMetadataWithSecondaryAnalysisFlow,
  FeatureMetadataWithSingleRun,
  SecondaryAnalysisStatus,
} from './featureMetadata';

export const isRunWithoutSecondaryAnalysis = (result?: FeatureMetadata): result is FeatureMetadataWithSingleRun =>
  (result as { secondaryAnalysisStatus?: SecondaryAnalysisStatus })?.secondaryAnalysisStatus ===
  SecondaryAnalysisStatus.None;

export const isParsedSecondaryAnalysisRun = (
  result: FeatureMetadata & {
    secondaryAnalysisStatus?: SecondaryAnalysisStatus;
  }
): result is FeatureMetadataWithSecondaryAnalysisFlow =>
  result?.secondaryAnalysisStatus === SecondaryAnalysisStatus.PrimaryResult;

export const flattenSecondaryResult = (
  result: FeatureMetadataWithSingleRun | FeatureMetadataWithSecondaryAnalysisFlow
) => compact([result, ...(result?.secondaryResults || [])]);

export const flattenParsedSecondaryResults = (results: FeatureMetadata[]) => flatMap(results, flattenSecondaryResult);

export const groupPrimaryResultsWithSecondaryResults = (
  results: FeatureMetadataBeforeSecondaryAnalysisGrouping[]
): FeatureMetadata[] => {
  const [secondaryResults, primaryResults] = partition(results, (result) => Boolean(result.primaryRunOrchestrationId));

  const secondaryResultsWithoutPrimary = map(
    filter(
      secondaryResults,
      (result) =>
        Boolean(result.primaryRunOrchestrationId) &&
        !some(primaryResults, { orchestrationId: result.primaryRunOrchestrationId, key: result.key })
    ),
    (result) =>
      ({
        ...result,
        secondaryAnalysisStatus: SecondaryAnalysisStatus.SecondaryResultWithMissingParent,
      } as FeatureMetadataWithMissingParent)
  );

  const resultsWithSecondaryAsNested = map(primaryResults, (primaryResult) => {
    const secondaryResultsForPrimary = map(
      filter(secondaryResults, {
        key: primaryResult.key,
        primaryRunOrchestrationId: primaryResult.orchestrationId,
      }),
      (secondaryResult) => ({
        ...secondaryResult,
        secondaryAnalysisStatus: SecondaryAnalysisStatus.SecondaryResult,
      })
    ) as FeatureMetadataSecondaryAnalysisEntry[];

    if (!isEmpty(secondaryResultsForPrimary)) {
      return {
        ...primaryResult,
        secondaryResults: secondaryResultsForPrimary,
        secondaryAnalysisStatus: SecondaryAnalysisStatus.PrimaryResult,
      } as FeatureMetadataWithSecondaryAnalysisFlow;
    } else {
      return {
        ...primaryResult,
        secondaryResults: undefined,
        secondaryAnalysisStatus: SecondaryAnalysisStatus.None,
      } as FeatureMetadataWithSingleRun;
    }
  });

  const groupedResults: FeatureMetadata[] = [...resultsWithSecondaryAsNested, ...secondaryResultsWithoutPrimary];

  // Check that all original results are present in the final result
  if (
    some(
      results,
      (originalResult) =>
        !some(
          groupedResults,
          (groupedResult) =>
            groupedResult.id === originalResult.id || some(groupedResult.secondaryResults, { id: originalResult.id })
        )
    )
  ) {
    console.warn('Not all original results are present in the final result after grouping secondary results', {
      originalResults: results,
      groupedResults,
    });
  }

  return groupedResults;
};
