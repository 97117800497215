import { SelectProps } from '@mui/material';
import LazyLoadSelect from 'components/atoms/Dropdown/LazyLoadDropDown';
import { useJobs } from 'components/Pages/Jobs/useJobs';
import { Job, JobType } from 'interfaces/job';
import { find, first, get, isEmpty, map, orderBy, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { humanize } from 'utils/helpers';

interface JobSelectProps extends Omit<SelectProps, 'onScroll' | 'label'> {
  label: string;
  jobType: JobType;
  selectedJobId: string | null;
  setSelectedJobId: (jobId: string | null) => void;
  setSelectedJob?: (job: Job) => void;
  autoAssignLastJob?: boolean;
  errors?: any;
  field?: string;
  helperText?: string;
  studyId?: string;
}

const PAGE_SIZE = 6;

const JobSelect: React.FC<JobSelectProps> = ({
  label,
  jobType,
  selectedJobId,
  autoAssignLastJob,
  setSelectedJobId,
  setSelectedJob,
  errors,
  field,
  helperText,
  studyId,
  ...selectProps
}) => {
  const [jobsPage, setJobsPage] = useState(1);
  const [allJobs, setAllJobs] = useState<Job[]>([]);

  const {
    data: jobs,
    isFetching: isJobsFetching,
    isError: isJobsError,
  } = useJobs({
    page: jobsPage,
    pageSize: PAGE_SIZE,
    additionalFilters: { type: jobType },
    fullData: true,
    ...(studyId ? { studyId } : {}),
  });

  const totalPages: number | null = jobs?.totalJobs ? Math.ceil(jobs.totalJobs / PAGE_SIZE) : null;

  const loadMoreJobs = () => {
    if (totalPages && jobsPage < totalPages) {
      setJobsPage((prevPage) => prevPage + 1);
    }
  };

  const selectNewJobId = (newJobId: string) => {
    const newSelectedJob = find(allJobs, (job) => job.id === newJobId);
    setSelectedJobId(newSelectedJob?.id || null);
    if (setSelectedJob) setSelectedJob(newSelectedJob || null);
  };

  useEffect(() => {
    if (jobs) {
      setAllJobs((prevJobs) => orderBy(uniqBy([...prevJobs, ...(jobs.jobs as Job[])], 'id'), 'startedAt', 'desc'));
    }
  }, [jobs]);

  useEffect(() => {
    if (!isEmpty(allJobs) && selectedJobId === null && autoAssignLastJob) {
      const latestJob: Job = first(allJobs);
      selectNewJobId(latestJob?.id || null);
    }
  }, [allJobs, selectedJobId, autoAssignLastJob]);

  const errorMessage = get(errors, field)?.message;

  return (
    <LazyLoadSelect
      label={label}
      options={map(allJobs, (job) => ({ value: job.id, label: `${job.name} ${job.startedAt}` }))}
      loadMoreOptions={loadMoreJobs}
      isLoading={isJobsFetching}
      required
      error={Boolean(errorMessage)}
      value={selectedJobId || ''}
      onChange={(event) => selectNewJobId(event.target.value as string)}
      helperText={humanize(errorMessage) || helperText || (isJobsError && 'Error loading jobs')}
      {...selectProps}
    />
  );
};

export default JobSelect;
