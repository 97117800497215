import React, { useEffect } from 'react';

import { Grid, Typography } from '@mui/material';
import { BooleanParam, useQueryParam, withDefault } from 'use-query-params';
import { toggleShowCommentsShortcut } from './shortcutsConsts';

const CommentsHints: React.FunctionComponent<React.PropsWithChildren> = () => {
  const [hideComments, setHideComments] = useQueryParam('hideComments', withDefault(BooleanParam, false));

  useEffect(() => {
    const handleKeyDownForAnnotationsShortcuts = (event: KeyboardEvent) => {
      if (event.key === toggleShowCommentsShortcut) {
        setHideComments(!hideComments);
      }
    };

    window.addEventListener('keydown', handleKeyDownForAnnotationsShortcuts);

    return () => {
      window.removeEventListener('keydown', handleKeyDownForAnnotationsShortcuts);
    };
  });

  return (
    <Grid item>
      <Typography variant="body2">
        {hideComments
          ? `Press ${toggleShowCommentsShortcut} to show comments`
          : `Press ${toggleShowCommentsShortcut} to hide comments`}
      </Typography>
    </Grid>
  );
};

export default CommentsHints;
