import { Grid, TextField, TextFieldProps } from '@mui/material';
import { DropdownOption } from 'components/atoms/Dropdown/Dropdown';
import LabelledDropdown from 'components/atoms/Dropdown/LabelledDropdown';
import { TodoDefinition } from 'interfaces/annotation';
import { compact, flatMap, map, uniq } from 'lodash';
import React, { ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import { humanize } from 'utils/helpers';

export const getAssignmentTodosClassNameOptions = (todos: TodoDefinition[]) =>
  uniq(compact(map(flatMap(todos, 'options'), 'name')));

interface StainInputProps {
  label: string;
  name: string;
  options: DropdownOption[];
  control: any;
  errors: any;
}

export const StainInput = ({ label, name, options, control, errors }: StainInputProps): ReactElement => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <LabelledDropdown
          label={label}
          options={options}
          value={value ?? []}
          onOptionSelected={(optionValue) => {
            onChange(optionValue);
          }}
          error={Boolean(errors[name])}
          helperText={humanize(errors[name]?.message)}
        />
      )}
    />
  );
};

interface TextInputControllerProps {
  name: string;
  label: string;
  control: any;
  errors: any;
  register: any;
  textFieldProps?: TextFieldProps;
  additionalOnChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const TextInputController = ({
  name,
  label,
  control,
  errors,
  register,
  textFieldProps,
  additionalOnChange,
}: TextInputControllerProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange } }) => (
        <TextField
          label={label}
          {...register(name)}
          onChange={
            additionalOnChange
              ? (e) => {
                  onChange(e);
                  additionalOnChange(e);
                }
              : onChange
          }
          placeholder="Type Here"
          error={Boolean(errors[name])}
          helperText={humanize(errors[name]?.message)}
          {...textFieldProps}
        />
      )}
    />
  );
};

interface JobNameAndDescriptionStepProps {
  control: any;
  errors: any;
  register: any;
  onChangeName?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const JobNameAndDescriptionStep = ({
  control,
  errors,
  register,
  onChangeName,
}: JobNameAndDescriptionStepProps) => {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <TextInputController
          name="jobName"
          label="Job Name"
          control={control}
          errors={errors}
          register={register}
          additionalOnChange={onChangeName}
        />
      </Grid>
      <Grid item>
        <TextInputController
          name="jobDescription"
          label="Job Description"
          control={control}
          errors={errors}
          register={register}
          textFieldProps={{
            multiline: true,
            minRows: 4,
          }}
        />
      </Grid>
    </Grid>
  );
};
