import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { runStudyAlgorithm } from 'api/platform';
import LabelledDropdown from 'components/atoms/Dropdown/LabelledDropdown';
import { find, first, forEach, isEmpty, map, size, some, times } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CasesParams } from 'utils/useCasesParams';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { useStudyAvailableAlgorithms } from 'utils/useStudyAvailableAlgorithms';
import * as yup from 'yup';
import { PlatformStepper } from './PlatformStepper';
import { JobNameAndDescriptionStep } from './utils';

const SNACK_BAR_KEY_RUN_STUDY_ALGORITHM = 'RUN_STUDY_ALGORITHM';

export interface NucleaiExecutorFormValues {
  jobName: string;
  jobDescription?: string;
  algorithmId: string;
}

const validationSchema = [
  yup.object().shape({
    algorithmId: yup.string().required('Must select an algorithm'),
  }),
  yup.object({
    jobName: yup.string().required('Job Name is required'),
    jobDescription: yup.string().optional().nullable(),
  }),
];
export interface ExecuteStudyAlgorithmProps {
  onClose: () => void;
  casesParams: CasesParams;
}

export const ExecuteStudyAlgorithm = (props: ExecuteStudyAlgorithmProps): ReactElement => {
  const { onClose, casesParams } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const activeStateHandlers = useState(0);
  const [activeStep, setActiveStep] = activeStateHandlers;
  const currentValidationSchema = validationSchema[activeStep];
  const {
    register,
    handleSubmit,
    control,
    watch,
    trigger,
    formState: { errors, isDirty },
    setValue,
  } = useForm<NucleaiExecutorFormValues>({
    mode: 'onChange',
    resolver: yupResolver(currentValidationSchema),
  });

  const [isStepFailed, setIsStepFailed] = useState<Record<number, boolean>>({});

  const checkValidationAndSetIsStepFailed = async (stepIndex: number) => {
    const isValid = await trigger();

    setIsStepFailed((prev) => ({
      ...prev,
      [stepIndex]: !isValid,
    }));

    return isValid;
  };

  const studyId = casesParams.studyId;
  const labId = casesParams.labId;

  const { data: availableAlgorithms, isLoading, isError } = useStudyAvailableAlgorithms(studyId);

  const currentAlgorithmId = watch('algorithmId');

  const onAlgorithmIdSelect = useCallback(
    (algorithmId: string, skipJobName?: boolean) => {
      setValue('algorithmId', algorithmId);
      const optionEntry = find(availableAlgorithms, { id: algorithmId });
      const timeStampText = new Date().toLocaleString();
      if (!skipJobName) {
        setValue('jobName', `${optionEntry?.displayName || ''} - ${timeStampText}`);
      }
      checkValidationAndSetIsStepFailed(0);
      checkValidationAndSetIsStepFailed(1);
    },
    [availableAlgorithms, setValue, checkValidationAndSetIsStepFailed]
  );

  const hasStudyAlgorithms = !isEmpty(availableAlgorithms);
  const hasSingleAlgorithm = size(availableAlgorithms) === 1;

  const autoSelectedAlgorithm = hasSingleAlgorithm ? first(availableAlgorithms)?.id : undefined;

  // If only one algorithm is available, automatically select it
  useEffect(() => {
    if (autoSelectedAlgorithm && currentAlgorithmId !== autoSelectedAlgorithm) {
      onAlgorithmIdSelect(autoSelectedAlgorithm, isDirty);
      setActiveStep(1);
    }
  }, [autoSelectedAlgorithm, currentAlgorithmId, onAlgorithmIdSelect]);

  const currentAlgorithmEntry = find(availableAlgorithms, { id: currentAlgorithmId });

  const runStudyAlgorithmMutation = useMutationWithErrorSnackbar({
    mutationFn: runStudyAlgorithm,
    mutationDescription: 'run an available study algorithm',
    onSuccess: () => {
      enqueueSnackbar(`${currentAlgorithmEntry?.displayName || currentAlgorithmId} Started`, {
        variant: 'success',
      });
      onClose();
    },
    onSettled() {
      closeSnackbar(SNACK_BAR_KEY_RUN_STUDY_ALGORITHM);
    },
  });

  const onSubmit: SubmitHandler<NucleaiExecutorFormValues> = async (data) => {
    try {
      forEach(validationSchema, (schema) => schema.validateSync(data, { abortEarly: false }));
    } catch (stepError) {
      console.error('Validation failed', stepError);
      enqueueSnackbar('Validation failed', { variant: 'error' });
      return;
    }
    if (!currentAlgorithmEntry) {
      throw Error('Failed to find selected algorithm');
    }
    enqueueSnackbar({
      variant: 'success',
      message: (
        <Grid container>
          <Grid item>
            <Typography>Waiting for {currentAlgorithmEntry?.displayName || currentAlgorithmId} to start</Typography>
          </Grid>
          <Grid item>
            <CircularProgress sx={{ marginLeft: 10 }} color="inherit" size={20} />
          </Grid>
        </Grid>
      ),
      key: SNACK_BAR_KEY_RUN_STUDY_ALGORITHM,
      autoHideDuration: null,
    });

    await runStudyAlgorithmMutation.mutateAsync({
      casesParams,
      studyId,
      labId,
      ...data,
    });
  };
  const options = useMemo(
    () => map(availableAlgorithms, (algorithm) => ({ value: algorithm.id, text: algorithm.displayName })),
    [availableAlgorithms]
  );

  const steps = [
    {
      label: 'Select Algorithm',
      isLoading,
      subLabel: isLoading ? 'Loading...' : currentAlgorithmEntry?.displayName,
      content: (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            {isLoading || hasStudyAlgorithms ? (
              <LabelledDropdown
                label={
                  <Grid item container columnSpacing={2}>
                    <Grid item>Select an algorithm to run</Grid>
                    {isLoading && (
                      <Grid item>
                        <CircularProgress size={20} />
                      </Grid>
                    )}
                    {isError && (
                      <Grid item>
                        <Typography color="error">Failed to load algorithms</Typography>
                      </Grid>
                    )}
                    {errors.algorithmId?.message && (
                      <Grid item>
                        <Typography color="error">{errors.algorithmId.message}</Typography>
                      </Grid>
                    )}
                  </Grid>
                }
                options={options}
                value={currentAlgorithmId || ''}
                onOptionSelected={onAlgorithmIdSelect}
                error={Boolean(errors.algorithmId)}
              />
            ) : (
              <Typography>No algorithms available</Typography>
            )}
          </Grid>
        </Grid>
      ),
      onNextOrBackClick: () => checkValidationAndSetIsStepFailed(0),
    },
    {
      label: 'Job Name and Description',
      subLabel: activeStep > 0 && watch('jobName'),
      content: (
        <JobNameAndDescriptionStep
          control={control}
          errors={errors}
          register={register}
          onChangeName={() => checkValidationAndSetIsStepFailed(1)}
        />
      ),
      onNextOrBackClick: () => checkValidationAndSetIsStepFailed(1),
    },
  ];

  return (
    <PlatformStepper
      activeStateHandlers={activeStateHandlers}
      handleSubmit={handleSubmit(onSubmit)}
      steps={steps}
      setActiveStepForValidation={setActiveStep}
      isStepFailed={isStepFailed}
      validateStep={async () =>
        !some(await Promise.all(times(size(steps), (i) => checkValidationAndSetIsStepFailed(i))))
      }
    />
  );
};
