import { COORDINATE_SYSTEM, Layer, PickingInfo } from '@deck.gl/core/typed';
import { DeckGLProps } from '@deck.gl/react/typed';
import GL from '@luma.gl/constants';
import { useSignals } from '@preact/signals-react/runtime';
import difference from '@turf/difference';
import { FeatureCollection } from '@turf/helpers';
import intersect from '@turf/intersect';
import { compact, filter, includes, isEmpty, size, times } from 'lodash';
import { EditableGeoJsonLayer, ViewMode } from 'nebula.gl';
import { useCallback, useMemo } from 'react';

import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { Permission } from 'interfaces/permissionOption';
import { useSecondaryAnalysis } from 'services/secondaryAnalysis';
import {
  deleteSecondaryAnalysisFeatureByViewerAndFeatureIndex,
  secondaryAnalysisPolygons,
} from 'services/secondaryAnalysis/secondaryAnalysisPolygons';
import {
  polygonSelectionModes,
  roiSelectionModes,
  secondaryAnalysisCutModes,
  SecondaryAnalysisEditType,
} from 'services/secondaryAnalysis/secondaryAnalysisSelectionModes';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';
import { usePermissions } from 'utils/usePermissions';
import { OrthographicMapViewState } from '../../OrthographicMapview';
import { DrawAnnotationPolygonMode, DrawAnnotationRectangleWithModifyMode } from '../NebulaGLExtensions/editModes';
import {
  getEditHandleColor,
  getEditHandleTypeFromEitherLayer,
  RGBAColor,
  TOOLTIP_COLOR,
} from '../NebulaGLExtensions/helpers';
import { ModifyOrthographicMode } from '../NebulaGLExtensions/ModifyOrthographicMode';
import { TranslateOrthographicMode } from '../NebulaGLExtensions/TranslateOrthographicMode';
import { getFillColor, getLineColor } from './helpers';

// TODO: not ideal to use a global constant, but we want a stable reference to the same signal across renders
// This is a workaround for the fact that nebula.gl always needs data to work with, even if it's empty
const NO_SECONDARY_ANALYSIS_SELECTIONS: FeatureCollection = { type: 'FeatureCollection', features: [] };

/**
 * Hook to draw the secondary analysis selections layer (i.e. the nebula.gl layer that allows the user to draw polygons
 * for inclusion/exclusion in secondary analysis).
 * @param slide The slide for which to draw the secondary analysis selections layer.
 * @param viewState The current view state of the deck.gl map.
 * @param viewSize The size of the deck.gl map.
 * @returns The configured layer for secondary analysis selections.
 */
export const useSecondaryAnalysisSelectionsLayer = ({
  slide,
  viewState,
  viewSize,
}: {
  slide: SlideWithChannelAndResults;
  viewState: OrthographicMapViewState;
  viewSize: { width: number; height: number };
}) => {
  useSignals();
  const {
    execution: { isStarting: isStartingSecondaryAnalysis },
    context: { secondaryAnalysisAreaSelectionMode, secondaryAnalysisSourceOrchestrationId },
  } = useSecondaryAnalysis();

  const { hasPermission } = usePermissions();
  const canRunSecondaryAnalysis = hasPermission(Permission.RunSecondaryAnalysis);

  const secondaryAnalysisFeatureCollection =
    secondaryAnalysisPolygons[slide.viewerIndex].value || NO_SECONDARY_ANALYSIS_SELECTIONS;

  const deleteFeature: DeckGLProps['onClick'] = useCallback(
    (pickingInfo) => {
      const featureIndex = pickingInfo?.index;
      return deleteSecondaryAnalysisFeatureByViewerAndFeatureIndex(slide.viewerIndex, featureIndex);
    },
    [slide.viewerIndex]
  );

  const onClick = useCallback(
    (info: PickingInfo, event: Parameters<DeckGLProps['onClick']>[1]) => {
      if (secondaryAnalysisAreaSelectionMode && event.rightButton) {
        event.preventDefault();
        event.stopPropagation();
        return true;
      }

      if (
        secondaryAnalysisAreaSelectionMode === SecondaryAnalysisEditType.DeletePolygon ||
        (event.leftButton && event.srcEvent.altKey)
      ) {
        // @ts-ignore
        deleteFeature(info, event);
        event.srcEvent.preventDefault();
        event.srcEvent.stopPropagation();
      }
    },
    [secondaryAnalysisAreaSelectionMode, deleteFeature]
  );

  const onEdit = useCallback(
    ({ updatedData, editContext }: { updatedData: FeatureCollection; editType: string; editContext: any }) => {
      const isCutMode = includes(secondaryAnalysisCutModes, secondaryAnalysisAreaSelectionMode);
      times(updatedData?.features?.length ?? 0, (featureIndex) => {
        const editedFeatureWithoutChanges = secondaryAnalysisFeatureCollection?.features?.[featureIndex];
        const isNewFeature = !editedFeatureWithoutChanges;

        if (isNewFeature && editContext?.feature?.properties?.guideType !== 'tentative') {
          if (isCutMode) {
            times(updatedData?.features?.length ?? 0, (intersectingFeatureIndex) => {
              // If the feature intersects with the edited feature, replace it with the intersection
              const feature = updatedData.features[featureIndex];
              const intersectingFeature = updatedData.features[intersectingFeatureIndex];
              if (intersectingFeatureIndex !== featureIndex && feature?.geometry?.type === 'Polygon') {
                // Use turf to calculate the intersection
                const intersection = intersect(intersectingFeature as any, feature as any);
                if (intersection) {
                  const differencePolygon = difference(intersectingFeature as any, feature as any);
                  if (differencePolygon?.properties?.shape === 'Rectangle') {
                    // If the difference is a rectangle, convert it to a polygon
                    differencePolygon.properties.shape = 'Polygon';
                  }
                  updatedData.features[intersectingFeatureIndex] = differencePolygon;
                }
              }
            });
            updatedData.features[featureIndex] = null;
          } else {
            updatedData.features[featureIndex].properties.featureType = secondaryAnalysisAreaSelectionMode;
            updatedData.features[featureIndex].properties.orchestrationId = secondaryAnalysisSourceOrchestrationId;
            console.info('Adding new feature', { feature: updatedData.features[featureIndex] });
          }
        }
      });
      updatedData.features = filter(
        compact(updatedData.features),
        (feature) =>
          // Only keep tentative features or features that match the current orchestrationId
          !feature?.properties?.orchestrationId ||
          feature?.properties?.orchestrationId === secondaryAnalysisSourceOrchestrationId
      );
      secondaryAnalysisPolygons[slide.viewerIndex].value = updatedData;
    },
    [
      secondaryAnalysisAreaSelectionMode,
      secondaryAnalysisFeatureCollection?.features,
      secondaryAnalysisSourceOrchestrationId,
      slide?.viewerIndex,
    ]
  );

  const selectedFeatureIndexes = useMemo(
    () => times(size(secondaryAnalysisFeatureCollection?.features), (i) => i),
    [size(secondaryAnalysisFeatureCollection?.features)]
  );

  const [measureToolActive] = useQueryParam('measureToolActive', BooleanParam);
  const [commentMode] = useQueryParam('commentMode', StringParam);

  const hasActiveNonAnnotationInteractions = Boolean(measureToolActive || commentMode);

  const secondaryAnalysisLayer =
    canRunSecondaryAnalysis &&
    secondaryAnalysisSourceOrchestrationId &&
    (secondaryAnalysisAreaSelectionMode || !isEmpty(secondaryAnalysisFeatureCollection?.features)) &&
    // @ts-ignore
    (new EditableGeoJsonLayer({
      id: `secondaryAnalysis-${slide.id}`,
      coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
      getRadius: 2,
      data: secondaryAnalysisFeatureCollection,
      selectedFeatureIndexes,
      // @ts-ignore
      onClick,
      onEdit,
      mode:
        isStartingSecondaryAnalysis || hasActiveNonAnnotationInteractions
          ? ViewMode
          : includes(polygonSelectionModes, secondaryAnalysisAreaSelectionMode)
          ? DrawAnnotationPolygonMode
          : includes(roiSelectionModes, secondaryAnalysisAreaSelectionMode)
          ? DrawAnnotationRectangleWithModifyMode
          : secondaryAnalysisAreaSelectionMode === SecondaryAnalysisEditType.ModifyPolygon
          ? ModifyOrthographicMode
          : secondaryAnalysisAreaSelectionMode === SecondaryAnalysisEditType.Move
          ? TranslateOrthographicMode
          : ViewMode,
      modeConfig: {
        viewport: { ...viewState, ...viewSize },
        ...(secondaryAnalysisAreaSelectionMode ? { lockRectangles: true } : {}),
      },
      pickable: true,
      autoHighlight: secondaryAnalysisAreaSelectionMode === SecondaryAnalysisEditType.DeletePolygon,
      highlightColor: [255, 255, 255, 25] as RGBAColor,
      stroked: true,
      filled: true,
      editHandleType: 'point',
      getFillColor,
      getLineColor,
      _subLayerProps: { tooltips: { getColor: TOOLTIP_COLOR } },
      getEditHandleIcon: getEditHandleTypeFromEitherLayer,
      getEditHandleIconSize: 40,
      getEditHandleIconColor: getEditHandleColor,

      pointRadiusMinPixels: 5,
      pointRadiusScale: 2,
      lineWidthScale: 2,
      lineWidthMinPixels: 2,

      parameters: {
        depthTest: true,
        depthMask: false,

        blend: true,
        blendEquation: GL.FUNC_ADD,
        blendFunc: [GL.SRC_ALPHA, GL.ONE_MINUS_SRC_ALPHA],
      },
    }) as Layer<any>);

  if (
    secondaryAnalysisLayer &&
    (isStartingSecondaryAnalysis || secondaryAnalysisAreaSelectionMode === SecondaryAnalysisEditType.DeletePolygon)
  ) {
    // @ts-ignore
    secondaryAnalysisLayer.getCursor = ({ isHovering }: { isHovering: boolean; isDragging: boolean }) => {
      if (isStartingSecondaryAnalysis) {
        return 'wait';
      } else if (isHovering) {
        return 'crosshair';
      } else {
        return 'pointer';
      }
    };
  }
  return secondaryAnalysisLayer;
};
