import { includes } from 'lodash';
import { enumValuesList } from 'utils/enumValues';

export interface StainType {
  id: string;
  displayName: string;
  index: number;
  canBeMifMarker: boolean;
  aliases?: string[];
  lineage?: boolean;
  phenotypic?: boolean;
  membrane?: boolean;
  nucleus?: boolean;
  cytoplasm?: boolean;
  function?: string;
  extracellular?: boolean;
  distal?: boolean;
  deletedAt?: string | null;
}

export const MULTIPLEX_STAIN_ID = 'mplex' as const;

export enum StainingMethod {
  he = 'he',
  mplex = 'mplex',
  chromogenicMplex = 'chromogenic_mplex',
  ihc = 'ihc',
}

export const stainingMethodsList = enumValuesList(StainingMethod);

export const getStainingMethodByStainType = (slideStainType: string): StainingMethod => {
  // If slideStainType is 'he' or 'mplex', we return it as is
  if (includes(['he', MULTIPLEX_STAIN_ID], slideStainType)) return slideStainType as StainingMethod;
  // In case we have more than 1 ihc, separated by '_', we consider it as mplexChromogenic
  if (slideStainType?.includes('_')) return StainingMethod.chromogenicMplex;
  // Otherwise, we return ihc
  return StainingMethod.ihc;
  // In the future, we may want to deal with non dab ihc differently
};
