import CopyableText from 'components/atoms/CopyableText';
import React, { FunctionComponent } from 'react';

const SlideIdentifierBadge: FunctionComponent<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const shortenedId = children?.toString().slice(0, 8);

  return (
    <CopyableText
      text={shortenedId}
      sx={{
        position: 'absolute',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        zIndex: 1,
        borderRadius: '20px',
        color: 'black',
        fontSize: '12px',
        padding: '5px',
        top: '7px',
        right: '6px'
      }}
    />
  );
}

export default SlideIdentifierBadge;
