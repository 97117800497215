import { useQueries, useQuery } from '@tanstack/react-query';
import { fromPairs, join, map } from 'lodash';
import { useMemo } from 'react';

export const useGeoJsonFile = (geoJSONFileURL: string) => {
  return useQuery(
    ['geoJSON', geoJSONFileURL],
    async () => {
      const response = await fetch(geoJSONFileURL);
      return response.json();
    },
    { enabled: !!geoJSONFileURL }
  );
};

export const useGeoJsonFiles = (geoJSONFileURLs: string[]) => {
  const queries = useQueries({
    queries: map(geoJSONFileURLs, (geoJSONFileURL) => ({
      queryKey: ['geoJSON', geoJSONFileURL],
      queryFn: async () => {
        const response = await fetch(geoJSONFileURL);
        return response.json();
      },
    })),
  });

  return useMemo(
    () => fromPairs(map(geoJSONFileURLs, (geoJSONFileURL, index) => [geoJSONFileURL, queries[index]])),
    // No need to pass `geoJSONFileURLs` as a dependency since it will change the queries
    [join(map(queries, 'dataUpdatedAt'))]
  );
};
