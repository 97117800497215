import { GridRowParams } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import React from 'react';

import { doesUpdatingRowHaveRequiredFields, isUpdatingRowUnique } from 'components/atoms/EditableDataGrid/helpers';
import { CellColorMapping } from 'interfaces/cellColorMapping';
import { uuidv4 } from 'utils/helpers';
import { CellColorMappingDraft } from './types';

export const generateDraftId = () => `draft-${uuidv4()}`;
export const getCellColorMappingId = (row: CellColorMapping | CellColorMappingDraft) =>
  (row as CellColorMappingDraft).draftId ?? row.id ?? generateDraftId();

export const getCellColorMappingError = ({
  id,
  apiRef,
}: GridRowParams<CellColorMapping> & {
  apiRef: React.MutableRefObject<GridApiCommunity>;
}) => {
  const missingCellClassId = !doesUpdatingRowHaveRequiredFields({
    apiRef,
    rowId: id,
    requiredFields: ['cellClassId'],
  });

  const missingColor = !doesUpdatingRowHaveRequiredFields({
    apiRef,
    rowId: id,
    requiredFields: ['color'],
  });

  const hasDuplicateColorMapping = !isUpdatingRowUnique({
    apiRef,
    rowId: id,
    uniqueFields: ['cellClassId', 'tags', 'stainTypeId', 'color'],
  });

  return missingCellClassId
    ? 'Missing Cell Class ID'
    : missingColor
    ? 'Missing Color'
    : hasDuplicateColorMapping
    ? 'Duplicate Color Mapping'
    : '';
};
