import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import { DisplayedField } from 'interfaces/genericFields';
import { Permission, permissionToName } from 'interfaces/permissionOption';
import { roleToTitle } from 'interfaces/roles';
import { UserCredentials } from 'interfaces/userCredentials';
import { filter, find, flatten, includes, isEmpty, join, map, reduce, toPairs, values } from 'lodash';
import React from 'react';
import { UserCredentialsFieldsContext } from './UserCredentialsFieldsContext';

export const primaryEmailField: DisplayedField<UserCredentials, string, UserCredentialsFieldsContext> = {
  filterType: 'text',
  dataKey: 'primaryEmail',
  label: 'Email',
  sortable: true,
  columnWidth: { min: 200, flex: 1 },
  // https://mui.com/x/migration/migration-data-grid-v6/#css-classes-and-styling
  display: 'flex',
  renderCell:
    () =>
    ({ value }) =>
      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: 'flex' }}>{value}</div>,
};

export const nameField: DisplayedField<UserCredentials, string, UserCredentialsFieldsContext> = {
  filterType: 'text',
  dataKey: 'name',
  label: 'Name',
  sortable: true,
  columnWidth: { min: 200, flex: 1 },
  // https://mui.com/x/migration/migration-data-grid-v6/#css-classes-and-styling
  display: 'flex',
  renderCell:
    () =>
    ({ value }) =>
      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: 'flex' }}>{value}</div>,
};

export const rolesField: DisplayedField<UserCredentials, string[], UserCredentialsFieldsContext> = {
  filterType: 'multiSelect',
  cellEditType: 'checkbox-group',
  dataKey: 'roles',
  label: 'Roles',
  columnWidth: { min: 250, flex: 1 },
  optionsGetter: ({ roles }) => map(roles, (role) => ({ value: role.id, label: roleToTitle(role) })),
  valueFormatter: ({ value }, { roles }) =>
    join(
      map((value || []) as string[], (id) => {
        const entry = find(roles, { id });
        return roleToTitle(entry);
      }),
      ', '
    ),
  // https://mui.com/x/migration/migration-data-grid-v6/#css-classes-and-styling
  display: 'flex',
  renderCell:
    () =>
    ({ formattedValue }) =>
      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{formattedValue}</div>,
};

export const permissionsField: DisplayedField<UserCredentials, string[], UserCredentialsFieldsContext> = {
  filterType: 'multiSelect',
  cellEditType: 'checkbox-group',
  dataKey: 'permissions',
  label: 'Permissions',
  columnWidth: { min: 250, flex: 3 },
  optionsGetter: ({ permissions }) =>
    map(permissions, (permission) => ({ value: permission.permission, label: permissionToName(permission) })),
  fixedOptionsGetter: (params, options, { roles }) => {
    const userRoles: string[] = params.api.getRowWithUpdatedValues(params.id, 'roles')?.['roles'];
    return flatten(
      map(userRoles, (roleId: string) => {
        const roleEntry = find(roles, { id: roleId });
        return map(roleEntry?.permissions, (value) => find(options, { value }));
      })
    );
  },
  valueFormatter: ({ value }, { permissions }) =>
    join(
      map((value || []) as Permission[], (id) => {
        const entry = find(permissions, { permission: id });
        return permissionToName(entry, id);
      }),
      ', '
    ),
  renderCell:
    ({ permissions, roles }) =>
    ({ id, value, api }) => {
      const row = api.getRow(id);
      const permissionsFromRoles = reduce(
        (row?.roles as string[]) || [],
        (acc, roleId) => {
          const roleEntry = find(roles, { id: roleId });
          const title = roleToTitle(roleEntry);
          acc[title] = map(roleEntry?.permissions, (v) => {
            const permissionOption = find(permissions, { permission: v });
            return permissionToName(permissionOption, v);
          });
          return acc;
        },
        {} as Record<string, string[]>
      );
      const directPermissionsIds = filter(
        (value as Permission[]) || [],
        (v) => !includes(flatten(values(permissionsFromRoles)), v)
      );
      const directPermissions = map(directPermissionsIds, (v) => {
        const permissionOption = find(permissions, { permission: v });
        return permissionToName(permissionOption, v);
      });
      return !isEmpty(values(permissionsFromRoles)) || !isEmpty(directPermissions) ? (
        <Grid container spacing={2}>
          {map(toPairs(permissionsFromRoles), ([role, rolePermissions]) => (
            <Grid item key={role}>
              <Accordion disableGutters variant="outlined" sx={{ background: 'none' }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant="subtitle1">
                    Permissions as {'"'}
                    {role}
                    {'"'}:
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1">{join(rolePermissions, ', ')}</Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
          {!isEmpty(directPermissions) ? (
            <Grid item key="direct">
              <Accordion disableGutters variant="outlined" sx={{ background: 'none' }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant="subtitle1">Direct permissions:</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1">{join(directPermissions, ', ')}</Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ) : null}
        </Grid>
      ) : null;
    },
};

export const userCredentialsFields = [primaryEmailField, nameField, rolesField, permissionsField];
