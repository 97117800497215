import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import RateReviewRoundedIcon from '@mui/icons-material/RateReviewRounded';
import { Box, Typography, useTheme } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { sum, values } from 'lodash';
import React, { useMemo } from 'react';

import { getRenderSlideIdCell } from 'components/atoms/DataGridRenderers/SlideIdCellRenderer';
import { Annotation, AnnotationAssignment, AnnotationWorkingStates } from 'interfaces/annotation';
import AnnotationAssignmentToolbar, { AnnotationAssignmentToolbarProps } from './AnnotationAssignmentTableToolbar';

interface AnnotationAssignmentTableProps {
  studyId: string;
  annotationAssignment: AnnotationAssignment;
}

const ANNOTATIONS_PAGE_SIZE = 10;
const ANNOTATIONS_TABLE_HEIGHT = 515; // the height of the table with 10 rows in compact density

declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides extends AnnotationAssignmentToolbarProps {}
}

const AnnotationAssignmentTable: React.FC<AnnotationAssignmentTableProps> = ({ studyId, annotationAssignment }) => {
  const theme = useTheme();

  const rows = annotationAssignment?.annotations;

  const dataGridSx = useMemo(
    () => ({
      '& .MuiDataGrid-columnHeaders': {
        backgroundColor: theme.palette.mode == 'light' ? theme.palette.grey[200] : theme.palette.grey[900],
        borderRadius: 0,
        borderTop: `1px solid ${theme.palette.mode == 'light' ? 'lightgrey' : 'rgb(81, 81, 81)'}`,
      },
      '.annotating': {
        color: theme.palette.primary.main,
      },
      '.qa': {
        color: theme.palette.info.main,
      },
      '.done': {
        color: theme.palette.success.main,
      },
      backgroundColor: theme.palette.background.paper,
    }),
    [theme]
  );

  const columns: GridColDef<Annotation>[] = [
    {
      field: 'slideId',
      headerName: 'Slide ID',
      width: 350,
      renderCell: getRenderSlideIdCell({
        target: '_blank',
        rel: 'noopener noreferrer',
        caseStudyId: annotationAssignment?.studyId ?? studyId,
        activeAnnotationAssignment: annotationAssignment?.annotationAssignmentId,
      }),
    },
    {
      field: '# of Annotations',
      headerName: '# of Annotations',
      width: 200,
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      valueGetter: (_value, annotation) => sum(values(annotation.annotationSummary)),
    },
    {
      field: 'tagger',
      headerName: 'Assignee',
      width: 200,
      valueGetter: (_value, annotation) => annotation.tagger?.name,
    },
    {
      field: 'workingState',
      headerName: 'Status',
      width: 200,
      renderCell: renderStatusCell,
    },
  ];

  const autoHeight = (rows ? rows.length : 0) < ANNOTATIONS_PAGE_SIZE;

  return (
    <Box sx={{ width: '100%', height: autoHeight ? undefined : ANNOTATIONS_TABLE_HEIGHT }}>
      <DataGrid
        slots={{ toolbar: AnnotationAssignmentToolbar }}
        slotProps={{
          toolbar: { annotationAssignment },
          pagination: {
            SelectProps: {
              sx: { width: 'auto' },
            },
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: ANNOTATIONS_PAGE_SIZE,
            },
          },
        }}
        pageSizeOptions={[ANNOTATIONS_PAGE_SIZE]}
        getCellClassName={getCellClassName}
        rows={rows}
        columns={columns}
        getRowId={getRowId}
        sx={dataGridSx}
        density="compact"
        autoHeight={autoHeight}
      />
    </Box>
  );
};

function getRowId(row: Annotation) {
  return row.annotationId;
}

function getCellClassName(params: { field: string; row: Annotation }) {
  if (params.field === 'workingState') {
    const state: AnnotationWorkingStates = params.row.workingState as AnnotationWorkingStates;
    return state === AnnotationWorkingStates.Annotating
      ? 'annotating'
      : state === AnnotationWorkingStates.QA
      ? 'qa'
      : 'done';
  }
  return '';
}

const renderStatusCell: GridColDef['renderCell'] = (params) => {
  const state: AnnotationWorkingStates = params.row.workingState;
  return (
    <>
      {state === AnnotationWorkingStates.Annotating ? (
        <DescriptionRoundedIcon />
      ) : state === AnnotationWorkingStates.QA ? (
        <RateReviewRoundedIcon />
      ) : (
        <CheckCircleRoundedIcon />
      )}
      <Typography sx={{ ml: 1 }}>{state}</Typography>
    </>
  );
};

export default AnnotationAssignmentTable;
