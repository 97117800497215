import { Grid, Typography, useTheme } from '@mui/material';
import React from 'react';

export const MultiplexChannelIdName: React.FC<{
  channelId: string;
  channelName: string;
}> = ({ channelId, channelName }) => {
  const theme = useTheme();
  const subTextColor = theme.palette.text.secondary;

  return (
    <>
      <Grid sx={{ color: subTextColor }} item md={1}>
        <Typography sx={{ color: subTextColor, fontSize: 9 }} variant="caption">
          {channelId}
        </Typography>
      </Grid>
      <Grid item md={'auto'}>
        <Typography sx={{ color: subTextColor, fontSize: 9 }} variant="caption">
          {channelName}
        </Typography>
      </Grid>
    </>
  );
};
