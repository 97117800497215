import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import { getSlides, SlideUpdate } from 'api/slides';
import { CaseUpdate, createProcedures, overrideStudy as overrideStudyApiCall } from 'api/study';
import { useRowSelectionContext } from 'components/atoms/RowSelectionContext';
import DashboardTabs from 'components/DashboardTabs';
import {
  getBaseRowIdFromUnwoundRowMetadata,
  getInnerRowIdFromUnwoundRowMetadata,
  unwindRows,
  UnwoundRow,
  unwoundRowMetadataFromId,
} from 'interfaces/genericFields/unwindRowsWithInnerArrays';
import validator from 'validator';

import { useTableEditingContext } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { Procedure } from 'interfaces/procedure';
import { ProceduresFieldsContext } from 'interfaces/procedure/fields/helpers';
import { slidesBaseFields } from 'interfaces/procedure/fields/slideFields';
import { Slide } from 'interfaces/slide';
import {
  chunk,
  cloneDeep,
  compact,
  concat,
  filter,
  find,
  first,
  flatMap,
  forEach,
  get,
  includes,
  indexOf,
  isArray,
  isEmpty,
  isNil,
  keys,
  map,
  omit,
  reduce,
  size,
  slice,
  some,
  times,
} from 'lodash';
import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import readXlsxFile, { Row } from 'read-excel-file';
import { ArrayParam, QueryParamConfig, StringParam, useQueryParam, withDefault } from 'use-query-params';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { encodeQueryParamsUsingSchema } from 'utils/useEncodedFilters';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { DataGridInterfaceWrapper } from '../ProceduresPage/DataGrids/DataGridInterfaceWrapper';
import { getProcedureChanges, getSlideChanges } from '../ProceduresPage/DataGrids/useBulkEditControl';
import { useEditableFields } from '../ProceduresPage/DataGrids/useEditableFields';
import { DEFAULT_PAGE_SIZE } from '../ProceduresPage/ProcedurePagination';
import { createMockCasesForSlides, usePendingSlides } from '../ProceduresPage/usePendingSlides';
import { StudyManifestConfirmationDialog } from './ConfirmationDialog';
import {
  addCaseToRelevantList,
  getCaseFromRowCombinedWithRealData,
  ManifestError,
  validateMandatoryHeaders,
} from './helpers';
import { StudyManifestInProgressDialog } from './InProgressDialog';
import NotEditableSlidesDataGrid from './NotEditableSlidesDataGrid';
import { useAllStudyProcedures } from './useAllStudyProcedures';

interface StudyManifestDialogProps {
  studyId: string;
  onClose: (event?: any) => void;
  open: boolean;
}

type ManifestTab = 'Manifest' | 'Not Ingested Slides' | 'Pending Slides';
const ManifestTabParam: QueryParamConfig<ManifestTab> = withDefault(
  StringParam,
  'Manifest'
) as QueryParamConfig<ManifestTab>;

const CaseManifestDialog: FunctionComponent<React.PropsWithChildren<StudyManifestDialogProps>> = ({
  studyId,
  onClose,
  open,
}) => {
  const queryClient = useQueryClient();
  const { labId } = useCurrentLabId();
  const { selectionMode, selectedRows, omittedRows } = useRowSelectionContext();
  const { fieldsContext: procedureFieldContext } = useTableEditingContext<Procedure, ProceduresFieldsContext, Slide>();

  const [rawRows, setRawRows] = useState<Row[]>([]);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [warningMessages, setWarningMessages] = useState([]);
  const [initialCases, setInitialCases] = useState<Procedure[]>([]);
  const [updatedCases, setUpdatedCases] = useState<Procedure[]>([]);
  // Slides that are in the manifest but not in the database
  const [notIngestedSlides, setNotIngestedSlides] = useState<Procedure[]>([]);
  const [slideIdsToFetch, setSlideIdsToFetch] = useState<string[]>([]);

  const [completedBatches, setCompletedBatches] = useState(0);
  const [totalBatches, setTotalBatches] = useState(0);

  useEffect(() => {
    // only when all batches are completed, refetch the queries
    if (totalBatches > 0 && completedBatches === totalBatches) {
      queryClient.refetchQueries(['procedures']);
      queryClient.refetchQueries(['pendingSlides']);
      queryClient.refetchQueries(['pendingSlidesCount']);
      queryClient.refetchQueries(['studies', labId, 'withStatistics']);
      queryClient.refetchQueries(['study', studyId, labId]);
    }
  }, [completedBatches, totalBatches]);

  const getEncodedParams = (slideIdsToInclude: string[]) =>
    encodeQueryParamsUsingSchema(
      { slideIdsToInclude },
      {
        slideIdsToInclude: ArrayParam,
      },
      {
        arrayFormat: 'repeat',
      }
    );

  const batchedSlideIds = useMemo(
    () => (slideIdsToFetch && size(slideIdsToFetch) > 0 ? chunk(slideIdsToFetch, 100) : []),
    [slideIdsToFetch]
  );

  const slideQueries = useQueries({
    queries: map(batchedSlideIds, (batch) => {
      return {
        queryKey: ['slides', getEncodedParams(batch), { pending: false, ignoreLabId: true }],
        queryFn: ({ signal }: { signal?: AbortSignal }) =>
          getSlides({ queryParams: getEncodedParams(batch), pending: false, ignoreLabId: true }, signal),
        retry: true,
      };
    }),
  });

  const isSlidesFetching = some(slideQueries, (q) => q.isFetching);
  const isSlidesError = some(slideQueries, (q) => q.isError);

  // this query is to fetch the slides from the manifest that are not pending in the current lab, or in existing study.
  // meaning that they are slides from different study or lab.
  // those slides are not editable because we don't want to change data from other studies or labs.
  const notEditableSlides = compact(concat(flatMap(slideQueries, 'data')));

  const [runningDialogOpen, setRunningDialogOpen] = useState(false);

  const {
    procedures: existingProcedures,
    isLoading: isCasesLoading,
    proceduresQueries,
  } = useAllStudyProcedures(studyId, open);

  const { allMockCasesOfPendingSlides, isPendingSlidesLoading, pendingSlidesQuery } = usePendingSlides({
    enabled: open,
  });

  const editableExistingAndMockCases = useMemo(
    () => concat(allMockCasesOfPendingSlides || [], existingProcedures || []),
    [allMockCasesOfPendingSlides, existingProcedures]
  );

  const numMockCasesOfPendingSlides = allMockCasesOfPendingSlides?.length ?? 0;
  const notEditableSlidesMockCases = useMemo(() => {
    const mockCases = createMockCasesForSlides(notEditableSlides, labId);
    // change the id of the mock cases to be unique
    return map(mockCases, (mockCase, index) => {
      mockCase.id = numMockCasesOfPendingSlides + index;
      return mockCase;
    });
  }, [size(notEditableSlides), numMockCasesOfPendingSlides, labId]);

  const allExistingAndMockCases = useMemo(() => {
    return concat(editableExistingAndMockCases || [], notEditableSlidesMockCases || []);
  }, [editableExistingAndMockCases, notEditableSlidesMockCases]);

  useEffect(() => {
    if (notEditableSlidesMockCases && !isEmpty(notEditableSlidesMockCases)) {
      processRows(rawRows, editableExistingAndMockCases, notEditableSlidesMockCases, true);
    }
  }, [notEditableSlidesMockCases]);

  const resetData = useCallback(() => {
    setInitialCases([]);
    setUpdatedCases([]);
    setNotIngestedSlides([]);
  }, []);

  const validateManifestHeaders = (headers: string[], warnings: string[]) => {
    try {
      return validateMandatoryHeaders(headers, warnings);
    } catch (error) {
      resetData();
      if (error instanceof ManifestError) {
        setErrorMessages([error.message]);
      } else {
        console.error(error);
      }
      return false;
    } finally {
      setWarningMessages(warnings);
    }
  };

  const [onlyManifestCases, setOnlyManifestCases] = useState<Procedure[]>(null);

  const combineManifestRowsWithExistingCases = useCallback(
    (manifestCases: Procedure[]) => {
      if (!manifestCases) {
        return;
      }

      const finalCases = cloneDeep(manifestCases);
      const manifestSlideIds = map(flatMap(finalCases, 'slides'), 'id');
      forEach(finalCases, (manifestCase) => {
        const existingCase = manifestCase && find(existingProcedures, { label: manifestCase.label });
        if (existingCase) {
          const slidesToAdd = filter(
            existingCase.slides,
            (existingSlide) => !includes(map(manifestCase.slides, 'id'), existingSlide.id)
          );
          forEach(slidesToAdd, (slideToAdd) => {
            if (includes(manifestSlideIds, slideToAdd.id)) {
              setWarningMessages((prev) => [
                ...prev,
                `Slide id ${slideToAdd.id} found also in Case ${manifestCase.label}. Moving the slide from the old case to the new case.`,
              ]);
            }
          });
          manifestCase.slides = concat(manifestCase.slides, slidesToAdd);
        }
      });
      setInitialCases(finalCases);
      setUpdatedCases(finalCases);
    },
    [existingProcedures]
  );

  const handleOverrideCases = (overrideCasesState: boolean, onlyManifestCasesState: Procedure[]) => {
    if (overrideCasesState) {
      if (onlyManifestCasesState != null) {
        setInitialCases(onlyManifestCasesState);
        setUpdatedCases(onlyManifestCasesState);
      }
    } else {
      combineManifestRowsWithExistingCases(onlyManifestCasesState);
    }
  };

  const processRows = useCallback(
    (
      excelRows: Row[],
      realAndMockCasesToCombineWith: Procedure[],
      notEditableSlidesMockCasesList: Procedure[],
      afterSlideIdsFetched: boolean = false
    ) => {
      const warnings: string[] = [];

      const fileHeaders = map(first(excelRows), (header: string) => header.toLowerCase());

      if (!validateManifestHeaders(fileHeaders, warnings)) {
        return;
      }

      const allCases = concat(realAndMockCasesToCombineWith, notEditableSlidesMockCasesList);

      let tmpCases: Procedure[] = [];
      let tmpMissingSlides: Procedure[] = [];
      const tmpSlideIdsToFetch: string[] = [];
      try {
        // Skip the first row (headers)
        forEach(slice(excelRows, 1), (row: Row, index) => {
          let newCase: Procedure;
          try {
            newCase = getCaseFromRowCombinedWithRealData(
              procedureFieldContext,
              fileHeaders,
              row,
              labId,
              studyId,
              index,
              realAndMockCasesToCombineWith,
              notEditableSlidesMockCasesList
            );
          } catch (error) {
            if (error instanceof ManifestError) {
              throw new ManifestError(`Error in row ${index + 1}: ${error.message}`);
            } else {
              throw error;
            }
          }

          const currentSlide = first(newCase.slides);
          if (
            includes(fileHeaders, 'slide id') &&
            isNil(currentSlide?.originalFileName) &&
            validator.isUUID(currentSlide?.id) &&
            !afterSlideIdsFetched // add to tmpSlideIdsToFetch only if it's the first iteration of this file
          ) {
            tmpSlideIdsToFetch.push(first(newCase.slides)?.id);
          } else {
            const {
              updatedCases: updatedTmpCases,
              updatedMissingSlides,
              warning,
            } = addCaseToRelevantList(newCase, tmpMissingSlides, tmpCases, allCases);
            if (warning) {
              warnings.push(warning);
            }
            tmpCases = updatedTmpCases;
            tmpMissingSlides = updatedMissingSlides;
          }
        });

        setOnlyManifestCases(tmpCases);
        handleOverrideCases(overrideCases, tmpCases);
        setNotIngestedSlides(tmpMissingSlides);
        // after fetching the slides, we call processRows again, this condition is to make sure
        // notEditableSlides still contains the last data for the slideIdsToFetch
        if (!afterSlideIdsFetched) {
          setSlideIdsToFetch(tmpSlideIdsToFetch);
        }
        setErrorMessages([]);
      } catch (error) {
        resetData();
        if (error instanceof ManifestError) {
          setErrorMessages([error.message]);
        } else {
          console.error(error);
        }
      }
      setWarningMessages(warnings);
    },
    [labId, studyId, combineManifestRowsWithExistingCases]
  );

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file: File = first(event.target.files);
    if (file) {
      const excelRows: Row[] = await readXlsxFile(file);
      if (isEmpty(excelRows) || excelRows.length === 1) {
        setErrorMessages(['No data found in the file']);
        return;
      }
      setRawRows(excelRows);
      processRows(excelRows, editableExistingAndMockCases, notEditableSlidesMockCases);
    }
  };

  const pendingSlidesThatNotInTheManifest = useMemo(() => {
    return filter(
      allMockCasesOfPendingSlides,
      (mockCase) =>
        !find(updatedCases, (updatesCase) =>
          some(updatesCase.slides, { originalFileName: first(mockCase.slides)?.originalFileName })
        )
    );
  }, [allMockCasesOfPendingSlides, updatedCases]);

  const tabs: ManifestTab[] = ['Manifest', 'Not Ingested Slides', 'Pending Slides'];
  const tabsDisplayTexts: string[] = [
    'Manifest',
    `Not Ingested Slides (${flatMap(notIngestedSlides, 'slides').length})`,
    `Pending Slides (${pendingSlidesThatNotInTheManifest.length})`,
  ];
  const [activeTab, setActiveTab] = useQueryParam<ManifestTab>('tab', ManifestTabParam);
  const activeTabIndex = indexOf(tabs, activeTab as string) !== -1 ? indexOf(tabs, activeTab as string) : 0;

  const changeTab = (newTab: ManifestTab) => {
    setActiveTab(newTab);
  };

  const updatedCasesUnwoundSlides: UnwoundRow<Procedure, Slide>[] = useMemo(() => {
    return unwindRows<Procedure, Slide>({
      rows: updatedCases || [],
      arrayFieldToUnwind: 'slides',
      unwoundRowIdField: 'id',
      omitUnwoundFields: ['cancerTypeId'],
    }) as any[];
  }, [updatedCases]);
  const allExistingAndMockCasesUnwoundSlides: UnwoundRow<Procedure, Slide>[] = useMemo(() => {
    return unwindRows<Procedure, Slide>({
      rows: allExistingAndMockCases || [],
      arrayFieldToUnwind: 'slides',
      unwoundRowIdField: 'id',
      omitUnwoundFields: ['cancerTypeId'],
    }) as any[];
  }, [allExistingAndMockCases]);

  const { slidesFields: fields } = useEditableFields();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGE_SIZE);

  useEffect(() => {
    if (!isEmpty(updatedCasesUnwoundSlides)) {
      const errors: string[] = [];

      // Go through all rows and fields and check if there are any errors
      forEach(updatedCasesUnwoundSlides, (row: UnwoundRow<Procedure, Slide>, index: number) => {
        forEach(fields, (field) => {
          const cellError = field?.getError?.({ value: get(row, field.dataKey), row, context: procedureFieldContext });
          if (cellError) {
            errors.push(`${cellError}, On page ${Math.floor(index / rowsPerPage + 1)}`);
          }
        });
      });
      setErrorMessages(Array.from(new Set(errors)));
    }
  }, [rowsPerPage, updatedCasesUnwoundSlides, fields]);

  const applyCellValueChangedClass: (id: string | number, field: string, row: any) => boolean = (
    id: string | number,
    field: string
  ) => {
    const existingValue = get(find(updatedCasesUnwoundSlides, { id: id }), field);
    let oldValue;

    if (typeof id == 'string') {
      const { innerRowId: slideId } = unwoundRowMetadataFromId(id);
      oldValue = get(
        find(allExistingAndMockCasesUnwoundSlides, (unwoundSlide) => {
          return unwoundSlide?._unwoundRowMetadata?.innerRowId === slideId;
        }),
        field
      );
    } else {
      oldValue = get(find(allExistingAndMockCasesUnwoundSlides, { id }), field);
    }

    // this is to not show null - undefined - empty array as change
    const oldValueIsNoneOrEmpty = !oldValue || (isArray(oldValue) && isEmpty(oldValue));
    const existingValueIsNoneOrEmpty = !existingValue || (isArray(existingValue) && isEmpty(existingValue));
    if (oldValueIsNoneOrEmpty && existingValueIsNoneOrEmpty) {
      return false;
    }
    return oldValue !== existingValue;
  };

  const onBulkSlideDataChange = (changes: Partial<SlideUpdate>, slideIds: string[]) => {
    setUpdatedCases(
      map(updatedCases, (updatedCase) => {
        return {
          ...updatedCase,
          slides: map(updatedCase.slides, (slide) => {
            return includes(slideIds, slide.id) ? { ...slide, ...changes } : slide;
          }),
        };
      })
    );
  };

  const onBulkCaseDataChange = (changes: Partial<CaseUpdate>, caseIds: number[]) => {
    setUpdatedCases(
      map(updatedCases, (updatedCase) => {
        return includes(caseIds, updatedCase.id) ? { ...updatedCase, ...changes } : updatedCase;
      })
    );
  };

  const onCaseDataChange = (caseId: number, changes: Partial<CaseUpdate>) => {
    onBulkCaseDataChange(changes, [caseId]);
  };

  const onSlideDataChange = (slideId: string, changes: Partial<SlideUpdate>) => {
    onBulkSlideDataChange(changes, [slideId]);
  };

  const onBulkEditSave = (changes: { [field: string]: any }) => {
    const slidesChanges = getSlideChanges(changes);
    if (!isEmpty(keys(slidesChanges))) {
      if (selectionMode === 'select') {
        const selectedSlideIds = getInnerRowIdFromUnwoundRowMetadata(selectedRows as string[]);
        onBulkSlideDataChange(slidesChanges, selectedSlideIds);
      } else {
        const omittedSlideIds = getInnerRowIdFromUnwoundRowMetadata(omittedRows as string[]);
        const casesToOmitFrom = showOnlyErrors ? casesWithError : updatedCases;
        const selectedSlideIds = filter(
          map(flatMap(casesToOmitFrom, 'slides'), 'id'),
          (slideId) => !includes(omittedSlideIds, slideId)
        );
        onBulkSlideDataChange(slidesChanges, selectedSlideIds);
      }
    }

    const procedureChanges = getProcedureChanges(changes);
    if (!isEmpty(keys(procedureChanges))) {
      if (selectionMode === 'select') {
        const selectedCaseIds = getBaseRowIdFromUnwoundRowMetadata(selectedRows as string[]);
        onBulkCaseDataChange(
          procedureChanges,
          map(selectedCaseIds, (caseId) => Number(caseId))
        );
      } else {
        const omittedCaseIds = map(getBaseRowIdFromUnwoundRowMetadata(omittedRows as string[]), (caseId) =>
          Number(caseId)
        );
        const casesToOmitFrom = showOnlyErrors ? casesWithError : updatedCases;
        const selectedCaseIds = filter(map(casesToOmitFrom, 'id'), (caseId) => !includes(omittedCaseIds, caseId));
        onBulkCaseDataChange(procedureChanges, selectedCaseIds);
      }
    }
  };

  const overrideStudyMutation = useMutationWithErrorSnackbar({
    mutationDescription: `override study ${studyId}`,
    mutationFn: overrideStudyApiCall,
    onError: (error: any, variables: { studyId: string; procedureLabels: string[]; labId: string }) => {
      console.error(`Error occurred while overriding cases for study ${variables.studyId}:`, error);
    },
    onSuccess: () => {
      setCompletedBatches((prev) => prev + 1);
    },
  });
  const createProceduresMutation = useMutationWithErrorSnackbar({
    mutationDescription: `create procedures for study ${studyId}`,
    mutationFn: createProcedures,
    onError: (
      error: any,
      variables: {
        studyId: string;
        procedures: Procedure[];
        overrideStudy: boolean;
      }
    ) => {
      console.error(`Error occurred while creating procedures for study ${variables.studyId}:`, error);
    },
    onSuccess: () => {
      setCompletedBatches((prev) => prev + 1);
    },
  });

  const onExecuteManifest = async ({ overrideStudy }: { overrideStudy?: boolean } = { overrideStudy: false }) => {
    setRunningDialogOpen(true);
    // save the cases 50 at a time
    const CASES_PER_BATCH = 50;
    const totalCases = updatedCases?.length ?? 0;
    const totalBatchesToSave = Math.ceil(totalCases / CASES_PER_BATCH);
    setTotalBatches(totalBatchesToSave + (overrideCases ? 1 : 0));

    if (overrideStudy) {
      // If this fails, the other batches should not be executed
      try {
        await overrideStudyMutation.mutateAsync({ studyId, procedureLabels: map(updatedCases, 'label') });
      } catch (error) {
        console.error('Error occurred while overriding cases, skipping the rest of the batches');
        return;
      }
    }
    times(totalBatchesToSave, (batchIndex) => {
      const batchCases = slice(updatedCases, batchIndex * CASES_PER_BATCH, (batchIndex + 1) * CASES_PER_BATCH);
      // add slide ids to the batch cases
      const batchCasesWithSlideIds = map(batchCases, (updatedCase) => {
        return {
          ...updatedCase,
          slideIds: map(updatedCase.slides, 'id'),
        };
      });
      createProceduresMutation.mutate({ studyId, procedures: batchCasesWithSlideIds });
    });
  };

  const disableInput = isCasesLoading || isPendingSlidesLoading;

  const [showOnlyErrors, setShowOnlyErrors] = useState(false);
  const onShowOnlyErrorsSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowOnlyErrors(Boolean(event.target.checked));
  };

  const casesUnwoundSlidesWithError = useMemo(
    () =>
      filter(updatedCasesUnwoundSlides, (row: UnwoundRow<Procedure, Slide>) =>
        some(fields, (field) =>
          field?.getError?.({ value: get(row, field.dataKey), row, context: procedureFieldContext })
        )
      ),
    [updatedCasesUnwoundSlides]
  );
  const casesWithError = useMemo(() => {
    const caseIdsWithError = map(casesUnwoundSlidesWithError, (slide) => slide._unwoundRowMetadata.baseRowId);
    const slideIdsWithError = map(casesUnwoundSlidesWithError, (slide) => slide._unwoundRowMetadata.innerRowId);
    return map(
      filter(updatedCases, (updatedCase) => includes(caseIdsWithError, updatedCase.id)),
      (updatedCase) => {
        return {
          ...updatedCase,
          slides: filter(updatedCase.slides, (slide) => {
            return includes(slideIdsWithError, slide.id);
          }),
        };
      }
    );
  }, [updatedCases, casesUnwoundSlidesWithError]);

  const pageCases = getPageCasesInSlidesMode(showOnlyErrors ? casesWithError : updatedCases, page, rowsPerPage);
  const totalSlides = flatMap(
    showOnlyErrors ? casesWithError : updatedCases,
    (updatedCase) => updatedCase.slides
  ).length;
  const totalCases = showOnlyErrors ? casesWithError?.length ?? 0 : updatedCases?.length ?? 0;
  const onPageChange = (withOffset: boolean) => (event: React.ChangeEvent<unknown>, nextPage?: number) => {
    const nextPageWithOffset = withOffset ? nextPage + 1 : nextPage;
    if (page !== nextPageWithOffset) {
      setPage(nextPageWithOffset);
    }
  };

  const onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newPageSize = parseInt(event.target.value) ?? DEFAULT_PAGE_SIZE;
    if (newPageSize !== rowsPerPage) {
      setRowsPerPage(newPageSize);
    }
  };

  const [runManifestConfirmModalOpen, setRunManifestConfirmModalOpen] = useState(false);

  const [overrideCases, setOverrideCases] = useState(false);
  const onOverrideCasesSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOverrideCases(Boolean(event.target.checked));
    handleOverrideCases(Boolean(event.target.checked), onlyManifestCases);
  };
  const slidesMovedBetweenCases: (Slide & { newCaseLabel: string; oldCaseLabel: string })[] = useMemo(() => {
    const manifestSlidesWithCaseLabel: (Slide & { caseLabel: string })[] = getSlidesWithCaseLabel(updatedCases);
    const existingSlidesWithCaseLabel: (Slide & { caseLabel: string })[] = getSlidesWithCaseLabel(existingProcedures);

    const slidesWithOldAndNewCaseLabel = compact(
      map(manifestSlidesWithCaseLabel, (slide) => {
        const existingSlide = find(existingSlidesWithCaseLabel, { id: slide.id });
        if (!existingSlide) {
          return null;
        }
        return {
          ...omit(slide, 'caseLabel'),
          newCaseLabel: slide.caseLabel,
          oldCaseLabel: existingSlide?.caseLabel,
        };
      })
    );
    return filter(slidesWithOldAndNewCaseLabel, (slide) => slide.newCaseLabel !== slide.oldCaseLabel);
  }, [updatedCases, existingProcedures]);

  const emptyCases = useMemo(() => {
    const casesWithRemovedSlides: { [key: string]: string[] } = {}; // { caseLabel: slideIds[] }
    forEach(slidesMovedBetweenCases, (duplicatedSlide) => {
      const existingCase = find(existingProcedures, { label: duplicatedSlide.oldCaseLabel });
      // not supposed to happen, but just in case
      if (!existingCase) {
        console.error(`Existing case not found for slide ${duplicatedSlide.id}`);
      } else {
        let existingCaseSlideIds = map(existingCase.slides, 'id');
        if (casesWithRemovedSlides[existingCase.label]) {
          existingCaseSlideIds = casesWithRemovedSlides[existingCase.label];
        }
        casesWithRemovedSlides[existingCase.label] = filter(
          existingCaseSlideIds,
          (slideId) => slideId !== duplicatedSlide.id
        );
      }
    });

    const caseLabels: string[] = keys(casesWithRemovedSlides);

    return filter(caseLabels, (caseLabel) => isEmpty(casesWithRemovedSlides[caseLabel]));
  }, [slidesMovedBetweenCases, existingProcedures]);

  return (
    <>
      <Dialog open={open} fullScreen scroll="paper">
        <Grid container px={1} justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <DialogTitle>Manifest Preview</DialogTitle>
              </Grid>
              <Grid item>
                <input type="file" onChange={handleFileChange} disabled={disableInput} />
              </Grid>
              {disableInput ? (
                <Typography>Loading study data...</Typography>
              ) : (
                <Grid item ml={2}>
                  <Grid container justifyContent="end" alignItems="center">
                    <Typography>Override existing cases</Typography>
                    <Switch checked={overrideCases} size="small" onChange={onOverrideCasesSwitchChange} />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent dividers>
          <DashboardTabs
            tabs={tabs}
            tabsDisplayTexts={tabsDisplayTexts}
            changeTab={changeTab}
            defaultValueIndex={activeTabIndex}
          />

          {activeTab === 'Manifest' ? (
            <Box my={1}>
              <Grid item container p={0.5}>
                <Grid item>
                  <Typography>Show only errors</Typography>
                </Grid>
                <Grid item>
                  <Switch checked={showOnlyErrors} size="small" onChange={onShowOnlyErrorsSwitchChange} />
                </Grid>
              </Grid>
              {isSlidesError && (
                <Typography color="error">Error occurred while fetching slides. Please try again</Typography>
              )}
              <DataGridInterfaceWrapper
                forceHeight={650}
                casesInPage={pageCases}
                totalSlides={totalSlides}
                totalCases={totalCases}
                isLoading={some(proceduresQueries, 'isFetching') || pendingSlidesQuery?.isFetching || isSlidesFetching}
                disableEditing={false}
                pendingSlidesMode={false}
                onCaseDataChange={onCaseDataChange}
                onSlideDataChange={onSlideDataChange}
                applyCellValueChangedClass={applyCellValueChangedClass}
                onBulkEditSave={onBulkEditSave}
                hideActionsMenu
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                forcePage={page}
                forcePageSize={rowsPerPage}
                dataGridProps={{
                  isCellEditable: (params) => {
                    const { innerRowId: slideId } = unwoundRowMetadataFromId(params.row.id);
                    const isSlideField = includes(map(slidesBaseFields, 'dataKey'), params.field);
                    return isSlideField ? !includes(map(notEditableSlides, 'id'), slideId) : true;
                  },
                }}
              />
            </Box>
          ) : activeTab === 'Not Ingested Slides' ? (
            <NotEditableSlidesDataGrid procedures={notIngestedSlides} />
          ) : activeTab === 'Pending Slides' ? (
            <NotEditableSlidesDataGrid procedures={pendingSlidesThatNotInTheManifest} />
          ) : null}
          <ErrorsSection errorMessages={errorMessages} />
          <WarningsSection warningMessages={warningMessages} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setUpdatedCases(initialCases);
            }}
          >
            Reset Changes
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setRunManifestConfirmModalOpen(true)}
            disabled={
              createProceduresMutation.isLoading || !isEmpty(errorMessages) || isEmpty(updatedCases) || showOnlyErrors
            }
          >
            {!createProceduresMutation.isLoading ? 'Run' : 'Running...'}
          </Button>
        </DialogActions>
      </Dialog>
      <StudyManifestInProgressDialog
        open={runningDialogOpen}
        completedBatches={completedBatches}
        totalBatches={totalBatches}
        onClose={onClose}
        setRunningDialogOpen={setRunningDialogOpen}
        isError={overrideStudyMutation.isError || createProceduresMutation.isError}
      />
      <StudyManifestConfirmationDialog
        open={runManifestConfirmModalOpen}
        onClose={() => setRunManifestConfirmModalOpen(false)}
        onExecuteManifest={onExecuteManifest}
        overrideCases={overrideCases}
        slidesMovedBetweenCases={slidesMovedBetweenCases}
        emptyCases={emptyCases}
      />
    </>
  );
};

const ErrorsSection: FunctionComponent<{ errorMessages: string[] }> = ({ errorMessages: errorMessage }) => {
  return (
    !isEmpty(errorMessage) && (
      <Grid container direction="row">
        <Grid item>
          <ErrorIcon color="error" />
        </Grid>
        <Grid item xs={true} container direction="row" spacing={1}>
          <Grid item>
            <Typography>Errors:</Typography>
          </Grid>
          <Grid item>
            {map(errorMessage, (message, index) => (
              <Typography color="error" key={index}>
                {message}
              </Typography>
            ))}
          </Grid>
        </Grid>
      </Grid>
    )
  );
};

const WarningsSection: FunctionComponent<{ warningMessages: string[] }> = ({ warningMessages }) => {
  return (
    !isEmpty(warningMessages) && (
      <Grid container direction="row">
        <Grid item>
          <WarningIcon color="warning" />
        </Grid>
        <Grid item xs={true} container direction="row" spacing={1}>
          <Grid item>
            <Typography color="warning">Warnings: </Typography>
          </Grid>
          <Grid item>
            {map(warningMessages, (message, index) => (
              <Typography key={index} color="warning">
                {message}
              </Typography>
            ))}
          </Grid>
        </Grid>
      </Grid>
    )
  );
};

export default CaseManifestDialog;

const getPageCasesInSlidesMode = (procedures: Procedure[], page: number, rowsPerPage: number) => {
  const caseIdSlideIdPairs = reduce(
    procedures,
    (acc, procedure) => {
      const currentCaseIdSlideIdPairs = map(procedure.slides, (slide) => ({ caseId: procedure.id, slideId: slide.id }));
      acc = acc.concat(currentCaseIdSlideIdPairs);
      return acc;
    },
    []
  );
  const pageCaseIdSlideIdPairs = slice(caseIdSlideIdPairs, (page - 1) * rowsPerPage, page * rowsPerPage);
  const pageCaseIds = map(pageCaseIdSlideIdPairs, 'caseId');
  const pageSlideIds = map(pageCaseIdSlideIdPairs, 'slideId');
  const pageCases = filter(procedures, (procedure) => includes(pageCaseIds, procedure.id));

  // Slice edge cases' slides if needed
  return map(pageCases, (procedure) => {
    const slides = filter(procedure.slides, (slide) => includes(pageSlideIds, slide.id));
    return { ...procedure, slides };
  });
};

const getSlidesWithCaseLabel = (cases: Procedure[]): (Slide & { caseLabel: string })[] => {
  return flatMap(cases, (updatedCase) => {
    return map(updatedCase.slides, (slide) => {
      return {
        ...slide,
        caseLabel: updatedCase.label,
      };
    });
  });
};
