import { SelectProps } from '@mui/material';
import { JobType, NeighborhoodClustersJob } from 'interfaces/job';
import React, { ReactElement } from 'react';
import JobSelect from './JobSelect';

interface NeighborhoodClustersJobSelectProps extends Omit<SelectProps, 'onScroll' | 'label'> {
  selectedNeighborhoodClustersJobId: string | null;
  setSelectedNeighborhoodClustersJobId: (jobId: string | null) => void;
  onJobSelect: (job: NeighborhoodClustersJob) => void;
  autoAssignLastJob?: boolean;
  helperText?: string;
  studyId?: string;
}

export const NeighborhoodClustersJobSelect = ({
  selectedNeighborhoodClustersJobId,
  setSelectedNeighborhoodClustersJobId,
  onJobSelect,
  autoAssignLastJob,
  helperText,
  studyId,
  ...selectProps
}: NeighborhoodClustersJobSelectProps): ReactElement => {
  return (
    <JobSelect
      label="Cluster by Neighborhoods"
      jobType={JobType.MultiplexNeighborhoodClusters}
      selectedJobId={selectedNeighborhoodClustersJobId}
      setSelectedJobId={setSelectedNeighborhoodClustersJobId}
      setSelectedJob={onJobSelect}
      autoAssignLastJob={autoAssignLastJob}
      helperText={helperText}
      studyId={studyId}
      {...selectProps}
    />
  );
};
