import { DrawRectangleMode } from 'nebula.gl';
import { shouldStopDraggingPropagation } from './helpers';

import { DraggingEvent, StartDraggingEvent, StopDraggingEvent } from './types';

export class DrawRectangleByDragging extends DrawRectangleMode {
  handleStartDragging(event: StartDraggingEvent, props: any): void {
    if (shouldStopDraggingPropagation(event)) return;

    super.handleStartDragging(event, props);
  }

  handleStopDragging(event: StopDraggingEvent, props: any) {
    if (shouldStopDraggingPropagation(event)) return;

    super.handleStopDragging(event, props);
  }

  handleDragging(event: DraggingEvent, props: any) {
    if (shouldStopDraggingPropagation(event)) return;

    super.handleDragging(event, props);
  }
}
