import { PMTilesMetadata, PMTilesSource } from '@loaders.gl/pmtiles';
import { useQueries, useQuery } from '@tanstack/react-query';
import { compact, indexOf, map, some, uniq } from 'lodash';
import { useMemo } from 'react';

import { FeatureMetadata, HeatmapType } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';

export interface PMTilesResult {
  pmtHeatmap?: FeatureMetadata;
  pmtTileSource: PMTilesSource | null;
  pmtMetadata: PMTilesMetadata | null;
}

export const setupProtomapTiles = async (pmtUrl: string): Promise<PMTilesResult> => {
  const pmtTileSource = new PMTilesSource({ url: pmtUrl });
  const pmtMetadata = await pmtTileSource.metadata;
  return { pmtTileSource, pmtMetadata };
};

const isPreSignedPmtHeatmapUrl = (pmtHeatmapUrl: string) =>
  Boolean(pmtHeatmapUrl) && !pmtHeatmapUrl.startsWith('s3://');

export const isPreSignedPmtHeatmap = (pmtHeatmap: Partial<FeatureMetadata>) =>
  // Wait for pre-signed URLs to be ready
  isPreSignedPmtHeatmapUrl(pmtHeatmap?.heatmapUrl) && pmtHeatmap.heatmapType === HeatmapType.Pmt;

export const useProtomapTiles = (pmtHeatmap: FeatureMetadata): PMTilesResult => {
  const { data } = useQuery(
    ['pmtiles', pmtHeatmap?.heatmapUrl],
    async () => setupProtomapTiles(pmtHeatmap?.heatmapUrl),
    {
      enabled: isPreSignedPmtHeatmap(pmtHeatmap),
    }
  );

  return {
    pmtTileSource: data?.pmtTileSource || null,
    pmtMetadata: data?.pmtMetadata || null,
    pmtHeatmap,
  };
};

export const useProtomapTilesList = (pmtHeatmaps: Partial<FeatureMetadata>[]) => {
  const uniquePmtHeatmapUrls = compact(uniq(map(pmtHeatmaps, (pmtHeatmap) => pmtHeatmap?.heatmapUrl)));
  const pmtTilesQueries = useQueries({
    queries: map(uniquePmtHeatmapUrls, (heatmapUrl) => ({
      queryKey: ['pmtiles', heatmapUrl],
      queryFn: async () => setupProtomapTiles(heatmapUrl),
      enabled: isPreSignedPmtHeatmapUrl(heatmapUrl),
    })),
  });

  const pmtLayersData = useMemo(
    () =>
      map(pmtHeatmaps, (pmtHeatmap) => {
        const queryIndex = indexOf(uniquePmtHeatmapUrls, pmtHeatmap?.heatmapUrl);
        const query = pmtTilesQueries[queryIndex];
        const { pmtTileSource, pmtMetadata } = query?.data || {};
        return {
          pmtHeatmap,
          pmtTileSource,
          pmtMetadata,
          isLoading: query?.isLoading && isPreSignedPmtHeatmap(pmtHeatmap),
          dataUpdatedAt: query?.dataUpdatedAt,
          isError: query?.isError,
          error: query?.error,
          heatmapUrl: pmtHeatmap?.heatmapUrl,
          heatmapType: pmtHeatmap.heatmapType,
        };
      }),
    [pmtHeatmaps, JSON.stringify(map(pmtTilesQueries, 'dataUpdatedAt'))]
  );

  return {
    pmtLayersData,
    isLoading: some(pmtTilesQueries, 'isLoading'),
  };
};
