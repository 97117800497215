import { Close } from '@mui/icons-material';
import { IconButton, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { Dictionary, isEmpty, join, map, size, some } from 'lodash';
import React, { useState } from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';

import DrawerToggleButton from 'components/atoms/DrawerToggleButton';
import { RouteProps } from 'components/Auth/PrivateRoute';
import Loader from 'components/Loader';
import { headerHeight } from 'components/Procedure/constants';
import ReviewSidebar from 'components/ReviewSidebar';
import useReviewSidebarWidth from 'components/ReviewSidebar/useReviewSidebarWidth';
import VerticalCarousel from 'components/StudyDashboard/ProceduresPage/ProcedureCard/Carousel/VerticalCarousel';
import { Accession } from 'interfaces/accession';
import { Permission } from 'interfaces/permissionOption';
import { Procedure } from 'interfaces/procedure';
import { MULTIPLEX_STAIN_ID } from 'interfaces/stainType';
import {
  getActiveAnnotationAssignmentIdViewerKey,
  useAnnotationQueryParams,
} from 'services/annotations/useAnnotationQueryParams';
import { useSyncSelectedSlideIds } from 'utils/useCurrentSlideIds';
import { usePermissions } from 'utils/usePermissions';
import useStudy from 'utils/useStudy';
import { useUpdateViewerTypeFromStudySettings } from 'utils/useStudyPlatformSettings';
import { CaseViewBody } from './CaseViewBody';
import Infobar from './Infobar/Infobar';
import ViewerHints from './SlidesViewer/HintsFooter/ViewerHints';
import { SlideThumbnails } from './SlideThumbnails';
import { ToggleRegistrationButton } from './ToggleRegistrationButton';
import { useSlideChannelsAndResults } from './useSlideChannelsAndResults';
import { SlideWithChannelAndResults } from './useSlideChannelsAndResults/utils';
import { useSlideImages } from './useSlideImages';

interface CaseViewProps {
  procedure: Procedure | Accession;
  refetchProcedure: () => void;
  isAccessionViewer: boolean;
  isPlaceholderData?: boolean;
  isLoadingCaseData: boolean;
}

export const INFOBAR_PIXEL_WIDTH = 370;
export const INFOBAR_WIDTH = `${INFOBAR_PIXEL_WIDTH}px`;
export const SLIDES_THUMBNAILS_PIXEL_HEIGHT = 110;
export const SLIDES_THUMBNAILS_HEIGHT = `${SLIDES_THUMBNAILS_PIXEL_HEIGHT}px`;

const anyValidForSidebar = (
  slides: SlideWithChannelAndResults[],
  canAnnotateSlides: boolean,
  annotationQueryParams: Dictionary<number>
): boolean => {
  const valid = some(slides, (currentSlide) => {
    const { channels, experimentResults, qcLabels, viewerIndex } = currentSlide;

    const sidebarInfoChannels = !isEmpty(channels);
    const sidebarInfoProcedureResults = !isEmpty(experimentResults);
    const sidebarInfoQcLabels = !isEmpty(qcLabels);
    const sidebarAnnotationParams =
      canAnnotateSlides && !isNaN(annotationQueryParams[getActiveAnnotationAssignmentIdViewerKey(viewerIndex)]);
    return sidebarInfoChannels || sidebarInfoProcedureResults || sidebarInfoQcLabels || sidebarAnnotationParams;
  });
  return valid;
};

export const CaseView: React.FunctionComponent<React.PropsWithChildren<CaseViewProps & RouteProps>> = ({
  procedure,
  refetchProcedure,
  isPlaceholderData,
  isLoadingCaseData,
}) => {
  const theme = useTheme();

  const { reviewSidebarWidth } = useReviewSidebarWidth();

  const { isLoadingStudySettings } = useUpdateViewerTypeFromStudySettings({ studyId: procedure.studyId });

  const [showInfoBar, setShowInfoBar] = useState(true);

  const { hasPermission } = usePermissions();
  const isAccessionViewer = hasPermission(Permission.ViewAccessionDashboard);
  const {
    selectedSlidesWithChannelsAndResults,
    isLoadingSlideChannelsAndResults,
    isLoadingPresignedUrls,
    isLoadingFileUrlHeatmaps,
  } = useSlideChannelsAndResults(procedure);

  const { annotationQueryParams } = useAnnotationQueryParams();
  const canAnnotateSlides = hasPermission(Permission.AnnotateSlides);
  const canViewAnnotations = hasPermission(Permission.ViewAnnotations);

  const wasSidebarVisible = React.useRef(false);

  const isSlideValidForSidebar = React.useMemo(
    () =>
      Boolean(selectedSlidesWithChannelsAndResults) &&
      anyValidForSidebar(selectedSlidesWithChannelsAndResults, canAnnotateSlides, annotationQueryParams),
    [selectedSlidesWithChannelsAndResults, canAnnotateSlides, annotationQueryParams]
  );
  const [useOSDViewer] = useQueryParam('useOSDViewer', BooleanParam);
  const [useAdvancedBaseSlideControls] = useQueryParam('useAdvancedBaseSlideControls', BooleanParam);

  const { study } = useStudy(procedure?.studyId, { enabled: Boolean(procedure?.studyId) });

  const studyDeconvolutionSettings = study?.settings?.deconvolutionSettings;

  const infoBarVisible =
    (useAdvancedBaseSlideControls && !useOSDViewer) ||
    (canViewAnnotations && !useOSDViewer) ||
    isSlideValidForSidebar ||
    ((isLoadingCaseData || isPlaceholderData) && wasSidebarVisible.current) ||
    some(selectedSlidesWithChannelsAndResults, {
      stainingType: MULTIPLEX_STAIN_ID,
    }) ||
    // If the study has deconvolution components matching the selected slides, show the infobar
    some(selectedSlidesWithChannelsAndResults, ({ stainingType }) =>
      Boolean(studyDeconvolutionSettings?.stainToComponentOperation?.[stainingType])
    );

  React.useEffect(() => {
    if (!isLoadingCaseData && !isPlaceholderData) {
      wasSidebarVisible.current = infoBarVisible;
    }
  }, [isLoadingCaseData, isPlaceholderData, infoBarVisible]);

  const canUseSlideThumbnails = hasPermission(Permission.ViewSlideThumbnailsNavigation);

  const [fullscreenViewer, setFullscreenViewer] = useQueryParam('fullscreenViewer', BooleanParam);
  const showReviewSidebar = hasPermission(Permission.ViewReviewMenu);

  React.useEffect(() => {
    if (fullscreenViewer) {
      const exitOnEscape = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          setFullscreenViewer(false, 'replaceIn');
        }
      };
      document.addEventListener('keydown', exitOnEscape);
      return () => document.removeEventListener('keydown', exitOnEscape);
    }
  }, [fullscreenViewer]);

  const {
    slidesBaseImagePyramids,
    slidesHeatmapsImagePyramids,
    baseSlideLoadingStates,
    isLoadingSlides,
    error: slideImagesError,
    canLoadSlides,
  } = useSlideImages(selectedSlidesWithChannelsAndResults || []);

  useSyncSelectedSlideIds(procedure);

  const isDisplayingSlidesWithRegistration =
    size(selectedSlidesWithChannelsAndResults) > 1 &&
    some(selectedSlidesWithChannelsAndResults, (slide) => !isEmpty(slide.registrations));

  // Study settings will affect the display of the slides in a case, so we need to wait for them to load before rendering the slides
  // Deck GL viewer should be able to handle this
  if (useOSDViewer && isLoadingStudySettings) {
    return <Loader />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        overflowY: 'hidden',
        width: '100%',
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          marginTop: !fullscreenViewer ? headerHeight : undefined,
          '& .viewer.displaying-tagged-slide': {
            borderColor: theme.palette.mode === 'dark' ? 'primary.main' : 'secondary.main',
            borderWidth: 1,
            borderStyle: 'solid',

            '& .MuiChip-root': {
              color: theme.palette.mode === 'dark' ? 'primary.main' : 'secondary.main',
            },
          },
        }}
      >
        <div id="ppi" style={{ height: '1in', width: '1in', left: '100%', position: 'fixed', top: '100%' }} />
        {!fullscreenViewer && infoBarVisible && (
          <>
            <Infobar
              drawerWidth={INFOBAR_WIDTH}
              isPlaceholderData={isPlaceholderData}
              isLoadingCaseData={isLoadingCaseData || isLoadingSlides || isLoadingSlideChannelsAndResults}
              isLoadingExtraResults={isLoadingPresignedUrls || isLoadingFileUrlHeatmaps}
              key={`infobar-${procedure?.id}-${join(map(selectedSlidesWithChannelsAndResults, 'id'), '-')}`}
              slidesWithChannelsAndResults={selectedSlidesWithChannelsAndResults || []}
              openDrawer={showInfoBar}
            />
            <DrawerToggleButton
              direction="left"
              drawerWidth={INFOBAR_WIDTH}
              onClick={() => setShowInfoBar((prevShowSidebar) => !prevShowSidebar)}
              collapsed={!showInfoBar}
              text={!showInfoBar && 'Analysis Results'}
            />
          </>
        )}
        {!fullscreenViewer && isAccessionViewer && <VerticalCarousel procedure={procedure} />}
        <CaseViewBody
          isLoadingCaseData={isLoadingCaseData || isLoadingSlides}
          procedure={procedure}
          isAccessionViewer={isAccessionViewer}
          isPlaceholderData={isPlaceholderData}
          selectedSlides={selectedSlidesWithChannelsAndResults}
          slidesBaseImagePyramids={slidesBaseImagePyramids}
          slidesHeatmapsImagePyramids={slidesHeatmapsImagePyramids}
          baseSlideLoadingStates={baseSlideLoadingStates}
          slideImagesError={slideImagesError}
          canLoadSlides={canLoadSlides}
        />
        {!fullscreenViewer && showReviewSidebar && (
          <ReviewSidebar
            procedure={procedure}
            refetchProcedure={refetchProcedure}
            isPlaceholderData={isPlaceholderData}
            isLoadingCaseData={isLoadingCaseData || isLoadingSlides || isLoadingSlideChannelsAndResults}
          />
        )}
        {!fullscreenViewer && isDisplayingSlidesWithRegistration && (
          <ToggleRegistrationButton
            marginLeft={infoBarVisible && showInfoBar ? INFOBAR_PIXEL_WIDTH : 0}
            marginRight={reviewSidebarWidth}
          />
        )}
      </Box>
      {!fullscreenViewer && canUseSlideThumbnails ? (
        <SlideThumbnails
          caseData={procedure}
          marginLeft={infoBarVisible && showInfoBar ? INFOBAR_PIXEL_WIDTH : 0}
          marginRight={reviewSidebarWidth}
          height={SLIDES_THUMBNAILS_PIXEL_HEIGHT}
          isLoadingCaseData={isLoadingCaseData || isLoadingSlides}
        />
      ) : null}
      <ViewerHints
        selectedSlides={selectedSlidesWithChannelsAndResults}
        marginLeft={infoBarVisible && showInfoBar ? INFOBAR_PIXEL_WIDTH : 0}
        marginRight={reviewSidebarWidth}
        showCommentsHint={!isAccessionViewer}
      />
      {fullscreenViewer && (
        <IconButton color="primary" onClick={() => setFullscreenViewer(false)} sx={{ position: 'fixed' }}>
          <Close />
        </IconButton>
      )}
    </Box>
  );
};
