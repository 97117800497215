import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Dictionary, find, forEach, fromPairs, map, pick } from 'lodash';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

import {
  getChannelsColorPresets,
  saveChannelColorPreset,
  updateChannelColorPreset,
} from 'api/slideMultiplexColorPresets';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import PresetSection from 'components/atoms/PresetSection';
import { BasePreset } from 'interfaces/basePreset';
import { ChannelColorPresetOptions, ChannelsColorPreset } from 'interfaces/channelsPreset';
import { NumberParam, useQueryParam } from 'use-query-params';
import { uuidv4 } from 'utils/helpers';
import queryClient from 'utils/queryClient';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import {
  SlideChannelColorSettings,
  computeDefaultSlideChannelColorSettings,
  slidesChannelColorSettings,
} from './colorSettings';

const visualSettingsFromPreset = ['color', 'opacity', 'gamma'];

const getChannelPresetByMarkerType = (
  markerType: string,
  preset: Record<string, ChannelColorPresetOptions>
): SlideChannelColorSettings | undefined => {
  return pick(preset?.[markerType], visualSettingsFromPreset);
};

export const defaultPreset: Partial<BasePreset> = {
  id: 'Default',
  name: 'Default',
};
interface Props {
  slide: SlideWithChannelAndResults;
}

const MultiplexPresetSection: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ slide }) => {
  useSignals();
  const { queryParams } = useEncodedFilters();
  const [caseIdFromParam] = useQueryParam('caseId', NumberParam);
  const [currentPreset, setCurrentPreset] = useState<Partial<ChannelsColorPreset>>(defaultPreset);

  const slideId = slide.id;
  const viewerSlidesChannelsSettings = slidesChannelColorSettings[slide.viewerIndex];

  const params = useParams();
  const caseId = queryParams.slidesMode ? Number(caseIdFromParam) : Number(params.id);
  const { labId } = useCurrentLabId();
  const { name: userName } = useAppSelector((state) => state.auth.profile);

  const { data: channelsPreset } = useQuery<ChannelsColorPreset[]>(['channelsPreset', labId], () =>
    getChannelsColorPresets(labId)
  );

  const savePresetMutation = useMutation(saveChannelColorPreset, {
    onMutate: async (preset) => {
      setCurrentPreset(preset);
      await queryClient.cancelQueries(['channelsPreset', labId]);
      const previousValue = queryClient.getQueryData(['channelsPreset', labId]);
      queryClient.setQueryData(['channelsPreset', labId], (presets: ChannelsColorPreset[]) => [...presets, preset]);
      return previousValue;
    },
    onError: (previousValue) => queryClient.setQueryData(['channelsPreset', labId], previousValue),
    onSettled: () => {
      queryClient.invalidateQueries(['channelsPreset', labId]);
    },
  });

  const updatePresetMutation = useMutation(updateChannelColorPreset, {
    onSuccess: () => {
      queryClient.invalidateQueries(['channelsPreset', labId]);
    },
  });

  const getOptionsObject = (): Dictionary<ChannelColorPresetOptions> => {
    const settingsForAllChannels = viewerSlidesChannelsSettings.value[slideId];

    return fromPairs(
      map([...slide.channelsMetadata], (channel) => {
        return [
          slide.channelMarkerTypes[channel.id],
          pick(settingsForAllChannels[channel.id]?.value, visualSettingsFromPreset) as ChannelColorPresetOptions,
        ];
      })
    );
  };

  const saveChannelsPreset = (name: string) => {
    const options = getOptionsObject();
    savePresetMutation.mutate({
      id: uuidv4(),
      labId,
      name,
      createdBy: userName,
      procedureId: caseId,
      slideId,
      options,
    });
  };

  const updateChannelsPreset = () => {
    const options = getOptionsObject();
    updatePresetMutation.mutate({ id: currentPreset?.id, options });
  };

  const getDefaultPresetSlideSettings = (channelId: string): SlideChannelColorSettings => {
    const settingsForAllChannels = viewerSlidesChannelsSettings?.value?.[slideId] || {};
    const channelSettings = settingsForAllChannels?.[channelId]?.value;
    const slideChannel = slide?.channels?.[channelId];
    return {
      ...channelSettings,
      ...computeDefaultSlideChannelColorSettings(Number(channelId), slideChannel),
    };
  };

  const selectChannelsPreset = (preset: Partial<BasePreset>) => {
    if (!viewerSlidesChannelsSettings) {
      console.warn(`Invalid viewerIndex: ${slide.viewerIndex}`);
      return;
    }
    const channelPreset: ChannelsColorPreset = find(channelsPreset, (currPreset) => currPreset.id == preset.id);
    const settingsForAllChannels = viewerSlidesChannelsSettings.value[slideId];
    forEach(slide.channelsMetadata, (channel) => {
      if (!channel?.id) {
        console.warn('Channel id is missing', { channel });
        return;
      }
      if (!settingsForAllChannels?.[channel.id]) {
        settingsForAllChannels[channel.id] = signal<SlideChannelColorSettings>(
          getDefaultPresetSlideSettings(channel.id)
        );
      }
      if (preset.id === defaultPreset.id) {
        settingsForAllChannels[channel.id].value = getDefaultPresetSlideSettings(channel.id);
      } else {
        const previousChannelSettings = settingsForAllChannels[channel.id].value;
        settingsForAllChannels[channel.id].value = {
          ...previousChannelSettings,
          ...(getChannelPresetByMarkerType(slide.channelMarkerTypes[channel.id], channelPreset.options) || {}),
        };
      }
    });
    viewerSlidesChannelsSettings.value = {
      ...viewerSlidesChannelsSettings.value,
      [slideId]: settingsForAllChannels,
    };
    setCurrentPreset(channelPreset);
  };

  return (
    <PresetSection
      defaultPreset={defaultPreset}
      selectedPreset={currentPreset}
      presets={channelsPreset}
      onSavePreset={saveChannelsPreset}
      onUpdatePreset={updateChannelsPreset}
      onSelectPreset={selectChannelsPreset}
      label="Channel Presets"
      description="Multiplex channel presets display settings for multiplex channels that can be saved and applied to
  different multiplex slides."
    />
  );
};

export default MultiplexPresetSection;
