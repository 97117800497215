import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';
import { isEmpty, map } from 'lodash';
import React from 'react';

interface LazyLoadSelectProps extends Omit<SelectProps, 'onScroll' | 'label'> {
  label: string;
  options: { value: string; label: string }[];
  loadMoreOptions: () => void;
  isLoading: boolean;
  helperText?: string;
}

const LazyLoadSelect: React.FC<LazyLoadSelectProps> = ({
  label,
  options,
  loadMoreOptions,
  isLoading,
  helperText,
  ...props
}) => {
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const bottom =
      event.currentTarget.scrollHeight === event.currentTarget.scrollTop + event.currentTarget.clientHeight;
    if (bottom && !isLoading) {
      loadMoreOptions();
    }
  };

  return (
    <FormControl fullWidth required={props.required}>
      <InputLabel error={props.error}>{label}</InputLabel>
      <Select
        {...props}
        label={label}
        endAdornment={
          isLoading ? (
            <InputAdornment position="end" sx={{ marginRight: 6 }}>
              <CircularProgress color="inherit" size={20} />
            </InputAdornment>
          ) : null
        }
        MenuProps={{
          PaperProps: {
            onScroll: handleScroll,
            style: { maxHeight: 200, overflowY: 'auto' },
          },
        }}
        variant="outlined"
      >
        {map(options, (option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
        {isLoading && (
          <MenuItem disabled>
            Loading...
            <CircularProgress size={24} />
          </MenuItem>
        )}
        {isEmpty(options) && !isLoading && <MenuItem disabled>No options available</MenuItem>}
      </Select>
      <FormHelperText error={props.error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default LazyLoadSelect;
