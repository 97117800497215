import {
  CellRule,
  CellRulePanel,
  CellRulePanelUpdates,
  CellRulePanelWithRules,
  CellRuleStudyId,
  UpdateCellRule,
} from 'interfaces/cellRule';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getCellPanels = async (studyId: CellRuleStudyId, signal?: AbortSignal): Promise<CellRulePanel[]> => {
  return apiRequestHandlerPromise<CellRulePanel[]>({
    url: `studies/${studyId}/cell_panels`,
    method: 'GET',
    signal,
  });
};

export const getCellRules = async (
  panelId: string,
  studyId: string,
  signal?: AbortSignal
): Promise<CellRulePanelWithRules> => {
  return apiRequestHandlerPromise<CellRulePanelWithRules>({
    url: `studies/${studyId}/cell_panels/${panelId}/rules`,
    method: 'GET',
    signal,
  });
};

export const updateCellRule = ({
  panelId,
  updateModel,
  studyId,
  signal,
}: {
  panelId: string;
  updateModel: UpdateCellRule;
  studyId: CellRuleStudyId;
  signal?: AbortSignal;
}) => {
  return apiRequestHandlerPromise<CellRule>({
    url: `studies/${studyId}/cell_panels/${panelId}/rules`,
    method: 'PUT',
    signal,
    data: JSON.stringify(updateModel),
  });
};

interface CellRulePanelRequest {
  panel: {
    label: string;
    stainTypeIds?: string[];
    slideIds?: string[];
  };
  globalPanelFilters?: {
    cellTypeIds?: string[];
    stainTypeIds?: string[];
  };
}

export const createCellPanel = ({
  studyId,
  label,
  stainTypeIds,
  signal,
}: {
  studyId: CellRuleStudyId;
  label: string;
  stainTypeIds?: string[];
  signal?: AbortSignal;
}) => {
  const requestData: CellRulePanelRequest = {
    panel: {
      label,
      stainTypeIds,
    },
  };

  return apiRequestHandlerPromise<CellRulePanelWithRules>({
    url: `studies/${studyId}/cell_panels`,
    method: 'POST',
    signal,
    data: JSON.stringify(requestData),
  });
};

export const updateCellPanel = ({
  panelId,
  studyId,
  panelUpdates,
  signal,
}: {
  panelId: string;
  studyId: CellRuleStudyId;
  panelUpdates?: CellRulePanelUpdates;
  signal?: AbortSignal;
}) => {
  return apiRequestHandlerPromise<CellRulePanel>({
    url: `studies/${studyId}/cell_panels/${panelId}`,
    method: 'PATCH',
    signal,
    data: JSON.stringify({
      panelUpdates,
    }),
  });
};

export const deleteCellRulePanel = ({ studyId, panelId }: { studyId: string; panelId: string }) => {
  return apiRequestHandlerPromise({
    url: `studies/${studyId}/cell_panels/${panelId}`,
    method: 'DELETE',
  });
};
