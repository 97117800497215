import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  ClickAwayListener,
  Grow,
  IconProps,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  SvgIconTypeMap,
  SxProps,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonProps,
  Tooltip,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { map } from 'lodash';
import React from 'react';

export interface SubMenuOption {
  subEditType: string;
  title: string;
}
export interface SlideInteractionMenuOption {
  editType: string;
  title: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  color?: IconProps['color'];
  sx?: IconProps['sx'];
  isDefault?: boolean;
  subOptions?: SubMenuOption[];
}

const menuItemSx: SxProps<Theme> = {
  paddingInline: 1.5,
};

export interface SubMenuOptionProps extends SubMenuOption {
  onClick?: (event: React.MouseEvent<HTMLElement>, value: string) => void;
}

export const ToggleButtonWithTooltip: React.FunctionComponent<{
  value: string;
  selected?: boolean;
  disabled?: boolean;
  title: string;
  icon: React.ReactNode;
  onClick?: ToggleButtonProps['onClick'];
}> = ({ icon, value, selected, disabled, title, onClick }) => (
  <Tooltip title={title} placement="bottom">
    {/* Add a span tag to support disabled ToggleButton */}
    <span>
      <ToggleButton
        value={value}
        title={title}
        disabled={disabled}
        selected={selected}
        sx={menuItemSx}
        onClick={onClick}
      >
        {icon}
      </ToggleButton>
    </span>
  </Tooltip>
);

export const SlideInteractionOption: React.FunctionComponent<{
  value: string;
  selected?: boolean;
  disabled?: boolean;
  title: string;
  icon: React.ReactNode;
  onClick?: ToggleButtonProps['onClick'];
  subOptions?: SubMenuOptionProps[];
}> = ({ icon, value, selected, disabled, title, onClick, subOptions }) => {
  const [subMenuOpen, setSubMenuOpen] = React.useState(false);
  const subMenuAnchorRef = React.useRef<HTMLDivElement>(null);

  const handleSubMenuItemClick = (event: React.MouseEvent<HTMLElement, MouseEvent>, subOption: SubMenuOptionProps) => {
    subOption.onClick?.(event, subOption.subEditType);
    setSubMenuOpen(false);
  };

  const handleToggle = () => {
    setSubMenuOpen((prevOpen) => !prevOpen);
  };

  const handleSubMenuClose = (event: Event) => {
    if (subMenuAnchorRef.current && subMenuAnchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setSubMenuOpen(false);
  };

  if (subOptions) {
    return (
      <>
        <ToggleButtonGroup ref={subMenuAnchorRef}>
          <ToggleButtonWithTooltip
            value={value}
            title={title}
            disabled={disabled}
            selected={selected}
            onClick={onClick}
            icon={icon}
          />
          <ToggleButton value={`${value}-sub-options`} onClick={handleToggle} sx={{ width: 20, paddingY: 0 }}>
            <ArrowDropDownIcon color="inherit" />
          </ToggleButton>
        </ToggleButtonGroup>
        <Popper
          sx={{ zIndex: 1 }}
          open={subMenuOpen}
          anchorEl={subMenuAnchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleSubMenuClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {map(subOptions, (option) => (
                      <MenuItem key={option.title} onClick={(event) => handleSubMenuItemClick(event as any, option)}>
                        {option.title}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  }

  return (
    <ToggleButtonWithTooltip
      value={value}
      title={title}
      disabled={disabled}
      selected={selected}
      onClick={onClick}
      icon={icon}
    />
  );
};
