import { editAnnotationAssignment } from 'api/annotationAssignments';
import { AnnotationAssignmentUpdate, AnnotationClassRemovalOperation } from 'interfaces/annotation';
import { first } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import queryClient from 'utils/queryClient';
import { ANNOTATIONS_BASE_QUERY_KEY, useAnnotationAssignments } from 'utils/useAnnotationAssignments';
import { useCasesParams } from 'utils/useCasesParams';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import AnnotationAssignmentDialog, { AssignmentFormValues } from './AnnotationAssignmentDialog';
import { DEFAULT_TODO, DEFAULT_TODO_TYPE } from './CreateAnnotationAssignmentDialog';

interface EditAssignmentDialogProps {
  open: boolean;
  onClose: () => void;
  annotationAssignmentId: number;
}

export const EditAnnotationAssignmentDialog: React.FC<EditAssignmentDialogProps> = ({
  open,
  onClose,
  annotationAssignmentId,
}) => {
  const { casesParams } = useCasesParams();
  const { data: annotationAssignments } = useAnnotationAssignments(
    casesParams?.studyId || casesParams?.filters?.studyId
  );

  const currentAssignment = annotationAssignments?.find(
    (assignment) => assignment.annotationAssignmentId === annotationAssignmentId
  );

  const editAnnotationAssignmentMutation = useMutationWithErrorSnackbar({
    mutationDescription: 'Edit annotation assignment',
    mutationFn: editAnnotationAssignment,
    onSuccess: () => {
      enqueueSnackbar('Annotation assignment edited successfully', { variant: 'success' });
      queryClient.invalidateQueries([ANNOTATIONS_BASE_QUERY_KEY]);
      queryClient.invalidateQueries(['annotations']);
      onClose();
    },
  });

  const [classesToRemoveWithActions, setClassesToRemoveWithActions] = React.useState<AnnotationClassRemovalOperation[]>(
    []
  );

  const handleApply: SubmitHandler<AssignmentFormValues> = async (data) => {
    const annotationAssignmentUpdates: AnnotationAssignmentUpdate = {
      name: data.assignmentName,
      todos: [
        {
          todo: first(currentAssignment?.todos)?.todo ?? DEFAULT_TODO,
          type: first(currentAssignment?.todos)?.type ?? DEFAULT_TODO_TYPE,
          options: data.classesToAnnotate,
        },
      ],
    };

    editAnnotationAssignmentMutation.mutate({
      annotationAssignmentId,
      data: { ...annotationAssignmentUpdates, ...casesParams, classRemovalOperations: classesToRemoveWithActions },
    });
  };

  return (
    <>
      <AnnotationAssignmentDialog
        open={open}
        onClose={onClose}
        title={`Edit Annotation Assignment ${annotationAssignmentId}`}
        buttonText="Save"
        isLoadingButtonText="Saving..."
        handleApply={handleApply}
        isLoading={editAnnotationAssignmentMutation.isLoading}
        currentAssignment={currentAssignment}
        classesToRemoveWithActions={classesToRemoveWithActions}
        setClassesToRemoveWithActions={setClassesToRemoveWithActions}
      />
    </>
  );
};
