import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Checkbox, Grid, TextField, Typography } from '@mui/material';
import { concat, filter, find, map, orderBy, some } from 'lodash';
import React, { useMemo } from 'react';

import Loader from 'components/Loader';
import { Study } from 'interfaces/study';
import { useAlgorithms } from 'utils/queryHooks/useAlgorithms';

export const StudyAlgorithmsSettings: React.FC<
  React.PropsWithChildren<{
    updatedSettings: Study['settings'];
    setUpdatedSettings: React.Dispatch<React.SetStateAction<Study['settings']>>;
  }>
> = ({ updatedSettings, setUpdatedSettings }) => {
  const { data: algoSourceMappings, isLoading: isLoadingAlgoSourceMappings, isError } = useAlgorithms();

  const orderedAlgoSourceMappings = useMemo(
    () =>
      concat(
        map(orderBy(algoSourceMappings, 'displayName'), (mapping) => ({
          value: mapping.id,
          text: mapping.displayName,
        })),
        map(
          filter(
            updatedSettings?.availableAlgorithms,
            (algo) => !some(algoSourceMappings, (mapping) => mapping.id === algo)
          ),
          (algo) => ({
            value: algo,
            text: `No longer available: ${algo}`,
          })
        )
      ),
    [algoSourceMappings, updatedSettings?.availableAlgorithms]
  );

  const availableAlgorithms = updatedSettings?.availableAlgorithms || [];

  return (
    <Grid p={2} container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle2">Available Algorithms for Study</Typography>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete<string, true, false, false>
          color={isError ? 'error' : undefined}
          fullWidth
          multiple
          options={!isLoadingAlgoSourceMappings ? map(orderedAlgoSourceMappings, 'value') : []}
          getOptionLabel={(option) => find(orderedAlgoSourceMappings, { value: option })?.text || ''}
          value={!isLoadingAlgoSourceMappings ? availableAlgorithms : []}
          disableCloseOnSelect
          onChange={(event, rawNewValues) => {
            const newValues = filter(rawNewValues, (algorithmId) => some(algoSourceMappings, { id: algorithmId }));
            setUpdatedSettings((prevSettings) => ({ ...prevSettings, availableAlgorithms: newValues }));
          }}
          noOptionsText={
            isLoadingAlgoSourceMappings
              ? 'Loading...'
              : isError
              ? 'Failed to load algorithms'
              : 'No algorithms available'
          }
          renderOption={(props, value, { selected }) => {
            const option = find(orderedAlgoSourceMappings, { value });
            return (
              Boolean(option) && (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option?.text}
                </li>
              )
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                <Grid item container alignItems="center" columnGap={1}>
                  <Grid item>Select Algorithms</Grid>
                  {isLoadingAlgoSourceMappings && (
                    <Grid item>
                      <Loader size="small" />
                    </Grid>
                  )}
                </Grid>
              }
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
