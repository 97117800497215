import { Tooltip } from '@mui/material';
import * as React from 'react';

interface Props extends React.HTMLProps<HTMLDivElement> {
  x: number;
  y: number;
  tooltipText: string;
}

export const HtmlDeckGLOverlayTooltip: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  x,
  y,
  tooltipText,
  ...divProps
}) => {
  return (
    <Tooltip open title={tooltipText} placement="top">
      <div
        {...divProps}
        style={{
          position: 'absolute',
          pointerEvents: 'none',
          left: x,
          top: y,
          ...(divProps?.style || {}),
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};
