import { Button, CircularProgress, Grid } from '@mui/material';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import React from 'react';
import { useParams } from 'react-router-dom';
import { NumberParam, useQueryParam } from 'use-query-params';

import { ResultType } from 'interfaces/experimentResults';
import { includes, size } from 'lodash';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { usePublishMode } from 'utils/usePublishMode';
import useResultsMutation from './useResultsMutation';

export const APPROVE_RESULTS_TEXT = 'Approve';
export const DISAPPROVE_RESULTS_TEXT = 'Disapprove';
export const PUBLISH_RESULTS_TEXT = 'Publish';
export const UNPUBLISH_RESULTS_TEXT = 'Unpublish';

export interface OrchestrationApprovalProps {
  slideId: string;
  viewerIndex: number;
  orchestrationId?: string;
  primaryRunOrchestrationId?: string;
  experimentResultIds?: number[];
  isInternallyApproved: boolean;
  doesSlideHaveApprovedResults: boolean;
  isOrchestrationIdPublished?: boolean;
  flowClassNames?: string[];
  resultTypes?: string[];
  studyId: string;
  onApprovalMutation?: () => void;
}

export const OrchestrationApproval: React.FunctionComponent<OrchestrationApprovalProps> = ({
  slideId,
  viewerIndex,
  orchestrationId,
  primaryRunOrchestrationId = orchestrationId,
  experimentResultIds,
  isInternallyApproved,
  isOrchestrationIdPublished,
  doesSlideHaveApprovedResults,
  flowClassNames,
  resultTypes,
  studyId,
  onApprovalMutation,
}) => {
  // Check if the approval is for normalization - if so, we can publish multiple results at once, and don't need to show the user the option to approve / disapprove
  const isNormalizationResult = includes(resultTypes, ResultType.Normalization);
  if (isNormalizationResult && (size(resultTypes) > 1 || size(flowClassNames) > 1)) {
    console.error(
      "Normalization approval can't be combined with other results (since multiple results can be approved at once)."
    );
    return null;
  }
  const { queryParams } = useEncodedFilters();
  const [caseIdFromParam] = useQueryParam('caseId', NumberParam);

  const [isPublishMode] = usePublishMode(viewerIndex);

  const params = useParams();
  const caseId = queryParams.slidesMode ? Number(caseIdFromParam) : Number(params.id);

  const { handleFieldSave, isLoading: isLoadingMutation } = useResultsMutation(caseId, studyId, onApprovalMutation);

  const [approvalType, setApprovalType] = React.useState<'approved' | 'internallyApproved' | null>(null);

  const normalizationText = isOrchestrationIdPublished
    ? 'Are you sure you want to unpublish this normalization?'
    : 'Are you sure you want to publish this normalization?';

  const discretePublishingText = doesSlideHaveApprovedResults
    ? 'This slide has published results. By publishing these results, the old ones will be unpublished. Are you sure you want to replace the published results?'
    : 'By publishing results, all users will see the results. Are you sure you want to publish the results?';

  const confirmationModalText =
    approvalType === 'approved'
      ? isNormalizationResult
        ? normalizationText
        : discretePublishingText
      : !isInternallyApproved
      ? 'Are you sure you want to approve these results?'
      : 'Are you sure you want to disapprove these results?';

  const [isRunningAction, setIsRunningAction] = React.useState(false);
  const doApprovalAction = async ({
    fieldName,
    approvalValue,
    shouldInternallyApprove,
  }: {
    fieldName: 'approved' | 'internallyApproved';
    approvalValue: boolean;
    shouldInternallyApprove: boolean;
  }) => {
    setIsRunningAction(true);
    try {
      if (shouldInternallyApprove) {
        await handleFieldSave({
          orchestrationId: primaryRunOrchestrationId || orchestrationId,
          experimentResultIds,
          slideId,
          fieldName: 'internallyApproved',
          value: approvalValue,
          flowClassNames,
          resultTypes,
        });
      }
      await handleFieldSave({
        orchestrationId: primaryRunOrchestrationId || orchestrationId,
        experimentResultIds,
        slideId,
        fieldName,
        value: approvalValue,
        flowClassNames,
        resultTypes,
      });
    } catch (e) {
      console.error('Error approving results', e);
    }
    setIsRunningAction(false);
  };

  const isLoading = isLoadingMutation || isRunningAction || Boolean(approvalType);

  return (
    isPublishMode && (
      <>
        <Grid item xs={6} container>
          {!isNormalizationResult && (
            <Grid item>
              <Button
                disabled={isLoading}
                onClick={(e) => {
                  e.stopPropagation();
                  setApprovalType('internallyApproved');
                }}
                endIcon={isLoading ? <CircularProgress size={20} /> : null}
              >
                {isInternallyApproved ? DISAPPROVE_RESULTS_TEXT : APPROVE_RESULTS_TEXT}
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              // Only normalization results can be unpublished using this component
              disabled={isLoading || (!isNormalizationResult && (!isInternallyApproved || isOrchestrationIdPublished))}
              onClick={(e) => {
                e.stopPropagation();
                setApprovalType('approved');
              }}
              endIcon={isLoading ? <CircularProgress size={20} /> : null}
            >
              {/* Only allow unpublishing for normalization results */}
              {isNormalizationResult && isOrchestrationIdPublished ? UNPUBLISH_RESULTS_TEXT : PUBLISH_RESULTS_TEXT}
            </Button>
          </Grid>
        </Grid>
        {approvalType && (
          <ConfirmationModal
            title={`${approvalType === 'approved' ? PUBLISH_RESULTS_TEXT : APPROVE_RESULTS_TEXT} Results`}
            text={confirmationModalText}
            onConfirm={() => {
              const approvalValue = isNormalizationResult
                ? // Normalization results can be published multiple times, and should ignore internal approval status
                  // Though we will make sure it is internally approved if we publish, just to be safe
                  !isOrchestrationIdPublished
                : // For non normalization results, we only allow publishing (unpublishing is done in a different component)
                  approvalType === 'approved' || !isInternallyApproved;

              // When we approve a normalization result, we want to make sure it is internally approved
              const shouldInternallyApprove = isNormalizationResult && !isInternallyApproved && approvalValue;
              // We want to close the modal after the user confirms the action, so we don't need to wait for the mutation to finish
              doApprovalAction({ fieldName: approvalType, approvalValue, shouldInternallyApprove });
              setApprovalType(null);
            }}
            onCancel={() => setApprovalType(null)}
          />
        )}
      </>
    )
  );
};

export default OrchestrationApproval;
