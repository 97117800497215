import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Box, CircularProgress, Divider, Grid, ToggleButton, Tooltip, useTheme } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { find } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';

import { useConfirmation } from 'components/modals/ConfirmationContext';
import { Permission } from 'interfaces/permissionOption';
import { useSecondaryAnalysis } from 'services/secondaryAnalysis';
import { getRelevantSecondaryAnalysisSelectionModes } from 'services/secondaryAnalysis/secondaryAnalysisSelectionModes';
import { useClearSecondaryAnalysisSelections } from 'services/secondaryAnalysis/useClearSecondaryAnalysisSelections';
import { usePermissions } from 'utils/usePermissions';
import usePrevious from 'utils/usePrevious';
import { InteractionMenusProps } from '..';
import { RunSecondaryAnalysisButton } from './RunSecondaryAnalysisButton';
import { SecondaryAnalysisToolsPopper } from './SecondaryAnalysisToolsPopper';

export const SecondaryAnalysisSelectionTools: React.FunctionComponent<InteractionMenusProps> = ({
  caseId,
  selectedSlides,
  hasActiveNonAnnotationInteractions,
  clearNonAnnotationInteractions,
}) => {
  useSignals();
  const { hasPermission } = usePermissions();
  const canRunSecondaryAnalysis = hasPermission(Permission.RunSecondaryAnalysis);
  const secondaryAnalysisOptions = getRelevantSecondaryAnalysisSelectionModes();

  const confirmWithModal = useConfirmation();

  const theme = useTheme();

  const [useOSDViewer] = useQueryParam('useOSDViewer', BooleanParam);

  const [anchorEl, setAnchorEl] = React.useState<null | Element>(null);

  // Check if any of the viewers is currently performing a secondary analysis
  const {
    execution: { isStarting: isStartingSecondaryAnalysis },
    context: { isSecondaryAnalysisActive, secondaryAnalysisAreaSelectionMode },
  } = useSecondaryAnalysis({
    caseId,
    selectedSlides,
    onSuccess: clearNonAnnotationInteractions,
  });

  const clearSecondaryAnalysisSelections = useClearSecondaryAnalysisSelections();

  const exitSecondaryAnalysis = useCallback(() => {
    clearNonAnnotationInteractions();
    clearSecondaryAnalysisSelections(true);
  }, [clearNonAnnotationInteractions]);

  const previousSecondaryAnalysisActive = usePrevious(isSecondaryAnalysisActive);
  const [isDrawingToolSelectionOpenState, setIsDrawingToolSelectionOpen] = React.useState(isSecondaryAnalysisActive);
  const isDrawingToolSelectionOpen = isSecondaryAnalysisActive && Boolean(anchorEl) && isDrawingToolSelectionOpenState;

  useEffect(() => {
    if (!previousSecondaryAnalysisActive && isSecondaryAnalysisActive) {
      // Open/Close the drawing tool selection if the drawing modes are active/inactive
      setIsDrawingToolSelectionOpen(isSecondaryAnalysisActive);
    }
  }, [isSecondaryAnalysisActive]);

  const activeInteractionOption = find(secondaryAnalysisOptions, { editType: secondaryAnalysisAreaSelectionMode });
  const ActiveOptionIcon = activeInteractionOption?.icon || EditIcon;
  const ArrowIcon = isDrawingToolSelectionOpen ? ArrowDropUpIcon : ArrowDropDownIcon;

  return (
    !useOSDViewer &&
    canRunSecondaryAnalysis &&
    isSecondaryAnalysisActive && (
      <Grid item>
        <Box
          sx={{
            borderRadius: 10,
            paddingInline: 1,
            paddingBlock: 0.5,
            marginInline: 1,
            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200],
          }}
        >
          <RunSecondaryAnalysisButton
            caseId={caseId}
            selectedSlides={selectedSlides}
            isStartingSecondaryAnalysis={isStartingSecondaryAnalysis}
          />
          <Tooltip
            enterDelay={500}
            title={
              activeInteractionOption?.title
                ? activeInteractionOption?.title
                : !isDrawingToolSelectionOpen
                ? 'Open selections for secondary analysis'
                : 'Hide'
            }
            placement="bottom"
          >
            <ToggleButton
              selected={isDrawingToolSelectionOpen}
              sx={{ borderWidth: 0 }}
              size="small"
              ref={setAnchorEl}
              value="slideActions"
              onChange={() => setIsDrawingToolSelectionOpen(!isDrawingToolSelectionOpen)}
              disabled={Boolean(hasActiveNonAnnotationInteractions) || isStartingSecondaryAnalysis}
            >
              <ArrowIcon color={hasActiveNonAnnotationInteractions ? 'disabled' : 'primary'} />
              {isStartingSecondaryAnalysis ? (
                <CircularProgress size={20} sx={{ marginLeft: 1 }} />
              ) : (
                <ActiveOptionIcon
                  color={
                    hasActiveNonAnnotationInteractions
                      ? 'disabled'
                      : secondaryAnalysisAreaSelectionMode
                      ? 'primary'
                      : 'inherit'
                  }
                />
              )}
            </ToggleButton>
          </Tooltip>
          <Divider variant="middle" orientation="vertical" sx={{ display: 'inline' }} />
          <ToggleButton
            size="small"
            title={'Exit secondary analysis mode'}
            sx={{ borderWidth: 0 }}
            onClick={async () => {
              if (
                await confirmWithModal({
                  title: 'Exit secondary analysis mode',
                  text: 'Are you sure you want to exit secondary analysis mode?',
                })
              ) {
                setIsDrawingToolSelectionOpen(true);
                exitSecondaryAnalysis();
              }
            }}
            value="exitSecondaryAnalysis"
            disabled={isStartingSecondaryAnalysis}
          >
            <CloseIcon />
          </ToggleButton>

          <SecondaryAnalysisToolsPopper
            anchorEl={anchorEl}
            isStartingSecondaryAnalysis={isStartingSecondaryAnalysis}
            isDrawingToolSelectionOpen={isDrawingToolSelectionOpen}
            setIsDrawingToolSelectionOpen={setIsDrawingToolSelectionOpen}
            clearNonAnnotationInteractions={clearNonAnnotationInteractions}
          />
        </Box>
      </Grid>
    )
  );
};
