import { useJobs } from 'components/Pages/Jobs/useJobs';
import { SecondaryAnalysisStatus } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import {
  getAllFlatMapDeepHeatmapsFromSlide,
  SlideWithChannelAndResults,
} from 'components/Procedure/useSlideChannelsAndResults/utils';
import { ExperimentResult } from 'interfaces/experimentResults';
import { Dictionary, filter, find, first, isEmpty, map, orderBy, partition, size, sortBy, uniq } from 'lodash';
import { useMemo } from 'react';
import { JsonParam, useQueryParam } from 'use-query-params';

export const useActiveSecondaryAnalysisStates = () =>
  useQueryParam<{
    [slideId: string]: { [viewerIndex: number]: { [primaryRunOrchestrationId: string]: number } };
  }>('activeSecondaryAnalysisStates', JsonParam);

export const getActiveSecondaryAnalysis = ({
  experimentResultsInRun,
  primaryRunOrchestrationId,
  activeSecondaryAnalysisStates,
  slideId,
  viewerIndex,
}: {
  experimentResultsInRun: ExperimentResult[];
  primaryRunOrchestrationId: string;
  slideId: string;
  viewerIndex: number;
  activeSecondaryAnalysisStates: Dictionary<Dictionary<Dictionary<number>>>;
}): ExperimentResult | null => {
  const [flattenedResultsByCreatedAtDeleted, flattenedResultsByCreatedAtWithoutDeleted] = partition(
    orderBy(experimentResultsInRun, ['createdAt'], ['desc']),
    'deletedAt'
  );

  const defaultResultToDisplay =
    find(flattenedResultsByCreatedAtWithoutDeleted, {
      approved: true,
    }) ||
    find(flattenedResultsByCreatedAtWithoutDeleted, {
      internallyApproved: true,
    }) ||
    first(flattenedResultsByCreatedAtWithoutDeleted) ||
    first(flattenedResultsByCreatedAtDeleted);

  const selectedSecondaryAnalysisExperimentId =
    activeSecondaryAnalysisStates?.[slideId]?.[viewerIndex]?.[primaryRunOrchestrationId] ||
    defaultResultToDisplay?.experimentResultId;

  return (
    find(experimentResultsInRun, { experimentResultId: selectedSecondaryAnalysisExperimentId }) ||
    defaultResultToDisplay ||
    // Should never happen, but just in case
    find(experimentResultsInRun, { orchestrationId: primaryRunOrchestrationId })
  );
};

export const useGetSecondaryAnalysisJobForHeatmaps = ({ slide }: { slide: SlideWithChannelAndResults }) => {
  const [activeSecondaryAnalysisStates] = useActiveSecondaryAnalysisStates();

  const sortedSelectedHeatmapOrchestrationIds = useMemo(() => {
    const allSecondaryHeatmaps = filter(
      getAllFlatMapDeepHeatmapsFromSlide(slide),
      ({ secondaryAnalysisStatus }) => secondaryAnalysisStatus === SecondaryAnalysisStatus.SecondaryResult
    );
    return uniq(
      sortBy(
        map(allSecondaryHeatmaps, (heatmap) => {
          const primaryRunOrchestrationId = heatmap.primaryRunOrchestrationId;
          const experimentResultsInRun = filter(
            slide.experimentResults,
            (result) =>
              result.orchestrationId === primaryRunOrchestrationId ||
              result.primaryRunOrchestrationId === primaryRunOrchestrationId
          );
          return getActiveSecondaryAnalysis({
            slideId: slide.id,
            viewerIndex: slide.viewerIndex,
            activeSecondaryAnalysisStates,
            primaryRunOrchestrationId,
            experimentResultsInRun,
          })?.orchestrationId;
        })
      )
    );
  }, [activeSecondaryAnalysisStates, slide]);

  return useJobs({
    enabled: !isEmpty(sortedSelectedHeatmapOrchestrationIds) && Boolean(slide?.studyId),
    additionalFilters: { orchestrationIds: sortedSelectedHeatmapOrchestrationIds, studyId: slide?.studyId },
    fullData: true,
    pageSize: size(sortedSelectedHeatmapOrchestrationIds),
  });
};
