import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { filter, includes, indexOf, map } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { getChannelMarkers } from 'api/markerTypes';
import { runMultiplexCellSegmentation } from 'api/platform';
import { useCasesParams } from 'utils/useCasesParams';
import { encodeQueryParamsUsingSchema } from 'utils/useEncodedFilters';
import { PlatformStepper } from '../../PlatformStepper';
import { JobNameAndDescriptionStep } from '../../utils';
import { ConfigurationStep } from './ConfigurationStep';
import {
  IFormValues,
  MultiplexCellSegmentationModelTypes,
  RunMultiplexCellSegmentationSteps,
  runMultiplexCellSegmentationStepsOrder,
} from './types';

const SNACK_BAR_KEY_RUN_MULTIPLEX_CELL_SEGMENTATION = 'RUN_MULTIPLEX_CELL_SEGMENTATION';

export interface IFormProps {
  onClose: () => void;
}

const defaultValues: Partial<IFormValues> = {
  modelType: MultiplexCellSegmentationModelTypes.amikta,
  nuclearChannelNames: ['dapi'],
  membranalChannelNames: [],
};

export const RunMultiplexCellSegmentation = (props: IFormProps): ReactElement => {
  const { onClose } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [activeStepIndex, setActiveStep] = useState(0);
  const activeStep = runMultiplexCellSegmentationStepsOrder[activeStepIndex];
  const { casesParams, schema, options } = useCasesParams();

  const getEncodedParams = () => encodeQueryParamsUsingSchema(casesParams, schema, options);

  const { data: channelMarkersResponse } = useQuery({
    queryKey: ['multiplex_marker_types', getEncodedParams()],
    queryFn: ({ signal }) => getChannelMarkers(getEncodedParams(), signal),
  });

  const channelMarkers = channelMarkersResponse?.channelMarkerTypes;
  const channelMarkersInAllSlides = filter(channelMarkers, (channelMarker) => {
    return channelMarkersResponse?.channelAppearance[channelMarker] === channelMarkersResponse?.totalSlidesCount;
  });

  const channelSelectSchema = useMemo(() => {
    return yup
      .array()
      .of(yup.string())
      .test('is-valid-channel', 'Partial Cohort Channel', function (value) {
        const { path, createError } = this;

        if (!value) {
          return true; // if no value, pass validation
        }

        for (let i = 0; i < value.length; i++) {
          if (!includes(channelMarkersInAllSlides, value[i])) {
            return createError({ path, message: `Channel: ${value[i]} does not appear in all slides` });
          }
        }

        return true; // All channels are valid
      })
      .required();
  }, [channelMarkersInAllSlides]);

  const validationSchema = useMemo(
    () => ({
      [RunMultiplexCellSegmentationSteps.NameAndDescription]: yup.object({
        jobName: yup.string(),
        jobDescription: yup.string(),
      }),
      [RunMultiplexCellSegmentationSteps.Configuration]: yup.object({
        modelType: yup.string().required(),
        nuclearChannelNames: channelSelectSchema.min(1, 'At least one channel is required'),
        membranalChannelNames: channelSelectSchema,
        modelPath: yup.string().when('modelType', {
          is: MultiplexCellSegmentationModelTypes.amikta,
          then: yup.string().required(),
          otherwise: yup.string().notRequired().nullable(),
        }),
      }),
    }),
    [channelSelectSchema]
  );

  const currentValidationSchema = validationSchema[activeStep];

  const methods = useForm<IFormValues>({
    mode: 'onChange',
    resolver: yupResolver(currentValidationSchema),
    defaultValues,
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = methods;

  const runMultiplexCellSegmentationMutation = useMutation(runMultiplexCellSegmentation, {
    onError: () => {
      enqueueSnackbar('Error occurred, Multiplex Cell Segmentation failed', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('Multiplex Cell Segmentation Started', { variant: 'success' });
      onClose();
    },
    onSettled() {
      closeSnackbar(SNACK_BAR_KEY_RUN_MULTIPLEX_CELL_SEGMENTATION);
    },
  });

  const onSubmit: SubmitHandler<IFormValues> = async (data) => {
    enqueueSnackbar({
      variant: 'success',
      message: (
        <Grid container>
          <Grid item>
            <Typography>Waiting for Multiplex Cell Segmentation to start</Typography>
          </Grid>
          <Grid item>
            <CircularProgress sx={{ marginLeft: 10 }} color="inherit" size={20} />
          </Grid>
        </Grid>
      ),
      key: SNACK_BAR_KEY_RUN_MULTIPLEX_CELL_SEGMENTATION,
      autoHideDuration: null,
    });

    await runMultiplexCellSegmentationMutation.mutateAsync({
      ...casesParams,
      configParams: { ...data },
    });
  };

  const [isStepFailed, setIsStepFailed] = useState<Record<number, boolean>>();

  const checkValidationAndSetIsStepFailed = (
    step: RunMultiplexCellSegmentationSteps,
    objectToValidate: Record<string, any>
  ) => {
    validationSchema[step]
      .validate(objectToValidate)
      .then(() => {
        setIsStepFailed((prev) => ({
          ...prev,
          [indexOf(runMultiplexCellSegmentationStepsOrder, step)]: false,
        }));
      })
      .catch((err) => {
        console.error(err);
        setIsStepFailed((prev) => ({
          ...prev,
          [indexOf(runMultiplexCellSegmentationStepsOrder, step)]: true,
        }));
      });
  };

  const steps = {
    [RunMultiplexCellSegmentationSteps.NameAndDescription]: {
      label: RunMultiplexCellSegmentationSteps.NameAndDescription,
      subLabel: activeStep !== RunMultiplexCellSegmentationSteps.NameAndDescription && watch('jobName'),
      optional: true,
      content: <JobNameAndDescriptionStep control={control} errors={errors} register={register} />,
      onNextOrBackClick: () =>
        checkValidationAndSetIsStepFailed(RunMultiplexCellSegmentationSteps.NameAndDescription, {
          modelType: watch('modelType'),
          nuclearChannelNames: watch('nuclearChannelNames'),
          membranalChannelNames: watch('membranalChannelNames'),
        }),
    },
    [RunMultiplexCellSegmentationSteps.Configuration]: {
      label: RunMultiplexCellSegmentationSteps.Configuration,
      content: (
        <ConfigurationStep methods={methods} checkValidationAndSetIsStepFailed={checkValidationAndSetIsStepFailed} />
      ),
    },
  };

  return (
    <PlatformStepper
      handleSubmit={handleSubmit(onSubmit)}
      steps={map(runMultiplexCellSegmentationStepsOrder, (step) => steps[step])}
      setActiveStepForValidation={setActiveStep}
      isStepFailed={isStepFailed}
    />
  );
};
