import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

import { CellRulePanel, CellRuleStudyId, CellRuleValue, MinimalCellRule } from 'interfaces/cellRule';
import React, { useEffect, useState } from 'react';
import useCellRules from 'utils/queryHooks/cellRule/useCellRules';
import CellRulesDataGrid from '.';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import { CellType } from 'interfaces/cellType';
import { StainType } from 'interfaces/stainType';
import { filter, find, includes, map, uniq } from 'lodash';
import {
  useDeleteCellRulePanelMutation,
  useUpdateCellRulePanelMutation,
} from 'utils/queryHooks/cellRule/useCellRuleMutations';
import CellSelectDropdown from './CellRuleOptions/CellSelectDropdown';
import StainTypeSelectDropdown from './CellRuleOptions/StainTypeDropdown';

interface CellRulesPanelDataContainerProps {
  studyId: CellRuleStudyId;
  panelId: string;
}

export const CellRulesPanel: React.FC<CellRulesPanelDataContainerProps> = ({ studyId, panelId }) => {
  const { data: panel, isLoading: isLoadingPanel, isError: isErrorLoadingPanel } = useCellRules(panelId, studyId);
  const updatePanelMutation = useUpdateCellRulePanelMutation();
  const savedStainTypeIds = uniq(panel?.stainTypeIds);
  const savedCellTypeIds = uniq(map(panel?.rules, 'cellTypeId'));

  const [stainTypeIds, setStainTypeIds] = useState<string[]>(null);
  const [cellTypeIds, setCellTypeIds] = useState<string[]>(null);
  const [rules, setRules] = useState<MinimalCellRule[]>(null);

  useEffect(() => {
    // update state when panel is fetched
    if (Boolean(panel) && stainTypeIds === null && cellTypeIds === null && rules === null) {
      setStainTypeIds(uniq(panel?.stainTypeIds));
      setCellTypeIds(uniq(map(panel?.rules, 'cellTypeId')));
      setRules(panel.rules);
    }
  }, [panel]);

  const handleCellValueChange = (cellTypeId: string, stainTypeId: string, value: CellRuleValue) => {
    setRules((previousRules) => {
      const existingRule = find(
        previousRules,
        (rule) => rule.cellTypeId === cellTypeId && rule.stainTypeId === stainTypeId
      );
      if (!existingRule) {
        return [...previousRules, { cellTypeId, stainTypeId, ruleValue: value }];
      }
      const updatedRules = map(previousRules, (rule) => {
        if (rule.cellTypeId === cellTypeId && rule.stainTypeId === stainTypeId) {
          return { ...rule, ruleValue: value };
        }
        return rule;
      });
      return updatedRules;
    });
  };

  const handleSave = () => {
    // Only save rules that are relevant to the final stains and cell types (because we dont delete the rules when the stains or cell types are removed)
    const relevantRules = filter(
      rules,
      (rule) => includes(cellTypeIds, rule.cellTypeId) && includes(stainTypeIds, rule.stainTypeId)
    );
    updatePanelMutation.mutate({
      panelId,
      panelUpdates: { rules: relevantRules, stainTypeIds },
      studyId,
    });
  };

  return isLoadingPanel ? (
    <CellRulesPanelSkeleton />
  ) : isErrorLoadingPanel ? (
    <Typography>Failed to load panel</Typography>
  ) : (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StainTypeSelectDropdown
            selectedStainTypeIds={stainTypeIds}
            onSelectStainTypes={(stainTypes: StainType[]) => setStainTypeIds(map(stainTypes, 'id'))}
            mifMarkersOnly
            limitTags={10}
          />
        </Grid>
        <Grid item xs={12}>
          <CellSelectDropdown
            selectedCellTypeIds={cellTypeIds}
            onSelectCellTypes={(cellTypes: CellType[]) => setCellTypeIds(map(cellTypes, 'id'))}
          />
        </Grid>
        <Grid item xs={12}>
          <CellRulesDataGrid
            panelId={panelId}
            isLoading={isLoadingPanel}
            stainTypeIds={stainTypeIds}
            cellTypeIds={cellTypeIds}
            rules={rules}
            editable
            onCellValueChange={handleCellValueChange}
            onRemoveStainType={(stainTypeId: string) => {
              setStainTypeIds((previousStainTypeIds) => filter(previousStainTypeIds, (id) => id !== stainTypeId));
            }}
            onRemoveCellType={(cellTypeId: string) => {
              setCellTypeIds((previousCellTypeIds) => filter(previousCellTypeIds, (id) => id !== cellTypeId));
            }}
          />
        </Grid>
        <Grid item container>
          <Grid item>
            <Button onClick={handleSave} startIcon={<SaveIcon />} disabled={updatePanelMutation.isLoading}>
              {updatePanelMutation.isLoading ? 'Saving...' : 'Save'}
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                setStainTypeIds(savedStainTypeIds);
                setCellTypeIds(savedCellTypeIds);
                setRules(panel.rules);
              }}
              disabled={updatePanelMutation.isLoading}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export const CellRulesPanelSkeleton: React.FC = () => {
  return (
    <Grid item xs={12} container spacing={2}>
      <Grid item xs={12}>
        <Skeleton variant="rounded" width={'100%'} height={35} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rounded" width={'100%'} height={35} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rounded" width={'100%'} height={400} />
      </Grid>
    </Grid>
  );
};

interface CellRulesPanelAccordionProps {
  studyId: CellRuleStudyId;
  panel: CellRulePanel;
}

export const CellRulesPanelAccordion: React.FC<CellRulesPanelAccordionProps> = ({ studyId, panel }) => {
  const deleteMutation = useDeleteCellRulePanelMutation();
  const deletePanel = () => {
    deleteMutation.mutate({ studyId, panelId: panel.id });
  };

  return (
    <Accordion key={panel.id}>
      <AccordionSummary>
        <ListItemText>
          <Typography>{panel.label}</Typography>
        </ListItemText>
        <ListItemSecondaryAction>
          {deleteMutation.isLoading ? (
            <CircularProgress size={20} />
          ) : (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                deletePanel();
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </ListItemSecondaryAction>
      </AccordionSummary>
      <AccordionDetails>
        <CellRulesPanel studyId={studyId} panelId={panel.id} />
      </AccordionDetails>
    </Accordion>
  );
};
