import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Tooltip, Typography } from '@mui/material';
import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import { find, isEmpty, map, values } from 'lodash';
import React, { useCallback, useEffect, useMemo, useTransition } from 'react';
import { JsonParam, useQueryParam } from 'use-query-params';

import VerticalSliderPopup from 'components/atoms/VerticalSliderPopup';
import { StudyDeconvolutionSettings } from 'interfaces/study';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import {
  BaseSlideVisualSettings,
  deconvolutionComponentsVisualSettings,
  defaultDeconvolutionComponentSlideVisualSettings,
} from '../Infobar/slidesVisualizationAndConfiguration';
import { SlideWithChannelAndResults } from '../useSlideChannelsAndResults/utils';

export interface SlideDeconvolutionControlsProps {
  slide: SlideWithChannelAndResults;
  deconvolutionSettings: StudyDeconvolutionSettings;
}

export const getDeconvolutionComponentSettingsKey = (componentStainTypeId: string, displayName?: string) =>
  `${componentStainTypeId}${displayName ? `-${displayName}` : ''}`;

const applyChangesToDeconvolutionComponentSettings = ({
  changes,
  slideId,
  viewerIndex,
  componentVisualSettingsKey,
}: {
  changes: Partial<BaseSlideVisualSettings>;
  slideId: string;
  viewerIndex: number;
  componentVisualSettingsKey: string;
}): BaseSlideVisualSettings => {
  const viewerDeconvolutionComponentsVisualSettings = deconvolutionComponentsVisualSettings[viewerIndex];
  const previousViewerSettings = viewerDeconvolutionComponentsVisualSettings.value;
  const previousSlideSettings = previousViewerSettings?.[slideId];
  let componentSettingsSignal = previousSlideSettings?.[componentVisualSettingsKey];
  const newComponentSettingsWithPrevious = {
    ...(componentSettingsSignal?.value || defaultDeconvolutionComponentSlideVisualSettings),
    ...changes,
  };
  if (!componentSettingsSignal) {
    componentSettingsSignal = signal<BaseSlideVisualSettings>(newComponentSettingsWithPrevious);
  } else {
    componentSettingsSignal.value = newComponentSettingsWithPrevious;
  }
  viewerDeconvolutionComponentsVisualSettings.value = {
    ...previousViewerSettings,
    [slideId]: {
      ...previousSlideSettings,
      [componentVisualSettingsKey]: componentSettingsSignal,
    },
  };

  return newComponentSettingsWithPrevious;
};

const DeconvolutionComponentControl: React.FC<{
  slideId: string;
  componentStainTypeId: string;
  displayName: string;
  viewerIndex: number;
}> = ({ slideId, componentStainTypeId, viewerIndex, displayName }) => {
  useSignals();
  const componentVisualSettingsKey = getDeconvolutionComponentSettingsKey(componentStainTypeId, displayName);

  // In the URL, we store the settings for each stain type and viewer index to allow for sharing the settings when navigating to a different slide,
  // as well as to allow for sharing the settings when users share the URL.
  const [urlComponentStainTypeIdToVisualSettings, setUrlComponentStainTypeIdToVisualSettings] = useQueryParam<{
    [componentStainTypeId: string]: { [viewerIndex: number]: BaseSlideVisualSettings };
  }>('componentStainTypeIdToDeconvolutionComponentsVisual', JsonParam);

  const settingsFromUrl: BaseSlideVisualSettings = useMemo(
    () =>
      componentVisualSettingsKey
        ? urlComponentStainTypeIdToVisualSettings?.[componentVisualSettingsKey]?.[viewerIndex] ||
          // If the settings for the current stain type and viewer index are not found, use any viewer's settings for the stain type, if available
          find(values(urlComponentStainTypeIdToVisualSettings?.[componentVisualSettingsKey])) ||
          defaultDeconvolutionComponentSlideVisualSettings
        : defaultDeconvolutionComponentSlideVisualSettings,
    [viewerIndex, urlComponentStainTypeIdToVisualSettings, componentVisualSettingsKey]
  );

  const deconvolutionComponentVisualSettings = {
    ...settingsFromUrl,
    ...(deconvolutionComponentsVisualSettings[viewerIndex].value?.[slideId]?.[componentVisualSettingsKey]?.value || {}),
  };

  // Reset the base slide settings when the slide in the viewer changes
  useEffect(() => {
    applyChangesToDeconvolutionComponentSettings({
      changes: settingsFromUrl,
      slideId,
      viewerIndex,
      componentVisualSettingsKey,
    });
  }, [slideId, componentVisualSettingsKey, viewerIndex]);

  const [, startTransition] = useTransition();

  const applyNewViewSettings = useCallback(
    (newComponentSettings: Partial<BaseSlideVisualSettings>) => {
      const newComponentSettingsWithPrevious = applyChangesToDeconvolutionComponentSettings({
        changes: newComponentSettings,
        slideId,
        viewerIndex,
        componentVisualSettingsKey,
      });
      if (componentVisualSettingsKey) {
        startTransition(() => {
          setUrlComponentStainTypeIdToVisualSettings((currentUrlComponentStainTypeIdToVisualSettings) => ({
            ...currentUrlComponentStainTypeIdToVisualSettings,
            [componentVisualSettingsKey]: {
              ...currentUrlComponentStainTypeIdToVisualSettings?.[componentVisualSettingsKey],
              [viewerIndex]: newComponentSettingsWithPrevious,
            },
          }));
        });
      }
    },
    [startTransition, setUrlComponentStainTypeIdToVisualSettings, slideId, viewerIndex, componentVisualSettingsKey]
  );

  const handleToggleShowSlide = useCallback(() => {
    const previousShowValue = deconvolutionComponentVisualSettings?.show;
    applyNewViewSettings({ show: !previousShowValue });
  }, [applyNewViewSettings, deconvolutionComponentVisualSettings?.show]);

  const handleChangeSlideOpacity = useCallback(
    (newOpacity: number) => applyNewViewSettings({ opacity: newOpacity }),
    [applyNewViewSettings]
  );

  const currentOpacity = deconvolutionComponentVisualSettings?.opacity ?? 0;

  const { isLoadingStainTypeOptions, stainTypeIdToDisplayName } = useStainTypeIdToDisplayName();

  return (
    <Grid item container wrap="nowrap" alignItems="center" spacing={2} justifyContent="space-between">
      <Grid item md={8} mr={1}>
        <Tooltip
          title={
            displayName
              ? isLoadingStainTypeOptions
                ? `Loading...`
                : stainTypeIdToDisplayName(componentStainTypeId)
              : undefined
          }
        >
          <Typography variant="caption">
            {displayName || (isLoadingStainTypeOptions ? `Loading...` : stainTypeIdToDisplayName(componentStainTypeId))}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid item md={3}>
        <VerticalSliderPopup
          value={currentOpacity}
          show={Boolean(deconvolutionComponentVisualSettings?.show)}
          onToggleChange={handleToggleShowSlide}
          onValueChange={handleChangeSlideOpacity}
          tooltipProps={{ placement: 'left' }}
        />
      </Grid>
    </Grid>
  );
};

export const SlideDeconvolutionControls: React.FC<SlideDeconvolutionControlsProps> = ({
  slide,
  deconvolutionSettings,
}) => {
  const slideStain = slide.stainingType;
  const componentsForStain = deconvolutionSettings?.stainToComponentOperation?.[slideStain];

  const [expand, setExpand] = React.useState(true);

  return (
    Boolean(slide?.id) &&
    !isEmpty(componentsForStain) && (
      <Accordion
        square
        expanded={expand}
        onChange={() => setExpand((prev) => !prev)}
        slotProps={{ transition: { unmountOnExit: true } }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h3">Stain Separation</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" justifyContent="space-between" pr={1}>
            {map(componentsForStain, ({ componentStainTypeId, displayName }, index) => (
              <DeconvolutionComponentControl
                key={`${componentStainTypeId}-${index}`}
                slideId={slide.id}
                componentStainTypeId={componentStainTypeId}
                displayName={displayName}
                viewerIndex={slide.viewerIndex}
              />
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    )
  );
};
