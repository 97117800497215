import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeItem, TreeView } from '@mui/lab';
import { CircularProgress } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import {
  concat,
  Dictionary,
  filter,
  forEach,
  isEmpty,
  join,
  keys,
  map,
  pickBy,
  reduce,
  some,
  toPairs,
  toString,
  uniq,
  uniqBy,
} from 'lodash';
import React, { useEffect, useState } from 'react';

import { LayerVisualizationSettings, slidesLayerVisualizationSettings } from '../slidesVisualizationAndConfiguration';
import { LayerGroupControl } from './LayerGroupControl';
import LayerVisualizationControl from './LayerVisualizationControl';

export interface GroupedLayersVisualControlsProps {
  viewerIndex: number;
  slideId: string;
  groupedLayers: Dictionary<string[]>;
  layerIdsToDisplayNames?: Dictionary<string>;
  // In case layers have slide specific ids, we can map them to the global ids - to allow for persistent settings when navigating between slides
  layerIdsToUrlKeys?: Dictionary<string>;
  stainTypeId: string;
  groupDisplayNames?: Dictionary<string>;
  groupLoadingStates?: Dictionary<boolean>;
  groupSubtitles?: Dictionary<React.ReactNode>;
  defaultExpanded?: string[];
  disableToggle?: boolean;
  hideOrchestrationId?: boolean;
  defaultLayerSettings?: Partial<LayerVisualizationSettings>;
}

export const GroupedLayersVisualControls: React.FC<React.PropsWithChildren<GroupedLayersVisualControlsProps>> = ({
  viewerIndex,
  slideId,
  groupedLayers,
  layerIdsToDisplayNames,
  layerIdsToUrlKeys,
  stainTypeId,
  groupDisplayNames,
  groupLoadingStates,
  groupSubtitles,
  defaultExpanded,
  disableToggle,
  defaultLayerSettings,
}) => {
  useSignals();
  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex]?.value;
  const [layersExpanded, setLayersExpanded] = useState<string[]>(defaultExpanded || []);

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setLayersExpanded(nodeIds);
  };

  useEffect(() => {
    setLayersExpanded(defaultExpanded || []);
  }, [defaultExpanded]);

  const layerSelections = reduce(
    groupedLayers,
    (res, layers, parentKey) => {
      forEach(layers, (layerName) => {
        const layerId = `${parentKey}-${layerName}`;
        res[layerId] = viewerSlideLayerVisualizationSettings?.[slideId]?.[layerId]?.value?.selected || false;
      });
      return res;
    },
    {} as Dictionary<boolean>
  );

  // On first render, set the default expanded state based on selected heatmaps
  useEffect(() => {
    if (!isEmpty(layersExpanded)) {
      return;
    }
    const expandedLayers = keys(pickBy(layerSelections, (selected) => selected));
    const expandedParentKeys = filter(keys(groupedLayers), (parentKey) =>
      some(groupedLayers[parentKey], (layerName) => layerSelections[`${parentKey}-${layerName}`])
    );
    if (!isEmpty(expandedLayers) || !isEmpty(expandedParentKeys)) {
      setLayersExpanded([...expandedLayers, ...expandedParentKeys]);
    }
  }, [groupedLayers, viewerIndex, slideId]);

  return (
    <TreeView
      expanded={layersExpanded}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      onNodeToggle={handleToggle}
    >
      {map(
        uniqBy(toPairs(groupedLayers), ([parentKey, layers]) => join(concat(parentKey, layers), '-')),
        ([parentKey, layers]) =>
          !isEmpty(layers) && (
            <TreeItem
              key={`${join(concat(parentKey, layers), '-')}`}
              nodeId={parentKey}
              icon={groupLoadingStates?.[parentKey] ? <CircularProgress size={16} /> : undefined}
              label={
                <LayerGroupControl
                  disableToggle={disableToggle}
                  viewerIndex={viewerIndex}
                  slideId={slideId}
                  displayName={groupDisplayNames?.[parentKey]}
                  parentKey={parentKey}
                  layerIdsToDisplayNames={layerIdsToDisplayNames}
                  layerIdsToUrlKeys={layerIdsToUrlKeys}
                  layers={layers}
                  stainTypeId={stainTypeId}
                  subtitle={groupSubtitles?.[parentKey]}
                  defaultLayerSettings={defaultLayerSettings}
                />
              }
            >
              {map(uniq(layers), (layerName) => {
                const layerId = `${parentKey}-${layerName}`;
                const selected = disableToggle || layerSelections[layerId];
                return (
                  <TreeItem
                    key={layerName}
                    nodeId={toString(layerName)}
                    label={
                      <LayerVisualizationControl
                        disableToggle={disableToggle}
                        key={layerName}
                        parentKey={parentKey}
                        layerName={layerName}
                        layerDisplayName={layerIdsToDisplayNames?.[layerId]}
                        slideId={slideId}
                        viewerIndex={viewerIndex}
                        stainTypeId={stainTypeId}
                        layerUrlKey={layerIdsToUrlKeys?.[layerId] || layerId}
                        selected={selected}
                      />
                    }
                  />
                );
              })}
            </TreeItem>
          )
      )}
    </TreeView>
  );
};
