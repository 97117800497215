import { useCallback } from 'react';
import { NumberParam, StringParam, useQueryParam, useQueryParams } from 'use-query-params';

const secondaryAnalysisUrlSchema = {
  secondaryAnalysisActiveViewer: NumberParam,
  secondaryAnalysisSourceOrchestrationId: StringParam,
};

export const useSecondaryAnalysisOrchestrationIdQueryParams = () => {
  const [secondaryAnalysisSourceOrchestrationIdQueryParams, setSecondaryAnalysisOrchestrationIdQueryParams] =
    useQueryParams(secondaryAnalysisUrlSchema);

  const setSecondaryAnalysisOrchestrationIdForViewer = useCallback(
    (viewerIndex: number, orchestrationId: string) => {
      setSecondaryAnalysisOrchestrationIdQueryParams({
        secondaryAnalysisActiveViewer: viewerIndex,
        secondaryAnalysisSourceOrchestrationId: orchestrationId,
      });
    },
    [setSecondaryAnalysisOrchestrationIdQueryParams]
  );

  const clearSecondaryAnalysisOrchestrationIdQueryParams = useCallback(() => {
    setSecondaryAnalysisOrchestrationIdQueryParams({
      secondaryAnalysisActiveViewer: null,
      secondaryAnalysisSourceOrchestrationId: null,
    });
  }, [setSecondaryAnalysisOrchestrationIdQueryParams]);

  return {
    ...secondaryAnalysisSourceOrchestrationIdQueryParams,
    setSecondaryAnalysisOrchestrationIdForViewer,
    clearSecondaryAnalysisOrchestrationIdQueryParams,
  };
};

export const useSecondaryAnalysisSelectionModeQueryParam = () =>
  useQueryParam('secondaryAnalysisAreaSelectionMode', StringParam);
