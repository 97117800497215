import { Button, CircularProgress, Grid, Tooltip } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { Feature, Geometry } from '@turf/helpers';
import { find, flatMap, includes } from 'lodash';
import React from 'react';
import { BooleanParam, NumberParam, useQueryParam } from 'use-query-params';

import { JobType } from 'interfaces/job';
import { Permission } from 'interfaces/permissionOption';
import { getActiveAnnotationAssignmentIdViewerKey } from 'services/annotations/useAnnotationQueryParams';
import { useSecondaryAnalysis } from 'services/secondaryAnalysis';
import { getRelevantSecondaryAnalysisSelectionModes } from 'services/secondaryAnalysis/secondaryAnalysisSelectionModes';
import { useWholeSlideAnalysisJob } from 'services/secondaryAnalysis/useWholeSlideAnalysisJob';
import { usePermissions } from 'utils/usePermissions';

export const geojsonFeaturesToMultiPolygon = (features: Feature[]) => {
  const coordinates: any[] = flatMap(features, (feature: Feature) => (feature.geometry as Geometry).coordinates);
  return {
    type: 'MultiPolygon',
    coordinates: coordinates,
  };
};
interface SecondaryAnalysisTriggerProps {
  slideId: string;
  viewerIndex: number;
  autoInternallyApprove: boolean;
  orchestrationId: string;
  studyId: string;
  hasMultiplePublishedOrchestrationIds?: boolean;
  enabled?: boolean;
}

export const SecondaryAnalysisTrigger: React.FunctionComponent<
  React.PropsWithChildren<SecondaryAnalysisTriggerProps>
> = ({ enabled, viewerIndex, orchestrationId, hasMultiplePublishedOrchestrationIds, studyId }) => {
  useSignals();
  const { hasPermission } = usePermissions();
  const canRunSecondaryAnalysis = hasPermission(Permission.RunSecondaryAnalysis);
  const [useOSDViewer] = useQueryParam('useOSDViewer', BooleanParam);
  const [activeAnnotationAssignmentId] = useQueryParam(
    getActiveAnnotationAssignmentIdViewerKey(viewerIndex),
    NumberParam
  );

  const {
    context: { secondaryAnalysisSourceOrchestrationId, secondaryAnalysisActiveViewer },
    modifyContext: {
      clearSecondaryAnalysisOrchestrationIdQueryParams,
      setSecondaryAnalysisOrchestrationIdForViewer,
      setSecondaryAnalysisAreaSelectionMode,
    },
  } = useSecondaryAnalysis({ studyId, skipQuery: true });

  // Get the whole slide analysis job for the current orchestration id
  const { wholeSlideAnalysisJob, isLoadingOriginalJob } = useWholeSlideAnalysisJob({
    secondaryAnalysisSourceOrchestrationId: orchestrationId,
    studyId,
    skipQuery: !enabled,
  });

  const hasActiveSelectionForSecondaryAnalysis =
    secondaryAnalysisActiveViewer === viewerIndex && secondaryAnalysisSourceOrchestrationId === orchestrationId;

  const isOtherSecondaryAnalysisActive =
    secondaryAnalysisSourceOrchestrationId && !hasActiveSelectionForSecondaryAnalysis;

  const secondaryAnalysisOptions = getRelevantSecondaryAnalysisSelectionModes();

  return (
    !useOSDViewer &&
    canRunSecondaryAnalysis && (
      <Tooltip
        title={
          hasMultiplePublishedOrchestrationIds
            ? `Multiple published orchestration ids - will run on ${orchestrationId}`
            : ''
        }
      >
        <Grid item xs={12} container direction="row">
          <Grid item>
            <Tooltip
              title={
                !orchestrationId
                  ? 'No orchestration id found'
                  : isLoadingOriginalJob
                  ? 'Loading original job'
                  : activeAnnotationAssignmentId
                  ? 'Cannot run secondary analysis while annotating'
                  : isOtherSecondaryAnalysisActive
                  ? 'Cannot run secondary analysis while other secondary analysis is active'
                  : !wholeSlideAnalysisJob
                  ? 'No job found'
                  : !includes([JobType.CalculateFeatures, JobType.NucleaiExecutor], wholeSlideAnalysisJob.type)
                  ? `Whole slide analysis job is not a CalculateFeatures or a study assigned algorithm with final results, got ${wholeSlideAnalysisJob.type}`
                  : ''
              }
            >
              <span>
                <Button
                  disabled={
                    isLoadingOriginalJob ||
                    !wholeSlideAnalysisJob ||
                    Boolean(activeAnnotationAssignmentId) ||
                    isOtherSecondaryAnalysisActive
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (hasActiveSelectionForSecondaryAnalysis) {
                      clearSecondaryAnalysisOrchestrationIdQueryParams();
                    } else {
                      setSecondaryAnalysisOrchestrationIdForViewer(viewerIndex, orchestrationId);
                      setSecondaryAnalysisAreaSelectionMode((previousMode) =>
                        // Don't change the previous mode, if set
                        find(secondaryAnalysisOptions, { editType: previousMode })
                          ? previousMode
                          : find(secondaryAnalysisOptions, { isDefault: true })?.editType
                      );
                    }
                  }}
                >
                  {hasActiveSelectionForSecondaryAnalysis
                    ? 'Cancel Secondary Analysis'
                    : 'Annotate for Secondary Analysis'}
                  {isLoadingOriginalJob && <CircularProgress size={20} sx={{ marginLeft: 1 }} />}
                </Button>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Tooltip>
    )
  );
};

export default SecondaryAnalysisTrigger;
