import { DeckGLProps } from '@deck.gl/react/typed';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { isEmpty, omit } from 'lodash';
import React from 'react';

import { Chip, ChipProps, Grid, useTheme } from '@mui/material';
import Loader from 'components/Loader';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { HeatmapsImagePyramids, ImagePyramid } from 'components/Procedure/useSlideImages';
import { Permission } from 'interfaces/permissionOption';
import { useActiveAnnotationAssignmentForViewer } from 'services/annotations/useAnnotationQueryParams';
import { BooleanParam, useQueryParam } from 'use-query-params';
import useScannerSlideVisualizationPreset from 'utils/queryHooks/useScannerSlideVisualizationPresets';
import { usePermissions } from 'utils/usePermissions';
import { SlideDeckGLOverview } from './SlideDeckGLOverview';
import { SlideDeckGLViewer } from './SlideDeckGLViewer';

export const ViewerContainer: React.FC<{
  displaySlideId?: boolean;
  showNavigation?: boolean;
  slide: SlideWithChannelAndResults;
  baseImagePyramids: ImagePyramid;
  heatmapsImagePyramids: HeatmapsImagePyramids;
  viewSize: { width: number; height: number };
  onViewStateChange: DeckGLProps['onViewStateChange'];
  hideComments?: boolean;
  procedureId: number;
  isLoading?: boolean;
  showTaggedChip?: boolean;
  isSingleViewerContainer?: boolean;
}> = (props) => {
  const theme = useTheme();
  const darkBackground = !isEmpty(props.slide?.channelsMetadata);
  const { hasPermission } = usePermissions();
  const [hideDeckGLOverlays] = useQueryParam('hideDeckGLOverlays', BooleanParam);

  const [activeAnnotationAssignmentId] = useActiveAnnotationAssignmentForViewer(props.slide?.viewerIndex);
  const canAnnotateSlides: boolean = hasPermission(Permission.AnnotateSlides);
  const activeAnnotations: boolean = Boolean(activeAnnotationAssignmentId) && canAnnotateSlides;

  const isScannerSlideVisualizationPresetEnabled = Boolean(props.slide?.scannerManufacturer);
  const scannerSlideVisualizationPresetQuery = useScannerSlideVisualizationPreset(props.slide?.scannerManufacturer, {
    enabled: isScannerSlideVisualizationPresetEnabled,
  });
  const scannerVisualizationPreset = scannerSlideVisualizationPresetQuery.data;
  const isLoadingScannerSlideVisualizationPreset =
    scannerSlideVisualizationPresetQuery.isLoading && isScannerSlideVisualizationPresetEnabled;

  return (
    <Grid
      item
      className="deckgl-slides-viewer"
      height="100%"
      width={props.viewSize.width}
      overflow="hidden"
      position="relative"
      flex={1}
      sx={{
        background: darkBackground ? 'black' : '#fff',
        ...((props.showTaggedChip || activeAnnotations) && !props.isSingleViewerContainer
          ? {
              border: `2px solid ${theme.palette.custom.highlight.main}`,
            }
          : {}),
      }}
      alignContent="center"
      justifyContent="center"
      onContextMenu={(event) => event.preventDefault()}
    >
      {!props.isLoading && props.baseImagePyramids && !isLoadingScannerSlideVisualizationPreset ? (
        <>
          <SlideDeckGLViewer {...props} visualizationSettingsPreset={scannerVisualizationPreset?.visualizationPreset} />
          {!hideDeckGLOverlays && Boolean(props.showNavigation) && (
            <SlideDeckGLOverview {...props} darkBackground={darkBackground} />
          )}
        </>
      ) : (
        <Loader />
      )}
      {!hideDeckGLOverlays && props.showTaggedChip && (
        <ViewerChip label="Tagged Slide" icon={<LocalOfferIcon color="inherit" />} />
      )}
      {!hideDeckGLOverlays && activeAnnotations && (
        <ViewerChip
          label={`Annotating slide in assignment- ${activeAnnotationAssignmentId}`}
          sx={{
            top: 10,
          }}
          icon={<AssignmentOutlinedIcon color="inherit" />}
        />
      )}
    </Grid>
  );
};

const ViewerChip: React.FC<ChipProps> = (props) => {
  const sx: ChipProps['sx'] = {
    zIndex: 100,
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: 0,
    color: 'black',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    border: '1px solid',
    ...props.sx,
  };

  return <Chip variant="filled" size="small" sx={sx} {...omit(props, 'sx')} />;
};
