import { useSignals } from '@preact/signals-react/runtime';
import React from 'react';

import VerticalSliderPopup from 'components/atoms/VerticalSliderPopup';
import {
  getSlideChannelColorSettingsSignal,
  slidesChannelToggles,
  useUpdateSlideChannelColorSettings,
} from '../colorSettings';

export const MultiplexOpacityToggle: React.FC<
  React.PropsWithChildren<{
    viewerIndex: number;
    slideId: string;
    channelId: string;
    selected: boolean;
    markerType?: string;
  }>
> = ({ viewerIndex, slideId, channelId, selected, markerType }) => {
  useSignals();
  const slideChannelSettingsSignal = getSlideChannelColorSettingsSignal(viewerIndex, slideId, channelId);
  const viewerSlidesChannelsToggles = slidesChannelToggles[viewerIndex];

  const { handleOpacityChange, handleShowChange } = useUpdateSlideChannelColorSettings();

  return (
    <VerticalSliderPopup
      value={selected ? slideChannelSettingsSignal?.value?.opacity ?? 0 : 0}
      disabled={!selected}
      show={Boolean(selected && viewerSlidesChannelsToggles?.value?.[slideId]?.[channelId])}
      onToggleChange={() => {
        handleShowChange(viewerIndex, slideId, channelId, markerType);
      }}
      onValueChange={(value: number) => {
        handleOpacityChange(viewerIndex, slideId, channelId, value, markerType);
      }}
      tooltipProps={{ placement: 'left' }}
    />
  );
};
