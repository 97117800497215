import { Grid, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { filter, find, first, groupBy, isEmpty, keyBy, map, size } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, Path, UseFormReturn } from 'react-hook-form';

import { getModel } from 'api/platform';
import { getStainTypeFilteredIds } from 'api/stainTypes';
import LabelledDropdown from 'components/atoms/Dropdown/LabelledDropdown';
import ModelsTable from 'components/ModelsTable';
import { getModelId } from 'components/Pages/CalculateFeatures/utils';
import { modelTypeRunInferenceOptions, modelTypesByApiModelValue } from 'components/Pages/Jobs/inferenceFieldsOptions';
import { Model } from 'interfaces/model';
import { getStainingMethodByStainType } from 'interfaces/stainType';
import { humanize } from 'utils/helpers';
import { useModelTypeOptions } from 'utils/queryHooks/useModelTypeOptions';
import { casesOptions, CasesParams, casesSchema } from 'utils/useCasesParams';
import { encodeQueryParamsUsingSchema } from 'utils/useEncodedFilters';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';

export interface SelectModelStepProps<
  FormFields extends Record<string, any>,
  ModelUrlField extends Path<FormFields>,
  ModelTypeField extends Path<FormFields> | undefined = undefined,
  SlideStainTypeField extends Path<FormFields> | undefined = undefined
> {
  jobId?: string;
  casesParams: CasesParams;
  debug?: boolean;
  onSelectModelUrl?: (modelUrl: string) => void;
  onSelectStainType?: (stainType: string) => void;
  onSelectModelType?: (modelType: string) => void;
  formMethods: UseFormReturn<FormFields, any>;
  modelUrlField: ModelUrlField;
  modelTypeField?: ModelTypeField;
  slideStainTypeField?: SlideStainTypeField;
  modelType?: ModelTypeField extends string ? string : undefined;
  slideStainType?: SlideStainTypeField extends string ? string : undefined;
}

export const SelectModelStep = <
  FormFields extends Record<string, any>,
  ModelUrlField extends Path<FormFields>,
  ModelTypeField extends Path<FormFields> | undefined = undefined,
  SlideStainTypeField extends Path<FormFields> | undefined = undefined
>({
  casesParams,
  debug = false,
  formMethods,
  modelType: modelTypeFromProps,
  slideStainType: slideStainTypeFromProps,
  modelUrlField,
  modelTypeField,
  slideStainTypeField,
  onSelectModelUrl,
  onSelectStainType,
  onSelectModelType,
}: React.PropsWithChildren<SelectModelStepProps<FormFields, ModelUrlField, ModelTypeField, SlideStainTypeField>>) => {
  const {
    control,
    register,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = formMethods;
  const modelUrl = watch(modelUrlField);
  const modelType = modelTypeFromProps || watch(modelTypeField);
  const slideStainType = slideStainTypeFromProps || watch(slideStainTypeField);
  const { data: modelTypeOptions, isLoading: isLoadingModelTypeOptions } = useModelTypeOptions({ retry: false });
  const modelTypeOptionsByType = groupBy(modelTypeOptions, 'type');

  const getEncodedParams = () => {
    return encodeQueryParamsUsingSchema(casesParams, casesSchema, casesOptions);
  };

  const { data: stains, isLoading: isLoadingStains } = useQuery({
    queryKey: ['slidesStainsTypes', getEncodedParams()],
    queryFn: ({ signal }) => getStainTypeFilteredIds(getEncodedParams(), signal),
  });
  const { stainTypeIdToDisplayName, isLoadingStainTypeOptions } = useStainTypeIdToDisplayName();

  // Update stain type dropdown if there is only one stain type.
  // This is to avoid the case where the user has to select the only stain type.
  // UseEffect is used because defaultValues from useForm is not updating the dropdown.
  useEffect(() => {
    if (slideStainTypeField && !isLoadingStains && size(stains) === 1) {
      const newStainType = first(stains);
      reset({ ...getValues(), [slideStainTypeField]: newStainType });
      onSelectStainType?.(newStainType);
    }
  }, [slideStainTypeField, stains, isLoadingStains]);

  const stainsOptions = map(stains, (currStain) => ({
    value: currStain,
    text: stainTypeIdToDisplayName(currStain),
  }));

  const onSelectModel = (model: Model) => {
    setValue(modelUrlField, model.url as any, { shouldValidate: true });
    onSelectModelUrl?.(model.url);
  };

  const modelTypes = keyBy(modelTypeRunInferenceOptions, 'value');

  const modelId = getModelId(modelUrl);

  const { data: modelData } = useQuery({
    queryKey: ['model', modelId],
    queryFn: ({ signal }) => getModel(modelId, signal),
    retry: false,
    enabled: !isEmpty(modelUrl) && Boolean(modelId),
  });

  const [isSetModelType, setIsSetModelType] = useState(Boolean(modelTypeFromProps));

  useEffect(() => {
    if (modelTypeField && !isSetModelType && isEmpty(modelType) && !isEmpty(modelData?.meta?.modelType)) {
      const modelTypeFromModelData = modelTypesByApiModelValue[modelData.meta.modelType]?.value;
      reset({ ...getValues(), [modelTypeField]: modelTypeFromModelData });

      onSelectModelType?.(modelTypeFromModelData);
      setIsSetModelType(true);
    }
  }, [modelTypeField, modelData?.meta?.modelType, modelType, reset, getValues]);

  return (
    <Grid container direction="column" spacing={2}>
      {Boolean(!modelTypeFromProps && !slideStainTypeFromProps) && (
        <Grid item container spacing={2}>
          {Boolean(!slideStainTypeFromProps) && (
            <Grid item xs={modelTypeFromProps ? 6 : 12}>
              <Controller
                control={control}
                name={slideStainTypeField}
                render={({ field: { onChange, value } }) => (
                  <LabelledDropdown
                    key={size(stainsOptions)}
                    disabled={size(stainsOptions) <= 1}
                    label="Stain Type Selection"
                    options={stainsOptions}
                    value={(!isLoadingStains && size(stainsOptions) === 1 && first(stainsOptions).value) || value}
                    onOptionSelected={(optionValue) => {
                      onChange(optionValue);
                      onSelectStainType?.(optionValue);
                    }}
                    error={Boolean(errors[slideStainTypeField])}
                    helperText={
                      errors[slideStainTypeField]?.message
                        ? humanize(errors[slideStainTypeField]?.message as any)
                        : size(stainsOptions) > 1
                        ? 'There is more than one stain type, please select one'
                        : 'required before choosing model'
                    }
                    required
                    loading={isLoadingStains || isLoadingStainTypeOptions}
                  />
                )}
              />
            </Grid>
          )}
          {Boolean(!modelTypeFromProps) && (
            <Grid item xs={slideStainTypeFromProps ? 6 : 12}>
              <Controller
                control={control}
                name={modelTypeField}
                render={({ field: { onChange, value } }) => (
                  <LabelledDropdown
                    label="Model Type"
                    options={modelTypeRunInferenceOptions}
                    value={value || ''}
                    disabled={isEmpty(slideStainType)}
                    onOptionSelected={(optionValue) => {
                      const matchingModelTypeRunInferenceOption = find(modelTypeRunInferenceOptions, {
                        value: optionValue,
                      });
                      const modelTypeOptionsWithUrls = filter(
                        modelTypeOptionsByType[matchingModelTypeRunInferenceOption?.apiModelValue || optionValue],
                        ({ defaultModelUrl }) => Boolean(defaultModelUrl)
                      );

                      const stainingMethod = getStainingMethodByStainType(slideStainType);
                      const matchingModelType =
                        find(
                          modelTypeOptionsWithUrls,
                          (modelTypeOption) => modelTypeOption.stainingMethod === stainingMethod
                        ) || find(modelTypeOptionsWithUrls, (modelTypeOption) => !modelTypeOption.stainingMethod);

                      if (debug) {
                        console.debug(
                          'Updating default model url to',
                          matchingModelType?.defaultModelUrl || '',
                          'for model type',
                          matchingModelTypeRunInferenceOption?.apiModelValue || optionValue,
                          'with staining method',
                          stainingMethod,
                          'and slide stain type',
                          slideStainType,
                          {
                            modelTypeOptionsByType,
                            matchingModelType,
                            optionValue,
                            matchingModelTypeRunInferenceOption,
                          }
                        );
                      }
                      setValue(modelUrlField, (matchingModelType?.defaultModelUrl || '') as any);
                      onChange(optionValue);

                      onSelectModelType?.(optionValue);
                    }}
                    error={Boolean(errors[modelTypeField])}
                    helperText={
                      errors[modelTypeField]?.message
                        ? humanize(errors[modelTypeField]?.message as any)
                        : 'required before choosing model'
                    }
                    loading={isLoadingModelTypeOptions}
                    required
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      )}
      <Grid item>
        <Controller
          control={control}
          name={modelUrlField}
          render={({ field: { onChange } }) => (
            <TextField
              value={modelUrl || ''}
              label="Model Url"
              {...register(modelUrlField)}
              onChange={(event) => {
                onChange(event);
                onSelectModelUrl?.(event.target.value);
              }}
              placeholder="Choose model from the table or write here the artifact url"
              error={Boolean(errors[modelUrlField])}
              helperText={humanize(errors[modelUrlField]?.message as any)}
              required
            />
          )}
        />
      </Grid>
      <Grid item>
        <ModelsTable
          modelType={modelTypes[modelType]?.apiModelValue || modelType}
          stainType={slideStainType}
          onSelect={onSelectModel}
          selectedModelUrl={modelUrl}
          enabled={!isEmpty(slideStainType) && !isEmpty(modelType)}
        />
      </Grid>
    </Grid>
  );
};
