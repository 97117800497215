import { useSignals } from '@preact/signals-react/runtime';
import { compact, concat, debounce, entries, filter, flatMap, flatten, fromPairs, isEmpty, map, values } from 'lodash';
import { Viewer as osdViewerInterface } from 'openseadragon';
import React, { useEffect, useMemo, useRef } from 'react';

import {
  LayerVisualizationSettings,
  slidesLayerVisualizationSettings,
} from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { slidesChannelNormalizationSettings } from 'components/Procedure/SlideControls/Multiplex/channelNormalizations';
import {
  SlideChannelColorSettings,
  slidesChannelColorSettings,
  slidesChannelToggles,
} from 'components/Procedure/SlideControls/Multiplex/colorSettings';
import { ChannelMetadata } from 'components/Procedure/useSlideChannelsAndResults/channelMetadata';
import {
  FeatureMetadata,
  FeatureMetadataSecondaryAnalysisEntry,
} from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { MAX_UINT16, MAX_UINT8 } from 'utils/constants';
import Viewer from '../SlidesViewer/Viewer';
import { handleOverlaysChanges } from './helpers';
import { OSDOverlayProps } from './types';

interface Props {
  osdViewer: osdViewerInterface;
  slide: SlideWithChannelAndResults;
  viewer: Viewer;
}
const Overlays: React.FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  useSignals();
  const { slide } = props;
  const maxSlideChannelValue = slide.encoding === 'uint16' ? MAX_UINT16 : MAX_UINT8;
  const slideViewerChannelColorSettings = slidesChannelColorSettings[slide.viewerIndex];
  const slideViewerChannelNormalizationSettings = slidesChannelNormalizationSettings[slide.viewerIndex];

  const viewerSlideChannelColorSettings = slideViewerChannelColorSettings?.value[slide.id];
  const viewerSlideChannelNormalizationSettings = slideViewerChannelNormalizationSettings?.value[slide.id];
  const channelSettings = fromPairs(
    map(entries(viewerSlideChannelColorSettings), ([key, channelSignal]) => [
      key,
      {
        ...(channelSignal.value || {}),
        range: viewerSlideChannelNormalizationSettings?.[key]?.value || [0, maxSlideChannelValue],
      },
    ])
  );

  const slideViewerChannelToggles = slidesChannelToggles[slide.viewerIndex];
  const channelToggles = slideViewerChannelToggles?.value[slide.id];

  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[slide.viewerIndex];

  const heatmapSettings = fromPairs(
    map(entries(viewerSlideLayerVisualizationSettings?.value?.[slide.id]), ([key, settingsSignal]) => [
      key,
      settingsSignal.value,
    ])
  );

  const baseHeatmaps = React.useMemo(
    () =>
      compact(
        flatMap(
          concat(
            slide.heatmapResults?.publishedResults,
            flatten(values(slide.heatmapResults?.internalResults)),
            flatten(values(slide.internalHeatmaps))
          ),
          (heatmap) =>
            concat<FeatureMetadata | FeatureMetadataSecondaryAnalysisEntry>(heatmap.secondaryResults || [], heatmap)
        )
      ),
    [slide.heatmapResults]
  );

  const heatmapOverlays: Array<FeatureMetadata & LayerVisualizationSettings> = useMemo(() => {
    const selectedHeatmapOverlays = flatMap(baseHeatmaps, (item) => {
      const selectedNestedHeatmaps = filter(
        map(
          item.nestedItems,
          (nestedItem) =>
            ({
              ...nestedItem,
              ...(heatmapSettings?.[nestedItem.id] || {}),
            } as LayerVisualizationSettings & FeatureMetadata)
        ),
        'selected'
      );
      return selectedNestedHeatmaps.length === (item.nestedItems || []).length
        ? ({
            ...item,
            ...(heatmapSettings?.[item.id] || {}),
          } as LayerVisualizationSettings & FeatureMetadata)
        : selectedNestedHeatmaps;
    });
    return selectedHeatmapOverlays;
  }, [slide.id, baseHeatmaps, JSON.stringify(heatmapSettings)]);

  const activeChannelOverlays: Array<ChannelMetadata & SlideChannelColorSettings> = useMemo(
    () =>
      map(
        filter(slide.channelsMetadata, ({ id }: ChannelMetadata) => channelToggles?.[id]),
        (channel: ChannelMetadata) => ({
          show: true,
          ...channel,
          ...(channelSettings?.[channel.id] || {}),
        })
      ),
    [slide.id, slide.channelsMetadata, channelToggles, JSON.stringify(channelSettings)]
  );

  // Render nothing if there are no overlays exist for the slide
  return !isEmpty(slide.channelsMetadata) || !isEmpty(baseHeatmaps) ? (
    <OverlaysInner
      {...props}
      activeChannelOverlays={activeChannelOverlays}
      heatmapOverlays={heatmapOverlays}
      maxSlideChannelValue={maxSlideChannelValue}
    />
  ) : null;
};

export default Overlays;

const OverlaysInner: React.FunctionComponent<
  React.PropsWithChildren<
    Props & {
      maxSlideChannelValue: number;
      activeChannelOverlays: Array<ChannelMetadata & SlideChannelColorSettings>;
      heatmapOverlays: Array<FeatureMetadata & LayerVisualizationSettings>;
    }
  >
> = (props) => {
  const pendingUpdatesRef = useRef([]);
  const prevChannelOverlaysRef = useRef([]);
  const prevHeatmapOverlaysRef = useRef([]);
  const currentlyUpdatingSlideRef = useRef<string>('');

  const forceUpdatesTimeout = useRef<number | null>(null);

  useEffect(() => {
    const orderedOverlays = [
      ...map(
        props.activeChannelOverlays,
        (overlay, overlayIndex): OSDOverlayProps => ({ ...overlay, type: 'channel', overlayIndex })
      ),
      ...map(
        props.heatmapOverlays,
        (overlay, heatmapOffset): OSDOverlayProps => ({
          ...overlay,
          type: 'heatmap',
          url: overlay.heatmapUrl,
          overlayIndex: Math.max((props.activeChannelOverlays || []).length, 1) + heatmapOffset,
        })
      ),
    ];

    const delayedUpdate = () => {
      console.debug('Forcing update');
      pendingUpdatesRef.current = [orderedOverlays];
      callPendingUpdates();
      if (props.osdViewer.world.needsDraw()) {
        props.osdViewer.world.draw();
      }
    };

    if (currentlyUpdatingSlideRef.current === props.slide.id) {
      pendingUpdatesRef.current = [orderedOverlays];
      // In case an update is stuck, force it after 5 seconds
      forceUpdatesTimeout.current = window.setTimeout(delayedUpdate, 5000);
    } else {
      updateTheCurrent(orderedOverlays);
      window.clearTimeout(forceUpdatesTimeout.current);
    }
    if (props.osdViewer.world.needsDraw()) {
      props.osdViewer.world.draw();
    }
  }, [props.slide.id, props.heatmapOverlays, props.activeChannelOverlays, props.osdViewer, props.viewer]);

  const callPendingUpdates = () => {
    while (!isEmpty(pendingUpdatesRef.current)) {
      const overlays = pendingUpdatesRef.current.pop();
      if (overlays) {
        updateTheCurrent(overlays);
      }
    }
  };

  const updateTheCurrent = (orderedOverlays: OSDOverlayProps[]) => {
    currentlyUpdatingSlideRef.current = props.slide.id;
    const { osdViewer } = props;
    let indentationIndex = 1;
    let prevIndentationIndex = 1;

    const newChannelOverlay = filter(orderedOverlays, { type: 'channel' });
    const newHeatmapOverlay = filter(orderedOverlays, { type: 'heatmap' });

    if (!isEmpty(newChannelOverlay)) {
      indentationIndex = newChannelOverlay.length;
      prevIndentationIndex = prevChannelOverlaysRef.current.length;
    }

    handleOverlaysChanges({
      overlays: newChannelOverlay,
      prevOverlays: prevChannelOverlaysRef.current,
      viewer: osdViewer,
      indentationIndex: 0,
      prevIndentationIndex: 0,
      onDone: (overlays, forceRedraw) => {
        setColorMap(overlays);
        handleHeatmapsOverlaysChanges(newHeatmapOverlay, indentationIndex, prevIndentationIndex);
        if (forceRedraw) {
          osdViewer.forceRedraw();
        }
      },
      onDoneNothingChange: () =>
        handleHeatmapsOverlaysChanges(newHeatmapOverlay, indentationIndex, prevIndentationIndex),
      onColorMapChange: (overlays: OSDOverlayProps[]) => {
        setColorMapDebounce(overlays);
        handleHeatmapsOverlaysChanges(newHeatmapOverlay, indentationIndex, prevIndentationIndex);
      },
      additionalOptions: compositionOperationOption,
    });
  };

  const handleHeatmapsOverlaysChanges = (
    newHeatmapOverlay: OSDOverlayProps[],
    indentationIndex: number,
    prevIndentationIndex: number
  ) => {
    const { osdViewer } = props;

    handleOverlaysChanges({
      overlays: newHeatmapOverlay,
      prevOverlays: prevHeatmapOverlaysRef.current,
      viewer: osdViewer,
      indentationIndex: indentationIndex,
      prevIndentationIndex: prevIndentationIndex,
      onDone: (overlays, forceRedraw) => {
        prevHeatmapOverlaysRef.current = overlays;
        currentlyUpdatingSlideRef.current = '';
        if (forceRedraw) {
          osdViewer.forceRedraw();
        }
        callPendingUpdates();
      },
    });
  };

  const setColorMapDebounce = debounce((overlays: OSDOverlayProps[]) => {
    setColorMap(overlays);
  }, 200);

  const setColorMap = (overlays: OSDOverlayProps[]) => {
    const filterItems: { hexColor: any; range: number[]; gamma: number; indexItem: number }[] = map(
      overlays,
      (overlay, index) => ({
        hexColor: overlay.color,
        range: overlay.range,
        gamma: overlay.gamma,
        indexItem: index,
      })
    );

    if (!isEmpty(filterItems))
      props.viewer.setColorMap(
        map(filterItems, (item) => ({
          ...item,
          // Convert the range to 0-255
          range: map(item.range, (n) => (n / (props.maxSlideChannelValue || MAX_UINT8)) * MAX_UINT8),
        }))
      );
    prevChannelOverlaysRef.current = overlays;
  };

  return <></>;
};

const compositionOperationOption = { compositeOperation: 'lighter' };
