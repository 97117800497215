import { signal } from '@preact/signals-react';
import { FeatureCollection } from '@turf/helpers';
import { MAX_VIEWERS } from 'components/Procedure/SlidesViewer/constants';
import { cloneDeep, filter, includes, isArray, times } from 'lodash';
import { secondaryAnalysisExclusionModes, secondaryAnalysisInclusionModes } from './secondaryAnalysisSelectionModes';

export const secondaryAnalysisPolygons = times(MAX_VIEWERS, () => signal<FeatureCollection | null>(null));

export const getSecondaryAnalysisFeatures = ({
  viewerIndex,
  secondaryAnalysisSourceOrchestrationId,
}: {
  viewerIndex: number;
  secondaryAnalysisSourceOrchestrationId: string;
}) => {
  const secondaryAnalysisFeature = secondaryAnalysisPolygons[viewerIndex].value;

  const secondaryAnalysisFeatures = filter(
    secondaryAnalysisFeature?.features,
    (feature) => feature.properties.orchestrationId === secondaryAnalysisSourceOrchestrationId
  );
  const inclusionFeatures = filter(secondaryAnalysisFeatures, (feature) =>
    includes(secondaryAnalysisInclusionModes, feature.properties.featureType)
  );

  const exclusionFeatures = filter(secondaryAnalysisFeatures, (feature) =>
    includes(secondaryAnalysisExclusionModes, feature.properties.featureType)
  );

  return {
    secondaryAnalysisFeatures,
    inclusionFeatures,
    exclusionFeatures,
  };
};

export const deleteSecondaryAnalysisFeatureByViewerAndFeatureIndex = (viewerIndex: number, featureIndex: number) => {
  const polygonsBeforeDelete = secondaryAnalysisPolygons[viewerIndex].value;
  if (
    !polygonsBeforeDelete ||
    featureIndex === undefined ||
    !isArray(polygonsBeforeDelete.features) ||
    !polygonsBeforeDelete.features[featureIndex]
  ) {
    console.warn('No feature to delete', { polygonsBeforeDelete, featureIndex });
    return;
  }
  const updatedData = cloneDeep(polygonsBeforeDelete);
  updatedData.features.splice(featureIndex, 1);
  secondaryAnalysisPolygons[viewerIndex].value = updatedData;
};
