import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { Button, Dialog, DialogContent, Grid, IconButton } from '@mui/material';
import { cloneDeep, filter, forEach, get, includes, isArray, isEmpty, keys, map, size, some } from 'lodash';
// @ts-ignore
import plotly from 'plotly.js/dist/plotly';
import React from 'react';
// @ts-ignore
import PlotlyEditor, {
  GraphCreatePanel,
  GraphSubplotsPanel,
  GraphTransformsPanel,
  PanelMenuWrapper,
  StyleAxesPanel,
  StyleColorbarsPanel,
  StyleImagesPanel,
  StyleLayoutPanel,
  StyleLegendPanel,
  StyleMapsPanel,
  StyleNotesPanel,
  StyleShapesPanel,
  StyleSlidersPanel,
  StyleTracesPanel,
  StyleUpdateMenusPanel,
} from 'react-chart-editor';
import Plot, { Figure, PlotParams } from 'react-plotly.js';

// matches gd._fullLayout._subplots categories except for xaxis & yaxis which
// are in fact cartesian types
export const TRACE_TO_AXIS = {
  cartesian: [
    'scatter',
    'scattergl',
    'box',
    'violin',
    'bar',
    'heatmap',
    'heatmapgl',
    'contour',
    'ohlc',
    'candlestick',
    'histogram',
    'histogram2d',
    'histogram2dcontour',
    'carpet',
    'scattercarpet',
    'contourcarpet',
    'waterfall',
    'funnel',
  ],
  ternary: ['scatterternary'],
  gl3d: ['scatter3d', 'surface', 'mesh3d', 'cone', 'streamtube'],
  geo: ['scattergeo', 'choropleth'],
  mapbox: ['scattermapbox', 'choroplethmapbox', 'densitymapbox'],
  polar: ['scatterpolar', 'scatterpolargl', 'barpolar'],
};

export const TRANSFORMABLE_TRACES = [
  'scatter',
  'scattergl',
  'box',
  'violin',
  'bar',
  'ohlc',
  'candlestick',
  'histogram',
  'histogram2d',
  'waterfall',
];

const hasTransforms = (fullData: any[]) => {
  return some(fullData, (d) => includes(TRANSFORMABLE_TRACES, d.type));
};

const hasAxes = (fullLayout: any) => {
  return (
    size(
      filter(
        keys(fullLayout._subplots),
        (type) => !['cartesian', 'mapbox'].includes(type) && fullLayout._subplots[type].length > 0
      )
    ) > 0
  );
};

const hasMenus = (fullLayout: any) => {
  return !isEmpty(fullLayout?.updatemenus);
};

const hasSliders = (layout: any) => {
  return !isEmpty(layout?.sliders);
};

const traceHasColorbar = (trace: any, fullTrace: any) =>
  (fullTrace.marker && fullTrace.marker.showscale !== undefined) || // eslint-disable-line no-undefined
  fullTrace.showscale !== undefined; // eslint-disable-line no-undefined

const hasColorbars = (fullData: any[]) => {
  return some(fullData, (d) => traceHasColorbar({}, d));
};

const hasLegend = (fullData: any[]) => {
  return some(fullData, (t) => t.showlegend !== undefined); // eslint-disable-line no-undefined
};

const hasMaps = (fullData: any[]) => {
  return some(fullData, (d) => includes([...TRACE_TO_AXIS.geo, ...TRACE_TO_AXIS.mapbox], d.type));
};
type DataSources = {
  [key: string]: Array<string | number>;
};

/**
 * Extracts data from a parsed Plotly plot JSON object into a data source format for `react-chart-editor`.
 * @param plotData - Parsed Plotly JSON object.
 * @returns A `DataSources` object containing the extracted data.
 */
function extractDataSourcesFromParsed(plotData: { data?: any[]; layout?: any }): DataSources {
  const dataSources: DataSources = {};

  if (!isArray(plotData.data)) {
    console.error('Invalid Plotly JSON: Missing data array');
    return dataSources;
  }

  const hasMultipleData = size(plotData.data) > 1;
  forEach(plotData.data, (trace: any, index: number) => {
    const traceType = get(trace, 'type', '');
    const traceName = hasMultipleData ? get(trace, 'name', traceType || `trace${index}`) : undefined;
    const traceNamePrefix = traceName ? `${traceName}_` : '';

    if (isArray(trace.x)) {
      const xTitle = get(plotData, 'layout.coloraxis.colorbar.title.text', 'x');
      dataSources[`${traceNamePrefix}${xTitle}`] = trace.x;
    } else if (trace?.xaxis) {
      const xTickVals = get(plotData, 'layout.xaxis.tickvals', []);
      const xTitle = get(plotData, 'layout.xaxis.title.text', trace?.xaxis);

      dataSources[`${traceNamePrefix}${xTitle}`] = xTickVals;
    }

    if (isArray(trace.y)) {
      const yTitle = get(plotData, 'layout.coloraxis.colorbar.title.text', 'y');
      dataSources[`${traceNamePrefix}${yTitle}`] = trace.y;
    } else if (trace?.yaxis) {
      const yTickVals = get(plotData, 'layout.yaxis.tickvals', []);
      const yTitle = get(plotData, 'layout.yaxis.title.text', trace?.yaxis);

      dataSources[`${traceNamePrefix}${yTitle}`] = yTickVals;
    }

    if (isArray(trace.z)) {
      const zTitle = get(plotData, 'layout.coloraxis.colorbar.title.text', 'z');
      dataSources[`${traceNamePrefix}${zTitle}`] = trace.z;
    }
  });

  return dataSources;
}

const config = { editable: true };

export const PlotlyWrapper: React.FC<
  {
    allowDataManipulation?: boolean;
  } & Partial<Figure>
> = ({ allowDataManipulation, ...initialPlotFigure }) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [showFullScreen, setShowFullScreen] = React.useState(false);
  const [plotFigure, setPlotFigure] = React.useState(() => cloneDeep(initialPlotFigure));
  const dataSource = React.useMemo(() => extractDataSourcesFromParsed(initialPlotFigure), [initialPlotFigure]);
  const dataSourceOptions = React.useMemo(
    () => map(keys(dataSource), (key) => ({ value: key, label: key })),
    [dataSource]
  );
  const { data, frames, layout } = plotFigure;
  const body = (
    <Grid item container direction="column" xs={12} columns={12} sx={{ '& .fold__top': { height: 'min-content' } }}>
      <Grid item container xs={12}>
        <Grid item>
          <Button onClick={() => setPlotFigure(cloneDeep(initialPlotFigure))}>Reset</Button>
        </Grid>
        <Grid item>
          {isEditing ? (
            <Button onClick={() => setIsEditing(false)}>Done</Button>
          ) : (
            <Button onClick={() => setIsEditing(true)}>Edit</Button>
          )}
        </Grid>
        <Grid item ml="auto">
          <IconButton
            onClick={() => setShowFullScreen((current) => !current)}
            color="inherit"
            title="Full Screen"
            sx={{ alignSelf: 'flex-end' }}
          >
            <FullscreenIcon />
          </IconButton>
        </Grid>
      </Grid>
      {isEditing ? (
        <Grid item xs={12} justifyContent="center" alignItems="center">
          <PlotlyEditor
            useResizeHandler
            data={data}
            frames={frames}
            config={config}
            layout={layout}
            onUpdate={(newData: PlotParams['data'], newLayout: PlotParams['layout'], newFrames: PlotParams['frames']) =>
              setPlotFigure({ data: newData, layout: newLayout, frames: newFrames })
            }
            dataSources={dataSource}
            dataSourceOptions={dataSourceOptions}
            plotly={plotly}
            style={
              showFullScreen ? { width: '100%', height: 'calc(100vh - 100px)' } : { width: '100%', height: '100%' }
            }
          >
            <PanelMenuWrapper>
              {allowDataManipulation && (
                <>
                  <GraphCreatePanel group={'Structure'} name={'Traces'} />
                  <GraphSubplotsPanel group={'Structure'} name={'Subplots'} />
                  {hasTransforms(initialPlotFigure.data) && (
                    <GraphTransformsPanel group={'Structure'} name={'Transforms'} />
                  )}
                </>
              )}
              <StyleLayoutPanel group={'Style'} name={'General'} collapsedOnStart />
              <StyleTracesPanel group={'Style'} name={'Traces'} />
              {hasAxes(initialPlotFigure.layout) && <StyleAxesPanel group={'Style'} name={'Axes'} collapsedOnStart />}
              {hasMaps(initialPlotFigure.data) && <StyleMapsPanel group={'Style'} name={'Maps'} />}
              {hasLegend(initialPlotFigure.data) && <StyleLegendPanel group={'Style'} name={'Legend'} />}
              {hasColorbars(initialPlotFigure.data) && <StyleColorbarsPanel group={'Style'} name={'Color Bars'} />}
              <StyleNotesPanel group={'Annotate'} name={'Text'} />
              <StyleShapesPanel group={'Annotate'} name={'Shapes'} />
              <StyleImagesPanel group={'Annotate'} name={'Images'} />
              {hasSliders(layout) && <StyleSlidersPanel group={'Control'} name={'Sliders'} />}
              {hasMenus(initialPlotFigure.layout) && <StyleUpdateMenusPanel group={'Control'} name={'Menus'} />}
            </PanelMenuWrapper>
          </PlotlyEditor>
        </Grid>
      ) : (
        <Grid item xs={12} justifyContent="center" alignItems="center">
          <Plot
            useResizeHandler
            data={data}
            frames={frames}
            layout={layout}
            config={config}
            onUpdate={setPlotFigure}
            style={
              showFullScreen ? { width: '100%', height: 'calc(100vh - 100px)' } : { width: '100%', height: '100%' }
            }
          />
        </Grid>
      )}
    </Grid>
  );

  return showFullScreen ? (
    <Dialog open={true} onClose={() => setShowFullScreen(false)} fullScreen>
      <DialogContent>{body}</DialogContent>
    </Dialog>
  ) : (
    body
  );
};
