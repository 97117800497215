import { useSignals } from '@preact/signals-react/runtime';
import { compact, Dictionary, filter, first, isEmpty, map, pick } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';

import Loader from 'components/Loader';
import { Accession } from 'interfaces/accession';
import { Permission } from 'interfaces/permissionOption';
import { Procedure } from 'interfaces/procedure';
import setPreferences from 'redux/actions/preferences';
import { useAppSelector } from 'redux/hooks';
import { useActiveAnnotationAssignmentForViewer } from 'services/annotations/useAnnotationQueryParams';
import { Point } from 'utils/slideTransformation';
import { usePermissions } from 'utils/usePermissions';
import Header from './Header';
import NotFoundMessage from './NotFoundMessage';
import { viewerAnnotationData } from './SlidesViewer/DeckGLViewer/layers/EditAnnotationLayers/useActiveAnnotationDraft';
import { useAnnotationsStoreWithUndo } from './SlidesViewer/DeckGLViewer/layers/EditAnnotationLayers/useAnnotationsWithUndo';
import SlidesViewer from './SlidesViewer/SlidesViewer';
import { SlideWithChannelAndResults } from './useSlideChannelsAndResults/utils';
import { HeatmapsImagePyramids, ImagePyramid } from './useSlideImages';
import { useSlideMagnification } from './useSlideMagnification';

interface ViewerPageBodyProps {
  procedure: Procedure | Accession;
  isLoadingCaseData: boolean;
  isAccessionViewer: boolean;
  isPlaceholderData?: boolean;
  selectedSlides: SlideWithChannelAndResults[];

  slidesBaseImagePyramids: Dictionary<ImagePyramid>;
  slidesHeatmapsImagePyramids: Dictionary<HeatmapsImagePyramids>;
  baseSlideLoadingStates: boolean[];
  slideImagesError: string;
  canLoadSlides: boolean;
}

export const CaseViewBody: React.FunctionComponent<React.PropsWithChildren<ViewerPageBodyProps>> = ({
  procedure,
  isLoadingCaseData,
  isPlaceholderData,
  isAccessionViewer,
  selectedSlides,

  slidesBaseImagePyramids,
  slidesHeatmapsImagePyramids,
  baseSlideLoadingStates,
  slideImagesError,
  canLoadSlides,
}) => {
  useSignals();
  const dispatch = useDispatch();
  const [fieldOfView, setFieldOfView] = useState(null);

  const { magnificationValue, handleMagnificationChangedInHeader, updateMagnificationFromZoom } = useSlideMagnification(
    selectedSlides || []
  );

  const { showNavigator } = useAppSelector((state) => state.preferences);
  const [showNavigation, setShowNavigation] = useState(
    showNavigator && !procedure.presentationInfo?.navigatorOffByDefault
  );

  const { hasPermission } = usePermissions();
  const publishResults = hasPermission(Permission.PublishResults);

  const { updateFeatureCollection } = useAnnotationsStoreWithUndo();
  const { undo, redo, clear } = useAnnotationsStoreWithUndo.temporal.getState();
  const [activeAnnotationAssignmentId] = useActiveAnnotationAssignmentForViewer(0);

  useEffect(() => {
    // Clear the current state of the annotations when the slide changes, or when the assignment changes
    updateFeatureCollection(null, null);
    // Clear the undo/redo stack when the slide changes
    clear();
  }, [first(selectedSlides)?.id, activeAnnotationAssignmentId]);

  useEffect(() => {
    // Every time the step of undo/redo changes, update the signal (that holds the current state of the annotations)
    // we need both because there are intermediate states that are not part of the undo/redo stack
    useAnnotationsStoreWithUndo.subscribe(
      (state) => state.featureCollection,
      // only the left viewer can be annotated
      (featureCollection) => (viewerAnnotationData[0].value = featureCollection)
    );

    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        (event.metaKey && event.key.toLowerCase() === 'z' && !event.shiftKey) ||
        (event.ctrlKey && event.key.toLowerCase() === 'z' && !event.shiftKey)
      ) {
        undo();
      }
      if (
        (event.metaKey && event.shiftKey && event.key.toLowerCase() === 'z') ||
        (event.ctrlKey && event.shiftKey && event.key.toLowerCase() === 'z')
      ) {
        redo();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const onUnmountViewportBounds = React.useCallback(
    (center: Point, zoom: number) => !isAccessionViewer && setFieldOfView({ center, zoom }),
    [isAccessionViewer, setFieldOfView]
  );

  const [slideIdForReviewing] = useQueryParam('slideIdForReviewing', StringParam);
  const [slideTagsDrawerOpen] = useQueryParam('slideTagsDrawerOpen', BooleanParam);
  const [fullscreenViewer] = useQueryParam('fullscreenViewer', BooleanParam);

  /* Slide registration cannot occur with placeholder data, so we display a loader */
  const isLoadingRegistration = isLoadingCaseData && isPlaceholderData && selectedSlides?.length > 1;

  const slidesDataForDisplay = compact(
    filter(
      map(selectedSlides, (slide) => pick(slide, ['id', 'format', 'labId'])),
      (slideData) => !isEmpty(slideData)
    )
  );
  const hasSlidesDataForDisplay = !isEmpty(slidesDataForDisplay);

  const onShowNavigatorChange = useCallback(
    (newShowNavigator: boolean) => dispatch(setPreferences(newShowNavigator)),
    [dispatch]
  );

  const showErrorMessage =
    !isLoadingCaseData &&
    !isLoadingRegistration &&
    (slideImagesError || (!isPlaceholderData && Boolean(selectedSlides) && !canLoadSlides) || !procedure);

  return (
    <>
      {!fullscreenViewer && (
        <Header
          procedure={procedure}
          selectedSlides={selectedSlides}
          showNavigation={showNavigation}
          onChangeNavigationVisibility={setShowNavigation}
          isAccession={isAccessionViewer}
          isLoadingCaseData={isLoadingCaseData}
          isPlaceholderData={isPlaceholderData}
          magnificationValue={magnificationValue}
          onChangeMagnification={handleMagnificationChangedInHeader}
          onShowNavigatorChange={onShowNavigatorChange}
        />
      )}
      {showErrorMessage ? (
        <NotFoundMessage hideHeader />
      ) : /* Slide registration cannot occur with placeholder data, so we display a loader */
      // selectedSlides starts as null, which we consider as loading. An empty array may be an error state.
      !selectedSlides || (isLoadingCaseData && !hasSlidesDataForDisplay) || isLoadingRegistration ? (
        <Loader />
      ) : (
        <SlidesViewer
          procedureId={procedure.id}
          slides={selectedSlides}
          slidesBaseImagePyramids={slidesBaseImagePyramids}
          slidesHeatmapsImagePyramids={slidesHeatmapsImagePyramids}
          baseSlideLoadingStates={baseSlideLoadingStates}
          showNavigation={showNavigation}
          magnificationValue={magnificationValue}
          displaySlideId={publishResults}
          hideComments={isAccessionViewer}
          updateMagnificationFromZoom={updateMagnificationFromZoom}
          unmountViewportBounds={onUnmountViewportBounds}
          fieldOfView={fieldOfView}
          focusedSlideId={slideTagsDrawerOpen ? slideIdForReviewing : undefined}
        />
      )}
    </>
  );
};
