import { Checkbox, Grid, Tooltip, Typography } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { compact, Dictionary, every, forEach, isEmpty, map, some } from 'lodash';
import React from 'react';

import VerticalSliderPopup from 'components/atoms/VerticalSliderPopup';
import {
  defaultHeatmapOpacity,
  LayerVisualizationChange,
  LayerVisualizationSettings,
  slidesLayerVisualizationSettings,
  useApplyChangesToSlideLayerVisualizationSettings,
} from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';

export const LayerGroupControl: React.FC<
  React.PropsWithChildren<{
    slideId: string;
    viewerIndex: number;
    parentKey?: string;
    layerIdsToDisplayNames?: Dictionary<string>;
    layerIdsToUrlKeys: Dictionary<string>;
    layers: string[];
    stainTypeId: string;
    displayName?: string;
    defaultLayerSettings?: Partial<LayerVisualizationSettings>;
    disableToggle?: boolean;
    subtitle?: React.ReactNode;
  }>
> = ({
  slideId,
  viewerIndex,
  parentKey,
  displayName,
  layerIdsToDisplayNames,
  layerIdsToUrlKeys,
  stainTypeId,
  layers,
  disableToggle,
  defaultLayerSettings = { opacity: defaultHeatmapOpacity },
  subtitle,
}) => {
  useSignals();
  const applyChangesToSlideLayerVisualizationSettings = useApplyChangesToSlideLayerVisualizationSettings();

  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];

  const slideLayerSettings = viewerSlideLayerVisualizationSettings?.value?.[slideId];
  const currentLayerSettings = compact(
    map(layers, (layer) => slideLayerSettings?.[parentKey ? `${parentKey}-${layer}` : layer]?.value)
  );
  const isEveryLayerSelected =
    disableToggle || (!isEmpty(currentLayerSettings) && every(currentLayerSettings, 'selected'));
  const isSomeLayerSelected = !isEveryLayerSelected && some(currentLayerSettings, 'selected');
  const isEveryLayerShown = every(currentLayerSettings, ({ show, opacity }) => show && opacity > 0);
  const isSomeLayerShown = some(currentLayerSettings, ({ show, opacity }) => show && opacity > 0);

  const maxSlideLayerOpacity = Math.max(...map(currentLayerSettings, 'opacity'));

  const applyChangeForLayers = (
    getNewSettings: (prev?: LayerVisualizationSettings) => Partial<LayerVisualizationSettings>
  ) => {
    if (!viewerSlideLayerVisualizationSettings) {
      console.warn(`Invalid viewerIndex: ${viewerIndex}`);
      return;
    }
    const previousSettings = viewerSlideLayerVisualizationSettings.value;
    const previousSlideSettings = previousSettings?.[slideId];
    const changes: LayerVisualizationChange[] = [];
    forEach(layers, (layerName) => {
      const layerKey = parentKey ? `${parentKey}-${layerName}` : layerName;
      const layerSettings = previousSlideSettings?.[layerKey];
      const layerPreviousSettings = layerSettings?.value;
      changes.push({
        layerId: layerKey,
        newSettings: getNewSettings(layerPreviousSettings),
        layerUrlKey: layerIdsToUrlKeys?.[layerKey] || layerKey,
      });
    });
    applyChangesToSlideLayerVisualizationSettings({
      slideId,
      viewerIndex,
      changes,
      stainTypeId,
      changeFlow: displayName || 'LayerGroupControl',
    });
  };

  const onToggleChange = () => {
    const newShowValue = !isEveryLayerShown;
    applyChangeForLayers((prev) => ({
      show: newShowValue,
      opacity:
        newShowValue && !prev?.opacity && !isNaN(defaultLayerSettings?.opacity)
          ? defaultLayerSettings.opacity
          : prev?.opacity || 0,
    }));
  };

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    applyChangeForLayers((prev) => ({
      ...prev,
      selected: event.target.checked,
      ...(event.target.checked ? { show: true, ...defaultLayerSettings } : {}),
    }));
  };

  const onSliderValueChange = (value: number) => {
    applyChangeForLayers((prev) => ({ opacity: value, show: prev?.show ?? value > 0 }));
  };

  return (
    <Grid container wrap="nowrap" alignItems="center">
      <Grid item md={2}>
        <Checkbox
          disabled={disableToggle || isEmpty(layers)}
          checked={isEveryLayerSelected}
          indeterminate={isSomeLayerSelected}
          onChange={onCheckboxChange}
          onClick={(e) => e.stopPropagation()}
        />
      </Grid>
      <Grid
        item
        container
        md={7}
        direction="column"
        sx={{
          '&>.MuiGrid-item': {
            maxWidth: '100%',
          },
        }}
      >
        <Grid item>
          <Tooltip
            title={
              parentKey && (displayName || layerIdsToDisplayNames?.[parentKey])
                ? `${displayName || layerIdsToDisplayNames?.[parentKey]} (${parentKey})`
                : parentKey
            }
            placement="top"
          >
            <Typography variant="caption" component="div">
              {displayName || layerIdsToDisplayNames?.[parentKey] || parentKey}
            </Typography>
          </Tooltip>
        </Grid>
        {subtitle}
      </Grid>
      <Grid item md={3} pl={1}>
        <VerticalSliderPopup
          value={maxSlideLayerOpacity}
          disabled={!isEveryLayerSelected && !isSomeLayerSelected}
          show={isSomeLayerShown}
          onToggleChange={onToggleChange}
          onValueChange={onSliderValueChange}
          tooltipProps={{ placement: 'left' }}
        />
      </Grid>
    </Grid>
  );
};
