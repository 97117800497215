import { Permission } from 'interfaces/permissionOption';
import { BooleanParam, useQueryParam, withDefault } from 'use-query-params';
import { usePermissions } from './usePermissions';

export const usePublishMode = (viewerIndex: number) => {
  const { hasPermission } = usePermissions();
  const canPublishResults = hasPermission(Permission.PublishResults);
  const [isPublishModeUrlState, setIsPublishModeUrlState] = useQueryParam(
    `isPublishModeViewer${viewerIndex}`,
    withDefault(BooleanParam, false)
  );
  const isPublishMode = isPublishModeUrlState && canPublishResults;

  return [isPublishMode, setIsPublishModeUrlState] as const;
};
