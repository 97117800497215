import AbcIcon from '@mui/icons-material/Abc';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import React from 'react';

import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import { MultiplexChannelIdName } from './MultiplexChannelIdName';
import { MultiplexOpacityToggle } from './MultiplexOpacityToggle';

export const MainSettings: React.FC<
  React.PropsWithChildren<{
    markerType: string;
    channelId: string;
    channelName: string;
    selected: boolean;
    viewerIndex: number;
    slideId: string;
  }>
> = ({ markerType, channelId, channelName, selected, viewerIndex, slideId }) => {
  const { stainTypeIdToDisplayName, isLoadingStainTypeOptions } = useStainTypeIdToDisplayName();

  const isUnassigned = !isLoadingStainTypeOptions && !markerType;

  return (
    <>
      <Grid item container direction="column" md={7} alignItems="start" sx={{ lineHeight: 1 }}>
        <Grid item>
          <Tooltip
            title={
              isUnassigned ? (
                <Box justifyContent="center" display="flex">
                  <Typography variant="caption" pr={1}>
                    Press the
                  </Typography>
                  <AbcIcon fontSize="small" />
                  <Typography variant="caption" pl={1}>
                    button to assign markers to channels
                  </Typography>
                </Box>
              ) : (
                false
              )
            }
            placement="top"
          >
            <Box justifyContent="center" display="flex">
              <Typography variant="caption" color={isUnassigned ? 'error' : undefined} pr={1}>
                {isUnassigned
                  ? 'Unassigned'
                  : isLoadingStainTypeOptions
                  ? 'Loading...'
                  : stainTypeIdToDisplayName(markerType)}
              </Typography>
              {isUnassigned ? <InfoIcon fontSize="small" /> : null}
            </Box>
          </Tooltip>
        </Grid>
        <Grid item container justifyContent="start">
          <MultiplexChannelIdName channelId={channelId} channelName={channelName} />
        </Grid>
      </Grid>
      <Grid item md={3}>
        <MultiplexOpacityToggle
          markerType={markerType}
          selected={selected}
          viewerIndex={viewerIndex}
          slideId={slideId}
          channelId={channelId}
        />
      </Grid>
    </>
  );
};
