import { useRowSelectionContextOptional } from 'components/atoms/RowSelectionContext';
import { RangeValue } from 'interfaces/caseQuery';
import { FilterKey, QueryFilter, QueryObject, UncategorizedFilterKey } from 'interfaces/cohort';
import {
  cloneDeep,
  every,
  filter,
  find,
  forEach,
  includes,
  isEmpty,
  keys,
  map,
  omit,
  omitBy,
  pick,
  pickBy,
} from 'lodash';
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { encodeQueryParams } from 'use-query-params';
import {
  flattenConfigObject,
  getFiltersFromQueryObject,
  getQueryParamsObjectFromFilters,
} from 'utils/advancedFilterAdapter';
import { useCurrentLabId } from 'utils/useCurrentLab';
import filterFieldsConfig from '../ProcedureFilterFields';
import useCohortFilters from './useCohortFilters';
import useSearchFiltersFromQueryParams from './useSearchFiltersFromQueryParams';

export const getUncategorizedFilters = (source: Partial<QueryObject>) => {
  return pick(source, keys(source) as Array<keyof Record<UncategorizedFilterKey, unknown>>);
};

export const isEmptyRange = (queryFilter: QueryFilter) => {
  if (!queryFilter) {
    return false;
  }
  if (queryFilter.filterType === 'range' || queryFilter.filterType === 'date-range') {
    return isEmpty(String(queryFilter.value['start'] ?? '')) && isEmpty(String(queryFilter.value['end'] ?? ''));
  }
  return false;
};

const shouldOmitValue = (value: QueryFilter | string | number) => {
  switch (typeof value) {
    case 'string':
      return isEmpty(value) || value === 'All';
    case 'number':
      return value === 0 || isNaN(value);
    case 'boolean':
      return false;
    default:
      return isEmpty(value);
  }
};

const useControlledFields = () => {
  const { labId } = useCurrentLabId();
  const { queryParams, searchFiltersFromQueryParams, featuresFromQueryParams, paramsConfig } =
    useSearchFiltersFromQueryParams();
  const { queryOptions, snapshotCohortOptions, cohorts, cohortsLoading } = useCohortFilters();

  const [advancedInputs, setAdvancedInputsState] = useState(searchFiltersFromQueryParams);
  const rowSelectionContext = useRowSelectionContextOptional();
  const setAdvancedInputs = useCallback(
    (value: SetStateAction<Record<string, any>>, clearSelectionsOnChange: boolean = true) => {
      if (clearSelectionsOnChange) {
        rowSelectionContext?.clearSelection?.();
      }
      setAdvancedInputsState(value);
    },
    [rowSelectionContext?.clearSelection, setAdvancedInputsState]
  );
  const [featuresFilters, setFeaturesFilters] = useState([]);
  const [ruleBoxes, setRuleBoxes] = useState<{}[]>([{}]);

  const cohort = find(cohorts, { id: queryParams.cohortId });

  useEffect(() => {
    setAdvancedInputs(searchFiltersFromQueryParams, false);

    if (featuresFromQueryParams) {
      setFeaturesFilters(featuresFromQueryParams);
      const newRuleBoxes = map(featuresFromQueryParams, () => ({}));
      if (isEmpty(newRuleBoxes)) newRuleBoxes.push({});
      setRuleBoxes(newRuleBoxes);
    }
  }, [cohort?.queryObject?.features, queryParams.advancedSearchDrawerOpen, queryParams?.features]);
  const onMainFilterChange = useCallback(
    (filterKey: FilterKey, value: any) => {
      if (filterKey === 'queryId') {
        const currentQuery = find(cohorts, { id: value });
        const newQueryObject = {
          ...currentQuery?.queryObject?.filters,
          ...currentQuery?.queryObject?.features,
          ...currentQuery?.queryObject?.clinicalData,
          searchTerm: currentQuery?.queryObject?.searchTerm,
        };

        const searchFiltersFromNewParams = getFiltersFromQueryObject(newQueryObject as Record<string, string>);

        setAdvancedInputs({ ...searchFiltersFromNewParams, queryId: value });
      } else
        setAdvancedInputs((currentBasicInputs: Partial<Record<FilterKey, QueryFilter>>) => ({
          ...currentBasicInputs,
          [filterKey]: value,
        }));
    },
    [cohorts, setAdvancedInputs]
  );

  const onFieldChange = useCallback(
    (
      filterType: QueryFilter['filterType'],
      key: FilterKey,
      value: QueryFilter['value'],
      category: QueryFilter['category']
    ) => {
      setAdvancedInputs((currentAdvancedInputs) => ({
        ...currentAdvancedInputs,
        [key]: {
          value,
          filterType,
          category,
        } as QueryFilter,
      }));
    },
    [setAdvancedInputs]
  );

  const onFeatureFilterChange = useCallback(
    (index: number, value: RangeValue, filterType: 'select' | 'range', selectedFeatureKey: string) => {
      const newFeaturesFilters = cloneDeep(featuresFilters);
      const currentFeature = newFeaturesFilters[index] || {};

      if (filterType === 'select') {
        currentFeature.featureKey = selectedFeatureKey;
      }
      if (filterType === 'range') {
        currentFeature.value = value;
      }
      newFeaturesFilters[index] = currentFeature;
      setFeaturesFilters(newFeaturesFilters);

      const { start, end } = currentFeature?.value || {};
      const noRange = every([start, end], isEmpty);

      if (!isEmpty(currentFeature?.featureKey) && !noRange) {
        onFieldChange('range', currentFeature?.featureKey, currentFeature?.value, 'features');
      }
    },
    [featuresFilters, onFieldChange]
  );

  const getFilterObjects = useCallback(() => {
    const currentStudyId = advancedInputs.studyId || queryParams.filters?.studyId;
    const orchestrationId = advancedInputs.orchestrationId || queryParams.filters?.orchestrationId;
    const searchTerm = advancedInputs.searchTerm || queryParams.searchTerm;

    const clinicalDataSections = flattenConfigObject(
      filter(filterFieldsConfig, (section) => section.title !== 'Calculated Features')
    );

    const allFilters: Record<string, any> = {};

    forEach(featuresFilters, (feature) => {
      allFilters[feature?.featureKey] = {
        category: 'features',
        filterType: 'range',
        value: feature?.value,
      };
    });

    // create filters object from clinical data sections
    forEach(clinicalDataSections, (field) => {
      if (includes(keys(advancedInputs), field.dataKey)) {
        allFilters[field.dataKey] = advancedInputs[field.dataKey];
        if (field.filterType === 'range' && field.conversionFactor) {
          allFilters[field.dataKey].value['start'] =
            allFilters[field.dataKey].value['start'] &&
            allFilters[field.dataKey].value['start'] * field.conversionFactor;
          allFilters[field.dataKey].value['end'] =
            allFilters[field.dataKey].value['end'] && allFilters[field.dataKey].value['end'] * field.conversionFactor;
        }
      }
    });

    const mainFilters = omitBy(getUncategorizedFilters(advancedInputs), (o) => shouldOmitValue(o));
    const featuresFiltersObj = pickBy(
      allFilters,
      (currentFilter) => currentFilter?.category === 'features' && !isEmptyRange(currentFilter?.value?.range)
    );
    const clinicalFiltersObj = pickBy(allFilters, (value) => value?.category === 'clinical' && !isEmptyRange(value));
    const clinicalData = omitBy(getQueryParamsObjectFromFilters(clinicalFiltersObj), (o) => shouldOmitValue(o));
    const features = omitBy(getQueryParamsObjectFromFilters(featuresFiltersObj), (o) => shouldOmitValue(o));

    const clinicalFilterKeys = keys(clinicalFiltersObj);
    const featuresFilterKeys = keys(featuresFiltersObj);

    const filtersWithMetadata = omit(getQueryParamsObjectFromFilters(allFilters), [
      ...clinicalFilterKeys,
      ...featuresFilterKeys,
    ]);

    const filters = omit(
      omitBy(
        {
          ...mainFilters,
          ...filtersWithMetadata,
          studyId: currentStudyId,
          orchestrationId,
        },
        (value) => shouldOmitValue(value) || isEmptyRange(value)
      ),
      [...featuresFilterKeys, ...clinicalFilterKeys, 'searchTerm']
    );

    return {
      filters,
      clinicalData,
      features,
      searchTerm,
    };
  }, [advancedInputs, featuresFilters, queryParams.filters]);

  const createNavQuery = useCallback(
    (
      filters: Record<string, string | number>,
      clinicalData: Record<string, string | number>,
      features: Record<string, string | number>,
      searchTerm: string
    ) => {
      const curQueryParams = {
        filters,
        features,
        clinicalData,
        labId,
        advancedSearchDrawerOpen: null as boolean | null,
        cohortId: null as string | null,
        resultsMode: queryParams.resultsMode,
        page: 1,
        searchTerm,
      };

      const encodedQuery = encodeQueryParams(
        // @ts-ignore
        pickBy(paramsConfig, (param, key) => !isEmpty(curQueryParams[key])),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        pickBy(curQueryParams, (value, key) => !isEmpty(value))
      );

      return encodedQuery;
    },
    [labId, paramsConfig, queryParams]
  );

  return {
    advancedInputs,
    setAdvancedInputs,
    featuresFilters,
    setFeaturesFilters,
    onMainFilterChange,
    onFieldChange,
    onFeatureFilterChange,
    queryOptions,
    snapshotCohortOptions,
    cohorts,
    cohortsLoading,
    getFilterObjects,
    createNavQuery,
    ruleBoxes,
    setRuleBoxes,
  };
};

export default useControlledFields;
