import { Autocomplete, Button, CircularProgress, Grid, TextField, Tooltip } from '@mui/material';
import { ClusterType } from 'interfaces/clusterType';
import { find, isNumber, map, omit, toNumber } from 'lodash';
import React from 'react';
import { useClusterTypeOptions } from 'utils/queryHooks/uiConstantsHooks';

export interface SingleClusterIndexToNeighborhoodNameProps {
  genericCluster: number;
  currentClusterAssignmentMap: Record<number, string>;
  setClusterAssignmentMap: React.Dispatch<React.SetStateAction<Record<number, string>>>;
}

export const SingleClusterIndexToNeighborhoodName: React.FC<SingleClusterIndexToNeighborhoodNameProps> = ({
  genericCluster,
  currentClusterAssignmentMap,
  setClusterAssignmentMap,
}) => {
  const clusterType = currentClusterAssignmentMap[genericCluster];
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [draftGenericCluster, setDraftGenericCluster] = React.useState<number | null>(null);

  const deleteClusterMapping = () =>
    setClusterAssignmentMap((currentMappings) => omit(currentMappings, genericCluster));

  const { clusterTypeOptions, isLoadingClusterTypes } = useClusterTypeOptions();

  const applyGenericClusterChange = () => {
    if (draftGenericCluster === null) {
      return;
    }

    if (!isNumber(draftGenericCluster) || isNaN(draftGenericCluster)) {
      setErrorMessage('Generic Cluster must be a number');
    } else if (draftGenericCluster < 0) {
      setErrorMessage('Generic Cluster must be a positive number');
    } else if (draftGenericCluster !== genericCluster && Boolean(currentClusterAssignmentMap[draftGenericCluster])) {
      setErrorMessage('Generic Cluster is already assigned');
    } else {
      setErrorMessage(null);
      setClusterAssignmentMap((currentMappings) => ({
        ...omit(currentMappings, genericCluster),
        [draftGenericCluster]: clusterType,
      }));
      setDraftGenericCluster(null);
    }
  };

  return (
    <Grid item container direction="row" spacing={1} xs={12} alignItems="center">
      <Grid item xs={2}>
        <Tooltip title={errorMessage ?? ''} open={Boolean(errorMessage)} placement="top">
          <TextField
            size="small"
            value={draftGenericCluster ?? toNumber(genericCluster)}
            type="number"
            error={Boolean(errorMessage)}
            onChange={(event) => setDraftGenericCluster(toNumber(event.target.value))}
            onBlur={applyGenericClusterChange}
            inputProps={{ min: 0, step: 1 }}
            label="Generic Cluster"
          />
        </Tooltip>
      </Grid>
      <Grid item xs={6}>
        <Autocomplete<string, false, false, false>
          size="small"
          value={clusterType || null}
          options={map(clusterTypeOptions, 'id')}
          disabled={isLoadingClusterTypes || Boolean(errorMessage)}
          onChange={(e, val) =>
            val &&
            setClusterAssignmentMap((currentMappings) => ({
              ...omit(currentMappings, genericCluster),
              [genericCluster]: val,
            }))
          }
          getOptionLabel={(valueStr) => {
            const newClusterType = find(clusterTypeOptions, { id: valueStr }) as ClusterType;
            return newClusterType?.displayName || newClusterType?.id || '';
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!clusterType}
              label="Cluster Type"
              InputProps={{
                ...params.InputProps,
                error: !clusterType,
                endAdornment: (
                  <React.Fragment>
                    {isLoadingClusterTypes ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item container direction="row" spacing={1} xs="auto" justifyContent="flex-end">
        <Grid item>
          <Button variant="contained" color="secondary" onClick={deleteClusterMapping}>
            Delete
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
