import { useQuery } from '@tanstack/react-query';
import { getStudyAvailableAlgorithms } from 'api/study';
import { useCurrentLabId } from './useCurrentLab';

export const useStudyAvailableAlgorithms = (studyId: string, { enabled = true }: { enabled?: boolean } = {}) => {
  const { labId } = useCurrentLabId();

  return useQuery(['studyAvailableAlgorithms', studyId, labId], () => getStudyAvailableAlgorithms(studyId, labId), {
    enabled: Boolean(studyId) && enabled,
  });
};
