import {
  compact,
  concat,
  filter,
  find,
  findIndex,
  flatMap,
  flatten,
  groupBy,
  includes,
  isEmpty,
  isNumber,
  isString,
  keys,
  map,
  partition,
  replace,
  some,
  startsWith,
  toNumber,
  values,
} from 'lodash';

import {
  ExperimentResult,
  labelColumn,
  PresentationInfoLayer,
  publishableFlowClasses,
  publishableResultTypes,
} from 'interfaces/experimentResults';
import { isBrackets } from 'utils/formatBrackets';
import { FormatBracketsOptions } from 'utils/formatBrackets/formatBracketsOptions';
import { formatBracketsVisualization } from 'utils/formatBrackets/formatBracketsVisualization';
import { humanize } from 'utils/helpers';
import {
  experimentResultToGroup,
  FeatureMetadata,
  FeatureMetadataBeforeSecondaryAnalysisGrouping,
  HeatmapType,
  parseBaseFeatureMetadataFromExperimentResult,
} from './featureMetadata';
import { groupPrimaryResultsWithSecondaryResults, isParsedSecondaryAnalysisRun } from './secondaryAnalysis';

const extensionRegex = /\.\S+$/g;
const underscoreAndPathSepRegex = /[_/]/g;
const underscoreRegex = /_/g;

const sourceStainIndexRegex = /<source_s:s:(\d+)>/;

export const markerPositivityColumnPrefix = 'marker.pos.';
export const markerProbabilityColumnPrefix = 'marker.prob.';

/**
 * Until we standardize the presentation info schema, we need to handle different types of heatmaps
 */
export const getPresentationInfoForOptionKey = (experimentResult: ExperimentResult, optionKey: string) => {
  return (experimentResult.presentationInfo?.[optionKey] ||
    experimentResult.presentationInfo?.layers?.[optionKey] ||
    find(experimentResult.presentationInfo?.layers, { key: optionKey }) ||
    find(experimentResult.presentationInfo?.layers, { class_name: optionKey })) as
    | PresentationInfoLayer
    | string
    | undefined;
};

export const getHeatmapDisplayName = (context: FormatBracketsOptions, key: string, mainHeatmapKey?: string) => {
  if (isBrackets(key)) {
    return formatBracketsVisualization(key, context, mainHeatmapKey);
  }

  // legacy name
  const itemConfig = context.uiSettings.webappSidebarConfig.heatmapsConfig[key];
  return itemConfig?.displayName || humanize(replace(key, underscoreRegex, ' '));
};

const legacyPmtCompositeKey = 'composite_layer_info';

const parseSinglePmtHeatmap = ({
  experimentResult,
  presentationInfo,
  key,
  formatBracketsOptions,
}: {
  experimentResult: ExperimentResult;
  presentationInfo?: PresentationInfoLayer;
  key: string;
  formatBracketsOptions: FormatBracketsOptions;
}): FeatureMetadataBeforeSecondaryAnalysisGrouping | null => {
  if (presentationInfo?.file_type && presentationInfo.file_type !== 'pmt') {
    console.warn('Unsupported file type for pmt heatmap', { presentationInfo, experimentResult });
  }
  const pmtHeatmapUrl = presentationInfo?.storage_url;
  if (!pmtHeatmapUrl) {
    console.warn('PMT heatmap url not found', { presentationInfo, experimentResult });
  }

  const layerOptions = find(experimentResult.options, { key })?.layers;
  const optionsForHeatmap = layerOptions ? layerOptions : legacyPmtCompositeKey === key ? experimentResult.options : [];

  const registeredFromStainTypeIndex = sourceStainIndexRegex.test(key)
    ? toNumber(sourceStainIndexRegex.exec(key)[1])
    : undefined;

  const nestedItems: FeatureMetadataBeforeSecondaryAnalysisGrouping[] = compact(
    map(optionsForHeatmap, (option) => {
      // TODO: we should align the options schema to have the nested layers in an option.layers array, making the source of truth the options array
      // This will then become the 'legacy' parser for pmt (if we decide to keep it)
      const matchingLayer = getPresentationInfoForOptionKey(experimentResult, option.key) as PresentationInfoLayer;

      // For each option, find the matching layer and check if
      if (matchingLayer && matchingLayer.file_type !== 'pmt') {
        if (key !== legacyPmtCompositeKey) {
          // If the layer is not a pmt layer, log a warning, since we don't support other types of layers yet
          console.warn('Unsupported file type for PMT heatmap', { matchingLayer, experimentResult, option });
        }
        return null;
      }

      // If no layer is found, parse the option as a 'pmt-layer' heatmap, which contains the option's color and key
      return {
        ...parseBaseFeatureMetadataFromExperimentResult(experimentResult, 'parsePmtHeatmaps-nested-'),
        id: `parsePmtHeatmaps-nested-[${experimentResult.experimentResultId}]-${option.key}`,
        displayName: getHeatmapDisplayName(formatBracketsOptions, option?.key, heatmapKeyWithoutRegisteredStain),
        heatmapType: HeatmapType.PmtLayer,
        options: optionsForHeatmap,
        key: option.key,
        registeredFromStainTypeIndex,
      };
    })
  );

  const heatmapKeyWithoutRegisteredStain = sourceStainIndexRegex.test(key)
    ? replace(key, sourceStainIndexRegex, '')
    : key;

  const displayName =
    heatmapKeyWithoutRegisteredStain !== legacyPmtCompositeKey
      ? getHeatmapDisplayName(formatBracketsOptions, heatmapKeyWithoutRegisteredStain)
      : experimentResult?.name ||
        // If the experiment result name is not available, use the artifact url as the name by replacing the file extension and underscores with spaces
        `[${experimentResult.experimentResultId}] - ${replace(
          replace(presentationInfo?.artifact_url, extensionRegex, ''),
          underscoreAndPathSepRegex,
          ' '
        )}`;

  return {
    ...parseBaseFeatureMetadataFromExperimentResult(experimentResult, `parsePmtHeatmap-${key}`),
    displayName,
    heatmapUrl: pmtHeatmapUrl,
    heatmapType: HeatmapType.Pmt,
    nestedItems,
    options: optionsForHeatmap,
    registeredFromStainTypeIndex,
  } as FeatureMetadataBeforeSecondaryAnalysisGrouping;
};

export const parseGeoJsonHeatmap = (
  experimentResult: ExperimentResult,
  layerInfo: PresentationInfoLayer,
  formatBracketsOptions: FormatBracketsOptions
): FeatureMetadataBeforeSecondaryAnalysisGrouping | null => {
  const geoJsonUrl = layerInfo?.storage_url;
  if (!geoJsonUrl) {
    console.warn('Dynamic heatmap url not found', { layerInfo, experimentResult });
    return null;
  }
  if (layerInfo?.file_type && layerInfo.file_type !== 'geojson') {
    console.warn('Unsupported file type for geojson heatmap', { layerInfo, experimentResult });
    return null;
  }

  const optionsForHeatmap = find(experimentResult.options, { key: layerInfo.key })?.layers || experimentResult.options;
  const matchingOption = find(optionsForHeatmap, { key: layerInfo.class_name });

  // We support his direct presentation info parsing since this is a legacy feature anyway
  const layerIndex = findIndex(experimentResult?.presentationInfo?.layers, { class_name: layerInfo.class_name });

  if (!matchingOption && layerIndex === -1) {
    console.warn('No matching option found for geojson layer', { layerInfo, experimentResult });
    return null;
  }

  const displayName =
    getHeatmapDisplayName(formatBracketsOptions, matchingOption?.key || layerInfo?.class_name) ||
    // If the experiment result name is not available, use the artifact url as the name by replacing the file extension and underscores with spaces
    `${replace(replace(layerInfo?.artifact_url, extensionRegex, ''), underscoreAndPathSepRegex, ' ')}`;
  return {
    ...parseBaseFeatureMetadataFromExperimentResult(experimentResult, 'parseGeoJsonHeatmap-'),
    id: `parseGeoJsonHeatmap-[${experimentResult.experimentResultId}]=geojson-${layerInfo.class_name}`,
    key: matchingOption?.key || layerInfo?.class_name || `[${experimentResult.experimentResultId}]-${layerIndex}`,
    displayName,
    heatmapUrl: geoJsonUrl,
    heatmapType: HeatmapType.GeoJson,
  };
};

export const parsePmtHeatmaps = (
  experimentResult: ExperimentResult,
  formatBracketsOptions: FormatBracketsOptions
): FeatureMetadataBeforeSecondaryAnalysisGrouping[] => {
  // TODO: we should align the options schema to have the nested layers in an option.layers array, making the source of truth the options array
  // This will then become the 'legacy' parser for pmt (if we decide to keep it)
  const pmtLayerKeys = concat(
    map(filter(experimentResult?.presentationInfo?.layers, { file_type: 'pmt' }), 'key'),
    filter(
      keys(experimentResult?.presentationInfo),
      (key) => experimentResult?.presentationInfo[key]?.file_type === 'pmt'
    )
  );

  return map(pmtLayerKeys, (pmtLayerKey) => {
    const presentationInfo = getPresentationInfoForOptionKey(experimentResult, pmtLayerKey) as PresentationInfoLayer;

    return presentationInfo
      ? parseSinglePmtHeatmap({
          experimentResult,
          presentationInfo,
          key: pmtLayerKey,
          formatBracketsOptions,
        })
      : null;
  });
};

const parseLegacyRasterHeatmaps = (
  experimentResult: ExperimentResult
): FeatureMetadataBeforeSecondaryAnalysisGrouping[] => {
  if (!experimentResult) {
    return [];
  }
  const legacyHeatmapPresentationInfo = getPresentationInfoForOptionKey(experimentResult, 'heatmap_url');

  if (isEmpty(legacyHeatmapPresentationInfo)) {
    return [];
  }

  const parentDisplayName = experimentResult.name || humanize(experimentResult.flowClassName);
  const nestedItems: FeatureMetadataBeforeSecondaryAnalysisGrouping['nestedItems'] = map(
    experimentResult.options,
    (heatmapOption) => {
      return {
        id: `[${experimentResult.experimentResultId}]-${heatmapOption.key}`,
        key: heatmapOption.key,
        color: heatmapOption.color,
        show: false,
        selected: false,
        displayName: heatmapOption?.key,
      };
    }
  );

  const newHMResult = {
    ...parseBaseFeatureMetadataFromExperimentResult(experimentResult, 'parseLegacyRasterHeatmaps-'),
    displayName: parentDisplayName,
    heatmapUrl:
      (legacyHeatmapPresentationInfo as PresentationInfoLayer)?.storage_url ||
      (legacyHeatmapPresentationInfo as string),
    heatmapType: HeatmapType.Dzi,
    nestedItems,
  } as FeatureMetadataBeforeSecondaryAnalysisGrouping;

  return [newHMResult];
};

/*
 * Get all the heatmap 'options' and parse their nested layers as composite raster heatmaps
 */
const parseCompositeRasterHeatmapUrls = (
  experimentResult: ExperimentResult,
  formatBracketsOptions: FormatBracketsOptions
): FeatureMetadataBeforeSecondaryAnalysisGrouping[] => {
  return flatMap(experimentResult.options, (heatmapOption) => {
    const compositePresentationInfo = heatmapOption
      ? getPresentationInfoForOptionKey(experimentResult, heatmapOption.key)
      : null;

    const parentDisplayName = getHeatmapDisplayName(formatBracketsOptions, heatmapOption.key);

    const nestedItems: FeatureMetadataBeforeSecondaryAnalysisGrouping['nestedItems'] = compact(
      map(heatmapOption.layers, (nestedHeatmap) => {
        const presentationInfo =
          getPresentationInfoForOptionKey(experimentResult, heatmapOption.key.concat(nestedHeatmap.key)) ||
          getPresentationInfoForOptionKey(experimentResult, nestedHeatmap.key);
        const hasHeatmapUrl = presentationInfo && isString(presentationInfo);
        return {
          id: `[${experimentResult.experimentResultId}]-${heatmapOption.key}-${nestedHeatmap.key}`,
          key: nestedHeatmap.key,
          color: nestedHeatmap.color,
          heatmapUrl: hasHeatmapUrl ? presentationInfo : undefined,
          show: false,
          selected: false,
          displayName: getHeatmapDisplayName(formatBracketsOptions, nestedHeatmap.key, heatmapOption.key),
          heatmapType: HeatmapType.Dzi,
          options: heatmapOption.layers,
        };
      })
    );

    const hasCompositeHeatmapUrl = compositePresentationInfo && isString(compositePresentationInfo);

    // Some heatmaps don't have either the composite heatmap url or nested heatmap urls, but if both are missing, we should log a warning and skip the heatmap
    if (!hasCompositeHeatmapUrl && !some(nestedItems, 'heatmapUrl')) {
      return [];
    }

    return {
      ...parseBaseFeatureMetadataFromExperimentResult(experimentResult, 'parseCompositeRasterHeatmapUrls-'),
      id: `[${experimentResult.experimentResultId}]-${heatmapOption.key}`,
      key: heatmapOption.key,
      color: heatmapOption.color,
      heatmapUrl: hasCompositeHeatmapUrl ? compositePresentationInfo : undefined,
      heatmapType: HeatmapType.Dzi,
      nestedItems,
      displayName: parentDisplayName,
      options: heatmapOption.layers,
    };
  });
};

/*
 * Get all the heatmap 'options' and parse their nested layers as composite raster heatmaps
 */
const parseParquetHeatmaps = (
  experimentResult: ExperimentResult,
  formatBracketsOptions: FormatBracketsOptions,
  debug: boolean = false
): FeatureMetadataBeforeSecondaryAnalysisGrouping[] => {
  return flatMap(experimentResult.options, (heatmapOption) => {
    if (heatmapOption?.type === 'point') {
      // Point columns are the X, Y coordinates of cells, not data for a heatmap
      return [];
    }
    const presentationInfo = getPresentationInfoForOptionKey(experimentResult, heatmapOption.key);
    if (!presentationInfo || isString(presentationInfo) || presentationInfo.file_type !== 'parquet') {
      return [];
    }
    const columnName = heatmapOption.column_name;
    if (!columnName) {
      console.warn('No column name found for parquet heatmap', { heatmapOption, experimentResult });
      return [];
    }
    const columnType = heatmapOption?.type;

    // Check for problematic options
    const isMarkerPositivity = startsWith(columnName, markerPositivityColumnPrefix);
    if (isMarkerPositivity && columnType && !includes(['boolean', 'categorical'], columnType)) {
      if (debug) {
        // To avoid spamming the console, only log this in debug mode
        console.warn('Marker positivity column is not boolean or categorical. Skipping to avoid bugs', {
          columnName,
          columnType,
          heatmapOption,
        });
      }
      return [];
    }
    const isMarkerProbability = startsWith(columnName, markerProbabilityColumnPrefix);
    if (isMarkerProbability && columnType !== 'float') {
      if (debug) {
        // To avoid spamming the console, only log this in debug mode
        console.warn('Marker probability column is not float. Skipping to avoid bugs', {
          columnName,
          columnType,
          heatmapOption,
        });
      }
      return [];
    }

    const registeredFromStainTypeIndex = sourceStainIndexRegex.test(heatmapOption.key)
      ? toNumber(sourceStainIndexRegex.exec(heatmapOption.key)[1])
      : undefined;

    const heatmapKeyWithoutRegisteredStain = sourceStainIndexRegex.test(heatmapOption.key)
      ? replace(heatmapOption.key, sourceStainIndexRegex, '')
      : heatmapOption.key;

    const parentDisplayName = getHeatmapDisplayName(formatBracketsOptions, heatmapKeyWithoutRegisteredStain);
    const nestedOptions = heatmapOption?.layers;
    const nestedItems = map(nestedOptions, (nestedHeatmap) => {
      return {
        ...parseBaseFeatureMetadataFromExperimentResult(experimentResult, 'parseParquetHeatmaps-'),
        id: `[${experimentResult.experimentResultId}]-${heatmapOption.key}-${heatmapOption.column_name}-${nestedHeatmap.key}`,
        key: `${heatmapOption.key}-${nestedHeatmap.key}`,
        color: nestedHeatmap.color,
        show: false,
        selected: false,
        displayName: getHeatmapDisplayName(formatBracketsOptions, nestedHeatmap.key, heatmapKeyWithoutRegisteredStain),
        heatmapType: HeatmapType.Parquet,
        options: nestedOptions,
        columnName,
        columnType,
        registeredFromStainTypeIndex,
      };
    });

    const stainTypeIdDisplayName = isNumber(registeredFromStainTypeIndex)
      ? find(formatBracketsOptions?.stainTypeOptions, { index: registeredFromStainTypeIndex })?.displayName
      : null;

    const columnDisplayNameSuffix =
      columnName && columnType === 'categorical' && columnName !== labelColumn ? ` by ${columnName}` : '';

    const newHMResult = {
      ...parseBaseFeatureMetadataFromExperimentResult(experimentResult, 'parseParquetHeatmaps-'),
      id: `[${experimentResult.experimentResultId}]-${heatmapOption.key}-${heatmapOption.column_name}`,
      key: heatmapOption.key,
      color: heatmapOption.color,
      heatmapUrl: presentationInfo.storage_url,
      heatmapType: HeatmapType.Parquet,
      nestedItems,
      displayName: `${
        stainTypeIdDisplayName
          ? // If the heatmap is registered from a stain, we will display it as a density heatmap with the stain name
            `${parentDisplayName} ${
              heatmapOption?.type === 'categorical' ? 'density ' : ''
            }from ${stainTypeIdDisplayName}`
          : parentDisplayName
      }${columnDisplayNameSuffix}`,
      options: nestedOptions,
      columnName: heatmapOption.column_name,
      columnType: heatmapOption?.type,
      registeredFromStainTypeIndex,
    };

    const hasUrls = Boolean(newHMResult?.heatmapUrl) || some(newHMResult.nestedItems, 'heatmapUrl');

    return hasUrls ? newHMResult : [];
  });
};

export const parseHeatmaps = (
  slideResults: ExperimentResult[],
  formatBracketsOptions: FormatBracketsOptions,
  skipVectorHeatmaps = false
): {
  publishedHeatmaps: FeatureMetadata[];
  publishableHeatmaps: { [key: string]: FeatureMetadata[] };
  internalHeatmaps: { [key: string]: FeatureMetadata[] };
  deletedHeatmaps: FeatureMetadata[];
} => {
  const allHeatmapsResults: FeatureMetadataBeforeSecondaryAnalysisGrouping[] = flatMap(
    slideResults,
    (experimentResult) => {
      const legacyRasterHeatmaps = parseLegacyRasterHeatmaps(experimentResult);

      const compositeRasterHeatmaps = parseCompositeRasterHeatmapUrls(experimentResult, formatBracketsOptions);

      if (skipVectorHeatmaps) {
        return [...legacyRasterHeatmaps, ...compositeRasterHeatmaps];
      }

      // In old PMT results (first round of implementation) the heatmap is stored in the composite_layer_info key
      const legacyCompositePmtPresentationInfo = getPresentationInfoForOptionKey(
        experimentResult,
        legacyPmtCompositeKey
      );

      const legacyCompositePmt =
        legacyCompositePmtPresentationInfo && !isString(legacyCompositePmtPresentationInfo)
          ? parseSinglePmtHeatmap({
              experimentResult,
              presentationInfo: legacyCompositePmtPresentationInfo,
              formatBracketsOptions,
              key: legacyPmtCompositeKey,
            })
          : null;

      const parquetHeatmaps = parseParquetHeatmaps(experimentResult, formatBracketsOptions);

      const pmtHeatmaps = parsePmtHeatmaps(experimentResult, formatBracketsOptions);

      const geoJsonHeatmaps = map(
        filter(flatten(values(experimentResult.presentationInfo)), { file_type: 'geojson' }),
        (layerInfo) => parseGeoJsonHeatmap(experimentResult, layerInfo, formatBracketsOptions)
      );

      const allHeatmaps = compact([
        legacyCompositePmt,
        ...legacyRasterHeatmaps,
        ...compositeRasterHeatmaps,
        ...parquetHeatmaps,
        ...pmtHeatmaps,
        ...geoJsonHeatmaps,
      ]);

      if ((!isEmpty(experimentResult.options) || !isEmpty(experimentResult.presentationInfo)) && isEmpty(allHeatmaps)) {
        console.warn('No heatmaps found in experiment result with options or presentation info', experimentResult);
      }

      return allHeatmaps;
    }
  );

  const allHeatmapsResultsWithNested = groupPrimaryResultsWithSecondaryResults(allHeatmapsResults);

  const [deletedHeatmaps, heatmapsResults] = partition(
    allHeatmapsResultsWithNested,
    // Hidden secondary results are handled separately
    (result) => isParsedSecondaryAnalysisRun(result) && Boolean(result.deletedAt)
  );

  const [publishedHeatmaps, allUnpublishedHeatmaps] = partition(heatmapsResults, (heatmap) => {
    const heatmapOrchestrationId = heatmap.orchestrationId;
    const runExperimentResults = filter(
      slideResults,
      (result) =>
        result.orchestrationId === heatmapOrchestrationId || result.primaryRunOrchestrationId === heatmapOrchestrationId
    );
    const hasPublishedResult = some(runExperimentResults, 'approved');
    return hasPublishedResult;
  });

  const visibleUnpublishedHeatmaps = filter(allUnpublishedHeatmaps, (heatmap) => Boolean(heatmap.flowClassName));

  const [publishableHeatmapsList, unpublishableHeatmapsList] = partition(
    visibleUnpublishedHeatmaps,
    (heatmap) =>
      includes(publishableFlowClasses, heatmap.flowClassName) || includes(publishableResultTypes, heatmap.resultType)
  );
  const publishableHeatmaps = groupBy(publishableHeatmapsList, experimentResultToGroup);
  const internalHeatmaps = groupBy(unpublishableHeatmapsList, experimentResultToGroup);

  return { publishedHeatmaps, publishableHeatmaps, internalHeatmaps, deletedHeatmaps };
};
