import { NumberOption, OrchestrationDataType } from './calculateFeatures';

export interface PostProcessingActionInputCondition {
  key: string;
  value: string;
  action: string;
}

export interface Input {
  limitMultipleTo?: number;
  inputKey: string;
  inputLabel: string;
  inputType: string;
  inputSource?: string | string[];
  inputSourceDependentOn?: string;
  required: boolean | PostProcessingActionInputCondition;
  inputRange?: {
    min?: number;
    max?: number;
  };
  freeSolo?: boolean;
  defaultValue?: number | boolean | string;
  displayCondition?: PostProcessingActionInputCondition;
  advanced?: boolean;
}

export enum ActionInput {
  FEATURE_FAMILY = 'feature_family',
  CELL = 'cell',
  AREA = 'area',
}

export default interface PostProcessingAction {
  id: string;
  actionInput: string;
  actionName: string;
  actionDisplayName: string;
  inputs: Input[];
  deletedAt?: string;
  newCellColumns?: string[];
}

export interface PostProcessingActionCreated {
  id: string;
  actionId: string;
  actionInput: string;
  actionName: string;
  newOptionValue?: string;
  newOptionValueInput?: string;
  [string: string]: any; // All the fields that will be added by the user in the form, the key will be the inputKey
}

export interface CategoricalOption {
  id: string | number;
  label: string | number;
}

export enum MappingFilterMetadataFrom {
  ClassConfig = 'ClassConfig',
  Metadata = 'Metadata',
}

export interface MappingFilterMetadata {
  sourceName: string;
  data: {
    label: string;
    options?: CategoricalOption[] | NumberOption;
    type?: OrchestrationDataType;
  }[];
}

export enum InputType {
  LOGICAL_QUERY = 'logical_query',
  NUMBER = 'number',
  NUMBERS = 'numbers',
  BOOLEAN = 'boolean',
  SELECT = 'select',
  MULTISELECT = 'multiselect',
  RADIO = 'radio',
}

export enum NewCellColumns {
  THRESHOLD_RESULT_MARKERS = 'threshold_results_marker',
}

export enum InputSource {
  STAIN_TYPES_OF_SELECTED_SLIDES = 'stain_types_of_selected_slides',
  AREAS = 'areas',
  INTERMEDIATE_AREAS_PER_TARGET_STAIN = 'intermediate_areas_per_target_stain',
  CLASSES_CONFIG_AREAS_PER_TARGET_STAIN = 'classes_config_areas_per_target_stain',
  CELLS = 'cells',
  INTERMEDIATE_CELLS_PER_TARGET_STAIN = 'intermediate_cells_per_target_stain',
  CLASSES_CONFIG_CELLS_PER_TARGET_STAIN = 'classes_config_cells_per_target_stain',
  INTERMEDIATE_CELLS_FEATURES_PER_TARGET_STAIN = 'intermediate_cells_features_per_target_stain',
  ATTRIBUTES_OF_SELECTED_SLIDES = 'attributes_of_selected_slides',
  OPTIONS_OF_SELECTED_ATTRIBUTE = 'options_of_selected_attribute',
  TAXONOMY_TAGS = 'taxonomy_tags',
  GEOMETRY_METADATA = 'geometry_metadata',
  THRESHOLD_MARKERS = 'threshold_markers',
}
