import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import { Chip, Grid, Tooltip, Typography } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { isEmpty, size } from 'lodash';
import React from 'react';

import { IconWithBadge } from 'components/atoms/IconWithBadge';
import { useConfirmation } from 'components/modals/ConfirmationContext';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { useSecondaryAnalysis } from 'services/secondaryAnalysis';
import { getSecondaryAnalysisFeatures } from 'services/secondaryAnalysis/secondaryAnalysisPolygons';
import { JobsStatusHeaderItem } from '../../JobsStatus';

const ConfirmationModalTitle: React.FC<{
  selectionType: 'inclusion' | 'exclusion' | '';
  numberOfSelectedAreas: number;
}> = ({ selectionType, numberOfSelectedAreas }) => (
  <Grid container direction="row" columns={12} spacing={1}>
    <Grid item>
      <IconWithBadge
        badgeContent={selectionType === 'inclusion' ? '+' : '-'}
        sx={{ fontSize: 32, color: selectionType === 'inclusion' ? 'green' : 'red' }}
        icon={HighlightAltIcon}
        badgeSize={19}
      />
    </Grid>
    <Grid item>
      <Typography variant="h6">
        Start secondary analysis with {numberOfSelectedAreas} area
        {numberOfSelectedAreas === 1 ? '' : 's'} {selectionType === 'inclusion' ? 'selected' : 'removed'}?
      </Typography>
    </Grid>
  </Grid>
);

const ConfirmationModalBody: React.FC = () => (
  <Grid item container spacing={2}>
    <Grid item>
      <Typography variant="body1">
        The recalculation will run in the background and may take a few minutes for large WSIs. You can continue working
        while it processes.
      </Typography>
    </Grid>
    <Grid item>
      <Typography variant="body1">Track progress at the top of the page.</Typography>
      {/* (add a preview of the component in the modal for visualization) */}
      <JobsStatusHeaderItem
        dontAnimate
        numPending={0}
        numRunning={1}
        isQueryRunning={false}
        wasWaitingForRecalculatedResults={false}
        allJobsDone={false}
      />
    </Grid>
    <Grid item>
      <Typography variant="body1">
        Once complete, results will appear in the results drawer. If needed, you can revert to the original results or
        run a secondary analysis again.
      </Typography>
    </Grid>
  </Grid>
);

export interface RunSecondaryAnalysisButtonProps {
  caseId: number;
  selectedSlides: SlideWithChannelAndResults[];
  isStartingSecondaryAnalysis: boolean;
}

export const RunSecondaryAnalysisButton: React.FC<RunSecondaryAnalysisButtonProps> = ({
  caseId: caseIdFromProps,
  selectedSlides,
  isStartingSecondaryAnalysis,
}) => {
  useSignals();

  const {
    context: {
      isSecondaryAnalysisActive,
      isLoadingOriginalJob,
      canRunSecondaryAnalysis,
      secondaryAnalysisSourceOrchestrationId,
      caseId,
      slideId,
      studyId,
      autoInternallyApprove,
      wholeSlideAnalysisJob,
      secondaryAnalysisActiveViewer,
    },
    execution: { start: runSecondaryAnalysis },
  } = useSecondaryAnalysis({ caseId: caseIdFromProps, selectedSlides });

  // We call this directly so the signal requests happen only when they are needed
  const { secondaryAnalysisFeatures, inclusionFeatures, exclusionFeatures } = getSecondaryAnalysisFeatures({
    viewerIndex: secondaryAnalysisActiveViewer,
    secondaryAnalysisSourceOrchestrationId,
  });

  const confirmWithModal = useConfirmation();

  const selectionType = !isEmpty(inclusionFeatures)
    ? ('inclusion' as const)
    : !isEmpty(exclusionFeatures)
    ? ('exclusion' as const)
    : ('' as const);

  const numberOfSelectedAreas = size(inclusionFeatures) || size(exclusionFeatures);

  const noSelection = isEmpty(secondaryAnalysisFeatures) || !selectionType;

  return (
    <Tooltip
      title={
        noSelection
          ? 'No areas selected'
          : isStartingSecondaryAnalysis
          ? 'Running Secondary Analysis'
          : !canRunSecondaryAnalysis
          ? 'You do not have permission to run secondary analysis'
          : isLoadingOriginalJob
          ? 'Loading original job'
          : !wholeSlideAnalysisJob
          ? 'No job found'
          : !secondaryAnalysisSourceOrchestrationId
          ? 'No primary run found'
          : ''
      }
    >
      <span>
        <Chip
          disabled={
            !secondaryAnalysisSourceOrchestrationId ||
            isStartingSecondaryAnalysis ||
            !canRunSecondaryAnalysis ||
            isLoadingOriginalJob ||
            !isSecondaryAnalysisActive ||
            noSelection ||
            !wholeSlideAnalysisJob
          }
          onClick={async (e) => {
            e.stopPropagation();
            if (
              await confirmWithModal({
                title: (
                  <ConfirmationModalTitle selectionType={selectionType} numberOfSelectedAreas={numberOfSelectedAreas} />
                ),
                text: <ConfirmationModalBody />,
              })
            ) {
              runSecondaryAnalysis({
                caseId,
                slideId,
                studyId,
                viewerIndex: secondaryAnalysisActiveViewer,
                secondaryAnalysisSourceOrchestrationId,
                autoInternallyApprove,
                wholeSlideAnalysisJob,
              });
            }
          }}
          label={isStartingSecondaryAnalysis ? 'Starting Secondary Analysis...' : 'Run Secondary Analysis'}
          color="primary"
        />
      </span>
    </Tooltip>
  );
};
