import { Badge, BadgeProps, SvgIconProps } from '@mui/material';
import React from 'react';

export const IconWithBadge: React.FC<
  SvgIconProps & {
    icon: React.ElementType<SvgIconProps>;
    badgeContent: BadgeProps['badgeContent'];
    badgeSize?: number;
  }
> = ({ icon: Icon, badgeContent, badgeSize = 13, ...props }) => (
  <Badge
    badgeContent={badgeContent}
    overlap="circular"
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    color="secondary"
    sx={(props?.sx as any)?.color ? { color: (props.sx as any).color } : {}}
    componentsProps={{
      badge: { style: { fontSize: badgeSize, width: badgeSize, minWidth: badgeSize, height: badgeSize } },
    }}
  >
    <Icon {...props} />
  </Badge>
);
