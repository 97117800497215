import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';

export interface ClassesToUseIgnoreRadioProps {
  isToIgnore: boolean;
  updateIsToIgnore: (isToIgnore: boolean) => void;
}

const ClassesToUseIgnoreRadioButtons: React.FC<React.PropsWithChildren<ClassesToUseIgnoreRadioProps>> = ({
  isToIgnore,
  updateIsToIgnore,
}) => {
  return (
    <RadioGroup
      value={String(isToIgnore)}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        updateIsToIgnore(e.target.value === 'true');
      }}
    >
      <FormControlLabel value="false" control={<Radio size="small" />} label="Classes To Use" />
      <FormControlLabel value="true" control={<Radio size="small" />} label="Classes To Ignore" />
    </RadioGroup>
  );
};
export default ClassesToUseIgnoreRadioButtons;
