import CloseIcon from '@mui/icons-material/Close';
import TuneIcon from '@mui/icons-material/Tune';
import { Grid, IconButton, IconButtonProps, Paper, Popper, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';

import { BooleanParam, useQueryParam } from 'use-query-params';
import { INFOBAR_WIDTH } from '../CaseView';
import { SlideWithChannelAndResults } from '../useSlideChannelsAndResults/utils';
import { AdvancedBaseSlideControl } from './AdvancedBaseSlideControls';
import { BaseSlideControl } from './BaseSlideControl';
import Multiplex from './Multiplex';

interface Props extends IconButtonProps {
  slide: SlideWithChannelAndResults;
  width?: string | number;
}

const SlideControls: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  slide,
  width = INFOBAR_WIDTH,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const [useOSDViewer] = useQueryParam('useOSDViewer', BooleanParam);
  const [useAdvancedBaseSlideControls] = useQueryParam('useAdvancedBaseSlideControls', BooleanParam);

  return (
    <>
      <IconButton {...props} onClick={handleIconClick}>
        {open ? <CloseIcon color="primary" /> : <TuneIcon color="primary" />}
      </IconButton>
      <Popper open={open} anchorEl={anchorEl} placement="bottom">
        <Paper sx={{ width, paddingBlock: 2 }}>
          <Grid
            item
            maxHeight={350}
            container
            direction="column"
            spacing={2}
            flexWrap="nowrap"
            sx={{
              position: 'relative',
              paddingBlock: 0,
              overflowY: 'auto',
              '&>.MuiGrid-item': {
                paddingBottom: 0,
              },
              '& .MuiGrid-item .MuiGrid-item': {
                paddingBlock: 0,
              },
            }}
          >
            {Boolean(slide) && isEmpty(slide?.channelsMetadata) ? (
              <>
                <Grid item container wrap="nowrap" alignItems="center" justifyContent="space-between">
                  <Grid item md="auto" pl={1}>
                    <Typography variant="caption">Opacity</Typography>
                  </Grid>
                  <Grid item md={3}>
                    <BaseSlideControl
                      slideId={slide.id}
                      viewerIndex={slide.viewerIndex}
                      stainTypeId={slide.stainingType}
                    />
                  </Grid>
                </Grid>
                {!useOSDViewer && useAdvancedBaseSlideControls && (
                  <Grid item>
                    <AdvancedBaseSlideControl
                      slideId={slide.id}
                      viewerIndex={slide.viewerIndex}
                      stainTypeId={slide.stainingType}
                    />
                  </Grid>
                )}
              </>
            ) : (
              <Grid item>
                <Multiplex slide={slide} fixed independentFilter />
              </Grid>
            )}
          </Grid>
        </Paper>
      </Popper>
    </>
  );
};

export default SlideControls;
