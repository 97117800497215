import renderSlideIdCell from 'components/atoms/DataGridRenderers/SlideIdCellRenderer';
import { Procedure } from 'interfaces/procedure';
import { MULTIPLEX_STAIN_ID } from 'interfaces/stainType';
import { concat, find, keys, map, size } from 'lodash';
import moment from 'moment';
import { formatNumber, humanize } from 'utils/helpers';
import { DisplayedField, FieldOption } from '../../genericFields';
import { getProcedureSlideFieldValues, ProceduresFieldsContext } from './helpers';

export const originalFileNameField: DisplayedField<Procedure, string[]> = {
  filterType: 'multiText',
  dataCategory: 'metadata',
  dataKey: 'originalFileName',
  label: 'Original File Name(s)',
  columnWidth: { width: 200 },
  valueGetter: (procedure) => getProcedureSlideFieldValues(procedure, 'originalFileName'),
  isFieldOfObjectInArray: true,
  objectArrayInnerPath: 'originalFileName',
  objectArrayKeyPath: 'id',
  objectArrayPath: 'slides',
  getNumOfEntries: (procedure) => size(procedure?.slides),
};

export const formatField: DisplayedField<Procedure, string[]> = {
  filterType: 'multiText', // TODO: change to multiSelect once converted to enum
  dataCategory: 'metadata',
  dataKey: 'format',
  label: 'Format',
  columnWidth: { width: 200 },
  isFieldOfObjectInArray: true,
  valueGetter: (procedure) => getProcedureSlideFieldValues(procedure, 'format'),
  objectArrayInnerPath: 'format',
  objectArrayKeyPath: 'id',
  objectArrayPath: 'slides',
  getNumOfEntries: (procedure) => size(procedure?.slides),
};

export const slideIdField: DisplayedField<Procedure, string[]> = {
  filterType: 'multiText', // TODO: change to multiSelect (based on search filter perhaps) or text search
  dataCategory: 'metadata',
  dataKey: 'slideId',
  label: 'Slide ID(s)',
  columnWidth: { width: 200 },
  isFieldOfObjectInArray: true,
  valueGetter: (procedure) => getProcedureSlideFieldValues(procedure, 'id'),
  objectArrayInnerPath: 'id',
  objectArrayKeyPath: 'id',
  objectArrayPath: 'slides',
  getNumOfEntries: (procedure) => size(procedure?.slides),
  adminOnly: true,
  renderCell: () => (params) => map(params.value, (slideId) => renderSlideIdCell({ ...params, value: slideId })),
  unwoundRenderCell: () => renderSlideIdCell,
};

export const biopsySiteField: DisplayedField<Procedure, (number | string)[], ProceduresFieldsContext> = {
  unwoundRowCellEditType: 'select',
  filterType: 'multiSelect',
  dataCategory: 'metadata',
  dataKey: 'biopsySiteId',
  label: 'Biopsy Site',
  columnWidth: { width: 150 },
  isFieldOfObjectInArray: true,
  valueGetter: (procedure) => map(getProcedureSlideFieldValues(procedure, 'biopsySiteId'), (n) => Number(n)),
  objectArrayInnerPath: 'biopsySiteId',
  objectArrayKeyPath: 'id',
  objectArrayPath: 'slides',
  singleObjectFormatter: (value, { biopsySiteTypes }) =>
    find(biopsySiteTypes, { id: Number(value) })?.displayName || value?.toString() || '',
  getNumOfEntries: (procedure) => size(procedure?.slides),
  optionsGetter: (context) =>
    map(context.biopsySiteTypes, (biopsySiteType) => ({
      value: biopsySiteType.id,
      label: biopsySiteType.displayName,
    })),
  getError: ({ value, row, context }) => {
    if (value && !find(context?.biopsySiteTypes, { id: Number(value) })) return `Invalid Biopsy Site`;
  },
  enforceUniquePerBaseRow: true,
};

export const stainTypeField: DisplayedField<Procedure, string[], ProceduresFieldsContext> = {
  unwoundRowCellEditType: 'select',
  filterType: 'multiSelect',
  dataKey: 'stainingType',
  label: 'Stain Type(s)',
  isFieldOfObjectInArray: true,
  valueGetter: (procedure) => getProcedureSlideFieldValues(procedure, 'stainingType'),
  singleObjectFormatter: (stainTypeId, context) =>
    find(context.stainTypes, { id: stainTypeId })?.displayName || stainTypeId,
  getError: ({ value, row, context }) => {
    if (value && !find(context?.stainTypes, { id: value })) return `Invalid Stain Type`;
    if (context?.isEditPreview && value && !find(context?.stainTypesNotDeprecated, { id: value }))
      return `Deprecated Stain Type`;
  },
  objectArrayInnerPath: 'stainingType',
  objectArrayKeyPath: 'id',
  objectArrayPath: 'slides',
  getNumOfEntries: (procedure) => size(procedure?.slides),
  columnWidth: { width: 150 },
  optionsGetter: (context) =>
    map(context.stainTypesNotDeprecated, (stainType) => ({
      value: stainType.id,
      label: stainType.displayName,
    })),
};

export const biopsyTypeField: DisplayedField<Procedure, string[], ProceduresFieldsContext> = {
  unwoundRowCellEditType: 'select',
  filterType: 'multiSelect',
  dataCategory: 'metadata',
  dataKey: 'biopsyType',
  label: `Biopsy Type`,
  columnWidth: { width: 150 },
  isFieldOfObjectInArray: true,
  valueGetter: (procedure) => getProcedureSlideFieldValues(procedure, 'biopsyType'),
  objectArrayInnerPath: 'biopsyType',
  objectArrayKeyPath: 'id',
  objectArrayPath: 'slides',
  singleObjectFormatter: humanize,
  optionsGetter: (context) => context.biopsyTypes,
  getError: ({ value, row, context }) => {
    if (value && !find(context?.biopsyTypes, { value })) return `Invalid Biopsy Type`;
  },
  enforceUniquePerBaseRow: true,
};

export const scannerModelField: DisplayedField<Procedure, string[], ProceduresFieldsContext> = {
  unwoundRowCellEditType: 'select',
  filterType: 'multiSelect',
  dataKey: 'scannerModel',
  dataCategory: 'metadata',
  label: 'Scanner Model',
  columnWidth: { width: 150 },
  isFieldOfObjectInArray: true,
  valueGetter: (procedure) => getProcedureSlideFieldValues(procedure, 'scannerModel'),
  objectArrayInnerPath: 'scannerModel',
  objectArrayKeyPath: 'id',
  objectArrayPath: 'slides',
  singleObjectFormatter: humanize,
  optionsGetter: (context) =>
    map(context.scannerModels, (scannerModel) => ({ value: scannerModel, label: scannerModel })),
  tableViewOnly: true,
};

export const scannerManufacturerField: DisplayedField<Procedure, string[]> = {
  unwoundRowCellEditType: 'select',
  filterType: 'multiSelect',
  dataCategory: 'metadata',
  dataKey: 'scannerManufacturer',
  enumType: 'scannerManufacturer',
  label: 'Scanner Manufacturer',
  columnWidth: { width: 160 },
  isFieldOfObjectInArray: true,
  valueGetter: (procedure) => getProcedureSlideFieldValues(procedure, 'scannerManufacturer'),
  objectArrayInnerPath: 'scannerManufacturer',
  objectArrayKeyPath: 'id',
  objectArrayPath: 'slides',
  getNumOfEntries: (procedure) => size(procedure?.slides),
  singleObjectFormatter: humanize,
  tableViewOnly: true,
};

export const tsmAreaField: DisplayedField<Procedure, number[]> = {
  filterType: 'range',
  dataCategory: 'metadata',
  dataKey: 'tsmArea',
  label: 'TSM Area',
  columnWidth: { width: 120 },
  valueGetter: (procedure) => getProcedureSlideFieldValues(procedure, 'tsmAreaUm2'),
  isFieldOfObjectInArray: true,
  objectArrayKeyPath: 'id',
  objectArrayPath: 'slides',
  objectArrayInnerPath: 'tsmAreaUm2',
  singleObjectFormatter: (value) => (value ? formatNumber(value) : null),
};

export const maxResolutionField: DisplayedField<Procedure, number[]> = {
  filterType: 'range',
  dataCategory: 'metadata',
  dataKey: 'maxResolution',
  label: 'Max Resolution',
  columnWidth: { width: 120 },
  valueGetter: (procedure) => getProcedureSlideFieldValues(procedure, 'maxResolution'),
  isFieldOfObjectInArray: true,
  objectArrayKeyPath: 'id',
  objectArrayPath: 'slides',
  objectArrayInnerPath: 'maxResolution',
  singleObjectFormatter: (value) => (value ? formatNumber(value) : null),
};

export const negativeControlField: DisplayedField<Procedure, boolean[]> = {
  unwoundRowCellEditType: 'select',
  filterType: 'checkbox-group',
  dataKey: 'negativeControl',
  label: 'Negative Control',
  columnWidth: { width: 130 },
  options: [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ] as any[],
  singleObjectFormatter: (option) => (option ? 'Yes' : 'No'),
  isFieldOfObjectInArray: true,
  objectArrayInnerPath: 'negativeControl',
  objectArrayKeyPath: 'id',
  objectArrayPath: 'slides',
  missingValueOption: false as any,
};

export const positiveControlField: DisplayedField<Procedure, boolean[]> = {
  unwoundRowCellEditType: 'select',
  filterType: 'checkbox-group',
  dataCategory: 'metadata',
  dataKey: 'positiveControl',
  label: 'Positive Control',
  columnWidth: { width: 130 },
  options: [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ] as any[],
  singleObjectFormatter: (option) => (option ? 'Yes' : 'No'),
  isFieldOfObjectInArray: true,
  objectArrayInnerPath: 'positiveControl',
  objectArrayKeyPath: 'id',
  objectArrayPath: 'slides',
  missingValueOption: false as any,
};

export const scanDateField: DisplayedField<Procedure, string[]> = {
  filterType: 'date-range',
  dataCategory: 'metadata',
  dataKey: 'scanDate',
  label: 'Scan Date',
  isFullDate: true,
  views: ['day', 'month', 'year'],
  columnWidth: { width: 150 },
  singleObjectFormatter(value, context) {
    return value ? moment(value).format('l, h:mm:ss a') : '';
  },
  isFieldOfObjectInArray: true,
  valueGetter: (procedure) => getProcedureSlideFieldValues(procedure, 'scanDate'),
  objectArrayInnerPath: 'scanDate',
  objectArrayKeyPath: 'id',
  objectArrayPath: 'slides',
};

export const qcFailedField: DisplayedField<Procedure, boolean[]> = {
  filterType: 'multiSelect',
  dataCategory: 'metadata',
  dataKey: 'qcFailed',
  label: 'Failed Quality Control',
  // TODO: add support for boolean options
  options: [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ] as any[], // Select works with boolean but type isn't supported :(
  columnWidth: { width: 150 },
  isFieldOfObjectInArray: true,
  valueGetter: (procedure) => getProcedureSlideFieldValues(procedure, 'qcFailed'),
  objectArrayInnerPath: 'qcFailed',
  objectArrayKeyPath: 'id',
  objectArrayPath: 'slides',
  getNumOfEntries: (procedure) => size(procedure?.slides),
  singleObjectFormatter: (qcFailed) => (qcFailed ? 'Yes' : 'No'),
  missingValueOption: false as any,
};

export const numOfChannelsField: DisplayedField<Procedure, number[]> = {
  filterType: 'range',
  dataCategory: 'metadata',
  dataKey: 'numOfChannels',
  label: 'Number of Channels',
  columnWidth: { width: 150 },
  valueGetter: (procedure) =>
    map(procedure?.slides, (slide) =>
      slide.stainingType === MULTIPLEX_STAIN_ID ? Number(keys(slide?.channelMarkerTypes || []).length || 0) : 1
    ),
  isFieldOfObjectInArray: true,
  objectArrayKeyPath: 'id',
  objectArrayPath: 'slides',
  objectArrayInnerPath: 'channelMarkerTypes.length',
};

export const objectiveMagnificationField: DisplayedField<Procedure, number[], ProceduresFieldsContext> = {
  filterType: 'multiSelect',
  dataCategory: 'metadata',
  dataKey: 'objectiveMagnification',
  label: 'Objective Magnification',
  optionsGetter: (context) => context.enumDisplayNames.objectiveMagnification,
  columnWidth: { width: 180 },
  valueGetter: (procedure) => getProcedureSlideFieldValues(procedure, 'objectiveMagnification'),
  singleObjectFormatter: (value, context) => {
    return find(context.enumDisplayNames.objectiveMagnification, (option) => Number(option.value) === value)?.label;
  },
  isFieldOfObjectInArray: true,
  objectArrayInnerPath: 'objectiveMagnification',
  objectArrayKeyPath: 'id',
  objectArrayPath: 'slides',
  getNumOfEntries: (procedure) => size(procedure?.slides),
};

export const encodingOptions: FieldOption[] = [
  {
    label: 'RGB',
    value: 'rgb',
  },
  {
    label: '8-bit',
    value: 'uint8',
  },
  {
    label: '16-bit',
    value: 'uint16',
  },
  {
    label: 'Float',
    value: 'float',
  },
];

export const encodingField: DisplayedField<Procedure, string[]> = {
  filterType: 'multiSelect',
  dataCategory: 'metadata',
  dataKey: 'encoding',
  unwoundRowCellEditType: 'select',
  label: 'Encoding',
  options: encodingOptions,
  columnWidth: { width: 150 },
  singleObjectFormatter: (value) => find(encodingOptions, { value })?.label ?? value ?? '8-bit (default)',
  isFieldOfObjectInArray: true,
  objectArrayInnerPath: 'encoding',
  objectArrayKeyPath: 'id',
  objectArrayPath: 'slides',
};

export const artifactsField: DisplayedField<Procedure, string[]> = {
  filterType: 'multiSelect',
  dataCategory: 'metadata',
  dataKey: 'artifacts',
  label: 'Excluded Artifacts',
  // TODO: get from endpoint
  options: [
    {
      label: 'Folds',
      value: 'Folds',
    },
    {
      label: 'Out of Focus',
      value: 'Out of Focus',
    },
    {
      label: 'Ink Stain',
      value: 'Ink Stain',
    },
  ],
  columnWidth: { width: 150 },
  valueGetter: (procedure) => map(procedure?.slides, (slide) => (slide ? 'None' : '-')),
  // TODO: add artifacts to procedure slides
  isFieldOfObjectInArray: true,
  objectArrayInnerPath: 'artifacts',
  objectArrayKeyPath: 'id',
  objectArrayPath: 'slides',
  getNumOfEntries: (procedure) => size(procedure?.slides),
};

export const slidesMetadataFields: DisplayedField<Procedure>[] = [
  scanDateField,
  biopsyTypeField,
  scannerModelField,
  scannerManufacturerField,
  artifactsField,
  qcFailedField,
  tsmAreaField,
  objectiveMagnificationField,
  maxResolutionField,
  numOfChannelsField,
  encodingField,
];

export const slidesBaseFields: DisplayedField<Procedure>[] = [
  originalFileNameField,
  formatField,
  slideIdField,
  biopsySiteField,
  stainTypeField,
  negativeControlField,
  positiveControlField,
];

export default concat(slidesBaseFields, slidesMetadataFields);
