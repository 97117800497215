import { Autocomplete, SxProps, TextField } from '@mui/material';
import { castArray, compact, find, map, orderBy } from 'lodash';
import React, { useMemo, useState, useTransition } from 'react';

import { TaxonomyTag } from 'interfaces/taxonomyTag';
import useTaxonomyTags from 'utils/queryHooks/taxonomy/useTaxonomyTags';

interface TaxonomyTagMultiSelectDropdownProps {
  cellTaxonomy?: string;
  selectedTaxonomyTagIds: string[];
  onSelectTaxonomyTagIds: (taxonomiesIds: string[]) => void;
  sx?: SxProps;
}

const TaxonomyTagMultiSelectDropdown: React.FC<TaxonomyTagMultiSelectDropdownProps> = ({
  cellTaxonomy,
  selectedTaxonomyTagIds: externalTaxonomyTagIds,
  onSelectTaxonomyTagIds,
  sx,
}) => {
  const [selectedInternalTaxonomyTagIds, setSelectedInternalTaxonomyTagIds] = useState<string[]>(null);

  const [, startTransition] = useTransition(); // we use useTransition to make it so the UI doesn't freeze when selecting a taxonomy

  const handleSelectTaxonomyTags = (newValue: TaxonomyTag[]) => {
    startTransition(() => {
      onSelectTaxonomyTagIds(map(newValue, 'id'));
    });
    setSelectedInternalTaxonomyTagIds(map(newValue, 'id'));
  };

  const selectedTaxonomyTagIds = orderBy(compact(castArray(selectedInternalTaxonomyTagIds || externalTaxonomyTagIds)));

  const { data: taxonomyTags, isLoading: isLoadingTaxonomyTags } = useTaxonomyTags(cellTaxonomy);

  const selectedTaxonomyTags = useMemo(
    () => compact(map(selectedTaxonomyTagIds, (id) => find(taxonomyTags, { id }))),
    [selectedTaxonomyTagIds, taxonomyTags]
  );

  return (
    <Autocomplete
      sx={sx}
      multiple
      loading={isLoadingTaxonomyTags}
      fullWidth
      options={taxonomyTags || []}
      getOptionLabel={(option) => option.displayName || option.id}
      value={selectedTaxonomyTags}
      onChange={(event, newValue) => handleSelectTaxonomyTags(newValue as TaxonomyTag[])}
      disableCloseOnSelect
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => <TextField size="small" {...params} placeholder="Select Taxonomy Tags" />}
    />
  );
};

export default TaxonomyTagMultiSelectDropdown;
