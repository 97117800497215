import {
  FeatureMetadata,
  HeatmapType,
  ParquetLayerType,
} from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import {
  markerPositivityColumnPrefix,
  markerProbabilityColumnPrefix,
} from 'components/Procedure/useSlideChannelsAndResults/parseHeatmaps';
import { compact, concat, Dictionary, filter, first, groupBy, isNumber, map } from 'lodash';
import { useMemo } from 'react';

import { useParquetFiles } from 'utils/useParquetFile';

/**
 * To group relevant marker positivity heatmaps together, we use this function to extract their common parent heatmap id.
 * @param heatmap a marker positivity heatmap
 * @returns the parent heatmap id, which is the heatmap id without the column name in it, i.e. "heatmapId-columnName-something" -> "heatmapId-something"
 */
export const getMarkerPositivityParentHeatmapId = (heatmap: FeatureMetadata) =>
  heatmap?.id?.replace(`-${heatmap?.columnName}`, '');

/**
 * Group parquet heatmaps by their type (i.e. how they should be visualized in the viewer).
 * We currently support:
 * - Marker Positivity: Heatmaps with column names starting with 'marker.pos.', which will be grouped by their source into a single heatmap with multiple layers, per marker.pos. column
 *   and visualized as a single heatmap with multiple layers, which draw circles colored into segments based on the number of positive markers for each cell.
 * - Cell Classification: Heatmaps with options, which will be visualized as a single heatmap with multiple layers, per option, which we assume to be discrete cell classifications
 *   and visualized as a single heatmap with multiple layers, which draw colored circles based on the cell classification.
 * @param parquetHeatmaps list of heatmaps with fileType = 'parquet'
 * @returns a dictionary of heatmaps lists, grouped by their type (i.e. how they should be visualized in the viewer)
 */
export const groupParquetHeatmapsByType = (parquetHeatmaps: FeatureMetadata[], onlyPreSigned?: boolean) => {
  const filteredHeatmaps = filter(
    parquetHeatmaps,
    (parquetHeatmap) =>
      parquetHeatmap?.heatmapType === HeatmapType.Parquet &&
      Boolean(!onlyPreSigned || (parquetHeatmap?.heatmapUrl && !parquetHeatmap?.heatmapUrl.startsWith('s3://')))
  );

  return groupBy(filteredHeatmaps, (parquetHeatmap) => {
    const columnType = parquetHeatmap?.columnType;
    if (parquetHeatmap?.columnName?.startsWith(markerPositivityColumnPrefix)) {
      return ParquetLayerType.MarkerPositivity;
    } else if (parquetHeatmap?.columnName?.startsWith(markerProbabilityColumnPrefix)) {
      return ParquetLayerType.MarkerProbability;
    } else if (columnType === 'categorical') {
      const registeredFromStainTypeIndex = parquetHeatmap?.registeredFromStainTypeIndex;
      if (isNumber(registeredFromStainTypeIndex)) {
        // Since these are registered results, we don't want to show them as point clouds, but as aggregated intensities
        return ParquetLayerType.CellClassificationDensities;
      } else {
        return ParquetLayerType.CellClassification;
      }
    } else if (columnType === 'categorical_array') {
      return ParquetLayerType.CellMultiClassification;
    } else {
      return ParquetLayerType.Other;
    }
  });
};

export const useParquetHeatmapFiles = ({
  parquetHeatmapByType,
}: {
  parquetHeatmapByType: Dictionary<FeatureMetadata[]>;
}) => {
  const parquetFileQueries = useMemo(() => {
    const groupMarkerPositivityHeatmapsById = groupBy(
      parquetHeatmapByType[ParquetLayerType.MarkerPositivity],
      getMarkerPositivityParentHeatmapId
    );

    return concat(
      map(
        compact(
          concat(
            parquetHeatmapByType[ParquetLayerType.CellClassification],
            parquetHeatmapByType[ParquetLayerType.CellClassificationDensities]
          )
        ),
        ({ heatmapUrl, columnName }) => ({
          url: heatmapUrl,
          columns: ['X', 'Y', columnName],
        })
      ),
      map(groupMarkerPositivityHeatmapsById, (parquetHeatmapsByParentId) => ({
        url: first(parquetHeatmapsByParentId)?.heatmapUrl,
        columns: ['X', 'Y', ...map(parquetHeatmapsByParentId, 'columnName')],
      }))
    );
  }, [parquetHeatmapByType]);

  return useParquetFiles(parquetFileQueries);
};
