import CloseIcon from '@mui/icons-material/Close';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import AlertDialog from 'components/atoms/Dialog/AlertDialog';
import { useConfirmation } from 'components/modals/ConfirmationContext';
import {
  AnnotationAssignment,
  AnnotationClassRemovalOperation,
  ClassRemovalAction,
  TodoOption,
} from 'interfaces/annotation';
import { filter, find, findIndex, first, includes, map, slice } from 'lodash';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import useHandleUnload from 'utils/useHandleUnload';
import * as yup from 'yup';
import CreateAnnotationAssignmentSection from './CreateAnnotationAssignmentSection';
import { DeleteClassFromAssignmentDialog } from './DeleteClassesFromAssignmentDialog';

export interface AssignmentFormValues {
  assignmentName: string;
  classesToAnnotate: TodoOption[];
}

interface DialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  buttonText: string;
  isLoadingButtonText: string;
  currentAssignment?: AnnotationAssignment;
  handleApply: SubmitHandler<AssignmentFormValues>;
  isLoading?: boolean;
  classesToRemoveWithActions?: AnnotationClassRemovalOperation[];
  setClassesToRemoveWithActions?: React.Dispatch<React.SetStateAction<AnnotationClassRemovalOperation[]>>;
}

const AnnotationAssignmentDialog: React.FC<DialogProps> = ({
  open,
  onClose,
  title,
  buttonText,
  isLoadingButtonText,
  currentAssignment,
  handleApply,
  isLoading,
  classesToRemoveWithActions,
  setClassesToRemoveWithActions,
}) => {
  const confirmWithModal = useConfirmation();
  const assignmentCreationForm = useForm<AssignmentFormValues>({
    mode: 'onChange',
    resolver: yupResolver(assignmentCreationSchema),
    defaultValues: currentAssignment
      ? {
          assignmentName: currentAssignment?.name,
          classesToAnnotate: first(currentAssignment?.todos)?.options,
        }
      : {
          assignmentName: '',
          classesToAnnotate: [],
        },
  });

  const {
    watch,
    formState: { isDirty },
  } = assignmentCreationForm;

  const [openRemoveClassDialog, setOpenRemoveClassDialog] = React.useState(false);
  const [alertDialogOpen, setAlertDialogOpen] = React.useState(false);
  const [selectedClassToRemove, setSelectedClassToRemove] = React.useState<TodoOption | null>(null);

  const handleAlertDialogClose = () => {
    setAlertDialogOpen(false);
  };

  const handleDeleteClassToAnnotate = (id: string) => {
    const previousClassesToAnnotate = assignmentCreationForm.watch('classesToAnnotate');
    const currentClassToRemove = find(previousClassesToAnnotate, (classToAnnotate) => classToAnnotate.name === id);

    const classesToRemoveWithReplaceAction = filter(
      classesToRemoveWithActions,
      (classToRemoveWithAction) => classToRemoveWithAction.action === ClassRemovalAction.replace
    );

    if (includes(map(map(classesToRemoveWithReplaceAction, 'classToReplaceWith'), 'name'), currentClassToRemove.name)) {
      setAlertDialogOpen(true);
      return;
    }

    // show delete class options only if the class was already in the assignment (if not- there are also no annotations to delete)
    if (setClassesToRemoveWithActions && find(first(currentAssignment.todos).options, (option) => option.name === id)) {
      setSelectedClassToRemove(currentClassToRemove);
      setOpenRemoveClassDialog(true);
    } else {
      removeClassFromAssignmentTodo(currentClassToRemove);
    }
  };

  const removeClassFromAssignmentTodo = (classToRemove: TodoOption) => {
    if (!classToRemove) return;

    const previousClassesToAnnotate = assignmentCreationForm.watch('classesToAnnotate');
    assignmentCreationForm.setValue(
      'classesToAnnotate',
      filter(
        previousClassesToAnnotate,
        (previousClassToAnnotate) => previousClassToAnnotate.name !== classToRemove?.name
      ),
      { shouldDirty: true }
    );
  };

  const handleRemoveClassFromAssignment = (action: ClassRemovalAction, classToReplaceWith?: TodoOption) => {
    // for assignment creation, we don't need to update any annotations
    if (setClassesToRemoveWithActions) {
      setClassesToRemoveWithActions((prevClasses) => [
        // if we are editing a removed class action, we need to remove the previous action
        ...filter(prevClasses, (classToRemove) => classToRemove.classToRemove.name !== selectedClassToRemove?.name),
        { classToRemove: selectedClassToRemove, action, classToReplaceWith },
      ]);
      setOpenRemoveClassDialog(false);
    }

    removeClassFromAssignmentTodo(selectedClassToRemove);
  };

  const handleEditDeletedClass = (id: string) => {
    if (setClassesToRemoveWithActions) {
      const currentClassToRemoveEdit = find(
        classesToRemoveWithActions,
        (classToRemove) => classToRemove.classToRemove.name === id
      );
      const todoOption = find(currentAssignment?.todos[0].options, (option) => option.name === id);
      if (currentClassToRemoveEdit && todoOption) {
        setSelectedClassToRemove(todoOption);
        setOpenRemoveClassDialog(true);
      }
    }
  };

  const handleUndoDelete = (id: string) => {
    if (setClassesToRemoveWithActions) {
      const previousClassToAnnotate = find(
        classesToRemoveWithActions,
        (classToRemove) => classToRemove.classToRemove.name === id
      )?.classToRemove;

      const originalIndex = findIndex(currentAssignment.todos[0].options, (option) => option.name === id);

      if (previousClassToAnnotate && originalIndex !== -1) {
        const currentClassesToAnnotate = watch('classesToAnnotate');
        assignmentCreationForm.setValue(
          'classesToAnnotate',
          [
            ...slice(currentClassesToAnnotate, 0, originalIndex),
            previousClassToAnnotate,
            ...slice(currentClassesToAnnotate, originalIndex),
          ],
          {
            shouldDirty: true,
          }
        );
      }
      setClassesToRemoveWithActions((prevClasses) =>
        filter(prevClasses, (classToRemove) => classToRemove.classToRemove.name !== id)
      );
    }
  };

  const handleColorChange = (id: string, color: any) => {
    const previousClassesToAnnotate = assignmentCreationForm.watch('classesToAnnotate');
    assignmentCreationForm.setValue(
      'classesToAnnotate',
      map(previousClassesToAnnotate, (previousClassToAnnotate) => {
        if (previousClassToAnnotate.name === id) {
          return {
            ...previousClassToAnnotate,
            color,
          };
        }
        return previousClassToAnnotate;
      }),
      { shouldDirty: true }
    );
  };

  const handleClassesToAnnotateChange = (newClassesToAnnotate: TodoOption[]) => {
    const prevClassesToAnnotate = assignmentCreationForm.watch('classesToAnnotate');

    const prevIds = map(prevClassesToAnnotate, 'name');
    // Add newly selected items at the end
    const added = filter(newClassesToAnnotate, (newClassToAnnotate) => !includes(prevIds, newClassToAnnotate.name));

    // Keep only the ids that are still selected, in their original order
    // Use the new classes to annotate to determine the order
    const retained = filter(newClassesToAnnotate, (newClassToAnnotate) =>
      includes(map(prevClassesToAnnotate, 'name'), newClassToAnnotate.name)
    );

    const newClassesToAnnotateWithPreviousOrder = [...retained, ...added];

    assignmentCreationForm.setValue('classesToAnnotate', newClassesToAnnotateWithPreviousOrder, { shouldDirty: true });
    // validate to update errors
    assignmentCreationForm.trigger('classesToAnnotate');
  };

  const handleClose = (event: React.MouseEvent, reason: string) => {
    if (reason !== 'backdropClick') {
      handleCloseButtonClick();
    }
  };

  const handleCloseButtonClick = async () => {
    if (isDirty) {
      if (
        await confirmWithModal({
          title: 'Unsaved changes',
          text: 'Unsaved changes will be lost. Are you sure you want to exit?',
          confirmButtonProps: { title: 'Exit' },
          cancelButtonProps: { title: 'Cancel' },
        })
      ) {
        handleClearChanges();
        onClose();
      }
    } else {
      onClose();
    }
  };

  const handleClearChanges = () => {
    assignmentCreationForm.reset();
    if (setClassesToRemoveWithActions) {
      setClassesToRemoveWithActions([]);
    }
  };

  useHandleUnload({ showAlert: isDirty });

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDialogContent-root': {
            padding: 0,
          },
        }}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={handleCloseButtonClick}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <>
            <CreateAnnotationAssignmentSection
              assignmentCreationForm={assignmentCreationForm}
              onDeleteClassToAnnotate={handleDeleteClassToAnnotate}
              onColorChange={handleColorChange}
              onClassesToAnnotateChange={handleClassesToAnnotateChange}
              classesToRemoveWithActions={classesToRemoveWithActions}
              onEditDeleted={handleEditDeletedClass}
              onUndoDelete={handleUndoDelete}
            />
            {handleRemoveClassFromAssignment && (
              <DeleteClassFromAssignmentDialog
                open={openRemoveClassDialog}
                onCancel={() => setOpenRemoveClassDialog(false)}
                removedTodoOption={selectedClassToRemove}
                assignment={{
                  ...currentAssignment,
                  todos: [
                    {
                      todo: first(currentAssignment?.todos)?.todo,
                      type: first(currentAssignment?.todos)?.type,
                      options: watch('classesToAnnotate'),
                    },
                  ],
                }}
                onContinue={handleRemoveClassFromAssignment}
              />
            )}
          </>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={assignmentCreationForm.handleSubmit(handleApply)}
            color="primary"
            variant="contained"
            disabled={isLoading}
          >
            {isLoading ? isLoadingButtonText : buttonText}
          </Button>
          <Button onClick={handleClearChanges} color="primary" variant="outlined" disabled={isLoading}>
            Clear changes
          </Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        open={alertDialogOpen}
        onClose={handleAlertDialogClose}
        title="This class cannot be deleted"
        message="This class is already selected to replace another class. Change it before you delete this class"
        severity="warning"
      />
    </>
  );
};

const assignmentCreationSchema = yup.object({
  assignmentName: yup.string().required('Assignment name is required'),
  classesToAnnotate: yup.array().required().min(1, 'At least one class is required'),
});

export default AnnotationAssignmentDialog;
