import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  OutlinedInput,
  TextField,
  Tooltip,
} from '@mui/material';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { isEmpty } from 'lodash';
import React from 'react';

import ModelsTable from 'components/ModelsTable';
import { ModelType } from '.';

const DefaultModelCellSelector: React.FC<{
  api: GridApiCommunity;
  id: GridRowId;
  modelType?: string;
  defaultModelId?: string;
  defaultModelUrl?: string;
  trainingType?: string;
}> = ({ api, id, modelType, defaultModelId, defaultModelUrl, trainingType }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const updateModel = (modelUrl: string | undefined, modelId?: string) => {
    api.setEditCellValue({ id, field: 'defaultModelUrl', value: modelUrl });
    api.setEditCellValue({ id, field: 'defaultModelId', value: modelId || undefined });
  };
  const missingModelType = isEmpty(modelType);
  return (
    <>
      {/* Selected model id with edit button to open the modal */}
      <OutlinedInput
        fullWidth
        value={defaultModelUrl}
        onChange={(event) => updateModel(event.target.value)}
        placeholder={
          missingModelType ? 'Select a model type first' : 'Choose model from the table or write here the Model URL'
        }
        endAdornment={
          <Tooltip title={missingModelType ? 'Select a model type first' : 'Search model'}>
            <span>
              <IconButton onClick={() => setIsModalOpen(true)} disabled={missingModelType}>
                <SearchIcon />
              </IconButton>
            </span>
          </Tooltip>
        }
        disabled={missingModelType}
        error={missingModelType}
      />

      <Dialog fullWidth open={isModalOpen} onClose={() => setIsModalOpen(false)} maxWidth="lg">
        <DialogTitle id="responsive-dialog-title">Select Model</DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={2} width="100%">
            <Grid item>
              <TextField
                value={defaultModelUrl}
                label="Model URL"
                onChange={(event) => updateModel(event.target.value)}
                placeholder="Choose model from the table or write the Model URL here"
                required
              />
            </Grid>
            <Grid item>
              <ModelsTable
                modelType={modelType}
                onSelect={(model) => updateModel(model.url, model.id)}
                selectedModelUrl={defaultModelUrl}
                enabled={!missingModelType && isModalOpen}
                defaultPageSize={100}
                trainingType={trainingType}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {(defaultModelId || defaultModelUrl) && (
            <Button onClick={() => updateModel(undefined)} color="secondary">
              Clear
            </Button>
          )}
          <Button onClick={() => setIsModalOpen(false)} color="primary">
            {defaultModelId || defaultModelUrl ? 'Select' : 'Close'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const renderDefaultModelCellSelector: GridColDef<ModelType, string, string>['renderEditCell'] = (params) => {
  const modelType = params.api.getRowWithUpdatedValues(params.id, 'type')?.type || params.row.type;
  const defaultModelId =
    params.api.getRowWithUpdatedValues(params.id, 'defaultModelId')?.defaultModelId || params.row.defaultModelId;
  const defaultModelUrl =
    params.api.getRowWithUpdatedValues(params.id, 'defaultModelUrl')?.defaultModelUrl || params.row.defaultModelUrl;
  const trainingType =
    params.api.getRowWithUpdatedValues(params.id, 'trainingType')?.trainingType || params.row.trainingType;

  return (
    <DefaultModelCellSelector
      api={params.api}
      id={params.id}
      modelType={modelType}
      defaultModelId={defaultModelId}
      defaultModelUrl={defaultModelUrl}
      trainingType={trainingType}
    />
  );
};
