import { useQuery } from '@tanstack/react-query';
import { fetchSingleStudy, fetchStudies } from 'api/study';
import { find } from 'lodash';
import { useCurrentLabId } from './useCurrentLab';

const useStudy = (studyId: string, { enabled = true }: { enabled?: boolean } = {}) => {
  const { labId } = useCurrentLabId();
  const { data: studies } = useQuery(['studies', labId], fetchStudies(labId), {
    enabled: false, // Only use cached data, if available - https://tanstack.com/query/v4/docs/framework/react/guides/disabling-queries
  });
  const query = useQuery(['study', studyId, labId], () => fetchSingleStudy(studyId, labId), {
    enabled: Boolean(studyId) && enabled,
    placeholderData: find(studies, { id: studyId }),
  });

  return { study: query.data, ...query };
};

export default useStudy;
