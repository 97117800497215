import { Grid, Typography, useTheme } from '@mui/material';
import DashboardTabs from 'components/DashboardTabs';
import CellRulesDataGrid from 'components/Pages/OperationsSettings/CellRulesDataGrid';
import CellRulePanelDropdown from 'components/Pages/OperationsSettings/CellRulesDataGrid/CellRulePanel/CellRulePanelDropdown';
import { useStainTypesUsedByPanel } from 'components/Pages/OperationsSettings/CellRulesDataGrid/panel.util';
import { TodoOption } from 'interfaces/annotation';
import { CellRuleValue, MinimalCellRule } from 'interfaces/cellRule';
import { compact, concat, filter, find, forEach, includes, indexOf, map, orderBy, uniq } from 'lodash';
import React, { useMemo, useState } from 'react';
import useCellRulePanels from 'utils/queryHooks/cellRule/useCellRulePanels';
import useCellRules from 'utils/queryHooks/cellRule/useCellRules';
import ClassesToAnnotateList, { ClassesToAnnotateListSkeleton } from '../ClassesToAnnotateList';

interface PresetsTodoTypesProps {
  selectedClassesToAnnotate: TodoOption[];
  onClassesSelected: (classes: TodoOption[]) => void;
  studyId: string;
  getClassToAnnotateFromTaxonomyId: (taxonomyId: string) => TodoOption;
  isLoading?: boolean;
  isError?: boolean;
  selectedPanelId: string | null;
  setSelectedPanelId: (panelId: string | null) => void;
}

const CellRulesTab: React.FC<PresetsTodoTypesProps> = ({
  selectedClassesToAnnotate,
  onClassesSelected,
  studyId,
  getClassToAnnotateFromTaxonomyId,
  isLoading,
  isError,
  selectedPanelId,
  setSelectedPanelId,
}) => {
  const theme = useTheme();
  const subTabs = ['Rules', 'Phenotypic markers'];
  const [activeTab, setActiveTab] = useState<string>('Rules');
  const activeTabIndex = indexOf(subTabs, activeTab) !== -1 ? indexOf(subTabs, activeTab) : 0;
  const changeTab = (newTab: string) => {
    setActiveTab(newTab);
  };

  const selectedClassesToAnnotateNames = useMemo(
    () => compact(map(selectedClassesToAnnotate, 'name')) ?? [],
    [selectedClassesToAnnotate]
  );

  const { data: panels, isLoading: isLoadingPanels, isError: isErrorLoadingPanels } = useCellRulePanels(studyId);

  const {
    data: panel,
    isLoading: isLoadingPanel,
    isError: isErrorLoadingPanel,
  } = useCellRules(selectedPanelId, studyId);

  const savedStainTypeIds = uniq(panel?.stainTypeIds);
  const savedCellTypeIds = uniq(map(panel?.rules, 'cellTypeId'));

  const [isCompact, setIsCompact] = useState(false);
  const [selectedStainTypeIds, setStainTypeIdsToShow] = useState(null);
  const [selectedCellTypeIds, setCellTypeIdsToShow] = useState(null);
  const [selectedRules, setSelectedRules] = useState<MinimalCellRule[]>([]);

  const stainTypeIdsToShow = selectedStainTypeIds ?? savedStainTypeIds;
  const cellTypeIdsToShow = selectedCellTypeIds ?? savedCellTypeIds;
  const panelStainTypes = useStainTypesUsedByPanel(panel);

  const phenotypicMarkerTodos: TodoOption[] = useMemo(() => {
    if (!panel) {
      return [];
    }
    const phenotypicMarkerTodoOptions: TodoOption[] = [];
    forEach(panelStainTypes, (stainType) => {
      if (stainType.phenotypic) {
        const phenotypicTodoOptionToAdd: TodoOption = {
          name: stainType.id,
          displayName: stainType.displayName,
          color: theme.palette.primary.main,
          className: 'point',
          isMarkerAnnotation: true,
        };
        phenotypicMarkerTodoOptions.push(phenotypicTodoOptionToAdd);
      }
    });

    return phenotypicMarkerTodoOptions;
  }, [panel]);

  const onSelectionFinished = () => {
    const todosFromSelectedCellRules: TodoOption[] = [];
    forEach(selectedRules, (cellRule) => {
      if (cellRule.ruleValue !== CellRuleValue.Indifferent) {
        let taxonomyToAnnotate: TodoOption = find(todosFromSelectedCellRules, { name: cellRule.cellTypeId });
        if (!taxonomyToAnnotate) {
          taxonomyToAnnotate = getClassToAnnotateFromTaxonomyId(cellRule.cellTypeId);
          taxonomyToAnnotate.markerPositivity = {};
          todosFromSelectedCellRules.push(taxonomyToAnnotate);
        }
        taxonomyToAnnotate.markerPositivity[cellRule.stainTypeId] = cellRule.ruleValue;
      }
    });

    const phenotypicSelectedClassesToAnnotate = filter(selectedClassesToAnnotate, (todo) => {
      return includes(map(phenotypicMarkerTodos, 'name'), todo.name);
    });

    const addedTodos = concat(todosFromSelectedCellRules, phenotypicSelectedClassesToAnnotate);

    const retained = filter(selectedClassesToAnnotate, (todo) => {
      return !includes(map(addedTodos, 'name'), todo.name);
    });

    const finalSelected = orderBy(concat(retained, addedTodos), (newClassToAnnotate) =>
      indexOf(selectedClassesToAnnotateNames, newClassToAnnotate.name)
    );

    onClassesSelected(finalSelected);
  };

  const handleShowCompactToggle = (isCompacting: boolean) => {
    setIsCompact(isCompacting);

    if (isCompacting) {
      const selectedRulesStainTypeIds = uniq(map(selectedRules, 'stainTypeId'));
      const selectedRulesCellTypeIds = uniq(map(selectedRules, 'cellTypeId'));
      setStainTypeIdsToShow(
        orderBy(selectedRulesStainTypeIds, (stainTypeId) => indexOf(savedStainTypeIds, stainTypeId))
      );
      setCellTypeIdsToShow(orderBy(selectedRulesCellTypeIds, (cellTypeId) => indexOf(savedCellTypeIds, cellTypeId)));
    } else {
      setStainTypeIdsToShow(savedStainTypeIds);
      setCellTypeIdsToShow(savedCellTypeIds);
    }
  };

  const border = `1px solid ${theme.palette.mode == 'light' ? 'lightgrey' : 'rgb(81, 81, 81)'}`;

  return (
    <Grid item container direction="column" mt={2}>
      <Grid item px={2}>
        <CellRulePanelDropdown
          panels={panels ?? []}
          selectedPanelId={selectedPanelId}
          onSelectPanel={setSelectedPanelId}
          isLoading={isLoadingPanels || isLoading}
          studyId={studyId}
          textFieldProps={{
            error: isErrorLoadingPanels || isError,
            helperText: isErrorLoadingPanels || isError ? 'Error loading panels' : undefined,
          }}
        />
      </Grid>
      <Grid item container direction="column" xs={true} sx={{ overflow: 'auto' }}>
        <Grid
          item
          sx={{
            px: 1,
            borderRadius: 0,
            borderBottom: border,
          }}
        >
          <DashboardTabs
            tabsDisplayTexts={subTabs}
            tabs={subTabs}
            changeTab={changeTab}
            defaultValueIndex={activeTabIndex}
          />
        </Grid>
        <Grid item xs={true} width="100%" p={1} sx={{ overflow: 'auto' }}>
          {activeTab === 'Rules' &&
            (selectedPanelId && isErrorLoadingPanel ? (
              <Typography color="error">Error loading panel rules</Typography>
            ) : (
              <CellRulesDataGrid
                panelId={selectedPanelId}
                stainTypeIds={stainTypeIdsToShow ?? savedStainTypeIds}
                cellTypeIds={cellTypeIdsToShow ?? savedCellTypeIds}
                rules={panel?.rules}
                isLoading={isLoadingPanel}
                selectedCells={selectedRules}
                onSelectCellRules={setSelectedRules}
                onSelectionFinished={onSelectionFinished}
                isCompact={isCompact}
                handleShowCompactToggle={handleShowCompactToggle}
              />
            ))}
          {activeTab === 'Phenotypic markers' &&
            (selectedPanelId && isLoadingPanel ? (
              <ClassesToAnnotateListSkeleton />
            ) : isErrorLoadingPanel ? (
              <Typography color="error">Error loading panel rules</Typography>
            ) : (
              <ClassesToAnnotateList
                classesToAnnotate={phenotypicMarkerTodos}
                isSelectable
                selectProps={{
                  isTodoSelected: (id) => includes(selectedClassesToAnnotateNames, id),
                  selectedClassesToAnnotate,
                  onClassesSelected,
                }}
              />
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CellRulesTab;
