import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import useMainFilters from 'components/SearchFilters/hooks/useMainFilters';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { SlidePageUrlProps, useNavigationToViewerPage } from 'utils/useNavigationToViewerPage';

interface SlideIdLinkProps extends Partial<SlidePageUrlProps> {
  slideId: string;
  formattedValue?: string;
  target?: string;
  rel?: string;
}

const LinkToSlideViewerComponent: React.FC<SlideIdLinkProps> = ({
  formattedValue,
  target,
  rel,
  ...slidePageUrlProps
}) => {
  const { labId } = useCurrentLabId();
  const { queryParams } = useEncodedFilters();
  const { isStudyIdSpecific } = useMainFilters();
  const studyIdIsSpecific = isStudyIdSpecific(queryParams?.filters?.studyId);

  const { getLinkToSlidePage } = useNavigationToViewerPage();
  return (
    <Link
      to={getLinkToSlidePage({
        labId,
        caseStudyId: studyIdIsSpecific ? queryParams?.filters?.studyId : undefined,
        ...slidePageUrlProps,
      })}
      target={target}
      rel={rel}
      component={RouterLink}
      color="secondary"
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      {target == '_blank' && <OpenInNewIcon fontSize="small" sx={{ mr: 0.5 }} />}
      <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {formattedValue || slidePageUrlProps.slideId}
      </span>
    </Link>
  );
};

export const renderSlideIdCell: GridColDef<any, string>['renderCell'] = ({
  value,
  formattedValue,
  row,
}): ReturnType<React.FC> => {
  const slideId = value;
  return (
    <LinkToSlideViewerComponent
      slideId={slideId}
      formattedValue={formattedValue}
      labId={row.labId}
      caseStudyId={row.studyId}
    />
  );
};

export const getRenderSlideIdCell = (
  overrides: Partial<SlideIdLinkProps> & {
    formattedValue?: string;
    slideIdColumn?: string;
    labIdColumn?: string;
    caseStudyIdColumn?: string;
  }
): GridColDef<any, string>['renderCell'] => {
  return (renderCellParams): ReturnType<React.FC> => {
    const slideId = overrides.slideId
      ? overrides.slideId
      : overrides.slideIdColumn
      ? renderCellParams.row[overrides.slideIdColumn]
      : renderCellParams.value;
    const formattedValue = overrides.formattedValue || renderCellParams.formattedValue;
    const labId = overrides.labId || renderCellParams.row[overrides.labIdColumn || 'labId'];
    const caseStudyId = overrides.caseStudyId || renderCellParams.row[overrides.caseStudyIdColumn || 'studyId'];
    return (
      <LinkToSlideViewerComponent
        {...overrides}
        slideId={slideId}
        formattedValue={formattedValue}
        labId={labId}
        caseStudyId={caseStudyId}
      />
    );
  };
};

export default renderSlideIdCell;
