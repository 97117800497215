import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export interface StudiesDashboardFiltersFields {
  text: string;
  startDate: Date | null;
  endDate: Date | null;
}

const defaultValues: StudiesDashboardFiltersFields = {
  text: '',
  startDate: null,
  endDate: null,
};

const validationSchema = yup
  .object()
  .shape({
    text: yup.string().nullable(),
    startDate: yup.date().nullable(),
    endDate: yup.date().nullable(),
  })
  .test({
    name: 'date-range-valid',
    message: 'StartDate must be before endDate, and vice versa, when both provided',
    test: (value) => {
      const { startDate, endDate } = value || {};
      if (!startDate && !endDate) {
        // if both are empty, it's valid
        return true;
      } else if (!startDate || !endDate) {
        // if one is empty, it's valid
        return true;
      }
      // if both are provided, startDate must be before endDate
      return new Date(startDate) < new Date(endDate);
    },
  });

export const useStudyFiltersForm = () =>
  useForm<StudiesDashboardFiltersFields>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
