import { Dictionary, findIndex, indexOf, isEmpty, map, size, some, values } from 'lodash';

import { HeatmapLayer } from '@deck.gl/aggregation-layers/typed';
import { LayerVisualizationSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { defaultLayerColors } from 'components/theme/theme';
import { getColorHex, hexToRgb, isHexString } from 'utils/helpers';
import { ParquetFile, ParquetRow } from 'utils/useParquetFile';
import MultiClassGaussianHeatmapLayer from '../MultiClassGaussianHeatmapLayer';
import { SizeInMicronsByZoomRange } from '../ZoomAwareLayers/types';
import { ZoomAwareGradientScatterplotLayer } from '../ZoomAwareLayers/ZoomAwareGradientScatterplotLayer';

const sizeInMicronsByZoomRange: SizeInMicronsByZoomRange[] = [
  { minZoom: -1, sizeInMicrons: 2 },
  { maxZoom: -1, minZoom: -1.5, sizeInMicrons: 10 },
  { maxZoom: -1.5, minZoom: -2, sizeInMicrons: 25 },
  { maxZoom: -2, sizeInMicrons: 35 },
];

type HeatmapLayerType = 'HeatmapLayer' | 'ZoomAwareGradientScatterplotLayer' | 'MultiClassGaussianHeatmapLayer';

export const deckGLParquetCellClassDensityLayer = ({
  idPrefix = '',
  parquetHeatmapId,
  heatmapMetadata,
  visualSettings,
  parquetFile,
  rescaleFactor,
  filteredColumns,
  slideMaxResolution,
  heatmapLayerType = 'MultiClassGaussianHeatmapLayer',
}: {
  idPrefix?: string;
  parquetHeatmapId: string;
  heatmapMetadata: FeatureMetadata;
  visualSettings: Dictionary<LayerVisualizationSettings>;
  rescaleFactor?: number;
  parquetFile: ParquetFile;
  filteredColumns: string[];
  slideMaxResolution: number;
  heatmapLayerType?: HeatmapLayerType;
}) => {
  if (!parquetHeatmapId) {
    return undefined;
  }
  const parquetClasses = map(heatmapMetadata?.options, 'key');
  const hasActiveParquetSource = some(values(visualSettings), 'selected');

  const columnIndex = filteredColumns
    ? indexOf(filteredColumns, heatmapMetadata.columnName)
    : findIndex(
        parquetFile?.metadata?.schema,
        (field: { name: string }) => field?.name === heatmapMetadata.columnName
      ) - 1;

  if (!parquetFile?.rows || isEmpty(parquetClasses) || !hasActiveParquetSource || columnIndex < 0) {
    return undefined;
  }

  if (heatmapLayerType === 'MultiClassGaussianHeatmapLayer') {
    const numClasses = size(parquetClasses);
    // RGBA colors for each class
    const classColors: Array<[number, number, number]> = map(parquetClasses, (parquetClass, layerIndex) => {
      const layerVisualSettings = visualSettings[`${parquetHeatmapId}-${parquetClass}`];
      return hexToRgb(
        isHexString(getColorHex(layerVisualSettings?.color))
          ? getColorHex(layerVisualSettings?.color)
          : defaultLayerColors[(layerIndex + size(defaultLayerColors)) % size(defaultLayerColors)]
      );
    });

    // Opacity for each class
    const classOpacities: number[] = map(parquetClasses, (parquetClass) => {
      const layerVisualSettings = visualSettings[`${parquetHeatmapId}-${parquetClass}`];
      return layerVisualSettings?.selected && layerVisualSettings?.show ? (layerVisualSettings?.opacity || 0) / 100 : 0;
    });
    return new MultiClassGaussianHeatmapLayer<ParquetRow>({
      getPosition: (d: ParquetRow) => [d[0], d[1]],
      pickable: false,
      getClassIndex: (d: ParquetRow) => {
        const label = d[columnIndex];
        return indexOf(parquetClasses, label);
      },
      id: `${idPrefix}${parquetHeatmapId}`,
      data: parquetFile.rows,
      numClasses,
      classColors,
      classOpacities,
      falloffDivisor: 3,
      // Add ramps - this is at full zoom
      radius: 35,
      intensity: 1.75,
      // This is at zoom out (10x and below)
      // radius: 20,
      // intensity: 2.25,
    });
  } else if (heatmapLayerType === 'HeatmapLayer') {
    return map(parquetClasses, (parquetClass, layerIndex) => {
      const layerVisualSettings = visualSettings[`${parquetHeatmapId}-${parquetClass}`];
      const opacity =
        layerVisualSettings?.selected && layerVisualSettings?.show ? (layerVisualSettings?.opacity || 0) / 100 : 0;
      const color = hexToRgb(
        isHexString(getColorHex(layerVisualSettings?.color))
          ? getColorHex(layerVisualSettings?.color)
          : defaultLayerColors[(layerIndex + size(defaultLayerColors)) % size(defaultLayerColors)]
      );
      const maxIntensityRgba = [...color, Math.floor(opacity * 255)];

      return new HeatmapLayer<ParquetRow>({
        id: `${idPrefix}${parquetHeatmapId}-${parquetClass}`,
        data: parquetFile.rows,
        getPosition: (d: ParquetRow) => [d[0], d[1]],
        debounceTimeout: 0,

        getWeight: (d: ParquetRow) => {
          const label = d[columnIndex];
          return label === parquetClass ? 1 : 0;
        },
        colorRange: [[0, 0, 0, 0], maxIntensityRgba] as [number, number, number, number][],
        updateTriggers: {
          getFillColor: [JSON.stringify(visualSettings)],
        },
      });
    });
  } else {
    return new ZoomAwareGradientScatterplotLayer<ParquetRow>({
      id: `${idPrefix}${parquetHeatmapId}`,
      data: parquetFile.rows,
      getPosition: (d: ParquetRow) => [d[0], d[1]],
      pickable: true,

      slideMaxResolution,
      sizeInMicronsByZoomRange: sizeInMicronsByZoomRange,

      // The point radius is scaled based on the zoom level - the higher the zoom level, the higher the point radius, up to the pointRadiusMicronsAtMaxZoom.
      // We don't use the pointRadiusMaxPixels because we can zoom beyond the maximum zoom level of the base layer.
      radiusScale: 10 * (rescaleFactor ? 1 / rescaleFactor : 1),
      radiusUnits: 'meters', // Which really means original slide pixels in this case
      getFillColor: (d: ParquetRow) => {
        const columnValue = d[columnIndex];
        const labelIndex = indexOf(parquetClasses, columnValue);
        const layerSettings = visualSettings[`${parquetHeatmapId}-${columnValue}`];
        const opacity = layerSettings?.selected && layerSettings?.show ? (layerSettings?.opacity || 0) / 100 : 0;
        const color = hexToRgb(
          isHexString(getColorHex(layerSettings?.color))
            ? getColorHex(layerSettings?.color)
            : defaultLayerColors[(labelIndex + size(defaultLayerColors)) % size(defaultLayerColors)]
        );
        return [...color, Math.floor(opacity * 255)];
      },
      stroked: false,
      filled: true,
      updateTriggers: {
        getFillColor: [JSON.stringify(visualSettings)],
      },
      getLineWidth: 0,
      bezierPoints: [0, 1, 1, 1],
    });
  }
};
