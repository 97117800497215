export default `
precision highp float;

in float v_weight;
in vec2  v_extrude;
in float v_classId;  // Has to be a float due to how attributes are passed

uniform float u_intensity;
uniform int   u_activeClass;

out vec4 fragColor;

#define GAUSS_COEF 0.3989422804014327

void main() {
  // If this fragment belongs to a data point whose class != u_activeClass, skip
  // We don't compare directly since v_classId is a float
  if (abs(v_classId - float(u_activeClass)) > 0.5) {
    discard;
  }

  float d2 = dot(v_extrude, v_extrude);
  float val = v_weight * u_intensity * GAUSS_COEF * exp(-0.5 * 3.0 * 3.0 * d2);
  val = clamp(val, 0.0, 1.0);

  // We'll accumulate in .r, alpha=0
  fragColor = vec4(val, 0.0, 0.0, 0.0);
}
`;
