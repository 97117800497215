import { CellColorMapping } from 'interfaces/cellColorMapping';
import { orderBy } from 'lodash';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getCellColorMappingOptions = (signal: AbortSignal) => {
  return apiRequestHandlerPromise<CellColorMapping[]>({
    url: `system/ui_settings/cell_color_mappings`,
    method: 'GET',
    signal,
  });
};

const sortCellColorMappingTags = (cellColorMapping: Partial<CellColorMapping>): Partial<CellColorMapping> => {
  return {
    ...cellColorMapping,
    tags: cellColorMapping.tags ? orderBy(cellColorMapping.tags) : cellColorMapping.tags,
  };
};

export const createCellColorMappingOption = ({
  id: _id,
  ...cellColorMappingFields
}: Omit<CellColorMapping, 'id'> & { id?: number }) => {
  return apiRequestHandlerPromise<CellColorMapping>({
    url: 'system/ui_settings/cell_color_mappings',
    method: 'POST',
    data: JSON.stringify(sortCellColorMappingTags(cellColorMappingFields)),
  });
};

export const updateCellColorMappingOption = ({ id, ...cellColorMappingFields }: Partial<CellColorMapping>) => {
  return apiRequestHandlerPromise({
    url: `system/ui_settings/cell_color_mappings/${id}`,
    method: 'POST',
    data: JSON.stringify(sortCellColorMappingTags(cellColorMappingFields)),
  });
};

export const deleteCellColorMappingOption = (id: string) => {
  return apiRequestHandlerPromise({
    url: `system/ui_settings/cell_color_mappings/${id}`,
    method: 'DELETE',
  });
};
