import { useQuery } from '@tanstack/react-query';
import { getAllTaxonomies, getAllTaxonomiesForPath } from 'api/taxonomy';
import { Taxonomy } from 'interfaces/taxonomy';
import { reduce } from 'lodash';
import { useMemo } from 'react';

export const taxonomyQueryKey = ['taxonomy'];
export const cellTaxonomy = 'tissue.cell';

const useTaxonomy = (parent: string = '') => {
  const query = useQuery<Taxonomy[]>({
    queryKey: parent === '' ? taxonomyQueryKey : [...taxonomyQueryKey, parent],
    queryFn: () => (parent === '' ? getAllTaxonomies() : getAllTaxonomiesForPath(parent)),
  });

  const idToDisplayNameMap = useMemo(() => {
    if (!query.data) return {};
    return reduce(
      query.data,
      (acc, taxonomy) => {
        acc[taxonomy.path] = taxonomy.name;
        return acc;
      },
      {} as Record<string, string>
    );
  }, [query.data]);

  return { ...query, idToDisplayNameMap };
};

export default useTaxonomy;
