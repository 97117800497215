export default `
in vec3 positions;         // local corners of each quad (x,y,z)
in vec2 instancePositions; // center of each data point (x,y)
in float instanceWeights;
in float instanceClassId;

uniform float u_intensity;
uniform float u_extrudeFactor;
uniform float u_falloffDivisor;
uniform int   u_activeClass;
uniform mat4  u_viewProjectionMatrix;

out float v_weight;
out vec2  v_extrude;
out float v_classId;

#define GAUSS_COEFFICIENT 0.3989422804014327
const float ZERO = 1.0 / 255.0 / 16.0;

void main(void) {
    v_classId = instanceClassId;

    float w = instanceWeights;
    // Compute S; using a smaller divisor yields a less steep falloff.
    float S = sqrt(-2.0 * log(ZERO / (w * u_intensity * GAUSS_COEFFICIENT))) / u_falloffDivisor;

    // Alternatively, you could clamp S to avoid very large values:
    // float S_raw = sqrt(-2.0 * log(ZERO / (w * u_intensity * GAUSS_COEFFICIENT)));
    // float S = clamp(S_raw, 0.0, 1.0);

    // The local corners in x,y are in [-1..1]. We can ignore z or keep it as 0.
    // We'll call it "extrude" in the XY plane.
    // Here "positions" is a vec3, but we only use positions.xy to compute the extrude factor
    v_extrude = positions.xy * S;
    v_weight = w;

    vec2 extrude = v_extrude * u_extrudeFactor;
    vec3 finalPos = vec3(
        instancePositions.x + extrude.x,
        instancePositions.y + extrude.y,
        0.0
    );
    gl_Position = u_viewProjectionMatrix * vec4(finalPos, 1.0);
}
`;
