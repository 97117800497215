import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { AnnotationAssignmentAutocomplete } from 'components/atoms/AnnotationAssignmentAutocomplete';
import { MultiplexHistogramConfig, MultiplexHistogramType } from 'interfaces/jobs/multiplex/histogramParams';
import { MULTIPLEX_STAIN_ID } from 'interfaces/stainType';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { CasesParams } from 'utils/useCasesParams';
import * as yup from 'yup';
import { getAssignmentTodosClassNameOptions } from '../../utils';

interface HistogramConfigProps {
  histogramConfig: MultiplexHistogramConfig;
  setHistogramConfig: (histogramConfig: MultiplexHistogramConfig) => void;
  casesParams: CasesParams;
}

const HistogramConfig: React.FC<React.PropsWithChildren<HistogramConfigProps>> = ({
  histogramConfig,
  setHistogramConfig,
  casesParams,
}) => {
  const { maskType, roiAssignmentId, roiAreaClassName, tsmUseArtifactIfAvailable, tsmExclude } = histogramConfig;

  const setMaskType = (newValue: MultiplexHistogramType) => {
    setHistogramConfig({ ...histogramConfig, maskType: newValue });
  };
  const setRoiAssignmentId = (newValue: number) => {
    setHistogramConfig({ ...histogramConfig, roiAssignmentId: newValue, roiAreaClassName: undefined });
  };
  const setRoiAreaClassName = (newValue: string) => {
    setHistogramConfig({ ...histogramConfig, roiAreaClassName: newValue });
  };
  const setTsmUseArtifactIfAvailable = (newValue: boolean) => {
    setHistogramConfig({ ...histogramConfig, tsmUseArtifactIfAvailable: newValue });
  };
  const setTsmExclude = (newValue: boolean) => {
    setHistogramConfig({ ...histogramConfig, tsmExclude: newValue });
  };

  const [roiClassNameOptions, setRoiClassNameOptions] = useState<string[]>([]);
  const isRoiClassNameValid = !isEmpty(roiAreaClassName);
  const roiSelectionErrorMessage =
    maskType === MultiplexHistogramType.Roi ?
      'Required when mask type is roi' :
      maskType === MultiplexHistogramType.Tsm && tsmExclude ?
        'Required when mask type is tsm and exclude manual annotations is checked' :
        undefined;

  return (
    <Grid container direction="column" spacing={2} mb={1}>
      <Grid item container spacing={1} alignItems="start" width={'60%'}>
        <Grid item xs={4}>
          <Select
            size="small"
            value={maskType || MultiplexHistogramType.Tsm}
            onChange={(event) => setMaskType(event.target.value as MultiplexHistogramType)}
          >
            <MenuItem value={MultiplexHistogramType.Full}>{MultiplexHistogramType.Full}</MenuItem>
            <MenuItem value={MultiplexHistogramType.Tsm}>{MultiplexHistogramType.Tsm}</MenuItem>
            <MenuItem value={MultiplexHistogramType.Roi}>{MultiplexHistogramType.Roi}</MenuItem>
          </Select>
        </Grid>
        {/* for entering row */}
        <Grid item xs={8}></Grid>
        {maskType === MultiplexHistogramType.Tsm && (
          <Grid item xs={12}>
            <FormControl component="fieldset"><FormControlLabel
              label="Use Existing Tissue Segmentation Mask if available"
              control={
                <Checkbox
                  checked={tsmUseArtifactIfAvailable || false}
                  onChange={(event) => setTsmUseArtifactIfAvailable(event.target.checked)}
                />
              }
            />
            </FormControl>
            <FormControl component="fieldset"><FormControlLabel
              label="Exclude Manual Annotations (ROI) from Tissue Segmentation Mask"
              control={
                <Checkbox
                  checked={tsmExclude || false}
                  onChange={(event) => setTsmExclude(event.target.checked)}
                />
              }
            />
            </FormControl>
          </Grid>
        )}
        {(maskType === MultiplexHistogramType.Roi || (maskType === MultiplexHistogramType.Tsm && tsmExclude)) && (
          <>
            {maskType === MultiplexHistogramType.Roi && (<Grid item xs={12}>
              <Typography variant="subtitle1">Histogram will be generated based on the following ROIs exclusively</Typography>
            </Grid>)}
            <Grid item xs={6}>
              <AnnotationAssignmentAutocomplete
                casesParams={casesParams}
                slideStainType={MULTIPLEX_STAIN_ID}
                selectedValue={roiAssignmentId ?? null}
                onChange={(event, newValue) => {
                  setRoiAssignmentId(newValue?.annotationAssignmentId);
                  setRoiClassNameOptions(getAssignmentTodosClassNameOptions(newValue?.todos));
                }}
                disableCloseOnSelect
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="ROI Assignment"
                    error={!roiAssignmentId}
                    helperText={!roiAssignmentId ? roiSelectionErrorMessage : undefined}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                value={roiAreaClassName || ''}
                options={roiClassNameOptions}
                onChange={(event, value) => {
                  setRoiAreaClassName(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="ROI Area Class Name"
                    error={!isRoiClassNameValid}
                    helperText={!isRoiClassNameValid ? roiSelectionErrorMessage : undefined}
                  />
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default HistogramConfig;

export const defaultHistogramConfig: MultiplexHistogramConfig = {
  maskType: MultiplexHistogramType.Tsm,
  tsmUseArtifactIfAvailable: true,
  maxWorkers: 16,
};

export const validationHistogramConfig = yup.object({
  maskType: yup.mixed<MultiplexHistogramType>().required().oneOf(Object.values(MultiplexHistogramType)),
  roiAssignmentId: yup.mixed().when('maskType', {
    is: MultiplexHistogramType.Roi,
    then: yup.number().required('Required when maskType is roi'),
  }),
  roiAreaClassName: yup.string().when('maskType', {
    is: MultiplexHistogramType.Roi,
    then: yup.string().required('Required when maskType is roi'),
  }),
});
