import { Viewport } from '@deck.gl/core/typed';
import { DeckGLProps } from '@deck.gl/react/typed';
import { List, ListItemButton, Paper, Popover } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { entries, map } from 'lodash';
import React from 'react';

import HtmlDeckGLOverlay from 'components/HtmlDeckGLOverlay';
import HtmlDeckGLOverlayItem from 'components/HtmlDeckGLOverlay/HtmlDeckGLOverlayItem';
import { RoiFixedSizes } from 'components/Procedure/Header/SlideInteractionMenu/SlideAnnotationTools/options';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { Permission } from 'interfaces/permissionOption';
import { usePermissions } from 'utils/usePermissions';
import { viewerClickData } from '../../viewerDataSignals';
import useAnnotationsForViewer from './useAnnotations';

const DEFAULT_COORDINATES = [0, 0] as [number, number];

export const RoiFixedSizesMenu: React.FC<{
  slide: SlideWithChannelAndResults;
  viewport: Viewport;
  clickInfo: Parameters<DeckGLProps['onClick']>[0];
}> = ({ slide, viewport, clickInfo }) => {
  useSignals();

  const coordinate = clickInfo.coordinate as [number, number];

  const { hasPermission } = usePermissions();
  const canAnnotateSlides = hasPermission(Permission.AnnotateSlides);

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement>(null);

  // allow escape functionality
  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === 'Escape') {
      if (viewerClickData[slide?.viewerIndex]) {
        viewerClickData[slide?.viewerIndex].value = null;
      }
    }
  };

  const { addFixedRoiOnCoordinate } = useAnnotationsForViewer({
    slideId: slide?.id,
    viewerIndex: slide?.viewerIndex,
  });

  const handleAddFixedRoi = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, roiSize: [number, number]) => {
    event.stopPropagation();
    addFixedRoiOnCoordinate(coordinate, roiSize);
    if (viewerClickData[slide?.viewerIndex]) {
      viewerClickData[slide?.viewerIndex].value = null;
    }
  };

  return (
    canAnnotateSlides && (
      <HtmlDeckGLOverlay viewport={viewport}>
        <HtmlDeckGLOverlayItem key="contextMenu" draggable={false} coordinates={coordinate || DEFAULT_COORDINATES}>
          <div ref={(el) => setAnchorEl(el)} />

          <Popover
            id="roiFixedSizesMenu"
            open={Boolean(coordinate) && Boolean(anchorEl)}
            anchorEl={anchorEl}
            disableEscapeKeyDown={false}
            onKeyDown={handleKeyDown}
          >
            <Paper>
              <List>
                {map(entries(RoiFixedSizes), ([roiTitle, roiSize]) => (
                  <ListItemButton onClick={(event) => handleAddFixedRoi(event, roiSize)} tabIndex={0}>
                    {roiTitle}
                  </ListItemButton>
                ))}
              </List>
            </Paper>
          </Popover>
        </HtmlDeckGLOverlayItem>
      </HtmlDeckGLOverlay>
    )
  );
};
