import { CircularProgress, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getJobPresets } from 'api/jobPreset';
import PresetSection from 'components/atoms/PresetSection';
import { InferenceJob, JobType } from 'interfaces/job';
import { JobPreset } from 'interfaces/jobPreset';
import { filter, find, join, map } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import { OldJobsStep } from '../OldJobsStep';

export const LoadParamsStep: React.FC<{
  selectedJobId: string;
  currentPreset: string;
  onSelectPreset: (preset: JobPreset) => void;
  onSelectJob: (job: InferenceJob) => void;
}> = ({ selectedJobId, currentPreset, onSelectPreset, onSelectJob }) => {
  const [loadMode, setLoadMode] = useState<'preset' | 'job'>('preset');

  const { data: presets, isLoading: isLoadingPresets } = useQuery({
    queryKey: ['jobPresets', { steps: ['run_inference'] }],
    queryFn: ({ signal }) => getJobPresets(['run_inference'], signal),
  });

  const { stainTypeIdToDisplayName } = useStainTypeIdToDisplayName();

  return (
    <Grid>
      <Grid item container direction="column" spacing={2} xs={6}>
        <Grid item>
          <RadioGroup
            value={loadMode}
            onChange={(event) => {
              setLoadMode(event.target.value as 'preset' | 'job');
            }}
          >
            <FormControlLabel value="preset" control={<Radio />} label="From Presets" />
            {loadMode === 'preset' && (
              <Grid item>
                <PresetSection
                  selectedPreset={find(presets, { id: currentPreset })}
                  presets={map(
                    filter(presets, (preset) => !preset.deletedBy),
                    (preset) => ({
                      ...preset,
                      displayName: (
                        <Grid container direction="row" spacing={1} alignItems="center">
                          <Grid item>
                            <Typography variant="body1">{preset.name}</Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="body2">
                              {moment(preset.createdAt).format('YYYY-MM-DD HH:mm')}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="caption">
                              (
                              {join(
                                map(preset.stains, (stain) => stainTypeIdToDisplayName(stain)),
                                ', '
                              )}
                              )
                            </Typography>
                          </Grid>
                        </Grid>
                      ),
                    })
                  )}
                  onSelectPreset={onSelectPreset}
                  label={
                    <>
                      Inference presets{' '}
                      {isLoadingPresets && <CircularProgress size={18.5} title="Loading inference presets..." />}
                    </>
                  }
                  isLoading={isLoadingPresets}
                />
              </Grid>
            )}
            <FormControlLabel value="job" control={<Radio />} label="From Existing Job" />
            {loadMode === 'job' && (
              <Grid item>
                <OldJobsStep jobType={JobType.Inference} onSelectedJob={onSelectJob} selectedJobId={selectedJobId} />
              </Grid>
            )}
          </RadioGroup>
        </Grid>
      </Grid>
    </Grid>
  );
};
