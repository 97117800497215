import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Radio,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ColorPicker } from 'components/atoms/ColorPicker';
import { getRenderSlideIdCell } from 'components/atoms/DataGridRenderers/SlideIdCellRenderer';
import { AnnotationAssignment, ClassRemovalAction, TodoOption } from 'interfaces/annotation';
import { filter, find, first, map, size } from 'lodash';
import React, { useMemo, useState } from 'react';
import ClassesToAnnotateRadio from './ClassesToAnnotateRadio';

interface DeleteClassFromAssignmentDialogProps {
  open: boolean;
  removedTodoOption: TodoOption;
  assignment: AnnotationAssignment;
  onCancel: () => void;
  onContinue: (selection: ClassRemovalAction, classToReplaceWith?: TodoOption) => void;
}

const options = [
  {
    id: ClassRemovalAction.keep,
    title: 'Keep Floating',
    description: 'existing annotations will be kept. New annotations could not get this class anymore',
  },
  {
    id: ClassRemovalAction.replace,
    title: 'Replace Class',
    description: 'Replace all existing annotations with another class from the assignment',
  },
  {
    id: ClassRemovalAction.delete,
    title: 'Delete',
    description: 'Delete all annotations with this class',
  },
];

export const DeleteClassFromAssignmentDialog: React.FC<DeleteClassFromAssignmentDialogProps> = ({
  open,
  removedTodoOption,
  assignment,
  onCancel,
  onContinue,
}) => {
  const theme = useTheme();
  const summaryAnnotationsPerSlide: { id: string; publishedCount: number; draftCount: number }[] = useMemo(() => {
    const resultsPerSlide: { id: string; publishedCount: number; draftCount: number }[] = map(
      assignment?.annotations,
      (slideAnnotations) => ({
        id: slideAnnotations.slideId,
        publishedCount: slideAnnotations?.annotationSummary?.[removedTodoOption?.name] ?? 0,
        draftCount: slideAnnotations?.draftAnnotationSummary?.[removedTodoOption?.name] ?? 0,
      })
    );

    return filter(resultsPerSlide, (slide) => slide.publishedCount > 0 || slide.draftCount > 0);
  }, [removedTodoOption, assignment]);

  const columns: GridColDef<any>[] = [
    {
      field: 'id',
      headerName: 'Slide ID',
      width: 350,
      renderCell: getRenderSlideIdCell({
        target: '_blank',
        caseStudyId: assignment.studyId,
        activeAnnotationAssignment: assignment.annotationAssignmentId,
      }),
    },
    {
      field: 'publishedCount',
      headerName: 'published annotations count',
      width: 200,
      type: 'number',
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'draftCount',
      headerName: 'draft annotations count',
      width: 200,
      type: 'number',
      align: 'left',
      headerAlign: 'left',
    },
  ];

  // we need to allow replacing only with same type of todo
  const optionalTodoOptionsForReplacement = filter(first(assignment.todos)?.options, (todoOption) => {
    return todoOption?.className === removedTodoOption?.className && todoOption?.name !== removedTodoOption?.name;
  });

  const [selectedOption, setSelectedOption] = useState(ClassRemovalAction.keep);
  const [selectedClassToReplace, setSelectedClassToReplace] = useState<TodoOption | null>(
    first(optionalTodoOptionsForReplacement) ?? null
  );

  const handleSelectOption = (optionId: ClassRemovalAction) => {
    setSelectedOption(optionId);
  };

  const handleSelectClassToReplace = (todoOption: TodoOption) => {
    setSelectedClassToReplace(todoOption);
  };

  const mustChooseClassToReplace =
    selectedOption === ClassRemovalAction.replace &&
    !find(optionalTodoOptionsForReplacement, { name: selectedClassToReplace?.name });

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiDialogContent-root': {
          padding: 0,
        },
      }}
      maxWidth="xl"
      fullWidth
    >
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        <ColorPicker disableEditing={true} currentColor={removedTodoOption?.color} />
        {`'${removedTodoOption?.displayName}' class is already in use`}
        <IconButton aria-label="close" onClick={onCancel} style={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction="row" spacing={1} padding={2} height={600}>
          <Grid item xs={5} container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="body1">what do you want to do?</Typography>
            </Grid>
            <Grid item container direction="column" spacing={1}>
              {map(options, (option) => (
                <Grid item key={option.id}>
                  <Card
                    variant="outlined"
                    sx={{
                      borderColor: selectedOption === option.id ? theme.palette.primary.main : 'grey.300',
                    }}
                  >
                    <CardActionArea
                      onClick={() => handleSelectOption(option.id)}
                      disabled={
                        size(optionalTodoOptionsForReplacement) === 0 && option.id === ClassRemovalAction.replace
                      }
                    >
                      <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                        <Radio
                          checked={selectedOption === option.id}
                          onChange={() => handleSelectOption(option.id)}
                          value={option.id}
                          name="option-radio"
                          inputProps={{ 'aria-label': option.title }}
                        />
                        <Box ml={2}>
                          <Typography variant="body1">{option.title}</Typography>
                          <Typography variant="body2" color="text.secondary">
                            {option.description}
                          </Typography>
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={true} container direction="column" spacing={1}>
              {selectedOption === ClassRemovalAction.replace && (
                <>
                  <Grid item>
                    <Typography variant="body1">Select a class to replace with</Typography>
                  </Grid>
                  <Grid item xs={true} sx={{ overflow: 'auto' }}>
                    <ClassesToAnnotateRadio
                      classesToAnnotate={optionalTodoOptionsForReplacement}
                      setSelectedClass={handleSelectClassToReplace}
                      selectedClass={selectedClassToReplace}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={7} container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="body1">Summary</Typography>
            </Grid>
            <Grid item xs={true} width="100%">
              <DataGrid
                rows={summaryAnnotationsPerSlide}
                columns={columns}
                slotProps={{
                  pagination: {
                    SelectProps: {
                      sx: { width: 'auto' },
                    },
                  },
                }}
                sx={{ width: '100%', maxHeight: 600 }}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Tooltip title={mustChooseClassToReplace ? 'You must choose a class to replace with' : ''}>
          <span>
            <Button
              onClick={() => onContinue(selectedOption, selectedClassToReplace)}
              color="primary"
              disabled={mustChooseClassToReplace}
            >
              Continue
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};
