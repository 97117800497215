import { SelectProps } from '@mui/material';
import { JobType, ThresholdJob } from 'interfaces/job';
import React, { ReactElement } from 'react';
import JobSelect from './JobSelect';

interface ThresholdJobSelectProps extends Omit<SelectProps, 'onScroll' | 'label'> {
  selectedThresholdJobId: string | null;
  setSelectedThresholdJobId: (jobId: string | null) => void;
  onJobSelect: (job: ThresholdJob) => void;
  autoAssignLastJob?: boolean;
  helperText?: string;
  studyId?: string;
}

export const ThresholdJobSelect = ({
  selectedThresholdJobId,
  setSelectedThresholdJobId,
  onJobSelect,
  autoAssignLastJob,
  helperText,
  studyId,
  ...selectProps
}: ThresholdJobSelectProps): ReactElement => {
  return (
    <JobSelect
      label="Threshold Job"
      jobType={JobType.MultiplexThreshold}
      selectedJobId={selectedThresholdJobId}
      setSelectedJobId={setSelectedThresholdJobId}
      setSelectedJob={onJobSelect}
      errors={{}}
      field="thresholdJob"
      autoAssignLastJob={autoAssignLastJob}
      helperText={helperText}
      studyId={studyId}
      {...selectProps}
    />
  );
};
