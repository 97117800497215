import React, { useEffect } from 'react';

import { Grid, Typography } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { slidesLayerVisualizationSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { forEach, some, values } from 'lodash';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { ViewerHintsProps } from './ViewerHints';

const GeneralHints: React.FunctionComponent<React.PropsWithChildren<ViewerHintsProps>> = ({ selectedSlides }) => {
  useSignals();

  const [hideHeatmaps, setHideHeatmaps] = useQueryParam('hideHeatmaps', BooleanParam);

  useEffect(() => {
    const handleKeyDownForAnnotationsShortcuts = (event: KeyboardEvent) => {
      if (event.key === 'm') {
        setHideHeatmaps(!hideHeatmaps);
      }
    };

    window.addEventListener('keydown', handleKeyDownForAnnotationsShortcuts);

    return () => {
      window.removeEventListener('keydown', handleKeyDownForAnnotationsShortcuts);
    };
  });

  // When hideHeatmapsChanges- update the settings of the current viewers
  useEffect(() => {
    forEach(selectedSlides, (slide) => {
      const currentViewerSettings = slidesLayerVisualizationSettings[slide.viewerIndex].value;
      forEach(values(currentViewerSettings), (slideSettings) => {
        forEach(values(slideSettings), (layerSettings) => {
          layerSettings.value = {
            ...layerSettings.value,
            show: !hideHeatmaps,
          };
        });
      });
      slidesLayerVisualizationSettings[slide.viewerIndex].value = { ...currentViewerSettings };
    });
  }, [hideHeatmaps, selectedSlides]);

  const doViewersHaveShownHeatmaps = some(selectedSlides, (slide) => {
    const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[slide.viewerIndex];

    if (!viewerSlideLayerVisualizationSettings) {
      return false;
    }

    const slideSettings = viewerSlideLayerVisualizationSettings?.value?.[slide.id];
    if (!slideSettings) {
      return false;
    }

    return some(slideSettings, (layerSettings) => layerSettings.value.selected);
  });

  return (
    <>
      {doViewersHaveShownHeatmaps && (
        <Grid item>
          <Typography variant="body2">
            {hideHeatmaps ? 'Press m to show heatmaps' : 'Press m to hide heatmaps'}
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default GeneralHints;
