import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { NamedFeature } from 'interfaces/features';
import { InferredFeatureConfig } from 'interfaces/inferredFeatures';
import { QualityControlLabel } from 'interfaces/qualityControlLabels';
import { Study } from 'interfaces/study';
import { isEmpty, map } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import useStudyMutations from 'utils/useStudyMutations';
import StudySettings from './StudySettings';

interface StudySettingsModalProps {
  study: Study;
  onClose: (event: any) => void;
  open: boolean;
}

const StudySettingsModal: FunctionComponent<React.PropsWithChildren<StudySettingsModalProps>> = ({
  study,
  onClose,
  open,
}) => {
  const [localHighlightedFeatures, setLocalHighlightedFeatures] = useState<NamedFeature[] | undefined>();
  const [localHiddenFeatures, setLocalHiddenFeatures] = useState<NamedFeature[] | undefined>();
  const [localLabels, setLocalLabels] = useState<QualityControlLabel[]>(undefined);
  const [localInferredFeatures, setLocalInferredFeatures] = useState<InferredFeatureConfig[]>(undefined);
  const [updatedSettings, setUpdatedSettings] = useState<Study['settings']>({
    defaultSlideViewMode: 'side-by-side',
    ...(study?.settings || {}),
  });
  const [updatedPlatformSettings, setUpdatedPlatformSettings] = useState<Study['platformSettings']>(undefined);

  const queryClient = useQueryClient();

  const { studyMutation } = useStudyMutations(study, {
    onSuccess: () => {
      setLocalHighlightedFeatures(undefined);
      setLocalHiddenFeatures(undefined);
      setLocalLabels(undefined);
      setLocalInferredFeatures(undefined);
      queryClient.resetQueries({ queryKey: ['procedures'], exact: false });
      queryClient.resetQueries({ queryKey: ['procedure'], exact: false });
    },
  });

  const onSave = () => {
    studyMutation.mutate({
      id: study.id,
      ...(localHighlightedFeatures && {
        highlightedFeatures: map(localHighlightedFeatures, 'key'),
      }),
      ...(localHiddenFeatures && {
        hiddenFeatures: map(localHiddenFeatures, 'key'),
      }),
      settings: { ...study.settings, ...(updatedSettings || {}) },
      ...(updatedPlatformSettings && {
        platformSettings: updatedPlatformSettings,
      }),
      ...(localLabels && {
        qcLabelsConfig: localLabels,
      }),
      ...(localInferredFeatures && {
        inferredFeaturesConfig: localInferredFeatures,
      }),
    });
  };

  return (
    open && (
      <Dialog
        open={open}
        scroll="paper"
        maxWidth="lg"
        onKeyDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogContent dividers>
          <DialogTitle variant="h1" style={{ display: 'flex', justifyContent: 'space-between' }}>
            {study.name}
            <IconButton onClick={onClose} size="medium">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <StudySettings
            study={study}
            setUpdatedSettings={setUpdatedSettings}
            updatedSettings={updatedSettings}
            updatedPlatformSettings={updatedPlatformSettings}
            setUpdatedPlatformSettings={setUpdatedPlatformSettings}
            localHighlightedFeatures={localHighlightedFeatures}
            setLocalHighlightedFeatures={setLocalHighlightedFeatures}
            localHiddenFeatures={localHiddenFeatures}
            setLocalHiddenFeatures={setLocalHiddenFeatures}
            localLabels={localLabels}
            setLocalLabels={setLocalLabels}
            localInferredFeatures={localInferredFeatures}
            setLocalInferredFeatures={setLocalInferredFeatures}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={(!localHighlightedFeatures && isEmpty(updatedSettings)) || studyMutation.isLoading}
            color="primary"
            onClick={onSave}
          >
            {studyMutation.isLoading ? 'Saving...' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

export default StudySettingsModal;
