import { QueryFunctionContext, useQueries } from '@tanstack/react-query';
import { fromPairs, join, map, some } from 'lodash';
import { useMemo } from 'react';

import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const useS3UrlToPresignedUrlMap = (
  queries: Array<{
    url: string;
    fileName: string;
  }>
) => {
  const presignedUrlQueries = useQueries({
    queries: map(queries, ({ url, fileName }) => ({
      queryKey: ['presigdUrl', url, fileName],
      queryFn: async ({ signal }: QueryFunctionContext) => {
        return apiRequestHandlerPromise<string>({
          url: `presignUrl`,
          method: 'POST',
          signal,
          data: JSON.stringify({ url, fileName }),
        });
      },
      enabled: Boolean(url) && Boolean(fileName),
    })),
  });

  const s3UrlToPresignedUrlMap = useMemo(
    () => fromPairs(map(queries, (presignedUrl, index) => [presignedUrl.url, presignedUrlQueries[index]])),
    [queries, join(map(presignedUrlQueries, 'dataUpdatedAt'))]
  );

  const isLoading = some(map(presignedUrlQueries, 'isLoading'));

  return { s3UrlToPresignedUrlMap, isLoading };
};
