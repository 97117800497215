import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { IconButton, useTheme } from '@mui/material';
import { isNumber } from 'lodash';
import React from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { SLIDES_THUMBNAILS_PIXEL_HEIGHT } from '../CaseView';

export const ToggleRegistrationButton: React.FC<{
  marginLeft?: string | number;
  marginRight?: string | number;
}> = ({ marginLeft, marginRight }) => {
  const theme = useTheme();
  const [slidesThumbnailsDrawerOpen] = useQueryParam('slidesThumbnailsDrawerOpen', BooleanParam);
  const marginBottom = slidesThumbnailsDrawerOpen ? SLIDES_THUMBNAILS_PIXEL_HEIGHT : 0;
  const [ignoreRegistrationActive, setIgnoreRegistrationActive] = useQueryParam(
    'ignoreRegistrationActive',
    BooleanParam
  );
  const lockedColor = theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900];
  const lockedHoverColor = theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800];
  const unlockedColor = theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[600];
  const unlockedHoverColor = theme.palette.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[500];

  return (
    <IconButton
      size="small"
      onClick={() => setIgnoreRegistrationActive((currentValue) => !currentValue)}
      sx={{
        elevation: 2,
        backgroundColor: ignoreRegistrationActive ? unlockedColor : lockedColor,
        position: 'absolute',
        bottom: `calc(44px${marginBottom ? ` + ${marginBottom}${isNumber(marginBottom) ? 'px' : ''}` : ''})`,
        left: `calc(50vw${marginLeft ? ` + ${marginLeft}${isNumber(marginLeft) ? 'px' : ''} / 2` : ''}${
          marginRight ? ` - ${marginRight}${isNumber(marginRight) ? 'px' : ''} / 2` : ''
        })`,
        transform: 'translateX(-50%)',

        '&:hover': {
          backgroundColor: ignoreRegistrationActive ? unlockedHoverColor : lockedHoverColor,
        },
      }}
      aria-label="Toggle registration"
    >
      {ignoreRegistrationActive ? <LockOpenIcon fontSize="inherit" /> : <LockIcon fontSize="inherit" />}
    </IconButton>
  );
};
