import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import AdjustIcon from '@mui/icons-material/Adjust';
import BorderStyleIcon from '@mui/icons-material/BorderStyle';
import CloseIcon from '@mui/icons-material/Close';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import EditIcon from '@mui/icons-material/Edit';
import PentagonOutlinedIcon from '@mui/icons-material/PentagonOutlined';
import UndoIcon from '@mui/icons-material/Undo';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  Checkbox,
  darken,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Tooltip,
} from '@mui/material';
import { ColorPicker } from 'components/atoms/ColorPicker';
import { AnnotationClassRemovalOperation, TodoOption } from 'interfaces/annotation';
import { concat, every, find, findIndex, forEach, includes, isEmpty, map, some, times } from 'lodash';
import React from 'react';

interface SelectProps {
  isTodoSelected: (id: string) => boolean;
  selectedClassesToAnnotate: TodoOption[];
  onClassesSelected: (classes: TodoOption[]) => void;
}

interface ClassesToAnnotateListProps {
  classesToAnnotate: TodoOption[];
  setClassesToAnnotate?: (classes: TodoOption[]) => void;
  onDeleteTodo?: (id: string) => void;
  onColorChange?: (id: string, color: string) => void;
  isSelectable?: boolean;
  selectProps?: SelectProps;
  isEditable?: boolean;
  showWarningOnItem?: (item: TodoOption) => boolean;
  warningMessage?: string;
  classesToRemoveWithActions?: AnnotationClassRemovalOperation[];
  onEditDeleted?: (id: string) => void;
  onUndoDelete?: (id: string) => void;
}

const ClassesToAnnotateList: React.FC<ClassesToAnnotateListProps> = ({
  classesToAnnotate,
  setClassesToAnnotate,
  onDeleteTodo,
  onColorChange,
  isSelectable,
  selectProps,
  isEditable,
  showWarningOnItem,
  warningMessage,
  classesToRemoveWithActions,
  onEditDeleted,
  onUndoDelete,
}) => {
  if (isEditable && !onDeleteTodo) {
    console.warn(
      'ClassesToAnnotateList: onDeleteTodo is not defined but isEditable is true- (not showing delete button)'
    );
  }
  if (isEditable && !onColorChange) {
    console.warn(
      'ClassesToAnnotateList: onColorChange is not defined but isEditable is true- (not allowing to edit color)'
    );
  }
  if (isSelectable && !selectProps) {
    console.warn('ClassesToAnnotateList: selectProps is not defined but isSelectable is true- (not showing checkbox)');
  }

  const allowSelect = isSelectable && Boolean(selectProps);
  const allSelected =
    allowSelect && every(classesToAnnotate, (classToAnnotate) => selectProps.isTodoSelected(classToAnnotate.name));
  const someSelected =
    allowSelect &&
    !allSelected &&
    some(classesToAnnotate, (classToAnnotate) => selectProps.isTodoSelected(classToAnnotate.name));

  const toggleSelection = selectProps
    ? (ids: string[], checked: boolean) => {
        const newSelectedClassesToAnnotate = selectProps.selectedClassesToAnnotate || [];
        forEach(ids, (id) => {
          const idSelected = includes(map(selectProps.selectedClassesToAnnotate, 'name'), id);
          if (checked) {
            if (!idSelected) newSelectedClassesToAnnotate.push(find(classesToAnnotate, { name: id }));
          } else {
            newSelectedClassesToAnnotate.splice(
              newSelectedClassesToAnnotate.indexOf(find(newSelectedClassesToAnnotate, { name: id })),
              1
            );
          }
        });
        selectProps.onClassesSelected(newSelectedClassesToAnnotate);
      }
    : undefined;

  const checkAll = (checked: boolean) => {
    toggleSelection(map(classesToAnnotate, 'name'), checked);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active?.id !== over?.id) {
      const activeIndex = findIndex(classesToAnnotate, (item) => item.name === active?.id);
      const overIndex = findIndex(classesToAnnotate, (item) => item.name === over?.id);
      const newClasses = arrayMove(classesToAnnotate, activeIndex, overIndex);
      setClassesToAnnotate(newClasses);
    }
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext
        items={map(classesToAnnotate, (item) => ({ ...item, id: item.name }))}
        strategy={verticalListSortingStrategy}
      >
        <List>
          {!isEmpty(classesToAnnotate) && allowSelect && (
            <ListItem>
              <ListItemIcon sx={{ minWidth: '40px' }}>
                <Checkbox
                  checked={allSelected}
                  indeterminate={someSelected}
                  onClick={() => {
                    if (allSelected) {
                      checkAll(false);
                    } else {
                      checkAll(true);
                    }
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={'Select All'} />
            </ListItem>
          )}

          {map(concat(classesToAnnotate, map(classesToRemoveWithActions, 'classToRemove')), (classToAnnotate) => (
            <ClassToAnnotateListItem
              key={classToAnnotate.name}
              item={classToAnnotate}
              onDelete={isEditable && Boolean(onDeleteTodo) ? () => onDeleteTodo(classToAnnotate.name) : undefined}
              onColorChange={
                isEditable && Boolean(onColorChange)
                  ? (newColor) => onColorChange(classToAnnotate.name, newColor)
                  : undefined
              }
              isSelectable={allowSelect}
              isSelected={selectProps?.isTodoSelected && selectProps.isTodoSelected(classToAnnotate.name)}
              toggleSelection={
                toggleSelection ? (checked) => toggleSelection([classToAnnotate.name], checked) : undefined
              }
              isEditable={isEditable}
              isDraggable={Boolean(setClassesToAnnotate)}
              showWarning={showWarningOnItem && showWarningOnItem(classToAnnotate)}
              warningMessage={warningMessage}
              isDeleted={some(
                map(classesToRemoveWithActions, 'classToRemove'),
                (classToRemove) => classToRemove.name === classToAnnotate.name
              )}
              onEditDeleted={() => onEditDeleted(classToAnnotate.name)}
              onUndoDelete={() => onUndoDelete(classToAnnotate.name)}
            />
          ))}
        </List>
      </SortableContext>
    </DndContext>
  );
};

interface ClassToAnnotateListItemProps {
  item: TodoOption;
  onColorChange?: (color: string) => void;
  onDelete?: () => void;
  isSelectable?: boolean;
  isSelected?: boolean;
  toggleSelection?: (checked: boolean) => void;
  isEditable?: boolean;
  isDraggable?: boolean;
  showWarning?: boolean;
  warningMessage?: string;
  isDeleted?: boolean;
  onEditDeleted?: () => void;
  onUndoDelete?: () => void;
}

const ClassToAnnotateListItem: React.FC<ClassToAnnotateListItemProps> = ({
  item,
  onColorChange,
  onDelete,
  isSelectable,
  isSelected,
  toggleSelection,
  isEditable,
  isDraggable = false,
  showWarning,
  warningMessage,
  isDeleted,
  onEditDeleted,
  onUndoDelete,
}) => {
  const { className, color, displayName, name } = item;

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: name });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  const handleColorChange = (newColor: string) => {
    onColorChange(newColor);
  };

  return (
    <ListItem
      style={style}
      secondaryAction={
        isEditable && (
          <IconButton edge="end" onClick={() => onDelete()} disabled={isDeleted}>
            <CloseIcon />
          </IconButton>
        )
      }
    >
      {isDraggable && (
        <ListItemIcon
          sx={{
            cursor: !isDeleted ? (isDragging ? 'grabbing' : 'grab') : 'inherit',
            minWidth: '40px',
            color: isDeleted ? 'grey' : 'inherit',
          }}
          {...(!isDeleted ? listeners : {})}
          {...(!isDeleted ? attributes : {})}
          ref={setNodeRef}
        >
          <DragHandleIcon />
        </ListItemIcon>
      )}
      {isSelectable && Boolean(toggleSelection) && (
        <ListItemIcon sx={{ minWidth: '40px' }}>
          <Checkbox checked={isSelected} onChange={(e) => toggleSelection(e.target.checked)} disabled={isDeleted} />
        </ListItemIcon>
      )}

      {/* the default minWidth is 56px witch is too big for this case */}
      <ListItemIcon sx={{ minWidth: '40px' }}>
        <ColorPicker
          disableEditing={!isEditable || !handleColorChange || isDeleted}
          currentColor={isDeleted ? darken(color, 0.5) : color}
          onColorChange={handleColorChange}
        />
      </ListItemIcon>
      <ListItemIcon sx={{ minWidth: '40px', color: isDeleted ? 'grey' : 'inherit' }}>
        {className === 'polygon' ? (
          name.includes('roi') ? (
            <BorderStyleIcon />
          ) : (
            <PentagonOutlinedIcon />
          )
        ) : (
          <AdjustIcon />
        )}
      </ListItemIcon>
      <ListItemText
        primary={displayName ?? name}
        primaryTypographyProps={{
          variant: 'subtitle2',
          style: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: isDeleted ? 'grey' : 'inherit',
          },
        }}
        secondary={`${map(item.markerPositivity, (value, key) => `${key}-${value}`).join(', ')}`}
      />
      {showWarning && (
        <Tooltip title={warningMessage}>
          <IconButton size="small">
            <WarningAmberIcon />
          </IconButton>
        </Tooltip>
      )}
      {isDeleted && onEditDeleted && (
        <Tooltip title="Edit deleted action">
          <IconButton size="small" onClick={onEditDeleted}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
      {isDeleted && onUndoDelete && (
        <Tooltip title="Undo delete">
          <IconButton size="small" onClick={onUndoDelete}>
            <UndoIcon />
          </IconButton>
        </Tooltip>
      )}
    </ListItem>
  );
};

export const ClassesToAnnotateListSkeleton: React.FC = () => {
  return (
    <List>
      {times(5, (index) => (
        <ListItem key={index}>
          <ListItemIcon sx={{ minWidth: '40px' }}>
            <Skeleton variant="rounded" width={15} height={15} />
          </ListItemIcon>
          <ListItemIcon sx={{ minWidth: '40px' }}>
            <Skeleton variant="rounded" width={15} height={15} />
          </ListItemIcon>
          <Skeleton variant="text" width={300} />
        </ListItem>
      ))}
    </List>
  );
};

export default ClassesToAnnotateList;
