import { LayerProps } from '@deck.gl/core/typed';
// @ts-ignore
import { Framebuffer, Model } from '@luma.gl/core';

/**
 * Strategy for rendering the color.
 */
export enum ColorStrategy {
  // will take the color of the class with the highest density at a point
  MaxDensity = 'maxDensity',
  // will sum the r, g, b, a values from all classes visible at a point
  Sum = 'sum',
  // will take the mean  r, g, b, a values from all classes visible at a point
  Mean = 'mean',
}

// Props for our multi-class heatmap layer
export interface MultiClassGaussianHeatmapLayerProps<DataT> extends LayerProps {
  /** Array of data objects */
  data: DataT[];

  /**
   * Returns [x, y] in your coordinate system (e.g. image coords, lat/lng, etc.)
   */
  getPosition?: (d: DataT) => [number, number];

  /**
   * Returns a numeric weight for each data point
   */
  getWeight?: (d: DataT) => number;

  /**
   * Returns the class index (0..N-1) for each data point
   */
  getClassIndex?: (d: DataT) => number;

  /**
   * Number of distinct classes we will render.
   * The aggregator will do one pass per class.
   */
  numClasses: number;

  /**
   * RGB Color for each class, e.g. [[255, 0, 0], [0, 255, 0], ...]
   */
  classColors?: Array<[number, number, number]>; // RGBA

  /**
   * Opacity for each class, e.g. [0.5, 0.5, ...]
   */
  classOpacities?: number[];

  /**
   * Radius in screen pixels (if you’re using orthographic, etc.).
   * Or in world units if you’re using a standard map.
   */
  radius?: number;

  /**
   * After calculating the density, we divide by this value.
   */
  falloffDivisor?: number;

  /**
   * Intensity multiplier for the Gaussian aggregator.
   */
  intensity?: number;

  /**
   * Opacity for the final blended image.
   */
  opacity?: number;

  /**
   * The strategy for rendering the color.
   */
  colorStrategy?: ColorStrategy;
}

// Internal state
export interface MultiClassGaussianHeatmapLayerState {
  aggregatorModel?: Model; // used to draw quads around points
  colorModel?: Model; // used to composite final colors
  classAggregators?: Framebuffer[]; // one FBO (Framebuffer object) per class
  classColorsUniform?: number[]; // flattened class colors
  classOpacitiesUniform?: Float32Array; // final opacities
}
