import { Checkbox, FormControlLabel, Grid, Input, Slider, Typography } from '@mui/material';
import {
  NormalizationConfig,
  PrecisionSpecificNormalizationParams,
} from 'interfaces/jobs/multiplex/normalizationParams';
import React from 'react';
import { MAX_UINT16 } from 'utils/constants';
import PrecisionSpecificForm from './PrecisionSpecificParamsForm';

interface NormalizationParamsFormProps {
  normalizationConfig: NormalizationConfig;
  setNormalizationConfig: (normalizationConfig: NormalizationConfig) => void;
  show8bitParams?: boolean;
  show16bitParams?: boolean;
}

const NormalizationParamsForm: React.FC<React.PropsWithChildren<NormalizationParamsFormProps>> = ({
  normalizationConfig,
  setNormalizationConfig,
  show8bitParams = true,
  show16bitParams = true,
}) => {
  const {
    isLowDynamicRange,
    isHighAbundance,
    params8bit: precisionSpecificNormalization8bitParams,
    params16bit: precisionSpecificNormalization16bitParams,
    maxCPercentile,
  } = normalizationConfig;
  const setIsLowDynamicRange = (newIsLowDynamicRange: boolean) => {
    setNormalizationConfig({ ...normalizationConfig, isLowDynamicRange: newIsLowDynamicRange });
  };
  const setIsHighAbundance = (newIsHighAbundance: boolean) => {
    setNormalizationConfig({ ...normalizationConfig, isHighAbundance: newIsHighAbundance });
  };
  const setPrecisionSpecificNormalization8bitParams = (
    newPrecisionSpecificNormalization8bitParams: PrecisionSpecificNormalizationParams
  ) => {
    setNormalizationConfig({ ...normalizationConfig, params8bit: newPrecisionSpecificNormalization8bitParams });
  };
  const setPrecisionSpecificNormalization16bitParams = (
    newPrecisionSpecificNormalization16bitParams: PrecisionSpecificNormalizationParams
  ) => {
    setNormalizationConfig({ ...normalizationConfig, params16bit: newPrecisionSpecificNormalization16bitParams });
  };
  const setMaxCPercentile = (newMaxCPercentile: number) => {
    setNormalizationConfig({ ...normalizationConfig, maxCPercentile: newMaxCPercentile / 100 });
  };

  const hasError = isLowDynamicRange && isHighAbundance;
  const errorMessage = hasError ? 'Cannot be both Low Dynamic Range and High Abundance' : '';

  return (
    <Grid container direction="column" spacing={2} mb={1}>
      {hasError && (
        <Grid item>
          <Typography color="error">{errorMessage}</Typography>
        </Grid>
      )}
      <Grid item container spacing={1} alignItems="center">
        <Grid item>
          <FormControlLabel
            label="Is Low Dynamic Range"
            control={
              <Checkbox
                checked={isLowDynamicRange || false}
                onChange={(event) => setIsLowDynamicRange(event.target.checked)}
              />
            }
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            label="Is High Abundance"
            control={
              <Checkbox
                checked={isHighAbundance || false}
                onChange={(event) => setIsHighAbundance(event.target.checked)}
              />
            }
          />
        </Grid>
      </Grid>

      {(show8bitParams || show16bitParams) && (
        <Grid item xs={12} container spacing={2}>
          {show8bitParams && (
            <Grid item width={400} alignItems="center">
              <PrecisionSpecificForm
                label={'Precision Specific Params 8bit'}
                precisionSpecificNormalizationParams={
                  precisionSpecificNormalization8bitParams ?? ({} as PrecisionSpecificNormalizationParams)
                }
                setPrecisionSpecificNormalizationParams={setPrecisionSpecificNormalization8bitParams}
              />
            </Grid>
          )}
          {show16bitParams && (
            <Grid item width={400} alignItems="center">
              <PrecisionSpecificForm
                label={'Precision Specific Params 16bit'}
                maxValue={MAX_UINT16}
                precisionSpecificNormalizationParams={
                  precisionSpecificNormalization16bitParams ?? ({} as PrecisionSpecificNormalizationParams)
                }
                setPrecisionSpecificNormalizationParams={setPrecisionSpecificNormalization16bitParams}
              />
            </Grid>
          )}
        </Grid>
      )}

      <Grid item container direction="row" spacing={2} alignItems="center">
        <Grid item>
          <Typography variant="h6">Max C Percentile</Typography>
        </Grid>
        <Grid item>
          <Slider
            sx={{ width: 200 }}
            value={(maxCPercentile ?? 0) * 100}
            max={100}
            step={1}
            onChange={(event: Event, newValue: number) => setMaxCPercentile(newValue)}
            valueLabelDisplay="auto"
          />
        </Grid>
        <Grid item>
          <Input
            value={(maxCPercentile ?? 0) * 100}
            size="small"
            onChange={(event) => {
              const newValue = event.target.value ? Number(event.target.value) : undefined;
              setMaxCPercentile(newValue);
            }}
            onBlur={() => {
              if (maxCPercentile < 0) {
                setMaxCPercentile(0);
              }
              if (maxCPercentile > 100) {
                setMaxCPercentile(100);
              }
            }}
            inputProps={{
              step: 1,
              min: 0,
              max: 100,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NormalizationParamsForm;
