const maxDensityColorStrategy = (numClasses) => `
vec4 selectedColor = vec4(0.0);

  float lastMaxDensity = 0.0;

  // Accumulate intensities from each class
  for (int c = 0; c < ${numClasses}; c++) {
    float classOpacity = u_classOpacities[c] * 255.0;
    if (classOpacity == 0.0) {
      continue;
    }
    float density = texture(u_textures[c], vTexCoord).r;
    density = clamp(density, 0.0, 1.0);
    if (density == 0.0) {
      continue;
    }

    if (density > lastMaxDensity) {
      lastMaxDensity = density;
      // Multiply by the user-defined color for that class
      selectedColor = vec4(u_classColors[c], classOpacity * density) / 255.0;
    }
  }

  fragColor = selectedColor;
`;

const sumColorStrategy = (numClasses) => `
  vec4 sumColor = vec4(0.0);

  // Accumulate intensities from each class
  for (int c = 0; c < ${numClasses}; c++) {
    float classOpacity = u_classOpacities[c] * 255.0;
    if (classOpacity == 0.0) {
      continue;
    }
    float density = texture(u_textures[c], vTexCoord).r;
    density = clamp(density, 0.0, 1.0);
    if (density == 0.0) {
      continue;
    }
    // Multiply by the user-defined color for that class
    vec4 classColor = vec4(u_classColors[c], classOpacity) / 255.0;
    sumColor += classColor * density;
  }

  fragColor = sumColor;
`;

const meanColorStrategy = (numClasses) => `
  vec4 sumColor = vec4(0.0);

  int numClassesVisible = 0;

  // Accumulate intensities from each class
  for (int c = 0; c < ${numClasses}; c++) {
    float classOpacity = u_classOpacities[c] * 255.0;
    if (classOpacity == 0.0) {
      continue;
    }
    float density = texture(u_textures[c], vTexCoord).r;
    density = clamp(density, 0.0, 1.0);
    if (density == 0.0) {
      continue;
    }
    numClassesVisible += 1;

    // Multiply by the user-defined color for that class
    vec4 classColor = vec4(u_classColors[c], classOpacity) / 255.0;
    sumColor += classColor * density;
  }

  fragColor = sumColor / float(numClassesVisible);
`;

/**
 * @typedef {import('../types.ts').ColorStrategy} ColorStrategy
 */

/**
 * Fragment shader for the OrthographicGaussHeatmapLayer.
 * This shader renders the color of the heatmap by accumulating the intensities
 * of each class and multiplying them by the user-defined color for that class.
 * The final color is then multiplied by the opacity.
 * The number of classes is passed when generating the shader.
 * @param {number} numClasses - The number of classes to render.
 * @param {ColorStrategy} colorStrategy - The color strategy to use.
 * @returns {string} The fragment shader.
 */
export default (numClasses, colorStrategy) => `
precision highp float;

in vec2 vTexCoord;
out vec4 fragColor;

int numClasses = ${numClasses};

uniform float     u_classOpacities[${numClasses}];
uniform sampler2D u_textures[${numClasses}];
uniform vec3      u_classColors[${numClasses}];

void main() {
  ${
    colorStrategy === 'sum'
      ? sumColorStrategy(numClasses)
      : colorStrategy === 'mean'
      ? meanColorStrategy(numClasses)
      : maxDensityColorStrategy(numClasses)
  }
}
`;
