import { Grid } from '@mui/material';
import React from 'react';

import CopyableText from 'components/atoms/CopyableText';

interface Props {
  slideId: string;
}

export const SlideIdOverlay: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ slideId }) => {
  return (
    <Grid
      item
      container
      alignContent="space-between"
      p={0}
      sx={{
        minWidth: 140,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        borderRadius: 2,
        color: '#fff',
      }}
    >
      <Grid item pl={1} pr={1} justifySelf="flex-start">
        <CopyableText variant="caption" text={slideId} sx={{ fontSize: '10px', display: 'block', cursor: 'pointer' }} />
      </Grid>
    </Grid>
  );
};

export default SlideIdOverlay;
