import { Autocomplete, AutocompleteProps, Box, Chip, SxProps, TextField } from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import deepEqual from 'deep-equal';
import { filter, findIndex, isArray, isEmpty, map } from 'lodash';
import React, { ReactNode } from 'react';

export const autocompleteCellEditorSxOverrides: SxProps = {
  // Remove borders from the input
  '& .MuiAutocomplete-inputRoot fieldset': {
    border: 'none',
  },
  // Stop the input element from starting a new line when empty
  '& .MuiAutocomplete-inputRoot .MuiInputBase-input[value=""]': {
    minWidth: 0,
  },
  // Fill the whole cell height with the input
  '&, & .MuiFormControl-root, & .MuiAutocomplete-inputRoot': {
    height: '100%',
  },
  // Align the tags to the top
  '& .MuiInputBase-root': {
    alignContent: 'flex-start',
    padding: 1,
  },
};
interface AutocompleteEditInputCellProps<
  T,
  Multiple extends boolean = undefined,
  DisableClearable extends boolean = undefined,
  FreeSolo extends boolean = undefined,
  V = T
> extends Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> {
  params: GridRenderEditCellParams<any, any, string>;
  fixedOptions?: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>['options'];
  renderInput?: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>['renderInput'];
  getValueFromSelection?: (selection: T) => V;
  maxSelections?: Multiple extends true ? number : undefined;
  autoFocusCell?: boolean;
  hideDropdownIndicator?: boolean;
}

export function AutocompleteEditInputCell<
  T,
  Multiple extends boolean = undefined,
  DisableClearable extends boolean = undefined,
  FreeSolo extends boolean = undefined,
  V = T
>({
  autoFocusCell,
  maxSelections,
  getValueFromSelection,
  hideDropdownIndicator,
  fixedOptions,
  params,
  ...props
}: AutocompleteEditInputCellProps<T, Multiple, DisableClearable, FreeSolo, V>) {
  const [didInteract, setDidInteract] = React.useState(false);
  const apiRef = useGridApiContext();

  const normalizedValue: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>['value'] = React.useMemo(
    () => (props.multiple ? (isEmpty(params.value) || !isArray(params.value) ? [] : params.value) : params.value) ?? '',
    [props.multiple, params.value]
  );

  const handleChange: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>['onChange'] = async (
    event,
    newValue,
    reason
  ) => {
    event.stopPropagation();
    if (
      props.multiple &&
      reason !== 'clear' &&
      reason !== 'removeOption' &&
      maxSelections &&
      isArray(newValue) &&
      newValue.length > maxSelections
    ) {
      return;
    }
    const filteredValue = (
      props.multiple
        ? isArray(newValue)
          ? filter(newValue, (v) => 0 > findIndex(fixedOptions, (o) => deepEqual(v, o)))
          : []
        : newValue
    ) as AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>['value'];
    const newNormalizedValue = getValueFromSelection
      ? props.multiple
        ? map(filteredValue as T[], getValueFromSelection)
        : getValueFromSelection(filteredValue as T)
      : filteredValue;

    await apiRef.current.setEditCellValue({
      id: params.id,
      field: params.field,
      value: newNormalizedValue,
    });

    if (!didInteract) {
      setDidInteract(true);
    }
  };

  const getOptionLabel = React.useCallback(
    props.getOptionLabel ??
      ((option: T) => `${typeof option === 'string' ? option : ((option as any)?.label || option) ?? ''}`),
    [props.getOptionLabel]
  );

  return (
    <Autocomplete
      sx={autocompleteCellEditorSxOverrides}
      // Open the popup on focus, but not when a row editing is started (which focuses all the cells, without blur)
      openOnFocus={didInteract}
      renderInput={(inputParams) => <TextField autoFocus={autoFocusCell} error={params.error} {...inputParams} />}
      {...(hideDropdownIndicator
        ? {
            componentsProps: {
              // Hide the dropdown indicator from the input
              popupIndicator: { style: { display: 'none' } },
            },
          }
        : {})}
      renderTags={
        fixedOptions
          ? (tagValue, getTagProps) => (
              <Box
                sx={{
                  maxHeight: 150,
                  overflow: 'auto',
                }}
              >
                {map(tagValue, (option, index) => (
                  <Chip
                    key={option}
                    label={getOptionLabel ? getOptionLabel(option) : (option as ReactNode)}
                    {...getTagProps({ index })}
                    disabled={findIndex(fixedOptions, (fixed) => deepEqual(option, fixed)) !== -1}
                  />
                ))}
              </Box>
            )
          : undefined
      }
      fullWidth
      value={normalizedValue}
      onChange={handleChange}
      onInputChange={() => {
        if (!didInteract) {
          setDidInteract(true);
        }
      }}
      onClick={() => setDidInteract(true)}
      options={props.options ?? []}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      {...props}
      limitTags={props.limitTags ?? maxSelections}
    />
  );
}
