import { Color, Layer, LayersList } from '@deck.gl/core/typed';
import { BitmapLayer, PolygonLayer, TextLayer } from '@deck.gl/layers/typed';
import GL from '@luma.gl/constants';
import {
  BoundingBox,
  MultiScaleImageLayerProps,
  PhotometricInterpretation,
} from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/StainsLayers/types';
import { DecodedPng } from 'fast-png';
import { castArray, compact, first, isEmpty, isNumber, map, min, size, slice, some } from 'lodash';
import CubicDeconvExtension from '../../extensions/bitmapLayer/CubicDeconvExtension';
import ImageControlsExtension from '../../extensions/bitmapLayer/ImageControlsExtension';
import LinearDeconvExtension from '../../extensions/bitmapLayer/LinearDeconvExtension';
import { getTransparentColor, isInterleaved } from '../utils';
import XRLayer, { XRLayerProps } from '../xr-layer/xr-layer';
import {
  MultiScaleImageData,
  MultiScaleImageIndex,
  getBoundsForDeepZoomTile,
  getBoundsForDeepZoomTileWithOverlapPixels,
} from './utils';

const debugColor: [number, number, number, number] = [255, 255, 0, 50];
const debugErrorColor: [number, number, number, number] = [255, 0, 0, 50];

/**
 * Generate debug layers for the PMTiles layer.
 * This function will generate a text layer with the tile's coordinates and the
 * number of features found in the tile,
 * @param options The options for the function.
 * @param options.id The id of the tile layer.
 * @param options.maxLevel The maximum zoom level of the base layer.
 * @param options.tileCoordinates The tile coordinates of the tile.
 * @param options.boundingBox The bounding box of the tile.
 * @param options.numFeatures The number of features found in the tile.
 * @returns The debug layers.
 */
const generateDebugLayers = ({
  id,
  tileCoordinates,
  boundingBox,
  maxZoom,
  isBackground,
  error,
}: {
  id: string;
  tileCoordinates: { x: number; y: number; z: number };
  boundingBox: [number[], number[]];
  maxZoom: number;
  isBackground?: boolean;
  error?: boolean;
}) => {
  const topLeft = boundingBox[0];
  const bottomRight = boundingBox[1];
  const { x, y, z } = tileCoordinates;

  const polygon = [
    [topLeft[0], topLeft[1]],
    [topLeft[0], bottomRight[1]],
    [bottomRight[0], bottomRight[1]],
    [bottomRight[0], topLeft[1]],
  ];

  return [
    // Text layer with the tiles' coordinates.
    new TextLayer({
      id: `${id}-text`,
      data: [
        {
          text: `Tile ${x}, ${y}, ${z}${isBackground ? ' (Background)' : ''}\nZoom: ${maxZoom + z}`,
          // Get the center of the tile.
          position: [(topLeft[0] + bottomRight[0]) / 2, (topLeft[1] + bottomRight[1]) / 2],
          size: 16,
          color: slice(error ? debugErrorColor : debugColor, 0, 3),
        },
      ],
      getPosition: (dataEntry) => dataEntry.position,
      getText: (dataEntry) => dataEntry.text,
      getSize: (dataEntry) => dataEntry.size,
      getColor: (dataEntry) => dataEntry.color,
      sizeScale: 1,
    }),

    new PolygonLayer({
      id: `${id}-bounding-box`,
      data: [{ polygon }],
      getPolygon: (dataEntry) => dataEntry.polygon,
      getFillColor: [0, 0, 0, 0],
      getLineColor: error ? debugErrorColor : debugColor,
      lineWidthScale: 1,
      getLineWidth: 1,
      sizeUnits: 'meters', // Which really means original slide pixels in this case
    }),
  ];
};

export interface TileProps {
  index: MultiScaleImageIndex;
  bbox: BoundingBox;
  isVisible?: boolean;
}

interface RenderBitmapBinarySubLayersProps<S extends string[]>
  extends Omit<MultiScaleImageLayerProps<S>, 'data' | 'selections'> {
  id: string;
  data: MultiScaleImageData;
  tile: TileProps;
  layersVisible: boolean[] | boolean;
  contrastLimits: [begin: number, end: number][];
  layerOpacities: number[] | number;
  transparentColor: Color;
  colors: Color[];
  selections: Array<Record<string, number>>;
  tileSize: number;
  debug?: boolean;
  debugTile?: boolean;
}

export function renderBitmapBinarySubLayersOnly<S extends string[]>(props: RenderBitmapBinarySubLayersProps<S>) {
  const {
    data: layers,
    id,
    layerSource,
    deconvolutionType,
    intercept,
    coefficients,
    transparentColor: transparentColorInHook,
    layerOpacities,
    debug,
    gammaValues,
    contrastValues,
    brightnessValues,
    tile,
  } = props;

  if (!layers || typeof layers === 'string') {
    if (debug) {
      console.warn('No layers data', { layers, id, props });
    }
    return null;
  }
  const { photometricInterpretation = PhotometricInterpretation.RGB } = layerSource?.meta || {};

  const transparentColor = getTransparentColor(photometricInterpretation);

  if (!isInterleaved(layerSource.shape)) {
    console.warn('We expect non-interleaved data to be in the form of ImageData');
    return null;
  }

  const layersData = layers?.data;
  // Only render in relevant range
  if (!layersData) {
    if (debug) {
      console.warn('No layers data', { layersData, id });
    }
    return null;
  }

  const activeTiles = compact(layersData);
  if (isEmpty(activeTiles)) {
    if (debug) {
      console.warn('No active tiles', { activeTiles, id });
    }
    return null;
  }

  const width = min(map(activeTiles, 'width'));
  const height = min(map(activeTiles, 'height'));
  if (!width || !height) {
    console.warn('Invalid tile dimensions', { width, height, id });
  }

  const bounds = getBoundsForDeepZoomTile({ tile, layerSource, width, height });

  return map(layersData, (image, channelIndex) => {
    const layerId = `renderBitmapBinarySubLayers-${channelIndex}-${bounds}-${id}`;

    if (!image || !castArray(layerOpacities)[channelIndex]) {
      if (debug && castArray(layerOpacities)[channelIndex]) {
        console.warn('No image', { layerId, image, channelIndex, layerOpacities, id });
      }
      return null;
    }

    const gamma = castArray(gammaValues)?.[channelIndex];
    const contrast = castArray(contrastValues)?.[channelIndex];
    const brightness = castArray(brightnessValues)?.[channelIndex];

    const useImageControls = isNumber(gamma) || isNumber(contrast) || isNumber(brightness);
    const extensions = [
      ...(coefficients
        ? deconvolutionType === 'linear'
          ? [new LinearDeconvExtension()]
          : deconvolutionType === 'cubic'
          ? [new CubicDeconvExtension()]
          : []
        : []),
      ...(useImageControls ? [new ImageControlsExtension()] : []),
    ];

    return new BitmapLayer(props as any, {
      id: layerId,
      image,
      photometricInterpretation,
      opacity: (castArray(layerOpacities)[channelIndex] ?? 0) / 100,
      // Shared props with XRLayer:
      bounds,
      extensions,
      intercept,
      coefficients,
      tileId: tile.index,
      gamma,
      contrast,
      brightness,
      // transparentColor is a prop applied to the original image data by deck.gl's
      // BitmapLayer and needs to be in the original colorspace.  It is used to determine
      // what color is "transparent" in the original color space (i.e what shows when opacity is 0).
      transparentColor:
        transparentColor?.length === 4
          ? transparentColor
          : [...((transparentColor || [0, 0, 0]) as [number, number, number]), 1],
      // This is our transparentColor props which needs to be applied in the hook that converts to the RGB space.
      transparentColorInHook,
      textureParameters: {
        [GL.TEXTURE_MIN_FILTER]: GL.NEAREST,
        [GL.TEXTURE_MAG_FILTER]: GL.NEAREST,
      },
    });
  }) as LayersList;
}

export function renderBitmapBinarySubLayers<S extends string[]>(props: RenderBitmapBinarySubLayersProps<S>) {
  const {
    data: layers,
    tileSize,
    visible,
    id,
    layerSource,
    debug = false,
    debugTile = false,
    overviewLayer,
    tile,
  } = props;
  if (!visible && !tile?.isVisible) {
    return null;
  }
  const dataLayers = renderBitmapBinarySubLayersOnly({ ...props, debug });

  let width = min(map(compact(layers?.data), 'width'));
  let height = min(map(compact(layers?.data), 'height'));
  if (!width || !height) {
    width = width || tileSize;
    height = height || tileSize;
  }

  const bounds = getBoundsForDeepZoomTile({ tile, layerSource, width, height });

  const debugLayers =
    debugTile && !overviewLayer
      ? generateDebugLayers({
          id: `debug-renderBitmapBinarySubLayers-${bounds}-${id}`,
          tileCoordinates: tile.index,
          boundingBox: [slice(bounds, 0, 2), slice(bounds, 2, 4)],
          maxZoom: layerSource.maxLevel,
          error: !dataLayers,
        })
      : [];

  return [...compact(dataLayers || []), ...debugLayers] as LayersList;
}

export function renderXRSubLayers<S extends string[]>(
  props: MultiScaleImageLayerProps<S> & {
    useNativeBitmaps?: boolean;
    id: string;
    data: MultiScaleImageData;
    tile: TileProps;
    layersVisible: boolean[] | boolean;
    contrastLimits: [begin: number, end: number][];
    layerOpacities: number[] | number;
    transparentColor: Color;
    colors: Color[];
    tileSize: number;
    debug?: boolean;
    debugTile?: boolean;
  }
): Layer<XRLayerProps<S>> | LayersList | null {
  const {
    colors,
    data: layers,
    id,
    layerSource,
    transparentColor: transparentColorInHook,
    layerOpacities,
    isBackground,
    // Debug flags
    useNativeBitmaps = false,
    debug = false,
    debugTile = false,
    overviewLayer,
    tile,
  } = props;

  if (!layers || typeof layers === 'string') {
    return null;
  } else if (isInterleaved(layerSource.shape)) {
    console.warn('We expect interleaved data to be handled by another sub-layer renderer');
    return null;
  }

  // Tiles are exactly fitted to have height and width such that their bounds match that of the actual image (not some padded version).
  // Thus the right/bottom given by deck.gl are incorrect since they assume tiles are of uniform sizes, which is not the case for us.
  const { photometricInterpretation = PhotometricInterpretation.RGB } = layerSource?.meta || {};

  const transparentColor = getTransparentColor(photometricInterpretation);

  const layersData = layers?.data;
  // Only render in relevant range
  if (!layersData) {
    return [];
  }

  const activeTiles = compact(layersData);
  if (isEmpty(activeTiles)) {
    return [];
  }

  const width = min(map(activeTiles, 'width'));
  const height = min(map(activeTiles, 'height'));
  if (!width || !height) {
    console.warn('Invalid tile dimensions', { width, height, id });
  }

  const usePngDecoder = layerSource.meta.usePngDecoder;
  const bounds =
    !usePngDecoder || props.cropDecodedPngTiles
      ? getBoundsForDeepZoomTile({ tile, layerSource, width, height })
      : getBoundsForDeepZoomTileWithOverlapPixels({ tile, layerSource, width, height });

  // If we switch to this logic, then When not fully zoomed in, we don't want to interpolate between pixels
  // const interpolation = index.z >= 0 ? GL.LINEAR : GL.NEAREST;
  const interpolation = GL.LINEAR;

  if (useNativeBitmaps) {
    return map(activeTiles, (image, channelIndex) => {
      return new BitmapLayer({
        image: image as ImageData,
        bounds,
        id: `xr-like-multi-scale-sub-layer-interleaved-${channelIndex}-${bounds}-${id}`,
        opacity: (castArray(layerOpacities)[channelIndex] ?? 0) / 100,
        tintColor: colors[channelIndex],
        interpolation,
        transparentColor,
      });
    });
  } else {
    const baseId = `multi-scale-sub-layer-xr-${JSON.stringify(tile.index)}-${bounds}-${id}`;
    const depth = (first(activeTiles) as DecodedPng)?.depth ?? 8;
    if (
      layers.isDecodedPng &&
      some(activeTiles, (activeTile) => (activeTile as DecodedPng)?.depth && (activeTile as DecodedPng).depth !== depth)
    ) {
      console.warn('Not all tiles have the same depth', { depth, tileDepths: map(activeTiles, 'depth'), id, baseId });
    }

    if (
      some(activeTiles, (activeTile) => activeTile?.width !== width) ||
      some(activeTiles, (activeTile) => activeTile?.height !== height)
    ) {
      console.warn('Not all tiles have the same dimensions', {
        id,
        baseId,
        activeTiles,
        widths: map(activeTiles, 'width'),
        heights: map(activeTiles, 'height'),
      });
    }

    const numChannels = size(layersData);

    return [
      new XRLayer<S>(props, {
        redOnlyOnCPU: true,
        photometricInterpretation,
        dtype: depth === 16 ? 'Uint16' : 'Uint8',
        transparentColor,
        transparentColorInHook,
        channelData: layers,
        bounds,
        id: baseId,
        interpolation,
        width,
        height,
        debug,
      }) as Layer<XRLayerProps<S>>,
      ...(debugTile && !overviewLayer
        ? generateDebugLayers({
            id: `debug-${baseId}`,
            tileCoordinates: tile.index,
            boundingBox: [slice(bounds, 0, 2), slice(bounds, 2, 4)],
            maxZoom: layerSource.maxLevel,
            isBackground,
          })
        : []),
    ] as LayersList;
  }
}
