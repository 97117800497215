import { useSignals } from '@preact/signals-react/runtime';
import { compact, concat, Dictionary, find, forEach, groupBy, isEqual, sortBy } from 'lodash';
import { useEffect } from 'react';

import { getMarkerPositivityParentHeatmapId } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/parquetLayers/helpers';
import { FeatureMetadata, ParquetLayerType } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { MULTIPLEX_STAIN_ID } from 'interfaces/stainType';
import useTaxonomy from 'utils/queryHooks/taxonomy/useTaxonomy';
import { useCellColorMappings } from 'utils/queryHooks/useCellColorMappings';
import usePrevious from 'utils/usePrevious';
import {
  defaultHeatmapOpacity,
  LayerVisualizationChange,
  slidesLayerVisualizationSettings,
  useApplyChangesToSlideLayerVisualizationSettings,
  useGetLayerSettingsFromUrl,
} from '../../../slidesVisualizationAndConfiguration';
import { computeDefaultCellTableLayerSettings, getCellTableLayerId } from './CellTableControl';

export const useUpdateCellTableHeatmapsSettingsOnChange = ({
  slideId,
  viewerIndex,
  stainTypeId,
  layerIdsToUrlKeys,
  parquetHeatmapByType,
  debug,
}: {
  slideId: string;
  viewerIndex: number;
  stainTypeId: string;
  layerIdsToUrlKeys?: Record<string, string>;
  parquetHeatmapByType: Dictionary<FeatureMetadata[]>;
  debug?: boolean;
}) => {
  useSignals();
  const getLayerSettingsFromUrl = useGetLayerSettingsFromUrl();
  const applyChangesToSlideLayerVisualizationSettings = useApplyChangesToSlideLayerVisualizationSettings();

  const { isLoading: isLoadingTaxonomies, data: taxonomies } = useTaxonomy();
  const { isLoading: isLoadingCellColorMappings, data: cellColorMappings } = useCellColorMappings();

  const currentSlideParams = {
    slideId,
    viewerIndex,
    isLoadingTaxonomies,
    isLoadingCellColorMappings,
    parquetHeatmapByType,
  };
  const previousSlideParams = usePrevious(currentSlideParams);

  useEffect(() => {
    const isMultiplex = stainTypeId === MULTIPLEX_STAIN_ID;
    if (isLoadingTaxonomies || isLoadingCellColorMappings) {
      return;
    }
    if (!slideId || isEqual(currentSlideParams, previousSlideParams)) {
      return;
    }
    if (debug) {
      console.debug('Updating visual configurations for cell table heatmaps');
    }
    const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];
    const changes: LayerVisualizationChange[] = [];
    forEach(
      compact(
        concat(
          parquetHeatmapByType[ParquetLayerType.CellClassification],
          parquetHeatmapByType[ParquetLayerType.CellClassificationDensities]
        )
      ),
      (cellTableHeatmap) => {
        const parquetClasses = compact(cellTableHeatmap.options || []);
        forEach(parquetClasses, (layer, layerIndex) => {
          const computedLayerSettings = computeDefaultCellTableLayerSettings({
            cellTableHeatmap,
            layerIndex,
            optionFromHeatmap: layer,
            taxonomies,
            cellColorMappings,
            stainTypeId,
          });
          const layerSettingsKey = getCellTableLayerId(cellTableHeatmap, layer.key);
          const previousSettings = viewerSlideLayerVisualizationSettings?.value?.[slideId]?.[layerSettingsKey]?.value;
          const layerUrlKey = layerIdsToUrlKeys?.[layerSettingsKey] || layerSettingsKey;
          const urlSettings = getLayerSettingsFromUrl({ layerUrlKey, stainTypeId, viewerIndex });
          const newSettings = {
            ...computedLayerSettings,
            ...(Boolean(previousSettings) && previousSettings),
            ...urlSettings,
          };
          // If we couldn't compute the color before, assign it now
          if (newSettings?.colorByIndex === newSettings?.color && computedLayerSettings?.color) {
            newSettings.color = computedLayerSettings?.color;
          }
          if (debug) {
            console.debug('Updating visual configurations for cell table heatmap', {
              cellTableHeatmapId: cellTableHeatmap.id,
              cellTableHeatmap,
              layerIndex: layerIndex,
              layerKey: layer.key,
              newSettings: newSettings,
              computedLayerSettings: computedLayerSettings,
              previousSettings: previousSettings,
              urlSettings: urlSettings,
            });
          }
          changes.push({ layerId: layerSettingsKey, newSettings });
        });
      }
    );

    const groupMarkerPositivityHeatmapsById = groupBy(
      parquetHeatmapByType[ParquetLayerType.MarkerPositivity],
      getMarkerPositivityParentHeatmapId
    );

    forEach(groupMarkerPositivityHeatmapsById, (parquetHeatmapsByParentId, parentHeatmapId) => {
      forEach(sortBy(parquetHeatmapsByParentId, 'columnName'), (markerPositivityHeatmap, markerIndex) => {
        const positiveOption = find(markerPositivityHeatmap.options, 'key');
        const computedLayerSettings = computeDefaultCellTableLayerSettings({
          cellTableHeatmap: markerPositivityHeatmap,
          layerIndex: markerIndex,
          optionFromHeatmap: positiveOption,
          taxonomies,
          cellColorMappings,
          stainTypeId,
        });
        if (isMultiplex) {
          // Marker positivity heatmaps for multiplex slides should all be white
          computedLayerSettings.color = '#FFFFFF';
          computedLayerSettings.opacity = defaultHeatmapOpacity;
        }
        const layerSettingsKey = `${parentHeatmapId}-${markerPositivityHeatmap.columnName}`;
        const previousSettings = viewerSlideLayerVisualizationSettings?.value?.[slideId]?.[layerSettingsKey]?.value;
        const layerUrlKey = layerIdsToUrlKeys?.[layerSettingsKey] || layerSettingsKey;
        const urlSettings = getLayerSettingsFromUrl({ layerUrlKey, stainTypeId, viewerIndex });
        const newSettings = {
          ...computedLayerSettings,
          ...(Boolean(previousSettings) && previousSettings),
          ...urlSettings,
        };
        // If we couldn't compute the color before, assign it now
        if (newSettings?.colorByIndex === newSettings?.color && computedLayerSettings?.color) {
          newSettings.color = computedLayerSettings?.color;
        }

        changes.push({ layerId: layerSettingsKey, newSettings });
      });
    });

    applyChangesToSlideLayerVisualizationSettings({
      slideId,
      viewerIndex,
      changes,
      skipUrlUpdate: true,
      changeFlow: `CellTableControl-initial`,
      debug,
    });
  }, [
    viewerIndex,
    slideId,
    stainTypeId,
    layerIdsToUrlKeys,
    parquetHeatmapByType,
    taxonomies,
    cellColorMappings,
    getLayerSettingsFromUrl,
    applyChangesToSlideLayerVisualizationSettings,
  ]);
};
