import AdjustIcon from '@mui/icons-material/Adjust';
import BorderStyleIcon from '@mui/icons-material/BorderStyle';
import PentagonOutlinedIcon from '@mui/icons-material/PentagonOutlined';
import { List, ListItem, ListItemIcon, ListItemText, Radio, RadioGroup, Skeleton } from '@mui/material';
import { ColorPicker } from 'components/atoms/ColorPicker';
import { TodoOption } from 'interfaces/annotation';
import { join, map, times } from 'lodash';
import React from 'react';

interface ClassesToAnnotateListProps {
  classesToAnnotate: TodoOption[];
  selectedClass: TodoOption;
  setSelectedClass: (classToAnnotate: TodoOption) => void;
}

const ClassesToAnnotateRadio: React.FC<ClassesToAnnotateListProps> = ({
  classesToAnnotate,
  selectedClass,
  setSelectedClass,
}) => {
  return (
    <RadioGroup>
      {map(classesToAnnotate, (classToAnnotate) => (
        <ClassToAnnotateListRadio
          key={classToAnnotate?.name}
          item={classToAnnotate}
          isSelected={classToAnnotate?.name === selectedClass?.name}
          onSelect={() => setSelectedClass(classToAnnotate)}
        />
      ))}
    </RadioGroup>
  );
};

interface ClassToAnnotateListRadioProps {
  item: TodoOption;
  isSelected?: boolean;
  onSelect?: () => void;
}

const ClassToAnnotateListRadio: React.FC<ClassToAnnotateListRadioProps> = ({ item, isSelected, onSelect }) => {
  const { className, color, displayName, name } = item;

  return (
    <ListItem>
      <Radio
        checked={isSelected}
        onChange={onSelect}
        value={item.name}
        name="option-radio"
        inputProps={{ 'aria-label': item.displayName }}
      />

      {/* the default minWidth is 56px witch is too big for this case */}
      <ListItemIcon sx={{ minWidth: '40px' }}>
        <ColorPicker disableEditing={true} currentColor={color} />
      </ListItemIcon>
      <ListItemIcon sx={{ minWidth: '40px' }}>
        {className === 'polygon' ? (
          name.includes('roi') ? (
            <BorderStyleIcon />
          ) : (
            <PentagonOutlinedIcon />
          )
        ) : (
          <AdjustIcon />
        )}
      </ListItemIcon>
      <ListItemText
        primary={displayName ?? name}
        primaryTypographyProps={{
          variant: 'subtitle2',
          style: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
        secondary={`${map(item.markerPositivity, (value, key) => join(`${key}-${value}`, ', '))}`}
      />
    </ListItem>
  );
};

export const ClassesToAnnotateListSkeleton: React.FC = () => {
  return (
    <List>
      {times(5, (index) => (
        <ListItem key={index}>
          <ListItemIcon sx={{ minWidth: '40px' }}>
            <Skeleton variant="rounded" width={15} height={15} />
          </ListItemIcon>
          <ListItemIcon sx={{ minWidth: '40px' }}>
            <Skeleton variant="rounded" width={15} height={15} />
          </ListItemIcon>
          <Skeleton variant="text" width={300} />
        </ListItem>
      ))}
    </List>
  );
};

export default ClassesToAnnotateRadio;
