import { WebMercatorViewport } from '@deck.gl/core/typed';
import { point } from '@turf/helpers';
import { forEach } from 'lodash';
import { ImmutableFeatureCollection, TranslateMode } from 'nebula.gl';
import { FeatureCollection, Position } from './geojson-types';
import { coordsOrthographicBearing, coordsOrthographicDistance, orthographicTransformTranslate } from './helpers';
import { EditAction, ModeProps } from './types';
import { mapCoords } from './utils';

export type GeoJsonEditAction = EditAction<FeatureCollection>;

export class TranslateOrthographicMode extends TranslateMode {
  getTranslateAction(
    startDragPoint: Position,
    currentPoint: Position,
    editType: string,
    props: ModeProps<FeatureCollection>
  ): GeoJsonEditAction | null | undefined {
    if (!this._geometryBeforeTranslate) {
      return null;
    }

    let updatedData = new ImmutableFeatureCollection(props.data);
    const selectedIndexes = props.selectedIndexes;

    const { viewport: viewportDesc, screenSpace } = props.modeConfig || {};

    if (viewportDesc && screenSpace) {
      const viewport = viewportDesc.project ? viewportDesc : new WebMercatorViewport(viewportDesc);

      const from = viewport.project(startDragPoint);
      const to = viewport.project(currentPoint);
      const dx = to[0] - from[0];
      const dy = to[1] - from[1];

      forEach(selectedIndexes, (selectedIndex, index) => {
        const feature = this._geometryBeforeTranslate.features[index];

        let coordinates = feature.geometry.coordinates;
        if (coordinates) {
          coordinates = mapCoords(coordinates, (coord) => {
            const pixels = viewport.project(coord);
            if (pixels) {
              pixels[0] += dx;
              pixels[1] += dy;
              return viewport.unproject(pixels);
            }
            return null;
          });

          // @ts-ignore
          updatedData = updatedData.replaceGeometry(selectedIndex, {
            type: feature.geometry.type,
            coordinates,
          });
        }
      });
    } else {
      const p1 = point(startDragPoint);
      const p2 = point(currentPoint);

      const distanceMoved = coordsOrthographicDistance(p1, p2);
      const direction = coordsOrthographicBearing(p1, p2);

      const movedFeatures = orthographicTransformTranslate(
        // @ts-ignore
        this._geometryBeforeTranslate,
        distanceMoved,
        direction
      );

      forEach(selectedIndexes, (selectedIndex, index) => {
        // @ts-ignore
        const movedFeature = movedFeatures.features[index];
        updatedData = updatedData.replaceGeometry(selectedIndex, movedFeature.geometry);
      });
    }

    return {
      updatedData: updatedData.getObject(),
      editType,
      editContext: {
        featureIndexes: selectedIndexes,
      },
    };
  }
}
