import RemoveIcon from '@mui/icons-material/Remove';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  allCategoricalActions,
  AutomaticConditionCell,
  CategoricalActions,
  FilterActions,
} from 'interfaces/automaticCondition';
import { NumberOption, OrchestrationDataType, orchestrationDataTypeNumbers } from 'interfaces/calculateFeatures';
import { CategoricalOption, MappingFilterMetadata } from 'interfaces/postProcessingAction';
import {
  cloneDeep,
  every,
  filter,
  find,
  flatMap,
  includes,
  isArray,
  isEmpty,
  isNumber,
  join,
  keyBy,
  map,
  remove,
} from 'lodash';
import React, { FunctionComponent } from 'react';
import { AutomaticConditionBuilderProps } from './AutomaticConditionBuilder';
import { isConditionCellParamsInvalid, isToFilterNumberTypeInvalid } from './utils';

type CellConditionBuilderProps = AutomaticConditionBuilderProps<AutomaticConditionCell>;

export interface MappingFilterOption {
  id: string;
  label: string;
  sourceName: string;
  originalLabel: string;
  groupBy?: string;
  type: OrchestrationDataType;
}

const CellConditionBuilder: FunctionComponent<React.PropsWithChildren<CellConditionBuilderProps>> = ({
  mappingFiltersMetadata,
  onRemove,
  editable,
  condition,
  onConditionChange,
  categoricalActions,
  OrchestrationDataTypes,
}) => {
  const mappingFilterOptions = getMappingFilterOptions(mappingFiltersMetadata, OrchestrationDataTypes);
  const mappingFilterOptionsById = keyBy(mappingFilterOptions, 'id');

  const filterActionOptions = getFilterActionOptions(
    condition?.mappingFilter,
    mappingFiltersMetadata,
    mappingFilterOptions,
    categoricalActions
  );

  const filterOptions = getFilterOptions(condition?.mappingFilter, mappingFiltersMetadata, mappingFilterOptions);
  const filterOptionsById = keyBy(filterOptions, 'id');

  const {
    isInvalidMappingFilter: isMappingFilterError,
    isInvalidFilterAction: isFilterActionError,
    isInvalidToFilter: isToFilterError,
  } = isConditionCellParamsInvalid(condition, mappingFilterOptionsById, filterActionOptions, filterOptions);

  return (
    <Paper
      elevation={1}
      sx={{
        p: 1,
        mb: 1,
      }}
    >
      <Grid container spacing={2} alignItems={'center'}>
        <Grid item>
          <IconButton disabled={!editable} onClick={() => onRemove()}>
            <RemoveIcon />
          </IconButton>
        </Grid>
        <Grid item xs={6}>
          <FormControl
            fullWidth
            variant="standard"
            error={isMappingFilterError}
            className={isMappingFilterError ? 'error' : undefined}
          >
            <Autocomplete
              size="small"
              options={mappingFilterOptions}
              groupBy={(option) => option.groupBy}
              renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                  <Typography key={key} {...optionProps}>
                    {option.label}
                  </Typography>
                );
              }}
              getOptionLabel={(option) => `${option.sourceName}.${option.label}`}
              value={
                condition?.mappingFilter
                  ? {
                      label: mappingFilterOptionsById[condition?.mappingFilter]?.label || condition?.mappingFilter,
                      id: condition?.mappingFilter,
                      sourceName: mappingFilterOptionsById[condition?.mappingFilter]?.sourceName,
                      originalLabel: mappingFilterOptionsById[condition?.mappingFilter]?.originalLabel,
                      groupBy: mappingFilterOptionsById[condition?.mappingFilter]?.groupBy,
                      type: mappingFilterOptionsById[condition?.mappingFilter]?.type,
                    }
                  : null
              }
              isOptionEqualToValue={(option, selectedValue) => option.id === selectedValue.id}
              renderInput={(params) => (
                <TextField
                  error={isEmpty(condition.mappingFilter) || !mappingFilterOptionsById[condition.mappingFilter]}
                  required
                  {...params}
                  size="medium"
                />
              )}
              onChange={(event, selected) => {
                const newCondition: AutomaticConditionCell = {
                  ...condition,
                  mappingFilter: selected?.id,
                  filterAction: null,
                  toFilter: null,
                };
                newCondition.displayValue = getCellName(newCondition, mappingFiltersMetadata);

                onConditionChange(newCondition);
              }}
            />
            {isMappingFilterError && !isEmpty(condition.mappingFilter) && (
              <FormHelperText error id="class-does-not-exist-error-text">
                This option does not exist anymore
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl
            fullWidth
            variant="standard"
            error={isFilterActionError}
            className={isFilterActionError ? 'error' : undefined}
          >
            <Select
              size="medium"
              error={isEmpty(condition.filterAction)}
              value={condition?.filterAction}
              onChange={(e) => {
                const newCondition = { ...condition, filterAction: e.target.value };
                newCondition.displayValue = getCellName(newCondition, mappingFiltersMetadata);
                onConditionChange(newCondition);
              }}
            >
              {map(filterActionOptions, (filterAction) => (
                <MenuItem key={filterAction.id} value={filterAction.id}>
                  {filterAction.label}
                </MenuItem>
              ))}
            </Select>
            {isFilterActionError && !isEmpty(condition.filterAction) && (
              <FormHelperText error id="class-does-not-exist-error-text">
                This option does not exist anymore
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl
            fullWidth
            variant="standard"
            error={isToFilterError}
            className={isToFilterError ? 'error' : undefined}
          >
            {condition && (
              <>
                {mappingFilterOptionsById[condition?.mappingFilter]?.type === OrchestrationDataType.Boolean && (
                  <Select
                    size="medium"
                    error={!isNumber(condition.toFilter)}
                    value={condition.toFilter}
                    onChange={(e) => {
                      const newCondition: AutomaticConditionCell = {
                        ...condition,
                        toFilter: Number(e.target.value),
                      };
                      newCondition.displayValue = getCellName(newCondition, mappingFiltersMetadata);

                      onConditionChange(newCondition);
                    }}
                  >
                    {map(getBooleanFilterOptions(), (option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                {mappingFilterOptionsById[condition?.mappingFilter]?.type === OrchestrationDataType.Categorical && (
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    limitTags={2}
                    size="small"
                    options={filterOptions as CategoricalOption[]}
                    value={
                      condition.toFilter
                        ? map(condition.toFilter as string[] | number[], (val: string | number) => {
                            return {
                              label: filterOptionsById[val]?.['label'] || val,
                              id: val,
                            };
                          })
                        : []
                    }
                    isOptionEqualToValue={(option, selectedValue) => option.id === selectedValue.id}
                    renderInput={(params) => (
                      <TextField
                        error={
                          isEmpty(condition.toFilter) ||
                          !every(
                            condition.toFilter as string[] | number[],
                            (val: string | number) => filterOptionsById[val]
                          )
                        }
                        required
                        {...params}
                        size="medium"
                      />
                    )}
                    onChange={(event, selected) => {
                      const newCondition = { ...condition, toFilter: map(selected, 'id') as string[] | number[] };
                      newCondition.displayValue = getCellName(newCondition, mappingFiltersMetadata);
                      onConditionChange(newCondition);
                    }}
                  />
                )}
                {includes(orchestrationDataTypeNumbers, mappingFilterOptionsById[condition?.mappingFilter]?.type) && (
                  <TextField
                    value={condition.toFilter}
                    id="outlined-number"
                    type="number"
                    error={isToFilterError}
                    onChange={(event) => {
                      let toFilterNumber;
                      if (mappingFilterOptionsById[condition?.mappingFilter]?.type === OrchestrationDataType.Int) {
                        toFilterNumber = parseInt(event.target.value);
                      } else {
                        toFilterNumber = parseFloat(event.target.value);
                      }

                      const newCondition = { ...condition, toFilter: toFilterNumber };
                      newCondition.displayValue = getCellName(newCondition, mappingFiltersMetadata);
                      onConditionChange(newCondition);
                    }}
                  />
                )}
              </>
            )}
            {isToFilterError &&
              !isEmpty(condition?.toFilter) &&
              mappingFilterOptionsById[condition?.mappingFilter]?.type === OrchestrationDataType.Categorical && (
                <FormHelperText error id="class-does-not-exist-error-text">
                  This option does not exist anymore
                </FormHelperText>
              )}
            {isToFilterError &&
              includes(orchestrationDataTypeNumbers, mappingFilterOptionsById[condition?.mappingFilter]?.type) &&
              isToFilterNumberTypeInvalid(condition.toFilter as number, filterOptions as NumberOption) && (
                <FormHelperText error id="class-does-not-exist-error-text">
                  This value need to be: {(filterOptions as NumberOption)?.min} {`< x < `}
                  {(filterOptions as NumberOption)?.max}
                </FormHelperText>
              )}
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const getMappingFilterOptions = (
  mappingFiltersMetadata: MappingFilterMetadata[],
  OrchestrationDataTypes?: OrchestrationDataType[]
): MappingFilterOption[] => {
  return flatMap(mappingFiltersMetadata, (mappingFilterMetadata) => {
    return map(
      filter(
        mappingFilterMetadata.data,
        (data) => !OrchestrationDataTypes || includes(OrchestrationDataTypes, data.type)
      ),
      (data) => {
        return {
          id: `${mappingFilterMetadata.sourceName}.${data.label}`,
          label: data.label,
          sourceName: mappingFilterMetadata.sourceName,
          originalLabel: data.label,
          groupBy: mappingFilterMetadata.sourceName,
          type: data.type,
        };
      }
    );
  });
};

export const getFilterActionOptions = (
  mappingFilter: string,
  mappingFiltersMetadata: MappingFilterMetadata[],
  mappingFilterOptions: MappingFilterOption[],
  categoricalActions: CategoricalActions[]
) => {
  const mappingFilterOptionsById = keyBy(mappingFilterOptions, 'id');

  if (!mappingFilter) return [];
  if (!mappingFilterOptionsById[mappingFilter]) return [];
  const currentMappingFilter = mappingFilterOptionsById[mappingFilter];

  const mappingFilterMetadata = find(mappingFiltersMetadata, { sourceName: currentMappingFilter.sourceName });

  const FilterActionType = find(mappingFilterMetadata.data, {
    label: currentMappingFilter.originalLabel,
  })?.type;

  const filterActionByType = cloneDeep(FilterActions);
  if (categoricalActions)
    remove(filterActionByType.categorical, (action) => !includes(categoricalActions, action.label));

  return filterActionByType[FilterActionType];
};

export const getFilterOptions = (
  mappingFilter: string,
  mappingFiltersMetadata: MappingFilterMetadata[],
  mappingFilterOptions: MappingFilterOption[]
) => {
  const mappingFilterOptionsById = keyBy(mappingFilterOptions, 'id');

  if (!mappingFilter) return [];
  if (!mappingFilterOptionsById[mappingFilter]) return [];
  const currentMappingFilter = mappingFilterOptionsById[mappingFilter];
  const mappingFilterMetadata = find(mappingFiltersMetadata, { sourceName: currentMappingFilter.sourceName });
  return find(mappingFilterMetadata.data, { label: currentMappingFilter.originalLabel })?.options;
};

export const getCellName = (cellCondition: AutomaticConditionCell, mappingFiltersMetadata: MappingFilterMetadata[]) => {
  const mappingFilterOptions = getMappingFilterOptions(mappingFiltersMetadata);
  const mappingFilterOptionsById = keyBy(mappingFilterOptions, 'id');

  const filterActionOptions = getFilterActionOptions(
    cellCondition?.mappingFilter,
    mappingFiltersMetadata,
    mappingFilterOptions,
    allCategoricalActions
  );

  const filterActionOptionsById = keyBy(filterActionOptions, 'id');
  const filterOptions = getFilterOptions(cellCondition?.mappingFilter, mappingFiltersMetadata, mappingFilterOptions);
  const filterOptionsById = keyBy(filterOptions, 'id');
  const toFilter = isArray(cellCondition.toFilter)
    ? join(
        map(
          cellCondition.toFilter,
          (currentToFilter: string | number) => filterOptionsById[currentToFilter]?.['label'] || currentToFilter
        ),
        ', '
      )
    : cellCondition.toFilter;

  const cellName = `${mappingFilterOptionsById[cellCondition.mappingFilter]?.id || cellCondition.mappingFilter} ${
    filterActionOptionsById[cellCondition.filterAction]?.label || cellCondition.filterAction
  } ${toFilter}`;

  const cellNameWithFormattedPositivity = applyCellPositivity(cellName);

  return cellNameWithFormattedPositivity;
};

const cellMarkerPositivityRegex = /^[^.]+\.(marker)\.pos\.([^.]+) = (\d)$/;

function applyCellPositivity(pattern: string): string {
  const match = cellMarkerPositivityRegex.exec(pattern);
  if (!match) return pattern;

  const [, , markerName, value] = match;
  return `(${markerName}${value === '1' ? '+' : '-'})`;
}

const getBooleanFilterOptions = () => {
  return [
    {
      id: 0,
      label: 'Negative (False)',
    },
    {
      id: 1,
      label: 'Positive (True)',
    },
  ];
};

export default CellConditionBuilder;
