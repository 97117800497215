import React, { useEffect, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { useSecondaryAnalysis } from 'services/secondaryAnalysis';
import { SecondaryAnalysisEditType } from 'services/secondaryAnalysis/secondaryAnalysisSelectionModes';
import { StringParam, useQueryParam } from 'use-query-params';
import { SlideAnnotationEditType } from '../../Header/SlideInteractionMenu/SlideAnnotationTools/options';

const AnnotationsHints: React.FunctionComponent<React.PropsWithChildren> = () => {
  const {
    execution: { isStarting: isStartingSecondaryAnalysis },
    context: { secondaryAnalysisAreaSelectionMode },
  } = useSecondaryAnalysis();
  const [editAnnotationsModeFromUrl] = useQueryParam('editAnnotationsMode', StringParam);
  const [altKeyPressed, setAltKeyPressed] = useState(false);
  const [shiftKeyPressed, setShiftKeyPressed] = useState(false);

  useEffect(() => {
    const handleKeyDownForAnnotationsShortcuts = (event: KeyboardEvent) => {
      if (event.key === 'Alt') {
        setAltKeyPressed(true);
      }

      if (event.key === 'Shift') {
        setShiftKeyPressed(true);
      }
    };

    const handleKeyUpForAnnotationsShortcuts = (event: KeyboardEvent) => {
      if (event.key === 'Alt') {
        setAltKeyPressed(false);
      }
      if (event.key === 'Shift') {
        setShiftKeyPressed(false);
      }
    };

    window.addEventListener('keydown', handleKeyDownForAnnotationsShortcuts);
    window.addEventListener('keyup', handleKeyUpForAnnotationsShortcuts);

    return () => {
      window.removeEventListener('keydown', handleKeyDownForAnnotationsShortcuts);
      window.removeEventListener('keyup', handleKeyUpForAnnotationsShortcuts);
    };
  });

  const isOnDrawPolygonMode =
    editAnnotationsModeFromUrl === SlideAnnotationEditType.Polygon ||
    secondaryAnalysisAreaSelectionMode === SecondaryAnalysisEditType.IncludePolygon ||
    secondaryAnalysisAreaSelectionMode === SecondaryAnalysisEditType.ExcludePolygon;
  const isOnSelectionMode = editAnnotationsModeFromUrl === SlideAnnotationEditType.Select;
  const isOnMoveFeatureModeWithClick = editAnnotationsModeFromUrl === SlideAnnotationEditType.Move;
  const isOnCutPolygonMode = secondaryAnalysisAreaSelectionMode === SecondaryAnalysisEditType.CutPolygon;
  const isOnCutRoiMode = secondaryAnalysisAreaSelectionMode === SecondaryAnalysisEditType.CutRoi;

  return (
    <>
      {isStartingSecondaryAnalysis && (
        <Grid item>
          <Typography variant="body2">Starting secondary analysis, annotations are disabled</Typography>
        </Grid>
      )}
      {altKeyPressed ? (
        <Grid item>
          <Typography variant="body2">Delete annotation</Typography>
        </Grid>
      ) : shiftKeyPressed && isOnDrawPolygonMode ? (
        <Grid item>
          <Typography variant="body2">Pan the map</Typography>
        </Grid>
      ) : (
        <>
          {isOnCutPolygonMode && (
            <Grid item>
              <Typography variant="body2">Click and drag to remove parts of the existing annotation</Typography>
            </Grid>
          )}
          {isOnCutRoiMode && (
            <Grid item>
              <Typography variant="body2">Select an ROI to remove it from the existing annotation</Typography>
            </Grid>
          )}
          {(isOnDrawPolygonMode || isOnSelectionMode) && (
            <Grid item>
              <Typography variant="body2">Shift or Scroller - pan</Typography>
            </Grid>
          )}
          {isOnMoveFeatureModeWithClick && (
            <Grid item>
              <Typography variant="body2">Click a feature to select it</Typography>
            </Grid>
          )}
          <Grid item>
            <Typography variant="body2">Alt - delete</Typography>
          </Grid>
        </>
      )}
    </>
  );
};

export default AnnotationsHints;
