import { Grid } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import React from 'react';

import { ColorPicker } from 'components/atoms/ColorPicker';
import { getSlideChannelColorSettingsSignal, useUpdateSlideChannelColorSettings } from '../colorSettings';

export const MultiplexChannelColor: React.FC<
  React.PropsWithChildren<{
    viewerIndex: number;
    slideId: string;
    channelId: string;
    selected: boolean;
    markerType?: string;
  }>
> = ({ viewerIndex, slideId, channelId, selected, markerType }) => {
  useSignals();
  const slideChannelsSettingsSignal = getSlideChannelColorSettingsSignal(viewerIndex, slideId, channelId);
  const currentColor = selected ? slideChannelsSettingsSignal?.value?.color : undefined;

  const { handleColorChange } = useUpdateSlideChannelColorSettings();

  const onColorChange = (newColor: string) => {
    handleColorChange(viewerIndex, slideId, channelId, newColor, markerType);
  };

  return (
    <Grid item onClick={(e) => e.stopPropagation()}>
      <ColorPicker currentColor={currentColor} onColorChange={onColorChange} disable={!selected} />
    </Grid>
  );
};
