import UndoIcon from '@mui/icons-material/Delete';
import RedoIcon from '@mui/icons-material/Redo';
import {
  Box,
  Button,
  darken,
  Divider,
  Grid,
  lighten,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { cloneDeep, filter, isEmpty, map, reverse, size } from 'lodash';
import React from 'react';
import { getActionDisplayName, getIconForAction, useAnnotationsStoreWithUndo } from './useAnnotationsWithUndo';

const AnnotationsHistoryView: React.FC = () => {
  const { action } = useAnnotationsStoreWithUndo();

  const { undo, redo, futureStates, pastStates } = useAnnotationsStoreWithUndo.temporal.getState();

  // the first state is with action=null
  const pastStatesWithActions = filter(pastStates, (state) => state.action != null);
  const futureStepsFromCurrent = reverse(cloneDeep(futureStates));
  const numberOfPastActions = size(pastStatesWithActions);
  const numberOfFutureActions = size(futureStepsFromCurrent);

  return (
    <Grid container direction="column" height="100%" width="100%" justifyContent="space-between">
      <Grid item xs width="100%" overflow="auto">
        {isEmpty(pastStates) && isEmpty(futureStates) && !action ? (
          <AnnotationHistoryEmptyPlaceholder />
        ) : (
          <>
            <Typography variant="body1" padding={1}>
              Annotations history:
            </Typography>

            <List>
              {map(pastStatesWithActions, (state, index) => (
                <AnnotationActionItem
                  key={`annotations-history-${index}`}
                  action={state.action}
                  onClick={() => undo(numberOfPastActions - index)}
                />
              ))}
              <AnnotationActionItem
                key={`annotations-history-${numberOfPastActions ?? 0}`}
                action={action}
                onClick={undo}
                isCurrent
              />
              {map(futureStepsFromCurrent, (state, index) => (
                <AnnotationActionItem
                  key={`annotations-history-${numberOfPastActions + 1 + index}`}
                  action={state.action}
                  isFuture
                  onClick={() => redo(index + 1)}
                  isLast={index === numberOfFutureActions - 1}
                />
              ))}
            </List>
          </>
        )}
      </Grid>
      <Grid item xs={1} container direction="row" width="100%" justifyContent="end" alignItems="end" paddingX={1}>
        <Grid item>
          <Button size="small" onClick={() => redo()} disabled={isEmpty(futureStates)}>
            <RedoIcon /> Redo
          </Button>
        </Grid>
        <Grid item>
          <Button size="small" onClick={() => undo()} disabled={isEmpty(pastStates)}>
            <UndoIcon /> Undo
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const AnnotationHistoryEmptyPlaceholder: React.FC = () => {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800],
        borderRadius: 2,
        padding: 1,
        marginY: 3,
        marginX: 1,
        textAlign: 'center',
      })}
    >
      <Typography variant="body1" fontWeight="bold" mb={1}>
        Annotations history is empty
      </Typography>
    </Box>
  );
};

const AnnotationActionItem: React.FC<{
  action: any;
  onClick: () => void;
  isFuture?: boolean;
  isCurrent?: boolean;
  isLast?: boolean;
}> = ({ action, onClick, isFuture = false, isCurrent = false, isLast = false }) => {
  if (!action) {
    return null;
  }
  return (
    <>
      <ListItemButton
        onClick={onClick}
        sx={(theme) => ({
          color: isFuture ? 'gray' : 'inherit',
          backgroundColor: isCurrent
            ? theme.palette.mode === 'dark'
              ? lighten(theme.palette.background.paper, 0.2)
              : darken(theme.palette.background.paper, 0.2)
            : 'inherit',
          paddingY: 0.5,
          ':hover': {
            backgroundColor: isCurrent
              ? theme.palette.mode === 'dark'
                ? lighten(theme.palette.background.paper, 0.3)
                : darken(theme.palette.background.paper, 0.3)
              : theme.palette.mode === 'dark'
              ? lighten(theme.palette.background.paper, 0.1)
              : darken(theme.palette.background.paper, 0.1),
          },
          '& .MuiTypography-root': {
            fontSize: '0.9rem',
          },
        })}
      >
        <ListItemIcon sx={{ color: isFuture ? 'gray' : 'inherit' }}>{getIconForAction(action)}</ListItemIcon>
        <ListItemText primary={getActionDisplayName(action)} />
      </ListItemButton>
      {!isLast && <Divider />}
    </>
  );
};

export default AnnotationsHistoryView;
