import {
  MAX_AGGREGATED_CHANNELS,
  MAX_TEXTURES,
} from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/StainsLayers/constants';

export default function generateLayerVertexShader(numChannels = MAX_AGGREGATED_CHANNELS, maxTextures = MAX_TEXTURES) {
  const maxAggregatedChannels = Math.min(MAX_AGGREGATED_CHANNELS, numChannels);
  const channelsPerAtlas = Math.ceil(maxAggregatedChannels / maxTextures);
  const squareDim = Math.max(1, Math.ceil(Math.sqrt(channelsPerAtlas)));
  return `\
#define SHADER_NAME xr-layer-vertex-shader

in vec2 texCoords;
in vec3 positions;
in vec3 positions64Low;
in vec3 instancePickingColors;

out vec2 vTexCoord;
out vec2 vTexPos;


void main(void) {
  geometry.worldPosition = positions;
  vec2 actualTexCoords = vec2(texCoords.x / float(${squareDim}), texCoords.y / float(${squareDim}));
  geometry.uv = actualTexCoords;
  geometry.pickingColor = instancePickingColors;
  gl_Position = project_position_to_clipspace(positions, positions64Low, vec3(0.), geometry.position);
  DECKGL_FILTER_GL_POSITION(gl_Position, geometry);
  vTexCoord = actualTexCoords;
  vTexPos = geometry.worldPosition.xy;
  vec4 color = vec4(0.);
  DECKGL_FILTER_COLOR(color, geometry);
}
`;
}
