import { Autocomplete, SxProps, TextField } from '@mui/material';
import { find } from 'lodash';
import React, { useState, useTransition } from 'react';

import { Taxonomy } from 'interfaces/taxonomy';
import useTaxonomy from 'utils/queryHooks/taxonomy/useTaxonomy';

interface TaxonomySelectDropdownProps {
  selectedTaxonomyId: string | null;
  onSelectTaxonomyId: (taxonomyId: string | null) => void;
  sx?: SxProps;
}

const TaxonomySelectDropdown: React.FC<TaxonomySelectDropdownProps> = ({
  selectedTaxonomyId: externalTaxonomyId,
  onSelectTaxonomyId,
  sx,
}) => {
  const { data: taxonomies } = useTaxonomy();
  const [selectedInternalTaxonomyId, setSelectedInternalTaxonomyId] = useState<string | null>(null);

  const [, startTransition] = useTransition();

  const handleSelectTaxonomy = (newValue: Taxonomy | null) => {
    startTransition(() => {
      onSelectTaxonomyId(newValue ? newValue.path : null);
    });
    setSelectedInternalTaxonomyId(newValue ? newValue.path : null);
  };

  const selectedId = selectedInternalTaxonomyId ?? externalTaxonomyId;
  const selectedTaxonomy = selectedId ? find(taxonomies, (t) => t.path === selectedId) : null;

  return (
    <Autocomplete
      sx={sx}
      fullWidth
      options={taxonomies ?? []}
      getOptionLabel={(option) => option.name}
      value={selectedTaxonomy}
      onChange={(event, newValue) => handleSelectTaxonomy(newValue as Taxonomy | null)}
      isOptionEqualToValue={(option, value) => option.path === value.path}
      // renderOption={(props, option, { selected }) => (
      //   <li {...props}>
      //     <Checkbox
      //       icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
      //       checkedIcon={<CheckBoxIcon fontSize="small" />}
      //       style={{ marginRight: 8 }}
      //       checked={selected}
      //     />
      //     {option.name}
      //   </li>
      // )}
      renderInput={(params) => <TextField size="small" {...params} placeholder="Select Taxonomy" />}
    />
  );
};

export default TaxonomySelectDropdown;
