import { useQueries } from '@tanstack/react-query';
import { SchemaElement } from 'hyparquet/src/types';
import {
  compact,
  filter,
  find,
  findIndex,
  flatMap,
  groupBy,
  includes,
  isEmpty,
  join,
  map,
  partition,
  some,
  uniq,
} from 'lodash';
import { useMemo } from 'react';
import { JsonParam, useQueryParam } from 'use-query-params';
import { useS3UrlToPresignedUrlMap } from 'utils/queryHooks/useS3UrlToPresignedUrlMap';
import { useGeoJsonFiles } from 'utils/useGeoJsonFileQuery';
import { useParquetFileQueries } from 'utils/useParquetFile';
import { isPreSignedPmtHeatmap, setupProtomapTiles } from 'utils/useProtomapTiles';
import {} from '../SlidesViewer/DeckGLViewer/layers/parquetLayers/helpers';
import { FeatureMetadata, HeatmapType } from './featureMetadata';
import { markerPositivityColumnPrefix, markerProbabilityColumnPrefix } from './parseHeatmaps';

export type HeatmapFromUrl = Partial<FeatureMetadata>;

export type FeatureMetadataFromUrl = FeatureMetadata & {
  originalIdentifier: string;
};

const isPmtHeatmapFromUrlWithMetadata = (heatmap: HeatmapFromUrl): heatmap is FeatureMetadataFromUrl =>
  Boolean(
    heatmap.heatmapType === HeatmapType.Pmt &&
      heatmap.heatmapUrl &&
      !heatmap.heatmapUrl.startsWith('s3://') &&
      !isEmpty(heatmap.options) &&
      !isEmpty(heatmap.key) &&
      !isEmpty(heatmap.nestedItems)
  );

const isParquetHeatmapFromUrlWithMetadata = (heatmap: HeatmapFromUrl): heatmap is FeatureMetadataFromUrl =>
  Boolean(
    heatmap.heatmapType === HeatmapType.Parquet &&
      heatmap.heatmapUrl &&
      !heatmap.heatmapUrl.startsWith('s3://') &&
      !isEmpty(heatmap.options) &&
      !isEmpty(heatmap.key) &&
      !isEmpty(heatmap.nestedItems) &&
      heatmap.columnName
  );

export const useFileUrlHeatmaps = (): {
  fileUrlHeatmaps: FeatureMetadataFromUrl[];
  isLoading: boolean;
} => {
  const [heatmapsFromUrl] = useQueryParam<HeatmapFromUrl[]>('heatmapsFromUrl', JsonParam);

  const s3Urls = filter(compact(map(heatmapsFromUrl, 'heatmapUrl')), (url) => url.startsWith('s3://'));

  const { s3UrlToPresignedUrlMap, isLoading: isLoadingPresignedUrls } = useS3UrlToPresignedUrlMap(
    map(s3Urls, (url, index) => ({ url, fileName: `heatmap-${index}` }))
  );

  const heatmapsByType = useMemo(
    () =>
      groupBy(
        filter(
          map(heatmapsFromUrl, (heatmap) => ({
            ...heatmap,
            originalIdentifier: heatmap.key || heatmap.id || heatmap.heatmapUrl,
            heatmapUrl: s3UrlToPresignedUrlMap[heatmap.heatmapUrl]?.data || heatmap.heatmapUrl,
            heatmapType: heatmap.heatmapType
              ? heatmap.heatmapType
              : heatmap.heatmapUrl?.endsWith('.pmt') || heatmap.heatmapUrl?.endsWith('.pmtiles')
              ? HeatmapType.Pmt
              : heatmap.heatmapUrl?.endsWith('.parquet')
              ? HeatmapType.Parquet
              : heatmap.heatmapUrl?.endsWith('.geojson')
              ? HeatmapType.GeoJson
              : undefined,
          })),
          (heatmapUrl) => Boolean(heatmapUrl.heatmapType && !heatmapUrl.heatmapUrl.startsWith('s3://'))
        ) as Array<HeatmapFromUrl & { originalIdentifier: string }>,
        'heatmapType'
      ),
    [heatmapsFromUrl, s3UrlToPresignedUrlMap]
  );

  const [pmtHeatmapsWithMetadata, pmtHeatmapsMissingMetadata] = useMemo(
    () => partition(heatmapsByType[HeatmapType.Pmt], isPmtHeatmapFromUrlWithMetadata),
    [heatmapsByType]
  );

  const pmtTilesQueries = useQueries({
    queries: map(pmtHeatmapsMissingMetadata, (pmtHeatmap) => ({
      queryKey: ['pmtiles', pmtHeatmap?.heatmapUrl],
      queryFn: async () => setupProtomapTiles(pmtHeatmap?.heatmapUrl),
      enabled: isPreSignedPmtHeatmap(pmtHeatmap),
    })),
  });

  const computedPmtHeatmapMetadata = useMemo(
    (): FeatureMetadataFromUrl[] =>
      flatMap(pmtTilesQueries, (query, index) => {
        const { pmtTileSource, pmtMetadata } = query?.data || {};
        const pmtHeatmap = pmtHeatmapsMissingMetadata[index];
        const pmtLayers = pmtMetadata?.tilejson?.layers;
        if (!pmtHeatmap || !pmtMetadata || !pmtTileSource || isEmpty(pmtLayers)) {
          return [];
        }

        const key = pmtHeatmap.key || pmtHeatmap.heatmapUrl;

        return [
          {
            id: pmtHeatmap.id || key,
            key,
            heatmapUrl: pmtHeatmap.heatmapUrl,
            originalIdentifier: (
              pmtHeatmap as HeatmapFromUrl & {
                originalIdentifier: string;
              }
            ).originalIdentifier,
            heatmapType: HeatmapType.Pmt,
            options: map(pmtLayers, (layer) => ({
              key: layer.name,
            })),
            nestedItems: map(pmtLayers, (layer) => ({
              id: layer.name,
              key: layer.name,
              type: HeatmapType.Pmt,
            })),
          },
        ];
      }),
    [join(map(pmtTilesQueries, 'dataUpdatedAt'), '_')]
  );

  const [parquetHeatmapsWithMetadata, parquetHeatmapsMissingMetadata] = useMemo(
    () => partition(heatmapsByType[HeatmapType.Parquet], isParquetHeatmapFromUrlWithMetadata),
    [heatmapsByType]
  );

  const parquetFiles = useParquetFileQueries(
    map(parquetHeatmapsMissingMetadata, (heatmap) => ({ url: heatmap.heatmapUrl }))
  );

  const computedParquetFeatureMetadata = useMemo(
    () =>
      flatMap(parquetFiles, (parsedParquetQuery, index) => {
        const parquetHeatmap = parquetHeatmapsMissingMetadata[index] as FeatureMetadataFromUrl & {
          originalIdentifier: string;
        };
        const parquetUrl = parquetHeatmap?.heatmapUrl;
        const parquetData = parsedParquetQuery?.data;
        const parquetColumnsMetadata = filter(parquetData?.metadata?.schema, ({ type, name }) => {
          // Only include columns with a type
          return (
            Boolean(type) &&
            // Exclude columns without a name
            Boolean(name) &&
            // Exclude columns with X, Y in the name (coordinates) and uuid (unique identifier)
            !includes(['X', 'Y', 'uuid'], name)
          );
        });

        return compact(
          map(parquetColumnsMetadata, (columnMetadata) => {
            const columnName = columnMetadata?.name;

            const columnType: FeatureMetadata['columnType'] =
              columnName === 'label' || columnName.startsWith(markerPositivityColumnPrefix)
                ? 'categorical'
                : columnName.startsWith(markerProbabilityColumnPrefix)
                ? 'float'
                : undefined;

            const columnIndex =
              findIndex(parquetData?.metadata?.schema, (column: SchemaElement) => column?.name === columnName) - 1;

            if (columnIndex < 0) {
              return undefined;
            }

            const rowData = map(parquetData?.rows, (row) => row[columnIndex]);

            const columnValues =
              columnName === 'label'
                ? uniq(rowData)
                : columnName.startsWith(markerPositivityColumnPrefix)
                ? [1, 0]
                : undefined;

            const key = `${parquetUrl}-${columnName}`;

            return {
              id: key,
              key,
              heatmapUrl: parquetUrl,
              heatmapType: HeatmapType.Parquet,
              columnName,
              columnType,
              options: map(columnValues, (value) => ({ key: value })),
              nestedItems: map(columnValues, (value) => ({ id: value, key: value, type: HeatmapType.Parquet })),
              originalIdentifier: parquetHeatmap?.originalIdentifier,
            };
          })
        ) as FeatureMetadataFromUrl[];
      }),
    [parquetFiles]
  );

  // No standard metadata for GeoJson heatmaps yet

  const geoJsonHeatmapsWithoutMetadata = heatmapsByType[HeatmapType.GeoJson];
  const geoJsonFiles = useGeoJsonFiles(uniq(map(geoJsonHeatmapsWithoutMetadata, 'heatmapUrl')));

  const computedGeoJsonFeatureMetadata = useMemo(
    () =>
      flatMap(geoJsonFiles, (parsedGeoJsonQuery, geoJsonUrl) => {
        const geoJsonHeatmap = find(geoJsonHeatmapsWithoutMetadata, { heatmapUrl: geoJsonUrl });
        const parsedGeoJson = parsedGeoJsonQuery?.data;
        const geoJsonLayers: string[] = uniq(compact(map(parsedGeoJson?.features, 'properties.class_name')));

        if (isEmpty(geoJsonLayers)) {
          return [];
        }

        const cellKey = `${geoJsonUrl}-cells`;
        const heatmapKey = `${geoJsonUrl}-heatmap`;

        return [
          {
            id: cellKey,
            key: cellKey,
            originalIdentifier: geoJsonHeatmap?.originalIdentifier,
            heatmapUrl: geoJsonUrl,
            heatmapType: HeatmapType.GeoJson,
            options: map(geoJsonLayers, (layer) => ({ key: layer })),
            nestedItems: map(geoJsonLayers, (layer) => ({ id: layer, key: layer, type: HeatmapType.GeoJson })),
          },
          {
            id: heatmapKey,
            key: heatmapKey,
            originalIdentifier: geoJsonHeatmap?.originalIdentifier,
            heatmapUrl: geoJsonUrl,
            heatmapType: HeatmapType.GeoJson,
            options: map(geoJsonLayers, (layer) => ({ key: layer })),
            nestedItems: map(geoJsonLayers, (layer) => ({ id: layer, key: layer, type: HeatmapType.GeoJson })),
          },
        ] as FeatureMetadataFromUrl[];
      }),
    [geoJsonFiles]
  );

  const fileUrlHeatmaps = useMemo(
    () => [
      ...pmtHeatmapsWithMetadata,
      ...parquetHeatmapsWithMetadata,
      ...computedPmtHeatmapMetadata,
      ...computedGeoJsonFeatureMetadata,
      ...computedParquetFeatureMetadata,
    ],
    [pmtHeatmapsWithMetadata, parquetHeatmapsWithMetadata, computedGeoJsonFeatureMetadata]
  );

  const isLoading =
    isLoadingPresignedUrls ||
    some(pmtTilesQueries, (query) => query?.isLoading) ||
    some(parquetFiles, (query) => query?.isLoading) ||
    some(geoJsonFiles, (query) => query?.isLoading);

  return {
    fileUrlHeatmaps,
    isLoading,
  };
};
