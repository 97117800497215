import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { Box, Button, Grid } from '@mui/material';
import { CellRuleValue } from 'interfaces/cellRule';
import { filter, map } from 'lodash';
import React from 'react';
import { cellRuleWidth } from '.';
import CellRuleDisplay from './CellRuleGridCell/CellRuleDisplay';
import { cellRuleOptions } from './CellRuleGridCell/CellRuleDropdown';

export interface CellRuleSelectionToolbarProps {
  onSelectRuleValue: (ruleValue: CellRuleValue) => void;
  finishSelectionText?: string;
  onSelectionFinished?: () => void;
  isCompact?: boolean;
  handleShowCompactToggle?: (isCompacting: boolean) => void;
}

const CellRuleSelectionToolbar: React.FC<CellRuleSelectionToolbarProps> = ({
  onSelectRuleValue,
  finishSelectionText,
  onSelectionFinished,
  isCompact,
  handleShowCompactToggle,
}) => {
  const showFinishSelectionButton = Boolean(onSelectionFinished);
  const showCompactToggle = Boolean(handleShowCompactToggle);

  return (
    <Grid container spacing={1} direction="row" alignItems="center" justifyContent="space-between" padding={1}>
      <Grid item xs={6} container direction="row" spacing={1} alignItems="center">
        <Grid item>Select All:</Grid>
        {map(
          filter(cellRuleOptions, (ruleOption) => ruleOption !== CellRuleValue.Indifferent),
          (option) => (
            <Grid item>
              <Button key={option} onClick={() => onSelectRuleValue(option)} size="small" variant="outlined">
                <Box
                  sx={{
                    width: cellRuleWidth,
                    height: cellRuleWidth,
                  }}
                >
                  <CellRuleDisplay ruleValue={option} />
                </Box>
              </Button>
            </Grid>
          )
        )}
      </Grid>
      <Grid item xs={6} container direction="row" spacing={1} justifyContent="flex-end">
        {showFinishSelectionButton && (
          <Grid item>
            <Button startIcon={<KeyboardReturnIcon />} onClick={onSelectionFinished}>
              {finishSelectionText ?? 'Finish Selection'}
            </Button>
          </Grid>
        )}
        {showCompactToggle && (
          <Grid item>
            <Button onClick={() => handleShowCompactToggle(!isCompact)}>{isCompact ? 'expand' : 'compact'}</Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default CellRuleSelectionToolbar;
