import {
  compact,
  concat,
  Dictionary,
  filter,
  flatMapDeep,
  flatten,
  groupBy,
  isEmpty,
  isEqual,
  keyBy,
  map,
  some,
  values,
} from 'lodash';

import { Slide } from 'interfaces/slide';
import { FormatBracketsOptions } from 'utils/formatBrackets/formatBracketsOptions';
import { isHeatmapActive } from '../Infobar/slidesVisualizationAndConfiguration';
import { ChannelMetadata, channelsMetadataFromSlideChannels } from './channelMetadata';
import {
  FeatureMetadata,
  FeatureMetadataSecondaryAnalysisEntry,
  NestedHeatmap,
  ParsedResults,
} from './featureMetadata';
import { parseSlideExperimentResults } from './parseSlideExperimentResults';

export interface SlideChannelsAndResults {
  id: string;
  heatmapResults: {
    publishedResults: FeatureMetadata[];
    internalResults: { [key: string]: FeatureMetadata[] };
  };
  featuresResults: {
    publishedResults: FeatureMetadata[];
    internalResults: { [key: string]: FeatureMetadata[] };
  };
  internalHeatmaps: { [key: string]: FeatureMetadata[] };
  channelsMetadata: ChannelMetadata[];
  studyId: string;
}

export interface SlideWithChannelAndResults extends Slide, Partial<Omit<SlideChannelsAndResults, 'id'>> {
  viewerIndex: number;
}

export const isSlideLoadingChannelsOrResults = (slide: SlideWithChannelAndResults): boolean =>
  // Slide has experiment results with presentation info or non empty features) but no heatmaps or features were parsed
  (!isEmpty(
    filter(slide?.experimentResults, (result) => result.numFeatures > 0 || !isEmpty(result.presentationInfo))
  ) &&
    isEmpty(slide?.internalHeatmaps) &&
    isEmpty(slide?.heatmapResults?.internalResults) &&
    isEmpty(slide?.heatmapResults?.publishedResults) &&
    isEmpty(slide?.featuresResults?.internalResults) &&
    isEmpty(slide?.featuresResults?.publishedResults)) ||
  // Slide has no channels metadata but has channel marker types or channels
  (isEmpty(slide?.channelsMetadata) && (!isEmpty(slide?.channelMarkerTypes) || !isEmpty(slide?.channels)));

export const getSlidesChannelsAndResults = ({
  slides,
  studyId,
  formatBracketsOptions,
  skipVectorHeatmaps = false,
  fileUrlHeatmaps,
}: {
  slides: Slide[];
  studyId: string;
  formatBracketsOptions: FormatBracketsOptions;
  skipVectorHeatmaps?: boolean;
  fileUrlHeatmaps?: FeatureMetadata[];
}): Dictionary<SlideChannelsAndResults> => {
  return keyBy(
    map(slides, (slide) => {
      const emptyParsedResults: ParsedResults = {
        heatmaps: {
          publishedResults: [],
          internalResults: {},
        },
        features: {
          publishedResults: [],
          internalResults: {},
        },
      };
      const { channels, channelMarkerTypes, id, labId, experimentResults } = slide;
      const results = !formatBracketsOptions?.isLoading
        ? parseSlideExperimentResults(experimentResults || [], formatBracketsOptions, skipVectorHeatmaps)
        : emptyParsedResults;

      const channelsMetadata = channelsMetadataFromSlideChannels({
        channels,
        channelMarkerTypes,
        id,
        labId,
      });

      if (!isEmpty(experimentResults) && isEqual(results, emptyParsedResults) && !formatBracketsOptions?.isLoading) {
        console.warn(`Failed to parse results for slide ${id}`, { experimentResults, formatBracketsOptions });
      }

      return {
        id,
        heatmapResults: results.heatmaps,
        internalHeatmaps: {
          ...groupBy(fileUrlHeatmaps, 'originalIdentifier'),
          ...results.internalHeatmaps,
        },
        featuresResults: results.features,
        channelsMetadata,
        studyId,
      };
    }),
    'id'
  );
};

export const getAllFlatMapDeepHeatmaps = (heatmapOverlays: FeatureMetadata[]): FeatureMetadata[] =>
  flatMapDeep(compact(heatmapOverlays), (heatmap) =>
    compact(
      concat<FeatureMetadata | FeatureMetadataSecondaryAnalysisEntry | NestedHeatmap>(
        heatmap,
        heatmap?.secondaryResults || [],
        heatmap?.nestedItems || []
      )
    )
  );

export const getAllFlatMapDeepHeatmapsFromSlide = (
  slide: Pick<SlideWithChannelAndResults, 'heatmapResults' | 'internalHeatmaps'>
): Array<FeatureMetadata | FeatureMetadataSecondaryAnalysisEntry | NestedHeatmap> => {
  const baseLayerVisualizationSettings = compact(
    concat(
      slide?.heatmapResults?.publishedResults,
      flatten(values(slide?.heatmapResults?.internalResults)),
      flatten(values(slide?.internalHeatmaps))
    )
  );
  return getAllFlatMapDeepHeatmaps(baseLayerVisualizationSettings);
};

export const getAllHeatmapsFromSlide = (slide: SlideWithChannelAndResults) => {
  return [
    ...(slide?.heatmapResults?.publishedResults || []),
    ...flatten(values(slide?.heatmapResults?.internalResults || {})),
    ...flatten(values(slide?.internalHeatmaps || {})),
  ];
};

export const getAllFeaturesFromSlide = (slide: SlideWithChannelAndResults) => {
  return [
    ...(slide?.featuresResults?.publishedResults || []),
    ...flatten(values(slide?.featuresResults?.internalResults || {})),
  ];
};

export const getHeatmapsRequiringPreSigningFromSlide = (slide: SlideWithChannelAndResults) => {
  return filter(
    getAllHeatmapsFromSlide(slide),
    (heatmap) => heatmap?.id && Boolean(heatmap.heatmapUrl) && heatmap.heatmapUrl.startsWith('s3://')
  );
};

export const getHeatmapsActiveStateFromSlide = (slide: SlideWithChannelAndResults) => {
  return map(getHeatmapsRequiringPreSigningFromSlide(slide), (heatmap) => {
    const heatmapActive =
      isHeatmapActive({ heatmapId: heatmap.id, slideId: slide.id, viewerIndex: slide.viewerIndex }) ||
      some(heatmap?.secondaryResults, (secondaryResult) =>
        isHeatmapActive({
          heatmapId: secondaryResult.id,
          slideId: slide.id,
          viewerIndex: slide.viewerIndex,
        })
      );

    return { id: heatmap.id, slideId: slide.id, isHeatmapActive: heatmapActive };
  });
};
