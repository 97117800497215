import { useTheme } from '@mui/system';
import { CellRuleValue } from 'interfaces/cellRule';
import React, { useEffect, useRef } from 'react';
import { cellRuleWidth } from '..';
import { cellRuleColorMap } from './CellRuleDropdown';

interface CellRuleDisplayProps {
  ruleValue: CellRuleValue;
}

const CellRuleDisplay: React.FC<CellRuleDisplayProps> = ({ ruleValue }) => {
  const theme = useTheme();
  const canvasPattern = cellRuleColorMap[ruleValue];

  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');

    if (ctx) {
      // Set the canvas size to match the cell size
      canvas.width = cellRuleWidth;
      canvas.height = cellRuleWidth;

      // Fill the canvas with the given pattern
      ctx.fillStyle = canvasPattern;

      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Add the ruleValue in the middle
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillStyle = ruleValue === CellRuleValue.Indifferent ? theme.palette.text.primary : 'black';
      ctx.fillText('' + ruleValue, canvas.width / 2, canvas.height / 2);
    }
  }, [canvasPattern]);

  return (
    <canvas
      ref={canvasRef}
      style={{
        width: '100%',
        height: '100%',
        display: 'block',
      }}
    ></canvas>
  );
};

export default CellRuleDisplay;
