import { ExperimentResult, labelColumn } from 'interfaces/experimentResults';
import { filter, find, flatMap, groupBy, includes, isEmpty, map, size } from 'lodash';
import { FormatBracketsOptions } from 'utils/formatBrackets/formatBracketsOptions';
import { FeatureMetadata, HeatmapType, ParsedResults } from './featureMetadata';
import { parseFeatures } from './parseFeatures';
import { parseHeatmaps } from './parseHeatmaps';

/** For published heatmaps with the same display name, we want to keep the vector heatmap over the raster heatmap */
export const filterRedundantRasterHeatmaps = (heatmaps: FeatureMetadata[]): FeatureMetadata[] => {
  const heatmapsResultsSidebarGrouping = groupBy(
    heatmaps,
    (heatmap) =>
      `${heatmap.resultType || heatmap.flowClassName}-${heatmap.orchestrationId}-${heatmap.displayName}-${
        heatmap?.registeredFromStainTypeIndex ?? ''
      }-${heatmap.columnName && heatmap.columnName !== labelColumn ? heatmap.columnName : ''}`
  );

  return flatMap(heatmapsResultsSidebarGrouping, (heatmapsGroup) => {
    const vectorHeatmaps = filter(heatmapsGroup, ({ heatmapType }) =>
      includes([HeatmapType.Parquet, HeatmapType.Pmt], heatmapType)
    );
    return size(vectorHeatmaps) > 0 ? vectorHeatmaps : heatmapsGroup;
  });
};

export const parseSlideExperimentResults = (
  slideResultsRaw: ExperimentResult[],
  formatBracketsOptions: FormatBracketsOptions,
  skipVectorHeatmaps = false
): ParsedResults => {
  const slideResults = map(slideResultsRaw, (result) => {
    const primaryRunOrchestration = find(slideResultsRaw, { orchestrationId: result.primaryRunOrchestrationId });
    return {
      ...result,
      options: !isEmpty(result?.options) ? result?.options : primaryRunOrchestration?.options,
      presentationInfo: !isEmpty(result?.presentationInfo)
        ? result?.presentationInfo
        : primaryRunOrchestration?.presentationInfo,
    } as ExperimentResult;
  });

  const { publishedFeatures, internalFeatures, deletedFeatures } = parseFeatures(slideResults);

  const { publishedHeatmaps, publishableHeatmaps, internalHeatmaps, deletedHeatmaps } = parseHeatmaps(
    slideResults,
    formatBracketsOptions,
    skipVectorHeatmaps
  );

  const results: ParsedResults = {
    heatmaps: {
      publishedResults: filterRedundantRasterHeatmaps(publishedHeatmaps),
      internalResults: publishableHeatmaps,
      deletedResults: deletedHeatmaps,
    },
    features: {
      publishedResults: publishedFeatures,
      internalResults: internalFeatures,
      deletedResults: deletedFeatures,
    },
    internalHeatmaps,
  };

  return results;
};
