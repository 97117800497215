import { Button, CircularProgress, Grid } from '@mui/material';
import { compact, forEach, isNumber, map, some, uniq } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import { NumberParam, useQueryParam } from 'use-query-params';

import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { usePublishMode } from 'utils/usePublishMode';
import useResultsMutation from '../useResultsMutation';

interface Props {
  slideId: string;
  viewerIndex: number;
  studyId: string;
  internalHeatmaps: boolean;
  heatmaps: FeatureMetadata[];
}

export const UnpublishResultsButton: React.FC<Props> = ({
  slideId,
  viewerIndex,
  studyId: studyIdFromProps,
  internalHeatmaps,
  heatmaps,
}) => {
  const hasPublishedResultsWithoutInternalApproval = some(
    heatmaps,
    (heatmap) => heatmap.approved && !heatmap.internallyApproved
  );
  const { queryParams } = useEncodedFilters();
  const [caseIdFromParam] = useQueryParam('caseId', NumberParam);

  const [isPublishMode] = usePublishMode(viewerIndex);

  const params = useParams();
  const caseId = queryParams.slidesMode ? Number(caseIdFromParam) : Number(params.id);
  const studyId = studyIdFromProps || queryParams.filters?.studyId;

  const { handleFieldSave, isLoading } = useResultsMutation(caseId, studyId);
  const handleDisapproveResult = (orchestrationId: string) => {
    // TODO: We can allow approval based on studyId without caseId in the backend, so we can remove this check (and others in the mutation flow)
    if (!isNumber(caseId)) {
      console.error('caseId is not a number', caseId);
      return;
    }
    const heatmapResultTypes = uniq(compact(map(heatmaps, 'resultType')));
    const heatmapFlowClassNames = uniq(
      compact(map(heatmaps, ({ resultType, flowClassName }) => (resultType ? flowClassName : undefined)))
    );
    handleFieldSave({
      orchestrationId,
      slideId,
      fieldName: 'approved',
      value: false,
      resultTypes: heatmapResultTypes,
      flowClassNames: heatmapFlowClassNames,
    });
  };

  const handleFixInternalApprovalForResult = (orchestrationId: string) => {
    // TODO: We can allow approval based on studyId without caseId in the backend, so we can remove this check (and others in the mutation flow)
    if (!isNumber(caseId)) {
      console.error('caseId is not a number', caseId);
      return;
    }
    const heatmapResultTypes = uniq(compact(map(heatmaps, 'resultType')));
    const heatmapFlowClassNames = uniq(
      compact(map(heatmaps, ({ resultType, flowClassName }) => (resultType ? flowClassName : undefined)))
    );
    handleFieldSave({
      orchestrationId,
      slideId,
      fieldName: 'internallyApproved',
      value: true,
      resultTypes: heatmapResultTypes,
      flowClassNames: heatmapFlowClassNames,
    });
  };

  const handleDisapproveResults = () => {
    // should be only one orchestrationId
    const uniqOrchestrationIds = uniq(
      // Publishing / unpublishing is done on primaryRunOrchestrationId if it exists, otherwise on orchestrationId
      map(heatmaps, (heatmap) => heatmap.primaryRunOrchestrationId || heatmap.orchestrationId)
    );
    forEach(uniqOrchestrationIds, (orchestrationId) => {
      handleDisapproveResult(orchestrationId);
    });
  };

  const handleFixInternalApprovalForResults = () => {
    // should be only one orchestrationId
    const uniqOrchestrationIds = uniq(
      // Publishing / unpublishing is done on primaryRunOrchestrationId if it exists, otherwise on orchestrationId
      map(heatmaps, (heatmap) => heatmap.primaryRunOrchestrationId || heatmap.orchestrationId)
    );
    forEach(uniqOrchestrationIds, (orchestrationId) => {
      handleFixInternalApprovalForResult(orchestrationId);
    });
  };
  const [onConfirm, setOnConfirm] = React.useState<any>(null);

  return (
    <>
      {studyId && isPublishMode && !internalHeatmaps && (
        <Grid item>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setOnConfirm(handleDisapproveResults);
            }}
            disabled={isLoading}
          >
            Unpublish
            {isLoading && <CircularProgress size={18} sx={{ ml: 1 }} />}
          </Button>
          {hasPublishedResultsWithoutInternalApproval && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setOnConfirm(handleFixInternalApprovalForResults);
              }}
              title={
                "This will set the 'Internally Approved' status to true for results that are approved but not internally approved."
              }
              disabled={isLoading}
            >
              Fix Internally Approved Status
              {isLoading && <CircularProgress size={18} sx={{ ml: 1 }} />}
            </Button>
          )}
        </Grid>
      )}
      {onConfirm && (
        <ConfirmationModal
          title={onConfirm === handleDisapproveResults ? 'Unpublish Results' : 'Fix Internally Approved Status'}
          text={
            onConfirm === handleDisapproveResults
              ? 'Are you sure you want to unpublish the results?'
              : 'Are you sure you want to fix the internally approved status?'
          }
          onConfirm={() => {
            onConfirm();
            setOnConfirm(null);
          }}
          onCancel={() => setOnConfirm(null)}
        />
      )}
    </>
  );
};
