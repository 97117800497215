import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';

export interface DialogWithCloseButtonProps {
  header: string;
  onClose: () => void;
}

/**
 * A dialog with a close button in the header.
 * @param header The header text.
 * @param onClose The function to call when the dialog is closed.
 * @param children The content of the dialog.
 * @returns The dialog with a close button.
 */
export const DialogWithCloseButton: React.FC<React.PropsWithChildren<DialogWithCloseButtonProps>> = ({
  header,
  onClose,
  children,
}) => {
  return (
    <Dialog
      onClick={(e) => {
        onClose(); // close dialog when click outside of dialog content
        e.stopPropagation();
      }}
      open={true}
      onClose={onClose}
      maxWidth="xl"
      fullWidth
    >
      <DialogTitle>
        <Grid item container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h2">{header}</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        onClick={(e) => {
          e.stopPropagation(); // prevent the click from propagating to the parent to make the dialog stay open
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};
