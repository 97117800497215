import { UpdateParameters } from '@deck.gl/core/typed';
import { find, isNumber } from 'lodash';

import { GradientScatterplotLayer, GradientScatterplotLayerExtraProps } from '../GradientScatterPlotLayer';
import { ZoomBoundsProps } from './types';

interface ZoomAwareGradientScatterplotLayerExtraProps extends ZoomBoundsProps, GradientScatterplotLayerExtraProps {}

export class ZoomAwareGradientScatterplotLayer<
  DataT = any,
  ExtraPropsT extends ZoomAwareGradientScatterplotLayerExtraProps = ZoomAwareGradientScatterplotLayerExtraProps
> extends GradientScatterplotLayer<DataT, ExtraPropsT> {
  static defaultProps = GradientScatterplotLayer.defaultProps;

  static layerName: string = 'ZoomAwareGradientScatterplotLayer';

  constructor(props: any) {
    super(props);
    // replace props with a proxy object that will call getRadiusForViewport when getRadius is called
    const proxyProps = new window.Proxy(this.props, {
      get: (target, prop) => {
        if (prop === 'getRadius') {
          return this.state.radiusForZoom;
        }
        return Reflect.get(target, prop);
      },
    });
    this.props = proxyProps;
  }

  updateState(args: UpdateParameters<this>): void {
    super.updateState(args);
    this.state.radiusForZoom = this.getRadiusForViewport();
  }

  shouldUpdateState({ props, oldProps, context, changeFlags }: UpdateParameters<this>): boolean {
    const oldRadiusForZoom = this.state.radiusForZoom;
    const newRadiusForZoom = changeFlags.viewportChanged ? this.getRadiusForViewport() : oldRadiusForZoom;
    return super.shouldUpdateState({ props, oldProps, context, changeFlags }) || oldRadiusForZoom !== newRadiusForZoom;
  }

  getRadiusForViewport(): number {
    const { zoom } = this.context.viewport;
    const sizeInMicronsByZoomRange = this.props.sizeInMicronsByZoomRange || [];
    const slideMaxResolution = this.props.slideMaxResolution || 1;
    const sizeScale = this.props.radiusScale || 1;
    const relevantSizeRange = find(sizeInMicronsByZoomRange, ({ minZoom, maxZoom }) => {
      const minZoomMatch = !isNumber(minZoom) || isNaN(minZoom) || zoom >= minZoom;
      const maxZoomMatch = !isNumber(maxZoom) || isNaN(maxZoom) || zoom <= maxZoom;
      return minZoomMatch && maxZoomMatch;
    });
    if (!relevantSizeRange) {
      return undefined;
    }
    const { sizeInMicrons } = relevantSizeRange;
    const pointRadiusMicronsAtMaxZoom = Math.max(1, sizeInMicrons / sizeScale);
    // We want to render the markers on top of cells, so we want their minimal size to be 5.00 μm
    return pointRadiusMicronsAtMaxZoom / slideMaxResolution; // (μm) / (μm/px) = px
  }
}
