import { AlgoSourceMapping } from 'interfaces/algoSourceMapping';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getAlgorithms = (signal?: AbortSignal) => {
  return apiRequestHandlerPromise<AlgoSourceMapping[]>({
    url: 'algorithms',
    method: 'GET',
    signal,
  });
};

export const createAlgorithm = ({ id, ...createFields }: Omit<AlgoSourceMapping, 'id'> & { id?: string }) => {
  return apiRequestHandlerPromise<AlgoSourceMapping>({
    url: 'algorithms',
    method: 'POST',
    data: JSON.stringify(createFields),
  });
};

export const updateAlgorithm = ({ id, ...updateFields }: AlgoSourceMapping) => {
  return apiRequestHandlerPromise({
    url: `algorithms/${id}`,
    method: 'POST',
    data: JSON.stringify(updateFields),
  });
};
