import { COORDINATE_SYSTEM } from '@deck.gl/core/typed';
import { GeoJsonLayer } from '@deck.gl/layers/typed';
import { DeckGLProps } from '@deck.gl/react/typed';
import { useSignals } from '@preact/signals-react/runtime';
import { compact, concat, filter, flatMap, fromPairs, join, keys, map, reduce, sortBy, toPairs, values } from 'lodash';
import { useMemo } from 'react';

import { slidesLayerVisualizationSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { CalculateFeaturesJob } from 'interfaces/job';
import { useGetSecondaryAnalysisJobForHeatmaps } from 'utils/useGetSecondaryAnalysisJobForHeatmaps';
import { MultiPolygon } from '../NebulaGLExtensions/geojson-types';

/**
 * We need to fix the malformed MultiPolygon that comes from the API, which misses one dimension in the coordinates.
 * @param malformedMultiPolygon: The malformed MultiPolygon.
 * @returns The fixed MultiPolygon.
 */
const fixMultiPolygon = (malformedMultiPolygon: any): MultiPolygon => {
  if (!malformedMultiPolygon?.coordinates) {
    return malformedMultiPolygon;
  }
  return {
    type: 'MultiPolygon',
    coordinates: [malformedMultiPolygon.coordinates],
  };
};

/**
 * Hook to draw the ROIs for heatmaps of secondary analysis jobs
 * (i.e. the deck.gl layers that show the inclusion and exclusion polygons of visible secondary analysis heatmaps).
 * @param slide The slide for which to draw the secondary analysis job ROI layers.
 * @param rescaleFactor The rescale factor to apply to the line width of the layers.
 * @returns The configured layers for the secondary analysis job ROI.
 */
export const useSecondaryAnalysisJobRoiLayers = ({
  slide,
  rescaleFactor,
}: {
  slide: SlideWithChannelAndResults;
  rescaleFactor?: number;
}): DeckGLProps['layers'] => {
  useSignals();
  const slideId = slide?.id;
  const viewerIndex = slide?.viewerIndex;
  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];

  const heatmapsSettings = viewerSlideLayerVisualizationSettings?.value?.[slideId];

  const jobsQuery = useGetSecondaryAnalysisJobForHeatmaps({ slide });

  const jobsInQuery = jobsQuery.data?.jobs;

  const jobVisualSettings = fromPairs(
    map(jobsInQuery, (job: CalculateFeaturesJob) => [job.id, heatmapsSettings?.[job?.id]?.value])
  );

  const jobSecondaryAnalysisPolygons = useMemo(() => {
    const selectedJobs = filter(jobsInQuery, (job) =>
      Boolean(jobVisualSettings[job.id]?.show && jobVisualSettings[job.id]?.selected)
    );
    return fromPairs(
      map(selectedJobs, (job) => {
        const polygonsFromJobs = compact(
          flatMap(values(job?.params?.secondaryAnalysisPolygons), (caseSlidePolygons) => values(caseSlidePolygons))
        );
        return [
          job.id,
          {
            inclusionMultipolygon: compact(map(polygonsFromJobs, 'inclusionMultipolygon')) as MultiPolygon[],
            exclusionMultipolygon: compact(map(polygonsFromJobs, 'exclusionMultipolygon')) as MultiPolygon[],
          },
        ];
      })
    );
  }, [jobsInQuery, JSON.stringify(jobVisualSettings)]);

  const visibleJobIdsPrefix = join(
    map(
      sortBy(keys(jobVisualSettings)),
      (jobId) => jobVisualSettings[jobId]?.show && jobVisualSettings[jobId]?.selected
    ),
    '+'
  );

  return useMemo(
    () =>
      flatMap(toPairs(jobSecondaryAnalysisPolygons), ([jobId, jobSecondaryAnalysisPolygon]) => {
        const jobOpacity = (jobVisualSettings[jobId]?.opacity ?? 0) / 100;
        const inclusionGeojson = reduce(
          jobSecondaryAnalysisPolygon.inclusionMultipolygon,
          (acc, inclusionMultipolygon) => {
            return {
              type: 'FeatureCollection',
              features: concat(acc.features, {
                type: 'Feature',
                geometry: fixMultiPolygon(inclusionMultipolygon) as MultiPolygon,
              }),
            };
          },
          { type: 'FeatureCollection', features: [] }
        );
        const inclusionGeojsonLayers = map(
          inclusionGeojson?.features,
          (feature, index) =>
            new GeoJsonLayer({
              id: `${visibleJobIdsPrefix}-inclusionGeojsonLayer-${index}`,
              coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
              data: feature,
              pickable: false,
              stroked: true,
              filled: false,
              getLineColor: [0, 255, 0],
              getLineWidth: 200,
              opacity: jobOpacity,
              lineWidthMinPixels: 1,
              lineWidthMaxPixels: 5,
              lineWidthScale: rescaleFactor ? 1 / rescaleFactor : 1,
            })
        );

        const exclusionGeojson = reduce(
          jobSecondaryAnalysisPolygon.exclusionMultipolygon,
          (acc, exclusionMultipolygon) => {
            return {
              type: 'FeatureCollection',
              features: concat(acc.features, {
                type: 'Feature',
                geometry: fixMultiPolygon(exclusionMultipolygon) as MultiPolygon,
              }),
            };
          },
          { type: 'FeatureCollection', features: [] }
        );
        const exclusionGeojsonLayers = map(
          exclusionGeojson?.features,
          (feature, index) =>
            new GeoJsonLayer({
              id: `${visibleJobIdsPrefix}-exclusionGeojsonLayer-${index}`,
              coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
              data: feature,
              pickable: false,
              stroked: true,
              filled: false,
              getLineColor: [255, 0, 0],
              getLineWidth: 200,
              opacity: jobOpacity,
              lineWidthMinPixels: 1,
              lineWidthMaxPixels: 5,
              lineWidthScale: rescaleFactor ? 1 / rescaleFactor : 1,
            })
        );

        return compact(concat<GeoJsonLayer>(inclusionGeojsonLayers, exclusionGeojsonLayers));
      }),
    [jobSecondaryAnalysisPolygons, visibleJobIdsPrefix, JSON.stringify(jobVisualSettings)]
  );
};
