import Button, { ButtonProps } from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { darken, useTheme } from '@mui/system';
import { useSignals } from '@preact/signals-react/runtime';
import { defaultLayerColors } from 'components/theme/theme';
import { sortBy } from 'lodash';
import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { getColorHex } from 'utils/helpers';

export const ColorPickerButton: React.FC<
  React.PropsWithChildren<{
    hexColor?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    disable?: boolean;
    sx?: ButtonProps['sx'];
  }>
> = ({ hexColor, onClick, disable = false, sx }) => {
  const disableEditing = !onClick;
  return (
    <Button
      role="color-picker"
      size="small"
      sx={{
        ...sx,
        minWidth: 17,
        height: 17,
        mr: 1,
        display: 'flex',
        ...(!disable && hexColor ? { backgroundColor: hexColor } : {}),
        '&:hover': hexColor
          ? {
              backgroundColor: darken(hexColor, 0.5), // Preserve color on hover
            }
          : {},
        ...(disableEditing && !disable && hexColor // If we only disable editing, we want to show the color but not allow editing
          ? {
              '&.Mui-disabled': {
                backgroundColor: hexColor,
              },
            }
          : {}),
      }}
      variant="contained"
      disabled={disable || disableEditing}
      onClick={onClick}
    />
  );
};

export const ColorPicker: React.FC<
  React.PropsWithChildren<{
    currentColor: any;
    onColorChange?: (color: string) => void;
    disable?: boolean;
    disableEditing?: boolean; // Still shows the color picker but disables editing
    applyChangeWhenFocusOut?: boolean; // call the onColorChange only when the user clicks save
    buttonSx?: ButtonProps['sx'];
  }>
> = ({
  currentColor,
  onColorChange,
  disable = false,
  disableEditing = false,
  applyChangeWhenFocusOut = false,
  buttonSx,
}) => {
  useSignals();
  const theme = useTheme();
  if (!currentColor) {
    currentColor = theme.palette.primary.main;
  }
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [temporaryColor, setTemporaryColor] = useState(currentColor);

  const colorToShow = applyChangeWhenFocusOut ? temporaryColor : currentColor;

  const openColorPopOver = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closeColorPopOver = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (applyChangeWhenFocusOut && reason === 'backdropClick') {
      onColorChange(temporaryColor);
    } else if (reason !== 'backdropClick') {
      setTemporaryColor(currentColor);
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const hexColor = getColorHex(colorToShow);

  return (
    <>
      <ColorPickerButton
        hexColor={hexColor}
        onClick={!disableEditing ? openColorPopOver : undefined}
        disable={disable}
        sx={buttonSx}
      />
      <Popover
        id={id}
        open={open && !disable && !disableEditing}
        anchorEl={anchorEl}
        onClose={closeColorPopOver}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <SketchPicker
          color={colorToShow}
          onChange={(color) => {
            if (!onColorChange) console.error('ColorPicker: onColorChange is required when not disabled');
            else if (!applyChangeWhenFocusOut) onColorChange(color?.hex);
            else setTemporaryColor(color?.hex);
          }}
          presetColors={sortBy(defaultLayerColors)}
        />
      </Popover>
    </>
  );
};
