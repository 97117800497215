import { Grid, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getImages } from 'api/platform';
import LabelledDropdown from 'components/atoms/Dropdown/LabelledDropdown';
import { Study } from 'interfaces/study';
import { concat, map, orderBy } from 'lodash';
import React, { useMemo } from 'react';

const DEV_IMAGE_INTERNAL_NAME = '---dev---';

// Prefix string + 3 numbers (semver) + optional suffix
// i.e. 562785346226.dkr.ecr.us-east-1.amazonaws.com/algo:git-v1.1.5-rc7-full
// -> ("562785346226.dkr.ecr.us-east-1.amazonaws.com/algo:git-v", "1", "1", "5", "-rc7-full")
const imageNameRegex = /^(.*)(\d+)\.(\d+)\.(\d+)(.*)$/;

function sortDropdownObjectByImageName<T extends { text: string } = { text: string }>(arr: Array<T>): Array<T> {
  return orderBy(
    arr,
    [
      ({ text }) => {
        const match = text.match(imageNameRegex);
        return match ? match[1] : text; // Prefix (string)
      },
      ({ text }) => {
        const match = text.match(imageNameRegex);
        return match ? Number(match[2]) : 0; // Major version (numeric)
      },
      ({ text }) => {
        const match = text.match(imageNameRegex);
        return match ? Number(match[3]) : 0; // Minor version (numeric)
      },
      ({ text }) => {
        const match = text.match(imageNameRegex);
        return match ? Number(match[4]) : 0; // Patch version (numeric)
      },
      ({ text }) => {
        const match = text.match(imageNameRegex);
        return match ? match[5] : ''; // Suffix (string)
      },
    ],
    ['desc', 'desc', 'desc', 'desc', 'desc']
  );
}

export const PlatformSettings: React.FC<
  React.PropsWithChildren<{
    updatedSettings: Study['platformSettings'];
    setUpdatedSettings: React.Dispatch<React.SetStateAction<Study['platformSettings']>>;
  }>
> = ({ updatedSettings, setUpdatedSettings }) => {
  const { data: platformImages, isLoading: isLoadingPlatformImages } = useQuery({
    queryKey: ['platformImages'],
    queryFn: ({ signal }) => getImages(signal),
  });

  const orderedPlatformImageOptions = useMemo(
    () => sortDropdownObjectByImageName(map(platformImages, (image) => ({ value: image, text: image }))),
    [platformImages]
  );

  return (
    <Grid
      sx={{
        p: 2,
      }}
      container
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography variant="subtitle2">Platform Images</Typography>
      </Grid>
      <Grid item xs={12}>
        <LabelledDropdown
          label="Docker Image Selection"
          options={concat(orderedPlatformImageOptions, { value: DEV_IMAGE_INTERNAL_NAME, text: 'dev' })}
          value={updatedSettings?.dockerImage || DEV_IMAGE_INTERNAL_NAME}
          onOptionSelected={(optionValue) => {
            setUpdatedSettings((prevSettings) => ({
              ...prevSettings,
              dockerImage: optionValue !== DEV_IMAGE_INTERNAL_NAME ? optionValue : undefined,
            }));
          }}
          loading={isLoadingPlatformImages}
        />
      </Grid>
    </Grid>
  );
};
