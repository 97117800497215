/* eslint-disable no-undef */
import { CompositeLayer, Layer } from '@deck.gl/core/typed';
import GL from '@luma.gl/constants';
import { Matrix4 } from '@math.gl/core';
import { castArray, compact, isEmpty, map, omit, some } from 'lodash';
import { ClassicComponentClass } from 'react';

import { ColorPerChannelExtension } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/StainsLayers/extensions/xrLayer/';
import {
  MultiScaleImageLayerProps,
  PhotometricInterpretation,
} from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/StainsLayers/types';
import { ADDITIONAL_ZOOM_OUT_FACTOR } from 'components/Procedure/SlidesViewer/DeckGLViewer/slidesViewerState';
import { isInterleaved } from '../utils';
import { BackgroundLayer } from './backgroundLayer';
import CustomTileLayer from './customTileLayer';
import getTileDataFromUrls from './getTileData';
import { getTileDataFromUrlsWebWorker } from './getTileDataFromUrlsWebWorker';
import { renderBitmapBinarySubLayers, renderXRSubLayers } from './sublayerRenderers';
import { MultiScaleImageData } from './utils';

export const BACKGROUND_LAYER_ID = '__background__' as const;
export const OVERVIEW_LAYER_ID = '__overview-only__' as const;

const defaultProps: ClassicComponentClass['defaultProps'] = {
  pickable: { type: 'boolean', value: true, compare: true },
  onHover: { type: 'function', value: null, compare: false },
  contrastLimits: { type: 'array', value: [], compare: true },
  layerOpacities: { type: 'array', value: [], compare: true },
  layersVisible: { type: 'array', value: [], compare: true },
  onClick: { type: 'function', value: null, compare: true },
  excludeBackground: { type: 'boolean', value: false, compare: true },
  extensions: { type: 'array', value: [new ColorPerChannelExtension()], compare: true },
  skipWebWorker: { type: 'boolean', value: true, compare: true },
};

const MultiScaleImageLayer = class<S extends string[] = []> extends CompositeLayer<MultiScaleImageLayerProps<S>> {
  state: {
    getTileData: ({
      index: { x, y, z },
    }: {
      index: { x: number; y: number; z: number };
    }) => Promise<MultiScaleImageData>;
    backgroundData: Promise<MultiScaleImageData>;
  };

  updateState: Layer<MultiScaleImageLayerProps<S>>['updateState'] = ({ props, oldProps }) => {
    if (
      Boolean(props.skipWebWorker) !== Boolean(oldProps.skipWebWorker) ||
      props.layerSource !== oldProps.layerSource ||
      props.selections !== oldProps.selections ||
      props.layersVisible !== oldProps.layersVisible ||
      Boolean(props.loadChannelsWhenHidden) !== Boolean(oldProps.loadChannelsWhenHidden)
    ) {
      const isRgb = props.layerSource.meta.photometricInterpretation === PhotometricInterpretation.RGB;
      const usePngDecoder = props.layerSource.meta.usePngDecoder;
      const minZoom = props.layerSource.minLevel - props.layerSource.maxLevel;

      const getTileData: typeof this.state.getTileData = ({ index: { x, y, z } }) => {
        // Early return if no selections
        if (isEmpty(props.selections)) {
          console.warn('No selections provided to getTileDataFromUrls');
          return null;
        }
        const urls = map(props.selections, (selection, channelIndex) =>
          props.loadChannelsWhenHidden || castArray(props.layersVisible)[channelIndex]
            ? props.layerSource.getTileURL({ x, y, z, ...selection })
            : null
        );
        const getTileDataFromUrlsParams = {
          selectionUrls: urls,
          isRgb,
          usePngDecoder,
          viewerIndex: props.viewerIndex,
          tileCoordinates: { x, y, z },
          overlapPixels: props.layerSource.getOverlapPixels(),
          tileSize: props.layerSource.getTileSize(),
          cropDecodedPngTiles: props.cropDecodedPngTiles,
        };
        return props.skipWebWorker
          ? getTileDataFromUrls(getTileDataFromUrlsParams)
          : getTileDataFromUrlsWebWorker(getTileDataFromUrlsParams);
      };
      const newState: typeof this.state = {
        getTileData,
        backgroundData: getTileData({ index: { x: 0, y: 0, z: minZoom } }),
      };
      this.setState(newState);
    }
  };

  renderLayers() {
    const propWithoutZoomBounds = omit(this.props, 'minZoom', 'maxZoom');
    const { baseImageSource, layerSource, onTileError, id, layerOpacities, layersVisible, overviewLayer, isOverlay } =
      propWithoutZoomBounds;

    const isRgb = this.props.layerSource.meta.photometricInterpretation === PhotometricInterpretation.RGB;

    const minZoom = layerSource.minLevel - layerSource.maxLevel;
    // Max zoom is 0 because that is the basis for the coordinate system (based on the image pixel size)
    const maxZoom = 0;

    const multipleChannelsVisible = some(
      castArray(layersVisible),
      (visible, layerIndex) => visible && castArray(layerOpacities)[layerIndex] !== 0
    );

    const hasSomeOpacity = some(castArray(layerOpacities), (op) => op !== 100 && op !== 0);

    const baseImageSize = baseImageSource ? baseImageSource.getImageSize() : undefined;
    const layerSourceSize = baseImageSize ? layerSource.getImageSize() : undefined;

    const scale =
      baseImageSize && layerSourceSize
        ? [baseImageSize.height / layerSourceSize.height, baseImageSize.width / layerSourceSize.width, 1]
        : undefined;

    // Does the source have interleaved data (rgb) or intensity values (R only)?
    const interleavedSource = isInterleaved(layerSource.shape);

    const avoidOverlappingTiles =
      (interleavedSource && multipleChannelsVisible) || some(castArray(layerOpacities), (op) => op !== 100);

    const baseTileLayerProps = {
      id: `Tiled-Image-${id}-${layerSource.getUniqueId()}`,
      tileSize: layerSource.getTileSize(),
      preloadZoomLevels: 1,
      modelMatrix: scale ? new Matrix4().scale(scale) : undefined,

      renderSubLayers: (isRgb ? renderBitmapBinarySubLayers : renderXRSubLayers) as any,
      onTileError: onTileError || layerSource.onTileError,
      refinementStrategy: avoidOverlappingTiles ? ('no-overlap' as const) : ('best-available' as const),
      getTileData: this.state.getTileData,
      updateTriggers: { getTileData: this.state.getTileData },
      ...(isOverlay
        ? {
            parameters: {
              /*
               * If the blend function is set to gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA),
               * it expects colors to be provided in premultiplied alpha form where the r, g and b
               * values are already multiplied by the a value. If you are unable to provide colors in
               * premultiplied form you may want to change the blend function to
               * gl.blendFuncSeparate(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA, gl.ONE, gl.ONE_MINUS_SRC_ALPHA).
               */
              // blendFunc: [GL.ONE, GL.ONE_MINUS_SRC_ALPHA],
              blendFunc: [GL.SRC_ALPHA, GL.ONE_MINUS_SRC_ALPHA, GL.ONE, GL.ONE_MINUS_SRC_ALPHA],
            },
          }
        : {}),
    };

    const tiledLayer = new CustomTileLayer<MultiScaleImageData, MultiScaleImageLayerProps<S>>(propWithoutZoomBounds, {
      ...baseTileLayerProps,
      minZoom,
      maxZoom,
      maxCacheSize: 5000,
    });

    const excludeBackground = propWithoutZoomBounds.excludeBackground;
    const backgroundLayerId = `${overviewLayer ? OVERVIEW_LAYER_ID : BACKGROUND_LAYER_ID}-${baseTileLayerProps.id}`;
    const bgLayer =
      (overviewLayer || !excludeBackground) && this.state.getTileData
        ? new BackgroundLayer({
            ...propWithoutZoomBounds,
            ...baseTileLayerProps,
            id: backgroundLayerId,
            data: this.state.backgroundData,
            zoomForBackgroundTile: minZoom,
            hideAfterMaxZoom: !overviewLayer && interleavedSource && hasSomeOpacity,
            // For interleaved sources with opacity, don't show the background layer when tiles are visible, since it will cause blending issues
            maxZoom: !overviewLayer && interleavedSource ? minZoom - 1 : maxZoom,
            minZoom: minZoom - ADDITIONAL_ZOOM_OUT_FACTOR * 10,
          })
        : undefined;

    const foregroundLayer = !overviewLayer ? tiledLayer : null;
    return compact([bgLayer, foregroundLayer]);
  }
};

MultiScaleImageLayer.layerName = 'MultiScaleImageLayer';
MultiScaleImageLayer.defaultProps = defaultProps;
export default MultiScaleImageLayer;
