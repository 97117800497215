import { useSignals } from '@preact/signals-react/runtime';
import { QueryFunctionContext, useQueries } from '@tanstack/react-query';
import {
  cloneDeep,
  compact,
  filter,
  find,
  flatMap,
  forEach,
  includes,
  isEmpty,
  isEqual,
  join,
  map,
  some,
  uniq,
} from 'lodash';
import { useEffect, useMemo, useState, useTransition } from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';

import { getPreSignedPresentationInfo } from 'api/experimentResults';
import { Accession } from 'interfaces/accession';
import { Procedure } from 'interfaces/procedure';
import { useFormatBracketsOptions } from 'utils/formatBrackets/formatBracketsOptions';
import { useSelectedSlideIds } from 'utils/useCurrentSlideIds';
import { useFileUrlHeatmaps } from './useFileUrlHeatmaps';

import {
  getHeatmapsActiveStateFromSlide,
  getHeatmapsRequiringPreSigningFromSlide,
  getSlidesChannelsAndResults,
  isSlideLoadingChannelsOrResults,
  SlideWithChannelAndResults,
} from './utils';

export const useSlideChannelsAndResults = (procedure: Procedure | Accession) => {
  useSignals();
  const [useOSDViewer] = useQueryParam('useOSDViewer', BooleanParam);

  const [heatmapsRequiringPreSigning, setHeatmapsRequiringPreSigning] = useState<number[]>([]);
  const formatBracketsOptions = useFormatBracketsOptions(false);

  const studyId = procedure?.studyId;

  const preSignedExperimentResultsQueries = useQueries({
    queries: map(heatmapsRequiringPreSigning, (experimentResultId) => ({
      queryKey: ['getPreSignedExperimentResultPresentationInfo', { studyId, experimentResultId }],
      queryFn: ({ signal }: QueryFunctionContext) => getPreSignedPresentationInfo(studyId, experimentResultId, signal),
      enabled: Boolean(studyId),
    })),
  });

  const { fileUrlHeatmaps, isLoading: isLoadingFileUrlHeatmaps } = useFileUrlHeatmaps();

  const slidesChannelsAndResults = useMemo(() => {
    const preSignedExperimentResults = map(preSignedExperimentResultsQueries, 'data');
    const procedureSlides = cloneDeep(procedure?.slides || []);
    forEach(procedureSlides, (slide) => {
      forEach(compact(slide?.experimentResults), (experimentResult) => {
        const presignedPresentationInfo = find(
          preSignedExperimentResults,
          (data) => !isEmpty(data?.[experimentResult.experimentResultId])
        );
        if (presignedPresentationInfo) {
          experimentResult.presentationInfo = presignedPresentationInfo[experimentResult.experimentResultId];
        }
      });
    });
    return getSlidesChannelsAndResults({
      slides: procedureSlides,
      studyId,
      formatBracketsOptions,
      skipVectorHeatmaps: useOSDViewer,
      fileUrlHeatmaps,
    });
  }, [
    procedure?.slides,
    studyId,
    join(map(preSignedExperimentResultsQueries, 'dataUpdatedAt'), '_'),
    JSON.stringify(formatBracketsOptions),
    useOSDViewer,
    fileUrlHeatmaps,
  ]);

  const [selectedSlideIds] = useSelectedSlideIds(procedure);

  const [selectedSlidesWithChannelsAndResults, setSelectedSlidesWithChannelsAndResults] = useState<
    SlideWithChannelAndResults[] | null
  >(null);
  useEffect(() => {
    const newSlidesWithChannelsAndResults = compact(
      map(selectedSlideIds, (slideId, viewerIndex) => {
        const slide = find(procedure.slides, { id: slideId });
        if (!slide) {
          return undefined;
        }

        // Warn if there are results with presentation info but no options
        const heatmapsWithPresentationInfoAndWithoutOptions = filter(
          slide.experimentResults,
          (result) => !isEmpty(result?.presentationInfo) && isEmpty(result.options)
        );

        if (!isEmpty(heatmapsWithPresentationInfoAndWithoutOptions)) {
          console.warn(
            'Found results with presentation info but no options',
            heatmapsWithPresentationInfoAndWithoutOptions
          );
        }

        return {
          viewerIndex,
          ...(slidesChannelsAndResults[slide.id] || {}),
          ...slide,
        };
      })
    );
    setSelectedSlidesWithChannelsAndResults((oldData) =>
      !isEqual(oldData, newSlidesWithChannelsAndResults) ? newSlidesWithChannelsAndResults : oldData
    );
  }, [procedure.slides, slidesChannelsAndResults, selectedSlideIds]);

  const [, startTransition] = useTransition();

  const heatmapActiveStates = flatMap(selectedSlidesWithChannelsAndResults, (slide) =>
    getHeatmapsActiveStateFromSlide(slide)
  );
  useEffect(() => {
    const newHeatmapsRequiringPreSigning: number[] = [];
    forEach(selectedSlidesWithChannelsAndResults, (slide) => {
      const heatmapsRequiringPreSigningForSlide = getHeatmapsRequiringPreSigningFromSlide(slide);
      const activeHeatmapsRequiringPreSigningForSlide = filter(
        heatmapsRequiringPreSigningForSlide,
        (heatmap) =>
          find(heatmapActiveStates, {
            id: heatmap.id,
            slideId: slide.id,
          })?.isHeatmapActive
      );
      newHeatmapsRequiringPreSigning.push(
        ...compact(map(activeHeatmapsRequiringPreSigningForSlide, 'experimentResultId'))
      );
    });
    if (some(newHeatmapsRequiringPreSigning, (id) => !includes(heatmapsRequiringPreSigning, id))) {
      startTransition(() => {
        setHeatmapsRequiringPreSigning((prev) => uniq([...prev, ...newHeatmapsRequiringPreSigning]));
      });
    }
  }, [JSON.stringify(heatmapActiveStates)]);

  const isLoadingSlideChannelsAndResults =
    isEmpty(selectedSlidesWithChannelsAndResults) ||
    some(selectedSlidesWithChannelsAndResults, isSlideLoadingChannelsOrResults);

  return {
    selectedSlidesWithChannelsAndResults,
    isLoadingSlideChannelsAndResults,
    isLoadingPresignedUrls: some(preSignedExperimentResultsQueries, 'isLoading'),
    isLoadingFileUrlHeatmaps,
  };
};
