import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Box, Grid, IconButton, Slider, SliderProps, Tooltip, TooltipProps, Typography } from '@mui/material';
import numeral from 'numeral';
import React from 'react';
import { ReactComponent as EyeDisabled } from './eye-disabled.svg';
import { ReactComponent as EyeEnabled } from './eye-enabled.svg';

interface Props {
  sliderProps?: Partial<SliderProps>;
  tooltipProps?: Partial<TooltipProps>;
  value: number;
  show: boolean;
  disabled?: boolean;
  alwaysShowValue?: boolean;
  onToggleChange: () => void;
  onValueChange: (value: number) => void;
}

const VerticalSliderPopup: React.FC<Props> = ({
  sliderProps,
  tooltipProps,
  alwaysShowValue,
  show,
  disabled,
  value,
  onToggleChange,
  onValueChange,
}) => {
  const calculatedValue = alwaysShowValue || (!disabled && show) ? value : 0;

  const [keepOpen, setKeepOpen] = React.useState(false);

  const saveValueFromChange = (newValue: number) => {
    if (typeof newValue === 'number') {
      onValueChange(newValue);
      if (newValue > 0 && !show) {
        onToggleChange();
      }
    }
  };

  const isVisible = !disabled && show && value > 0;

  return (
    <Grid container wrap="nowrap" alignItems="center" onClick={(e) => e.stopPropagation()}>
      <Tooltip
        placement="right"
        open={keepOpen || undefined}
        slotProps={{
          popper: {
            sx: {
              ['& .MuiTooltip-tooltip']: {
                backgroundColor: 'background.paper',
                borderRadius: 4,
                boxShadow: 2,
              },
            },
            modifiers: [{ name: 'offset', options: { offset: [0, -14] } }],
          },
        }}
        color="primary"
        {...(tooltipProps || {})}
        title={
          !disabled && (
            <Box
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 150, pt: 1, pb: 1 }}
              onClick={(e) => e.stopPropagation()}
            >
              <Slider
                size="small"
                orientation="vertical"
                value={calculatedValue}
                disabled={disabled}
                onChange={(e, newValue) => {
                  saveValueFromChange(newValue as number);
                  setKeepOpen(true);
                }}
                onMouseUp={() => setKeepOpen(false)}
                valueLabelDisplay="auto"
                {...(sliderProps || {})}
              />
            </Box>
          )
        }
      >
        <span>
          <Box display="flex" flexDirection="column" alignItems="center" sx={{ width: 40 }}>
            {!disabled && (
              <ArrowDropUp
                color={isVisible ? 'primary' : 'disabled'}
                sx={{ fontSize: 14, position: 'absolute', top: -1 }}
              />
            )}
            <Typography color={isVisible ? 'primary' : 'disabled'} variant="caption">
              {numeral(calculatedValue / 100).format('0%')}
            </Typography>
            {!disabled && (
              <ArrowDropDown
                color={isVisible ? 'primary' : 'disabled'}
                sx={{ fontSize: 14, position: 'absolute', bottom: -1 }}
              />
            )}
          </Box>
        </span>
      </Tooltip>
      <IconButton
        disabled={disabled}
        onClick={(event) => {
          onToggleChange();
          event.stopPropagation();
        }}
        size="small"
        color="primary"
        sx={{ '& .MuiButton-icon.MuiButton-startIcon': { mr: 0 } }}
      >
        {isVisible ? <EyeEnabled /> : <EyeDisabled />}
      </IconButton>
    </Grid>
  );
};

export default VerticalSliderPopup;
