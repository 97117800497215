import { useQuery } from '@tanstack/react-query';
import { addSlidesToAnnotationAssignment } from 'api/annotationAssignments';
import { getStainTypeFilteredIds } from 'api/stainTypes';
import { ActionDialogType, useActiveActionDialogType } from 'components/ActionDialog';
import CalculateFeaturesMenuItem from 'components/Pages/CalculateFeatures/CalculateFeaturesMenuItem';
import useMainFilters from 'components/SearchFilters/hooks/useMainFilters';
import { Permission } from 'interfaces/permissionOption';
import { MULTIPLEX_STAIN_ID } from 'interfaces/stainType';
import { includes, isEmpty, map } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { stringify } from 'querystring';
import React, { useMemo, useState } from 'react';
import { JsonParam, useQueryParam } from 'use-query-params';
import queryClient from 'utils/queryClient';
import { useAnnotationAssignments } from 'utils/useAnnotationAssignments';
import { useCasesParams } from 'utils/useCasesParams';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { usePermissions } from 'utils/usePermissions';
import { useStudyAvailableAlgorithms } from 'utils/useStudyAvailableAlgorithms';

export interface ActionMenuItemOption {
  type: ActionDialogType;
  header: string;
  hide?: boolean; // don't show option at all
  disabled?: boolean; // show option but disabled
  customMenuItem?: React.ReactNode;
  subMenuItems?: ActionMenuItemOption[];
  onClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}

const assignmentsLoadingSubItems: ActionMenuItemOption[] = [
  {
    type: ActionDialogType.AddToAssignment,
    header: 'Loading annotation assignments...',
    disabled: true,
  },
];

const assignmentsErrorSubItems: ActionMenuItemOption[] = [
  {
    type: ActionDialogType.AddToAssignment,
    header: 'Error loading annotation assignments',
    disabled: true,
  },
];

export const useActionMenuItemOptions = ({ currentStains }: { currentStains?: string[] }) => {
  const { labId } = useCurrentLabId();
  const [filters] = useQueryParam('filters', JsonParam);
  const { isStudyIdSpecific } = useMainFilters();
  const studyId = filters?.studyId;
  const studyIdIsSpecific = isStudyIdSpecific(studyId);

  const { data: availableAlgorithms, isLoading: isLoadingStudyAlgorithms } = useStudyAvailableAlgorithms(studyId);
  const hasStudyAlgorithms = !isEmpty(availableAlgorithms);

  const { hasPermission } = usePermissions();
  const canApproveResults = hasPermission(Permission.PublishResults);
  const canAssignNeighborhoodNames = hasPermission(Permission.AssignGenericClusters) && studyIdIsSpecific;
  const canRunInference = hasPermission(Permission.RunInference);
  const canRunCalculateFeatures = hasPermission(Permission.RunCalculateFeatures);
  const canRunSlideRegistrations = hasPermission(Permission.ExecuteSlideRegistrations);
  const canRunMultiplexCellSegmentation = hasPermission(Permission.RunMultiplexCellSegmentation);
  const canRunMultiplexBinaryClassifier = hasPermission(Permission.ExecuteMultiplexBinaryClassifier);
  const canRunMultiplexNormalization = hasPermission(Permission.ExecuteMultiplexNormalization);
  const canRunMultiplexHistogram = hasPermission(Permission.ExecuteMultiplexHistogram);
  const canRunMultiplexThreshold = hasPermission(Permission.ExecuteMultiplexThreshold);
  const canRunMultiplexCellTyping = hasPermission(Permission.ExecuteMultiplexCellTyping);
  const canCreateAnnotationAssignment = hasPermission(Permission.CreateAnnotationAssignment);
  const canRunCropTma = hasPermission(Permission.RunCropTma);
  const canExecuteStudyAlgorithms = hasPermission(Permission.ExecuteStudyAlgorithms);

  const encodedParamsForStudy = stringify({ labId, filters: JSON.stringify({ studyId }) });
  const { data: stains, isLoading: isLoadingStains } = useQuery({
    queryKey: ['slidesStainsTypes', encodedParamsForStudy],
    queryFn: ({ signal }) => getStainTypeFilteredIds(encodedParamsForStudy, signal),
    placeholderData: currentStains,
  });

  const { casesParams } = useCasesParams();

  const isMultiplex = includes(stains, MULTIPLEX_STAIN_ID);

  const {
    data: annotationAssignments,
    isLoading: isLoadingAssignments,
    isError: isErrorAssignments,
    queryKey: annotationAssignmentsQueryKey,
  } = useAnnotationAssignments(studyId);

  const addSlidesToAssignmentMutation = useMutationWithErrorSnackbar({
    mutationFn: addSlidesToAnnotationAssignment,
    mutationDescription: 'add slides to annotation assignment',
    onSuccess: (data: any) => {
      enqueueSnackbar(data?.message || 'Slides added to assignment', { variant: 'success' });
      queryClient.invalidateQueries(annotationAssignmentsQueryKey);
      queryClient.invalidateQueries(['annotations']);
    },
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
      setSubmenuAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const [, setCurrentActionItemType] = useActiveActionDialogType();

  const handleSubmenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setSubmenuAnchorEl(event.currentTarget);
  };

  const handleSubmenuClose = () => {
    setSubmenuAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const addToAssignmentSubItems = useMemo(
    () =>
      isLoadingAssignments
        ? assignmentsLoadingSubItems
        : isErrorAssignments
          ? assignmentsErrorSubItems
          : map(annotationAssignments, (assignment) => ({
            type: ActionDialogType.AddToAssignment,
            header: `${assignment.name}-${assignment.createdAt}`,
            enabled: canCreateAnnotationAssignment,
            onClick: () => {
              setCurrentActionItemType(null);
              handleMenuClose();
              handleSubmenuClose();
              addSlidesToAssignmentMutation.mutate({
                annotationAssignmentId: assignment.annotationAssignmentId,
                casesParams,
              });
            },
          })),
    [isLoadingAssignments, isErrorAssignments, annotationAssignments, canCreateAnnotationAssignment, casesParams]
  );
  const actionItems: ActionMenuItemOption[] = useMemo(() => {
    return [
      {
        type: ActionDialogType.ApproveResults,
        header: 'Approve Results',
        hide: !canApproveResults,
      },
      {
        type: ActionDialogType.AssignNeighborhoodNames,
        header: 'Assign Neighborhood Names',
        hide: !canAssignNeighborhoodNames,
      },
      {
        type: ActionDialogType.CreateCohort,
        header: 'Create Cohort',
      },
      {
        type: ActionDialogType.CreateAssignment,
        header: 'Create Assignment',
        hide: !canCreateAnnotationAssignment,
      },
      {
        type: ActionDialogType.AddToAssignment,
        header: 'Add To Existing Assignment',
        hide: !canCreateAnnotationAssignment,
        subMenuItems: addToAssignmentSubItems,
      },
      {
        type: ActionDialogType.Inference,
        header: 'Run Inference',
        hide: !canRunInference,
      },
      {
        type: ActionDialogType.CalculateFeatures,
        header: 'Run Calculate Features',
        hide: !canRunCalculateFeatures,
        customMenuItem: <CalculateFeaturesMenuItem key="CalculateFeatures" disabled={!canRunCalculateFeatures} />,
      },
      {
        type: ActionDialogType.SlideRegistrations,
        header: 'Run Slide Registrations',
        hide: !canRunSlideRegistrations,
      },
      {
        type: ActionDialogType.MultiplexCellSegmentation,
        header: 'Run Multiplex Cell Segmentation',
        hide: !(canRunMultiplexCellSegmentation && isMultiplex),
      },
      {
        type: ActionDialogType.MultiplexBinaryClassifier,
        header: 'Run Multiplex Binary Classifier',
        hide: !(canRunMultiplexBinaryClassifier && isMultiplex),
      },
      {
        type: ActionDialogType.MultiplexHistogram,
        header: 'Run Multiplex Histogram',
        hide: !(canRunMultiplexHistogram && isMultiplex),
      },
      {
        type: ActionDialogType.MultiplexNormalization,
        header: 'Run Multiplex Normalization',
        hide: !(canRunMultiplexNormalization && isMultiplex),
      },
      {
        type: ActionDialogType.MultiplexThreshold,
        header: 'Run Multiplex Threshold',
        hide: !(canRunMultiplexThreshold && isMultiplex),
      },
      {
        type: ActionDialogType.MultiplexCellTyping,
        header: 'Run Multiplex Cell Typing',
        hide: !(canRunMultiplexCellTyping && isMultiplex),
      },
      {
        type: ActionDialogType.CropTma,
        header: 'Run Crop TMA',
        hide: !canRunCropTma,
      },
      {
        type: ActionDialogType.ExecuteStudyAlgorithm,
        header: 'Run Study Algorithm',
        hide: !canExecuteStudyAlgorithms || !hasStudyAlgorithms,
      },
    ];
  }, [
    canApproveResults,
    canCreateAnnotationAssignment,
    canRunCalculateFeatures,
    canRunInference,
    canRunMultiplexBinaryClassifier,
    canRunMultiplexCellSegmentation,
    canRunMultiplexCellTyping,
    canRunMultiplexHistogram,
    canRunMultiplexNormalization,
    canRunMultiplexThreshold,
    isMultiplex,
    addToAssignmentSubItems,
  ]);

  return {
    actionItems,
    isLoading: isLoadingStains || isLoadingStudyAlgorithms,
    anchorEl,
    setAnchorEl,
    submenuAnchorEl,
    setSubmenuAnchorEl,
    open,
    handleClick,
    handleSubmenuOpen,
    handleSubmenuClose,
    handleMenuClose,
  };
};
