import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { runCropTma } from "api/platform";
import { enqueueSnackbar } from "notistack";
import React, { FunctionComponent, useState } from 'react';
import { CasesParams } from 'utils/useCasesParams';
import { useMutationWithErrorSnackbar } from "utils/useMutationWithErrorSnackbar";

export interface RunCropTmaProps {
  onClose: () => void;
  casesParams: CasesParams;
}

const RunCropTma: FunctionComponent<RunCropTmaProps> = ({ casesParams, onClose }) => {
  const [open, setOpen] = useState(true);

  const cropTmaMutation = useMutationWithErrorSnackbar({
    mutationFn: runCropTma,
    mutationDescription: 'initiate crop TMA task',
    onSuccess: (data: any) => {
      enqueueSnackbar(data?.message || 'Crop TMA task started successfully', { variant: 'success' });
    },
  });

  const handleClose = () => {
    setOpen(false)
    onClose();
  };

  const handleExecute = () => {
    cropTmaMutation.mutate(casesParams);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Confirm Action</DialogTitle>
      <DialogContent>
        Are you sure you want to execute the Crop TMA task on selected cases/slides?
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleExecute} color="primary" variant="contained">
          Execute
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RunCropTma;