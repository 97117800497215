import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider, Grid, IconButton, ListItem, Paper, Popper } from '@mui/material';
import {
  editTypeToShapeSubTypeMap,
  ShapeSubTypes,
  shapeSubTypeToEditTypeMap,
  SlideAnnotationEditType,
} from 'components/Procedure/Header/SlideInteractionMenu/SlideAnnotationTools/options';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { compact, difference, filter, get, includes, map, some } from 'lodash';
import React, { useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { AnnotationOption, AnnotationOptionsMenu } from './AnnotationsContextMenu/AnnotationOptionsMenu';
import useAnnotationsForViewer, { AnnotationItem, getShapeSubTypeFromTodoOption } from './useAnnotations';

interface StatusBarProps {
  slide: SlideWithChannelAndResults;
}

const AnnotatingQuickMenu: React.FC<StatusBarProps> = ({ slide }) => {
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleExpandClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    setExpanded((prev) => !prev);
  };

  const [editAnnotationsMode, setEditAnnotationMode] = useQueryParam('editAnnotationsMode', StringParam);
  const shapeSubTypeOfCurrentAnnotationMode: ShapeSubTypes | null = get(
    editTypeToShapeSubTypeMap,
    editAnnotationsMode,
    null
  );

  const {
    annotationItems,
    updateAnnotationSettings,
    activeAnnotationClass,
    activeAnnotationData,
    currentViewerAnnotationSettings,
  } = useAnnotationsForViewer({
    slideId: slide?.id,
    viewerIndex: slide?.viewerIndex,
  });

  const onItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: AnnotationItem) => {
    const selectedItemShapeSubType = getShapeSubTypeFromTodoOption(item);
    if (
      shapeSubTypeOfCurrentAnnotationMode === null ||
      selectedItemShapeSubType !== shapeSubTypeOfCurrentAnnotationMode
    ) {
      // change the annotation mode
      setEditAnnotationMode(shapeSubTypeToEditTypeMap[selectedItemShapeSubType]);
    }

    updateAnnotationSettings({ todoOption: item });
  };

  const onToggleClassSelection = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: AnnotationItem) => {
    const featureIndexesOfSelectedClass = compact(
      map(activeAnnotationData?.features, (feature, index) => {
        return feature.properties?.diagnosis === item.name ? index : null;
      })
    );

    const featureIndexesOfClassInSelectedArea = filter(
      currentViewerAnnotationSettings?.featureIndexesOfSelectedArea,
      (featureIndex) => {
        return includes(featureIndexesOfSelectedClass, featureIndex);
      }
    );

    if (
      some(
        featureIndexesOfClassInSelectedArea,
        (featureIndex) => !includes(currentViewerAnnotationSettings?.selectedIndexes, featureIndex)
      )
    ) {
      updateAnnotationSettings({
        selectedIndexes: [
          ...(currentViewerAnnotationSettings?.selectedIndexes ?? []),
          ...featureIndexesOfClassInSelectedArea,
        ],
      });
    } else {
      updateAnnotationSettings({
        selectedIndexes: difference(
          currentViewerAnnotationSettings?.selectedIndexes,
          featureIndexesOfClassInSelectedArea
        ),
      });
    }
  };

  const doesClassHasSelectedFeatures = (className: string) => {
    return some(
      currentViewerAnnotationSettings?.selectedIndexes,
      (featureIndex) => activeAnnotationData?.features?.[featureIndex]?.properties?.diagnosis === className
    );
  };

  const doesClassHasFeaturesInSelectedArea = (className: string) => {
    return some(
      currentViewerAnnotationSettings?.featureIndexesOfSelectedArea,
      (featureIndex) => activeAnnotationData?.features?.[featureIndex]?.properties?.diagnosis === className
    );
  };

  const annotationItemsForSelectionQuickMenu: AnnotationItem[] = map(
    filter(annotationItems, (item) => doesClassHasFeaturesInSelectedArea(item.name)),
    (item) => ({
      ...item,
      selected: doesClassHasSelectedFeatures(item.name),
    })
  );

  if (editAnnotationsMode != SlideAnnotationEditType.Select && !activeAnnotationClass?.name) {
    return null;
  }

  return (
    <>
      <div style={{ position: 'absolute', bottom: 50, left: 15 }} ref={(el) => setAnchorEl(el)} />
      <Popper id={`quickMenu-${slide?.viewerIndex}`} open={Boolean(anchorEl)} anchorEl={anchorEl} placement="top-start">
        <Paper
          sx={(theme) => ({
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '4px',
            width: 250,
          })}
        >
          {editAnnotationsMode === SlideAnnotationEditType.Select ? (
            <AnnotationOptionsMenu
              annotationItems={annotationItemsForSelectionQuickMenu}
              onClick={onToggleClassSelection}
              useSelectedStyle
            />
          ) : (
            <>
              {expanded && (
                <>
                  <AnnotationOptionsMenu annotationItems={annotationItems} onClick={onItemClick} />
                  <Divider variant="middle" />
                </>
              )}
              <Grid container direction="row" spacing={1} alignItems="center" width="100%">
                <Grid item xs={10}>
                  <ListItem>
                    <AnnotationOption item={activeAnnotationClass} />
                  </ListItem>
                </Grid>
                <Grid item xs={2}>
                  <IconButton size="small" onClick={handleExpandClick}>
                    <ExpandMoreIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
      </Popper>
    </>
  );
};

export default AnnotatingQuickMenu;
