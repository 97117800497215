import {
  Point,
  Position,
} from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/NebulaGLExtensions/geojson-types';
import { Tagger } from './tagger';

export interface TodoOption {
  name: string; // class name (e.g. necrosis)
  className?: string;
  color?: string; // hex color code (e.g. #e6194b)
  displayName?: string;
  positive?: boolean;
  markerPositivity?: { [key: string]: number }; // key: stain type id, value: positivity cell rule
  isMarkerAnnotation?: boolean; // for markers annotations (can apply on top of an existing annotation, or as a new annotation with unknown diagnosis)
}

export interface TodoDefinition {
  todo: string; // the name of the todo (e.g. registration)
  type: string; // type of the todo (e.g. annotation_marker)
  options: TodoOption[];
  todoTypesId?: number;
}

export type AnnotationPolygonCoordinates = Position[];

export type AnnotationFeaturePolygonGeometry = {
  type: 'Polygon';
  coordinates: AnnotationPolygonCoordinates;
};

export interface AnnotationFeature {
  type: string | number; // (e.g. Feature)
  geometry: AnnotationFeaturePolygonGeometry | Point;
  properties: AnnotationFeatureProperties;
  tags?: AnnotationFeatureProperties;
}

export interface AnnotationFeatureProperties {
  id: string;
  hidden: boolean;
  selected: boolean;
  diagnosis: string;
  markerType: string;
  shapeSubType: string;
  markerPositivity?: { [key: string]: number }; // key: stain type id, value: positivity cell rule
}

export interface AnnotationsData {
  todo: string; // the name of the todo (e.g. registration)
  markers: AnnotationFeature[];
}

export interface AnnotationAssignmentUpdate extends Pick<AnnotationAssignment, 'name' | 'todos'> {}

export interface AnnotationAssignment {
  annotationAssignmentId: number;
  studyId?: string;
  name: string;
  todos: TodoDefinition[];
  description: string;
  allowedTaggerIds?: number[];
  archived: boolean;
  assignmentPriority: number;
  createdAt: Date;
  createdBy: string;
  taggerId?: number;
  slideIds?: string[];
  annotations?: Annotation[];
}

export enum AnnotationWorkingStates {
  Annotating = 'annotating',
  QA = 'qa',
  Done = 'done',
}

export interface Annotation {
  annotationId: number;
  slideId: string;
  annotationAssignment: AnnotationAssignment;
  createdAt: string;
  annotationsData?: AnnotationsData[];
  // this is data taken from the annotation_extensions table, representing the unpublished annotations
  draftAnnotationsData?: AnnotationsData[];
  // this is calculation happening on the back end, to not fetch all annotationsData
  annotationSummary?: { [key: string]: number }; // key: class, value: count of annotations
  draftAnnotationSummary?: { [key: string]: number }; // key: class, value: count of annotations
  taggerId: number;
  tagger?: Tagger;
  validFrom: string;
  validTo: string;
  workingState: AnnotationWorkingStates;
  participantTaggerId: number;
}

export enum AnnotationExtensionStates {
  Annotating = 'annotating',
  QA = 'qa',
  Done = 'done',
  None = 'none',
  AllAssignment = 'all',
  StarredAssignment = 'starred',
}

export interface AnnotationExtension {
  annotationAssignmentId: number;
  annotationExtensionsId: number;
  slideId: string;
  extensionAnnotationsData?: AnnotationsData[];
  taggerId: number;
  workingState: AnnotationExtensionStates;
  extensionPriority: number;
  url: string;
}

export enum ClassRemovalAction {
  keep = 'keep',
  replace = 'replace',
  delete = 'delete',
}

export interface AnnotationClassRemovalOperation {
  classToRemove: TodoOption;
  action: ClassRemovalAction;
  classToReplaceWith?: TodoOption;
}

export function generateAnnotationExtensionSlideAssignmentId(assignmentId: number, slideId: string) {
  return `${slideId}||${assignmentId}`;
}
