import { DialogWithCloseButton } from 'components/atoms/Dialog/DialogWithCloseButton';
import JobModal from 'components/JobModal';
import SaveCohortModal from 'components/SearchFilters/SaveCohortModal';
import { CreateAnnotationAssignmentDialog } from 'components/StudyDashboard/AnnotationAssignment/CreateAnnotationAssignmentDialog';
import ApproveResultsForm from 'components/StudyDashboard/ProceduresPage/Actions/ApproveResults/ApproveResultsForm';
import AssignNeighborhoodNamesForm from 'components/StudyDashboard/ProceduresPage/Actions/AssignNeighborhoodNamesForm';
import { CohortQueryObject } from 'interfaces/cohort';
import { JobType } from 'interfaces/job';
import { Dictionary, isEmpty, omitBy } from 'lodash';
import React, { useMemo } from 'react';
import { StringParam, UrlUpdateType, useQueryParam } from 'use-query-params';
import { useCasesParams } from 'utils/useCasesParams';
import { useEncodedFilters } from 'utils/useEncodedFilters';

export enum ActionDialogType {
  ApproveResults = 'approve_results',
  AssignNeighborhoodNames = 'assign_generic_clusters',
  CreateCohort = 'create_cohort',
  CreateAssignment = 'create_assignment',
  AddToAssignment = 'add_to_assignment',
  CalculateFeatures = 'calculate_features',
  Inference = 'inference',
  SlideRegistrations = 'slide_registrations',
  MultiplexCellSegmentation = 'multiplex_cell_segmentation',
  MultiplexThreshold = 'multiplex_threshold_binary_classification',
  MultiplexHistogram = 'multiplex_histogram_creation',
  MultiplexNormalization = 'multiplex_normalization',
  MultiplexBinaryClassifier = 'multiplex_binary_classification',
  MultiplexCellTyping = 'multiplex_cell_typing',
  CropTma = 'crop_tma',
  ExecuteStudyAlgorithm = 'execute_study_algorithm',
}

export const useActiveActionDialogType = () =>
  useQueryParam('activeActionDialog', StringParam) as [
    ActionDialogType | undefined,
    (newValue: ActionDialogType | undefined, updateType?: UrlUpdateType) => void
  ];

export const actionModalToJobType: Dictionary<JobType> = {
  [ActionDialogType.CalculateFeatures]: JobType.CalculateFeatures,
  [ActionDialogType.SlideRegistrations]: JobType.SlideRegistrations,
  [ActionDialogType.MultiplexCellSegmentation]: JobType.MultiplexCellSegmentation,
  [ActionDialogType.MultiplexBinaryClassifier]: JobType.BinaryClassifier,
  [ActionDialogType.MultiplexHistogram]: JobType.MultiplexHistogram,
  [ActionDialogType.MultiplexNormalization]: JobType.MultiplexNormalization,
  [ActionDialogType.MultiplexThreshold]: JobType.MultiplexThreshold,
  [ActionDialogType.MultiplexCellTyping]: JobType.MultiplexCellTyping,
  [ActionDialogType.CropTma]: JobType.CropTma,
  [ActionDialogType.Inference]: JobType.Inference,
  [ActionDialogType.ExecuteStudyAlgorithm]: JobType.NucleaiExecutor,
};

export const ActionDialog: React.FC = () => {
  const { queryParams, convertToQueryObject } = useEncodedFilters();
  const [currentActionItemType, setCurrentActionItemType] = useActiveActionDialogType();

  const jobTypeForActionDialog = currentActionItemType && actionModalToJobType[currentActionItemType];

  const { casesParams } = useCasesParams();
  const cohortQueryObject = useMemo(() => convertToQueryObject(queryParams), [queryParams]);
  const cohortQueryObjectWithSelection: CohortQueryObject = {
    ...cohortQueryObject,
    fromSelection: true,
    slidesMode: casesParams.slidesMode,
    ...omitBy(
      {
        caseIdsToInclude: casesParams.caseIdsToInclude,
        caseIdsToExclude: casesParams.caseIdsToExclude,
        slideIdsToInclude: casesParams.slideIdsToInclude,
        slideIdsToExclude: casesParams.slideIdsToExclude,
      },
      isEmpty
    ),
  };

  const onCloseModal = () => setCurrentActionItemType(null);

  return (
    currentActionItemType &&
    (jobTypeForActionDialog ? (
      <JobModal casesParams={casesParams} jobType={jobTypeForActionDialog} onClose={onCloseModal} />
    ) : currentActionItemType === ActionDialogType.ApproveResults ? (
      <DialogWithCloseButton header="Approve Results" onClose={onCloseModal}>
        <ApproveResultsForm onClose={onCloseModal} />
      </DialogWithCloseButton>
    ) : currentActionItemType === ActionDialogType.AssignNeighborhoodNames ? (
      <DialogWithCloseButton header="Assign Generic Clusters" onClose={onCloseModal}>
        <AssignNeighborhoodNamesForm onClose={onCloseModal} />
      </DialogWithCloseButton>
    ) : currentActionItemType === ActionDialogType.CreateCohort ? (
      <SaveCohortModal isOpen={true} onClose={onCloseModal} queryObject={cohortQueryObjectWithSelection} onlyCohort />
    ) : currentActionItemType === ActionDialogType.CreateAssignment ? (
      <CreateAnnotationAssignmentDialog open onClose={onCloseModal} />
    ) : null)
  );
};
