// @ts-ignore
import { Geometry } from '@luma.gl/core';

// Additive blending so multiple points accumulate
export const additiveBlendingParametersFromGLContext = (gl: any) => ({
  clearColor: [0, 0, 0, 0],
  blend: true,
  depthTest: false,
  blendFunc: [gl.ONE, gl.ONE],
  blendEquation: gl.FUNC_ADD,
});

export const BLEND_WITHOUT_DEPTH_TEST = {
  blend: true,
  depthTest: false,
};

/**
 * Creates a square geometry (a.k.a quad) by combining two triangles.
 *
 * This function generates a Geometry object representing a square in the XY plane (z = 0)
 * by defining two triangles. These triangles are specified such that their combination forms a complete
 * square. Graphics hardware is typically optimized for triangles rather than quads. Triangles are
 * inherently planar, ensuring that each consists of three points that are always coplanar. In contrast,
 * a quadrilateral (square) can sometimes be non-planar when its vertices do not lie in the same plane,
 * which could cause rendering issues. By constructing a square from two triangles, we bypass these potential
 * issues and leverage the hardware's efficient triangle processing capabilities.
 *
 * This approach is particularly useful for rendering a simple, flat shape that can be further manipulated
 * for tasks like texture mapping, transformations, or serving as a basis for more complex visualizations in WebGL.
 *
 * @param gl - The WebGL context used to access rendering constants, particularly the TRIANGLES draw mode.
 * @returns A Geometry object composed of two triangles that together form a square.
 */
export const createSquareFromTwoTriangles = (gl: any): Geometry => {
  // Two triangles forming a square in XY plane, z=0
  const CORNERS = new Float32Array([
    // Triangle 1
    -1, -1, 0, 1, -1, 0, -1, 1, 0,

    // Triangle 2
    -1, 1, 0, 1, -1, 0, 1, 1, 0,
  ]);

  const geometry = new Geometry({
    drawMode: gl.TRIANGLES,
    attributes: {
      positions: { size: 3, value: CORNERS },
    },
  });

  return geometry;
};
