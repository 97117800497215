import { useSignals } from '@preact/signals-react/runtime';
import {
  compact,
  concat,
  filter,
  find,
  first,
  forEach,
  fromPairs,
  groupBy,
  includes,
  isEmpty,
  isNumber,
  join,
  keys,
  last,
  lowerCase,
  map,
  size,
  some,
  sortBy,
  split,
  startsWith,
  uniq,
  values,
} from 'lodash';
import React, { useMemo } from 'react';

import { GroupedLayersVisualControls } from 'components/Procedure/Infobar/GroupedLayersVisualControls';
import { isHeatmapActive } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import {
  getMarkerPositivityParentHeatmapId,
  groupParquetHeatmapsByType,
  useParquetHeatmapFiles,
} from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/parquetLayers/helpers';
import { viewerLastRenderTime } from 'components/Procedure/SlidesViewer/DeckGLViewer/viewerDataSignals';
import { FeatureMetadata, ParquetLayerType } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { markerPositivityColumnPrefix } from 'components/Procedure/useSlideChannelsAndResults/parseHeatmaps';
import { defaultLayerColors } from 'components/theme/theme';
import { CellColorMapping } from 'interfaces/cellColorMapping';
import { OptionsEntry } from 'interfaces/experimentResults';
import { Permission } from 'interfaces/permissionOption';
import { Taxonomy } from 'interfaces/taxonomy';
import { humanize, reverseLightness } from 'utils/helpers';
import useTaxonomy from 'utils/queryHooks/taxonomy/useTaxonomy';
import { useCellColorMappings } from 'utils/queryHooks/useCellColorMappings';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { usePermissions } from 'utils/usePermissions';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import { heatmapToSubtitle } from './helpers';
import { useUpdateCellTableHeatmapsSettingsOnChange } from './useUpdateCellTableHeatmapsSettingsOnChange';

export const getCellTableLayerId = (cellTableHeatmap: FeatureMetadata, layerName: string) =>
  `${cellTableHeatmap.id}-${layerName}`;

const doesCellColorMappingMatchClassName = (
  cellColorMapping: CellColorMapping,
  layerName: string,
  stainTypeId?: string
) => {
  const stainTypeMatch = !stainTypeId || cellColorMapping.stainTypeId === stainTypeId;
  const sortedTags = sortBy(cellColorMapping.tags);
  const tagsString = join(sortedTags, '-');
  const layerNameFromCell = `${cellColorMapping?.cellClassId}${tagsString ? `-${tagsString}` : ''}`;
  return stainTypeMatch && layerName === layerNameFromCell;
};

export const computeDefaultCellTableLayerSettings = ({
  cellTableHeatmap,
  layerIndex,
  optionFromHeatmap,
  taxonomies,
  cellColorMappings,
  stainTypeId,
}: {
  cellTableHeatmap: FeatureMetadata;
  layerIndex: number;
  optionFromHeatmap?: OptionsEntry;
  taxonomies?: Taxonomy[];
  cellColorMappings?: CellColorMapping[];
  stainTypeId?: string;
}) => {
  const layerName = optionFromHeatmap?.key || `layer-${layerIndex}`;
  const colorMatchingFromCellColorMappings = (
    find(
      cellColorMappings,
      (cellColorMapping) =>
        cellColorMapping?.color && doesCellColorMappingMatchClassName(cellColorMapping, layerName, stainTypeId)
    ) ||
    find(
      cellColorMappings,
      (cellColorMapping) => cellColorMapping?.color && doesCellColorMappingMatchClassName(cellColorMapping, layerName)
    )
  )?.color;
  const taxonomiesWithColors = filter(taxonomies, 'defaultColor');
  const colorMatchingFromTaxonomy = (
    find(taxonomiesWithColors, (taxonomy) => taxonomy.path === layerName) ||
    find(taxonomiesWithColors, (taxonomy) => startsWith(layerName, `${taxonomy.path}-`)) ||
    find(taxonomiesWithColors, (taxonomy) => last(split(taxonomy.path, '.')) === layerName) ||
    find(taxonomiesWithColors, (taxonomy) => startsWith(layerName, `${last(split(taxonomy.path, '.'))}-`))
  )?.defaultColor;

  const colorFromOption = typeof optionFromHeatmap?.color === 'string' ? optionFromHeatmap.color : undefined;

  const colorByIndex = defaultLayerColors[+layerIndex % size(defaultLayerColors)];
  const computedColor =
    colorMatchingFromCellColorMappings || colorMatchingFromTaxonomy || colorFromOption || colorByIndex;

  const isRegisteredHeatmap = isNumber(cellTableHeatmap?.registeredFromStainTypeIndex);

  const color = isRegisteredHeatmap
    ? // Reverse the brightness of the color for registered heatmaps
      reverseLightness(computedColor)
    : computedColor;

  return {
    id: getCellTableLayerId(cellTableHeatmap, layerName),
    color,
    colorByIndex,
    opacity: 100,
    show: false,
    select: false,
  };
};

export const CellTableControl: React.FC<{
  heatmaps: FeatureMetadata[];
  slideId: string;
  viewerIndex: number;
  stainTypeId: string;
  filterText: string;
  hideOrchestrationId?: boolean;
  onEmptyFilter?: () => void;
}> = ({
  heatmaps: parquetHeatmaps,
  slideId,
  viewerIndex,
  filterText,
  hideOrchestrationId,
  stainTypeId,
  onEmptyFilter,
}) => {
  useSignals();

  const { isLoading: isLoadingTaxonomies } = useTaxonomy();
  const { isLoading: isLoadingCellColorMappings } = useCellColorMappings();

  const currentViewerLastRenderTime = viewerLastRenderTime[viewerIndex].value;

  const { stainTypeIdToDisplayName } = useStainTypeIdToDisplayName();

  const { labId } = useCurrentLabId();
  const parquetHeatmapByType = useMemo(() => groupParquetHeatmapsByType(parquetHeatmaps), [parquetHeatmaps, labId]);
  const { parquetFiles } = useParquetHeatmapFiles({ parquetHeatmapByType });

  const layerIdsToDisplayNames = useMemo(() => {
    const result: { [key: string]: string } = {};

    forEach(
      compact(
        concat(
          parquetHeatmapByType[ParquetLayerType.CellClassification],
          parquetHeatmapByType[ParquetLayerType.CellClassificationDensities]
        )
      ),
      (cellTableHeatmap) => {
        const parquetClasses = cellTableHeatmap?.options || [];
        const cellTableHeatmapId = cellTableHeatmap.id;
        result[cellTableHeatmapId] = cellTableHeatmap?.displayName;
        forEach(parquetClasses, (layer) => {
          if (layer) {
            result[getCellTableLayerId(cellTableHeatmap, layer.key)] = humanize(layer.key);
          }
        });
      }
    );

    const groupMarkerPositivityHeatmapsById = groupBy(
      parquetHeatmapByType[ParquetLayerType.MarkerPositivity],
      getMarkerPositivityParentHeatmapId
    );
    const groupMarkerProbabilityHeatmapsById = groupBy(
      parquetHeatmapByType[ParquetLayerType.MarkerProbability],
      getMarkerPositivityParentHeatmapId
    );
    forEach(groupMarkerPositivityHeatmapsById, (heatmaps, parentId) => {
      result[parentId] = `Marker Positivity`;
      forEach(heatmaps, (heatmap) => {
        const stainType = heatmap.columnName.replace(markerPositivityColumnPrefix, '');
        result[heatmap.id] = stainTypeIdToDisplayName(stainType) || humanize(stainType);
      });
    });
    forEach(groupMarkerProbabilityHeatmapsById, (heatmaps, parentId) => {
      result[parentId] = `Marker Probability`;
      forEach(heatmaps, (heatmap) => {
        const stainType = heatmap.columnName.replace(markerPositivityColumnPrefix, '');
        result[heatmap.id] = stainTypeIdToDisplayName(stainType) || humanize(stainType);
      });
    });

    return result;
  }, [parquetHeatmapByType, stainTypeIdToDisplayName]);

  useUpdateCellTableHeatmapsSettingsOnChange({
    slideId,
    viewerIndex,
    stainTypeId,
    parquetHeatmapByType,
  });

  const groupedFilterLayers = useMemo(() => {
    const result: { [key: string]: string[] } = {};

    forEach(
      compact(
        concat(
          parquetHeatmapByType[ParquetLayerType.CellClassification],
          parquetHeatmapByType[ParquetLayerType.CellClassificationDensities]
        )
      ),
      (cellTableHeatmap) => {
        const parquetClasses = cellTableHeatmap?.options || [];

        const filteredLayers =
          filterText !== ''
            ? filter(parquetClasses, (layer) => {
                if (filterText === '') return true;

                const lowerCaseFilter = lowerCase(filterText);
                return includes(lowerCase(layer.key), lowerCaseFilter);
              })
            : parquetClasses;
        const cellTableHeatmapId = cellTableHeatmap.id;
        result[cellTableHeatmapId] = map(filteredLayers, 'key');
      }
    );

    const groupMarkerPositivityHeatmapsById = groupBy(
      parquetHeatmapByType[ParquetLayerType.MarkerPositivity],
      getMarkerPositivityParentHeatmapId
    );

    const groupMarkerProbabilityHeatmapsById = groupBy(
      parquetHeatmapByType[ParquetLayerType.MarkerProbability],
      getMarkerPositivityParentHeatmapId
    );

    forEach(groupMarkerPositivityHeatmapsById, (heatmaps, key) => {
      const filteredLayers =
        filterText !== ''
          ? filter(heatmaps, (heatmap) => {
              if (filterText === '') return true;

              const lowerCaseFilter = lowerCase(filterText);
              return includes(lowerCase(heatmap.displayName), lowerCaseFilter);
            })
          : heatmaps;
      result[key] = map(filteredLayers, 'columnName');
    });

    forEach(groupMarkerProbabilityHeatmapsById, (heatmaps, key) => {
      const filteredLayers =
        filterText !== ''
          ? filter(heatmaps, (heatmap) => {
              if (filterText === '') return true;

              const lowerCaseFilter = lowerCase(filterText);
              return includes(lowerCase(heatmap.displayName), lowerCaseFilter);
            })
          : heatmaps;
      result[key] = map(filteredLayers, 'columnName');
    });
    if (isEmpty(result)) {
      onEmptyFilter?.();
    }
    return result;
  }, [parquetHeatmapByType, filterText]);

  const groupDisplayNames = useMemo(() => {
    const result: { [key: string]: string } = {};

    forEach(
      compact(
        concat(
          parquetHeatmapByType[ParquetLayerType.CellClassification],
          parquetHeatmapByType[ParquetLayerType.CellClassificationDensities]
        )
      ),
      (cellTableHeatmap) => {
        const cellTableHeatmapId = cellTableHeatmap.id;
        result[cellTableHeatmapId] = cellTableHeatmap?.displayName || humanize(cellTableHeatmap?.columnName);
      }
    );

    const groupMarkerPositivityHeatmapsById = groupBy(
      parquetHeatmapByType[ParquetLayerType.MarkerPositivity],
      getMarkerPositivityParentHeatmapId
    );
    const groupMarkerProbabilityHeatmapsById = groupBy(
      parquetHeatmapByType[ParquetLayerType.MarkerProbability],
      getMarkerPositivityParentHeatmapId
    );
    forEach(keys(groupMarkerPositivityHeatmapsById), (key) => {
      result[key] = `Marker Positivity`;
    });
    forEach(keys(groupMarkerProbabilityHeatmapsById), (key) => {
      result[key] = `Marker Probability`;
    });

    return result;
  }, [parquetHeatmapByType]);

  const { hasPermission } = usePermissions();
  const canSeeOrchestrationId = !hideOrchestrationId && hasPermission(Permission.SeeOrchestrationId);

  const groupSubtitles = useMemo(() => {
    const result: { [key: string]: React.ReactNode } = {};

    forEach(
      compact(
        concat(
          parquetHeatmapByType[ParquetLayerType.CellClassification],
          parquetHeatmapByType[ParquetLayerType.CellClassificationDensities]
        )
      ),
      (cellTableHeatmap) => {
        const cellTableHeatmapId = cellTableHeatmap.id;
        result[cellTableHeatmapId] = heatmapToSubtitle({
          heatmap: cellTableHeatmap,
          canSeeOrchestrationId,
          showFileTypes: canSeeOrchestrationId,
        });
      }
    );

    const groupMarkerPositivityHeatmapsById = groupBy(
      parquetHeatmapByType[ParquetLayerType.MarkerPositivity],
      getMarkerPositivityParentHeatmapId
    );

    const groupMarkerProbabilityHeatmapsById = groupBy(
      parquetHeatmapByType[ParquetLayerType.MarkerProbability],
      getMarkerPositivityParentHeatmapId
    );

    forEach(groupMarkerPositivityHeatmapsById, (heatmaps, key) => {
      const markerPositivityHeatmap = first(heatmaps);
      if (markerPositivityHeatmap) {
        result[key] = heatmapToSubtitle({
          heatmap: markerPositivityHeatmap,
          canSeeOrchestrationId,
          showFileTypes: canSeeOrchestrationId,
        });
      }
    });

    forEach(groupMarkerProbabilityHeatmapsById, (heatmaps, key) => {
      result[key] = first(heatmaps)?.orchestrationId;
    });
    return result;
  }, [parquetHeatmapByType, canSeeOrchestrationId]);

  const heatmapsActivityState = fromPairs(
    map(
      compact(
        concat(
          map(parquetHeatmapByType[ParquetLayerType.CellClassification], 'id'),
          map(parquetHeatmapByType[ParquetLayerType.CellClassificationDensities], 'id'),
          uniq(map(parquetHeatmapByType[ParquetLayerType.MarkerPositivity], getMarkerPositivityParentHeatmapId))
        )
      ),
      (heatmapId) => [heatmapId, isHeatmapActive({ heatmapId, slideId, viewerIndex })]
    )
  );
  const groupLoadingStates = useMemo(() => {
    const result: { [key: string]: boolean } = {};

    forEach(
      compact(
        concat(
          parquetHeatmapByType[ParquetLayerType.CellClassification],
          parquetHeatmapByType[ParquetLayerType.CellClassificationDensities]
        )
      ),
      (cellTableHeatmap) => {
        const cellTableHeatmapId = cellTableHeatmap.id;
        const heatmapUrl = cellTableHeatmap?.heatmapUrl;
        result[cellTableHeatmapId] = some(
          values(parquetFiles?.[heatmapUrl]),
          (file) =>
            // Cell color mappings / taxonomies are loading (used for default colors)
            isLoadingTaxonomies ||
            isLoadingCellColorMappings ||
            // Query is running
            file?.isLoading ||
            // There is no data but the heatmap is active
            (isEmpty(file?.rows) && heatmapsActivityState[cellTableHeatmap.id]) ||
            // Query is done but rendered data is outdated
            (!isEmpty(file?.rows) && file?.dataUpdatedAt > currentViewerLastRenderTime)
        );
      }
    );

    const groupMarkerPositivityHeatmapsById = groupBy(
      parquetHeatmapByType[ParquetLayerType.MarkerPositivity],
      getMarkerPositivityParentHeatmapId
    );

    const groupMarkerProbabilityHeatmapsById = groupBy(
      parquetHeatmapByType[ParquetLayerType.MarkerProbability],
      getMarkerPositivityParentHeatmapId
    );

    forEach(groupMarkerPositivityHeatmapsById, (heatmaps, parentHeatmapId) => {
      const heatmapUrl = first(heatmaps)?.heatmapUrl;
      result[parentHeatmapId] = some(
        values(parquetFiles?.[heatmapUrl]),
        (file) =>
          // Cell color mappings / taxonomies are loading (used for default colors)
          isLoadingTaxonomies ||
          isLoadingCellColorMappings ||
          // Query is running
          file?.isLoading ||
          // There is no data but the heatmap is active
          (isEmpty(file?.rows) && heatmapsActivityState[parentHeatmapId]) ||
          // Query is done but rendered data is outdated
          (!isEmpty(file?.rows) && file?.dataUpdatedAt > currentViewerLastRenderTime)
      );
    });

    forEach(groupMarkerProbabilityHeatmapsById, (heatmaps, parentHeatmapId) => {
      const heatmapUrl = first(heatmaps)?.heatmapUrl;
      result[parentHeatmapId] = some(
        values(parquetFiles?.[heatmapUrl]),
        (file) =>
          // Query is running
          file?.isLoading ||
          // There is no data but the heatmap is active
          (isEmpty(file?.rows) && heatmapsActivityState[parentHeatmapId]) ||
          // Query is done but rendered data is outdated
          (!isEmpty(file?.rows) && file?.dataUpdatedAt > currentViewerLastRenderTime)
      );
    });
    return result;
  }, [parquetHeatmapByType, parquetFiles, currentViewerLastRenderTime, JSON.stringify(heatmapsActivityState)]);

  return (
    !isEmpty(groupedFilterLayers) && (
      <GroupedLayersVisualControls
        viewerIndex={viewerIndex}
        slideId={slideId}
        groupedLayers={groupedFilterLayers}
        groupLoadingStates={groupLoadingStates}
        groupSubtitles={groupSubtitles}
        groupDisplayNames={groupDisplayNames}
        layerIdsToDisplayNames={layerIdsToDisplayNames}
        stainTypeId={stainTypeId}
        hideOrchestrationId={hideOrchestrationId}
        defaultLayerSettings={{ opacity: 100 }}
      />
    )
  );
};
