import { includes } from 'lodash';

import {
  secondaryAnalysisExclusionModes,
  secondaryAnalysisInclusionModes,
} from 'services/secondaryAnalysis/secondaryAnalysisSelectionModes';
import { GeoJsonFeature } from '../NebulaGLExtensions/geojson-types';
import { RGBAColor } from '../NebulaGLExtensions/helpers';

const SECONDARY_ANALYSIS_EXCLUSION_COLOR: [number, number, number] = [255, 0, 0];
const SECONDARY_ANALYSIS_INCLUSION_COLOR: [number, number, number] = [0, 255, 0];

export const getFillColor = (feature: GeoJsonFeature): RGBAColor =>
  includes(secondaryAnalysisInclusionModes, feature?.properties?.featureType)
    ? [...SECONDARY_ANALYSIS_INCLUSION_COLOR, 50]
    : includes(secondaryAnalysisExclusionModes, feature?.properties?.featureType)
    ? [...SECONDARY_ANALYSIS_EXCLUSION_COLOR, 50]
    : [0, 0, 0, 50];

export const getLineColor = (feature: GeoJsonFeature): RGBAColor =>
  includes(secondaryAnalysisInclusionModes, feature?.properties?.featureType)
    ? [...SECONDARY_ANALYSIS_INCLUSION_COLOR, 100]
    : includes(secondaryAnalysisExclusionModes, feature?.properties?.featureType)
    ? [...SECONDARY_ANALYSIS_EXCLUSION_COLOR, 100]
    : [0, 0, 0, 50];
