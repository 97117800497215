// startingHistValue: is the minimum of the minimum value.
// maxMinC: is the maximum of the minimum value - must be greater then startingHistValue.
// minMaxC: is the minimum of the maximum value - can be smaller then maxMinC but must be larger then startingHistValue.
// maxMaxC: is the maximum of the maximum value - must be greater then minMaxC.
export interface PrecisionSpecificNormalizationParams {
  startingHistValue?: number;
  maxMinC?: number;
  minMaxC?: number;
  maxMaxC?: number;
}

export interface NormalizationConfig {
  isLowDynamicRange?: boolean;
  isHighAbundance?: boolean;
  params8bit?: PrecisionSpecificNormalizationParams;
  params16bit?: PrecisionSpecificNormalizationParams;
  maxCPercentile?: number;
}

export interface NormalizationParamOverrideChannelGroup {
  name: string;
  channels: (number | string)[]; // channel names or indices
  overrides: NormalizationConfig;
}

export enum NormalizationMethod {
  Parametrized = 'parametrized',
  Auto = 'autonormalization',
}

export interface MultiplexNormalizationJobParams {
  method: NormalizationMethod;
  channels: (number | string)[];

  // provided only when method = auto
  modelUrl?: string;

  // provided only when method = semiAuto
  baseParams?: NormalizationConfig;
  paramOverrideChannelGroups?: NormalizationParamOverrideChannelGroup[];

  resultName?: string;
  resultDescription?: string;
  internallyApproved?: boolean;
}
