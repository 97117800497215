import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Button, Grid, List, ListItemButton, ListItemText, ListSubheader, Popover } from '@mui/material';
import { PostProcessingActionCreated } from 'interfaces/postProcessingAction';
import { Dictionary, filter, get, isArray, isEmpty, map, pullAt, set } from 'lodash';
import React, { useState } from 'react';
import { PostProcessingAction } from './PostProcessingAction';
import { useActionsQuery } from './usePostProcessingActions';
import { createNewAction } from './utils';

interface PostProcessingActionsProps {
  actions: PostProcessingActionCreated[];
  setActions: React.Dispatch<React.SetStateAction<PostProcessingActionCreated[]>>;
  updateClassConfigAccordingToActions: (actions: PostProcessingActionCreated[]) => void;
  getOptions: (
    optionSource: string | string[],
    selectedStains: string[],
    index: number,
    actionInput: string,
    inputSourceDependentOn?: string
  ) => any[];
  getOptionsByValue: (
    optionSource: string | string[],
    selectedStains: string[],
    index: number,
    actionInput: string,
    inputSourceDependentOn?: string
  ) => Dictionary<{ id: string | number; label: string }>;
  getMappingFiltersMetadataForLogicalQuery: (stain: string, actionInput: string, index: number) => any;
}

export const PostProcessingActions: React.FC<React.PropsWithChildren<PostProcessingActionsProps>> = ({
  actions,
  setActions,
  updateClassConfigAccordingToActions,
  getOptions,
  getOptionsByValue,
  getMappingFiltersMetadataForLogicalQuery,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const { postProcessingActionsById, postProcessingActionsAreas, postProcessingActionsCells } = useActionsQuery();

  const addNewAction = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, postProcessingActionId: string) => {
    setAnchorEl(null);
    const newAction = createNewAction(postProcessingActionId, postProcessingActionsById);

    setActions((prevActions) => {
      const newActions = [...prevActions, newAction];
      updateClassConfigAccordingToActions(newActions);
      return newActions;
    });
  };

  const removeAction = (index: number) => {
    setActions((prevActions) => {
      const correctNewActions = getActionsWithCorrectNewOptionValueAfterIndexChange(
        prevActions,
        index,
        prevActions.length - 1
      );
      pullAt(correctNewActions, index);
      updateClassConfigAccordingToActions(correctNewActions);
      return correctNewActions;
    });
  };

  const setAction = (action: any, index: number) => {
    setActions((prevActions) => {
      let newActions = [...prevActions];
      newActions[index] = action;

      // if a value of a newOption has changed, look for the oldValue in actions and change to the new value
      if (prevActions[index].newOptionValue && prevActions[index].newOptionValue !== action.newOptionValue) {
        const oldNewOption = prevActions[index].newOptionValue;
        newActions = getUpdateActions(
          newActions,
          action.actionInput,
          action.stain,
          oldNewOption,
          action.newOptionValue
        );
      }

      // if stain has changed and one of his values is from newOptions, delete the newOption
      if (prevActions[index].stain && prevActions[index].stain !== action.stain) {
        map(postProcessingActionsById[action.actionId].inputs, (input) => {
          if (input.freeSolo) {
            // if freesolo value- add to newOptionValue
            const options = getOptionsByValue(input.inputSource, action.stain, index, action.actionInput);
            if (isEmpty(options) || !options[get(action, input.inputKey)]) {
              newActions[index].newOptionValue = get(action, input.inputKey);
            }
          }
        });
      }

      return newActions;
    });
  };

  const handleActionRecursively = (
    action: any,
    actionInput: string,
    oldValue: string,
    newValue: string | null
  ): any => {
    if (isArray(action)) {
      const updatedAction = map(action, (actionItem) => {
        return handleActionRecursively(actionItem, actionInput, oldValue, newValue);
      });
      return updatedAction;
    } else if (typeof action === 'object' && action !== null) {
      const updatedAction: Record<string, any> = {};
      for (const key in action) {
        updatedAction[key] = handleActionRecursively(action[key], actionInput, oldValue, newValue);
      }
      return updatedAction;
    } else if (action === oldValue) {
      return newValue;
    } else {
      return action;
    }
  };

  const getUpdateActions = (
    newActions: any,
    actionInput: string,
    actionStain: string,
    oldValue: string,
    newValue: string
  ): any[] => {
    return map(newActions, (action) => {
      if (action.actionInput === actionInput && action.stain === actionStain)
        return handleActionRecursively(action, actionInput, oldValue, newValue);
      else return action;
    });
  };

  const getActionsWithCorrectNewOptionValueAfterIndexChange = (
    newActions: PostProcessingActionCreated[],
    oldIndex: number,
    newIndex: number
  ) => {
    const correctNewActions = [...newActions];
    let canUseNewOption = false;

    if (newIndex > oldIndex) {
      if (correctNewActions[oldIndex].newOptionValue) {
        // check if actions that now not suppose to know this newOptionValue used it
        for (let index = oldIndex + 1; index <= newIndex; index++) {
          const action = correctNewActions[index];
          const newOption = correctNewActions[oldIndex].newOptionValue;
          const postProcessingAction = postProcessingActionsById[action.actionId];
          if (
            action.stain == correctNewActions[oldIndex].stain &&
            action.actionInput == correctNewActions[oldIndex].actionInput
            // && checkIfNewOptionIsUsed(newOption, postProcessingAction, action
          ) {
            for (let input of postProcessingAction.inputs) {
              if (get(action, input.inputKey) === newOption) {
                if (input.freeSolo) {
                  // if freesolo - add to newOptionValue and delete from old action
                  correctNewActions[index].newOptionValue = newOption;
                  correctNewActions[oldIndex].newOptionValue = null;
                  canUseNewOption = true;
                } else {
                  // delete the value from action
                  set(correctNewActions[index], input.inputKey, null);
                }
              }
            }

            // all the other actions that used this newOptionValue can continue to use it
            if (canUseNewOption) {
              break;
            }
          }
        }
      }
    } else {
      const postProcessingAction = postProcessingActionsById[correctNewActions[oldIndex].actionId];

      for (let input of postProcessingAction.inputs) {
        const options = getOptionsByValue(
          input.inputSource,
          correctNewActions[oldIndex].stain,
          newIndex,
          correctNewActions[oldIndex].actionInput
        );
        const inputValue = get(correctNewActions[oldIndex], input.inputKey);
        // if the value is not exist anymore in the options
        if (!isEmpty(options) && !options[inputValue]) {
          if (input.freeSolo) {
            // if freesolo - add to newOptionValue and delete from old action
            correctNewActions[oldIndex].newOptionValue = inputValue;
            // search for the action that used this newOptionValue and delete it
            for (let index = newIndex; index < oldIndex; index++) {
              const action = correctNewActions[index];
              if (
                action.stain == correctNewActions[oldIndex].stain &&
                action.actionInput == correctNewActions[oldIndex].actionInput &&
                action.newOptionValue == inputValue
              ) {
                correctNewActions[index].newOptionValue = null;
                break;
              }
            }
          } else {
            // delete the value from action
            set(correctNewActions[oldIndex], input.inputKey, null);
          }
        }
      }
    }

    return correctNewActions;
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active?.id !== over?.id) {
      setActions((prev) => {
        const activeIndex = prev.findIndex((item) => item.id === active?.id);
        const overIndex = prev.findIndex((item) => item.id === over?.id);
        const correctnessActions = getActionsWithCorrectNewOptionValueAfterIndexChange(prev, activeIndex, overIndex);
        return arrayMove(correctnessActions, activeIndex, overIndex);
      });
    }
  };

  return (
    <Grid container>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={actions} strategy={verticalListSortingStrategy}>
          <Grid container direction="column">
            {map(actions, (action, index) => {
              return (
                <Grid item mb={1} key={action.id}>
                  <PostProcessingAction
                    key={action.id}
                    id={action.id}
                    index={index}
                    postProcessingActionsById={postProcessingActionsById}
                    action={action}
                    setAction={setAction}
                    removeAction={removeAction}
                    getOptions={getOptions}
                    getOptionsByValue={getOptionsByValue}
                    getMappingFiltersMetadataForLogicalQuery={getMappingFiltersMetadataForLogicalQuery}
                  />
                </Grid>
              );
            })}
          </Grid>
        </SortableContext>
      </DndContext>
      <Button
        aria-describedby="simple-popover"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
      >
        Add New Action
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List>
          <ListSubheader>{`Areas`}</ListSubheader>
          {map(
            filter(postProcessingActionsAreas, (postProcessingAction) => isEmpty(postProcessingAction.deletedAt)),
            (postProcessingAction) => (
              <ListItemButton
                key={postProcessingAction.id}
                onClick={(event) => addNewAction(event, postProcessingAction.id)}
              >
                <ListItemText primary={postProcessingAction.actionDisplayName ?? postProcessingAction.actionName} />
              </ListItemButton>
            )
          )}
          <ListSubheader>{`Cells`}</ListSubheader>
          {map(
            filter(postProcessingActionsCells, (postProcessingAction) => isEmpty(postProcessingAction.deletedAt)),
            (postProcessingAction) => (
              <ListItemButton
                key={postProcessingAction.id}
                onClick={(event) => addNewAction(event, postProcessingAction.id)}
              >
                <ListItemText primary={postProcessingAction.actionDisplayName ?? postProcessingAction.actionName} />
              </ListItemButton>
            )
          )}
        </List>
      </Popover>
    </Grid>
  );
};
