import { DataGridProps, GridRowModes, GridRowModesModel, useGridApiRef } from '@mui/x-data-grid';
import { isEmpty } from 'lodash';
import React, { useCallback } from 'react';

import { SettingsDataGrid } from 'components/atoms/BaseDataGrid/SettingsDataGrid';
import {
  generateGetCellClassNames,
  handleRowModesModelChangeWithoutDraftIds,
} from 'components/atoms/EditableDataGrid/helpers';
import Loader from 'components/Loader';
import { CellColorMapping } from 'interfaces/cellColorMapping';
import {
  cellColorMappingFields,
  useCellColorMappingFieldsContext,
} from 'interfaces/cellColorMapping/cellColorMappingFields';
import { useCellColorMappings } from 'utils/queryHooks/useCellColorMappings';
import { CellColorMappingDraft } from './types';
import { useCellColorMappingsColumns } from './useCellColorMappingsColumns';
import { generateDraftId, getCellColorMappingId } from './utils';

export const CellColorMappingsDataGrid: React.FC = () => {
  const { data: dbCellColorMappings, isLoading: isLoadingCellColorMappings } = useCellColorMappings();
  const [draftCellColorMappings, setDraftCellColorMappings] = React.useState<CellColorMappingDraft[]>([]);
  const cellColorMappings = React.useMemo(
    () => [...draftCellColorMappings, ...(dbCellColorMappings || [])],
    [dbCellColorMappings, draftCellColorMappings]
  );
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  const handleAddCellColorMapping = useCallback(() => {
    // We use the draftId field to distinguish between draft rows and db rows
    // This is necessary because we need to be able to edit ids in new rows but not in existing rows
    const draftId = generateDraftId();
    const newCellColorMapping: CellColorMappingDraft = {
      id: '',
      draftId,
    };
    setDraftCellColorMappings((oldDraftCellColorMappings) => [newCellColorMapping, ...oldDraftCellColorMappings]);
    setRowModesModel((oldRowModesModel) => ({
      ...oldRowModesModel,
      [newCellColorMapping.draftId]: { mode: GridRowModes.Edit },
    }));
  }, [setDraftCellColorMappings, setRowModesModel]);

  const apiRef = useGridApiRef();

  const handleRowModesModelChange: DataGridProps<CellColorMapping>['onRowModesModelChange'] = React.useCallback(
    (newRowModesModel: GridRowModesModel) => {
      handleRowModesModelChangeWithoutDraftIds(
        newRowModesModel,
        setRowModesModel,
        draftCellColorMappings,
        (row: CellColorMappingDraft) => row.draftId
      );
    },
    [draftCellColorMappings]
  );

  const columns = useCellColorMappingsColumns({
    noRows: isEmpty(cellColorMappings),
    apiRef,
    cellColorMappings,
    draftCellColorMappings,
    rowModesModel,
    setDraftCellColorMappings,
    setRowModesModel,
  });

  const cellColorMappingFieldsContext = useCellColorMappingFieldsContext();

  const getCellColorMappingCellClassName: DataGridProps['getCellClassName'] = React.useMemo(
    () =>
      generateGetCellClassNames<CellColorMapping | CellColorMappingDraft>({
        apiRef,
        requiredFields: ['cellClassId', 'color'],
        uniqueFieldGroups: [['cellClassId', 'tags', 'stainTypeId', 'color']],
        draftRows: draftCellColorMappings,
        fieldsToCheckForErrors: cellColorMappingFields,
        idGetter: getCellColorMappingId,
        context: cellColorMappingFieldsContext,
      }),
    [apiRef, draftCellColorMappings]
  );

  return !isLoadingCellColorMappings ? (
    <SettingsDataGrid
      apiRef={apiRef}
      addText="Add Cell Color Mapping"
      handleAdd={handleAddCellColorMapping}
      getCellClassName={getCellColorMappingCellClassName}
      rows={cellColorMappings}
      columns={columns}
      rowModesModel={rowModesModel}
      onRowModesModelChange={handleRowModesModelChange}
      getRowId={getCellColorMappingId}
    />
  ) : (
    <Loader />
  );
};
