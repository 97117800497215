import { ScatterplotLayer } from '@deck.gl/layers/typed';

import circleGradientFrag from './circle-gradient-frag.glsl';

export interface GradientScatterplotLayerExtraProps {
  bezierPoints?: [number, number, number, number];
}

const defaultProps = {
  ...ScatterplotLayer.defaultProps,
  bezierPoints: [0.0, 0.0, 1.0, 1.0], // linear gradient
};

export class GradientScatterplotLayer<
  DataT = any,
  ExtraPropsT extends GradientScatterplotLayerExtraProps = GradientScatterplotLayerExtraProps
> extends ScatterplotLayer<DataT, ExtraPropsT> {
  static defaultProps = defaultProps;

  static layerName: string = 'GradientScatterplotLayer';

  getShaders() {
    return {
      ...super.getShaders(),
      fs: circleGradientFrag,
    };
  }

  draw(opts: any) {
    const { uniforms } = opts;
    if (this.props.bezierPoints) {
      uniforms.bezierPoints = this.props.bezierPoints;
    }
    super.draw(opts);
  }
}
