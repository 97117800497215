import { useSignals } from '@preact/signals-react/runtime';
import { find, forEach } from 'lodash';
import { useCallback } from 'react';

import { secondaryAnalysisPolygons } from 'services/secondaryAnalysis/secondaryAnalysisPolygons';
import { secondaryAnalysisOptionsWithoutPolygons } from './secondaryAnalysisSelectionModes';
import {
  useSecondaryAnalysisOrchestrationIdQueryParams,
  useSecondaryAnalysisSelectionModeQueryParam,
} from './useSecondaryAnalysisQueryParams';

export const useClearSecondaryAnalysisSelections = () => {
  useSignals();
  const { clearSecondaryAnalysisOrchestrationIdQueryParams } = useSecondaryAnalysisOrchestrationIdQueryParams();
  // Check if any of the viewers is currently performing a secondary analysis
  const [, setSecondaryAnalysisAreaSelectionMode] = useSecondaryAnalysisSelectionModeQueryParam();

  const clearSecondaryAnalysisSelections = useCallback(
    (stopAnnotating: boolean) => {
      forEach(secondaryAnalysisPolygons, (secondaryAnalysisSignal) => {
        const previousValue = secondaryAnalysisSignal.value;
        secondaryAnalysisSignal.value =
          stopAnnotating || !previousValue ? null : { type: 'FeatureCollection', features: [] };
      });
      if (stopAnnotating) {
        setSecondaryAnalysisAreaSelectionMode(null);
        clearSecondaryAnalysisOrchestrationIdQueryParams();
      } else {
        setSecondaryAnalysisAreaSelectionMode(
          find(secondaryAnalysisOptionsWithoutPolygons, { isDefault: true })?.editType ?? null
        );
      }
    },
    [clearSecondaryAnalysisOrchestrationIdQueryParams, setSecondaryAnalysisAreaSelectionMode]
  );

  return clearSecondaryAnalysisSelections;
};
