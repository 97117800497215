import { AreaType } from 'interfaces/areaType';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getAreaTypeOptions = () => {
  return apiRequestHandlerPromise<AreaType[]>({
    url: `area_types`,
    method: 'GET',
  });
};

export const createAreaTypeOption = ({
  id,
  index: _index,
  ...areaTypeFields
}: Omit<AreaType, 'index'> & { index?: number }) => {
  return apiRequestHandlerPromise<AreaType>({
    url: 'area_types',
    method: 'POST',
    data: JSON.stringify({ id, ...areaTypeFields }),
  });
};

export const updateAreaTypeOption = ({ id, index: _index, ...areaTypeFields }: Partial<AreaType>) => {
  return apiRequestHandlerPromise({
    url: `area_types/${id}`,
    method: 'POST',
    data: JSON.stringify(areaTypeFields),
  });
};

export const deleteAreaTypeOption = (id: string) => {
  return apiRequestHandlerPromise({
    url: `area_types/${id}`,
    method: 'DELETE',
  });
};
