import { find, join, map } from 'lodash';
import React, { useCallback } from 'react';

import { RowsChangesSummary } from 'components/atoms/EditableDataGrid/TableEditingContext/ChangesSummary';
import { CellColorMapping } from 'interfaces/cellColorMapping';
import {
  cellColorMappingFields,
  useCellColorMappingFieldsContext,
} from 'interfaces/cellColorMapping/cellColorMappingFields';
import { CellColorMappingDraft } from './types';

export const CellColorMappingRowChangesSummary: React.FC<
  React.PropsWithChildren<{
    changes: Partial<CellColorMapping>;
    fullData?: CellColorMapping | CellColorMappingDraft;
  }>
> = ({ changes, fullData }) => {
  const context = useCellColorMappingFieldsContext();
  const cellColorMappingToDisplayString = useCallback(
    (cellColorMapping: Partial<CellColorMapping>) => {
      const cellClassId = cellColorMapping?.cellClassId;
      const cellClassName =
        find(context.taxonomies, (taxonomy) => taxonomy.path === cellClassId)?.name || 'Unknown Cell Class';

      const tags = cellColorMapping?.tags;
      const tagsString = tags
        ? join(
            map(tags, (tagId) => find(context.taxonomyTags, (tag) => tag.id === tagId)?.displayName || 'Unknown Tag'),
            ', '
          )
        : '';
      const stainTypeId = cellColorMapping?.stainTypeId;
      const stainTypeName = stainTypeId
        ? find(context.stainTypes, (stainType) => stainType.id === stainTypeId)?.displayName || 'Unknown Stain Type'
        : '';
      return `Cell Color Mapping for ${cellClassName}${tagsString ? ` with tags ${tagsString}` : ''}${
        stainTypeName ? ` for stain type ${stainTypeName}` : ''
      }`;
    },
    [context]
  );

  return (
    <RowsChangesSummary
      rowsChanges={{ changes }}
      rowToDisplayString={() => cellColorMappingToDisplayString(fullData || changes)}
      fieldToDisplayString={(fieldId) => find(cellColorMappingFields, (f) => f.dataKey === fieldId)?.label || fieldId}
      valueToDisplayString={(value, fieldId) => {
        const field = find(cellColorMappingFields, (f) => f.dataKey === fieldId);
        return field?.valueFormatter?.({ value }, context) ?? `${value}`;
      }}
    />
  );
};
