import { Box, Divider, Grid, TextField, Typography, useTheme } from '@mui/material';
import { AnnotationClassRemovalOperation, TodoOption } from 'interfaces/annotation';
import { find } from 'lodash';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { humanize } from 'utils/helpers';
import { useCellTypeOptions } from 'utils/queryHooks/useCellTypeOptions';
import { AssignmentFormValues } from './AnnotationAssignmentDialog';
import ClassesToAnnotateList from './ClassesToAnnotateList';
import ClassesToAnnotateSelection from './ClassesToAnnotateSelection';

interface ClassesToAnnotateListProps {
  assignmentCreationForm: UseFormReturn<AssignmentFormValues, any>;
  onDeleteClassToAnnotate?: (id: string) => void;
  onColorChange?: (id: string, color: string) => void;
  onClassesToAnnotateChange?: (newClassesToAnnotate: TodoOption[]) => void;
  classesToRemoveWithActions?: AnnotationClassRemovalOperation[];
  onEditDeleted?: (id: string) => void;
  onUndoDelete?: (id: string) => void;
}

const CreateAnnotationAssignmentSection: React.FC<ClassesToAnnotateListProps> = ({
  assignmentCreationForm,
  onDeleteClassToAnnotate,
  onColorChange,
  onClassesToAnnotateChange,
  classesToRemoveWithActions,
  onEditDeleted,
  onUndoDelete,
}) => {
  const {
    register,
    watch,
    control,
    formState: { errors },
  } = assignmentCreationForm;

  const { data: cellTypes } = useCellTypeOptions();
  const showWarningOnItem = (todo: TodoOption) => find(cellTypes, { id: todo.name })?.isAbstract;

  return (
    <Grid container height={800}>
      <Grid item xs={6} container direction="column" spacing={1} height="100%">
        <Grid item mx={2}>
          <Controller
            control={control}
            name="assignmentName"
            render={({ field: { onChange } }) => (
              <TextField
                required
                label="Assignment Name"
                {...register('assignmentName')}
                onChange={onChange}
                variant="outlined"
                margin="normal"
                error={Boolean(errors['assignmentName'])}
                helperText={humanize(errors['assignmentName']?.message)}
              />
            )}
          />
        </Grid>
        <Grid item mx={2}>
          <Typography variant="body1">{`Classes (${(watch('classesToAnnotate') ?? []).length})`}</Typography>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item xs={true} sx={{ overflow: 'auto' }} ml={2} pr={2}>
          {Boolean(errors['classesToAnnotate']) && (
            <Typography variant="body2" color="error">
              {humanize(errors['classesToAnnotate']?.message)}
            </Typography>
          )}
          {(watch('classesToAnnotate') ?? []).length === 0 ? (
            <ClassesToAnnotatePlaceHolder />
          ) : (
            <ClassesToAnnotateList
              classesToAnnotate={watch('classesToAnnotate')}
              onDeleteTodo={onDeleteClassToAnnotate}
              onColorChange={onColorChange}
              isEditable
              setClassesToAnnotate={onClassesToAnnotateChange}
              showWarningOnItem={showWarningOnItem}
              warningMessage="Not recommended, not a specific cell type"
              classesToRemoveWithActions={classesToRemoveWithActions}
              onEditDeleted={onEditDeleted}
              onUndoDelete={onUndoDelete}
            />
          )}
        </Grid>
      </Grid>

      <ClassesToAnnotateSelection
        onClassesSelected={onClassesToAnnotateChange}
        selectedClassesToAnnotate={watch('classesToAnnotate')}
      />
    </Grid>
  );
};

const ClassesToAnnotatePlaceHolder: React.FC = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800],
        borderRadius: 2,
        padding: 4,
        marginY: 6,
        marginX: 10,
        textAlign: 'center',
        minHeight: 150,
      }}
    >
      <Typography variant="body1" fontWeight="bold" mb={1}>
        Please start adding classes to your assignment
      </Typography>
      <Typography variant="body2">you can add classes from a preset, taxonomy or copy assignments settings</Typography>
    </Box>
  );
};
export default CreateAnnotationAssignmentSection;
