import { Dictionary, flatMap, keys, map, size, uniq } from 'lodash';

import { RegistrationDetails } from 'components/Pages/CalculateFeatures';
import { CasesParams } from 'utils/useCasesParams';
import { Inputs } from './calculateFeatures';
import { FlowClassName } from './experimentResults';
import GridBasedCalculationParams from './girdBasedCalculationParams';
import { CellRules, MultiplexCellTypingConfig, Thresholds } from './jobs/multiplex/cellTypingParams';
import { MultiplexHistogramConfig } from './jobs/multiplex/histogramParams';
import { MultiplexNormalizationJobParams, NormalizationConfig } from './jobs/multiplex/normalizationParams';
import { MultiplexThresholdConfig } from './jobs/multiplex/thresholdParams';
import { PostProcessingActionCreated } from './postProcessingAction';
import { VisualizationCreated } from './visualization';

export interface InferenceParamsNormalizationConfig {
  active: boolean;
  loadParamsFromDb: boolean;
  otfNormalizationConfig: {
    active: boolean;
    normParamsConfig: NormalizationConfig;
  };
}

export interface InferenceParams {
  stainType?: string;
  runType?: string;
  modelPaths?: string[];
  buildNumber?: string;
  heatmapNames?: string[];
  studyId?: string;
  tissueMaskFromAnnotations?: boolean | string;
  assignmentIds?: string[];
  classNames?: string[];
  dedupValue?: string;
  tissueSegmentationModelOverride?: string;
  manifest?: string[] | string;
  version?: string;
  branchName?: string;
  clearmlMachineType: string;
  inferenceVmsLimit?: number;
  skipRunExistingArtifacts?: boolean;
  useDynamicCellDetection?: boolean;
  dynamicCellDetectionConfig?: string;
  normalizationConfig?: InferenceParamsNormalizationConfig;
  channelsToExtract?: string[];
}

export type InferenceManifest = { slide_id: string }[];

export type SlideRegistrationsManifest = { slide_id1: string; slide_id2: string }[];

export interface SecondaryAnalysisInput {
  [caseId: string]: {
    [slideId: string]: {
      inclusionMultipolygon?: any;
      exclusionMultipolygon?: any;
    };
  };
}

export interface StudyMultiplexInputs {
  perMarkerThresholds: Thresholds;
  cellRules: CellRules;
  knnClassifier: {
    artifactUrl: string;
    fileExtension: string;
  };
  kMeansClusteringResults: ClusteringResult[];
}

export interface CalculateFeaturesParams {
  name?: string; // e.g. generic pips run, cell typing, neighborhood clusters
  manifest: Record<string, string[]>;
  studyId: string;
  postprocessing: PostProcessingActionCreated[];
  features: PostProcessingActionCreated[];
  gridBasedCalculationParams: { [key: string]: GridBasedCalculationParams };
  visualizations: VisualizationCreated[];
  inputs: Inputs;
  pipsStudyInputs?: StudyMultiplexInputs;
  registrations: RegistrationDetails[];
  secondaryAnalysisPolygons?: SecondaryAnalysisInput;
  primaryRunOrchestrationId?: string;
  autoInternallyApprove?: boolean;
}

export interface ResultForExport {
  experimentResultId: number;
  orchestrationId: string;
  slideId: string;
  originalFileName: string;
  caseId: number;
  caseName: string;
}

export interface QueryStatistics {
  procedures_count: number;
  slides_count: number;
  excluded_procedures_count: number;
  excluded_slides_count: number;
}

export interface PrepareCustomerResultsParams {
  numOfCasesInZip: number;
  resultTypesToExport: string[];
  canViewInternalLabels: boolean;
  queryObject?: CasesParams;
  snapshot?: Dictionary<{ slides: string[] }>;
  unpublishedExperimentResultIds?: number[];
  queryStatistics?: QueryStatistics;
}

export interface SlideRegistrationsParams {
  registratorType: string;
  registrationFeatures: {
    useHematoxylinOrNuclear: boolean;
    useAreaSegmentation: boolean;
    useCellDensities: boolean;
    useTissueMask: boolean;
  };
  cellDetectionArtifactSlide1?: string;
  cellDetectionArtifactSlide2?: string;
  areaSegmentationArtifactSlideId1?: string;
  areaSegmentationArtifactSlideId2?: string;
  machineType?: string;
  evaluationParams?: {
    autoApproveIfBestScore: boolean;
    autoApproveIfFirstScore: boolean;
    sparseRegistratorConfig: string;
  };
  multiplex: boolean;
  multiplexNuclearChannelName?: string;
  docker?: string;
  manifest: SlideRegistrationsManifest;
  studyId: string;
}

export type CalculateFeaturesManifest = Array<{
  slides: Dictionary<string>;
  case_id: string;
}>;

export enum JobStatus {
  Pending = 'pending',
  Running = 'running',
  FailedToStart = 'failed_to_start',
  Failed = 'failed',
  Completed = 'completed',
}

export enum JobType {
  CalculateFeatures = 'calculate_features',
  Inference = 'inference',
  MultiplexCellSegmentation = 'multiplex_cell_segmentation',
  PrepareCustomerResults = 'prepare_export_results',
  MultiplexThreshold = 'multiplex_calculate_binary_classifier_thresholds',
  MultiplexNormalization = 'multiplex_normalization',
  MultiplexHistogram = 'multiplex_histogram_creation',
  BinaryClassifier = 'multiplex_binary_classification',
  MultiplexCellTyping = 'multiplex_cell_typing',
  QaAnalysis = 'qa_analysis',
  SlideRegistrations = 'registration',
  MultiplexNeighborhoodClusters = 'multiplex_get_neighborhood_clusters',
  CropTma = 'crop_tma',
  NucleaiExecutor = 'nucleai_executor',
}

type CommonJobFields = {
  updatedAt?: string;
  status?: string;
  userId?: string;
  type?: JobType;
  name?: string;
  externalTaskId?: string;
  description?: string;
  externalTaskLink?: string;
  requestSender?: string;
  statusMessage?: string;
  studyId?: string;
  orchestrationId?: string;
  startedAt?: string;
  id?: string;
  children?: Job[];
  parentId?: string;
};

export type CalculateFeaturesJob = CommonJobFields & {
  flowClassName: FlowClassName.CalculateFeatures;
  type: JobType.CalculateFeatures;
  params: CalculateFeaturesParams;
  manifest: CalculateFeaturesManifest;
  primaryRunOrchestrationId?: string; // taken from the params object to disable the rebuild for secondary analysis jobs
};

export type InferenceJob = CommonJobFields & {
  flowClassName: FlowClassName.ConcurrentInferenceFlow;
  type: JobType.Inference;
  params: InferenceParams;
  manifest: InferenceManifest;
};

export type QaAnalysisJob = CommonJobFields & {
  type: JobType.QaAnalysis;
  manifest: string[];
  params: any;
  flowClassName?: undefined | null;
  results: Dictionary<
    Dictionary<{
      data: any;
      layout: any;
      file_type: 'json';
      title?: string;
      index?: number;
      storage_url?: string;
      url?: string;
    }>
  >;
};

export const isLegacyPrepareCustomerResultsManifest = (manifest: ResultForExport[] | string[]): manifest is string[] =>
  size(manifest) > 0 && typeof manifest[0] === 'string';

export type PrepareCustomerResultsJob = CommonJobFields & {
  flowClassName?: undefined | null;
  type: JobType.PrepareCustomerResults;
  params: PrepareCustomerResultsParams;
  manifest: ResultForExport[] | string[]; // string[] is legacy, a list of slide ids
};

export type NormalizationJob = CommonJobFields & {
  flowClassName?: undefined | null;
  type: JobType.MultiplexNormalization;
  params: { histogramNormalization: MultiplexNormalizationJobParams; histogramCreation: MultiplexHistogramConfig };
  manifest: string[];
};

export type HistogramJob = CommonJobFields & {
  flowClassName?: undefined | null;
  type: JobType.MultiplexHistogram;
  params: MultiplexHistogramConfig;
  manifest: string[];
};

export type ThresholdJob = CommonJobFields & {
  flowClassName?: undefined | null;
  type: JobType.MultiplexThreshold;
  params: MultiplexThresholdConfig;
  manifest: string[];
  results: {
    perMarkerThresholds: { [key: string]: number };
  };
};

export type CellTypingJob = CommonJobFields & {
  flowClassName?: undefined | null;
  type: JobType.MultiplexCellTyping;
  params: MultiplexCellTypingConfig;
  manifest: string[];
  results: {
    slideInferenceMultiplexMarkersProbabilities: {
      artifactUrl: string;
      fileType: string;
    };
  };
};

export type NucleaiExecutorJob = CommonJobFields & {
  flowClassName?: undefined | null;
  type: JobType.NucleaiExecutor;
  params: any;
  manifest: Array<{
    case_id: string;
    slides: Dictionary<string>; // stain type to slide id
  }>;
  results: any;
};

export interface KMeansParams {
  k?: number;
  minK?: number;
  maxK?: number;
  numIterations: number;
  numRedo: number;
}

export interface NeighborCountParams {
  nNeighbors: number;
  normalizeCounts?: boolean;
  arcsinhNormalizationCoFactor?: number;
  countColumnPrefix?: string;
}

export interface ClusteringResult {
  kMeansParams: KMeansParams;
  neighborCountParams: NeighborCountParams;
  clusteringCellLabelsOrder: string[];
  clusteringCentroids: number[][];
  davidBouldinScore: number;
  wssScore: number;
  cellFractionsPerCluster: number[][];
  // Default (to be set in implementation): internallyApproved = false
  internallyApproved?: boolean;
}

export interface NeighborClustersJobResults {
  kmeansClusteringResults: ClusteringResult[];
}

export type NeighborhoodClustersJob = CommonJobFields & {
  flowClassName?: undefined | null;
  type: JobType.MultiplexNeighborhoodClusters;
  manifest: string[];
  results: NeighborClustersJobResults;
  params: { [key: string]: any }; // this should be more specific when adding the execution of the job to the app
};

export type RegistrationJob = CommonJobFields & {
  flowClassName: FlowClassName.PerformRegistrationFlow;
  type: JobType.SlideRegistrations;
  params: SlideRegistrationsParams;
  manifest: SlideRegistrationsManifest;
};

export type Job =
  | CalculateFeaturesJob
  | InferenceJob
  | PrepareCustomerResultsJob
  | NormalizationJob
  | HistogramJob
  | QaAnalysisJob
  | ThresholdJob
  | RegistrationJob
  | CellTypingJob
  | NeighborhoodClustersJob
  | NucleaiExecutorJob;

export interface JobResponse<JobType extends Job = Job> {
  jobs: JobType[];
  totalJobs: number;
}

export const getJobManifestSlideIds = (job: Job): string[] => {
  if (job.type === JobType.CalculateFeatures) {
    return flatMap(job.manifest, ({ slides }) => keys(slides));
  }

  if (job.type === JobType.PrepareCustomerResults && !isLegacyPrepareCustomerResultsManifest(job.manifest)) {
    return map(job.manifest, 'slideId');
  }

  if (job.type === JobType.SlideRegistrations) {
    return uniq(flatMap(job.manifest, ({ slide_id1, slide_id2 }) => [slide_id1, slide_id2]));
  }

  return job.manifest as string[];
};
