import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import useMainFilters from 'components/SearchFilters/hooks/useMainFilters';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { CasePageUrlProps, useNavigationToViewerPage } from 'utils/useNavigationToViewerPage';

interface LinkToCaseViewerComponentProps extends Partial<CasePageUrlProps> {
  caseId: number;
  formattedValue?: string;
  target?: string;
  rel?: string;
}

const LinkToCaseViewerComponent: React.FC<LinkToCaseViewerComponentProps> = ({
  formattedValue,
  target,
  rel,
  ...casePageUrlProps
}) => {
  const { labId } = useCurrentLabId();
  const { queryParams } = useEncodedFilters();
  const { isStudyIdSpecific } = useMainFilters();
  const studyIdIsSpecific = isStudyIdSpecific(queryParams?.filters?.studyId);

  const { getLinkToCasePage } = useNavigationToViewerPage();
  return (
    <Link
      to={getLinkToCasePage({
        labId,
        caseStudyId: studyIdIsSpecific ? queryParams?.filters?.studyId : undefined,
        ...casePageUrlProps,
      })}
      target={target}
      rel={rel}
      component={RouterLink}
      color="secondary"
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      {target == '_blank' && <OpenInNewIcon fontSize="small" sx={{ mr: 0.5 }} />}
      <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{formattedValue || casePageUrlProps.caseId}</span>
    </Link>
  );
};

export const renderCaseIdCell: GridColDef<any, number, string>['renderCell'] = ({
  value,
  formattedValue,
  row,
}): ReturnType<React.FC> => {
  const caseId = value;
  return (
    <LinkToCaseViewerComponent
      caseId={caseId}
      formattedValue={formattedValue}
      labId={row.labId}
      caseStudyId={row.studyId}
    />
  );
};

export const getRenderCaseIdCell = (
  overrides: Partial<LinkToCaseViewerComponentProps> & {
    formattedValue?: string;
    caseIdColumn?: string;
    labIdColumn?: string;
    caseStudyIdColumn?: string;
  }
): GridColDef<any, string>['renderCell'] => {
  return (renderCellParams): ReturnType<React.FC> => {
    const caseId = overrides.caseId
      ? overrides.caseId
      : overrides.caseIdColumn
      ? renderCellParams.row[overrides.caseIdColumn]
      : renderCellParams.value;
    const formattedValue = overrides.formattedValue || renderCellParams.formattedValue;
    const labId = overrides.labId || renderCellParams.row[overrides.labIdColumn || 'labId'];
    const caseStudyId = overrides.caseStudyId || renderCellParams.row[overrides.caseStudyIdColumn || 'studyId'];
    return (
      <LinkToCaseViewerComponent
        {...overrides}
        caseId={caseId}
        formattedValue={formattedValue}
        labId={labId}
        caseStudyId={caseStudyId}
      />
    );
  };
};

export default renderCaseIdCell;
