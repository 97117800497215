import React from 'react';

import { Grid } from '@mui/material';
import { useTheme } from '@mui/system';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { Permission } from 'interfaces/permissionOption';
import { useAnnotationQueryParams } from 'services/annotations/useAnnotationQueryParams';
import { useSecondaryAnalysis } from 'services/secondaryAnalysis';
import { usePermissions } from 'utils/usePermissions';
import AnnotationsHints from './AnnotationsHints';
import CommentsHints from './CommentsHints';
import GeneralHints from './GeneralHints';

export interface ViewerHintsProps {
  selectedSlides: SlideWithChannelAndResults[];
  marginLeft?: number;
  marginRight?: number;
  showCommentsHint?: boolean;
}

const ViewerHints: React.FunctionComponent<React.PropsWithChildren<ViewerHintsProps>> = ({
  selectedSlides,
  marginLeft,
  marginRight,
  showCommentsHint,
}) => {
  const theme = useTheme();
  const { hasPermission } = usePermissions();
  const { annotationsActive } = useAnnotationQueryParams();
  const canAnnotateSlides = hasPermission(Permission.AnnotateSlides);

  const {
    context: { isSecondaryAnalysisActive },
  } = useSecondaryAnalysis({ selectedSlides });

  return (
    <Grid
      container
      spacing={1}
      sx={{
        backgroundColor: theme.palette.background.paper,
        width: `calc(100% - ${marginLeft}px - ${marginRight}px)`,
        marginLeft: `${marginLeft}px`,
        marginRight: `${marginRight}px`,
        height: 30,
        alignItems: 'center',
      }}
    >
      <GeneralHints selectedSlides={selectedSlides} />
      {showCommentsHint && <CommentsHints />}
      {((annotationsActive && canAnnotateSlides) || isSecondaryAnalysisActive) && <AnnotationsHints />}
    </Grid>
  );
};

export default ViewerHints;
