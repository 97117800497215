import { DialogWithCloseButton } from 'components/atoms/Dialog/DialogWithCloseButton';
import { ExecuteStudyAlgorithm } from 'components/StudyDashboard/ProceduresPage/Actions/ExecuteStudyAlgorithms';
import { RunMultiplexBinaryClassifier } from 'components/StudyDashboard/ProceduresPage/Actions/Multiplex/RunMultiplexBinaryClassifier';
import { RunMultiplexCellSegmentation } from 'components/StudyDashboard/ProceduresPage/Actions/Multiplex/RunMultiplexCellSegmentation';
import { RunMultiplexCellTyping } from 'components/StudyDashboard/ProceduresPage/Actions/Multiplex/RunMultiplexCellTyping';
import { RunMultiplexHistogram } from 'components/StudyDashboard/ProceduresPage/Actions/Multiplex/RunMultiplexHistogram';
import { RunMultiplexNormalization } from 'components/StudyDashboard/ProceduresPage/Actions/Multiplex/RunMultiplexNormalization';
import { RunMultiplexThreshold } from 'components/StudyDashboard/ProceduresPage/Actions/Multiplex/RunMultiplexThreshold';
import RunCropTma from 'components/StudyDashboard/ProceduresPage/Actions/RunCropTma';
import { RunInferenceSteps } from 'components/StudyDashboard/ProceduresPage/Actions/RunInference/RunInferenceSteps';
import { RunSlideRegistrations } from 'components/StudyDashboard/ProceduresPage/Actions/RunSlideRegistrations';
import { JobType } from 'interfaces/job';
import { Permission } from 'interfaces/permissionOption';
import React from 'react';
import { CasesParams } from 'utils/useCasesParams';
import { usePermissions } from 'utils/usePermissions';

interface Props {
  jobType: JobType;
  onClose: () => void;
  casesParams: CasesParams;
  jobId?: string;
}

const JobModal: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  jobType,
  onClose,
  casesParams,
  jobId,
}) => {
  const { hasPermission } = usePermissions();
  const canRunInference = hasPermission(Permission.RunInference);
  const canRunSlideRegistrations = hasPermission(Permission.ExecuteSlideRegistrations);
  const canRunMultiplexCellSegmentation = hasPermission(Permission.RunMultiplexCellSegmentation);
  const canRunMultiplexBinaryClassifier = hasPermission(Permission.ExecuteMultiplexBinaryClassifier);
  const canRunMultiplexNormalization = hasPermission(Permission.ExecuteMultiplexNormalization);
  const canRunMultiplexHistogram = hasPermission(Permission.ExecuteMultiplexHistogram);
  const canRunMultiplexThreshold = hasPermission(Permission.ExecuteMultiplexThreshold);
  const canRunMultiplexCellTyping = hasPermission(Permission.ExecuteMultiplexCellTyping);
  const canExecuteStudyAlgorithms = hasPermission(Permission.ExecuteStudyAlgorithms);
  const canRunCropTma = hasPermission(Permission.RunCropTma);

  switch (jobType) {
    case JobType.Inference:
      if (canRunInference) {
        return (
          <DialogWithCloseButton header="Run Inference" onClose={onClose}>
            <RunInferenceSteps jobId={jobId} casesParams={casesParams} onClose={onClose} />
          </DialogWithCloseButton>
        );
      }
      break;
    case JobType.MultiplexCellSegmentation:
      if (canRunMultiplexCellSegmentation) {
        return (
          <DialogWithCloseButton header="Run Multiplex Cell Segmentation" onClose={onClose}>
            <RunMultiplexCellSegmentation onClose={onClose} />
          </DialogWithCloseButton>
        );
      }
      break;
    case JobType.BinaryClassifier:
      if (canRunMultiplexBinaryClassifier) {
        return (
          <DialogWithCloseButton header="Run Multiplex Binary Classifier" onClose={onClose}>
            <RunMultiplexBinaryClassifier onClose={onClose} />
          </DialogWithCloseButton>
        );
      }
      break;
    case JobType.MultiplexHistogram:
      if (canRunMultiplexHistogram) {
        return (
          <DialogWithCloseButton header="Run Multiplex Histogram" onClose={onClose}>
            <RunMultiplexHistogram jobId={jobId} casesParams={casesParams} onClose={onClose} />
          </DialogWithCloseButton>
        );
      }
      break;
    case JobType.MultiplexNormalization:
      if (canRunMultiplexNormalization) {
        return (
          <DialogWithCloseButton header="Run Multiplex Normalization" onClose={onClose}>
            <RunMultiplexNormalization jobId={jobId} casesParams={casesParams} onClose={onClose} />
          </DialogWithCloseButton>
        );
      }
      break;
    case JobType.MultiplexThreshold:
      if (canRunMultiplexThreshold) {
        return (
          <DialogWithCloseButton header="Run Multiplex Threshold" onClose={onClose}>
            <RunMultiplexThreshold onClose={onClose} />
          </DialogWithCloseButton>
        );
      }
      break;
    case JobType.MultiplexCellTyping:
      if (canRunMultiplexCellTyping) {
        return (
          <DialogWithCloseButton header="Run Multiplex Cell Typing" onClose={onClose}>
            <RunMultiplexCellTyping onClose={onClose} />
          </DialogWithCloseButton>
        );
      }
      break;
    case JobType.SlideRegistrations:
      if (canRunSlideRegistrations) {
        return (
          <DialogWithCloseButton header="Run Slide Registrations" onClose={onClose}>
            <RunSlideRegistrations onClose={onClose} casesParams={casesParams} />
          </DialogWithCloseButton>
        );
      }
      break;
    case JobType.NucleaiExecutor:
      if (canExecuteStudyAlgorithms) {
        return (
          <DialogWithCloseButton header="Run Study Algorithm" onClose={onClose}>
            <ExecuteStudyAlgorithm onClose={onClose} casesParams={casesParams} />
          </DialogWithCloseButton>
        );
      }
      break;
    case JobType.CropTma:
      if (canRunCropTma) {
        return (
          <RunCropTma onClose={onClose} casesParams={casesParams} />
        );
      }
      break;
    default:
      console.error(`JobModal: jobType ${jobType} not found`);
      return null;
  }
  console.error(`User does not have permission to run job type ${jobType}`);
  return null;
};

export default JobModal;
