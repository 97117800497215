import { COORDINATE_SYSTEM, Layer, PickingInfo } from '@deck.gl/core/typed';
import { DeckGLProps } from '@deck.gl/react/typed';
import GL from '@luma.gl/constants';
import { useSignals } from '@preact/signals-react/runtime';
import { Feature } from '@turf/helpers';
import {
  cloneDeep,
  filter,
  find,
  first,
  forEach,
  get,
  includes,
  isArray,
  keys,
  last,
  map,
  size,
  times,
  uniq,
} from 'lodash';
import { EditableGeoJsonLayer, SELECTION_TYPE, ViewMode } from 'nebula.gl';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';

import {
  editTypeToShapeSubTypeMap,
  RoiFixedSizes,
  ShapeSubTypes,
  SlideAnnotationEditType,
} from 'components/Procedure/Header/SlideInteractionMenu/SlideAnnotationTools/options';
import {
  useActiveAnnotationDraft,
  viewerAnnotationData,
} from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/EditAnnotationLayers/useActiveAnnotationDraft';
import { Permission } from 'interfaces/permissionOption';
import { usePermissions } from 'utils/usePermissions';
import { OrthographicMapViewState } from '../../OrthographicMapview';
import { deckGLViewerStates } from '../../slidesViewerState';
import {
  DrawAnnotationPointMode,
  DrawAnnotationPolygonMode,
  DrawAnnotationRectangleMode,
} from '../NebulaGLExtensions/editModes';
import { FeatureCollection } from '../NebulaGLExtensions/geojson-types';
import { getEditHandleColor, getEditHandleTypeFromEitherLayer, TOOLTIP_COLOR } from '../NebulaGLExtensions/helpers';
import { ModifyOrthographicMode } from '../NebulaGLExtensions/ModifyOrthographicMode';
import { SelectionOrthographicLayer } from '../NebulaGLExtensions/SelectionOrthographicLayer';
import { TranslateOrthographicMode } from '../NebulaGLExtensions/TranslateOrthographicMode';
import useAnnotationsForViewer, { UNKNOWN_DIAGNOSIS } from './useAnnotations';
import { ActionTypes, useAnnotationsStoreWithUndo } from './useAnnotationsWithUndo';

export const useEditAnnotationLayers = ({
  slideId,
  viewerIndex,
  viewState,
  viewSize,
  viewOnly,
}: {
  slideId: string;
  viewerIndex: number;
  viewState: OrthographicMapViewState;
  viewSize: { width: number; height: number };
  viewOnly?: boolean;
}) => {
  useSignals();
  const { hasPermission } = usePermissions();
  const canAnnotateSlides = hasPermission(Permission.AnnotateSlides);

  const [middleButtonDown, setMiddleButtonDown] = useState(false);

  // This use effect is to fix performance issued caused when pickable is set to true while panning the map (causing redundant calculations)
  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      if (event.button === 1) {
        setMiddleButtonDown(true);
      }
    };

    const handleMouseUp = (event: MouseEvent) => {
      if (event.button === 1) {
        setMiddleButtonDown(false);
      }
    };

    window.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  const {
    getFillColorForFeature,
    getColorForFeature,
    activeAnnotationAssignmentId,
    slideAnnotations,
    isSlideAnnotationsLoading,
    activeAnnotationClass,
    addFixedRoiOnCoordinate,
    updateAnnotationSettings,
    currentViewerAnnotationSettings,
  } = useAnnotationsForViewer({
    slideId,
    viewerIndex,
  });

  const getFillColor = (feature: Feature, isSelected: boolean) => {
    // when fill color is hidden we put opacity 0.1 so it will look like its hidden, but still be clickable
    return getFillColorForFeature(feature, isSelected, hideFillColor ? 0.01 : null);
  };

  // This is needed to make the GeoJsonLayer to work as expected
  const getLineColor = (feature: Feature, isSelected: boolean) => {
    return getColorForFeature(feature, isSelected, null);
  };

  const savedAnnotationData = canAnnotateSlides
    ? find(slideAnnotations, { annotationAssignment: { annotationAssignmentId: activeAnnotationAssignmentId } })
    : undefined;

  const [hideFillColor] = useQueryParam('hideFillColor', BooleanParam);
  const [editAnnotationsModeFromUrl, setEditAnnotationMode] = useQueryParam('editAnnotationsMode', StringParam);
  const [roiSelectedSize, setRoiSelectedSize] = useQueryParam('roiSelectedSize', StringParam);
  const editAnnotationsMode = viewOnly ? undefined : editAnnotationsModeFromUrl;
  const [isHoveringOverFeature, setIsHoveringOverFeature] = useState(false);

  const { activeAnnotationData: editAnnotationFeatureCollection } = useActiveAnnotationDraft({
    slideId,
    viewerIndex,
  });

  const { updateFeatureCollection } = useAnnotationsStoreWithUndo();

  // for now we are using the selected feature indexes for move and selection modes only
  const selectedFeatureIndexes = currentViewerAnnotationSettings?.selectedIndexes;

  const deleteFeature: DeckGLProps['onClick'] = useCallback(
    (pickingInfo, event) => {
      const featureIndex = pickingInfo?.index;
      if (
        !editAnnotationFeatureCollection ||
        featureIndex === undefined ||
        !isArray(editAnnotationFeatureCollection.features) ||
        !editAnnotationFeatureCollection.features[featureIndex]
      ) {
        console.warn('No feature to delete', { editAnnotationFeatureCollection, featureIndex });
        return;
      }
      const updatedData = cloneDeep(editAnnotationFeatureCollection);
      updatedData.features.splice(featureIndex, 1);

      updateFeatureCollection(updatedData, {
        type: ActionTypes.DELETE_FEATURE,
        featureType: editAnnotationFeatureCollection.features[featureIndex].properties.shapeSubType,
      });
    },
    [viewerIndex, editAnnotationFeatureCollection]
  );

  const addFixedRoiOnMap = useCallback(
    (coordinate: [number, number]) => {
      if (includes(keys(RoiFixedSizes), roiSelectedSize)) {
        const roiSize: [number, number] = RoiFixedSizes[roiSelectedSize];
        addFixedRoiOnCoordinate(coordinate, roiSize);
        updateAnnotationSettings({ selectedIndexes: [size(editAnnotationFeatureCollection.features)] });
      } else {
        console.warn('Invalid roi size selected', { roiSelectedSize });
        setRoiSelectedSize(null);
      }
    },
    [editAnnotationsMode, editAnnotationFeatureCollection]
  );

  useEffect(() => {
    if (roiSelectedSize) {
      const currentViewState = deckGLViewerStates[viewerIndex]?.value?.[slideId];
      if (currentViewState) {
        const coordinate = (currentViewState.target ?? [0, 0]) as [number, number];
        addFixedRoiOnMap(coordinate);
      }

      setRoiSelectedSize(null);
      setEditAnnotationMode(SlideAnnotationEditType.Move);
    }
  }, [roiSelectedSize]);

  const onClick = useCallback(
    (info: PickingInfo, event: Parameters<DeckGLProps['onClick']>[1]) => {
      // if the user is in the middle of drawing an annotation, right click should not do anything
      if (event.rightButton && info?.object?.properties?.guideType === 'tentative') {
        event.preventDefault();
        event.stopPropagation();
        return true;
      }

      if (editAnnotationsMode === SlideAnnotationEditType.Delete || (event.leftButton && event.srcEvent.altKey)) {
        // @ts-ignore
        deleteFeature(info, event);
        event.srcEvent.preventDefault();
        event.srcEvent.stopPropagation();
      }

      if (editAnnotationsMode === SlideAnnotationEditType.Move) {
        const featureIndex = info?.index;
        if (event.srcEvent.ctrlKey) {
          const previousSelectedIndexes = currentViewerAnnotationSettings?.selectedIndexes ?? [];
          const newSelectedIndexes = includes(previousSelectedIndexes, featureIndex)
            ? filter(previousSelectedIndexes, (index) => index !== featureIndex)
            : [...previousSelectedIndexes, featureIndex];
          updateAnnotationSettings({ selectedIndexes: newSelectedIndexes });
        } else if (featureIndex !== undefined) {
          updateAnnotationSettings({ selectedIndexes: [featureIndex] });
        }
      }
    },
    [editAnnotationsMode, deleteFeature]
  );

  const editAnnotationFeaturesNumber = editAnnotationFeatureCollection?.features?.length ?? 0;

  const onEdit = ({
    updatedData,
    editType,
    editContext,
  }: {
    updatedData: FeatureCollection;
    editType: string;
    editContext: any;
  }) => {
    times(updatedData?.features?.length ?? 0, (featureIndex) => {
      const shapeSubType =
        updatedData.features[featureIndex].properties?.shapeSubType ||
        get(editTypeToShapeSubTypeMap, editAnnotationsMode, '');
      updatedData.features[featureIndex].properties.shapeSubType = shapeSubType;
      updatedData.features[featureIndex].properties.annotationId = savedAnnotationData?.annotationId;
      updatedData.features[featureIndex].properties.markerType = 'tagger_annotation';
    });

    if (editType === 'addFeature' && editContext?.featureIndexes) {
      if (activeAnnotationClass?.name) {
        forEach(editContext.featureIndexes, (featureIndex) => {
          if (activeAnnotationClass.isMarkerAnnotation) {
            updatedData.features[featureIndex].properties.diagnosis = UNKNOWN_DIAGNOSIS;
            updatedData.features[featureIndex].properties.markerPositivity = {
              [activeAnnotationClass.name]: activeAnnotationClass.positive,
            };
          } else {
            updatedData.features[featureIndex].properties.diagnosis = activeAnnotationClass.name;
            updatedData.features[featureIndex].properties.markerPositivity = activeAnnotationClass?.markerPositivity;
          }
        });
      }

      updateAnnotationSettings({ selectedIndexes: [last(editContext.featureIndexes)] });
      updateFeatureCollection(updatedData, {
        type: ActionTypes.ADD_FEATURE,
        featureType: last(updatedData?.features).properties.shapeSubType,
      });
    } else if (editType === 'translated') {
      updateFeatureCollection(updatedData, {
        type: ActionTypes.MOVE_FEATURE,
      });
    } else if (editType === 'finishMovePosition') {
      updateFeatureCollection(updatedData, {
        type: ActionTypes.MODIFY_FEATURE,
      });
    } else {
      // The signal is used to hold also the tentative state of the features
      // (e.g. while moving a feature, or while modifying a polygon's point)
      // and the store is used to hold each step of drawing (according to what we support)
      if (viewerAnnotationData) {
        viewerAnnotationData[viewerIndex].value = updatedData;
      }
    }
  };

  const getCursor = ({ isDragging }: { isDragging: boolean }) => {
    if (editAnnotationsMode === SlideAnnotationEditType.Delete) {
      // Allow panning when not hovering over a feature
      if (isHoveringOverFeature) {
        return 'crosshair';
      } else if (isDragging) {
        return 'grabbing';
      }
      return 'grab';
    } else if (
      includes(
        [SlideAnnotationEditType.Point, SlideAnnotationEditType.Roi, SlideAnnotationEditType.Polygon],
        editAnnotationsMode
      )
    ) {
      return 'crosshair';
    } else {
      if (isDragging) {
        return 'grabbing';
      }
      return 'grab';
    }
  };

  const allFeatureIndexes = useMemo(
    () => times(editAnnotationFeaturesNumber, (i) => i),
    [editAnnotationFeaturesNumber]
  );
  const finalSelectedIndexes =
    (editAnnotationsMode === SlideAnnotationEditType.Move || editAnnotationsMode === SlideAnnotationEditType.Select) &&
      selectedFeatureIndexes != null
      ? selectedFeatureIndexes
      : allFeatureIndexes;

  const EditableGeoJsonLayerMode =
    editAnnotationsMode === SlideAnnotationEditType.Polygon
      ? DrawAnnotationPolygonMode
      : editAnnotationsMode === SlideAnnotationEditType.Roi
        ? DrawAnnotationRectangleMode
        : editAnnotationsMode === SlideAnnotationEditType.Point
          ? DrawAnnotationPointMode
          : editAnnotationsMode === SlideAnnotationEditType.Modify
            ? ModifyOrthographicMode
            : editAnnotationsMode === SlideAnnotationEditType.Move
              ? TranslateOrthographicMode
              : ViewMode;

  const shouldShowLayer = activeAnnotationAssignmentId && !isSlideAnnotationsLoading;

  const editLayerId = `annotation-editable-${slideId}-${savedAnnotationData?.annotationId || 'new'}`;

  const editAnnotationLayer = !shouldShowLayer
    ? null
    : // @ts-ignore
    (new EditableGeoJsonLayer({
      id: editLayerId,
      coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
      getRadius: 2,
      data: editAnnotationFeatureCollection,
      selectedFeatureIndexes: finalSelectedIndexes ?? [],
      // @ts-ignore
      onClick,
      onEdit,
      mode: EditableGeoJsonLayerMode,
      modeConfig: {
        viewport: { ...viewState, ...viewSize },
        selectedIndexes: finalSelectedIndexes ?? [],
        ...(editAnnotationsMode ? { lockRectangles: true } : {}),
        ...(editAnnotationsMode === SlideAnnotationEditType.Polygon ? { throttleMs: 100 } : {}),
      },
      pickable: !middleButtonDown,
      autoHighlight: editAnnotationsMode === SlideAnnotationEditType.Delete,
      highlightColor: [255, 255, 255, 25],
      stroked: true,
      editHandleType: 'point',
      getFillColor: getFillColor,
      getLineColor: getLineColor,
      getTentativeFillColor: () => [0, 0, 0, 0],
      _subLayerProps: {
        tooltips: { getColor: TOOLTIP_COLOR },
        geojson: {
          updateTriggers: {
            getFillColor: [getFillColor],
            getLineColor: [getLineColor],
          },
        },
      },
      getEditHandleIcon: getEditHandleTypeFromEitherLayer,
      getEditHandleIconSize: 40,
      getEditHandleIconColor: getEditHandleColor,

      pointRadiusMinPixels: 0,
      pointRadiusScale: 2,

      parameters: {
        depthTest: true,
        depthMask: false,

        blend: true,
        blendEquation: GL.FUNC_ADD,
        blendFunc: [GL.SRC_ALPHA, GL.ONE_MINUS_SRC_ALPHA],
      },
      updateTriggers: {
        renderSublayers: [editAnnotationsMode],
      },
    }) as Layer<any>);

  if (shouldShowLayer && editAnnotationsMode === SlideAnnotationEditType.Delete) {
    // Use onHover to detect hover state
    // @ts-ignore
    editAnnotationLayer.onHover = (info: PickingInfo) => {
      setIsHoveringOverFeature(Boolean(info.object)); // Set to true if hovering over a feature
    };
  }
  if (shouldShowLayer && editAnnotationsMode !== SlideAnnotationEditType.Modify) {
    // @ts-ignore
    editAnnotationLayer.getCursor = getCursor;
  }

  let selectionLayer = null;
  const onSelect = async ({ pickingInfos }: any) => {
    // ensure that only one feature type (point, polygon) is selected (so the diagnosis selection is correct for all of them)
    const differentFeatureTypes: string[] = uniq(
      map(
        map(
          pickingInfos,
          (pickingInfo) => editAnnotationFeatureCollection?.features[pickingInfo.index]?.properties?.shapeSubType
        ),
        // roi are also polygons and all rois and polygons can get the same diagnosis
        (featureSubType) => (featureSubType === ShapeSubTypes.Rect ? ShapeSubTypes.Polygon : featureSubType)
      )
    );

    // If the features selected are not from the same type, we take by this order:
    // 1. points
    // 2. polygons (including rois)
    // 3. the first type found (for now we don't have another type so this step not supposed to be reached)
    if (size(differentFeatureTypes) > 1) {
      const containsPoints = includes(differentFeatureTypes, ShapeSubTypes.Point);
      const containsPolygons =
        includes(differentFeatureTypes, ShapeSubTypes.Polygon) || includes(differentFeatureTypes, ShapeSubTypes.Rect);
      if (containsPoints || containsPolygons) {
        pickingInfos = filter(pickingInfos, (pi) =>
          containsPoints
            ? pi.object?.properties?.shapeSubType === ShapeSubTypes.Point
            : pi.object?.properties?.shapeSubType === ShapeSubTypes.Polygon ||
            pi.object?.properties?.shapeSubType === ShapeSubTypes.Rect
        );
        const featureIndexes = map(pickingInfos, (pi) => pi?.index) ?? [];
        updateAnnotationSettings({ featureIndexesOfSelectedArea: featureIndexes, selectedIndexes: featureIndexes });
      } else {
        console.warn(
          'Different feature types selected which are not cells or polygons (including rois). taking the first feature type',
          {
            differentFeatureTypes,
          }
        );
        const featureType = first(differentFeatureTypes);
        const featureIndexes = filter(pickingInfos, (pi) => pi.object?.properties?.shapeSubType === featureType);
        updateAnnotationSettings({
          featureIndexesOfSelectedArea: featureIndexes,
          selectedIndexes: featureIndexes,
        });
      }
    } else {
      const featureIndexes = map(pickingInfos, (pi) => pi?.index) ?? [];
      updateAnnotationSettings({ featureIndexesOfSelectedArea: featureIndexes, selectedIndexes: featureIndexes });
    }
  };

  if (shouldShowLayer && editAnnotationsMode === SlideAnnotationEditType.Select) {
    selectionLayer =
      // @ts-ignore
      new SelectionOrthographicLayer({
        id: 'selection',
        selectionType: SELECTION_TYPE.RECTANGLE,
        onSelect,
        layerIds: [editLayerId],
        getTentativeFillColor: () => [255, 0, 255, 100],
        getTentativeLineColor: () => [0, 0, 255, 255],
        lineWidthMinPixels: 3,
      });
  }

  return { editAnnotationLayer, selectionLayer };
};
