import { Grid, Skeleton } from '@mui/material';
import { DataGrid, GridEventListener, GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params';

import { jobDrawerWidth, Main } from 'components/atoms/FlexContainers/FlexContainers';
import wrapPage from 'components/atoms/wrapPage/wrapPage';
import JobModal from 'components/JobModal';
import PageHeader from 'components/PageHeader';
import { getJobManifestSlideIds, Job, JobType } from 'interfaces/job';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import useStudy from 'utils/useStudy';
import { useJobColumns } from './columns';
import Filters from './Filters';
import { JobDrawer } from './JobDrawer';
import { defaultRowsPerPage, useJobs } from './useJobs';

const Jobs: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [currentJobModal, setCurrentJobModal] = useState<{ jobType: JobType; jobData: Job }>(null);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [page, setPage] = useState(0);

  const { jobColumns, isLoading: isLoadingColumnsQueries } = useJobColumns({ setCurrentJobModal });
  const { labSearch, labId } = useCurrentLabId();

  const navigate = useNavigate();
  const {
    encodedFilters,
    queryParams: { filters: { studyId } = {} },
  } = useEncodedFilters();
  const location = useLocation();

  const { data, isLoading, isFetching } = useJobs({
    page: page + 1,
    pageSize: defaultRowsPerPage,
    fetchWithInterval: true,
    studyId,
    orderBy: JSON.stringify(sortModel),
  });

  const studyQuery = useStudy(studyId, { enabled: Boolean(studyId) });

  const rowCount = data?.totalJobs ?? data?.jobs?.length;

  const [queryParams, setQueryParams] = useQueryParams({
    jobDrawerOpen: BooleanParam,
    currentJobId: StringParam,
  });

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    setQueryParams({ jobDrawerOpen: true });
    setQueryParams({ currentJobId: params.row.id });
  };

  const handlePaginationModelChange = (paginationModel: GridPaginationModel) => {
    setPage(paginationModel.page);
  };

  return (
    <>
      <Grid container direction="column">
        <Grid item mt={4}>
          {studyQuery.isLoading ? (
            <Skeleton variant="text" width="400px" />
          ) : (
            <PageHeader
              pageTitle={`${studyQuery.data?.name} Jobs`}
              onBackClick={() => {
                const search = location.state?.cameFrom === '/procedures' ? encodedFilters : labSearch;
                navigate({ pathname: location.state?.cameFrom || '/', search: search });
              }}
            />
          )}
        </Grid>
        <Grid width={queryParams.jobDrawerOpen ? `calc(100% - ${jobDrawerWidth}px)` : 'auto'} item>
          <Filters />
        </Grid>
        <Main rightDrawerOpen={queryParams.jobDrawerOpen} rightDrawerWidth={jobDrawerWidth}>
          <DataGrid
            autoHeight
            paginationModel={{ page, pageSize: defaultRowsPerPage }}
            onPaginationModelChange={handlePaginationModelChange}
            loading={isLoading || isFetching || isLoadingColumnsQueries}
            pagination
            rows={data?.jobs}
            rowCount={rowCount}
            columns={jobColumns}
            onRowClick={handleRowClick}
            paginationMode="server"
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={setSortModel}
          />
        </Main>
      </Grid>
      {!isEmpty(queryParams.currentJobId) && (
        <JobDrawer
          key={queryParams.currentJobId}
          jobDrawerOpen={queryParams.jobDrawerOpen}
          setJobDrawerOpen={(open) => {
            setQueryParams({ jobDrawerOpen: open });
          }}
          currentJobId={queryParams.currentJobId}
        />
      )}
      {currentJobModal?.jobData && (
        <JobModal
          casesParams={{
            labId: labId,
            studyId: currentJobModal.jobData.studyId,
            filters: { studyId: currentJobModal.jobData.studyId },
            slideIdsToInclude: getJobManifestSlideIds(currentJobModal.jobData),
            slidesMode: true,
          }}
          jobType={currentJobModal.jobType}
          jobId={currentJobModal.jobData.id}
          onClose={() => {
            setCurrentJobModal(null);
          }}
        />
      )}
    </>
  );
};

export default wrapPage(Jobs, { maxWidth: '1500px' }, false);
