import { CircularProgress } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { filter, find, forEach, includes, isEmpty, lowerCase, map, size } from 'lodash';
import React, { useMemo } from 'react';

import { viewerLastRenderTime } from 'components/Procedure/SlidesViewer/DeckGLViewer/viewerDataSignals';
import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { defaultLayerColors } from 'components/theme/theme';
import { AreaType } from 'interfaces/areaType';
import { Permission } from 'interfaces/permissionOption';
import { fromPairs } from 'lodash/fp';
import { humanize } from 'utils/helpers';
import { useAreaTypes } from 'utils/queryHooks/useAreaTypes';
import { usePermissions } from 'utils/usePermissions';
import { useProtomapTilesList } from 'utils/useProtomapTiles';
import { GroupedLayersVisualControls } from '../../../GroupedLayersVisualControls';
import { defaultHeatmapOpacity, isHeatmapActive } from '../../../slidesVisualizationAndConfiguration';
import { getHeatmapUrlKeyFromFeatureMetadata, heatmapToSubtitle } from './helpers';
import { useUpdatePmtHeatmapsSettingsOnChange } from './useUpdatePmtHeatmapsSettingsOnChange';

export const getPmtLayerId = (pmtHeatmap: FeatureMetadata, layerName: string) => `${pmtHeatmap.id}-${layerName}`;

export const computeDefaultPmtLayerSettings = ({
  pmtHeatmap,
  layerIndex,
  layerName,
  areaTypes,
}: {
  pmtHeatmap: FeatureMetadata;
  layerIndex: number;
  layerName?: string;
  areaTypes?: AreaType[];
}) => {
  const matchingAreaTypeDefaultColor = find(areaTypes, (areaType) => areaType.id === layerName)?.defaultColor;

  const optionFromHeatmap = find(pmtHeatmap?.options, { key: layerName });
  const colorByIndex = defaultLayerColors[+layerIndex % size(defaultLayerColors)];
  return {
    id: getPmtLayerId(pmtHeatmap, layerName),
    color: matchingAreaTypeDefaultColor || optionFromHeatmap?.color || colorByIndex,
    colorByIndex,
    opacity: defaultHeatmapOpacity,
    show: false,
    select: false,
  };
};

export const ProtomapTree: React.FC<{
  addOrchestrationIdToUrl?: boolean;
  pmtHeatmaps: FeatureMetadata[];
  slideId: string;
  viewerIndex: number;
  stainTypeId: string;
  filterText: string;
  hideOrchestrationId?: boolean;
  onEmptyFilter?: () => void;
}> = ({
  pmtHeatmaps,
  slideId,
  viewerIndex,
  filterText,
  hideOrchestrationId,
  stainTypeId,
  addOrchestrationIdToUrl,
  onEmptyFilter,
}) => {
  useSignals();

  const currentViewerLastRenderTime = viewerLastRenderTime[viewerIndex].value;

  const { pmtLayersData } = useProtomapTilesList(pmtHeatmaps);

  const heatmapsActivityState = fromPairs(
    map(map(pmtHeatmaps, 'id'), (heatmapId) => [heatmapId, isHeatmapActive({ heatmapId, slideId, viewerIndex })])
  );
  const { isLoading: isLoadingAreaTypes } = useAreaTypes();
  const groupLoadingStates = useMemo(() => {
    const result: { [key: string]: boolean } = {};

    forEach(pmtLayersData, ({ pmtHeatmap, pmtTileSource, pmtMetadata, isLoading, dataUpdatedAt }) => {
      const pmtHeatmapId = pmtHeatmap.id;
      const hasData = Boolean(pmtTileSource && pmtMetadata);
      result[pmtHeatmapId] =
        // Area types are loading (used for default colors)
        isLoadingAreaTypes ||
        // Query is running
        isLoading ||
        // There is no data but the heatmap is active
        (!hasData && heatmapsActivityState[pmtHeatmapId]) ||
        // Query is done but rendered data is outdated
        (hasData && dataUpdatedAt > currentViewerLastRenderTime);
    });

    return result;
  }, [pmtLayersData, currentViewerLastRenderTime, JSON.stringify(heatmapsActivityState)]);

  const layerIdsToDisplayNames = useMemo(() => {
    const result: { [key: string]: string } = {};

    forEach(pmtHeatmaps, (pmtHeatmap) => {
      result[pmtHeatmap.id] = pmtHeatmap?.displayName;
      forEach(pmtHeatmap?.nestedItems, (layer) => {
        if (layer) {
          result[getPmtLayerId(pmtHeatmap, layer.key)] = humanize(layer.displayName || layer.key || layer.id);
        }
      });
    });
    return result;
  }, [pmtHeatmaps]);

  const layerIdsToUrlKeys = useMemo(() => {
    const result: { [key: string]: string } = {};

    forEach(pmtHeatmaps, (pmtHeatmap) => {
      result[pmtHeatmap.id] = getHeatmapUrlKeyFromFeatureMetadata({
        heatmap: pmtHeatmap,
        addOrchestrationId: addOrchestrationIdToUrl,
      });
      forEach(pmtHeatmap?.nestedItems, (layer) => {
        if (layer) {
          const layerId = getPmtLayerId(pmtHeatmap, layer.key);
          // Multiple internal heatmaps can have the same layer key, so we need to use the orchestration id to differentiate them
          result[layerId] = getHeatmapUrlKeyFromFeatureMetadata({
            heatmap: layer,
            parentHeatmap: pmtHeatmap,
            addOrchestrationId: addOrchestrationIdToUrl,
          });
        }
      });
    });
    return result;
  }, [pmtHeatmaps, addOrchestrationIdToUrl]);

  const didApplyInitialSettings = useUpdatePmtHeatmapsSettingsOnChange({
    slideId,
    viewerIndex,
    stainTypeId,
    pmtHeatmaps,
    layerIdsToUrlKeys,
  });

  const groupedFilterLayers = useMemo(() => {
    const result: { [key: string]: string[] } = {};

    forEach(pmtHeatmaps, (pmtHeatmap) => {
      const pmtLayers = pmtHeatmap?.nestedItems;

      const filteredLayers =
        filterText !== ''
          ? filter(pmtLayers, (pmtLayerHeatmap) => {
              if (filterText === '') return true;

              const lowerCaseFilter = lowerCase(filterText);
              return (
                includes(lowerCase(pmtLayerHeatmap?.key), lowerCaseFilter) ||
                includes(lowerCase(pmtLayerHeatmap?.displayName), lowerCaseFilter)
              );
            })
          : pmtLayers;
      result[pmtHeatmap.id] = map(filteredLayers, 'key');
    });
    if (isEmpty(result)) {
      onEmptyFilter?.();
    }
    return result;
  }, [pmtHeatmaps, filterText]);

  const groupDisplayNames = useMemo(() => {
    const result: { [key: string]: string } = {};

    forEach(pmtHeatmaps, (pmtHeatmap) => {
      result[pmtHeatmap.id] = pmtHeatmap?.displayName || pmtHeatmap?.key;
    });
    return result;
  }, [pmtHeatmaps]);

  const { hasPermission } = usePermissions();
  const canSeeOrchestrationId = !hideOrchestrationId && hasPermission(Permission.SeeOrchestrationId);

  const groupSubtitles = useMemo(() => {
    const result: { [key: string]: React.ReactNode } = {};

    forEach(pmtHeatmaps, (pmtHeatmap) => {
      result[pmtHeatmap.id] = heatmapToSubtitle({
        heatmap: pmtHeatmap,
        canSeeOrchestrationId,
        showFileTypes: canSeeOrchestrationId,
      });
    });
    return result;
  }, [pmtHeatmaps, canSeeOrchestrationId]);

  return (
    !isEmpty(groupedFilterLayers) &&
    (didApplyInitialSettings ? (
      <GroupedLayersVisualControls
        viewerIndex={viewerIndex}
        slideId={slideId}
        groupedLayers={groupedFilterLayers}
        groupSubtitles={groupSubtitles}
        groupLoadingStates={groupLoadingStates}
        groupDisplayNames={groupDisplayNames}
        layerIdsToDisplayNames={layerIdsToDisplayNames}
        stainTypeId={stainTypeId}
        hideOrchestrationId={hideOrchestrationId}
      />
    ) : (
      <CircularProgress />
    ))
  );
};
