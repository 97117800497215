import { useSignals } from '@preact/signals-react/runtime';
import { Feature } from '@turf/helpers';
import { getSlideAnnotationsQueryKey, publishAnnotationVersion, saveAnnotationDraft } from 'api/annotations';
import {
  editTypeToShapeSubTypeMap,
  ShapeSubTypes,
  SlideAnnotationEditType,
} from 'components/Procedure/Header/SlideInteractionMenu/SlideAnnotationTools/options';
import {
  getAnnotationSettingsKey,
  getAnnotationTodoNameAndDefinition,
} from 'components/Procedure/Infobar/SlideInfobar/SlideAnnotation/helpers';
import { slidesLayerVisualizationSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { Annotation, TodoOption } from 'interfaces/annotation';
import { Permission } from 'interfaces/permissionOption';
import {
  cloneDeep,
  compact,
  concat,
  find,
  first,
  fromPairs,
  get,
  includes,
  isEqual,
  isNil,
  isNumber,
  keys,
  map,
  pick,
  values,
} from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useMemo } from 'react';
import {
  AnnotationState,
  viewerAnnotationSettings,
  viewerAnnotationSettingsDefaultValues,
} from 'services/annotations/annotationsSettings';
import markerAnnotationService from 'services/annotations/markerAnnotationService';
import { useActiveAnnotationAssignmentForViewer } from 'services/annotations/useAnnotationQueryParams';
import { StringParam, useQueryParam } from 'use-query-params';
import { getColorHex, hexToRgb } from 'utils/helpers';
import queryClient from 'utils/queryClient';
import { ANNOTATIONS_BASE_QUERY_KEY } from 'utils/useAnnotationAssignments';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { usePermissions } from 'utils/usePermissions';
import { FeatureCollection, GeoJsonFeature } from '../NebulaGLExtensions/geojson-types';
import { RGBAColor } from '../NebulaGLExtensions/helpers';
import { useActiveAnnotationDraft } from './useActiveAnnotationDraft';
import { ActionTypes, useAnnotationsStoreWithUndo } from './useAnnotationsWithUndo';

export interface AnnotationItem extends TodoOption {
  selected?: boolean;
  icon?: React.ReactNode;
}

export const UNKNOWN_DIAGNOSIS = 'unknown';

const useAnnotationsForViewer = ({ slideId, viewerIndex }: { slideId: string; viewerIndex: number }) => {
  useSignals();
  const { hasPermission } = usePermissions();
  const canAnnotateSlides = hasPermission(Permission.AnnotateSlides);

  const { activeAnnotationData, slideAnnotations, isLoading } = useActiveAnnotationDraft({
    slideId,
    viewerIndex,
  });

  const { featureCollection: liveAnnotations, updateFeatureCollection } = useAnnotationsStoreWithUndo();

  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];
  const slideLayerVisualizationSettings = viewerSlideLayerVisualizationSettings?.value?.[slideId];

  const [activeAnnotationAssignmentId] = useActiveAnnotationAssignmentForViewer(viewerIndex);

  const savedAnnotationData = canAnnotateSlides
    ? find(slideAnnotations, { annotationAssignment: { annotationAssignmentId: activeAnnotationAssignmentId } })
    : undefined;
  const { todo } = getAnnotationTodoNameAndDefinition(savedAnnotationData);

  const visualSettingsForAnnotation = fromPairs(
    map(todo?.options, (option) => {
      const annotationSettingsKey = getAnnotationSettingsKey(savedAnnotationData, option?.name);
      return [annotationSettingsKey, slideLayerVisualizationSettings?.[annotationSettingsKey]?.value];
    })
  );

  const getSettingsForFeature = useCallback(
    (feature: Feature) => {
      const annotationId = feature?.properties?.annotationId;
      const diagnosis = feature?.properties?.diagnosis;
      const isMarkerAnnotation = diagnosis === UNKNOWN_DIAGNOSIS;
      // If we don't know the diagnosis, take the first marker positivity
      const marker = isMarkerAnnotation ? first(keys(feature?.properties?.markerPositivity)) : null;
      // The marker annotation is saved in the todo as the option name
      // And in the annotation itself it is saved inside the marker positivity and not the diagnosis
      const diagnosisForSettings = isMarkerAnnotation ? marker : diagnosis;
      const annotation = find(slideAnnotations, { annotationId });

      const annotationSettingsKey = annotation ? getAnnotationSettingsKey(annotation, diagnosisForSettings) : undefined;
      const featureSettings = annotationSettingsKey ? visualSettingsForAnnotation?.[annotationSettingsKey] : undefined;
      // If there is no diagnosis there is no settings (meaning opacity 0) and we still want to show the feature
      const opacity = !diagnosis
        ? 255
        : featureSettings?.selected && featureSettings?.show
        ? ((featureSettings?.opacity ?? 100) / 100) * 255
        : 0;

      let color = featureSettings?.color;

      if (!color) {
        const annotationTodo = getAnnotationTodoNameAndDefinition(annotation);

        // Use the color from the todo option if it exists, otherwise look in all todos
        const todoOptions = annotationTodo?.todo?.options;
        const matchingOption = find(todoOptions, { name: diagnosisForSettings });
        color = matchingOption?.color;
      }
      return { ...featureSettings, opacity, color };
    },
    [slideAnnotations, JSON.stringify(visualSettingsForAnnotation)]
  );

  const getColorForFeature = useCallback(
    (feature: Feature, isSelected: boolean, baseOpacity: number = 100): RGBAColor => {
      const matchingVisualizationSetting = getSettingsForFeature(feature);
      const opacity =
        (matchingVisualizationSetting?.opacity ?? 100) *
        (isNumber(baseOpacity) ? baseOpacity : 1) *
        (isSelected ? 1 : 0.5);
      const bright = isSelected ? 1 : 0.5;

      return matchingVisualizationSetting.color
        ? [...map(hexToRgb(getColorHex(matchingVisualizationSetting.color)), (c) => c * bright), opacity]
        : [180, 180, 180, opacity];
    },
    [getSettingsForFeature]
  );

  /**
   * Get fill color for feature
   * If the feature is a negative marker annotation then the fill color should be a bit transparent
   *
   * @param feature - The feature to get the fill color for
   * @returns The fill color for the feature
   */
  const getFillColorForFeature = useCallback(
    (feature: Feature, isSelected: boolean, opacity?: number) => {
      if (opacity) {
        return getColorForFeature(feature, isSelected, opacity);
      }

      let defaultOpacity = 1;

      const diagnosis = feature?.properties?.diagnosis;
      const isMarkerAnnotation = diagnosis === UNKNOWN_DIAGNOSIS;
      // Take the first marker positivity (if we don't know the diagnosis, color the feature by the marker)
      const markerValue = isMarkerAnnotation ? first(values(feature?.properties?.markerPositivity)) : null;
      if (
        (isMarkerAnnotation && !isNil(markerValue) && !markerValue) ||
        feature?.properties?.shapeSubType !== ShapeSubTypes.Point
      ) {
        defaultOpacity = 0.5;
      }

      return getColorForFeature(feature, isSelected, defaultOpacity);
    },
    [getColorForFeature]
  );

  const getTodoOptionColor = useCallback(
    (annotation: Annotation, option: TodoOption) => {
      const annotationSettingsKey = annotation ? getAnnotationSettingsKey(annotation, option.name) : undefined;
      const featureSettings = annotationSettingsKey ? visualSettingsForAnnotation?.[annotationSettingsKey] : undefined;
      return featureSettings?.color;
    },
    [JSON.stringify(visualSettingsForAnnotation)]
  );

  const getFilteredAnnotationItemsByFeatureIndex = useCallback(
    (featureIndex: number) => {
      const annotationFeatures = activeAnnotationData?.features;
      const feature = annotationFeatures?.[featureIndex];
      const annotationId = feature?.properties?.annotationId;
      const annotation = find(slideAnnotations, { annotationId });
      const isPoint = feature?.geometry.type === 'Point';
      // including roi
      const isPolygon = feature?.geometry.type === 'Polygon';

      return feature?.properties?.markerType === 'tagger_annotation'
        ? compact(
            map(todo?.options, (option) => {
              const color = getTodoOptionColor(annotation, option);
              return !option.className ||
                (isPoint && getShapeSubTypeFromTodoOption(option) === ShapeSubTypes.Point) ||
                (isPolygon &&
                  (getShapeSubTypeFromTodoOption(option) === ShapeSubTypes.Rect ||
                    getShapeSubTypeFromTodoOption(option) === ShapeSubTypes.Polygon))
                ? {
                    ...option,
                    positive: option.positive,
                    color: color ?? option.color,
                    selected: option.name === feature.properties.optionName,
                    isPoint,
                  }
                : null;
            })
          )
        : [];
    },
    [activeAnnotationData, todo, slideAnnotations, getTodoOptionColor]
  );

  const updateAnnotationSettings = ({
    todoOption,
    selectedIndexes,
    featureIndexesOfSelectedArea,
  }: {
    todoOption?: TodoOption;
    selectedIndexes?: number[];
    featureIndexesOfSelectedArea?: number[];
  }) => {
    if (viewerAnnotationSettings[viewerIndex]) {
      const newAnnotationSettings =
        cloneDeep(viewerAnnotationSettings[viewerIndex].value) || viewerAnnotationSettingsDefaultValues;

      if (todoOption) {
        const newClassesToAnnotatePerGeometry: { [K in ShapeSubTypes]?: AnnotationState } =
          viewerAnnotationSettings[viewerIndex].value?.currentClassToAnnotatePerGeometry ||
          ({} as { [K in ShapeSubTypes]?: AnnotationState });
        const optionShapeSubType = getShapeSubTypeFromTodoOption(todoOption);
        newClassesToAnnotatePerGeometry[optionShapeSubType] = {
          name: todoOption.name,
          ...(todoOption.isMarkerAnnotation ? { positive: todoOption.positive ?? true } : {}),
        };

        newAnnotationSettings.currentClassToAnnotatePerGeometry = newClassesToAnnotatePerGeometry;
      }

      if (featureIndexesOfSelectedArea) {
        newAnnotationSettings.featureIndexesOfSelectedArea = featureIndexesOfSelectedArea;
      }

      if (selectedIndexes) {
        newAnnotationSettings.selectedIndexes = selectedIndexes;
      }

      viewerAnnotationSettings[viewerIndex].value = newAnnotationSettings;
    }
  };

  const annotationItems = useMemo(
    () =>
      map(todo?.options, (option) => {
        const color = getTodoOptionColor(savedAnnotationData, option);
        return {
          ...option,
          color: color ?? option.color,
          selected: false,
        };
      }),
    [todo, savedAnnotationData, getTodoOptionColor]
  );

  const savedAnnotation = useMemo(
    () =>
      find(slideAnnotations, {
        annotationAssignment: { annotationAssignmentId: activeAnnotationAssignmentId },
      }),
    [slideAnnotations, activeAnnotationAssignmentId]
  );

  const getFeaturesForComparison = useCallback(
    ({ draft = false }: { draft?: boolean } = {}): GeoJsonFeature[] => {
      try {
        const featureCollection = markerAnnotationService.convertAnnotationToGeoJson({
          annotation: cloneDeep(savedAnnotation),
          draft,
        });

        return map(featureCollection.features, (feature) => {
          return {
            ...feature,
            properties: getPropertiesForComparison(feature.properties),
          };
        });
      } catch (error) {
        console.error('Error converting annotation to GeoJSON', error);
        return [];
      }
    },
    [savedAnnotation]
  );

  const savedAnnotationDraftFeatureCollection = useMemo(
    () => getFeaturesForComparison({ draft: true }),
    [getFeaturesForComparison]
  );
  const publishedAnnotationsFeatureCollection = useMemo(() => getFeaturesForComparison(), [getFeaturesForComparison]);

  const viewerFeatureForComparison: GeoJsonFeature[] = map(liveAnnotations?.features, (features) => {
    return {
      ...features,
      properties: getPropertiesForComparison(features.properties),
    };
  });

  const draftDataSaved =
    liveAnnotations === null || isEqual(viewerFeatureForComparison, savedAnnotationDraftFeatureCollection);

  const publishedAnnotationsSaved = useMemo(
    () => isEqual(savedAnnotationDraftFeatureCollection, publishedAnnotationsFeatureCollection),
    [savedAnnotationDraftFeatureCollection, publishedAnnotationsFeatureCollection]
  );

  const saveDraftMutation = useMutationWithErrorSnackbar({
    mutationFn: saveAnnotationDraft,
    mutationDescription: 'save annotation draft',
    onSuccess: (data, { annotationAssignmentId, slideId: updatedSlideId, annotationsData }) => {
      queryClient.setQueryData(
        getSlideAnnotationsQueryKey({ slideId: updatedSlideId, includeEmpty: true }),
        (oldData: Annotation[]) => {
          if (oldData) {
            return map(oldData, (annotation) => {
              if (annotation.annotationAssignment.annotationAssignmentId === annotationAssignmentId) {
                return {
                  ...annotation,
                  draftAnnotationsData: annotationsData,
                };
              }
              return annotation;
            });
          }
          return oldData;
        }
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries([ANNOTATIONS_BASE_QUERY_KEY]);
    },
  });

  const publishAnnotationsMutation = useMutationWithErrorSnackbar({
    mutationFn: publishAnnotationVersion,
    mutationDescription: 'publish annotations',
    onMutate: ({ annotationAssignmentId, slideId: updatedSlideId }) => {
      queryClient.setQueryData(
        getSlideAnnotationsQueryKey({ slideId: updatedSlideId, includeEmpty: true }),
        (oldData: Annotation[]) => {
          if (oldData) {
            return map(oldData, (annotation) => {
              if (annotation.annotationAssignment.annotationAssignmentId === annotationAssignmentId) {
                return {
                  ...annotation,
                  annotationsData: annotation.draftAnnotationsData,
                };
              }
              return annotation;
            });
          }
          return oldData;
        }
      );
    },
    onSuccess: () => {
      enqueueSnackbar('Annotations published', { variant: 'success' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(getSlideAnnotationsQueryKey({ slideId: slideId, includeEmpty: true }));
      queryClient.invalidateQueries([ANNOTATIONS_BASE_QUERY_KEY]);
    },
  });

  const saveDraft = ({
    assignmentId,
    featureCollection,
    showSuccessSnackbar = true,
  }: {
    assignmentId: number;
    featureCollection: FeatureCollection;
    showSuccessSnackbar?: boolean;
  }) => {
    saveDraftMutation.mutate(
      {
        annotationAssignmentId: assignmentId,
        slideId,
        annotationsData: [markerAnnotationService.convertGeoJsonToAnnotationsData(featureCollection)],
      },
      {
        onSuccess: () => {
          if (showSuccessSnackbar) {
            enqueueSnackbar('Annotation saved', { variant: 'success' });
          }
        },
      }
    );
  };

  const [editAnnotationsMode] = useQueryParam('editAnnotationsMode', StringParam);

  const currentViewerAnnotationSettings = viewerAnnotationSettings?.[viewerIndex]?.value || undefined;
  const activeAnnotationClass: TodoOption = useMemo(() => {
    const currentClassToAnnotatePerGeometry = currentViewerAnnotationSettings?.currentClassToAnnotatePerGeometry;
    const currentShapeSubtype = get(editTypeToShapeSubTypeMap, editAnnotationsMode, '');
    const currentClassToAnnotate = get(currentClassToAnnotatePerGeometry, currentShapeSubtype, null);
    const matchingOption = find(annotationItems, { name: currentClassToAnnotate?.name });

    return matchingOption ? { ...matchingOption, positive: currentClassToAnnotate.positive } : ({} as TodoOption);
  }, [editAnnotationsMode, annotationItems, currentViewerAnnotationSettings]);

  const addFixedRoiOnCoordinate = useCallback(
    (coordinate: [number, number], roiSize: [number, number]) => {
      const currentFeatureCollection = cloneDeep(activeAnnotationData);
      const newRoiProperties: {
        [key: string]: any;
      } = {};
      newRoiProperties.shapeSubType = get(editTypeToShapeSubTypeMap, SlideAnnotationEditType.Roi, '');
      newRoiProperties.annotationId = savedAnnotationData?.annotationId;
      newRoiProperties.markerType = 'tagger_annotation';
      if (activeAnnotationClass?.name) {
        if (activeAnnotationClass.isMarkerAnnotation) {
          console.warn('Roi should not be marker annotation');
          newRoiProperties.diagnosis = UNKNOWN_DIAGNOSIS;
          newRoiProperties.markerPositivity = {
            [activeAnnotationClass.name]: activeAnnotationClass.positive,
          };
        } else {
          newRoiProperties.diagnosis = activeAnnotationClass.name;
          newRoiProperties.markerPositivity = activeAnnotationClass?.markerPositivity;
        }
      }
      const newFeatureCollection = {
        ...currentFeatureCollection,
        features: concat(currentFeatureCollection?.features, {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                coordinate,
                [coordinate[0] + roiSize[0], coordinate[1]],
                [coordinate[0] + roiSize[0], coordinate[1] + roiSize[1]],
                [coordinate[0], coordinate[1] + roiSize[1]],
                coordinate,
              ],
            ],
          },
          properties: newRoiProperties,
        }),
      };

      updateFeatureCollection(newFeatureCollection, {
        type: ActionTypes.ADD_FEATURE,
        featureType: ShapeSubTypes.Rect,
      });
    },
    [activeAnnotationData, savedAnnotationData, activeAnnotationClass, viewerIndex]
  );

  return {
    savedAnnotationData,
    getFillColorForFeature,
    getColorForFeature,
    getSettingsForFeature,
    todoOptions: todo?.options,
    activeAnnotationData,
    getFilteredAnnotationItemsByFeatureIndex,
    annotationItems,
    updateAnnotationSettings,
    saveDraftMutation,
    publishAnnotationsMutation,
    publishedAnnotationsSaved,
    draftDataSaved,
    activeAnnotationAssignmentId,
    saveDraft,
    slideAnnotations,
    isSlideAnnotationsLoading: isLoading,
    activeAnnotationClass,
    currentViewerAnnotationSettings,
    addFixedRoiOnCoordinate,
  };
};

export default useAnnotationsForViewer;

export const getShapeSubTypeFromTodoOption = (todoOption: TodoOption): ShapeSubTypes => {
  if (todoOption.className.toLocaleLowerCase() == 'point') {
    return ShapeSubTypes.Point;
  }
  if (includes(todoOption.displayName.toLocaleLowerCase(), 'roi')) {
    return ShapeSubTypes.Rect;
  }
  return ShapeSubTypes.Polygon;
};

// if old annotations are saved with more data than needed for comparison, this function will remove the extra data
const getPropertiesForComparison = (properties: any) => {
  return pick(properties, ['diagnosis', 'markerType', 'shapeSubType', 'markerPositivity']);
};
