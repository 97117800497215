import { Button, CircularProgress } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { NumberParam, useQueryParam } from 'use-query-params';

import ConfirmationModal from 'components/modals/ConfirmationModal';
import { Permission } from 'interfaces/permissionOption';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { usePermissions } from 'utils/usePermissions';
import useResultsMutation, { ArchiveAction } from './useResultsMutation';

export interface ExperimentResultArchivalProps {
  experimentResultIds?: number[];
  studyId: string;
  isDeleted: boolean;
  onArchivalMutation?: () => void;
}

export const ExperimentResultArchival: React.FunctionComponent<ExperimentResultArchivalProps> = ({
  experimentResultIds,
  studyId,
  isDeleted,
  onArchivalMutation,
}) => {
  const { queryParams } = useEncodedFilters();
  const [caseIdFromParam] = useQueryParam('caseId', NumberParam);

  const { hasPermission } = usePermissions();
  const canHideSecondaryResults = hasPermission(
    Permission.CanArchiveViewRestoreOwnResults || Permission.CanViewRestoreResultsArchivedByAny
  );

  const params = useParams();
  const caseId = queryParams.slidesMode ? Number(caseIdFromParam) : Number(params.id);

  const { isLoading, handleArchiveAndRestore } = useResultsMutation(caseId, studyId, onArchivalMutation);

  const [archiveAction, setArchiveAction] = React.useState<ArchiveAction | null>(null);

  const confirmationModalText =
    archiveAction === 'archive'
      ? 'Are you sure you want to archive these results?'
      : 'Are you sure you want to restore these results?';

  return (
    canHideSecondaryResults && (
      <>
        <Button
          size="small"
          onClick={(event) => {
            event?.stopPropagation();
            event?.preventDefault();
            setArchiveAction(isDeleted ? 'restore' : 'archive');
          }}
          title={isDeleted ? 'Restore Results' : 'Archive Results'}
        >
          {isLoading ? <CircularProgress size={20} /> : isDeleted ? 'Restore' : 'Archive'}
        </Button>
        {archiveAction && (
          <ConfirmationModal
            title={`${archiveAction === 'archive' ? 'Archive' : 'Restore'} Results`}
            text={confirmationModalText}
            onConfirm={() => {
              handleArchiveAndRestore({ experimentResultIds, archiveAction });
              setArchiveAction(null);
            }}
            onCancel={() => setArchiveAction(null)}
          />
        )}
      </>
    )
  );
};

export default ExperimentResultArchival;
