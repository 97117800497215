// This is a copy of Nebula utils so we can implement new functionality over the current Nebula code

import { filter, first, map, size } from 'lodash';
import { AnyCoordinates, Position } from './geojson-types';
import { EditHandleFeature, Pick } from './types';

export function getPickedEditHandles(picks: Pick[] | null | undefined): EditHandleFeature[] {
  const handles =
    (picks &&
      map(
        filter(picks, (pick) => pick.isGuide && pick.object.properties.guideType === 'editHandle'),
        (pick) => pick.object
      )) ||
    [];

  return handles;
}

export function getPickedEditHandle(picks: Pick[] | null | undefined): EditHandleFeature | null | undefined {
  const handles = getPickedEditHandles(picks);
  return first(handles);
}

// This is to check if the dragging (when drawing a polygon) was ending close enough to some other point
export function isPicksCycle(picks: Pick[] | null | undefined): boolean {
  const handles = getPickedEditHandles(picks);
  // If we get 2 or more edit handle picks it means there are more then 1 dot that is close to the current mouse position
  // The last drawn point will always be close to the current position, so we are checking if there are more then 1
  return size(handles) > 1;
}

/** Creates a copy of feature's coordinates.
 * Each position in coordinates is transformed by calling the provided function.
 * @param coords Coordinates of a feature.
 * @param callback A function to transform each coordinate.
 * @retuns Transformed coordinates.
 */
export function mapCoords(coords: AnyCoordinates, callback: (coords: Position) => Position): AnyCoordinates {
  if (typeof coords[0] === 'number') {
    if (!isNaN(coords[0]) && isFinite(coords[0])) {
      return callback(coords as Position);
    }
    return coords;
  }

  return filter(
    map(coords as Position[], (coord) => {
      return mapCoords(coord, callback) as Position;
    }),
    (coord) => Boolean(coord)
  );
}
