import { Dictionary, filter, first, forEach, isEmpty, join, map, size, some, sortBy, values } from 'lodash';

import { LayerVisualizationSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { markerPositivityColumnPrefix } from 'components/Procedure/useSlideChannelsAndResults/parseHeatmaps';
import { defaultLayerColors } from 'components/theme/theme';
import { getColorHex, hexToRgb, isHexString } from 'utils/helpers';
import { ParquetFile, ParquetRow } from 'utils/useParquetFile';
import { SizeInMicronsByZoomRange } from '../ZoomAwareLayers/types';
import { ZoomAwareIconLayer } from '../ZoomAwareLayers/ZoomAwareIconLayer';
import { ZoomAwareScatterplotLayer } from '../ZoomAwareLayers/ZoomAwareScatterplotLayer';
import { getMarkerPositivityParentHeatmapId } from './helpers';

const sizeInMicronsByZoomRangeIconStroked: SizeInMicronsByZoomRange[] = [
  { minZoom: 1, sizeInMicrons: 6 },
  { minZoom: 0, maxZoom: 1, sizeInMicrons: 10 },
  { minZoom: -1, maxZoom: 0, sizeInMicrons: 14 },
  { minZoom: -2, maxZoom: -1, sizeInMicrons: 18 },
  { maxZoom: -2, minZoom: -3, sizeInMicrons: 24 },
  { maxZoom: -3, sizeInMicrons: 50 },
];

const sizeInMicronsByZoomRangeIconFilled: SizeInMicronsByZoomRange[] = [
  { minZoom: 1, sizeInMicrons: 3 },
  { minZoom: 0, maxZoom: 1, sizeInMicrons: 5 },
  { minZoom: -1, maxZoom: 0, sizeInMicrons: 7 },
  { minZoom: -2, maxZoom: -1, sizeInMicrons: 9 },
  { maxZoom: -2, sizeInMicrons: 12 },
];

const sizeInMicronsByZoomRangeScatterPlot: SizeInMicronsByZoomRange[] = [
  { minZoom: -1, sizeInMicrons: 2 },
  { maxZoom: -1, minZoom: -1.5, sizeInMicrons: 2.5 },
  { maxZoom: -1.5, minZoom: -2, sizeInMicrons: 3 },
  { maxZoom: -2, sizeInMicrons: 3.5 },
];

function generateColoredCircle({
  colors,
  radius,
  filled = false,
  strokeSize = Math.floor(radius / 4),
  svgMargin = strokeSize,
}: {
  colors: string[];
  radius?: number;
  filled?: boolean;
  strokeSize?: number;
  svgMargin?: number;
}): string {
  const svgSize = radius * 2 + svgMargin * 2;
  const centerX = radius + svgMargin;
  const centerY = radius + svgMargin;
  const sliceAngle = 360 / colors.length;
  const innerRadius = filled ? 0 : radius - strokeSize; // Inner radius for ring effect

  // Generate SVG
  let svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${svgSize}" height="${svgSize}" viewBox="0 0 ${svgSize} ${svgSize}">`;

  // If only one color, draw a circle / ring
  if (size(colors) === 1) {
    const color = first(colors);
    if (filled) {
      svg += `<circle cx="${centerX}" cy="${centerY}" r="${radius}" fill="${color}"/>`;
    } else {
      svg += `<circle cx="${centerX}" cy="${centerY}" r="${radius}" fill="none" stroke="${color}" stroke-width="${strokeSize}"/>`;
    }
  } else if (size(colors) > 1) {
    forEach(colors, (color, index) => {
      const startAngle = index * sliceAngle;
      const endAngle = (index + 1) * sliceAngle;

      const startAngleRad = (startAngle - 90) * (Math.PI / 180);
      const endAngleRad = (endAngle - 90) * (Math.PI / 180);

      const startXOuter = centerX + radius * Math.cos(startAngleRad);
      const startYOuter = centerY + radius * Math.sin(startAngleRad);
      const endXOuter = centerX + radius * Math.cos(endAngleRad);
      const endYOuter = centerY + radius * Math.sin(endAngleRad);

      const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

      if (filled) {
        const path = `M ${centerX} ${centerY} L ${startXOuter} ${startYOuter} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endXOuter} ${endYOuter} Z`;
        svg += `<path d="${path}" fill="${color}"/>`;
      } else {
        const startXInner = centerX + innerRadius * Math.cos(endAngleRad);
        const startYInner = centerY + innerRadius * Math.sin(endAngleRad);
        const endXInner = centerX + innerRadius * Math.cos(startAngleRad);
        const endYInner = centerY + innerRadius * Math.sin(startAngleRad);

        const path = `M ${startXOuter} ${startYOuter} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endXOuter} ${endYOuter} L ${startXInner} ${startYInner} A ${innerRadius} ${innerRadius} 0 ${largeArcFlag} 0 ${endXInner} ${endYInner} Z`;
        svg += `<path d="${path}" fill="${color}" stroke="none"/>`;
      }
    });
  }

  svg += '</svg>';
  return svg;
}

// TODO: Move to utils
function svgToDataURL(svg: string) {
  return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`;
}

const rgbaRegex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*(?:\.\d+)?))?\)$/;
const rgbaStringToColor = (rgba: string): [number, number, number, number] => {
  const result = rgbaRegex.exec(rgba);
  if (!result) {
    console.error(`Invalid rgba color: ${rgba}`, { rgba });
    return [0, 0, 0, 0];
  }
  return map(result.slice(1), (value, index) => (index < 3 ? parseInt(value, 10) : parseFloat(value) * 255)) as [
    number,
    number,
    number,
    number
  ];
};

export const deckGLParquetMarkerPositivityLayer = ({
  idPrefix = '',
  isFilled,
  markerHeatmaps,
  visualSettings,
  parquetFile,
  rescaleFactor,
  slideMaxResolution,
  scatterPlot,
}: {
  idPrefix?: string;
  isFilled: boolean;
  markerHeatmaps: FeatureMetadata[];
  visualSettings: Dictionary<LayerVisualizationSettings>;
  rescaleFactor?: number;
  parquetFile: ParquetFile;
  slideMaxResolution: number;
  scatterPlot?: boolean;
}) => {
  if (isEmpty(markerHeatmaps)) {
    console.warn('No marker heatmaps provided');
    return null;
  }
  const hasActiveParquetSource = some(values(visualSettings), 'selected');

  const parentHeatmapId = getMarkerPositivityParentHeatmapId(first(markerHeatmaps));

  const markerColumnMetadata = map(
    filter(parquetFile?.metadata?.schema, (field: { name: string }) =>
      field?.name?.startsWith(markerPositivityColumnPrefix)
    ),
    (field) => {
      const layerSettings = visualSettings[`${parentHeatmapId}-${field.name}`];
      const isVisible = layerSettings?.selected && layerSettings?.show && layerSettings?.opacity > 0;
      const opacity = isVisible ? (layerSettings?.opacity ?? 100) / 100 : 0;
      const hexColor = isHexString(getColorHex(layerSettings?.color))
        ? getColorHex(layerSettings?.color)
        : defaultLayerColors[(size(defaultLayerColors) + 1) % size(defaultLayerColors)];
      return { ...field, isVisible, color: `rgba(${join(hexToRgb(hexColor), ', ')}, ${opacity})` };
    }
  );
  if (!parquetFile?.rows || !hasActiveParquetSource || !some(markerColumnMetadata, 'isVisible')) {
    return null;
  }

  const getActiveClassesFromRow = (row: ParquetRow) =>
    filter(markerColumnMetadata, (field, columnOffset) => {
      if (!field.isVisible) {
        return false;
      }
      const markerColumnIndex = columnOffset + 2; // X and Y are the first two columns
      const isPositive = Boolean(row[markerColumnIndex]);
      return isPositive;
    });

  if (scatterPlot) {
    return new ZoomAwareScatterplotLayer<ParquetRow>({
      id: `ZoomAwareScatterplotLayer-${idPrefix}${parentHeatmapId}`,
      data: parquetFile.rows,
      getPosition: (d: ParquetRow) => [d[0], d[1]],
      pickable: false,
      sizeInMicronsByZoomRange: sizeInMicronsByZoomRangeScatterPlot,
      slideMaxResolution,

      // The point radius is scaled based on the zoom level - the higher the zoom level, the higher the point radius, up to the pointRadiusAtMaxZoom.
      // We don't use the pointRadiusMaxPixels because we can zoom beyond the maximum zoom level of the base layer.
      sizeScale: rescaleFactor ? 1 / rescaleFactor : 1,
      sizeUnits: 'meters', // Which really means original slide pixels in this case
      [isFilled ? 'getFillColor' : 'getLineColor']: (row: ParquetRow) => {
        const activeMarkerColumns = getActiveClassesFromRow(row);
        return first(activeMarkerColumns)?.color ? rgbaStringToColor(first(activeMarkerColumns)?.color) : [0, 0, 0, 0];
      },
      filled: isFilled,
      stroked: !isFilled,
      updateTriggers: {
        getRadius: [rescaleFactor],
        getLineColor: [isFilled, JSON.stringify(markerColumnMetadata)],
        getFillColor: [isFilled, JSON.stringify(markerColumnMetadata)],
      },
    });
  } else {
    const iconsCache: Dictionary<{ url: string; width: number; height: number }> = {};
    return new ZoomAwareIconLayer<ParquetRow>({
      id: `ZoomAwareIconLayer-${idPrefix}${parentHeatmapId}`,
      data: parquetFile.rows,
      getPosition: (d: ParquetRow) => [d[0], d[1]],
      pickable: false,
      sizeInMicronsByZoomRange: isFilled ? sizeInMicronsByZoomRangeIconFilled : sizeInMicronsByZoomRangeIconStroked,
      slideMaxResolution,

      // The point radius is scaled based on the zoom level - the higher the zoom level, the higher the point radius, up to the pointRadiusAtMaxZoom.
      // We don't use the pointRadiusMaxPixels because we can zoom beyond the maximum zoom level of the base layer.
      sizeScale: rescaleFactor ? 1 / rescaleFactor : 1,
      sizeUnits: 'meters', // Which really means original slide pixels in this case
      getIcon: (row: ParquetRow) => {
        const activeMarkerColumns = getActiveClassesFromRow(row);
        const columnNameKey = join(sortBy(map(activeMarkerColumns, 'name')), ',');
        if (!iconsCache[columnNameKey]) {
          const diameter = isFilled ? 32 : 64;
          iconsCache[columnNameKey] = {
            url: svgToDataURL(
              generateColoredCircle({
                colors: map(activeMarkerColumns, 'color'),
                radius: diameter / 2,
                filled: isFilled,
              })
            ),
            width: diameter,
            height: diameter,
          };
        }
        return iconsCache[columnNameKey];
      },
      updateTriggers: {
        getRadius: [rescaleFactor],
        getIcon: [JSON.stringify(markerColumnMetadata)],
      },
    });
  }
};
