import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Grid } from '@mui/material';
import {
  ClassificationBinningParams,
  InferenceModelData,
  OrchestrationInference,
  SlideInferenceResults,
} from 'interfaces/calculateFeatures';
import { Dictionary, isEmpty, keys, map } from 'lodash';
import React from 'react';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import { ModelsByStainByType } from '.';
import {
  ModelPreferencesById,
  ModelUserPreferences,
  OrchestrationBySlideByFlowClassName,
  OrchestrationBySlideByType,
} from '..';
import InferenceModel from './InferenceModel';

export interface InferenceStainProps {
  studyId: string;
  stain: string;
  stainModelInferences?: ModelsByStainByType[string];
  orchestrationByFlowClassName?: {
    [flowClassName: string]: OrchestrationInference[];
  };
  slides: Dictionary<SlideInferenceResults>;
  selectedOrchestrations?: OrchestrationBySlideByType;
  setSelectedOrchestrations?: (
    slideIds: string[],
    model: InferenceModelData,
    modelType: string,
    orchestration: OrchestrationInference
  ) => void;
  selectedPostprocessedOrchestrations?: OrchestrationBySlideByFlowClassName;
  setSelectedPostprocessedOrchestrations?: (
    slideIds: string[],
    flowClassName: string,
    orchestration: OrchestrationInference
  ) => void;
  setModelPreferencesByModelId?: (modelId: string, userPreferences: ModelUserPreferences) => void;
  modelPreferencesByModelId?: ModelPreferencesById;
  setSelectedOrchestrationsByClassificationType?: (modelType: string, previousModelType?: string) => void;
  setIntensityClassificationBinning?: (modelId: string, binning: ClassificationBinningParams) => void;
  defaultExpanded?: boolean;
}

const InferenceStain: React.FC<React.PropsWithChildren<InferenceStainProps>> = ({
  studyId,
  stain,
  stainModelInferences,
  orchestrationByFlowClassName,
  slides,
  selectedOrchestrations,
  setSelectedOrchestrations,
  selectedPostprocessedOrchestrations,
  setSelectedPostprocessedOrchestrations,
  modelPreferencesByModelId,
  setModelPreferencesByModelId,
  setSelectedOrchestrationsByClassificationType,
  setIntensityClassificationBinning,
  defaultExpanded = false,
}) => {
  const { stainTypeIdToDisplayName, isLoadingStainTypeOptions } = useStainTypeIdToDisplayName();

  return (
    <Accordion disableGutters defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {isLoadingStainTypeOptions ? <CircularProgress /> : stainTypeIdToDisplayName(stain)}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          {!isEmpty(stainModelInferences)
            ? map(keys(stainModelInferences), (modelType) => {
                return (
                  <Grid item xs={12} key={modelType}>
                    <InferenceModel
                      key={modelType}
                      stain={stain}
                      modelType={modelType}
                      stainModelInferences={stainModelInferences}
                      slides={slides}
                      selectedOrchestrations={selectedOrchestrations}
                      setSelectedOrchestrations={setSelectedOrchestrations}
                      studyId={studyId}
                      modelPreferencesByModelId={modelPreferencesByModelId}
                      setModelPreferencesByModelId={setModelPreferencesByModelId}
                      setSelectedOrchestrationsByClassificationType={setSelectedOrchestrationsByClassificationType}
                      setIntensityClassificationBinning={setIntensityClassificationBinning}
                    />
                  </Grid>
                );
              })
            : !isEmpty(orchestrationByFlowClassName)
            ? map(orchestrationByFlowClassName, (orchestrations, flowClassName) => {
                return (
                  <Grid item xs={12} key={flowClassName}>
                    <InferenceModel
                      key={flowClassName}
                      stain={stain}
                      modelType={flowClassName}
                      orchestrations={orchestrations}
                      slides={slides}
                      selectedPostprocessedOrchestrations={selectedPostprocessedOrchestrations}
                      setSelectedPostprocessedOrchestrations={setSelectedPostprocessedOrchestrations}
                      studyId={studyId}
                      stainModelInferences={stainModelInferences}
                    />
                  </Grid>
                );
              })
            : null}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default InferenceStain;
