import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { Button, CircularProgress, Grow, Menu, MenuList, Paper, Popper } from '@mui/material';
import { Placement } from '@popperjs/core';
import { find, map } from 'lodash';
import React from 'react';

import { ActionDialog, useActiveActionDialogType } from 'components/ActionDialog';
import { ActionMenuItem } from 'components/StudyDashboard/ProceduresPage/Actions/ActionMenuItem';
import { useRowSelectionContext } from 'components/atoms/RowSelectionContext';
import { ActionMenuItemOption, useActionMenuItemOptions } from './useActionMenuItemOptions';

interface Props {
  totalRows: number;
  currentStains?: string[];
  disablePortal?: boolean;
  placement?: Placement;
}

const ActionsMenu: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  totalRows,
  currentStains,
  disablePortal = true,
  placement = 'bottom-end',
}) => {
  const [currentActionItemType, setCurrentActionItemType] = useActiveActionDialogType();

  const { noneSelected } = useRowSelectionContext();
  const noRows = totalRows === 0;
  const disableRowOperations = noRows || noneSelected(totalRows);

  const {
    actionItems,
    isLoading,
    anchorEl,
    submenuAnchorEl,
    open,
    handleClick,
    handleSubmenuOpen,
    handleSubmenuClose,
    handleMenuClose,
  } = useActionMenuItemOptions({ currentStains });

  const currentActionItem = find(actionItems, ({ type }) => Boolean(currentActionItemType === type));

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    actionItem: ActionMenuItemOption
  ) => {
    setCurrentActionItemType(actionItem.type);
    if (actionItem.subMenuItems) {
      handleSubmenuOpen(event);
      return;
    }

    handleMenuClose();
  };

  return (
    <div>
      <Button
        color="secondary"
        onClick={handleClick}
        endIcon={isLoading ? <CircularProgress color="inherit" size={20} /> : <AddToPhotosIcon />}
        data-cy="actions-menu-button"
      >
        Actions
      </Button>

      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        placement={placement}
        transition
        disablePortal={disablePortal}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'left bottom',
            }}
          >
            <Paper>
              <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button">
                {map(actionItems, (menuItem) => {
                  return (
                    !menuItem.hide &&
                    (menuItem.customMenuItem || (
                      <ActionMenuItem
                        key={menuItem.header}
                        disabled={disableRowOperations || menuItem.disabled}
                        header={menuItem.header}
                        onClick={(event) => {
                          if (menuItem.onClick) {
                            menuItem.onClick(event);
                            return;
                          }
                          handleMenuItemClick(event, menuItem);
                        }}
                      />
                    ))
                  );
                })}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Menu
        anchorEl={submenuAnchorEl}
        open={Boolean(submenuAnchorEl)}
        onClose={handleSubmenuClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            style: {
              maxHeight: 400,
              width: 500,
            },
          },
        }}
      >
        {map(currentActionItem?.subMenuItems, (subMenuItem) => {
          return (
            !subMenuItem.hide &&
            (subMenuItem.customMenuItem || (
              <ActionMenuItem
                key={subMenuItem.header}
                disabled={disableRowOperations || subMenuItem.disabled}
                header={subMenuItem.header}
                onClick={(event) => {
                  if (subMenuItem.onClick) {
                    subMenuItem.onClick(event);
                    return;
                  }
                  handleMenuItemClick(event, subMenuItem);
                }}
              />
            ))
          );
        })}
      </Menu>
      <ActionDialog />
    </div>
  );
};

export default ActionsMenu;
