import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeItem, TreeView } from '@mui/lab';
import { Checkbox, CircularProgress, Grid, Tooltip, Typography } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

import { signal } from '@preact/signals-react';
import { LayerGroupControl } from 'components/Procedure/Infobar/GroupedLayersVisualControls/LayerGroupControl';
import {
  defaultHeatmapOpacity,
  LayerVisualizationSettings,
  slidesLayerVisualizationSettings,
} from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { CalculateFeaturesJob } from 'interfaces/job';

const changeClipHeatmapsWithPolygons = ({
  checked,
  viewerIndex,
  slideId,
  layerId,
}: {
  checked: boolean;
  viewerIndex: number;
  slideId: string;
  layerId: string;
}) => {
  const currentViewerSignal = slidesLayerVisualizationSettings[viewerIndex];
  if (!currentViewerSignal) {
    console.warn('No viewer signal found for viewerIndex', viewerIndex);
    return;
  }
  const currentViewerValues = currentViewerSignal.value;
  let currentLayerSignal = currentViewerValues?.[slideId]?.[layerId];
  if (!currentLayerSignal) {
    currentLayerSignal = signal<LayerVisualizationSettings>({
      opacity: defaultHeatmapOpacity,
      show: false,
      selected: false,
    });
  }
  const currentLayerSettings = currentLayerSignal.value;
  const newSettings = {
    ...(currentLayerSettings || {}),
    additionalProperties: {
      ...currentLayerSettings.additionalProperties,
      clipHeatmapsWithPolygons: checked,
    },
  } as LayerVisualizationSettings;

  currentLayerSignal.value = newSettings;

  currentViewerSignal.value = {
    ...(currentViewerValues || {}),
    [slideId]: {
      ...(currentViewerValues[slideId] || {}),
      [layerId]: currentLayerSignal,
    },
  };
};

export interface SecondaryAnalysisPolygonControlProps {
  viewerIndex: number;
  slideId: string;
  stainTypeId: string;
  secondaryAnalysisJob: CalculateFeaturesJob;
  defaultLayerSettings?: LayerVisualizationSettings;
  isLoading?: boolean;
  showClipControl?: boolean;
}

export const SecondaryAnalysisPolygonControl: React.FC<
  React.PropsWithChildren<SecondaryAnalysisPolygonControlProps>
> = ({
  viewerIndex,
  slideId,
  stainTypeId,
  secondaryAnalysisJob,
  defaultLayerSettings = { opacity: defaultHeatmapOpacity },
  isLoading = false,
  showClipControl,
}) => {
  useSignals();
  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex]?.value;
  const [layersExpanded, setLayersExpanded] = useState<string[]>([]);

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setLayersExpanded(nodeIds);
  };

  const layerId = secondaryAnalysisJob?.id || 'loading';
  const layerSettings = !isLoading ? viewerSlideLayerVisualizationSettings?.[slideId]?.[layerId]?.value : undefined;
  const isPolygonLayerSelected = Boolean(layerSettings?.selected);
  const clipHeatmapsWithPolygons = Boolean(layerSettings?.additionalProperties?.clipHeatmapsWithPolygons);

  // On first render, set the default expanded state based on selected heatmaps
  useEffect(() => {
    if (!isEmpty(layersExpanded)) {
      return;
    } else if (isPolygonLayerSelected) {
      setLayersExpanded([layerId]);
    }
  }, [layerId, layersExpanded]);

  return (
    <TreeView
      expanded={layersExpanded}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      onNodeToggle={handleToggle}
    >
      <TreeItem
        disabled={isLoading}
        nodeId={layerId}
        icon={isLoading ? <CircularProgress size={16} /> : undefined}
        label={
          <LayerGroupControl
            viewerIndex={viewerIndex}
            slideId={slideId}
            displayName="Secondary Analysis Polygons"
            layerIdsToDisplayNames={{ [layerId]: 'Secondary Analysis Polygons' }}
            layerIdsToUrlKeys={{ [layerId]: `${layerId}-polygons` }}
            layers={[layerId]}
            stainTypeId={stainTypeId}
            defaultLayerSettings={defaultLayerSettings}
          />
        }
      >
        {showClipControl && (
          <TreeItem
            disabled={isLoading}
            nodeId={`${layerId}-clip`}
            label={
              <Grid container wrap="nowrap" alignItems="center">
                <Grid item>
                  <Checkbox
                    disabled={isLoading}
                    checked={Boolean(clipHeatmapsWithPolygons)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      changeClipHeatmapsWithPolygons({
                        checked: event.target.checked,
                        viewerIndex,
                        slideId,
                        layerId,
                      })
                    }
                  />
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  md="auto"
                  alignItems="start"
                  sx={{ '&>.MuiGrid-item': { maxWidth: '100%' } }}
                >
                  <Grid item>
                    <Tooltip title={'Clip heatmaps to the secondary analysis annotations.'} placement="top">
                      <Typography
                        variant="caption"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        component="div"
                      >
                        Clip heatmaps
                      </Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            }
          />
        )}
      </TreeItem>
    </TreeView>
  );
};
