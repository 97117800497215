// import { PolygonLayer } from '@deck.gl/layers';
import { forEach } from 'lodash';
import { EditableGeoJsonLayer, SelectionLayer, ViewMode } from 'nebula.gl';
import { FeatureCollection } from './geojson-types';
import { DrawRectangleByDragging } from './RectangleByDraggingMode';

export enum SelectionType {
  NONE = 'null',
  RECTANGLE = 'rectangle',
}

const MODE_MAP: { [key in SelectionType]?: any } = {
  [SelectionType.RECTANGLE]: DrawRectangleByDragging,
};

const EMPTY_DATA = {
  type: 'FeatureCollection',
  features: [] as any[],
};

const LAYER_ID_GEOJSON = 'selection-geojson';

const PASS_THROUGH_PROPS: string[] = [
  'lineWidthScale',
  'lineWidthMinPixels',
  'lineWidthMaxPixels',
  'lineWidthUnits',
  'lineJointRounded',
  'lineCapRounded',
  'lineMiterLimit',
  'pointRadiusScale',
  'pointRadiusMinPixels',
  'pointRadiusMaxPixels',
  'lineDashJustified',
  'getLineColor',
  'getFillColor',
  'getRadius',
  'getLineWidth',
  'getLineDashArray',
  'getTentativeLineDashArray',
  'getTentativeLineColor',
  'getTentativeFillColor',
  'getTentativeLineWidth',
];
export class SelectionOrthographicLayer extends SelectionLayer<any, any> {
  static layerName = 'SelectionOrthographicLayer';

  renderLayers() {
    // @ts-ignore
    const mode = MODE_MAP[this.props.selectionType as SelectionType] || ViewMode;

    const inheritedProps: { [key: string]: any } = {};
    forEach(PASS_THROUGH_PROPS, (p) => {
      // @ts-ignore
      if (this.props[p] !== undefined) inheritedProps[p] = this.props[p];
    });

    const layers = [
      new EditableGeoJsonLayer(
        // @ts-ignore
        this.getSubLayerProps({
          id: LAYER_ID_GEOJSON,
          pickable: true,
          mode,
          modeConfig: {
            dragToDraw: true,
          },
          selectedFeatureIndexes: [],
          data: EMPTY_DATA,
          onEdit: ({
            updatedData,
            editType,
          }: {
            updatedData: FeatureCollection;
            editType: string;
            editContext: any;
          }) => {
            if (editType === 'addFeature') {
              const { coordinates } = updatedData.features[0].geometry;

              // @ts-ignore
              if (this.props.selectionType === SelectionType.RECTANGLE) {
                this._selectRectangleObjects(coordinates);
              }
            }
          },
          ...inheritedProps,
        })
      ),
    ];

    return layers;
  }
}
