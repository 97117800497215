import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  createFilterOptions,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { AutocompleteNumbers } from 'components/atoms/Autocompletes/AutocompleteNumbers';
import AutomaticConditionGroup from 'components/atoms/ConditionBuilder/AutomaticConditionGroup';
import { AutomaticCondition, ConditionType } from 'interfaces/automaticCondition';
import PostProcessingActionInterface, {
  Input,
  InputType,
  MappingFilterMetadata,
  PostProcessingActionCreated,
} from 'interfaces/postProcessingAction';
import { concat, Dictionary, filter, get, groupBy, isEmpty, join, map, set, size, some, sortBy, uniq } from 'lodash';
import React, { ReactElement, useState } from 'react';
import {
  checkOptionExist,
  getConditionValue,
  getIsRequired,
  isFeatureBetweenStains,
  neighborStainsInputKey,
  targetStainsInputKey,
  validateInput,
} from './utils';

interface PostProcessingActionProps {
  id: string;
  index: number;
  action: PostProcessingActionCreated;
  setAction: (action: PostProcessingActionCreated, index: number) => void;
  removeAction: (index: number) => void;
  postProcessingActionsById: Dictionary<PostProcessingActionInterface>;
  getOptions: (
    optionSource: string | string[],
    selectedStains: string[],
    index: number,
    actionInput: string,
    inputSourceDependentOn?: string
  ) => any[];
  getOptionsByValue: (
    optionSource: string | string[],
    selectedStains: string[],
    index: number,
    actionInput: string,
    inputSourceDependentOn?: string
  ) => Dictionary<{ id: string | number; label: string }>;
  getMappingFiltersMetadataForLogicalQuery: (
    stain: string,
    actionInput: string,
    index: number
  ) => MappingFilterMetadata[];
  // for now this is only relevant for vmplex features in neighbor_stains input- therefore we use it only in the multiselect input
  getAdditionalInputError?: (inputValue: any, input: Input, action: PostProcessingActionCreated) => string;
}

export const PostProcessingAction: React.FC<React.PropsWithChildren<PostProcessingActionProps>> = ({
  id,
  index,
  postProcessingActionsById,
  action,
  setAction,
  removeAction,
  getOptions,
  getOptionsByValue,
  getMappingFiltersMetadataForLogicalQuery,
  getAdditionalInputError,
}) => {
  const [expandAccordion, setExpandAccordion] = useState(true);
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };
  const [displayAdvancedInputs, setDisplayAdvancedInputs] = useState(false);

  const parseToFloatInRanges = (value: string, inputRange: { min?: number; max?: number }) => {
    const floatNumber = parseFloat(value);
    const numberBetweenRange =
      floatNumber < inputRange?.min ? inputRange?.min : floatNumber > inputRange?.max ? inputRange?.max : floatNumber;
    return numberBetweenRange;
  };

  const mappingFiltersMetadataForLogicalQuery = getMappingFiltersMetadataForLogicalQuery(
    action?.stain,
    action?.actionInput,
    index
  );

  const inputByType: Record<string, (input: Input, value?: string | string[] | AutomaticCondition) => ReactElement> = {
    [InputType.SELECT]: (input, value: string) => {
      const selectedStainsForInput = getRelevantSelectedStainsForInput(input, action);
      const options = getOptions(
        input.inputSource,
        selectedStainsForInput,
        index,
        action.actionInput,
        get(action, input.inputSourceDependentOn)
      );
      const isError = !validateInput(get(action, input.inputKey), input, action, options);
      const isRequired = getIsRequired(input.required, action);
      const displayCondition = !input.displayCondition || getConditionValue(input.displayCondition, action);

      return (
        displayCondition && (
          <FormControl fullWidth variant="standard" error={isError} className={isError ? 'error' : undefined}>
            <Autocomplete
              size="small"
              options={options}
              value={
                value
                  ? {
                      id: value,
                      label:
                        getOptionsByValue(
                          input.inputSource,
                          selectedStainsForInput,
                          index,
                          action.actionInput,
                          get(action, input.inputSourceDependentOn)
                        )[value]?.label ?? value,
                    }
                  : ''
              }
              isOptionEqualToValue={(option, selectedValue) => option.id === selectedValue.id}
              renderInput={(params) => (
                <TextField
                  error={(isRequired && isEmpty(value)) || isError}
                  required={isRequired}
                  {...params}
                  label={`Select ${input.inputLabel}`}
                  size="medium"
                />
              )}
              filterOptions={(autocompleteOptions, params) => {
                const { inputValue } = params;
                const filtered = createFilterOptions()(autocompleteOptions, params);
                const isExisting = some(autocompleteOptions, (option) => inputValue === option.label);
                if (inputValue !== '' && !isExisting && input.freeSolo) {
                  filtered.push({
                    id: inputValue,
                    label: inputValue,
                    freeSolo: true,
                  });
                }

                return filtered;
              }}
              onChange={(event, selected) => {
                let newAction = { ...action };

                set(newAction, input.inputKey, selected?.id);

                if (input.inputKey === 'action_params.attribute_to_map') {
                  set(newAction, 'newOptionValueInput', selected?.input);
                }

                if (input?.freeSolo) {
                  if (selected?.freeSolo) {
                    set(newAction, 'newOptionValue', selected?.id);
                  } else {
                    set(newAction, 'newOptionValue', null);
                  }
                }

                // check if another inputs is dependent on this input, if so, set it to null
                const dependentInputs = filter(
                  postProcessingActionsById[action.actionId]?.inputs,
                  (inputToFind) => inputToFind.inputSourceDependentOn === input.inputKey
                );
                if (!isEmpty(dependentInputs)) {
                  map(dependentInputs, (dependentInput) => {
                    set(newAction, dependentInput.inputKey, null);
                  });
                }

                setAction(newAction, index);
              }}
              freeSolo={input.freeSolo}
            />
            {!checkOptionExist(get(action, input.inputKey), input, options) && (
              <FormHelperText error id="class-does-not-exist-error-text">
                This option does not exist anymore
              </FormHelperText>
            )}
          </FormControl>
        )
      );
    },
    [InputType.MULTISELECT]: (input, value: string[]) => {
      const selectedStainsForInput = getRelevantSelectedStainsForInput(input, action);

      const options = getOptions(input.inputSource, selectedStainsForInput, index, action.actionInput);
      const isError = !validateInput(get(action, input.inputKey), input, action, options);
      const isRequired = getIsRequired(input.required, action);
      const errorMessage = !checkOptionExist(get(action, input.inputKey), input, options)
        ? 'This option does not exist anymore'
        : (getAdditionalInputError && getAdditionalInputError(get(action, input.inputKey), input, action)) || '';

      return (
        <FormControl fullWidth variant="standard" error={isError} className={isError ? 'error' : undefined}>
          <Autocomplete
            multiple
            getOptionDisabled={input?.limitMultipleTo ? (option) => size(value) >= input.limitMultipleTo : undefined}
            disableCloseOnSelect
            limitTags={2}
            size="small"
            options={options}
            value={
              value
                ? map(value, (val) => {
                    return {
                      id: val,
                      label:
                        getOptionsByValue(input.inputSource, selectedStainsForInput, index, action.actionInput)[val]
                          ?.label ?? val,
                    };
                  })
                : []
            }
            isOptionEqualToValue={(option, selectedValue) => option.id === selectedValue.id}
            renderInput={(params) => (
              <TextField
                error={(isRequired && isEmpty(value)) || isError || Boolean(errorMessage)}
                required={isRequired}
                {...params}
                label={`Select ${input.inputLabel}`}
                size="medium"
              />
            )}
            onChange={(event, selected) => {
              setAction(set(action, input.inputKey, sortBy(map(selected, 'id'))), index);
            }}
          />
          {errorMessage && (
            <FormHelperText error id="class-does-not-exist-error-text">
              {errorMessage}
            </FormHelperText>
          )}
        </FormControl>
      );
    },
    [InputType.NUMBER]: (input, value) => {
      return (
        <TextField
          error={!validateInput(get(action, input.inputKey), input, action)}
          required={getIsRequired(input.required, action)}
          value={value}
          id="outlined-number"
          label={input.inputLabel}
          type="number"
          inputProps={{
            max: input?.inputRange?.max,
            min: input?.inputRange?.min,
          }}
          onChange={(event) => {
            if (event.target.value === '') {
              setAction(set(action, input.inputKey, null), index);
            } else {
              const numberBetweenRange = parseToFloatInRanges(event.target.value, input?.inputRange);
              setAction(set(action, input.inputKey, numberBetweenRange), index);
            }
          }}
        />
      );
    },
    [InputType.RADIO]: (input, value) => {
      const selectedStainsForInput = getRelevantSelectedStainsForInput(input, action);
      const options = getOptions(input.inputSource, selectedStainsForInput, index, action.actionInput);

      return (
        <FormControl>
          <FormLabel id="demo-controlled-radio-buttons-group">{input.inputLabel}</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={(event) => {
              const newAction = { ...action };
              set(newAction, input.inputKey, event.target.value);

              // check if another inputs  is displayCondition on this input, if so, set it to null
              const dependentInputsOnDisplayCondition = filter(
                postProcessingActionsById[action.actionId]?.inputs,
                (inputToFind) => inputToFind.displayCondition?.key === input.inputKey
              );

              if (!isEmpty(dependentInputsOnDisplayCondition)) {
                map(dependentInputsOnDisplayCondition, (dependentInputOnDisplayCondition) => {
                  set(newAction, dependentInputOnDisplayCondition.inputKey, null);
                  if (dependentInputOnDisplayCondition?.freeSolo) {
                    set(newAction, 'newOptionValue', null);
                  }
                });
              }

              setAction(newAction, index);
            }}
          >
            {map(options, (option) => {
              return <FormControlLabel value={option.id} control={<Radio />} label={option.label} />;
            })}
          </RadioGroup>
        </FormControl>
      );
    },
    [InputType.LOGICAL_QUERY]: (input, value: AutomaticCondition) => {
      return (
        <AutomaticConditionGroup
          conditionOptions={[ConditionType.LOGICAL, ConditionType.CELL]}
          editable
          onOperandsChange={(newOperands) => {
            const newValue = isEmpty(newOperands) ? null : newOperands[0];
            setAction(set(action, input.inputKey, newValue), index);
          }}
          operands={value ? [value] : []}
          singleOperand
          mappingFiltersMetadata={mappingFiltersMetadataForLogicalQuery}
        />
      );
    },
    [InputType.NUMBERS]: (input, value: string[]) => {
      const isError = !validateInput(get(action, input.inputKey), input, action);
      const isRequired = getIsRequired(input.required, action);

      return (
        <AutocompleteNumbers
          value={map(value, (val) => Number(val))}
          onChange={(selected) => {
            const newAction = { ...action };
            const numbers = uniq(
              map(selected, (number) => {
                return parseToFloatInRanges(number.toString(), input?.inputRange);
              })
            );
            set(newAction, input.inputKey, isEmpty(numbers) ? null : numbers);
            setAction(newAction, index);
          }}
          label={input.inputLabel}
          isError={isError}
          required={isRequired}
          inputProps={{
            max: input?.inputRange?.max,
            min: input?.inputRange?.min,
          }}
        />
      );
    },
    [InputType.BOOLEAN]: (input, value: string) => {
      return (
        <FormControl fullWidth>
          <InputLabel>{input.inputLabel}</InputLabel>
          <Select
            required={getIsRequired(input.required, action)}
            value={value}
            label={input.inputLabel}
            onChange={(event) => {
              const newAction = { ...action };
              set(newAction, input.inputKey, event.target.value === 'true' ? true : false);
              setAction(newAction, index);
            }}
          >
            <MenuItem value="true">True</MenuItem>
            <MenuItem value="false">False</MenuItem>
          </Select>
        </FormControl>
      );
    },
  };

  const getDisplayValueByOption = (input: Input, value: string) => {
    const selectedStainsForInput = getRelevantSelectedStainsForInput(input, action);
    return (
      getOptionsByValue(
        input.inputSource,
        selectedStainsForInput,
        index,
        action.actionInput,
        get(action, input.inputSourceDependentOn)
      )?.[value]?.label ?? value
    );
  };

  const getDisplayValue = (input: Input, value: string) => {
    if (input.inputType === InputType.LOGICAL_QUERY) {
      if (validateInput(value, input, action, mappingFiltersMetadataForLogicalQuery)) return 'filters';
      else return '';
    }

    if (input.inputType === InputType.NUMBER) {
      return value;
    }

    if (input.inputType === InputType.NUMBERS) {
      return join(value, ', ');
    }

    if (input.inputType === InputType.BOOLEAN) {
      return value ? 'True' : 'False';
    }

    if (input.inputType === InputType.MULTISELECT) {
      return join(
        map(value, (valueOption) => getDisplayValueByOption(input, valueOption)),
        ', '
      );
    }

    if (input.inputType === InputType.SELECT || input.inputType === InputType.RADIO) {
      return getDisplayValueByOption(input, value);
    }

    return value;
  };

  const inputsByAdvanced = groupBy(postProcessingActionsById[action.actionId]?.inputs, 'advanced');
  const advancedInputs = inputsByAdvanced.true;
  const basicInputs = inputsByAdvanced.false;

  const getInputs = (inputs: Input[]) => {
    return map(inputs, (input) => {
      const toDisplay = input.displayCondition ? getConditionValue(input.displayCondition, action) : true;

      return (
        toDisplay && (
          <Grid
            key={`${action.id}-${input.inputKey}`}
            item
            xs={input.inputType === InputType.LOGICAL_QUERY ? 12 : 11 / basicInputs.length}
          >
            {inputByType?.[input.inputType] && inputByType?.[input.inputType](input, get(action, input.inputKey))}
          </Grid>
        )
      );
    });
  };

  return (
    <Grid container spacing={1} style={style} justifyContent="flex-start">
      <Grid
        item
        sx={{
          cursor: 'grab',
        }}
        {...listeners}
        ref={setNodeRef}
        {...attributes}
        textAlign="center"
      >
        <Grid item>
          <DragHandleIcon />
        </Grid>
        <Grid item>
          <Typography variant="caption" color="textSecondary">
            {index + 1}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={11}>
        <Accordion key={action.id} disableGutters expanded={expandAccordion}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => setExpandAccordion((prev) => !prev)}>
            <Grid container spacing={1}>
              <Grid item container xs={expandAccordion ? 10 : 2} alignContent="center" spacing={1}>
                <Grid item>
                  <Typography variant="h4">
                    {postProcessingActionsById[action.actionId]?.actionDisplayName ??
                      postProcessingActionsById[action.actionId]?.actionName}
                  </Typography>
                </Grid>
                <Grid item>
                  {postProcessingActionsById[action.actionId]?.deletedAt && (
                    <Typography variant="h4" color="red">
                      deprecated feature
                    </Typography>
                  )}
                </Grid>
              </Grid>

              {!expandAccordion && (
                <Grid item container xs={9}>
                  {map(postProcessingActionsById[action.actionId]?.inputs, (input) => {
                    const displayCondition = input.displayCondition
                      ? getConditionValue(input.displayCondition, action)
                      : input.advanced
                      ? Boolean(input.defaultValue != get(action, input.inputKey))
                      : true;
                    const selectedStainsForInput = getRelevantSelectedStainsForInput(input, action);
                    return (
                      displayCondition && (
                        <Grid item container direction="column" xs={input.inputLabel.length < 10 ? 1 : 2}>
                          <Grid item>
                            <Typography
                              variant="caption"
                              color={
                                !validateInput(
                                  get(action, input.inputKey),
                                  input,
                                  action,
                                  input.inputType === InputType.LOGICAL_QUERY
                                    ? mappingFiltersMetadataForLogicalQuery
                                    : getOptions(
                                        input.inputSource,
                                        selectedStainsForInput,
                                        index,
                                        action.actionInput,
                                        get(action, input.inputSourceDependentOn)
                                      )
                                )
                                  ? 'red'
                                  : ''
                              }
                            >
                              {input.inputLabel}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="caption">
                              {getDisplayValue(input, get(action, input.inputKey))}
                            </Typography>
                          </Grid>
                        </Grid>
                      )
                    );
                  })}
                </Grid>
              )}
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="column">
              <Grid container spacing={1} alignItems="baseline">
                {getInputs(basicInputs)}
                <Grid item>
                  {!isEmpty(advancedInputs) && (
                    <Tooltip title="Advanced inputs">
                      <IconButton
                        onClick={() => {
                          setDisplayAdvancedInputs((prev) => !prev);
                        }}
                      >
                        {displayAdvancedInputs ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>

              {displayAdvancedInputs && (
                <Grid container spacing={1} alignItems="baseline" pt={1}>
                  {getInputs(advancedInputs)}
                </Grid>
              )}

              {action.newOptionValue && (
                <Grid item container justifyContent="flex-end">
                  <Typography variant="caption" color="textSecondary">
                    Pay attention- when creating a new option you won&apos;t see it in the visualization.
                  </Typography>
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item pt={15}>
        <DeleteOutlineIcon
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => removeAction(index)}
        />
      </Grid>
    </Grid>
  );
};

export const neighborCellClassesInputKey = 'action_inputs.neighbor_cell_classes';
export const targetCellClassesInputKey = 'action_inputs.target_cell_classes';

export const getRelevantSelectedStainsForInput = (input: Input, action: PostProcessingActionCreated) => {
  if (isFeatureBetweenStains(action)) {
    if (input.inputKey === targetCellClassesInputKey) {
      return get(action, targetStainsInputKey, []);
    } else if (input.inputKey === neighborCellClassesInputKey) {
      return get(action, neighborStainsInputKey, []);
    }
  }
  return action?.stain
    ? [action.stain]
    : action.stains
    ? action.stains
    : concat(get(action, targetStainsInputKey, []), get(action, neighborStainsInputKey, []));
};
