import { Grid, useTheme } from '@mui/material';
import { DataGridProps, GridSortModel } from '@mui/x-data-grid';
import { flatMap, map, reduce } from 'lodash';
import React, { useMemo } from 'react';

import { SlideUpdate } from 'api/slides';
import { CaseUpdate } from 'api/study';
import { useTableEditingContext } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { useRowSelectionContext } from 'components/atoms/RowSelectionContext';
import { Procedure } from 'interfaces/procedure';
import { ReviewForm } from 'interfaces/reviewForm';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { CasesDataGrid } from './CasesDataGrid';
import { DataGridFooter, DataGridHeader } from './DataGridHeaderFooter';
import { SlidesDataGrid } from './SlidesDataGrid';
import { useDataGridStyle } from './Style';
import { useEditableFields } from './useEditableFields';
import { DEFAULT_ROW_HEIGHT, useStableTableHeight } from './useStableTableHeight';

const staticCommonDataGridProps: Partial<DataGridProps> = {
  keepNonExistentRowsSelected: true,
  disableRowSelectionOnClick: true,
  disableColumnFilter: true,
  disableColumnSelector: true,
  disableColumnMenu: true,
  rowSelection: false, // handled by RowSelectionContext
  sortingMode: 'server',
  filterMode: 'server',
  getRowHeight: () => DEFAULT_ROW_HEIGHT,
};

export interface DataGridInterfaceWrapperProps {
  casesInPage: Procedure[];
  totalCases: number;
  totalSlides: number;
  isLoading: boolean;
  disableEditing?: boolean;
  hidePagination?: boolean;
  pendingSlidesMode?: boolean;
  forceHeight?: number | string;
  onCaseDataChange?: (caseId: number, changes: Partial<CaseUpdate>) => void;
  onSlideDataChange?: (slideId: string, changes: Partial<SlideUpdate>) => void;
  applyCellValueChangedClass?: (id: string | number, field: string, row: any) => boolean;
  onBulkEditSave?: (changes: { [field: string]: any }) => void;
  forms?: ReviewForm[];
  hideActionsMenu?: boolean;
  onPageChange?: (withOffset: boolean) => (event: React.ChangeEvent<unknown>, nextPage?: number) => void;
  onRowsPerPageChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  forcePage?: number;
  forcePageSize?: number;
  dataGridProps?: Partial<DataGridProps>;
}

export const DataGridInterfaceWrapper: React.FC<React.PropsWithChildren<DataGridInterfaceWrapperProps>> = ({
  totalCases,
  totalSlides,
  hidePagination,
  pendingSlidesMode,
  forceHeight,
  onCaseDataChange: onCaseRowChange,
  onSlideDataChange,
  applyCellValueChangedClass,
  onBulkEditSave,
  forms,
  hideActionsMenu = false,
  onPageChange,
  onRowsPerPageChange,
  forcePage,
  forcePageSize,
  dataGridProps,
  ...props
}) => {
  const currentStains = useMemo(
    () => flatMap(props?.casesInPage, ({ slides }) => map(slides, (slide) => slide.stainingType)),
    [props.casesInPage]
  );
  const { bulkEditMode } = useTableEditingContext();
  const { isLoading, disableEditing } = props;

  const { queryParams, setQueryParams } = useEncodedFilters();
  const onSortModelChange = React.useCallback((sortModel: GridSortModel) => {
    setQueryParams({ sortBy: sortModel });
  }, []);
  const { isRowSelected } = useRowSelectionContext();

  const { selectedFields, orderedFields, selectedTableColumnGroup, setSelectedTableColumnGroup } = useEditableFields();

  const theme = useTheme();

  const height = forceHeight
    ? forceHeight
    : useStableTableHeight({
        isLoading,
        currentNumberOfRows: !isLoading
          ? queryParams?.slidesMode
            ? reduce(props.casesInPage, (acc, { slides }) => acc + slides.length, 0)
            : props.casesInPage
            ? props.casesInPage.length
            : 0
          : undefined,
      });

  const { sx } = useDataGridStyle();

  const commonDataGridProps: Partial<DataGridProps> = React.useMemo(
    () => ({
      ...staticCommonDataGridProps,
      checkboxSelection: true,
      sx,
      getRowClassName: ({ id }) => (isRowSelected(id) ? 'Mui-selected' : ''),
      columnHeaderHeight: bulkEditMode ? 2.5 * DEFAULT_ROW_HEIGHT : DEFAULT_ROW_HEIGHT,
      loading: isLoading,
      sortModel: queryParams.sortBy,
      onSortModelChange,
      hideFooter: hidePagination,
      slots: {
        toolbar: DataGridHeader,
        footer: DataGridFooter,
      },
      slotProps: {
        toolbar: {
          totalSlides,
          totalCases,
          currentStains,
          hidePagination,
          bulkEditMode,
          isLoading,
          disableEditing,
          selectedTableColumnGroup,
          setSelectedTableColumnGroup,
          onBulkEditSave,
          forms,
          hideActionsMenu,
          onPageChange,
          onRowsPerPageChange,
          forcePage,
          forcePageSize,
        },
        footer: {
          totalSlides,
          totalCases,
          onPageChange,
          onRowsPerPageChange,
          forcePage,
          forcePageSize,
        } as {}, // small hack to avoid TS error
      },
    }),
    [
      sx,
      theme,
      totalSlides,
      totalCases,
      hidePagination,
      isLoading,
      queryParams.sortBy,
      disableEditing,
      selectedTableColumnGroup,
      setSelectedTableColumnGroup,
      onBulkEditSave,
      isRowSelected,
      forms,
      hideActionsMenu,
    ]
  );

  return (
    <Grid
      item
      sx={{
        height,
        width: '100%',
        '&.MuiGrid-item': {
          pt: 0,
        },
      }}
    >
      {queryParams?.slidesMode ? (
        <SlidesDataGrid
          {...props}
          selectedFields={selectedFields}
          orderedFields={orderedFields}
          dataGridProps={{ ...commonDataGridProps, ...dataGridProps }}
          totalRows={totalSlides}
          pendingSlidesMode={pendingSlidesMode}
          onCaseRowChange={onCaseRowChange}
          onSlideDataChange={onSlideDataChange}
          applyCellValueChangedClass={applyCellValueChangedClass}
        />
      ) : (
        <CasesDataGrid
          {...props}
          selectedFields={selectedFields}
          orderedFields={orderedFields}
          dataGridProps={{ ...commonDataGridProps, ...dataGridProps }}
          totalRows={totalCases}
          pendingSlidesMode={pendingSlidesMode}
          onCaseRowChange={onCaseRowChange}
        />
      )}
    </Grid>
  );
};
