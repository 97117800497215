import { List, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { cloneDeep, find, first, flatMap, forEach, isNumber, map, size, uniq } from 'lodash';
import React from 'react';

import { Box, darken } from '@mui/system';
import { useSignals } from '@preact/signals-react/runtime';
import deepEqual from 'deep-equal';
import { viewerClickData } from '../../../viewerDataSignals';
import { FeatureCollection } from '../../NebulaGLExtensions/geojson-types';
import useAnnotationsForViewer, { AnnotationItem, UNKNOWN_DIAGNOSIS } from '../useAnnotations';
import { ActionTypes, useAnnotationsStoreWithUndo } from '../useAnnotationsWithUndo';
import ContextMenuIcon from './ContextMenuIcon';

export const DiagnosisSelect: React.FC<{
  slideId: string;
  viewerIndex: number;
  featureIndexes: number[];
  annotationId: number;
}> = ({ featureIndexes, slideId, viewerIndex, annotationId }) => {
  useSignals();

  const { getFilteredAnnotationItemsByFeatureIndex, activeAnnotationData, updateAnnotationSettings } =
    useAnnotationsForViewer({
      slideId,
      viewerIndex,
    });

  const annotationFeatures = activeAnnotationData?.features;
  const uniqTypes = uniq(map(featureIndexes, (featureIndex) => activeAnnotationData?.features[featureIndex]?.type));

  if (size(uniqTypes) > 1) {
    console.warn(
      'Multiple shape types selected, returning null to avoid data mismatch (e.g. a cell feature that gets an area diagnosis)',
      { uniqTypes }
    );
    return null;
  }

  // after checking all selected features are of same shape type, we can take the first one
  const annotationItems = getFilteredAnnotationItemsByFeatureIndex(first(featureIndexes));

  const { updateFeatureCollection } = useAnnotationsStoreWithUndo();

  const onItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: AnnotationItem) => {
    if (viewerClickData[viewerIndex]) {
      viewerClickData[viewerIndex].value = null;
    }

    const indexOutOfRange = find(
      featureIndexes,
      (featureIndex) => featureIndex >= size(annotationFeatures) || featureIndex < 0
    );
    if (isNumber(indexOutOfRange)) {
      console.warn('Feature index out of range', { editedAnnotationData: activeAnnotationData, indexOutOfRange });
      return;
    }

    const editedAnnotationData: FeatureCollection = cloneDeep(activeAnnotationData);
    editedAnnotationData.features = cloneDeep(annotationFeatures) as typeof editedAnnotationData.features;
    forEach(featureIndexes, (featureIndex) => {
      const editedFeature = editedAnnotationData.features[featureIndex];
      editedFeature.properties = {
        ...(editedFeature?.properties || {}),
        annotationId,
        markerType: editedFeature?.properties?.markerType || 'tagger_annotation',
      };
      if (item.isMarkerAnnotation) {
        editedFeature.properties.diagnosis = UNKNOWN_DIAGNOSIS;
        editedFeature.properties.markerPositivity = { [item.name]: item.positive };
      } else {
        editedFeature.properties.diagnosis = item.name;
        editedFeature.properties.markerPositivity = item.markerPositivity;
      }
    });

    if (deepEqual(editedAnnotationData.features, annotationFeatures)) {
      return;
    }

    updateFeatureCollection(editedAnnotationData, {
      type: ActionTypes.UPDATE_CLASS,
      featureType: editedAnnotationData.features[first(featureIndexes)].properties.shapeSubtype,
    });

    updateAnnotationSettings({ todoOption: item });
  };

  return <AnnotationOptionsMenu annotationItems={annotationItems} onClick={onItemClick} />;
};

export const AnnotationOptionsMenu: React.FC<{
  annotationItems: AnnotationItem[];
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: AnnotationItem) => void;
  useSelectedStyle?: boolean;
}> = ({ annotationItems, onClick, useSelectedStyle }) => {
  useSignals();

  const flatItems: AnnotationItem[] = flatMap(annotationItems, (annotationItem) => {
    if (annotationItem.isMarkerAnnotation) {
      return [
        { ...annotationItem, positive: true },
        { ...annotationItem, positive: false },
      ];
    } else {
      return [annotationItem];
    }
  });

  return (
    <Box onWheel={(e) => e.stopPropagation()} sx={{ maxHeight: '300px', overflowY: 'auto' }}>
      <List>
        {map(flatItems, (item: AnnotationItem) => (
          <Tooltip
            title={`${item.displayName} ${item.isMarkerAnnotation ? (item.positive ? ' Positive' : ' Negative') : ''}`}
            key={`${item.name}-${item.color}-${item.positive}`}
            enterDelay={500}
            enterNextDelay={500}
            placement="right"
          >
            <ListItemButton
              selected={useSelectedStyle && item.selected}
              onClick={(event) => onClick && onClick(event, item)}
              tabIndex={0}
            >
              <AnnotationOption item={item} useSelectedStyle={useSelectedStyle} />
            </ListItemButton>
          </Tooltip>
        ))}
      </List>
    </Box>
  );
};

export const AnnotationOption: React.FC<{ item: AnnotationItem; useSelectedStyle?: boolean }> = ({
  item,
  useSelectedStyle,
}) => {
  return (
    <>
      {item.color && (
        <ListItemIcon>
          <ContextMenuIcon
            positive={item.positive}
            displayPosNeg={item?.isMarkerAnnotation}
            // TODO: implement with brushtool
            // hole={isHole(item.optionName)}
            hole={false}
            color={useSelectedStyle && !item.selected ? darken(item.color, 0.2) : item.color}
          />
        </ListItemIcon>
      )}
      {item.icon}
      <Box
        sx={{
          overflow: 'hidden',
          '& .MuiListItemText-primary': { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' },
        }}
      >
        <ListItemText
          sx={{
            color: useSelectedStyle && !item.selected ? 'grey.600' : 'inherit',
          }}
          primary={`${item.displayName} ${item.isMarkerAnnotation ? (item.positive ? ' Positive' : ' Negative') : ''}`}
        />
      </Box>
    </>
  );
};
