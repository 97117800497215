import { TileJSONLayer } from '@loaders.gl/mvt/dist/lib/parse-tilejson';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, Typography } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { compact, every, flatMap, forEach, includes, isEmpty, keyBy, keys, lowerCase, map, uniq } from 'lodash';
import React, { useEffect, useMemo } from 'react';

import { defaultLayerColors } from 'components/theme/theme';
import { useGeoJsonFile } from 'utils/useGeoJsonFileQuery';
import usePrevious from 'utils/usePrevious';
import { GroupedLayersVisualControls } from '../../../GroupedLayersVisualControls';
import {
  LayerVisualizationChange,
  LayerVisualizationSettings,
  slidesLayerVisualizationSettings,
  useApplyChangesToSlideLayerVisualizationSettings,
  useGetLayerSettingsFromUrl,
} from '../../../slidesVisualizationAndConfiguration';

export const computeDefaultGeoJsonTestSettings = (geoJsonUrl: string, layers: string[]) => {
  const layerSettingsList: LayerVisualizationSettings[] = flatMap(layers, (layer, layerIndex) => {
    return [
      {
        id: `${geoJsonUrl}-cells-${layer}`,
        color: defaultLayerColors[+layerIndex % defaultLayerColors.length],
        opacity: 100,
        show: false,
        select: false,
      },
      {
        id: `${geoJsonUrl}-heatmap-${layer}`,
        color: defaultLayerColors[+layerIndex % defaultLayerColors.length],
        opacity: 100,
        show: false,
        select: false,
      },
    ];
  });

  return keyBy(layerSettingsList, 'id');
};

export const SingleGeoJsonTestControl: React.FC<{
  slideId: string;
  viewerIndex: number;
  geoJsonUrl: string;
  stainTypeId: string;
  filterText: string;
}> = ({ slideId, viewerIndex, geoJsonUrl, stainTypeId, filterText }) => {
  useSignals();
  const { data: parsedGeoJson } = useGeoJsonFile(geoJsonUrl);
  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];
  const geoJsonLayers: string[] = useMemo(() => {
    if (!parsedGeoJson) return [];
    return uniq(compact(map(parsedGeoJson.features, 'properties.class_name')));
  }, [JSON.stringify(parsedGeoJson?.features)]);

  const getLayerSettingsFromUrl = useGetLayerSettingsFromUrl();
  const applyChangesToSlideLayerVisualizationSettings = useApplyChangesToSlideLayerVisualizationSettings();

  const previousSlideParams = usePrevious({ slideId, viewerIndex });
  useEffect(() => {
    if (previousSlideParams?.slideId === slideId && previousSlideParams?.viewerIndex === viewerIndex) {
      return;
    }
    if (!viewerSlideLayerVisualizationSettings) {
      console.warn(`Invalid viewerIndex: ${viewerIndex}`);
      return;
    }
    const previousSettings = viewerSlideLayerVisualizationSettings.value;
    const changes: LayerVisualizationChange[] = [];
    if (
      slideId &&
      !isEmpty(geoJsonLayers) &&
      // We don't have any settings for all the layers
      !every(geoJsonLayers, (geoJSONLayer: TileJSONLayer) =>
        includes(keys(previousSettings?.[slideId]), geoJSONLayer.name)
      )
    ) {
      const newGeoJsonSettings = computeDefaultGeoJsonTestSettings(geoJsonUrl, geoJsonLayers);
      forEach(newGeoJsonSettings, (newLayerSettings, layerSettingsKey) => {
        const settingsFromUrl = getLayerSettingsFromUrl({
          layerUrlKey: layerSettingsKey,
          stainTypeId,
          viewerIndex,
        });
        changes.push({
          layerId: layerSettingsKey,
          newSettings: { ...newLayerSettings, ...settingsFromUrl },
        });
      });
    }
    applyChangesToSlideLayerVisualizationSettings({
      slideId,
      viewerIndex,
      changes,
      skipUrlUpdate: true,
      changeFlow: `GeoJsonTestControl-initial-${geoJsonUrl}`,
    });
  }, [viewerIndex, slideId, geoJsonUrl, geoJsonLayers, viewerSlideLayerVisualizationSettings, getLayerSettingsFromUrl]);

  const groupedFilterLayers = useMemo(() => {
    const layerIncludesFilterText = (geoJSONLayer: string) => {
      if (filterText === '') return true;

      return includes(lowerCase(geoJSONLayer), lowerCase(filterText));
    };

    const filteredLayers =
      filterText !== ''
        ? geoJsonLayers?.filter((geoJSONLayer) => {
            if (layerIncludesFilterText(geoJSONLayer)) return geoJSONLayer;
          })
        : geoJsonLayers;
    return {
      [`${geoJsonUrl}-cells`]: filteredLayers,
      [`${geoJsonUrl}-heatmap`]: filteredLayers,
    };
  }, [filterText, geoJsonLayers, geoJsonUrl]);

  return (
    <GroupedLayersVisualControls
      viewerIndex={viewerIndex}
      slideId={slideId}
      groupedLayers={groupedFilterLayers}
      stainTypeId={stainTypeId}
    />
  );
};

export const GeoJsonTestControl: React.FC<{
  geoJsonUrls: string[];
  slideId: string;
  viewerIndex: number;
  stainTypeId: string;
  textSearch: string;
}> = ({ geoJsonUrls, slideId, viewerIndex, stainTypeId, textSearch }) => {
  const [expandAccordion, setExpandAccordion] = React.useState(true);

  return (
    <Accordion square expanded={expandAccordion} onChange={() => setExpandAccordion(!expandAccordion)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item md={12}>
            <Typography variant="h4">GeoJson Tile / Heatmap Tests (Beta)</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 1 }}>
        {map(geoJsonUrls, (geoJsonUrl, urlIdx) => (
          <React.Fragment key={`${urlIdx}-${geoJsonUrl}`}>
            <Grid item>
              <SingleGeoJsonTestControl
                geoJsonUrl={geoJsonUrl}
                slideId={slideId}
                viewerIndex={viewerIndex}
                stainTypeId={stainTypeId}
                filterText={textSearch}
              />
            </Grid>
            <Divider sx={{ borderBottomWidth: 3 }} />
          </React.Fragment>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
