import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { castArray, compact, first, groupBy, isEmpty, map, some, sortBy, uniq } from 'lodash';
import React, { useMemo, useState } from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';

import { useJobs } from 'components/Pages/Jobs/useJobs';
import {
  FeatureMetadata,
  SecondaryAnalysisStatus,
} from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { CalculateFeaturesJob, Job } from 'interfaces/job';
import { usePublishMode } from 'utils/usePublishMode';
import { CellTableControl } from './CellTableControl';
import { useInitialExpandIfHeatmapSelected } from './helpers';
import { ProtomapTree } from './ProtomapTileControl';
import { RasterHeatmapTree } from './RasterHeatmaps';
import { SecondaryAnalysisPolygonControl } from './SecondaryAnalysisPolygonControl';
import { UnpublishResultsButton } from './UnpublishResultsButton';

interface HeatmapsProps {
  viewerIndex: number;
  title: string;
  heatmaps: FeatureMetadata[];
  slideId: string;
  stainTypeId: string;
  studyId: string;
  filterText: string;
  expandByDefault?: boolean;
  internalHeatmaps?: boolean;
  hideOrchestrationId?: boolean;
  secondaryAnalysisJob?: Job;
}

const Heatmaps: React.FunctionComponent<React.PropsWithChildren<HeatmapsProps>> = (props) => {
  useSignals();
  const {
    viewerIndex,
    title,
    heatmaps,
    stainTypeId,
    slideId,
    studyId,
    filterText,
    expandByDefault,
    internalHeatmaps = false,
    hideOrchestrationId,
  } = props;
  const calculateFeaturesOrchestrationId = first(compact(uniq(map(heatmaps, 'orchestrationId'))));
  const isSecondaryAnalysisHeatmap = some(
    heatmaps,
    (heatmap) => heatmap.secondaryAnalysisStatus === SecondaryAnalysisStatus.SecondaryResult
  );
  const { data: calculateFeaturesJobResponse, isLoading: isLoadingCalculateFeaturesJobResponse } =
    useJobs<CalculateFeaturesJob>({
      enabled: Boolean(calculateFeaturesOrchestrationId),
      additionalFilters: { orchestrationIds: castArray(calculateFeaturesOrchestrationId), studyId },
      fullData: true,
      studyId,
      keepPreviousData: false,
    });

  const secondaryAnalysisJob = useMemo(
    () =>
      !isLoadingCalculateFeaturesJobResponse &&
      !isEmpty(first(calculateFeaturesJobResponse?.jobs)?.params?.secondaryAnalysisPolygons)
        ? first(calculateFeaturesJobResponse?.jobs)
        : undefined,
    [calculateFeaturesJobResponse, isLoadingCalculateFeaturesJobResponse]
  );

  const {
    dzi: dziHeatmaps,
    pmt: pmtHeatmaps,
    parquet: parquetHeatmaps,
  } = useMemo(() => groupBy(sortBy(heatmaps, 'displayName'), 'heatmapType'), [heatmaps]);

  const parquetHeatmapsPerKey = useMemo(() => groupBy(parquetHeatmaps, 'key'), [parquetHeatmaps]);

  const [expandAccordion, setExpandAccordion] = useState(Boolean(expandByDefault));

  // On first render, set the default expanded state based on selected heatmaps
  useInitialExpandIfHeatmapSelected({
    expandAccordion,
    setExpandAccordion,
    heatmaps,
    viewerIndex,
    slideId,
  });

  const [isPublishMode] = usePublishMode(viewerIndex);

  const [useOSDViewer] = useQueryParam('useOSDViewer', BooleanParam);

  const showDziHeatmaps = !isEmpty(dziHeatmaps);
  const showPmtHeatmaps = !useOSDViewer && !isEmpty(pmtHeatmaps);
  const showParquetHeatmaps = !useOSDViewer && !isEmpty(parquetHeatmaps);
  return (
    <Accordion square expanded={expandAccordion} onChange={() => setExpandAccordion(!expandAccordion)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item md={isPublishMode && !internalHeatmaps ? 7 : 12}>
            <Typography variant={internalHeatmaps ? 'subtitle2' : 'h4'}>{title}</Typography>
          </Grid>
          <UnpublishResultsButton
            slideId={slideId}
            viewerIndex={viewerIndex}
            studyId={studyId}
            internalHeatmaps={internalHeatmaps}
            heatmaps={heatmaps}
          />
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 1 }}>
        {expandAccordion && (
          <>
            {showDziHeatmaps && (
              <RasterHeatmapTree
                addOrchestrationIdToUrl={internalHeatmaps}
                viewerIndex={viewerIndex}
                slideId={slideId}
                heatmaps={dziHeatmaps}
                stainTypeId={stainTypeId}
                filterText={filterText}
                onEmptyFilter={() => setExpandAccordion(true)}
                hideOrchestrationId={hideOrchestrationId}
              />
            )}
            {showPmtHeatmaps && (
              <ProtomapTree
                addOrchestrationIdToUrl={internalHeatmaps}
                viewerIndex={viewerIndex}
                slideId={slideId}
                pmtHeatmaps={pmtHeatmaps}
                stainTypeId={stainTypeId}
                filterText={filterText}
                onEmptyFilter={() => setExpandAccordion(true)}
                hideOrchestrationId={hideOrchestrationId}
              />
            )}
            {showParquetHeatmaps &&
              map(
                parquetHeatmapsPerKey,
                (parquetHeatmapsGroup, key) =>
                  !isEmpty(parquetHeatmapsGroup) && (
                    <CellTableControl
                      key={key}
                      viewerIndex={viewerIndex}
                      slideId={slideId}
                      heatmaps={parquetHeatmapsGroup}
                      filterText={filterText}
                      hideOrchestrationId={hideOrchestrationId}
                      stainTypeId={stainTypeId}
                    />
                  )
              )}
            {isSecondaryAnalysisHeatmap && (
              <SecondaryAnalysisPolygonControl
                secondaryAnalysisJob={secondaryAnalysisJob}
                slideId={slideId}
                viewerIndex={viewerIndex}
                stainTypeId={stainTypeId}
                isLoading={isLoadingCalculateFeaturesJobResponse}
              />
            )}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default Heatmaps;
