import { GridRowId, GridRowModesModel } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { useQueryClient } from '@tanstack/react-query';
import { omit, reject } from 'lodash';
import React from 'react';

import {
  createCellColorMappingOption,
  deleteCellColorMappingOption,
  updateCellColorMappingOption,
} from 'api/cellColorMappings';
import { useCrudControlsColumns } from 'components/atoms/EditableDataGrid/rowEditingControlsColumns';
import { CellColorMapping } from 'interfaces/cellColorMapping';
import { cellColorMappingFields } from 'interfaces/cellColorMapping/cellColorMappingFields';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { CellColorMappingRowChangesSummary } from './CellColorMappingRowChangesSummary';
import { CellColorMappingDraft } from './types';
import { getCellColorMappingError, getCellColorMappingId } from './utils';

export const useCellColorMappingsColumns = ({
  apiRef,
  noRows,
  cellColorMappings,
  draftCellColorMappings,
  rowModesModel,
  setDraftCellColorMappings,
  setRowModesModel,
}: {
  noRows?: boolean;
  apiRef: React.MutableRefObject<GridApiCommunity>;
  cellColorMappings: Array<CellColorMapping | CellColorMappingDraft>;
  draftCellColorMappings: CellColorMappingDraft[];
  rowModesModel: GridRowModesModel;
  setDraftCellColorMappings: React.Dispatch<React.SetStateAction<Array<CellColorMappingDraft>>>;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
}) => {
  const [mutatingRowId, setMutatingRowId] = React.useState<GridRowId | undefined>();
  const commonMutationOptions = React.useMemo(() => ({ onError: () => setMutatingRowId(undefined) }), []);

  const queryClient = useQueryClient();

  const createCellColorMappingMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess: (newCellColorMapping) => {
      queryClient.invalidateQueries(['cellColorMappings']);
      queryClient.setQueryData(['cellColorMappings'], (oldData: CellColorMapping[]) => [
        ...oldData,
        newCellColorMapping,
      ]);
    },
    mutationFn: (cellColorMapping: CellColorMapping) => createCellColorMappingOption(omit(cellColorMapping, 'id')),
    mutationDescription: 'create cell color mapping',
  });

  const updateCellColorMappingMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['cellColorMappings']);
      queryClient.setQueryData(['cellColorMappings'], (oldData: CellColorMapping[]) => {
        return [...reject(oldData, { id: mutatingRowId }), variables];
      });
    },
    mutationFn: (cellColorMapping: CellColorMapping) => updateCellColorMappingOption(cellColorMapping),
    mutationDescription: 'update cell color mapping',
  });

  const deleteCellColorMappingMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess(data, variables) {
      queryClient.invalidateQueries(['cellColorMappings']);
      queryClient.setQueryData(['cellColorMappings'], (oldData: CellColorMapping[]) =>
        reject(oldData, { id: variables })
      );
    },
    mutationFn: deleteCellColorMappingOption,
    mutationDescription: 'delete cell color mapping',
  });

  const columns = useCrudControlsColumns<CellColorMapping, CellColorMappingDraft>({
    createMutation: createCellColorMappingMutation,
    deleteMutation: deleteCellColorMappingMutation,
    updateMutation: updateCellColorMappingMutation,
    apiRef,
    rowModesModel,
    setRowModesModel,
    setDraftRows: setDraftCellColorMappings,
    draftRows: draftCellColorMappings,
    currentRows: cellColorMappings,
    rowTypeFields: cellColorMappingFields,
    getRowError: getCellColorMappingError,
    noRows,
    getCancelEditConfirmationModalOptions: ({ newRowValue, isDraft, changes }) => ({
      title: `Cancel cell color ${isDraft ? 'mapping' : 'update'}`,
      text: <CellColorMappingRowChangesSummary fullData={newRowValue} changes={changes} />,
    }),
    getSaveConfirmationModalOptions: ({ newRowValue, isDraft, changes }) => ({
      title: `${isDraft ? 'Create' : 'Update'} cell color mapping`,
      text: <CellColorMappingRowChangesSummary fullData={newRowValue} changes={changes} />,
    }),
    getDeleteConfirmationModalOptions: () => ({
      title: 'Delete Cell Color Mapping',
      text: 'Are you sure you want to delete this cell color mapping?',
    }),
    idGetter: getCellColorMappingId,
    draftIdField: 'draftId',
    mutatingRowId,
    setMutatingRowId,
  });
  return columns;
};
