import { Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { find, map } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { getChannelMarkers } from 'api/markerTypes';
import LabelledDropdown from 'components/atoms/Dropdown/LabelledDropdown';
import { modelTypeCellSegmentation } from 'components/Pages/Jobs/inferenceFieldsOptions';
import { ModelType } from 'interfaces/modelType';
import { MULTIPLEX_STAIN_ID } from 'interfaces/stainType';
import { humanize } from 'utils/helpers';
import { useModelTypeOptions } from 'utils/queryHooks/useModelTypeOptions';
import { useCasesParams } from 'utils/useCasesParams';
import { encodeQueryParamsUsingSchema } from 'utils/useEncodedFilters';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import { SelectModelStep } from '../../SelectModelStep';
import {
  IFormValues,
  MultiplexCellSegmentationModelTypes,
  multiplexCellSegmentationModelTypesOptions,
  RunMultiplexCellSegmentationSteps,
} from './types';

export interface ConfigurationStepProps {
  methods: UseFormReturn<IFormValues, any>;
  checkValidationAndSetIsStepFailed: (step: RunMultiplexCellSegmentationSteps, values: IFormValues) => void;
}

export const ConfigurationStep: React.FC<ConfigurationStepProps> = ({ methods, checkValidationAndSetIsStepFailed }) => {
  const { stainTypeIdToDisplayName, isLoadingStainTypeOptions } = useStainTypeIdToDisplayName();
  const { casesParams, schema, options } = useCasesParams();

  const getEncodedParams = () => encodeQueryParamsUsingSchema(casesParams, schema, options);

  const { isLoading: isLoadingDefaultModel } = useModelTypeOptions({ retry: false });
  const { data: channelMarkersResponse, isLoading: isLoadingChannelMarkers } = useQuery({
    queryKey: ['multiplex_marker_types', getEncodedParams()],
    queryFn: ({ signal }) => getChannelMarkers(getEncodedParams(), signal),
  });

  const channelMarkers = channelMarkersResponse?.channelMarkerTypes;

  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = methods;

  const checkStep = useCallback(
    (updates: Partial<IFormValues>) => {
      checkValidationAndSetIsStepFailed(RunMultiplexCellSegmentationSteps.Configuration, {
        ...getValues(),
        ...updates,
      });
    },
    [methods, checkValidationAndSetIsStepFailed]
  );

  const { data: modelTypeOptions } = useModelTypeOptions({ retry: false });
  const defaultSegmentationModelUrl = find(modelTypeOptions, {
    type: modelTypeCellSegmentation.apiModelValue,
    stainingMethod: MULTIPLEX_STAIN_ID,
  } as Partial<ModelType>)?.defaultModelUrl;

  const onSelectModelUrl = (modelUrl: string) => {
    setValue('modelPath', modelUrl, { shouldValidate: true });
    checkStep({
      modelType: MultiplexCellSegmentationModelTypes.amikta,
      nuclearChannelNames: watch('nuclearChannelNames'),
      membranalChannelNames: watch('membranalChannelNames'),
      modelPath: modelUrl,
    });
  };

  const segmentationModelType = watch('modelType');
  useEffect(() => {
    // Set default model url if model type is amikta
    if (segmentationModelType === MultiplexCellSegmentationModelTypes.amikta) {
      onSelectModelUrl(defaultSegmentationModelUrl);
    }
  }, [segmentationModelType, defaultSegmentationModelUrl]);

  const channelMarkersOptions = map(channelMarkers, (channelMarker) => ({
    text: stainTypeIdToDisplayName(channelMarker) ?? channelMarker,
    value: channelMarker,
  }));

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Controller
          control={control}
          name="modelType"
          render={({ field: { onChange, value } }) => (
            <LabelledDropdown
              required
              label="model Type"
              options={multiplexCellSegmentationModelTypesOptions}
              value={value ?? []}
              onOptionSelected={(optionValue) => {
                onChange(optionValue);
                if (MultiplexCellSegmentationModelTypes.amikta !== optionValue) {
                  setValue('modelPath', null);
                  checkStep({
                    modelType: optionValue,
                    nuclearChannelNames: watch('nuclearChannelNames'),
                    membranalChannelNames: watch('membranalChannelNames'),
                    modelPath: null,
                  });
                } else {
                  checkStep({
                    modelType: optionValue,
                    nuclearChannelNames: watch('nuclearChannelNames'),
                    membranalChannelNames: watch('membranalChannelNames'),
                    modelPath: watch('modelPath'),
                  });
                }
              }}
              error={Boolean(errors['modelType'])}
              helperText={humanize(errors['modelType']?.message)}
              loading={MultiplexCellSegmentationModelTypes.amikta === segmentationModelType && isLoadingDefaultModel}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Controller
          control={control}
          name="nuclearChannelNames"
          render={({ field: { onChange, value } }) => (
            <LabelledDropdown
              required
              multiple
              label="Nuclear Channel Names"
              options={channelMarkersOptions}
              value={value || []}
              onOptionSelected={(optionValue: string[]) => {
                onChange(optionValue);
                checkStep({
                  modelType: watch('modelType'),
                  nuclearChannelNames: optionValue,
                  membranalChannelNames: watch('membranalChannelNames'),
                });
              }}
              loading={isLoadingChannelMarkers || isLoadingStainTypeOptions}
              error={Boolean(errors['nuclearChannelNames'])}
              helperText={humanize(errors['nuclearChannelNames']?.message)}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Controller
          control={control}
          name="membranalChannelNames"
          render={({ field: { onChange, value } }) => (
            <LabelledDropdown
              multiple
              label="Membranal channels"
              options={channelMarkersOptions}
              value={value || []}
              onOptionSelected={(optionValue) => {
                onChange(optionValue);
                checkStep({
                  modelType: watch('modelType'),
                  nuclearChannelNames: watch('nuclearChannelNames'),
                  membranalChannelNames: optionValue,
                });
              }}
              loading={isLoadingChannelMarkers}
              error={Boolean(errors['membranalChannelNames'])}
              helperText={humanize(errors['membranalChannelNames']?.message)}
            />
          )}
        />
      </Grid>
      {MultiplexCellSegmentationModelTypes.amikta === segmentationModelType && (
        <SelectModelStep<IFormValues, 'modelPath'>
          casesParams={casesParams}
          formMethods={methods}
          modelUrlField="modelPath"
          onSelectModelUrl={(modelPath) => {
            checkStep({
              modelType: watch('modelType'),
              nuclearChannelNames: watch('nuclearChannelNames'),
              membranalChannelNames: watch('membranalChannelNames'),
              modelPath,
            });
          }}
          modelType={modelTypeCellSegmentation.apiModelValue}
          slideStainType={MULTIPLEX_STAIN_ID}
        />
      )}
    </Grid>
  );
};
