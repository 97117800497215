import { ClinicalData } from 'interfaces/clinical';
import {
  ArtifactType,
  ExperimentResult,
  ExperimentResultUpdate,
  FlowClassName,
  ResultsExistence,
  ResultsMode,
} from 'interfaces/experimentResults';
import { stringify } from 'qs';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export type SlideResult = {
  slideId: string;
  caseId: number;
  caseName: string;
  orchestrationId: string;
  flowClassName: FlowClassName;
  createdAt?: string;
  approved?: boolean;
  internallyApproved?: boolean;
};

export type Orchestration = {
  orchestrationId: string;
  slidesData: SlideResult[];
  createdAtMinimum?: string;
};

export type OrchestrationResponse = {
  orchestrations: Orchestration[];
};

export const getCasesOrchestrations = (data: {
  features?: any;
  filters?: any;
  clinicalData?: ClinicalData;
  labId?: string;
  caseIdsToInclude?: number[];
  caseIdsToExclude?: number[];
  shouldOnlyGetByStudyIdAndResultsMode?: boolean;
  resultsMode?: ResultsMode;
}) => {
  const queryObject: Record<string, any> = {
    features: JSON.stringify(data.features),
    filters: JSON.stringify(data.filters),
    clinicalData: JSON.stringify(data.clinicalData),
    labId: data.labId,
    shouldOnlyGetByStudyIdAndResultsMode: data.shouldOnlyGetByStudyIdAndResultsMode,
    resultsMode: data.resultsMode,
  };

  if ('caseIdsToInclude' in data) {
    queryObject['caseIdsToInclude'] = data.caseIdsToInclude;
  } else if ('caseIdsToExclude' in data) {
    queryObject['caseIdsToExclude'] = data.caseIdsToExclude;
  }

  return apiRequestHandlerPromise<OrchestrationResponse>({
    url: `orchestrations?${stringify(queryObject, {
      arrayFormat: 'repeat',
    })}`,
    method: 'GET',
  });
};

export const getSlidesOrchestrations = (data: {
  features?: any;
  filters?: any;
  clinicalData?: ClinicalData;
  labId?: string;
  slideIdsToInclude?: string[];
  slideIdsToExclude?: string[];
  slidesMode?: boolean;
  resultsMode?: ResultsMode;
}) => {
  return apiRequestHandlerPromise<OrchestrationResponse>({
    url: `orchestrations?${stringify(
      {
        features: JSON.stringify(data.features),
        filters: JSON.stringify(data.filters),
        clinicalData: JSON.stringify(data.clinicalData),
        labId: data.labId,
        slideIdsToInclude: data.slideIdsToInclude,
        slideIdsToExclude: data.slideIdsToExclude,
        slidesMode: data.slidesMode,
        resultsMode: data.resultsMode,
      },
      {
        arrayFormat: 'repeat',
      }
    )}`,
    method: 'GET',
  });
};

export const bulkUpdateResultsByOrchestration = ({
  studyId,
  labId,
  orchestrationId,
  ...data
}: {
  orchestrationId: string;
  flowClassNames?: string[];
  resultTypes?: string[];
  slideIds: string[];
  studyId: string;
  labId: string;
  updatedData: ExperimentResultUpdate;
}) => {
  return apiRequestHandlerPromise<OrchestrationResponse>({
    url: `studies/${studyId}/results/orchestrations/${orchestrationId}?${stringify({ labId })}`,
    method: 'PATCH',
    data: JSON.stringify(data),
  });
};

export const bulkUpdateResultsByExperimentResultIds = ({
  studyId,
  labId,
  ...data
}: {
  experimentResultIds?: number[];
  studyId: string;
  labId: string;
  updatedData: ExperimentResultUpdate;
}) => {
  return apiRequestHandlerPromise<OrchestrationResponse>({
    url: `studies/${studyId}/results/by_ids?${stringify({ labId })}`,
    method: 'PATCH',
    data: JSON.stringify(data),
  });
};

export const getResultsExistence = (stringParams: string, studyId: string, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<ResultsExistence>({
    url: `studies/${studyId}/results-existence-by-flow-class-name?${stringParams}`,
    method: 'GET',
    signal,
  });
};

export const getPreSignedPresentationInfo = (studyId: string, experimentResultId: number, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<{ [experimentResultId: number]: ExperimentResult['presentationInfo'] }>({
    url: `studies/${studyId}/results/presentation_info/${experimentResultId}`,
    method: 'GET',
    signal,
  });
};

export const archiveExperimentResult = ({
  studyId,
  experimentResultIds,
  labId,
}: {
  studyId: string;
  experimentResultIds: number[];
  labId: string;
}) => {
  return apiRequestHandlerPromise<ExperimentResult>({
    url: `studies/${studyId}/results/by_ids/archive?${stringify({ labId })}`,
    method: 'POST',
    data: JSON.stringify({ experimentResultIds }),
  });
};

export const restoreExperimentResult = ({
  studyId,
  experimentResultIds,
  labId,
}: {
  studyId: string;
  experimentResultIds: number[];
  labId: string;
}) => {
  return apiRequestHandlerPromise<ExperimentResult>({
    url: `studies/${studyId}/results/by_ids/restore?${stringify({ labId })}`,
    method: 'POST',
    data: JSON.stringify({ experimentResultIds }),
  });
};

export const getArtifactUrlQueryKey = ({
  studyId,
  orchestrationId,
  slideId,
  labId,
  artifactType,
}: {
  studyId: string;
  orchestrationId: string;
  slideId: string;
  labId: string;
  artifactType: ArtifactType;
}) => {
  return ['artifactUrl', { studyId, orchestrationId, slideId, labId, artifactType }];
};

export const getArtifactUrl = (
  {
    studyId,
    orchestrationId,
    slideId,
    labId,
    artifactType,
  }: {
    studyId: string;
    orchestrationId: string;
    slideId: string;
    labId: string;
    artifactType: ArtifactType;
  },
  signal: AbortSignal
) => {
  return apiRequestHandlerPromise<string>({
    url: `studies/${studyId}/results/slide/${slideId}/artifact_url?${stringify({
      labId,
      orchestrationId,
      artifactType,
    })}`,
    method: 'GET',
    signal,
  });
};

export const assignGenericClusters = ({
  studyId,
  slideIds,
  orchestrationId,
  clusterAssignmentMap,
}: {
  studyId: string;
  slideIds: string[];
  orchestrationId: string;
  clusterAssignmentMap: Record<number, string>;
}) => {
  return apiRequestHandlerPromise({
    url: `studies/${studyId}/results/orchestrations/${orchestrationId}/assign_generic_clusters`,
    method: 'POST',
    data: JSON.stringify({ clusterAssignmentMap, slideIds }),
  });
};
