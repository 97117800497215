import { Autocomplete, FormControl, FormHelperText, Grid, TextField } from '@mui/material';
import { filter, find, includes, map, reduce } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useStainTypeOptions } from 'utils/queryHooks/uiConstantsHooks';
import { MultiplexChannelIdName } from './MultiplexChannelIdName';

export const ChannelMarkerTypeChoice: React.FC<
  React.PropsWithChildren<{
    channelId: string;
    channelName: string;
    markerType: string;
    previousMarkerAssignment?: string;
    assignedMarkers: string[];
    handleChannelMarkerTypeChange: (channel: string, value: string) => void;
  }>
> = ({
  previousMarkerAssignment,
  channelId,
  channelName,
  markerType,
  assignedMarkers,
  handleChannelMarkerTypeChange,
}) => {
  const [markerTypeSearch, setMarkerTypeSearch] = useState('');
  const missingMarker = !markerType;
  const isDuplicate = reduce(assignedMarkers, (count, curr) => count + (curr === markerType ? 1 : 0), 0) !== 1;

  const { mifMarkerTypeOptionsWithoutDeprecated, stainTypeOptions, isLoadingStainTypeOptions } = useStainTypeOptions();
  const orderedMarkerTypes = useMemo(() => {
    const markerTypes = mifMarkerTypeOptionsWithoutDeprecated;
    return [
      ...filter(markerTypes, (option) => !includes(assignedMarkers, option.id)),
      ...filter(markerTypes, (option) => includes(assignedMarkers, option.id)),
    ];
  }, [assignedMarkers, mifMarkerTypeOptionsWithoutDeprecated]);

  return (
    <Grid item container direction="column" md={8} alignItems="start">
      <FormControl
        fullWidth
        variant="standard"
        error={missingMarker || isDuplicate}
        className={missingMarker || isDuplicate ? 'error' : undefined}
      >
        <Autocomplete<string, false, false, false>
          size="small"
          id="marker-select-label"
          value={markerType}
          loading={isLoadingStainTypeOptions}
          options={map(orderedMarkerTypes, 'id')}
          getOptionLabel={(option) => find(stainTypeOptions, { id: option })?.displayName || option}
          groupBy={(option) => (includes(assignedMarkers, option) ? 'Assigned' : '')}
          inputValue={markerTypeSearch}
          onInputChange={(_event, newMarkerTypeSearch) => setMarkerTypeSearch(newMarkerTypeSearch)}
          onChange={(_event, markerTypeOption) => handleChannelMarkerTypeChange(channelId, markerTypeOption)}
          clearText={markerType}
          clearIcon={false}
          renderInput={(params) => <TextField {...params} error={missingMarker || isDuplicate} variant="standard" />}
        />
        {missingMarker ? (
          <FormHelperText error id="marker-error-text">
            Missing Marker
            {previousMarkerAssignment
              ? ` (Previously Assigned to: ${
                  find(stainTypeOptions, {
                    id: previousMarkerAssignment,
                  })?.displayName || previousMarkerAssignment
                }})`
              : ''}
          </FormHelperText>
        ) : isDuplicate ? (
          <FormHelperText error id="marker-error-text">
            Duplicate Marker
          </FormHelperText>
        ) : null}
      </FormControl>
      <Grid item container justifyContent="start" md={4}>
        <MultiplexChannelIdName channelId={channelId} channelName={channelName} />
      </Grid>
    </Grid>
  );
};
