import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Fade, Grid, Popper, ToggleButton } from '@mui/material';
import { flatMap, map } from 'lodash';
import React, { useEffect } from 'react';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';

import { Permission } from 'interfaces/permissionOption';
import { useAnnotationQueryParams } from 'services/annotations/useAnnotationQueryParams';
import { usePermissions } from 'utils/usePermissions';
import usePrevious from 'utils/usePrevious';
import { InteractionMenusProps } from '..';
import { useSlideAnnotationKeyboardEvents } from './hooks';
import { SlideAnnotationEditType, slideAnnotationOptions } from './options';
import { SlideInteractionOption } from './SlideInteractionOption';

export const SlideAnnotationTools: React.FunctionComponent<InteractionMenusProps> = ({
  hasActiveNonAnnotationInteractions,
  clearNonAnnotationInteractions,
}) => {
  const [useOSDViewer] = useQueryParam('useOSDViewer', BooleanParam);
  const { annotationsActive } = useAnnotationQueryParams();
  const previousAnnotationsActive = usePrevious(annotationsActive);
  const [editAnnotationsMode, setEditAnnotationMode] = useQueryParam('editAnnotationsMode', StringParam);
  const [, setRoiSelectedSize] = useQueryParam('roiSelectedSize', StringParam);

  const [anchorEl, setAnchorEl] = React.useState<null | Element>(null);

  const [isDrawingToolSelectionOpenState, setIsDrawingToolSelectionOpen] = React.useState(annotationsActive);
  const isDrawingToolSelectionOpen = annotationsActive && Boolean(anchorEl) && isDrawingToolSelectionOpenState;

  useEffect(() => {
    if (!previousAnnotationsActive && annotationsActive) {
      // Open/Close the drawing tool selection if the drawing modes are active/inactive
      setIsDrawingToolSelectionOpen(annotationsActive);
    }
  }, [annotationsActive]);

  const { hasPermission } = usePermissions();
  const canAnnotateSlides = hasPermission(Permission.AnnotateSlides);
  const hasPermissionsToDrawingTools = canAnnotateSlides;

  useSlideAnnotationKeyboardEvents();

  return (
    !useOSDViewer &&
    hasPermissionsToDrawingTools &&
    annotationsActive && (
      <Grid item>
        <ToggleButton
          ref={setAnchorEl}
          value="slideActions"
          onChange={() => setIsDrawingToolSelectionOpen(!isDrawingToolSelectionOpen)}
          title={isDrawingToolSelectionOpen ? 'Open slide annotation tools' : 'Hide'}
          disabled={Boolean(hasActiveNonAnnotationInteractions)}
        >
          {isDrawingToolSelectionOpen ? <ArrowForwardIosIcon /> : <EditIcon />}
        </ToggleButton>
        <Popper anchorEl={anchorEl} open={isDrawingToolSelectionOpen} placement="left" transition disablePortal>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Box sx={{ bgcolor: 'background.paper' }}>
                <Grid item container direction="row" p={0} m={0}>
                  {flatMap(slideAnnotationOptions, ({ editType, title, icon: OptionIcon, color, sx, subOptions }) => (
                    <SlideInteractionOption
                      key={editType}
                      value={editType}
                      title={title}
                      selected={editAnnotationsMode === editType}
                      onClick={() => {
                        clearNonAnnotationInteractions();
                        if (editType === editAnnotationsMode) {
                          setEditAnnotationMode(null);
                        } else {
                          setEditAnnotationMode(editType);
                        }
                      }}
                      icon={
                        <OptionIcon
                          color={color ? color : editAnnotationsMode === editType ? 'primary' : 'inherit'}
                          sx={sx}
                        />
                      }
                      subOptions={
                        subOptions
                          ? map(subOptions, (subOption) => ({
                              ...subOption,
                              onClick: (event, subEditType) => {
                                clearNonAnnotationInteractions();
                                setEditAnnotationMode(editType);
                                if (editType === SlideAnnotationEditType.Roi) {
                                  setRoiSelectedSize(subEditType);
                                }
                              },
                            }))
                          : undefined
                      }
                    />
                  ))}
                </Grid>
              </Box>
            </Fade>
          )}
        </Popper>
      </Grid>
    )
  );
};
