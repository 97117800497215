import { GridRenderEditCellParams } from '@mui/x-data-grid';
import React from 'react';
import { ColorPicker } from '../ColorPicker';

// Define your custom cell editor component
export const ColorEditInputCell = (props: GridRenderEditCellParams) => {
  const { id, field, api } = props;

  const currentColor = api.getRowWithUpdatedValues(id, field)?.[field];

  return (
    <ColorPicker
      currentColor={currentColor}
      onColorChange={(newColor) => api.setEditCellValue({ id, field, value: newColor })}
      buttonSx={{ mt: 'auto', mb: 'auto', mr: 'auto', mx: 'auto' }}
    />
  );
};

export default ColorEditInputCell;
