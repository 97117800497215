import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/system';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './../utils/wdyr';

import 'regenerator-runtime/runtime';
import supportedBrowsers from '../../static/supportedBrowsers';
import App from './AppContainer';
import BrowserNotSupported from './BrowserNotSupported';
import { ColorModeContextProvider } from './ColorModeContext';
import Root from './Root';

import 'katex/dist/katex.min.css';
import 'react-chart-editor/lib/react-chart-editor.css';

declare const SENTRY_ENABLED: boolean;
declare const SENTRY_ENVIRONMENT: string;

if (SENTRY_ENABLED) {
  Sentry.init({
    dsn: 'https://45d462dcc94644bab369d01ef984cc31@o158663.ingest.sentry.io/1417983',
    attachStacktrace: true,
    integrations: [
      new Integrations.CaptureConsole({
        levels: ['error'],
      }),
    ],
    environment: SENTRY_ENVIRONMENT,
  });
}
const isBrowserSupported = () => supportedBrowsers.test(navigator.userAgent);

const StyledMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: theme.palette.grey[800],
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: theme.palette.grey[800],
  },
  '&.notistack-MuiContent-default': {
    backgroundColor: theme.palette.grey[800],
  },
}));

const Index: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => (
  <ColorModeContextProvider>
    <SnackbarProvider
      autoHideDuration={5000}
      maxSnack={4}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        default: StyledMaterialDesignContent,
      }}
    >
      <Root>
        {isBrowserSupported() ? (
          <>
            <CssBaseline />
            <App />
          </>
        ) : (
          <BrowserNotSupported />
        )}
      </Root>
    </SnackbarProvider>
  </ColorModeContextProvider>
);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<Index />);
