import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import BackHandOutlinedIcon from '@mui/icons-material/BackHandOutlined';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FormatShapesOutlinedIcon from '@mui/icons-material/FormatShapesOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PentagonOutlinedIcon from '@mui/icons-material/PentagonOutlined';
import UndoIcon from '@mui/icons-material/Undo';
import { ShapeSubTypes } from 'components/Procedure/Header/SlideInteractionMenu/SlideAnnotationTools/options';
import deepEqual from 'deep-equal';
import React from 'react';
import { temporal } from 'zundo';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { FeatureCollection } from '../NebulaGLExtensions/geojson-types';

export enum ActionTypes {
  ADD_FEATURE = 'ADD_FEATURE',
  ADD_POLYGON_POINT = 'ADD_POLYGON_POINT',
  MOVE_FEATURE = 'MOVE_FEATURE',
  MODIFY_FEATURE = 'MODIFY_FEATURE',
  UPDATE_CLASS = 'UPDATE_CLASS',
  DELETE_FEATURE = 'DELETE_FEATURE',
  RETURN_TO_LAST_SAVED_ANNOTATIONS = 'RETURN_TO_LAST_SAVED_ANNOTATIONS',
}

interface AnnotationsAction {
  type: ActionTypes;
  featureType?: ShapeSubTypes;
  payload?: any;
}

export const getIconForAction = (action: AnnotationsAction): React.ReactElement => {
  switch (action.type) {
    case ActionTypes.ADD_FEATURE:
      if (action.featureType === ShapeSubTypes.Point) {
        return <AddCircleOutlineOutlinedIcon fontSize="small" />;
      }
      if (action.featureType === ShapeSubTypes.Rect) {
        return <AddBoxOutlinedIcon fontSize="small" />;
      }
      return <PentagonOutlinedIcon fontSize="small" />;

    case ActionTypes.ADD_POLYGON_POINT:
      return <ControlPointIcon fontSize="small" />;

    case ActionTypes.UPDATE_CLASS:
      return <EditOutlinedIcon fontSize="small" />;

    case ActionTypes.DELETE_FEATURE:
      return <DeleteForeverOutlinedIcon fontSize="small" />;

    case ActionTypes.RETURN_TO_LAST_SAVED_ANNOTATIONS:
      return <UndoIcon fontSize="small" />;

    case ActionTypes.MOVE_FEATURE:
      return <BackHandOutlinedIcon fontSize="small" />;

    case ActionTypes.MODIFY_FEATURE:
      return <FormatShapesOutlinedIcon fontSize="small" />;

    default:
      return <HelpOutlineIcon fontSize="small" />;
  }
};

export const getActionDisplayName = (action: AnnotationsAction): string => {
  switch (action.type) {
    case ActionTypes.ADD_FEATURE:
      return 'Add feature';

    case ActionTypes.ADD_POLYGON_POINT:
      return 'Add polygon point';

    case ActionTypes.UPDATE_CLASS:
      return 'Update class';

    case ActionTypes.DELETE_FEATURE:
      return 'Delete feature';

    case ActionTypes.RETURN_TO_LAST_SAVED_ANNOTATIONS:
      return 'Cancel unsaved changes';

    case ActionTypes.MOVE_FEATURE:
      return 'Move feature';

    case ActionTypes.MODIFY_FEATURE:
      return 'Modify feature';

    default:
      return 'Unknown action';
  }
};

interface AnnotationsState {
  action?: AnnotationsAction;
  featureCollection: FeatureCollection | null;
  updateFeatureCollection: (newFeatureCollection: FeatureCollection, action: AnnotationsAction) => void;
}

// This store is used to manage the annotations state with undo functionality
// It stores only the states (steps) that we can go back to with undo
// The tentative state of the features are saved in the signal (e.g. while drawing new polygon, or while modifying a point before picking up the mouse)
// The supporting actions are the above ActionTypes
export const useAnnotationsStoreWithUndo = create<AnnotationsState>()(
  subscribeWithSelector(
    temporal(
      (set) => ({
        featureCollection: null,
        action: null,
        updateFeatureCollection: (newFeatureCollection: FeatureCollection | null, action: AnnotationsAction | null) => {
          set((state) => ({ featureCollection: newFeatureCollection, action: action }));
        },
      }),
      {
        equality: deepEqual,
      }
    )
  )
);
