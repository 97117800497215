import { DefaultProps, Layer, LayersList } from '@deck.gl/core/typed';
import { ClipExtension } from '@deck.gl/extensions/typed';
import { MVTLayer, TileLayer } from '@deck.gl/geo-layers/typed';
import { Tile2DHeader, TileLoadProps } from '@deck.gl/geo-layers/typed/tileset-2d';
import { GeoJsonLayer } from '@deck.gl/layers/typed';
import { PMTilesSource } from '@loaders.gl/pmtiles';
import type { BinaryFeatures } from '@loaders.gl/schema';
import { castArray, isEmpty, size } from 'lodash';

import { MVTLoader, MVTLoaderOptions } from '@loaders.gl/mvt';
import { generatePMTDebugLayers } from './debugLayers';
import { deckGLToPMTTileIndex, getTileSizeByMaxZoom, reprojectFeatureCollection, validatePmtLayers } from './helpers';
import { ParsedPmTile, PmtLayerProps, TileJson } from './types';

/**
 * Override the MVTLayer class to support PMTiles data while leveraging the MVTLayer render performance
 */

const defaultProps: DefaultProps<PmtLayerProps> = {
  ...GeoJsonLayer.defaultProps,
  onDataLoad: { type: 'function', value: null, optional: true, compare: false },
  uniqueIdProperty: '',
  highlightedFeatureId: null,
  // TODO: this doesn't have an effect due to our direct loading, but it might be worth looking into for performance
  binary: true,
  // We handle the loading of the data ourselves, so we don't need the loaders
  loaders: [],
} as any;

export class PMTLayer extends MVTLayer<PmtLayerProps> {
  static layerName = 'PMTilesLayer';

  static defaultProps = defaultProps;

  state: {
    data: string | null;
    pmtilesSource: PMTilesSource | null;
    header: any | null;
    binary: boolean;
    tileset: any;
    isLoaded: boolean;
    tileJSON: TileJson | null;
    hoveredFeatureId: string | null;
    hoveredFeatureLayerName: string | null;
  };

  constructor(props: PmtLayerProps) {
    const modifiedProps = {
      ...props,
      tileSize: getTileSizeByMaxZoom(props.maxLevel),
    };
    super(modifiedProps);
  }

  initializeState(): void {
    super.initializeState();
    // GlobeView doesn't work well with binary data
    const binary = this.context.viewport.resolution !== undefined ? false : this.props.binary;

    /**
     * Update the pmtilesSource and header when the data changes.
     */
    (this as any)._updateTileData = async (): Promise<void> => {
      const data = this.props.data;

      const pmtilesSource = new PMTilesSource({ url: data as string });

      const selectedLayers = this.props.selectedLayers;
      const expectedLayers = this.props.expectedLayers;

      if (!isEmpty(expectedLayers) || !isEmpty(selectedLayers)) {
        // Check that the PMT classes in the metadata match the selected layers
        const pmtMetadata = await pmtilesSource.metadata;
        validatePmtLayers({ pmtMetadata, selectedLayers, expectedLayers });
      }

      this.setState({ data, pmtilesSource });
    };

    this.setState({ binary, data: null });
  }

  /**
   * Override the getTileData method to load the tile data from the PMTilesSource.
   */
  async getTileData(loadProps: TileLoadProps): Promise<ParsedPmTile | BinaryFeatures> {
    const { index, signal } = loadProps;
    const { data, pmtilesSource } = this.state;
    if (!data || !pmtilesSource) {
      return null;
    }
    const { x, y, z } = index;
    const { maxLevel } = this.props;
    const { x: actualX, y: actualY, z: actualZoom } = deckGLToPMTTileIndex({ x, y, z, maxLevel });

    const rangeResponse = await pmtilesSource.pmtiles.getZxy(actualZoom, actualX, actualY, signal);
    const arrayBuffer = rangeResponse?.data;
    if (!arrayBuffer) {
      return null;
    }
    const loadOptions: MVTLoaderOptions = {
      shape: 'geojson-table',
      mvt: {
        coordinates: 'wgs84',
        tileIndex: { x: actualX, y: actualY, z: actualZoom },
        ...(pmtilesSource.loadOptions as MVTLoaderOptions)?.mvt,
      },
      ...pmtilesSource.loadOptions,
    };

    const tileData = arrayBuffer ? await MVTLoader.parse(arrayBuffer, loadOptions) : null;

    return reprojectFeatureCollection(tileData as any) as ParsedPmTile;
  }

  /**
   * Override the renderSubLayers method to add a clip extension to the layer based on the orthographic tile's bounding box.
   * Also adds debug layers if the debug prop is set to true.
   */
  renderSubLayers(
    props: TileLayer['props'] & {
      id: string;
      data: ParsedPmTile;
      _offset: number;
      tile: Tile2DHeader<ParsedPmTile>;
    }
  ): Layer | null | LayersList {
    props.autoHighlight = false;

    // Add a clip extension to the layer to clip the features to the tile's bounding box.
    // Without this extension, features extending beyond the tile's bounding box are displayed.
    props.extensions = [...(props.extensions || []), new ClipExtension()];
    // Set the clip bounds to the tile's bounding box. The bounds are in the form [minX, minY, maxX, maxY].
    const topLeft = props.tile.boundingBox[0];
    const bottomRight = props.tile.boundingBox[1];
    // @ts-ignore
    props.clipBounds = [topLeft[0], topLeft[1], bottomRight[0], bottomRight[1]];

    const parentRenderSubLayers = TileLayer.prototype.renderSubLayers.bind(this);
    const subLayers = parentRenderSubLayers(props);

    if (this.state.binary && !(subLayers instanceof GeoJsonLayer)) {
      console.warn('renderSubLayers() must return GeoJsonLayer when using binary:true');
    }

    const returnValue = castArray(subLayers);

    if (this.props.debug) {
      const tileCoordinates = (props.tile.index || props.tile) as { x: number; y: number; z: number };
      returnValue.push(
        generatePMTDebugLayers({
          id: `${props.id}-debug`,
          maxLevel: this.props.maxLevel,
          tileCoordinates,
          boundingBox: props.tile.boundingBox,
          numFeatures: size((props.data as any)?.features || props.data),
        })
      );
    }
    return returnValue;
  }
}
