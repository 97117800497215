import {
  ExperimentResult,
  FlowClassName,
  OptionsEntry,
  ResultColorMap,
  ResultType,
} from 'interfaces/experimentResults';

export enum HeatmapType {
  Dzi = 'dzi',
  Pmt = 'pmt',
  GeoJson = 'geojson',
  PmtLayer = 'pmt-layer',
  Parquet = 'parquet',
}

export enum ParquetLayerType {
  MarkerPositivity = 'marker positivity',
  MarkerProbability = 'marker probability',
  CellClassification = 'cell classification',
  CellClassificationDensities = 'cell classification densities',
  CellMultiClassification = 'cell multi classification',
  Other = 'other',
}

export enum SecondaryAnalysisStatus {
  None = 'none',
  PrimaryResult = 'primary-result',
  SecondaryResult = 'secondary-result',
  SecondaryResultWithMissingParent = 'secondary-result-with-missing-parent',
}

interface BaseFeatureMetadata {
  key?: string;
  id: string;
  value?: number | string;
  heatmapType?: HeatmapType;
  columnName?: string;
  columnType?: OptionsEntry['type'];
  heatmapUrl?: string;
  cellsDataUrl?: string;
  displayName?: string;
  orchestrationId?: string;
  numFeatures?: number;
  createdAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  approved?: boolean;
  internallyApproved?: boolean;
  experimentResultId?: number;
  flowClassName?: FlowClassName;
  resultType?: ResultType;
  color?: string | ResultColorMap;
  options?: ExperimentResult['options'];
  primaryRunOrchestrationId?: string;
  registeredFromStainTypeIndex?: number;
}

export interface NestedHeatmap extends BaseFeatureMetadata {
  nestedItems?: NestedHeatmap[];
  secondaryResults?: null | undefined;
  secondaryAnalysisStatus?: undefined | null;
}

export interface FeatureMetadataBeforeSecondaryAnalysisGrouping extends BaseFeatureMetadata {
  nestedItems?: NestedHeatmap[];
}

export interface FeatureMetadataWithSingleRun extends FeatureMetadataBeforeSecondaryAnalysisGrouping {
  primaryRunOrchestrationId: undefined | null;
  secondaryResults?: null | undefined;
  secondaryAnalysisStatus: SecondaryAnalysisStatus.None;
}

export interface FeatureMetadataWithMissingParent extends FeatureMetadataBeforeSecondaryAnalysisGrouping {
  primaryRunOrchestrationId: string;
  secondaryAnalysisStatus: SecondaryAnalysisStatus.SecondaryResultWithMissingParent;
  secondaryResults?: null | undefined;
}

export interface FeatureMetadataSecondaryAnalysisEntry extends FeatureMetadataBeforeSecondaryAnalysisGrouping {
  primaryRunOrchestrationId: string;
  secondaryResults?: null | undefined;
  secondaryAnalysisStatus: SecondaryAnalysisStatus.SecondaryResult;
}

export interface FeatureMetadataWithSecondaryAnalysisFlow extends FeatureMetadataBeforeSecondaryAnalysisGrouping {
  secondaryResults?: FeatureMetadataSecondaryAnalysisEntry[];
  primaryRunOrchestrationId: null | undefined;
  secondaryAnalysisStatus: SecondaryAnalysisStatus.PrimaryResult;
}

// This is the final type used for both heatmaps and features after parsing
export type FeatureMetadata =
  | FeatureMetadataWithSingleRun
  | FeatureMetadataWithSecondaryAnalysisFlow
  | FeatureMetadataWithMissingParent
  | FeatureMetadataSecondaryAnalysisEntry
  | NestedHeatmap;

export interface Results {
  publishedResults: FeatureMetadata[];
  internalResults: { [key: string]: FeatureMetadata[] };
  deletedResults?: FeatureMetadata[];
}

export interface ParsedResults {
  heatmaps: Results;
  features: Results;
  internalHeatmaps?: { [key: string]: FeatureMetadata[] };
}

export const experimentResultToGroup = (result: FeatureMetadata) => result.resultType || result.flowClassName;

export const parseBaseFeatureMetadataFromExperimentResult = (
  experimentResult: ExperimentResult,
  keyPrefix: string // keyPrefix is used to differentiate between different types of features / heatmaps for the same experiment result
): FeatureMetadataBeforeSecondaryAnalysisGrouping => ({
  id: `${keyPrefix}[${experimentResult.experimentResultId}]`,
  key: `${keyPrefix}[${
    experimentResult.primaryRunOrchestrationId ||
    experimentResult.orchestrationId ||
    experimentResult.experimentResultId
  }]`,
  experimentResultId: experimentResult.experimentResultId,
  orchestrationId: experimentResult.orchestrationId,
  numFeatures: experimentResult.numFeatures,
  createdAt: experimentResult.createdAt,
  approved: Boolean(experimentResult.approved),
  internallyApproved: Boolean(experimentResult.internallyApproved),
  flowClassName: experimentResult.flowClassName,
  resultType: experimentResult.resultType,
  primaryRunOrchestrationId: experimentResult.primaryRunOrchestrationId,
  deletedAt: experimentResult.deletedAt,
  deletedBy: experimentResult.deletedBy,
  options: experimentResult.options,
});
