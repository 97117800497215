import { Permission } from 'interfaces/permissionOption';
import { includes } from 'lodash';
import { useEffect } from 'react';
import { useAnnotationQueryParams } from 'services/annotations/useAnnotationQueryParams';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';
import { usePermissions } from 'utils/usePermissions';
import { SlideAnnotationEditType } from './options';

const HIDE_FILL_COLOR_SHORTCUTS = ['h', 'י'];
const MOVE_TO_POLYGON_MODE = ['p', 'פ'];
const MOVE_TO_ROI_MODE = ['r', 'ר'];
const MOVE_TO_DELETE_MODE = ['d', 'ג'];
const MOVE_TO_MODIFY_MODE = ['e', 'ק'];

export const useSlideAnnotationKeyboardEvents = () => {
  const [useOSDViewer] = useQueryParam('useOSDViewer', BooleanParam);
  const [, setEditAnnotationMode] = useQueryParam('editAnnotationsMode', StringParam);
  const [, setHideFillColor] = useQueryParam('hideFillColor', BooleanParam);

  const { annotationsActive } = useAnnotationQueryParams();

  const { hasPermission } = usePermissions();
  const canAnnotateSlides = hasPermission(Permission.AnnotateSlides);
  const canRunSecondaryAnalysis = hasPermission(Permission.RunSecondaryAnalysis);
  const hasPermissionsToDrawingTools = canAnnotateSlides || canRunSecondaryAnalysis;

  useEffect(() => {
    if (!useOSDViewer && hasPermissionsToDrawingTools && annotationsActive) {
      const handleKeyDownForShortcuts = (event: KeyboardEvent) => {
        // don't trigger shortcuts if the user is pressing a modifier key
        if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
          return;
        }
        if (includes(MOVE_TO_POLYGON_MODE, event.key.toLowerCase())) {
          setEditAnnotationMode(SlideAnnotationEditType.Polygon);
        } else if (includes(MOVE_TO_ROI_MODE, event.key.toLowerCase())) {
          setEditAnnotationMode(SlideAnnotationEditType.Roi);
        } else if (includes(MOVE_TO_DELETE_MODE, event.key.toLowerCase())) {
          setEditAnnotationMode(SlideAnnotationEditType.Delete);
        } else if (includes(MOVE_TO_MODIFY_MODE, event.key.toLowerCase())) {
          // e for edit
          setEditAnnotationMode(SlideAnnotationEditType.Modify);
        } else if (includes(HIDE_FILL_COLOR_SHORTCUTS, event.key.toLowerCase())) {
          setHideFillColor((hideFillColor) => !hideFillColor);
        }
      };

      window.addEventListener('keydown', handleKeyDownForShortcuts);

      return () => {
        window.removeEventListener('keydown', handleKeyDownForShortcuts);
      };
    }
  }, [useOSDViewer, hasPermissionsToDrawingTools, annotationsActive]);
};
