import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getResultsExistence } from 'api/experimentResults';
import { runMultiplexBinaryClassifier } from 'api/platform';
import { modelTypeBinaryClassifier } from 'components/Pages/Jobs/inferenceFieldsOptions';
import { FlowClassName } from 'interfaces/experimentResults';
import { MULTIPLEX_STAIN_ID } from 'interfaces/stainType';
import { filter, indexOf, isEmpty, keys, map, some } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ArrayParam } from 'use-query-params';
import { encodeQueryParamsUsingSchema } from 'utils/useEncodedFilters';
import * as yup from 'yup';
import { useCasesParams } from '../../../../../utils/useCasesParams';
import { PlatformStepper } from '../PlatformStepper';
import { SelectModelStep } from '../SelectModelStep';
import MultiplexProcessSlideIds from './MultiplexProcessSlideIds';

const SNACK_BAR_KEY_RUN_MULTIPLEX_BINARY_CLASSIFIER = 'RUN_MULTIPLEX_BINARY_CLASSIFIER';

export interface IFormValues {
  modelPath: string;
}

export enum RunMultiplexBinaryClassifierSteps {
  CheckProcesses = 'Check Histogram And Segmentation Processes',
  SelectModel = 'Select Model',
}

const stepsOrder = [RunMultiplexBinaryClassifierSteps.CheckProcesses, RunMultiplexBinaryClassifierSteps.SelectModel];

const modelUrlValidation = yup.object({
  modelPath: yup.string().required(),
});

const defaultValues = { modelPath: '' };

export interface IFormProps {
  onClose: () => void;
}

export const RunMultiplexBinaryClassifier = (props: IFormProps): ReactElement => {
  const formMethods = useForm<IFormValues>({
    mode: 'onChange',
    resolver: yupResolver(modelUrlValidation),
    defaultValues,
  });
  const { onClose } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(RunMultiplexBinaryClassifierSteps.CheckProcesses);
  const { casesParams, schema, options } = useCasesParams();

  const getEncodedParams = () =>
    encodeQueryParamsUsingSchema(
      {
        ...casesParams,
        flowClassNames: [FlowClassName.CreateMultiplexHistogramFlow, FlowClassName.MultiplexCellSegmentationProcessor],
      },
      {
        ...schema,
        flowClassNames: ArrayParam,
      },
      options
    );

  const { data: resultsExist, isLoading: isLoadingResultsExist } = useQuery({
    queryKey: ['resultsExist', getEncodedParams()],
    queryFn: ({ signal }) => getResultsExistence(getEncodedParams(), casesParams.studyId, signal),
  });

  const runMultiplexBinaryClassifierMutation = useMutation(runMultiplexBinaryClassifier, {
    onError: () => {
      enqueueSnackbar('Error occurred, Multiplex Binary Classifier failed', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('Multiplex Binary Classifier Started', { variant: 'success' });
    },
    onSettled() {
      closeSnackbar(SNACK_BAR_KEY_RUN_MULTIPLEX_BINARY_CLASSIFIER);
    },
  });

  const onSubmit = () => {
    runMultiplexBinaryClassifierMutation.mutate({
      ...casesParams,
      ...formMethods.getValues(),
    });

    enqueueSnackbar({
      variant: 'success',
      message: (
        <Grid container>
          <Grid item>
            <Typography>Waiting for Multiplex Binary Classifier to start</Typography>
          </Grid>
          <Grid item>
            <CircularProgress sx={{ marginLeft: 10 }} color="inherit" size={20} />
          </Grid>
        </Grid>
      ),
      key: SNACK_BAR_KEY_RUN_MULTIPLEX_BINARY_CLASSIFIER,
      autoHideDuration: null,
    });

    onClose();
  };

  const missingResults = filter(keys(resultsExist), (flowClassName) => {
    return some(resultsExist[flowClassName], (result) => result === false);
  });

  const steps = {
    [RunMultiplexBinaryClassifierSteps.CheckProcesses]: {
      label: RunMultiplexBinaryClassifierSteps.CheckProcesses,
      content: (
        <Grid item>
          {isLoadingResultsExist ? (
            <CircularProgress />
          ) : (
            <Typography>
              {!isEmpty(missingResults) ? (
                <Grid container spacing={1}>
                  <Grid item>
                    Some slides are missing results. You need to run Multiplex Cell Segmentation and Multiplex Histogram
                    processes
                  </Grid>
                  <Grid item xs={12}>
                    {map(missingResults, (flowClassName) => (
                      <MultiplexProcessSlideIds
                        flowClassName={flowClassName}
                        slideIds={filter(
                          keys(resultsExist[flowClassName]),
                          (slideId) => resultsExist[flowClassName][slideId] === false
                        )}
                      />
                    ))}
                  </Grid>

                  <Grid item>
                    If you have already run these processes not in the webapp and you are sure that all slides have
                    results , you can proceed with Multiplex Binary Classifier
                  </Grid>
                </Grid>
              ) : (
                'All slides have results. You can proceed with Multiplex Binary Classifier'
              )}
            </Typography>
          )}
        </Grid>
      ),
    },
    [RunMultiplexBinaryClassifierSteps.SelectModel]: {
      label: 'Model Selection',
      content: (
        <SelectModelStep<IFormValues, 'modelPath'>
          modelUrlField="modelPath"
          casesParams={casesParams}
          formMethods={formMethods}
          modelType={modelTypeBinaryClassifier.apiModelValue}
          slideStainType={MULTIPLEX_STAIN_ID}
        />
      ),
    },
  };

  const [didChangeSteps, setDidChangeSteps] = useState(false);
  const isStepFailed: Record<number, boolean> = {
    1: isLoadingResultsExist,
    2: didChangeSteps && !modelUrlValidation.isValidSync(formMethods.getValues()),
  };

  return (
    <PlatformStepper
      validateStep={async () => isStepFailed[indexOf(stepsOrder, activeStep)]}
      handleSubmit={onSubmit}
      steps={map(stepsOrder, (step) => steps[step])}
      setActiveStepForValidation={(newStep) => {
        setActiveStep(stepsOrder[newStep]);
        setDidChangeSteps(true);
      }}
      isStepFailed={isStepFailed}
    />
  );
};
