export interface IFormValues {
  jobName: string;
  jobDescription: string;
  modelType: string;
  modelPath?: string;
  nuclearChannelNames: string[];
  membranalChannelNames: string[];
}

export enum RunMultiplexCellSegmentationSteps {
  NameAndDescription = 'Name and Description',
  Configuration = 'Configuration',
}

export const runMultiplexCellSegmentationStepsOrder = [
  RunMultiplexCellSegmentationSteps.NameAndDescription,
  RunMultiplexCellSegmentationSteps.Configuration,
];

export enum MultiplexCellSegmentationModelTypes {
  amikta = 'amikta',
  deepcell = 'deepcell',
  cellpose = 'cellpose',
}

export const multiplexCellSegmentationModelTypesOptions = [
  {
    text: 'Amikta',
    value: MultiplexCellSegmentationModelTypes.amikta,
    isDefault: true,
  },
  {
    text: 'deepcell',
    value: MultiplexCellSegmentationModelTypes.deepcell,
  },
  {
    text: 'cellpose',
    value: MultiplexCellSegmentationModelTypes.cellpose,
  },
];
