import { COORDINATE_SYSTEM } from '@deck.gl/core/typed';
import { PMTilesMetadata } from '@loaders.gl/pmtiles';
import { compact, Dictionary, fromPairs, indexOf, isEmpty, keys, map, replace, size, sortedUniq, values } from 'lodash';

import { MVTLayerProps } from '@deck.gl/geo-layers/typed';
import { LayerVisualizationSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { defaultLayerColors } from 'components/theme/theme';
import { getColorHex, hexToRgb } from 'utils/helpers';
import { PMTLayer } from './PmtLayer';

const UNSELECTED_FEATURE_COLOR = [0, 0, 0, 0] as [number, number, number, number];

/**
 * Create a deck.gl layer for a PMT Heatmap.
 * This layer will fetch the PMT Heatmap and display the selected classes on the map in the selected colors.
 * @param options The options for the function.
 * @param options.idPrefix The prefix to add to the layer id.
 * @param options.visualSettings The visualization settings for the layer.
 * @param options.maxLevel The maximum zoom level of the base layer.
 * @param options.pmtHeatmap The heatmap to display.
 * @param options.pmtMetadata The metadata of the PMTiles file, used to get the min and max zoom levels, as well as to validate the selected layers.
 * @param options.rescaleFactor The rescale factor to apply to the point radius.
 * @param options.stroked Whether to add a stroke to the features.
 * @param options.filled Whether to fill the polygons.
 * @param options.debug Whether to log debug information and add debug layers.
 * @returns The deck.gl layers for the PMT Heatmap.
 */
export const deckGLPMTLayer = ({
  idPrefix = '',
  visualSettings,
  maxLevel,
  pmtHeatmap,
  pmtMetadata,
  rescaleFactor,
  stroked = false,
  filled = true,
  debug = false,
}: {
  idPrefix?: string;
  visualSettings: Dictionary<LayerVisualizationSettings>;
  maxLevel: number;
  pmtHeatmap: Partial<FeatureMetadata>;
  pmtMetadata: PMTilesMetadata;
  rescaleFactor?: number;
  debug?: boolean;
  stroked?: boolean;
  filled?: boolean;
}) => {
  const pmtUrl = pmtHeatmap?.heatmapUrl;
  if (!pmtUrl || pmtUrl.startsWith('s3://')) {
    console.warn('Invalid PMTiles URL', { pmtHeatmap });
    return undefined;
  }

  if (!pmtMetadata) {
    console.warn('Invalid PMTiles metadata', { pmtMetadata });
    return undefined;
  }

  const pmtHeatmapId = pmtHeatmap?.id;
  if (!pmtHeatmapId) {
    console.warn('Invalid PMTiles heatmap id', { pmtHeatmap, visualSettings, pmtMetadata });
    return undefined;
  }

  const pointRadiusAtMaxZoom = Math.max(1, 5 / (rescaleFactor || 1));

  const layersKeys = sortedUniq(keys(visualSettings));
  const heatmapLayerToGeoJsonProps = fromPairs(
    compact(
      map(visualSettings, (featureSettings, heatmapLayerKey) => {
        if (!featureSettings?.selected) {
          if (debug) {
            console.debug('Layer not selected', { pmtHeatmapId, heatmapLayerKey, featureSettings });
          }
          return undefined;
        }
        const layerIdx = indexOf(layersKeys, heatmapLayerKey);
        return [
          heatmapLayerKey,
          {
            color: hexToRgb(getColorHex(featureSettings?.color) || defaultLayerColors[layerIdx % size(layersKeys)]),
            opacity: featureSettings?.show ? featureSettings?.opacity / 100 : 0,
            layerName: replace(heatmapLayerKey, `${pmtHeatmapId}-`, ''),
          },
        ];
      })
    )
  );

  if (isEmpty(heatmapLayerToGeoJsonProps)) {
    if (debug) {
      console.debug('No heatmap layers selected', {
        visualSettings,
        pmtHeatmap,
        pmtMetadata,
        heatmapLayerToGeoJsonProps,
        layersKeys,
        pmtHeatmapId,
      });
    }
    return undefined;
  }

  // TODO: See if we can pass this logic into the Layer class
  const maxZoom = pmtMetadata.maxZoom - maxLevel;
  const minZoom = Math.min(maxZoom, pmtMetadata.minZoom - maxLevel);

  const getColorFromFeature: MVTLayerProps['getFillColor'] | MVTLayerProps['getLineColor'] = (feature) => {
    const layerName = feature?.properties?.class_name || feature?.properties?.name;
    const featureSettings = heatmapLayerToGeoJsonProps?.[`${pmtHeatmapId}-${layerName}`];
    if (!featureSettings) {
      return UNSELECTED_FEATURE_COLOR;
    }
    const color = featureSettings.color;
    const opacity = featureSettings.opacity;
    return [...color, opacity * 255];
  };

  return new PMTLayer({
    id: `${idPrefix}${pmtHeatmapId}`,
    minZoom,
    maxZoom,
    maxLevel,
    // TODO: figure out why the zoomOffset is needed
    zoomOffset: -2,

    coordinateSystem: COORDINATE_SYSTEM.DEFAULT,

    // Used to check that the PMT classes in the metadata match the options in the pmtHeatmap.
    expectedLayers: map(pmtHeatmap?.nestedItems, 'key'),
    selectedLayers: map(values(heatmapLayerToGeoJsonProps), 'layerName'),

    // Assume the pmtiles file support HTTP/2, so we aren't limited by the browser to a certain number per domain.
    maxRequests: 20,

    pickable: false,

    data: pmtUrl,

    getFillColor: getColorFromFeature,
    getLineColor: getColorFromFeature,

    stroked,
    filled,
    pointRadiusMinPixels: pointRadiusAtMaxZoom,
    lineWidthMinPixels: 1 / rescaleFactor,

    // Update the colors when the visual settings change.
    updateTriggers: { getFillColor: [visualSettings], getLineColor: [visualSettings] },

    debug,
  });
};
