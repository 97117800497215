import CheckIcon from '@mui/icons-material/Check';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Checkbox, Chip, CircularProgress, FormControlLabel, Grid, Popover } from '@mui/material';
import { DataGrid, GridEventListener } from '@mui/x-data-grid';
import { JobFilters } from 'api/jobs';
import { getSlideByIdKeys } from 'api/slides';
import { getStudyProcedureQueryKey } from 'api/study';
import { useJobColumns } from 'components/Pages/Jobs/columns';
import { JobDrawer } from 'components/Pages/Jobs/JobDrawer';
import { defaultRowsPerPage, useJobs } from 'components/Pages/Jobs/useJobs';
import { JobStatus, JobType } from 'interfaces/job';
import { Permission } from 'interfaces/permissionOption';
import { Procedure } from 'interfaces/procedure';
import { compact, filter, flatMap, forEach, includes, isEmpty, noop, size, some, toString } from 'lodash';
import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';
import queryClient from 'utils/queryClient';
import { useSelectedSlideIds } from 'utils/useCurrentSlideIds';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { usePermissions } from 'utils/usePermissions';
import usePrevious from 'utils/usePrevious';
import { headerHeight } from '../constants';

const displayedColumns = ['type', 'startedAt', 'orchestrationId', 'status'];

export const JobsStatusHeaderItem = forwardRef(
  (
    {
      numPending,
      numRunning,
      isQueryRunning,
      allJobsDone,
      wasWaitingForRecalculatedResults,
      dontAnimate,
      handleClick,
    }: {
      numPending: number;
      numRunning: number;
      isQueryRunning: boolean;
      allJobsDone: boolean;
      wasWaitingForRecalculatedResults: boolean;
      dontAnimate?: boolean;
      handleClick?: () => void;
    },
    ref: React.Ref<HTMLDivElement>
  ) => {
    return (
      <Grid
        item
        container
        columnGap={1}
        ref={ref}
        onClick={handleClick}
        sx={handleClick ? { cursor: 'pointer' } : undefined}
      >
        <Chip
          label={
            isQueryRunning
              ? 'Jobs: Loading...'
              : numPending > 0 && numRunning
              ? `Jobs: ${numPending} pending, ${numRunning} running`
              : numPending > 0
              ? `Jobs: ${numPending} pending`
              : numRunning > 0
              ? `Jobs: ${numRunning} running`
              : wasWaitingForRecalculatedResults
              ? 'Recalculated Results are Ready'
              : 'All jobs completed'
          }
          icon={
            isQueryRunning || !allJobsDone ? (
              !dontAnimate && <CircularProgress size={20} value={dontAnimate ? 0 : undefined} />
            ) : (
              <CheckIcon />
            )
          }
          color={allJobsDone && !isQueryRunning ? 'success' : 'default'}
          deleteIcon={open ? <ExpandLessIcon color="primary" /> : <ExpandMoreIcon color="primary" />}
          onDelete={handleClick ? handleClick : dontAnimate ? noop : undefined}
        />
      </Grid>
    );
  }
);

export const JobsStatus: React.FC<{
  enabled?: boolean;
  procedure: Procedure;
}> = ({ enabled = true, procedure }) => {
  const caseId = procedure?.id;
  const studyId = procedure?.studyId;
  const [selectedSlideIds] = useSelectedSlideIds(procedure);
  const didHaveRunningJobs = useRef(false);
  const wasWaitingForRecalculatedResults = useRef(false);
  const { hasPermission, isLoading: isLoadingPermissions } = usePermissions();
  const canViewJobs = hasPermission(Permission.ViewJobs);
  const canRunSecondaryAnalysis = hasPermission(Permission.RunSecondaryAnalysis);
  const canOnlyRunSecondaryAnalysis = !canViewJobs && canRunSecondaryAnalysis;

  const [currentJobId, setCurrentJobId] = useState<string | null>(null);

  const [page, setPage] = React.useState(1);

  const handleChangePage = (newPage: number) => setPage(newPage);

  const canQueryJobs =
    (canViewJobs || canRunSecondaryAnalysis) && Boolean(studyId) && !isEmpty(compact(selectedSlideIds)) && enabled;

  const commonJobsFilters: JobFilters = {
    studyId,
    slideIds: compact(selectedSlideIds),
    // Users who can view secondary analysis jobs can view calculate features jobs only
    ...(canOnlyRunSecondaryAnalysis ? { type: JobType.CalculateFeatures } : {}),
  };
  const {
    data: activeJobsQueryResponse,
    isLoading: activeJobsIsLoading,
    isFetching: activeJobsIsFetching,
    refetch: refetchActiveJobs,
  } = useJobs({
    page,
    enabled: canQueryJobs,
    additionalFilters: { ...commonJobsFilters, statuses: [JobStatus.Pending, JobStatus.Running] },
    fullData: true,
    fetchWithInterval: true,
  });

  // We want to show the children of the active jobs if they exist, as they are the ones who will write the results
  const activeJobsReplacedByChildrenIfExist = useMemo(
    () =>
      flatMap(activeJobsQueryResponse?.jobs, (job) => {
        const slideChildren = filter(job?.children, (childJob) =>
          some(selectedSlideIds, (slideId) => toString(childJob?.manifest).includes(`"${slideId}"`))
        );
        return !isEmpty(slideChildren) ? slideChildren : job;
      }),
    [activeJobsQueryResponse]
  );

  const hasActiveJobs = !isEmpty(activeJobsReplacedByChildrenIfExist);
  const numRunning = size(filter(activeJobsReplacedByChildrenIfExist, { status: JobStatus.Running }));
  // In case status wasn't updated, we assume it's pending, so we calculate by removing running and completed jobs
  const numPending =
    size(activeJobsReplacedByChildrenIfExist) -
    numRunning -
    size(filter(activeJobsReplacedByChildrenIfExist, { status: JobStatus.Completed }));

  const [showAllJobsInCasePageUrlState, setShowAllJobsInCasePage] = useQueryParam(
    'showAllJobsInCasePage',
    BooleanParam
  );
  const canShowAllJobs = canViewJobs || canRunSecondaryAnalysis;
  // We only allow showing all jobs if the user can view them
  const showAllJobsInCasePage = (!hasActiveJobs || showAllJobsInCasePageUrlState) && canShowAllJobs;

  const showAllJobsInCasePageAfterCompletion = numPending === 0 && numRunning === 0 && didHaveRunningJobs.current;

  // Only show the all jobs if there are no pending or running jobs, and there were running jobs before - or if the user asked to show all jobs
  const shouldQueryAllJobs =
    canQueryJobs &&
    !activeJobsIsLoading &&
    !activeJobsIsFetching &&
    (showAllJobsInCasePageAfterCompletion || showAllJobsInCasePage);

  const {
    data: allJobsQueryResponse,
    isLoading: allJobsIsLoading,
    isFetching: allJobsIsFetching,
    refetch: refetchAllJobs,
  } = useJobs({
    page,
    enabled: shouldQueryAllJobs,
    additionalFilters: commonJobsFilters,
    fetchWithInterval: true,
  });

  const isLoading = activeJobsIsLoading || (shouldQueryAllJobs && allJobsIsLoading);
  const isFetching = activeJobsIsFetching || (shouldQueryAllJobs && allJobsIsFetching);

  const refetchJobs = hasActiveJobs ? refetchActiveJobs : refetchAllJobs;

  const rows =
    hasActiveJobs && !showAllJobsInCasePage
      ? activeJobsReplacedByChildrenIfExist || []
      : allJobsQueryResponse?.jobs || [];

  const rowCount = hasActiveJobs
    ? activeJobsQueryResponse?.totalJobs ?? size(activeJobsQueryResponse?.jobs)
    : allJobsQueryResponse?.totalJobs ?? size(allJobsQueryResponse?.jobs);

  const toggleRef = useRef<HTMLDivElement | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback(() => {
    setAnchorEl(toggleRef.current);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setCurrentJobId(null);
  }, []);

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    setCurrentJobId(params.row.id);
  };

  const { queryParams, generateEncodedParams } = useEncodedFilters();
  const fullSlideQueryEncodedFilters = generateEncodedParams({
    filters: { ...(queryParams?.filters || {}), studyId },
  });

  const previousRunningJobs = usePrevious(numRunning);
  useEffect(() => {
    // If there are running jobs, we want to keep the status available
    if (numRunning > 0 || numPending > 0) {
      didHaveRunningJobs.current = true;
    } else if ((previousRunningJobs ?? 0) > numRunning) {
      // If the number of running jobs decreased, we need to refetch experiment results for the slides and case
      queryClient.invalidateQueries(getStudyProcedureQueryKey(studyId, caseId, queryParams));
      forEach(selectedSlideIds, (slideId) => {
        queryClient.invalidateQueries(getSlideByIdKeys({ slideId, encodedFilters: fullSlideQueryEncodedFilters }));
      });
    }
  }, [numRunning, numPending, previousRunningJobs]);

  const hasActiveSecondaryAnalysisJobs = some(
    rows,
    (row) => row?.type === JobType.CalculateFeatures && Boolean(row.params?.primaryRunOrchestrationId)
  );
  useEffect(() => {
    if (!wasWaitingForRecalculatedResults.current && hasActiveSecondaryAnalysisJobs) {
      wasWaitingForRecalculatedResults.current = true;
    }
  }, [hasActiveSecondaryAnalysisJobs]);

  const { jobColumns, isLoading: isLoadingColumnsQueries } = useJobColumns({ viewOnly: true });
  const displayedColumnDefinitions = useMemo(
    () => filter(jobColumns, (column) => includes(displayedColumns, column.field)),
    [jobColumns]
  );

  const isQueryRunning = isLoading || isFetching;

  const jobDrawerOpen = currentJobId !== null && !canOnlyRunSecondaryAnalysis;

  const allJobsDone = numPending + numRunning <= 0;
  return (
    !isLoadingPermissions &&
    canQueryJobs &&
    (hasActiveJobs || didHaveRunningJobs.current || showAllJobsInCasePageUrlState) && (
      <>
        <JobsStatusHeaderItem
          ref={toggleRef}
          numPending={numPending}
          numRunning={numRunning}
          isQueryRunning={isQueryRunning}
          wasWaitingForRecalculatedResults={wasWaitingForRecalculatedResults.current}
          allJobsDone={allJobsDone}
          handleClick={handleClick}
        />
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ horizontal: 'center', vertical: -8 }}
        >
          <Grid item container direction={'column'} spacing={1} p={1} width={800} wrap="nowrap">
            <Grid item container justifyContent="center" alignItems="center" spacing={1} direction="row">
              {!showAllJobsInCasePageAfterCompletion && canShowAllJobs && (
                <Grid item>
                  <FormControlLabel
                    label="Show all jobs"
                    control={
                      <Checkbox
                        size="small"
                        checked={Boolean(showAllJobsInCasePage)}
                        onChange={(e) => {
                          setShowAllJobsInCasePage(e.target.checked);

                          if (e.target.checked) {
                            refetchAllJobs();
                          } else {
                            refetchActiveJobs();
                          }
                        }}
                      />
                    }
                  />
                </Grid>
              )}
              <Grid item>
                <Button onClick={() => refetchJobs()} disabled={isQueryRunning}>
                  {isQueryRunning ? 'Refreshing...' : 'Refresh'}
                </Button>
              </Grid>
            </Grid>
            <Grid item height="35vh">
              <DataGrid
                paginationModel={{ page: page - 1, pageSize: defaultRowsPerPage }}
                onPaginationModelChange={({ page: newPage }) => handleChangePage(newPage + 1)}
                loading={isLoadingColumnsQueries || (isLoading && canQueryJobs)}
                pagination
                rows={rows}
                rowCount={rowCount}
                columns={displayedColumnDefinitions}
                onRowClick={handleRowClick}
                paginationMode="server"
              />
            </Grid>
          </Grid>
          {jobDrawerOpen && (
            <JobDrawer
              headerHeight={headerHeight}
              currentJobId={currentJobId}
              jobDrawerOpen={jobDrawerOpen}
              setJobDrawerOpen={(newOpen) => {
                if (!newOpen) {
                  setCurrentJobId(null);
                }
              }}
            />
          )}
        </Popover>
      </>
    )
  );
};
