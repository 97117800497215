import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';
import { Box, Fade, Grid, IconButton, Popper, ToggleButton } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { last } from 'lodash';
import React from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';

import { deckGLViewerStates } from 'components/Procedure/SlidesViewer/DeckGLViewer/slidesViewerState';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import CopyableText from 'components/atoms/CopyableText';
import Slider from 'components/atoms/Slider';
import numeral from 'numeral';

/**
 * Normalizes all bearing values,
 * including those smaller than -360 or greater than 360,
 * into the range [-180, 180] with one decimal precision.
 * @param bearing - The bearing value to normalize.
 * @returns The normalized bearing value in the range [-180, 180], with one decimal precision.
 */
const normalizeBearing = (bearing: number) => {
  const normalized = ((((bearing + 180) % 360) + 360) % 360) - 180;
  return Math.round(normalized * 10) / 10;
};

const setBearing = ({
  newBearing,
  slideId,
  viewerIndex,
}: {
  newBearing: number;
  slideId: string;
  viewerIndex: number;
}) => {
  const deckGLViewerState = deckGLViewerStates[viewerIndex];
  deckGLViewerState.value = {
    ...deckGLViewerState.value,
    [slideId]: { ...deckGLViewerState.value[slideId], bearing: newBearing },
  };
};

const RotateToolPopper: React.FunctionComponent<{
  anchorEl: HTMLElement;
  slideToRotate: SlideWithChannelAndResults;
}> = ({ anchorEl, slideToRotate }) => {
  useSignals();
  const deckGLViewerState = slideToRotate ? deckGLViewerStates[slideToRotate.viewerIndex] : null;

  const baseBearing = deckGLViewerState?.value?.[slideToRotate.id]?.bearing || 0;

  const bearing = normalizeBearing(baseBearing);

  return (
    <Popper open anchorEl={anchorEl} placement="bottom" transition disablePortal>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps}>
          <Box sx={{ bgcolor: 'background.paper' }} width={300}>
            <Grid
              item
              container
              direction="row"
              p={0}
              m={0}
              columnSpacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={7}>
                <Slider
                  value={bearing}
                  onValueChange={(value) =>
                    slideToRotate &&
                    setBearing({
                      newBearing: value as number,
                      slideId: slideToRotate.id,
                      viewerIndex: slideToRotate.viewerIndex,
                    })
                  }
                  aria-labelledby="continuous-slider"
                  min={-180}
                  max={179.9}
                  step={0.1}
                />
              </Grid>
              <Grid item xs={3} pl={1}>
                <CopyableText text={`${numeral(bearing).format('0.0')}°`} sx={{ textAlign: 'center' }} />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  onClick={() =>
                    slideToRotate &&
                    setBearing({
                      newBearing: 0,
                      slideId: slideToRotate.id,
                      viewerIndex: slideToRotate.viewerIndex,
                    })
                  }
                >
                  <RestartAltIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      )}
    </Popper>
  );
};

export const RotateTool: React.FunctionComponent<{
  selectedSlides: SlideWithChannelAndResults[];
}> = ({ selectedSlides }) => {
  // We create a control to rotate one slide independently. Manual rotation controls also work
  const slideToRotate = last(selectedSlides);

  const [rotateToolActive, setRotateToolActive] = useQueryParam('rotateToolActive', BooleanParam);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  return (
    <Grid item>
      <ToggleButton
        ref={setAnchorEl}
        value="rotateTool"
        selected={Boolean(rotateToolActive)}
        onClick={() => setRotateToolActive(!rotateToolActive)}
        title="Rotate tool"
        disabled={!slideToRotate}
      >
        <ScreenRotationIcon color={rotateToolActive ? 'primary' : 'inherit'} />
      </ToggleButton>
      {Boolean(anchorEl) && rotateToolActive && Boolean(slideToRotate) && (
        // Since the tool depends on a signal, it's best to render it only when needed
        // to avoid unnecessary re-renders.
        <RotateToolPopper anchorEl={anchorEl} slideToRotate={slideToRotate} />
      )}
    </Grid>
  );
};
