import Grid from '@mui/material/Grid';
import { useQuery } from '@tanstack/react-query';
import { getInternalUsers } from 'api/userCredentials';
import LabelledInput from 'components/atoms/LabelledInput/LabelledInput';
import AutocompleteFilter from 'components/SearchFilters/MainFilters/AutocompleteFilter';
import { isEmpty, isNumber, map, mapValues } from 'lodash';
import React from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { JsonParam, useQueryParams } from 'use-query-params';
import { useUiSettings } from 'utils/queryHooks/uiConstantsHooks';

const Filters: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { uiSettings } = useUiSettings();
  const enumDisplayNames = uiSettings.enumDisplayNames;

  const senderOptions = map(enumDisplayNames.requestSender, (requestSender) => {
    return {
      value: requestSender.value,
      text: requestSender.label,
    };
  });

  const statusOptions = map(enumDisplayNames.jobStatus, (jobStatus) => {
    return {
      value: jobStatus.value,
      text: jobStatus.label,
    };
  });

  const { data: internalUsers, isLoading: internalUsersLoading } = useQuery(['internalUsers'], getInternalUsers);
  const internalUsersOptions = map(internalUsers, (internalUser) => ({
    value: internalUser.id,
    text: internalUser.name,
  }));

  const [{ jobsFilters: queryFilters }, setQueryParams] = useQueryParams({
    jobsFilters: JsonParam,
  });

  // we are using a local state as a middleman to debounce the query params update which triggers a refetch of the data
  const [filters, setFilters] = React.useState<Record<string, any>>(queryFilters);

  const updateJobFiltersQueryParams = useDebouncedCallback((updatedFilters: Record<string, any>) => {
    const normalizedFilters = mapValues(updatedFilters, (value) =>
      !isNumber(value) && isEmpty(value) ? undefined : value
    );
    setQueryParams({ jobsFilters: { ...queryFilters, ...normalizedFilters } }, 'replaceIn');
  }, 1000);

  const handleFilterChange = (filterKey: string, value: any) => {
    const newFilters = { ...filters, [filterKey]: value };
    setFilters(newFilters);
    updateJobFiltersQueryParams(newFilters);
  };

  return (
    <Grid my={2} container spacing={1} wrap="wrap">
      <Grid item flex={1}>
        <LabelledInput
          size="small"
          label="Job Name"
          value={filters?.jobName || []}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFilterChange('jobName', event.target.value)}
        />
      </Grid>
      <Grid item flex={1}>
        <AutocompleteFilter
          label="User Name"
          options={internalUsersOptions}
          limitTags={1}
          handleChange={(optionValue) => {
            handleFilterChange('users', optionValue);
          }}
          value={filters?.users || []}
          loading={internalUsersLoading}
        />
      </Grid>
      <Grid item flex={1}>
        <AutocompleteFilter
          label={'Source'}
          options={senderOptions}
          limitTags={1}
          handleChange={(optionValue) => {
            handleFilterChange('senders', optionValue);
          }}
          value={filters?.senders || []}
        />
      </Grid>
      <Grid item flex={1}>
        <AutocompleteFilter
          label={'Status'}
          options={statusOptions}
          limitTags={1}
          handleChange={(optionValue) => {
            handleFilterChange('statuses', optionValue);
          }}
          value={filters?.statuses || []}
        />
      </Grid>
      <Grid item flex={1}>
        <LabelledInput
          size="small"
          label="Orchestration Id"
          value={filters?.orchestrationIdTextFilter || []}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleFilterChange('orchestrationIdTextFilter', event.target.value)
          }
        />
      </Grid>
    </Grid>
  );
};

export default Filters;
