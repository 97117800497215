import SuccessIcon from '@mui/icons-material/CheckCircleOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  bulkUpdateResultsByOrchestration,
  Orchestration,
  OrchestrationResponse,
  SlideResult,
} from 'api/experimentResults';
import Loader from 'components/Loader';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import {
  APPROVE_RESULTS_TEXT,
  DISAPPROVE_RESULTS_TEXT,
  PUBLISH_RESULTS_TEXT,
  UNPUBLISH_RESULTS_TEXT,
} from 'components/Procedure/Infobar/SlideInfobar/Results/OrchestrationApproval';
import { ExperimentResultUpdate, ResultsMode } from 'interfaces/experimentResults';
import {
  compact,
  every,
  filter,
  findIndex,
  first,
  flatMap,
  groupBy,
  includes,
  isEmpty,
  map,
  size,
  slice,
  some,
  uniq,
} from 'lodash';
import moment from 'moment';
import React, { FunctionComponent, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useCurrentLabId } from 'utils/useCurrentLab';
import { useNavigationToViewerPage } from 'utils/useNavigationToViewerPage';
import { useCasesParams } from '../../../../../utils/useCasesParams';

interface OrchestrationViewProps {
  orchestration: Orchestration;
  studyId: string;
  selectedSlideIds: string[];
  approvedSlideResults: SlideResult[];
  expanded: boolean;
  onOpen: (orchestrationId: string) => void;
  onClose: () => void;
}

type ResultStateAction = 'Approve' | 'Disapprove' | 'Publish' | 'Unpublish';

const resultOverride = { resultsMode: ResultsMode.Manual };

const OrchestrationView: FunctionComponent<React.PropsWithChildren<OrchestrationViewProps>> = ({
  orchestration,
  studyId,
  selectedSlideIds,
  approvedSlideResults,
  expanded,
  onOpen,
  onClose,
}) => {
  const { getUrlToSlidePage } = useNavigationToViewerPage();

  // A notice - published results mention here are saved in the database in the 'approved' column
  // and the approved results mention here are saved in the database in the 'internally_approved' column
  const { orchestrationId, slidesData, createdAtMinimum } = orchestration;
  const slideDataFlowClassNames = map(slidesData, 'flowClassName');
  const flowClassNamesFromSlidesData = uniq(compact(slideDataFlowClassNames));

  // We update the results for each flow class name separately
  const bulkUpdateResultsByOrchestrationPerFlow = async (
    ...[updateParams]: Parameters<typeof bulkUpdateResultsByOrchestration>
  ) => {
    return Promise.allSettled(
      map(flowClassNamesFromSlidesData, (flowClassName) => {
        return bulkUpdateResultsByOrchestration({ ...updateParams, flowClassNames: [flowClassName] });
      })
    );
  };

  const { labId } = useCurrentLabId();
  const queryClient = useQueryClient();

  const { casesParams } = useCasesParams(resultOverride);

  const updateMutation = useMutation(bulkUpdateResultsByOrchestrationPerFlow, {
    onMutate: (data) => {
      const previousValue = queryClient.getQueryData(['orchestrations', casesParams]);

      queryClient.setQueryData(['orchestrations', casesParams], (oldData: OrchestrationResponse) => {
        return {
          orchestrations: replaceOrchestrationData(oldData.orchestrations, data),
        };
      });

      return previousValue;
    },
    onSuccess: () => {
      queryClient.resetQueries(['procedure']);
      // this is needed to update the slide's labels that may relay on approved/published results
      queryClient.invalidateQueries(['procedures']);
    },
    onError: (error, previousValue) => {
      queryClient.setQueryData(['orchestrations', casesParams], previousValue);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['orchestrations']);
    },
  });

  const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState(false);
  const [resultStateAction, setResultStateAction] = useState<ResultStateAction>('Approve');

  // if we are publishing, update only approved slides
  const slideIdsToUpdate =
    resultStateAction == 'Publish'
      ? map(
          filter(
            slidesData,
            (slideData) =>
              slideData.internallyApproved && !slideData.approved && includes(selectedSlideIds, slideData.slideId)
          ),
          'slideId'
        )
      : resultStateAction == 'Disapprove'
      ? map(
          filter(
            slidesData,
            (slideData) => slideData.internallyApproved && includes(selectedSlideIds, slideData.slideId)
          ),
          'slideId'
        )
      : resultStateAction == 'Unpublish'
      ? map(
          filter(slidesData, (slideData) => slideData.approved && includes(selectedSlideIds, slideData.slideId)), // as mentioned above, published results are saved in the 'approved' column
          'slideId'
        )
      : map(
          filter(
            slidesData,
            (slideData) => !slideData.internallyApproved && includes(selectedSlideIds, slideData.slideId)
          ),
          'slideId'
        );

  const selectedSlidesData = filter(slidesData, (slideData) => includes(selectedSlideIds, slideData.slideId));
  const hasNoneApprovedSlides = every(selectedSlidesData, (slideData) => !slideData.internallyApproved);
  const hasDisapprovedSlides = some(selectedSlidesData, (slideData) => !slideData.internallyApproved);
  const hasApprovedUnpublishedSlides = some(
    selectedSlidesData,
    (slideData) => !slideData.approved && slideData.internallyApproved
  );

  const publishResults = hasApprovedUnpublishedSlides || hasNoneApprovedSlides;
  const publishedResultsWithDifferentOrchIds = groupBy(
    compact(
      flatMap(selectedSlidesData, (slideData) =>
        filter(
          approvedSlideResults,
          (approvedSlideData) =>
            approvedSlideData.slideId === slideData.slideId &&
            approvedSlideData?.approved &&
            approvedSlideData?.orchestrationId != orchestrationId
        )
      )
    ),
    'orchestrationId'
  );

  const approveDisapproveText = hasDisapprovedSlides ? APPROVE_RESULTS_TEXT : DISAPPROVE_RESULTS_TEXT;
  const publishUnpublishText = publishResults ? PUBLISH_RESULTS_TEXT : UNPUBLISH_RESULTS_TEXT;

  const warnings =
    resultStateAction == 'Publish'
      ? !isEmpty(publishedResultsWithDifferentOrchIds)
        ? [
            'Only approved results will be published.',
            `Warning: Publishing this orchestration will unpublish results that have been published in a different orchestration for the selected slides.`,
            ...map(
              publishedResultsWithDifferentOrchIds,
              (results, currentlyPublishedOrchId) =>
                `- Orchestration ${currentlyPublishedOrchId} (created at
                ${moment(first(results).createdAt).format('llll')}) is published for ${size(results)} slides.`
            ),
          ]
        : publishOnlyApprovedSlidesText
      : resultStateAction == 'Disapprove' && some(slidesData, (slideData) => slideData.approved)
      ? disapprovePublishedSlidesText
      : null;

  const confirmationModalContent = (
    <>
      <Typography>
        Are you sure you want to {`${resultStateAction.toLowerCase()} results for ${size(slideIdsToUpdate)}`} slides?
      </Typography>
      {!isEmpty(warnings) && (
        <Grid item container rowSpacing={1} sx={{ mt: 2, overflowY: 'auto', maxHeight: 200 }}>
          {map(warnings, (warningText) => (
            <Grid item>
              <Typography key={warningText}>{warningText}</Typography>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );

  const onConfirmUpdate = () => {
    // if we are unapproving, we want to unpublish all slides that are published
    const updatedData: ExperimentResultUpdate =
      resultStateAction == 'Publish'
        ? { approved: true }
        : resultStateAction == 'Unpublish'
        ? { approved: false }
        : resultStateAction == 'Approve'
        ? { internallyApproved: true }
        : { internallyApproved: false, approved: false };

    updateMutation.mutate({
      orchestrationId,
      studyId,
      slideIds: slideIdsToUpdate,
      labId,
      updatedData,
      flowClassNames: flowClassNamesFromSlidesData,
    });
    setOpenConfirmationDialog(false);
  };

  return (
    <>
      <ListItemButton
        onClick={() => {
          if (expanded) {
            onClose();
          } else {
            onOpen(orchestrationId);
          }
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={0.5}>
            <ListItemIcon>{expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}</ListItemIcon>
          </Grid>
          <Grid item xs={8}>
            <ListItemText
              primary={`Orchestration ${orchestrationId}`}
              secondary={`Created at: ${moment(createdAtMinimum).format('llll')}`}
            />
          </Grid>
          {expanded && (
            <>
              <Grid item xs={1.5}>
                <Button
                  onClick={(e) => {
                    setResultStateAction(hasDisapprovedSlides ? 'Approve' : 'Disapprove');
                    setOpenConfirmationDialog(true);

                    e.stopPropagation();
                  }}
                  disabled={updateMutation.isLoading}
                  color={some(slidesData, (slideData) => slideData.internallyApproved) ? 'primary' : 'secondary'}
                >
                  {approveDisapproveText}
                </Button>
              </Grid>
              <Grid item xs={1.5}>
                <Button
                  onClick={(e) => {
                    setResultStateAction(publishResults ? 'Publish' : 'Unpublish');
                    setOpenConfirmationDialog(true);
                    e.stopPropagation();
                  }}
                  disabled={updateMutation.isLoading || hasNoneApprovedSlides || hasDisapprovedSlides}
                  color={some(slidesData, (slideData) => slideData.internallyApproved) ? 'primary' : 'secondary'}
                >
                  {publishUnpublishText}
                </Button>
              </Grid>
            </>
          )}
          <Grid item xs={0.5}>
            {updateMutation.isSuccess ? (
              <SuccessIcon />
            ) : (
              updateMutation.isLoading && (
                <Box>
                  <Loader size={'small'} />
                </Box>
              )
            )}
          </Grid>

          {openConfirmationDialog && (
            <ConfirmationModal
              title={`${resultStateAction} Results`}
              content={confirmationModalContent}
              onConfirm={onConfirmUpdate}
              onCancel={() => setOpenConfirmationDialog(false)}
            />
          )}
        </Grid>
      </ListItemButton>
      <Collapse in={expanded}>
        <List
          component="div"
          sx={{
            pl: 2,
          }}
          subheader={<ListSubheader>Slide IDs</ListSubheader>}
        >
          <Divider />
          {map(slidesData, (slideData) => (
            <ListItem key={slideData.slideId}>
              <ListItemText>
                <Link
                  to={getUrlToSlidePage({ slideId: slideData.slideId, labId, caseStudyId: studyId })}
                  target="_blank"
                  rel="noopener noreferrer"
                  component={RouterLink}
                >
                  {slideData.slideId}
                </Link>{' '}
                {(slideData.internallyApproved || slideData.approved) && (
                  <Chip
                    size="small"
                    variant="outlined"
                    color={slideData.approved ? 'primary' : 'secondary'}
                    label={slideData.approved ? 'published' : 'approved'}
                  />
                )}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default OrchestrationView;

const replaceOrchestrationData = (
  orchestrations: Orchestration[],
  ...[updatedData]: Parameters<typeof bulkUpdateResultsByOrchestration>
) => {
  const oldOrchestrationIndex = findIndex(orchestrations, { orchestrationId: updatedData.orchestrationId });
  const newOrchestrationData = {
    ...orchestrations[oldOrchestrationIndex],
    slidesData: map(orchestrations[oldOrchestrationIndex].slidesData, (slideData) => {
      if (updatedData.slideIds.includes(slideData.slideId)) {
        return { ...slideData, ...updatedData.updatedData };
      }
      return slideData;
    }),
  };

  return [
    ...slice(orchestrations, 0, oldOrchestrationIndex),
    newOrchestrationData,
    ...slice(orchestrations, oldOrchestrationIndex + 1),
  ];
};

const publishOnlyApprovedSlidesText = ['Only approved results will be published.'];
const disapprovePublishedSlidesText = [
  'Some slides have published results. Disapproving them will automatically unpublish.',
];
