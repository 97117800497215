import { Button, Grid, Typography } from '@mui/material';
import { AnnotationAssignment } from 'interfaces/annotation';
import React, { useState } from 'react';
import { EditAnnotationAssignmentDialog } from '../AnnotationAssignment/EditAnnotationAssignmentDialog';
import AnnotationAssignmentTable from './AnnotationAssignmentTable';

interface AnnotationAssignmentViewProps {
  annotationAssignment: AnnotationAssignment;
  studyId: string;
}

const AnnotationAssignmentView: React.FC<AnnotationAssignmentViewProps> = ({ annotationAssignment, studyId }) => {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const handleEditClick = () => {
    setEditDialogOpen(true);
  };
  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
  };

  return (
    <>
      <Grid item container direction="column" spacing={1}>
        <Grid item container alignItems="center" spacing={1}>
          <Grid item>
            <Typography variant="h4">{`${annotationAssignment.annotationAssignmentId}- ${annotationAssignment.name}`}</Typography>
          </Grid>
          <Grid item>
            <Button onClick={handleEditClick}>Edit</Button>
          </Grid>
        </Grid>
        <Grid item>
          <AnnotationAssignmentTable studyId={studyId} annotationAssignment={annotationAssignment} />
        </Grid>
      </Grid>

      {editDialogOpen && (
        <EditAnnotationAssignmentDialog
          open={editDialogOpen}
          onClose={handleEditDialogClose}
          annotationAssignmentId={annotationAssignment.annotationAssignmentId}
        />
      )}
    </>
  );
};

export default AnnotationAssignmentView;
