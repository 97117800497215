import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { CircularProgress, IconButton, Link, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { filter, fromPairs, get, keyBy, map } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';

import { prepareCustomerResults } from 'api/customerResults';
import { cancelJob } from 'api/jobs';
import { getInternalUsers } from 'api/userCredentials';
import { Job, JobType } from 'interfaces/job';
import { Permission } from 'interfaces/permissionOption';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { humanize } from 'utils/helpers';
import { useUiSettings } from 'utils/queryHooks/uiConstantsHooks';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { usePermissions } from 'utils/usePermissions';
import { jobsQueryKey } from './useJobs';

export const useJobColumns = ({
  setCurrentJobModal,
  viewOnly,
}: {
  setCurrentJobModal?: (value: { jobType: JobType; jobData: any }) => void;
  viewOnly?: boolean;
}): {
  jobColumns: GridColDef<Job>[];
  isLoading: boolean;
} => {
  const { userId: loggedInUserId } = useAppSelector((state) => state.auth.profile);
  const navigate = useNavigate();

  const { labId, labSearch } = useCurrentLabId();

  const { data: internalUsers, isLoading: isLoadingInternalUsers } = useQuery(['internalUsers'], getInternalUsers);
  const internalUsersById = useMemo(() => keyBy(internalUsers, 'id'), [internalUsers]);

  const { hasPermission, isLoading: isLoadingPermissions } = usePermissions();
  const canContinueJob = hasPermission(Permission.ContinueJob);
  const canCancelAllUsersJobs = hasPermission(Permission.CancelAllUsersJobs);
  const canCancelOwnJobs = hasPermission(Permission.CancelOwnJobs);
  const hasCancelJobPermission = canCancelAllUsersJobs || canCancelOwnJobs;

  const { uiSettings, isLoadingUiSettings } = useUiSettings();

  const jobStatusToDisplayText = fromPairs(
    map(uiSettings?.enumDisplayNames?.['jobStatus'], ({ value, label }) => [value, label]) || []
  );
  const requestSenderToDisplayText = fromPairs(
    map(uiSettings?.enumDisplayNames?.['requestSender'], ({ value, label }) => [value, label]) || []
  );

  const prepareCustomerResultsMutation = useMutationWithErrorSnackbar({
    mutationFn: prepareCustomerResults,
    mutationDescription: 'create customer results',
  });

  const queryClient = useQueryClient();

  const cancelJobMutation = useMutationWithErrorSnackbar({
    onSuccess: () => {
      queryClient.invalidateQueries([jobsQueryKey]);
    },
    mutationFn: cancelJob,
    mutationDescription: 'cancel job',
  });

  const jobColumns: GridColDef<Job>[] = [
    {
      field: 'startedAt',
      headerName: 'Started At',
      flex: 1,
      valueFormatter: (value) => moment(value).format('lll'),
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Type',
      valueFormatter: (_, row) => {
        const type = row.type;
        if (type !== JobType.CalculateFeatures) {
          return humanize(type);
        }
        const isSecondaryAnalysis = Boolean(row?.primaryRunOrchestrationId || row?.params?.primaryRunOrchestrationId);

        return isSecondaryAnalysis ? 'Secondary analysis' : 'Calculate features';
      },
      sortComparator: (_v1, _v2, cellParams1, cellParams2) => {
        const row1: Job = cellParams1.api.getRow(cellParams1.id);
        const row2: Job = cellParams2.api.getRow(cellParams2.id);
        const type1 = row1.type;
        const type2 = row2.type;
        if (type1 !== type2 || type1 !== JobType.CalculateFeatures || type2 !== JobType.CalculateFeatures) {
          return humanize(type1).localeCompare(humanize(type2));
        }
        const isSecondaryAnalysis1 = Boolean(
          row1?.primaryRunOrchestrationId || row1?.params?.primaryRunOrchestrationId
        );
        const isSecondaryAnalysis2 = Boolean(
          row2?.primaryRunOrchestrationId || row2?.params?.primaryRunOrchestrationId
        );
        if (isSecondaryAnalysis1 === isSecondaryAnalysis2) {
          return 0;
        } else if (isSecondaryAnalysis1) {
          return 1;
        } else {
          return -1;
        }
      },
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
    },
    {
      field: 'userId',
      headerName: 'User',
      flex: 1,
      valueFormatter: (value) => internalUsersById[value]?.name ?? internalUsersById[value]?.primaryEmail ?? value,
    },
    {
      field: 'orchestrationId',
      headerName: 'Orchestration Id',
      flex: 1,
    },
    {
      field: 'requestSender',
      headerName: 'Source',
      flex: 1,
      valueFormatter: (value) => requestSenderToDisplayText[value],
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      valueFormatter: (value) => jobStatusToDisplayText[value],
    },
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      editable: false,
      flex: 1,
      cellClassName: 'actions',
      align: 'left',
      getActions: (params) => {
        const type = params.row.type;
        const externalTaskLink = params.row.externalTaskLink;
        const status = params.row.status;
        const jobId = params.row.id;
        const userId = params.row.userId;

        const action = [];

        if (externalTaskLink) {
          action.push(
            <Tooltip placement="top-start" title="Go to clearML" key={`externalTaskLink-${params.id}`}>
              <Link
                color="inherit"
                underline="hover"
                href={externalTaskLink}
                target="_blank"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <IconButton>
                  <OpenInNewIcon />
                </IconButton>
              </Link>
            </Tooltip>
          );
        }

        // If the job has primary run orchestration id, it means it is a child job (secondary analysis) and should not have rebuild option
        if (type === 'calculate_features') {
          const disableRebuild = Boolean(
            params?.row?.primaryRunOrchestrationId || params?.row?.params?.primaryRunOrchestrationId
          );
          action.push(
            <Tooltip
              placement="top"
              title={disableRebuild ? "Can't rebuild secondary analysis job" : 'Rebuild'}
              key={`rebuild-${params.id}`}
            >
              {/* the span is for the tooltip to work on disabled button */}
              <span>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(
                      { pathname: `/calculate-features`, search: `${labSearch}` },
                      { state: { jobId: params.row.id } }
                    );
                  }}
                  disabled={disableRebuild}
                >
                  <RestartAltIcon />
                </IconButton>
              </span>
            </Tooltip>
          );
        }

        if (type === JobType.Inference || type === JobType.MultiplexNormalization) {
          action.push(
            <Tooltip placement="top-start" title="Rebuild" key={`rebuild-${params.id}`}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrentJobModal({ jobType: type, jobData: params.row });
                }}
              >
                <RestartAltIcon />
              </IconButton>
            </Tooltip>
          );
        }

        if (canContinueJob && type === JobType.PrepareCustomerResults && !isLoadingPermissions) {
          action.push(
            <Tooltip placement="top-start" title="Continue" key={`continue-${params.id}`}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  prepareCustomerResultsMutation.mutate({
                    jobId: params.row.id,
                    studyId: params.row.studyId,
                    resultTypesToExport: get(params.row.params, 'resultTypesToExport'),
                    queryObject: get(params.row.params, 'queryObject'),
                    labId,
                  });
                }}
              >
                <ArrowRightIcon />
              </IconButton>
            </Tooltip>
          );
        }

        if (hasCancelJobPermission) {
          const canCancelJob = canCancelAllUsersJobs || (canCancelOwnJobs && userId === loggedInUserId);
          action.push(
            <Tooltip placement="top-start" title="Cancel job" key={`cancel-${params.id}`}>
              <span>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    cancelJobMutation.mutate(jobId);
                  }}
                  disabled={
                    !(status === 'running') || !canCancelJob || !externalTaskLink || cancelJobMutation.isLoading
                  }
                >
                  {cancelJobMutation.isLoading && cancelJobMutation.variables === jobId ? (
                    <CircularProgress size={20} />
                  ) : (
                    <CloseIcon />
                  )}
                </IconButton>
              </span>
            </Tooltip>
          );
        }

        return action;
      },
    },
  ];

  return {
    jobColumns: viewOnly ? filter(jobColumns, (column) => column.field !== 'actions') : jobColumns,
    isLoading: isLoadingInternalUsers || isLoadingPermissions || isLoadingUiSettings,
  };
};
