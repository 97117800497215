import { ExperimentResult } from 'interfaces/experimentResults';
import { filter, groupBy, map, partition, size, some, uniqBy } from 'lodash';
import {
  experimentResultToGroup,
  FeatureMetadata,
  FeatureMetadataBeforeSecondaryAnalysisGrouping,
  parseBaseFeatureMetadataFromExperimentResult,
} from './featureMetadata';
import { groupPrimaryResultsWithSecondaryResults, isParsedSecondaryAnalysisRun } from './secondaryAnalysis';

export const parseFeatures = (
  slideResults: ExperimentResult[]
): {
  publishedFeatures: FeatureMetadata[];
  internalFeatures: { [key: string]: FeatureMetadata[] };
  deletedFeatures: FeatureMetadata[];
} => {
  const resultsWithFeatures = filter(slideResults, (result) => result.numFeatures > 0);

  // we filter out duplicate orchestration ids to avoid showing the same results twice
  // we do this to avoid a bug when calculated results where saved each heatmap in a different experiment result (all with the same features)
  const slideUniqOrchIdResults = uniqBy(resultsWithFeatures, 'orchestrationId');

  if (size(slideUniqOrchIdResults) !== size(resultsWithFeatures)) {
    const duplicateOrchIds = groupBy(
      filter(
        resultsWithFeatures,
        (result) => size(filter(resultsWithFeatures, { orchestrationId: result.orchestrationId })) > 1
      ),
      'orchestrationId'
    );
    console.warn('Found duplicate orchestration ids in slide feature results', duplicateOrchIds);
  }

  const allFeaturesResults: FeatureMetadataBeforeSecondaryAnalysisGrouping[] = map(slideUniqOrchIdResults, (er) =>
    parseBaseFeatureMetadataFromExperimentResult(er, 'parseFeatures-')
  );

  const allFinalResults = groupPrimaryResultsWithSecondaryResults(allFeaturesResults);

  const [deletedFeatures, finalFeatures] = partition(
    allFinalResults,
    // Hidden secondary results are handled separately
    (result) =>
      isParsedSecondaryAnalysisRun(result) && Boolean(result.deletedAt) && Boolean(result.primaryRunOrchestrationId)
  );

  // A feature is considered published if it's approved or if any of its secondary results are published
  const [publishedFeatures, unpublishedFeatures] = partition(finalFeatures, (feature) => {
    const featureOrchestrationId = feature.orchestrationId;
    const runExperimentResults = filter(
      slideResults,
      (result) =>
        result.orchestrationId === featureOrchestrationId || result.primaryRunOrchestrationId === featureOrchestrationId
    );
    const hasPublishedResult = some(runExperimentResults, 'approved');
    return hasPublishedResult;
  });

  const internalFeatures = groupBy(
    filter(unpublishedFeatures, (feature) =>
      Boolean(!feature.approved && (feature.resultType || feature.flowClassName))
    ),
    experimentResultToGroup
  );

  return { publishedFeatures, internalFeatures, deletedFeatures };
};
