import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Grid, Tooltip } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import React, { useCallback, useState } from 'react';

import { NormalizationResult } from 'interfaces/experimentResults';
import { useSelectChannel } from '../colorSettings';
import { ChannelMarkerTypeChoice } from './ChannelMarkerTypeChoice';
import { MainSettings } from './MainSettings';
import { MultiplexChannelColor } from './MultiplexChannelColor';
import { MultiplexDynamicRange } from './MultiplexDynamicRange';
import { MultiplexGamma } from './MultiplexGamma';

interface Props {
  markerType: string;
  draftAssignedMarkers: string[];
  channelId: string;
  channelName: string;
  editChannelMarkerMode: boolean;
  draftMarkerType: string;
  selected: boolean;
  disabled?: boolean;
  disabledReason?: string;
  slideId: string;
  viewerIndex: number;
  encoding: string;
  channelHistogram?: number[];
  normalizationData?: NormalizationResult;
  handleChannelMarkerTypeChange(channel: string, value: string): void;
  resetChannelNormalizationSettings: (channelIndex: number, markerType?: string) => void;
}

const MultiplexChannelControl: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  draftAssignedMarkers,
  channelId,
  channelName,
  editChannelMarkerMode,
  draftMarkerType,
  markerType: dbMarkerType,
  selected,
  disabled = false,
  disabledReason,
  slideId,
  viewerIndex,
  encoding,
  channelHistogram,
  normalizationData,
  handleChannelMarkerTypeChange,
  resetChannelNormalizationSettings,
}) => {
  useSignals();
  const markerType = editChannelMarkerMode ? draftMarkerType : dbMarkerType;
  const [expand, setExpand] = useState(false);
  const toggleExpand = useCallback(() => setExpand((prev) => !prev), []);

  const { handleSelectChannel } = useSelectChannel();

  return (
    <Grid
      item
      container
      wrap="nowrap"
      alignItems="center"
      direction="column"
      pl={1}
      onClick={!editChannelMarkerMode ? toggleExpand : undefined}
    >
      <Grid container wrap="nowrap" alignItems="center" paddingInline={1} sx={{ cursor: 'pointer' }}>
        <Grid item md={2}>
          <Tooltip title={disabledReason || ''} arrow>
            <span>
              <Checkbox
                checked={selected}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const newSelected = Boolean(event?.target?.checked);
                  handleSelectChannel(viewerIndex, slideId, channelId, newSelected, markerType);
                }}
                disabled={disabled}
                sx={{ p: 0 }}
              />
            </span>
          </Tooltip>
        </Grid>
        <MultiplexChannelColor
          selected={selected}
          viewerIndex={viewerIndex}
          slideId={slideId}
          channelId={channelId}
          markerType={markerType}
        />
        {editChannelMarkerMode ? (
          <ChannelMarkerTypeChoice
            channelId={channelId}
            channelName={channelName}
            markerType={markerType}
            assignedMarkers={draftAssignedMarkers}
            previousMarkerAssignment={dbMarkerType}
            handleChannelMarkerTypeChange={handleChannelMarkerTypeChange}
          />
        ) : (
          <MainSettings
            channelId={channelId}
            channelName={channelName}
            markerType={markerType}
            selected={selected}
            viewerIndex={viewerIndex}
            slideId={slideId}
          />
        )}
      </Grid>
      <Grid width="100%" pl={1}>
        <Accordion disableGutters expanded={expand && !editChannelMarkerMode} square>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon style={editChannelMarkerMode ? { opacity: 0.3 } : undefined} fontSize="small" />
            }
            sx={{ paddingInlineStart: 0, paddingInlineEnd: 1 }}
          >
            <MultiplexDynamicRange
              viewerIndex={viewerIndex}
              encoding={encoding}
              slideId={slideId}
              channelId={channelId}
              selected={selected}
              resetChannelNormalizationSettings={resetChannelNormalizationSettings}
              channelHistogram={channelHistogram}
              normalizationData={normalizationData}
              markerType={markerType}
            />
          </AccordionSummary>
          <AccordionDetails sx={{ paddingInline: 1 }}>
            <Grid container wrap="nowrap" alignItems="center" direction="column">
              <MultiplexGamma
                viewerIndex={viewerIndex}
                slideId={slideId}
                channelId={channelId}
                selected={selected}
                markerType={markerType}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default MultiplexChannelControl;
