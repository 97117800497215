import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Autocomplete, Grid, TextField, useTheme } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { ExperimentResult } from 'interfaces/experimentResults';
import { Permission } from 'interfaces/permissionOption';
import { filter, find, first, forEach, isNumber, keys, map, orderBy, size, some } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import {
  getActiveSecondaryAnalysis,
  useActiveSecondaryAnalysisStates,
} from 'utils/useGetSecondaryAnalysisJobForHeatmaps';
import { usePermissions } from 'utils/usePermissions';
import {
  LayerVisualizationChange,
  slidesLayerVisualizationSettings,
  useApplyChangesToSlideLayerVisualizationSettings,
} from '../../slidesVisualizationAndConfiguration';

export const useActiveResultForPrimaryOrchestrationId = ({
  primaryRunOrchestrationId,
  slideId,
  experimentResults,
  viewerIndex,
  debug,
}: {
  primaryRunOrchestrationId: string;
  slideId: string;
  experimentResults: ExperimentResult[];
  viewerIndex: number;
  debug?: boolean;
}) => {
  const primaryRunResult = find(experimentResults, { orchestrationId: primaryRunOrchestrationId });

  if (primaryRunResult?.orchestrationId !== primaryRunOrchestrationId) {
    console.error('Orchestration ID mismatch', {
      primaryRunResult,
      primaryRunOrchestrationId,
      slideId,
      viewerIndex,
      experimentResults,
    });
  }

  const isSecondaryAnalysis = Boolean(primaryRunResult);

  const [activeSecondaryAnalysisStates] = useActiveSecondaryAnalysisStates();

  const experimentResultsInRun = useMemo(
    () =>
      filter(
        experimentResults,
        (result) =>
          result.orchestrationId === primaryRunOrchestrationId ||
          result.primaryRunOrchestrationId === primaryRunOrchestrationId
      ),
    [experimentResults, primaryRunOrchestrationId]
  );

  const firstResult = first(experimentResultsInRun);
  const activeResult =
    isSecondaryAnalysis && primaryRunResult
      ? getActiveSecondaryAnalysis({
          slideId,
          viewerIndex,
          activeSecondaryAnalysisStates,
          primaryRunOrchestrationId,
          experimentResultsInRun,
        })
      : firstResult;

  const orchestrationId = activeResult?.orchestrationId;

  if (
    primaryRunOrchestrationId &&
    primaryRunOrchestrationId !== orchestrationId &&
    primaryRunOrchestrationId !== activeResult?.primaryRunOrchestrationId
  ) {
    console.warn(
      `Slide ${slideId}, primaryRunOrchestrationId ${primaryRunOrchestrationId} ` +
        `does not match the active result's orchestrationId ${orchestrationId} or primaryRunOrchestrationId ${activeResult?.primaryRunOrchestrationId}.`,
      {
        slideId,
        primaryRunOrchestrationId,
        orchestrationId,
        viewerIndex,
        activeResult,
        primaryRunResult,
        deletedAt: activeResult?.deletedAt,
        deletedBy: activeResult?.deletedBy,
      }
    );
  }

  const createdAt = activeResult?.createdAt;

  const publishingStatus = { published: activeResult?.approved, internallyApproved: activeResult?.internallyApproved };

  if (debug) {
    console.debug({
      slideId,
      primaryRunOrchestrationId,
      orchestrationId,
      createdAt,
      publishingStatus,
      viewerIndex,
      activeResult,
      primaryRunResult,
      experimentResultsInRun,
    });
  }

  return {
    orchestrationId,
    isSecondaryAnalysis,
    createdAt,
    publishingStatus,
    primaryRunResult,
    experimentResultsInRun,
    activeResult,
    deletedAt: activeResult?.deletedAt,
    deletedBy: activeResult?.deletedBy,
  };
};

export const getExperimentResultLabel = (
  result: Pick<
    ExperimentResult,
    'primaryRunOrchestrationId' | 'createdAt' | 'orchestrationId' | 'deletedAt' | 'deletedBy'
  >,
  canSeeOrchestrationId: boolean,
  primaryJobName?: string
) => {
  const createdAt = moment(result?.createdAt).format('YYYY-MM-DD HH:mm');
  const orchestrationId = canSeeOrchestrationId && result?.orchestrationId;
  const deletedAt = result?.deletedAt ? moment(result?.deletedAt).format('YYYY-MM-DD HH:mm') : undefined;
  return `${result?.primaryRunOrchestrationId ? 'Recalculated' : 'Primary'} - ${createdAt}${
    primaryJobName ? ` - ${primaryJobName}` : ''
  }${orchestrationId ? ` - ${orchestrationId}` : ''}${
    deletedAt ? ` (hidden on ${deletedAt}${result?.deletedBy ? ` by ${result?.deletedBy}` : ''})` : ''
  }`;
};

export const ActiveSecondaryAnalysisResultsSelect: React.FC<{
  activeExperimentResultId?: number;
  experimentResultsInRun?: ExperimentResult[];
  primaryRunOrchestrationId?: string;
  primaryJobName?: string;
  slideId: string;
  stainTypeId: string;
  viewerIndex: number;
  internalFeatures?: boolean;
}> = ({
  activeExperimentResultId,
  experimentResultsInRun,
  primaryRunOrchestrationId,
  slideId,
  viewerIndex,
  stainTypeId,
  internalFeatures,
  primaryJobName,
}) => {
  useSignals();
  const theme = useTheme();

  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex]?.value;
  const { hasPermission } = usePermissions();
  const canSeeOrchestrationId = hasPermission(Permission.SeeOrchestrationId);
  const [activeSecondaryAnalysisStates, setActiveSecondaryAnalysisStates] = useActiveSecondaryAnalysisStates();
  const currentResult = find(experimentResultsInRun, { experimentResultId: activeExperimentResultId });
  const hasDeletedResults = Boolean(some(experimentResultsInRun, 'deletedAt'));
  const resultsByCreatedAt = React.useMemo(
    () => orderBy(experimentResultsInRun, ['createdAt'], ['desc']),
    [experimentResultsInRun]
  );
  const resultOptions = React.useMemo(
    () =>
      map(resultsByCreatedAt, (result) => ({
        value: result?.experimentResultId,
        label: getExperimentResultLabel(result, canSeeOrchestrationId, primaryJobName),
        deleted: Boolean(result?.deletedAt),
      })),
    [resultsByCreatedAt, primaryJobName, canSeeOrchestrationId]
  );

  const applyChangesToSlideLayerVisualizationSettings = useApplyChangesToSlideLayerVisualizationSettings();

  // Switch the heatmap settings to the new experiment result
  const switchHeatmapSettings = (experimentResultId: number) => {
    const previousHeatmapExperimentResultId = currentResult?.experimentResultId ?? activeExperimentResultId;

    if (!isNumber(previousHeatmapExperimentResultId) || isNaN(previousHeatmapExperimentResultId)) {
      console.warn(
        `Slide ${slideId}, viewerIndex ${viewerIndex} has an invalid experimentResultId ${previousHeatmapExperimentResultId}.`
      );
    }

    const previousExperimentResultIdPartInKey = `[${previousHeatmapExperimentResultId}]`;

    const previousHeatmapIds = filter(keys(viewerSlideLayerVisualizationSettings?.[slideId]), (key) =>
      key?.includes(previousExperimentResultIdPartInKey)
    );

    const changes: LayerVisualizationChange[] = [];

    forEach(previousHeatmapIds, (previousHeatmapId) => {
      const newHeatmapId = previousHeatmapId.replace(previousExperimentResultIdPartInKey, `[${experimentResultId}]`);
      changes.push({
        layerId: newHeatmapId,
        newSettings: viewerSlideLayerVisualizationSettings?.[slideId]?.[previousHeatmapId]?.value,
      });
      changes.push({
        layerId: previousHeatmapId,
        newSettings: { show: false },
      });
    });

    applyChangesToSlideLayerVisualizationSettings({
      slideId,
      viewerIndex,
      changes,
      stainTypeId,
      changeFlow: 'secondary analysis select',
    });
  };

  return (
    size(resultOptions) > 1 && (
      <Grid
        item
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Autocomplete
          sx={
            !internalFeatures
              ? {
                  backgroundColor: theme.palette.primary.main,
                  '& .MuiAutocomplete-inputRoot .MuiAutocomplete-endAdornment svg': {
                    color: 'white',
                  },
                  '& .MuiInput-root.MuiAutocomplete-inputRoot input': {
                    color: 'white',
                    paddingInlineStart: '4px',
                  },
                }
              : undefined
          }
          value={find(resultOptions, { value: currentResult?.experimentResultId ?? -1 })}
          onChange={(event, newValue) => {
            setActiveSecondaryAnalysisStates({
              ...activeSecondaryAnalysisStates,
              [slideId]: {
                ...activeSecondaryAnalysisStates?.[slideId],
                [viewerIndex]: {
                  ...activeSecondaryAnalysisStates?.[slideId]?.[viewerIndex],
                  [primaryRunOrchestrationId || currentResult.orchestrationId]: newValue?.value,
                },
              },
            });
            switchHeatmapSettings(newValue?.value ?? currentResult?.experimentResultId);
          }}
          renderInput={(inputParams) => <TextField {...inputParams} fullWidth size="small" variant="standard" />}
          getOptionLabel={(option) => option.label}
          options={resultOptions}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          groupBy={hasDeletedResults ? (option) => (option.deleted ? 'Deleted' : 'Available') : undefined}
          clearIcon={<RestartAltIcon />}
          clearText={internalFeatures ? 'Return to approved results' : 'Return to published results'}
        />
      </Grid>
    )
  );
};
