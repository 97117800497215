import { Grid, TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import LabelledInput from 'components/atoms/LabelledInput/LabelledInput';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { StudiesDashboardFiltersFields } from './useStudyFiltersForm';

const StudyFilters: React.FunctionComponent<
  React.PropsWithChildren<{
    formMethods: UseFormReturn<StudiesDashboardFiltersFields, any>;
  }>
> = ({ formMethods }) => {
  const { startDate, endDate, text } = formMethods.watch();

  const setStartDate = (date: Date | null) => {
    formMethods.setValue('startDate', date, { shouldValidate: true });
  };

  const setEndDate = (date: Date | null) => {
    formMethods.setValue('endDate', date, { shouldValidate: true });
  };

  const setText = (value: string) => {
    formMethods.setValue('text', value);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // If the user presses backspace and the text is shorter than 4 characters, clear the text
    // TODO: is this still needed?
    if (event.key === 'Backspace' && Boolean(text) && text.length < 4) {
      setText('');
    }
  };

  const onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const onReset = () => {
    setText('');
  };

  return (
    <Grid item container alignItems="center" spacing={2}>
      <Grid item>
        <LabelledInput
          label="Search"
          value={text}
          onChange={onTextChange}
          dataTestClass="input-text-filter"
          onReset={onReset}
          onKeyDown={onKeyDown}
        />
      </Grid>
      <Grid item>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            label="Start Date"
            inputFormat="DD/MM/YYYY"
            value={startDate}
            onChange={setStartDate}
            renderInput={(params) => (
              <TextField
                {...params}
                error={params.error || (formMethods.formState.isDirty && !formMethods.formState.isValid)}
              />
            )}
            className="date-picker-start"
            mask={'__/__/____'}
          />
        </LocalizationProvider>
      </Grid>

      <Grid item>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            label="End Date"
            inputFormat="DD/MM/YYYY"
            value={endDate}
            onChange={setEndDate}
            renderInput={(params) => (
              <TextField
                {...params}
                error={params.error || (formMethods.formState.isDirty && !formMethods.formState.isValid)}
              />
            )}
            className="date-picker-end"
            mask={'__/__/____'}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
};

export default StudyFilters;
