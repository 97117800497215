import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import React from 'react';

interface AlertDialogProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  message: string;
  severity?: 'error' | 'warning' | 'info' | 'success';
}

const AlertDialog: React.FC<AlertDialogProps> = ({ open, onClose, title, message, severity = 'info' }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      <DialogContent>
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert severity={severity}>{message}</Alert>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
