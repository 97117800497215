import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Autocomplete, Chip, CircularProgress, FormControl, FormHelperText, Grid, TextField } from '@mui/material';
import { AnnotationAssignment } from 'interfaces/annotation';
import { compact, filter, find, flatMap, isEmpty, map, uniq } from 'lodash';
import React from 'react';
import { useModelTypeOptions } from 'utils/queryHooks/useModelTypeOptions';
import { useAreaTypeIdToDisplayName } from 'utils/useAreaTypeIdToDisplayName';
import { SelectedAssignmentDetails } from '..';
import ClassesToUseIgnoreRadioButtons from './ClassesToUseIgnoreRadio';

export interface AssignmentStainProps {
  assignmentAnnotations: AnnotationAssignment[];
  selectedAssignment: SelectedAssignmentDetails;
  updateSelectedAssignment: (updatedAssignment: SelectedAssignmentDetails) => void;
  removeSelectedAssignment: (assignmentOverrideId: string) => void;
  isDuplicateModelType: boolean;
}

const AssignmentSelection: React.FC<React.PropsWithChildren<AssignmentStainProps>> = ({
  assignmentAnnotations,
  selectedAssignment,
  updateSelectedAssignment,
  removeSelectedAssignment,
  isDuplicateModelType,
}) => {
  const { areaTypeIdToDisplayName, isLoadingAreaTypes } = useAreaTypeIdToDisplayName();

  const {
    data: modelTypes,
    isError: modelTypesError,
    isLoading: modelTypesLoading,
  } = useModelTypeOptions({ retry: false });

  const modelTypesOptions = map(filter(modelTypes, { trainingType: 'segmentation' }), (modelType) => ({
    value: modelType?.type,
    label: modelType?.displayName,
    backgroundClassesToIgnore: modelType?.backgroundClassesToIgnore,
  }));

  const assignmentAnnotationsOptions = map(assignmentAnnotations, (annotation) => ({
    value: annotation?.annotationAssignmentId,
    label: annotation?.name,
  }));

  const selectedAssignmentTodos = uniq(compact(map(flatMap(selectedAssignment?.assignment?.todos, 'options'), 'name')));

  const classOptions =
    map(selectedAssignmentTodos, (todo) => ({
      value: todo,
      label: areaTypeIdToDisplayName(todo),
    })) || [];

  return (
    <Grid container spacing={1} direction="row" alignItems="start">
      <Grid item xs={3}>
        <FormControl
          size="small"
          fullWidth
          variant="standard"
          error={isDuplicateModelType}
          className={isDuplicateModelType ? 'error' : undefined}
        >
          <Autocomplete
            size="small"
            id="modelType"
            options={modelTypesOptions}
            value={find(modelTypesOptions, (modelType) => modelType?.value === selectedAssignment?.modelType) || null}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Override Model Type"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {modelTypesLoading && <CircularProgress color="inherit" size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                helperText={'keep empty to use assignment as input'}
              />
            )}
            onChange={(event, option) => {
              updateSelectedAssignment({
                ...selectedAssignment,
                modelType: option?.value,
                classesToUse: [],
                classesToIgnore: [],
              });
            }}
          />
          {isDuplicateModelType ? <FormHelperText error>Duplicate model type</FormHelperText> : ''}
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <Autocomplete
          size="small"
          id="assignment"
          options={assignmentAnnotationsOptions}
          value={
            find(
              assignmentAnnotationsOptions,
              (assignmentAnnotation) =>
                assignmentAnnotation?.value === selectedAssignment?.assignment?.annotationAssignmentId
            ) || null
          }
          renderInput={(params) => (
            <TextField {...params} required error={isEmpty(selectedAssignment?.assignment)} label="Assignment" />
          )}
          onChange={(event, option) => {
            updateSelectedAssignment({
              ...selectedAssignment,
              assignment: find(
                assignmentAnnotations,
                (assignmentAnnotation) => assignmentAnnotation?.annotationAssignmentId === option?.value
              ),
              classesToUse: [],
              classesToIgnore: [],
            });
          }}
        />
      </Grid>
      <Grid item xs={2.5}>
        <ClassesToUseIgnoreRadioButtons
          isToIgnore={selectedAssignment?.useClassesFromIgnore}
          updateIsToIgnore={(isToIgnore) => {
            updateSelectedAssignment({
              ...selectedAssignment,
              useClassesFromIgnore: isToIgnore,
            });
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <Autocomplete
          disabled={isEmpty(selectedAssignment.assignment)}
          size="small"
          multiple
          disableCloseOnSelect
          limitTags={1}
          options={classOptions}
          value={
            map(
              selectedAssignment?.useClassesFromIgnore
                ? selectedAssignment?.classesToIgnore
                : selectedAssignment?.classesToUse,
              (selectedClass) => find(classOptions, (classOption) => classOption?.value == selectedClass)
            ) || []
          }
          renderTags={(tagValue, getTagProps) =>
            map(tagValue, (option, index) => {
              const { key, ...tagProps } = getTagProps({ index });
              return <Chip key={key} label={option.label} {...tagProps} />;
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={selectedAssignment?.useClassesFromIgnore ? 'Classes To Ignore' : 'Classes To Use'}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoadingAreaTypes && <CircularProgress color="inherit" size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          onChange={(event, option) =>
            updateSelectedAssignment({
              ...selectedAssignment,
              [selectedAssignment?.useClassesFromIgnore ? 'classesToIgnore' : 'classesToUse']: uniq(
                map(option, 'value')
              ),
            })
          }
        />
      </Grid>
      <Grid item xs={0.5}>
        <DeleteOutlineIcon
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => removeSelectedAssignment(selectedAssignment.id)}
        />
      </Grid>
    </Grid>
  );
};

export default AssignmentSelection;
