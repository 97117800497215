import { Box, darken, lighten, styled } from '@mui/system';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRenderEditCellParams,
  GridRowClassNameParams,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { useCommonDataGridStyles } from 'components/atoms/BaseDataGrid/commonStyles';
import { CellRulePanelWithRules, CellRuleValue, MinimalCellRule } from 'interfaces/cellRule';
import { compact, concat, every, filter, find, findIndex, forEach, map, slice } from 'lodash';
import React, { useMemo } from 'react';
import { useStainTypeOptions } from 'utils/queryHooks/uiConstantsHooks';
import { useCellTypeOptions } from 'utils/queryHooks/useCellTypeOptions';
import CellRuleDisplay from './CellRuleGridCell/CellRuleDisplay';
import CellRuleDropdown from './CellRuleGridCell/CellRuleDropdown';
import CellRuleLegend from './CellRuleLegend';
import CellRuleSelectionToolbar, { CellRuleSelectionToolbarProps } from './CellRuleTableSelectionToolbar';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .row-warning': {
    backgroundColor: theme.palette.mode == 'light' ? theme.palette.warning.light : theme.palette.warning.dark,
    '&:hover': {
      backgroundColor:
        theme.palette.mode == 'light'
          ? darken(theme.palette.warning.light, 0.1)
          : lighten(theme.palette.warning.dark, 0.1),
    },
  },
  '& .sticky-left': {
    position: 'sticky',
    left: 0,
    zIndex: 4,
    backgroundColor: theme.palette.background.paper,
  },
  '& .vertical-header .MuiDataGrid-columnHeaderTitleContainerContent': {
    overflow: 'visible',
  },
  '& .selected-cell': {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? lighten(theme.palette.primary.main, 0.3)
        : darken(theme.palette.primary.main, 0.3),
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
}));

declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides extends CellRuleSelectionToolbarProps {}
}

interface CellRulesDataGridProps {
  panelId: string;
  stainTypeIds: string[];
  cellTypeIds: string[];
  rules: MinimalCellRule[];
  isLoading: boolean;
  editable?: boolean;
  // only if editable is true
  onCellValueChange?: (cellTypeId: string, stainTypeId: string, value: CellRuleValue) => void;
  onRemoveStainType?: (stainTypeId: string) => void;
  onRemoveCellType?: (cellTypeId: string) => void;
  selectedCells?: MinimalCellRule[];
  onSelectCellRules?: (rules: MinimalCellRule[]) => void;
  onSelectionFinished?: () => void;
  isCompact?: boolean;
  handleShowCompactToggle?: (isCompacting: boolean) => void;
}

export const cellRuleWidth = 30;

const rulesToTable = (rules: CellRulePanelWithRules['rules'], stainTypeIds: string[], cellTypeIds: string[]) => {
  if (rules === undefined) return {};
  let table: Record<string, Record<string, number>> = {};

  forEach(cellTypeIds, (cellTypeId) => {
    table[cellTypeId] = {};
    forEach(stainTypeIds, (stainTypeId) => {
      table[cellTypeId][stainTypeId] = CellRuleValue.Indifferent;
    });
  });

  forEach(rules, (rule) => {
    const { cellTypeId, stainTypeId, ruleValue } = rule;
    if (!table[cellTypeId]) {
      table[cellTypeId] = {};
    }
    table[cellTypeId][stainTypeId] = ruleValue;
  });

  return table;
};

const CellRulesDataGrid: React.FC<CellRulesDataGridProps> = ({
  panelId,
  stainTypeIds,
  cellTypeIds,
  rules,
  isLoading,
  editable = false,
  onCellValueChange,
  onRemoveStainType,
  onRemoveCellType,
  selectedCells,
  onSelectCellRules,
  onSelectionFinished,
  isCompact,
  handleShowCompactToggle,
}) => {
  const table = useMemo(() => rulesToTable(rules, stainTypeIds, cellTypeIds), [rules, stainTypeIds, cellTypeIds]);
  const { data: cellTypesOptions } = useCellTypeOptions();
  const { stainTypeOptions } = useStainTypeOptions();

  const commonDataGridStyles = useCommonDataGridStyles();

  const rows = useMemo(
    () =>
      map(cellTypeIds, (cellTypeId) => {
        const row = { id: cellTypeId, ...table[cellTypeId] };
        return row;
      }),
    [cellTypeIds, table]
  );

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Cell Type',
      width: 150,
      editable: false,
      renderCell: (params) => {
        const cellType = find(cellTypesOptions, (cellTypeOption) => cellTypeOption.id === params.value);

        const displayName = cellType?.displayName || params.value;

        return (
          <Box
            onMouseUp={(event) => {
              // if middle mouse button is clicked, we want to delete the stain type
              if (event.button === 1 && editable && onRemoveCellType) {
                onRemoveCellType(params.value);
              }
            }}
          >
            {displayName}
          </Box>
        );
      },
      headerClassName: 'sticky-left',
      cellClassName: 'sticky-left',
    },
    ...compact(
      map(stainTypeIds, (stainTypeId) => {
        const stainType = find(stainTypeOptions, (stainTypeOption) => stainTypeOption.id === stainTypeId);
        if (!stainType) {
          console.error(`StainType with id ${stainTypeId} not found`);
          return;
        }
        return {
          field: stainType.id,
          sortable: false,
          editable,
          headerName: stainType.displayName,
          headerAlign: 'center',
          align: 'center',
          width: cellRuleWidth,
          resizable: false,
          renderEditCell: (params: GridRenderEditCellParams) => (
            <CellRuleDropdown
              cellRule={{
                cellRulePanelId: panelId,
                cellTypeId: params.row.id as string,
                stainTypeId: stainType.id,
                ruleValue: params.value as CellRuleValue,
                id: `${panelId}-${params.row.id}-${stainType.id}`,
              }}
              onChange={onCellValueChange}
            />
          ),
          renderCell: (params) => (
            <Box width={cellRuleWidth}>
              <CellRuleDisplay ruleValue={params.value as CellRuleValue} />
            </Box>
          ),
          // the custom header is so the text is rotated 90 degrees, this allows for a more compact data grid display
          renderHeader: () => (
            <Box
              onMouseDown={(event) => {
                // if middle mouse button is clicked, we want to delete the stain type
                if (event.button === 1 && editable && onRemoveStainType) {
                  onRemoveStainType?.(stainType.id);
                }
              }}
              sx={{
                transform: 'translateY(25%) rotate(-90deg)',
                width: '100%',
                height: cellRuleWidth,
                overflow: 'visible',
              }}
            >
              {stainType.displayName}
            </Box>
          ),
          headerClassName: 'vertical-header',
        } as GridColDef;
      })
    ),
  ];

  const getRowClassName = (params: GridRowClassNameParams<GridValidRowModel>) => {
    // if there are no rules for this cell type, we want to highlight it
    const rowRules = map(stainTypeIds, (stainTypeId) => params.row[stainTypeId]);
    if (every(rowRules, (value) => value === CellRuleValue.Indifferent)) {
      return 'row-warning';
    }
  };

  const getCellClassName = (params: GridCellParams) => {
    const cellTypeId = params.row.id as string;
    const stainTypeId = params.field as string;
    if (selectedCells && find(selectedCells, { cellTypeId, stainTypeId })) {
      return 'selected-cell';
    }
  };

  const allowSelect = Boolean(onSelectCellRules) && Boolean(selectedCells);

  const handleCellClick = (params: GridCellParams) => {
    if (!allowSelect) return;

    const cellTypeId = params.row.id as string;
    const stainTypeId = params.field as string;
    const ruleIndex = findIndex(selectedCells, { cellTypeId, stainTypeId });

    if (ruleIndex > -1) {
      onSelectCellRules([...slice(selectedCells, 0, ruleIndex), ...slice(selectedCells, ruleIndex + 1)]);
    } else {
      const rule = find(rules, { cellTypeId, stainTypeId });
      const ruleToAdd = rule ? rule : { cellTypeId, stainTypeId, ruleValue: CellRuleValue.Indifferent };
      // add the cell
      onSelectCellRules([...selectedCells, ruleToAdd]);
    }
  };

  const handleSelectRuleValue = (ruleValue: CellRuleValue) => {
    if (!allowSelect) return;

    const cellsWithSelectedRuleValue = filter(rules, { ruleValue });
    if (every(cellsWithSelectedRuleValue, (cell) => find(selectedCells, cell))) {
      // if all cells with the selected rule value are already selected, we want to deselect them
      const finalSelectedCells = filter(selectedCells, (cell) => !find(cellsWithSelectedRuleValue, cell));
      onSelectCellRules(finalSelectedCells);
    } else {
      // if not all cells with the selected rule value are selected, we want to select them
      const finalSelectedCells = concat(selectedCells, cellsWithSelectedRuleValue);
      onSelectCellRules(finalSelectedCells);
    }
  };

  const slots = {
    footer: CellRuleLegend,
    ...(allowSelect ? { toolbar: CellRuleSelectionToolbar } : {}),
  };

  const slotProps = {
    ...(allowSelect
      ? {
          toolbar: {
            onSelectRuleValue: handleSelectRuleValue,
            finishSelectionText: 'Add to assignment',
            onSelectionFinished,
            isCompact,
            handleShowCompactToggle,
          },
        }
      : {}),
  };

  return (
    <StyledDataGrid
      disableVirtualization
      rows={rows}
      columns={columns}
      slots={slots}
      slotProps={slotProps}
      getRowClassName={getRowClassName}
      getCellClassName={getCellClassName}
      columnHeaderHeight={200}
      loading={isLoading && Boolean(panelId)}
      disableColumnMenu
      disableColumnFilter
      disableColumnSelector
      disableRowSelectionOnClick
      density="compact"
      onCellClick={handleCellClick}
      sx={{ ...commonDataGridStyles }}
    />
  );
};

export default CellRulesDataGrid;
