import { Viewport } from '@deck.gl/core/typed';
import { DeckGLProps } from '@deck.gl/react/typed';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Divider, ListItemIcon, ListItemText, MenuItem, Paper, Popover } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import centroid from '@turf/centroid';
import { Feature } from '@turf/helpers';
import { cloneDeep, filter, find, findIndex, first, includes, size } from 'lodash';
import React from 'react';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';

import { FeatureSizeSection } from 'components/FeatureSizeSection';
import HtmlDeckGLOverlay from 'components/HtmlDeckGLOverlay';
import HtmlDeckGLOverlayItem from 'components/HtmlDeckGLOverlay/HtmlDeckGLOverlayItem';
import { SlideAnnotationEditType } from 'components/Procedure/Header/SlideInteractionMenu/SlideAnnotationTools/options';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { ImagePyramid } from 'components/Procedure/useSlideImages';
import { Permission } from 'interfaces/permissionOption';
import { getActiveAnnotationAssignmentIdViewerKey } from 'services/annotations/useAnnotationQueryParams';
import { usePermissions } from 'utils/usePermissions';
import { viewerClickData } from '../../../viewerDataSignals';
import { useActiveAnnotationDraft } from '../useActiveAnnotationDraft';
import { ActionTypes, useAnnotationsStoreWithUndo } from '../useAnnotationsWithUndo';
import { DiagnosisSelect } from './AnnotationOptionsMenu';
import { StickyMenuItems } from './StickyMenuItems';

const DEFAULT_COORDINATES = [0, 0] as [number, number];

export interface TaggedItem extends DiagnosisItem {
  isPoint?: boolean;
}

export interface DiagnosisItem {
  text: string;
  diagnosis: string;
  color: string;
  positive: boolean;
  selected: boolean;
  icon?: React.ReactNode;
}

export interface StickyItem {
  text: string;
  icon: React.ReactNode;
  menuAction: () => void;
  color?: any; // TODO: PropTypes.Color;
}

export const AnnotationsContextMenu: React.FC<{
  slide: SlideWithChannelAndResults;
  baseImagePyramids: ImagePyramid;
  viewport: Viewport;
  rightClickInfo?: Parameters<DeckGLProps['onClick']>[0];
  locked?: boolean;
  isSelectFeaturesOn?: boolean;
  // TODO: is this necessary?
  isOnlyNonCanvasEditingEnabled?: boolean;
}> = ({
  slide,
  viewport,
  baseImagePyramids,
  rightClickInfo,
  locked,
  isSelectFeaturesOn,
  isOnlyNonCanvasEditingEnabled,
}) => {
  useSignals();
  const [editAnnotationsMode] = useQueryParam('editAnnotationsMode', StringParam);
  const { activeAnnotationData, slideAnnotations } = useActiveAnnotationDraft({
    slideId: slide?.id,
    viewerIndex: slide?.viewerIndex,
  });
  const annotationFeatures = activeAnnotationData?.features;

  const unassignedFeatureIndex = findIndex(annotationFeatures, (feature) => !feature?.properties?.diagnosis);

  const unassignedFeature = unassignedFeatureIndex >= 0 ? annotationFeatures[unassignedFeatureIndex] : undefined;

  const rightClickFeatureIndex = rightClickInfo?.index;
  const selectedFeatureIndexes = (rightClickInfo?.layer?.props as any)?.selectedFeatureIndexes;
  const rightClickFeatureIndexIsInSelectedFeatures = includes(selectedFeatureIndexes, rightClickFeatureIndex);
  const updateBulkSelection =
    editAnnotationsMode === SlideAnnotationEditType.Select && rightClickFeatureIndexIsInSelectedFeatures;

  const rightClickFeature = !isNaN(rightClickFeatureIndex) ? annotationFeatures[rightClickFeatureIndex] : undefined;

  const contextMenuFeatureIndexes: number[] = unassignedFeature
    ? [unassignedFeatureIndex]
    : updateBulkSelection
    ? selectedFeatureIndexes
    : [rightClickFeatureIndex];

  const featureForContextMenu = (unassignedFeature || rightClickFeature) as Feature;

  const featureCoordinates = featureForContextMenu
    ? (centroid(featureForContextMenu)?.geometry?.coordinates as [number, number])
    : undefined;

  const { hasPermission } = usePermissions();
  const canAnnotateSlides = hasPermission(Permission.AnnotateSlides);

  const featureAnnotationId = featureForContextMenu?.properties?.annotationId;
  const [activeAnnotationAssignmentId] = useQueryParam(
    getActiveAnnotationAssignmentIdViewerKey(slide.viewerIndex),
    NumberParam
  );

  const savedAnnotationData = canAnnotateSlides
    ? !isNaN(featureAnnotationId)
      ? find(slideAnnotations, { annotationId: featureAnnotationId })
      : find(slideAnnotations, {
          annotationAssignment: {
            annotationAssignmentId: activeAnnotationAssignmentId,
          },
        })
    : undefined;

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement>(null);

  const { updateFeatureCollection } = useAnnotationsStoreWithUndo();

  const handleRemove = () => {
    const currentDraft = cloneDeep(activeAnnotationData);
    currentDraft.features = filter(currentDraft.features, (feature, index) => {
      return !includes(contextMenuFeatureIndexes, index);
    });

    updateFeatureCollection(currentDraft, {
      type: ActionTypes.DELETE_FEATURE,
      // if multiple features are selected, they must be from the same shape type, so we can just take the first one
      featureType: activeAnnotationData?.features?.[first(contextMenuFeatureIndexes)]?.properties?.shapeSubtype,
    });

    if (viewerClickData[slide?.viewerIndex]) {
      viewerClickData[slide?.viewerIndex].value = null;
    }
  };

  // allow escape functionality
  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === 'Escape') {
      // if we are on selection mode (the only mode featureIndexes will be more then one), then all features have diagnosis (so its ok to only check the first one)
      const contextMenuFeatureIndex: number = first(contextMenuFeatureIndexes);
      if (!activeAnnotationData?.features?.[contextMenuFeatureIndex]?.properties?.diagnosis) {
        event.stopPropagation();
        event.preventDefault();
        handleRemove();
      } else {
        if (viewerClickData[slide?.viewerIndex]) {
          viewerClickData[slide?.viewerIndex].value = null;
        }
      }
    }
  };

  return (
    canAnnotateSlides && (
      <HtmlDeckGLOverlay viewport={viewport}>
        <HtmlDeckGLOverlayItem
          key="contextMenu"
          draggable={false}
          coordinates={featureCoordinates || DEFAULT_COORDINATES}
        >
          <div ref={(el) => setAnchorEl(el)} />

          <Popover
            onClose={(event, reason) => {
              // If the feature is without a diagnosis, don't allow close the popover until a diagnosis is selected
              if (reason === 'backdropClick' && featureForContextMenu?.properties?.diagnosis) {
                if (viewerClickData[slide?.viewerIndex]) {
                  viewerClickData[slide?.viewerIndex].value = null;
                }
              }
            }}
            id="annotationContextMenu"
            open={Boolean(featureCoordinates) && Boolean(anchorEl)}
            anchorEl={anchorEl}
            disableEscapeKeyDown={false}
            onKeyDown={handleKeyDown}
          >
            <Paper>
              <DiagnosisSelect
                annotationId={savedAnnotationData?.annotationId}
                slideId={slide?.id}
                viewerIndex={slide?.viewerIndex}
                featureIndexes={contextMenuFeatureIndexes}
              />

              <Divider variant="middle" />

              <StickyMenuItems
                viewerIndex={slide?.viewerIndex}
                annotationDraft={activeAnnotationData}
                featureIndexes={contextMenuFeatureIndexes}
                isSelectFeaturesOn={isSelectFeaturesOn}
                isOnlyNonCanvasEditingEnabled={isOnlyNonCanvasEditingEnabled}
                handleRemove={handleRemove}
              />

              <Divider variant="middle" />

              {size(contextMenuFeatureIndexes) === 1 && (
                <>
                  {/* TODO: implement lock */}
                  <MenuItem onClick={console.debug}>
                    <ListItemIcon>
                      {locked ? <LockOpenIcon fontSize="small" /> : <LockIcon fontSize="small" />}
                    </ListItemIcon>
                    <ListItemText>{locked ? 'Unlock' : 'Lock'}</ListItemText>
                  </MenuItem>

                  <FeatureSizeSection
                    feature={featureForContextMenu}
                    maxResolution={slide?.maxResolution}
                    maxLevel={baseImagePyramids?.layerSource?.maxLevel ?? 0}
                  />
                </>
              )}
            </Paper>
          </Popover>
        </HtmlDeckGLOverlayItem>
      </HtmlDeckGLOverlay>
    )
  );
};
