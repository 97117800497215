import { CellRulesPanelSelect } from 'components/atoms/CellRulesPanelSelect';
import { CellRules } from 'interfaces/jobs/multiplex/cellTypingParams';
import React, { useEffect, useState } from 'react';
import useCellRules from 'utils/queryHooks/cellRule/useCellRules';
import { convertToCellRules } from './helpers';

interface CellRulesFromPanelSelectProps {
  studyId: string;
  showRequiredError?: boolean;
  onCellRulesChange: (cellRules: CellRules) => void;
  autoAssignLastPanel?: boolean;
}

const CellRulesFromPanelSelect: React.FC<CellRulesFromPanelSelectProps> = ({
  studyId,
  showRequiredError,
  onCellRulesChange,
  autoAssignLastPanel,
}) => {
  const [selectedPanelId, setSelectedPanelId] = useState<string | null>(null);
  const { data: panel, isLoading } = useCellRules(selectedPanelId, studyId);

  useEffect(() => {
    const cellRules: CellRules = convertToCellRules(panel);
    onCellRulesChange(cellRules);
  }, [panel]);

  return (
    <CellRulesPanelSelect
      selectedPanelId={selectedPanelId}
      onSelectPanel={setSelectedPanelId}
      studyId={studyId}
      isRequired
      showRequiredError={showRequiredError && !isLoading}
      autoAssignLastPanel={autoAssignLastPanel}
    />
  );
};

export default CellRulesFromPanelSelect;
