import { Button, Grid } from '@mui/material';
import { keys, map, max, toNumber } from 'lodash';
import React from 'react';

import { SingleClusterIndexToNeighborhoodName } from './SingleClusterIndexToNeighborhoodName';

export interface MapClusterIndexToNeighborhoodNameProps {
  clusterAssignmentMap: Record<number, string>;
  setClusterAssignmentMap: React.Dispatch<React.SetStateAction<Record<number, string>>>;
}

export const MapClusterIndexToNeighborhoodName: React.FC<MapClusterIndexToNeighborhoodNameProps> = ({
  clusterAssignmentMap,
  setClusterAssignmentMap,
}) => {
  return (
    <Grid container direction="column">
      <Grid item container direction="column" spacing={1} marginBlock={2}>
        {map(keys(clusterAssignmentMap), (genericCluster) => (
          <SingleClusterIndexToNeighborhoodName
            key={genericCluster}
            genericCluster={toNumber(genericCluster)}
            currentClusterAssignmentMap={clusterAssignmentMap}
            setClusterAssignmentMap={setClusterAssignmentMap}
          />
        ))}
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            setClusterAssignmentMap((currentMappings) => ({
              ...currentMappings,
              [max([...map(keys(currentMappings), toNumber), -1]) + 1]: '',
            }))
          }
        >
          Add Cluster Mapping
        </Button>
      </Grid>
    </Grid>
  );
};
