import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Checkbox, FormControl, TextField } from '@mui/material';
import { CellType } from 'interfaces/cellType';
import { filter, includes } from 'lodash';
import React from 'react';
import { useCellTypeOptions } from 'utils/queryHooks/useCellTypeOptions';

interface CellSelectDropdownProps {
  selectedCellTypeIds: string[];
  onSelectCellTypes: (cellTypes: CellType[]) => void;
}

const icon = <CheckBoxOutlineBlankIcon />;
const checkedIcon = <CheckBoxIcon />;

const CellSelectDropdown: React.FC<CellSelectDropdownProps> = ({ selectedCellTypeIds, onSelectCellTypes }) => {
  const { data: cellTypes, isLoading } = useCellTypeOptions();

  const selectedCellTypes = filter(cellTypes, (cellTypeOption) => includes(selectedCellTypeIds, cellTypeOption.id));

  return (
    <FormControl
      sx={{
        width: '100%',
      }}
    >
      <Autocomplete
        multiple
        options={cellTypes}
        getOptionLabel={(option) => option.displayName}
        value={selectedCellTypes}
        onChange={(event, newValue) => onSelectCellTypes(newValue as CellType[])}
        disableCloseOnSelect
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props} key={option.id}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
                size="small"
              />
              {option.displayName}
            </li>
          );
        }}
        renderInput={(params) => <TextField {...params} label="Cell Type(s)" disabled={isLoading} />}
        limitTags={8}
      />
    </FormControl>
  );
};

export default CellSelectDropdown;
