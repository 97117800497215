import { signal } from '@preact/signals-react';
import { ShapeSubTypes } from 'components/Procedure/Header/SlideInteractionMenu/SlideAnnotationTools/options';
import { MAX_VIEWERS } from 'components/Procedure/SlidesViewer/constants';
import { times } from 'lodash';

export interface AnnotationState {
  name: string;
  positive?: boolean; // for marker annotations (to know if now annotating cells that are positive or negative to the marker)
}

// what we save in the signal
interface AnnotationSettings {
  currentClassToAnnotatePerGeometry: {
    [K in ShapeSubTypes]?: AnnotationState;
  };
  selectedIndexes: number[];
  featureIndexesOfSelectedArea: number[];
}

export const viewerAnnotationSettings = times(MAX_VIEWERS, () => signal<AnnotationSettings | null>(null));

export const viewerAnnotationSettingsDefaultValues: AnnotationSettings = {
  currentClassToAnnotatePerGeometry: {},
  selectedIndexes: [],
  featureIndexesOfSelectedArea: [],
};
