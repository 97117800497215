import { useJobs } from 'components/Pages/Jobs/useJobs';
import { CalculateFeaturesJob } from 'interfaces/job';
import { Permission } from 'interfaces/permissionOption';
import { castArray, compact, find, isEmpty, size } from 'lodash';
import { usePermissions } from 'utils/usePermissions';

export const useWholeSlideAnalysisJob = ({
  secondaryAnalysisSourceOrchestrationId,
  studyId,
  skipQuery,
}: {
  secondaryAnalysisSourceOrchestrationId: string;
  studyId: string;
  skipQuery: boolean;
}) => {
  const { hasPermission } = usePermissions();
  const canRunSecondaryAnalysis = hasPermission(Permission.RunSecondaryAnalysis);
  const jobQueryOrchestrationIds = compact(castArray(secondaryAnalysisSourceOrchestrationId));
  const jobsQueryEnabled = !skipQuery && canRunSecondaryAnalysis && !isEmpty(jobQueryOrchestrationIds);
  const { data: jobsQueryResponse, isLoading: isLoadingOriginalJobState } = useJobs({
    enabled: jobsQueryEnabled,
    additionalFilters: { orchestrationIds: jobQueryOrchestrationIds, studyId },
    fullData: true,
  });
  const isLoadingOriginalJob = isLoadingOriginalJobState && jobsQueryEnabled;

  const orchestrationIdJobs = jobsQueryResponse?.jobs;

  if (!isEmpty(orchestrationIdJobs) && size(orchestrationIdJobs) > jobsQueryResponse?.totalJobs) {
    console.error('Got more jobs for orchestration than a single page size, need to implement paginated query', {
      orchestrationIdJobs,
      totalJobs: jobsQueryResponse?.totalJobs,
    });
  }

  const wholeSlideAnalysisJob = find(orchestrationIdJobs, (job): job is CalculateFeaturesJob => !job?.parentId);

  return {
    wholeSlideAnalysisJob,
    isLoadingOriginalJob,
  };
};
