import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { CalculateFeaturesJob, NucleaiExecutorJob } from 'interfaces/job';
import { Permission } from 'interfaces/permissionOption';
import { WebappOrchestration } from 'interfaces/webappOrchestration';
import { concat, filter, find, first, isNumber, some } from 'lodash';
import { useParams } from 'react-router-dom';
import { NumberParam, StringParam, UrlUpdateType, useQueryParam } from 'use-query-params';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { usePermissions } from 'utils/usePermissions';
import { RunSecondaryAnalysisParams, useRunSecondaryAnalysis } from './useRunSecondaryAnalysis';
import {
  useSecondaryAnalysisOrchestrationIdQueryParams,
  useSecondaryAnalysisSelectionModeQueryParam,
} from './useSecondaryAnalysisQueryParams';
import { useWholeSlideAnalysisJob } from './useWholeSlideAnalysisJob';

export interface SecondaryAnalysisContext {
  isSecondaryAnalysisActive: boolean;
  isLoadingOriginalJob: boolean;
  canRunSecondaryAnalysis: boolean;
  secondaryAnalysisActiveViewer: number;
  secondaryAnalysisSourceOrchestrationId: string;
  caseId: number;
  studyId: string;
  slideId: string;
  autoInternallyApprove: boolean;
  wholeSlideAnalysisJob: CalculateFeaturesJob | NucleaiExecutorJob;
  secondaryAnalysisAreaSelectionMode: string;
}

// We check if any experiment from the parent orchestration is approved / internally approved
const shouldAutoInternallyApprove = (
  slideForActiveViewer: SlideWithChannelAndResults,
  secondaryAnalysisSourceOrchestrationId: string
) => {
  const existingParentExperimentResult = find(slideForActiveViewer?.experimentResults, {
    orchestrationId: secondaryAnalysisSourceOrchestrationId,
  });
  const parentSecondaryExperimentResults = filter(
    slideForActiveViewer?.experimentResults,
    (result) => result.primaryRunOrchestrationId === secondaryAnalysisSourceOrchestrationId
  );

  const experimentResultsToCheck = concat(
    existingParentExperimentResult ? [existingParentExperimentResult] : [],
    parentSecondaryExperimentResults
  );

  const autoInternallyApprove = Boolean(
    some(experimentResultsToCheck, 'approved') || some(existingParentExperimentResult, 'internallyApproved')
  );

  return autoInternallyApprove;
};

export interface SecondaryAnalysisContextModifiers {
  clearSecondaryAnalysisOrchestrationIdQueryParams: () => void;
  setSecondaryAnalysisOrchestrationIdForViewer: (viewerIndex: number, orchestrationId: string) => void;
  setSecondaryAnalysisAreaSelectionMode: (
    newValue: string | ((prev: string) => string),
    updateType?: UrlUpdateType
  ) => void;
}

interface SecondaryAnalysisOrchestration
  extends WebappOrchestration<
    SecondaryAnalysisContext,
    RunSecondaryAnalysisParams,
    SecondaryAnalysisContextModifiers
  > {}

export const useSecondaryAnalysis = ({
  onSuccess,
  caseId: caseIdFromProps,
  studyId: studyIdFromProps,
  selectedSlides,
  debug,
  skipQuery,
}: {
  caseId?: number;
  studyId?: string;
  selectedSlides?: SlideWithChannelAndResults[];
  onSuccess?: () => void;
  debug?: boolean;
  skipQuery?: boolean;
} = {}): SecondaryAnalysisOrchestration => {
  const { runSecondaryAnalysis, isStartingSecondaryAnalysis } = useRunSecondaryAnalysis({ onSuccess });
  const { hasPermission } = usePermissions();
  const canRunSecondaryAnalysis = hasPermission(Permission.RunSecondaryAnalysis);

  const {
    secondaryAnalysisActiveViewer,
    secondaryAnalysisSourceOrchestrationId,
    setSecondaryAnalysisOrchestrationIdForViewer,
    clearSecondaryAnalysisOrchestrationIdQueryParams,
  } = useSecondaryAnalysisOrchestrationIdQueryParams();

  const { queryParams } = useEncodedFilters();
  const params = useParams();
  const [caseIdFromParam] = useQueryParam('caseId', NumberParam);
  const caseId = caseIdFromProps
    ? caseIdFromProps
    : queryParams.slidesMode
    ? Number(caseIdFromParam)
    : Number(params.id);

  const [fromStudyFilter] = useQueryParam('fromStudyFilter', StringParam);
  const studyId =
    studyIdFromProps ||
    first(selectedSlides)?.studyId ||
    params?.studyId ||
    queryParams.filters?.studyId ||
    fromStudyFilter;
  const [secondaryAnalysisAreaSelectionMode, setSecondaryAnalysisAreaSelectionMode] =
    useSecondaryAnalysisSelectionModeQueryParam();

  const slideForActiveViewer = find(selectedSlides, { viewerIndex: secondaryAnalysisActiveViewer });
  const slideId = slideForActiveViewer?.id;

  const secondaryExperimentResult = find(slideForActiveViewer?.experimentResults, {
    orchestrationId: secondaryAnalysisSourceOrchestrationId,
  });

  const autoInternallyApprove = Boolean(
    secondaryAnalysisSourceOrchestrationId &&
      shouldAutoInternallyApprove(slideForActiveViewer, secondaryAnalysisSourceOrchestrationId)
  );

  const isSecondaryAnalysisActive =
    Boolean(secondaryAnalysisSourceOrchestrationId) && isNumber(secondaryAnalysisActiveViewer);

  const { wholeSlideAnalysisJob, isLoadingOriginalJob } = useWholeSlideAnalysisJob({
    secondaryAnalysisSourceOrchestrationId,
    skipQuery,
    studyId,
  });

  if (debug) {
    console.debug('useSecondaryAnalysis', {
      selectedSlides,
      slideForActiveViewer,
      slideId,
      secondaryExperimentResult,
      secondaryAnalysisSourceOrchestrationId,
      secondaryAnalysisActiveViewer,
      caseId,
      studyId,
      autoInternallyApprove,
      wholeSlideAnalysisJob,
      isSecondaryAnalysisActive,
      isLoadingOriginalJob,
      canRunSecondaryAnalysis,
      secondaryAnalysisAreaSelectionMode,
    });
  }

  return {
    execution: {
      start: runSecondaryAnalysis,
      isStarting: isStartingSecondaryAnalysis,
    },
    context: {
      isSecondaryAnalysisActive,
      isLoadingOriginalJob,
      canRunSecondaryAnalysis,
      secondaryAnalysisSourceOrchestrationId,
      caseId,
      studyId,
      slideId,
      autoInternallyApprove,
      wholeSlideAnalysisJob,
      secondaryAnalysisActiveViewer,
      secondaryAnalysisAreaSelectionMode,
    },
    modifyContext: {
      clearSecondaryAnalysisOrchestrationIdQueryParams,
      setSecondaryAnalysisOrchestrationIdForViewer,
      setSecondaryAnalysisAreaSelectionMode,
    },
  };
};
